export interface FlightPurpose {
    id: string;
    name: string;
    description: string;
}

export enum GroundRiskBufferType {
    Standard = "STANDARD",
    Individual = "INDIVIDUAL",
}

export enum PilotCompetencies {
    A1 = "A1",
    A2 = "A2",
    A3 = "A3",
    STS01 = "STS-01",
    STS02 = "STS-02",
    PDRAG01 = "PDRA-G01",
    PDRAG02 = "PDRA-G02",
    NSTS01 = "NSTS-01",
    NSTS02 = "NSTS-02",
    NSTS03 = "NSTS-03",
    NSTS04 = "NSTS-04",
    NSTS05 = "NSTS-05",
    NSTS06 = "NSTS-06",
    NSTS07 = "NSTS-07",
    NSTS08 = "NSTS-08",
}

export type PilotOperatorQualifications = Exclude<PilotCompetencies, PilotCompetencies.PDRAG02 | PilotCompetencies.PDRAG01>;

export const OPERATIONAL_AUTHORIZATIONS: PilotOperatorQualifications[] = [
    PilotCompetencies.STS01,
    PilotCompetencies.STS02,
    PilotCompetencies.NSTS01,
    PilotCompetencies.NSTS02,
    PilotCompetencies.NSTS03,
    PilotCompetencies.NSTS04,
    PilotCompetencies.NSTS05,
    PilotCompetencies.NSTS06,
    PilotCompetencies.NSTS07,
    PilotCompetencies.NSTS08,
];

export const PILOT_COMPETENCIES: PilotOperatorQualifications[] = [
    PilotCompetencies.A1,
    PilotCompetencies.A2,
    PilotCompetencies.A3,
    PilotCompetencies.STS01,
    PilotCompetencies.STS02,
    PilotCompetencies.NSTS01,
    PilotCompetencies.NSTS02,
    PilotCompetencies.NSTS03,
    PilotCompetencies.NSTS04,
    PilotCompetencies.NSTS05,
    PilotCompetencies.NSTS06,
    PilotCompetencies.NSTS07,
    PilotCompetencies.NSTS08,
];
