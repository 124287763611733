import { ChangeDetectionStrategy, Component, Inject, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { FILES_UPLOAD_API_PROVIDER, InvalidFormScrollableDirective, UavType } from "@dtm-frontend/shared/ui";
import { LocalComponentStore, ONLY_WHITE_SPACES_VALIDATION_PATTERN } from "@dtm-frontend/shared/utils";
import { Observable, Subject } from "rxjs";
import { UavClass } from "../../../shared";
import { CustomUavImageUploadApiService } from "../../services/custom-uav-image-upload-api.service";
import { EditableCustomUav, MAX_UAV_NAME_LENGTH, MIN_UAV_NAME_LENGTH } from "../../services/uav.models";

interface EditCustomUavDialogComponentState {
    isSaveButtonDisabled: boolean;
}

interface EditCustomUavDialogData {
    currentName: string;
    currentSerialNumbers: string[];
    currentUavClasses: UavClass[];
    currentIsSwarm: boolean;
    currentManufacturerName: string;
    currentIsCeCompliant: boolean;
    currentModelName: string;
    currentType: UavType;
    currentImageId: string | undefined;
    isProcessing$: Observable<boolean>;
}

interface CustomUavForm {
    name: FormControl<string | null>;
    serialNumbers: FormControl<string[]>;
    uavClasses: FormControl<UavClass[]>;
    isCeCompliant: FormControl<boolean>;
    image: FormControl<string | null>;
}

interface CustomUavFormValues {
    name: string;
    serialNumbers: string[];
    uavClasses: UavClass[];
    manufacturerName: string;
    modelName: string;
    type: UavType;
    isCeCompliant: boolean;
    image: string | null;
}

@Component({
    selector: "dtm-web-app-lib-edit-custom-uav-dialog",
    templateUrl: "./edit-custom-uav-dialog.component.html",
    styleUrls: ["./edit-custom-uav-dialog.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        LocalComponentStore,
        {
            provide: FILES_UPLOAD_API_PROVIDER,
            useClass: CustomUavImageUploadApiService,
        },
    ],
})
export class EditCustomUavDialogComponent {
    @ViewChild(InvalidFormScrollableDirective) private readonly invalidFormScrollable!: InvalidFormScrollableDirective;

    private readonly setUavValuesSubject = new Subject<EditableCustomUav>();
    public readonly setUavValues$ = this.setUavValuesSubject.asObservable();
    protected readonly isSaveButtonDisabled$ = this.localStore.selectByKey("isSaveButtonDisabled");

    protected readonly uavTypes: UavType[] = Object.values(UavType);
    protected readonly uavClasses: UavClass[] = Object.values(UavClass);
    protected readonly customUavForm = new FormGroup<CustomUavForm>({
        name: new FormControl<string | null>(null, [
            Validators.required,
            Validators.maxLength(MAX_UAV_NAME_LENGTH),
            Validators.minLength(MIN_UAV_NAME_LENGTH),
            Validators.pattern(ONLY_WHITE_SPACES_VALIDATION_PATTERN),
        ]),
        serialNumbers: new FormControl<string[]>([], {
            validators: [Validators.required],
            nonNullable: true,
        }),
        uavClasses: new FormControl<UavClass[]>([], { nonNullable: true }),
        isCeCompliant: new FormControl<boolean>(false, { validators: Validators.required, nonNullable: true }),
        image: new FormControl<string | null>(null),
    });

    constructor(
        @Inject(MAT_DIALOG_DATA) protected readonly data: EditCustomUavDialogData,
        private readonly localStore: LocalComponentStore<EditCustomUavDialogComponentState>
    ) {
        const values = {
            name: data.currentName,
            serialNumbers: data.currentSerialNumbers,
            uavClasses: data.currentUavClasses,
            isCeCompliant: data.currentIsCeCompliant,
            image: data.currentImageId ?? null,
        };

        this.customUavForm.setValue(values, { emitEvent: false });

        this.localStore.setState({ isSaveButtonDisabled: false });
    }

    protected save(): void {
        if (this.customUavForm.invalid) {
            this.customUavForm.markAllAsTouched();
            this.invalidFormScrollable.scrollToFirstInvalidField();

            return;
        }

        const values = this.customUavForm.value as CustomUavFormValues;

        this.setUavValuesSubject.next({
            name: values.name,
            serialNumbers: values.serialNumbers,
            uavClasses: values.uavClasses,
            manufacturerName: this.data.currentManufacturerName,
            modelName: this.data.currentModelName,
            type: this.data.currentType,
            isCeCompliant: values.isCeCompliant,
            imageId: values.image ?? null,
        });
    }

    protected setImageEditMode(isEditMode: boolean): void {
        this.localStore.patchState({ isSaveButtonDisabled: isEditMode });
    }
}
