import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import {
    ButtonTheme,
    ConfirmationDialogComponent,
    InvalidFormScrollableDirective,
    SelectFieldComponent,
    UavType,
} from "@dtm-frontend/shared/ui";
import { LocalComponentStore, ONLY_WHITE_SPACES_VALIDATION_PATTERN, RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Observable, of } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { UavClass } from "../../../../../shared";
import { MAX_UAV_NAME_LENGTH, MIN_UAV_NAME_LENGTH, Manufacturer, ManufacturerModel } from "../../../../services/uav.models";
import { NewCustomUavBasicInfo, NewStandardUavBasicInfo, NewUavBasicInfo } from "../../new-uav-wizard.models";
import { CustomUavConfirmationDialogComponent } from "./custom-uav-confirmation-dialog/custom-uav-confirmation-dialog.component";
import { NewUavWizardBasicInfoStepCustomUavComponent } from "./custom-uav/custom-uav.component";
import { NewUavWizardBasicInfoStepStandardUavComponent } from "./standard-uav/standard-uav.component";

interface NewUavWizardBasicInfoStepComponentState {
    manufacturers: Manufacturer[];
    manufacturersOptions: Manufacturer[];
    uavModels: ManufacturerModel[] | undefined;
    isCustomUav: boolean | undefined;
    isProcessing: boolean;
    isNextButtonEnabled: boolean;
    initialValues: NewUavBasicInfo | undefined;
}

export interface StandardUavForm {
    manufacturer: FormControl<Manufacturer | null>;
    uavModel: FormControl<ManufacturerModel | null>;
    isSwarm: FormControl<boolean>;
    serialNumbers: FormControl<string[]>;
    uavClasses: FormControl<UavClass[]>;
    name: FormControl<string | null>;
}

export interface CustomUavForm {
    manufacturer: FormControl<Manufacturer | null>;
    manufacturerName: FormControl<string>;
    modelName: FormControl<string>;
    type: FormControl<UavType | null>;
    isSwarm: FormControl<boolean>;
    serialNumbers: FormControl<string[]>;
    uavClasses: FormControl<UavClass[]>;
    isCeCompliant: FormControl<boolean>;
    name: FormControl<string | null>;
    image: FormControl<string | null>;
}

interface StandardUavFormValues {
    manufacturer: Manufacturer;
    uavModel: ManufacturerModel;
    isSwarm: boolean;
    serialNumbers: string[];
    uavClasses: UavClass[];
    name: string;
}

interface CustomUavFormValues {
    manufacturer: Manufacturer;
    manufacturerName: string;
    modelName: string;
    type: UavType;
    isSwarm: boolean;
    serialNumbers: string[];
    uavClasses: UavClass[];
    name: string;
    isCeCompliant: boolean;
    image: string | null;
}

const MAX_MANUFACTURER_NAME_LENGTH = 100;
const MAX_MODEL_NAME_LENGTH = 100;
const OTHER_MANUFACTURER_VALUE = { name: "", models: [] };

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-new-uav-wizard-basic-info-step[manufacturers]",
    templateUrl: "./basic-info-step.component.html",
    styleUrls: ["./basic-info-step.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class NewUavWizardBasicInfoStepComponent {
    @Input() public set manufacturers(value: Manufacturer[] | undefined) {
        const sortedManufacturers = [...(value ?? [])].sort((left, right) => left.name.localeCompare(right.name));
        this.localStore.patchState({ manufacturers: sortedManufacturers, manufacturersOptions: sortedManufacturers });

        this.manufacturerSearchControl.reset("");
    }

    @Input() public set isProcessing(value: BooleanInput) {
        this.localStore.patchState({ isProcessing: coerceBooleanProperty(value) });
    }

    @Input() public set initialValues(value: NewUavBasicInfo | undefined) {
        this.localStore.patchState({
            initialValues: value,
        });

        if (!value) {
            return;
        }

        this.localStore.patchState({
            uavModels: !value.isCustom && value.manufacturer ? value.manufacturer.models ?? [] : undefined,
            isCustomUav: value.isCustom,
        });

        this.setFormValuesByUavBasicInfo(value);
    }

    @Output() public next = new EventEmitter<NewUavBasicInfo>();

    @ViewChild(InvalidFormScrollableDirective) private readonly invalidFormScrollable!: InvalidFormScrollableDirective;
    @ViewChild(SelectFieldComponent) private readonly manufacturerSelect!: SelectFieldComponent;
    @ViewChild(NewUavWizardBasicInfoStepStandardUavComponent)
    private readonly standardUavFormComponent!: NewUavWizardBasicInfoStepStandardUavComponent;
    @ViewChild(NewUavWizardBasicInfoStepCustomUavComponent)
    private readonly customUavFormComponent!: NewUavWizardBasicInfoStepCustomUavComponent;

    protected OTHER_MANUFACTURER_VALUE = OTHER_MANUFACTURER_VALUE;

    protected readonly manufacturers$ = this.localStore.selectByKey("manufacturersOptions");
    protected readonly uavModels$ = this.localStore.selectByKey("uavModels").pipe(RxjsUtils.filterFalsy());
    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");
    protected readonly isNextButtonEnabled$ = this.localStore.selectByKey("isNextButtonEnabled");

    protected readonly manufacturerSearchControl = new FormControl<string>("", { nonNullable: true });
    protected readonly manufacturerControl = new FormControl<Manufacturer | null>(null, Validators.required);
    protected readonly standardUavForm = new FormGroup<StandardUavForm>({
        manufacturer: this.manufacturerControl,
        uavModel: new FormControl<ManufacturerModel | null>(null, Validators.required),
        isSwarm: new FormControl<boolean>(false, { validators: Validators.required, nonNullable: true }),
        serialNumbers: this.createSerialNumbersControl(),
        uavClasses: new FormControl<UavClass[]>([], { nonNullable: true }),
        name: this.createNameControl(),
    });
    protected readonly customUavForm = new FormGroup<CustomUavForm>({
        manufacturer: this.manufacturerControl,
        manufacturerName: new FormControl<string>("", {
            validators: [
                Validators.required,
                Validators.maxLength(MAX_MANUFACTURER_NAME_LENGTH),
                Validators.pattern(ONLY_WHITE_SPACES_VALIDATION_PATTERN),
            ],
            nonNullable: true,
        }),
        modelName: new FormControl<string>("", {
            validators: [
                Validators.required,
                Validators.maxLength(MAX_MODEL_NAME_LENGTH),
                Validators.pattern(ONLY_WHITE_SPACES_VALIDATION_PATTERN),
            ],
            nonNullable: true,
        }),
        type: new FormControl<UavType | null>(null, Validators.required),
        image: new FormControl<string | null>(null),
        isSwarm: new FormControl<boolean>(false, { validators: Validators.required, nonNullable: true }),
        serialNumbers: this.createSerialNumbersControl(),
        uavClasses: new FormControl<UavClass[]>([], { nonNullable: true }),
        isCeCompliant: new FormControl<boolean>(false, { validators: Validators.required, nonNullable: true }),
        name: this.createNameControl(),
    });

    protected readonly isStandardUavModel$ = this.localStore.selectByKey("isCustomUav").pipe(map((isCustomUav) => isCustomUav === false));
    protected readonly isCustomUavModel$ = this.localStore.selectByKey("isCustomUav").pipe(map((isCustomUav) => !!isCustomUav));

    constructor(
        private readonly localStore: LocalComponentStore<NewUavWizardBasicInfoStepComponentState>,
        private readonly matDialog: MatDialog,
        private readonly transloco: TranslocoService
    ) {
        this.localStore.setState({
            manufacturers: [],
            manufacturersOptions: [],
            uavModels: undefined,
            isCustomUav: undefined,
            isProcessing: false,
            isNextButtonEnabled: true,
            initialValues: undefined,
        });

        this.manufacturerSearchControl.valueChanges.pipe(startWith(""), untilDestroyed(this)).subscribe((searchValue) => {
            this.localStore.patchState(({ manufacturers }) => ({
                manufacturersOptions: manufacturers.filter((manufacturer) =>
                    manufacturer.name.toLowerCase().includes(searchValue.toLowerCase())
                ),
            }));
        });

        this.manufacturerControl.valueChanges
            .pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
            .subscribe((manufacturer) => this.selectManufacturer(manufacturer));
    }

    protected goToNextStep() {
        const isCustomUav = this.localStore.selectSnapshotByKey("isCustomUav");

        if (isCustomUav === false) {
            this.goToNextStepForStandardUav();
        } else if (isCustomUav) {
            this.goToNextStepForCustomUav();
        } else {
            this.manufacturerControl.markAsTouched();
            this.invalidFormScrollable.scrollToFirstInvalidField();
        }
    }

    protected setImageEditMode(isEditMode: boolean): void {
        this.localStore.patchState({ isNextButtonEnabled: !isEditMode });
    }

    protected selectActiveManufacturer() {
        const value = this.manufacturerSelect.activeItem?.value;

        if (!value) {
            return;
        }

        this.manufacturerControl.setValue(value);
    }

    private createSerialNumbersControl() {
        return new FormControl<string[]>([], {
            validators: [Validators.required],
            nonNullable: true,
        });
    }

    private createNameControl() {
        return new FormControl<string | null>(null, [
            Validators.required,
            Validators.maxLength(MAX_UAV_NAME_LENGTH),
            Validators.minLength(MIN_UAV_NAME_LENGTH),
            Validators.pattern(ONLY_WHITE_SPACES_VALIDATION_PATTERN),
        ]);
    }

    private selectManufacturer(manufacturer: Manufacturer) {
        const uavModels = manufacturer.models ?? [];
        this.localStore.patchState({
            uavModels,
            isCustomUav: uavModels.length === 0,
        });
        this.manufacturerSearchControl.reset("");
    }

    private goToNextStepForStandardUav(): void {
        if (this.standardUavForm.valid) {
            const isConfirmed$ = this.canShowSerialNumberConfirmation(this.standardUavForm)
                ? this.confirmSerialNumber(this.standardUavForm.controls.serialNumbers.value[0])
                : of(true);

            isConfirmed$.pipe(untilDestroyed(this)).subscribe((isConfirmed) => {
                if (isConfirmed) {
                    this.next.emit(this.getStandardUavFormValues());
                } else {
                    this.standardUavFormComponent.focusOnSerialNumberControl();
                }
            });
        } else {
            this.standardUavForm.markAllAsTouched();
            this.invalidFormScrollable.scrollToFirstInvalidField();
        }
    }

    private goToNextStepForCustomUav(): void {
        if (this.customUavForm.valid) {
            const formValues = this.getCustomUavFormValues();

            this.confirmCustomUavData(formValues)
                .pipe(untilDestroyed(this))
                .subscribe((isConfirmed) => {
                    if (isConfirmed) {
                        this.next.emit(formValues);
                    } else {
                        this.customUavFormComponent.focusOnManufacturerNameControl();
                    }
                });
        } else {
            this.customUavForm.markAllAsTouched();
            this.invalidFormScrollable.scrollToFirstInvalidField();
        }
    }

    private confirmSerialNumber(serialNumber: string): Observable<boolean> {
        return this.matDialog
            .open(ConfirmationDialogComponent, {
                data: {
                    titleText: this.transloco.translate("dtmWebAppLibUav.newUavWizardSteps.basicInfo.confirmSerialNumberDialog.titleText"),
                    confirmationText: this.transloco.translate(
                        "dtmWebAppLibUav.newUavWizardSteps.basicInfo.confirmSerialNumberDialog.contentText",
                        { serialNumber }
                    ),
                    declineButtonLabel: this.transloco.translate(
                        "dtmWebAppLibUav.newUavWizardSteps.basicInfo.confirmSerialNumberDialog.cancelButtonLabel"
                    ),
                    confirmButtonLabel: this.transloco.translate(
                        "dtmWebAppLibUav.newUavWizardSteps.basicInfo.confirmSerialNumberDialog.confirmButtonLabel"
                    ),
                    theme: ButtonTheme.Primary,
                },
            })
            .afterClosed();
    }

    private confirmCustomUavData(data: NewCustomUavBasicInfo): Observable<boolean> {
        return this.matDialog.open(CustomUavConfirmationDialogComponent, { data }).afterClosed();
    }

    private getStandardUavFormValues(): NewStandardUavBasicInfo {
        const formValues = this.standardUavForm.value as StandardUavFormValues;

        return {
            isCustom: false,
            manufacturer: formValues.manufacturer,
            uavModel: formValues.uavModel,
            isSwarm: formValues.isSwarm,
            serialNumbers: formValues.serialNumbers,
            uavClasses: formValues.uavClasses ?? [],
            name: formValues.name,
        };
    }

    private getCustomUavFormValues(): NewCustomUavBasicInfo {
        const formValues = this.customUavForm.value as CustomUavFormValues;

        return {
            isCustom: true,
            manufacturerName: formValues.manufacturerName,
            modelName: formValues.modelName,
            type: formValues.type,
            isSwarm: formValues.isSwarm,
            serialNumbers: formValues.serialNumbers,
            uavClasses: formValues.uavClasses,
            isCeCompliant: formValues.isCeCompliant,
            name: formValues.name,
            imageId: formValues.image ?? null,
        };
    }

    private canShowSerialNumberConfirmation(form: FormGroup<StandardUavForm>): boolean {
        return !form.controls.isSwarm.value && !!form.controls.serialNumbers.value.length;
    }

    private setFormValuesByUavBasicInfo(value: NewUavBasicInfo): void {
        if (value.isCustom) {
            this.customUavForm.setValue({
                manufacturer: OTHER_MANUFACTURER_VALUE,
                manufacturerName: value.manufacturerName,
                modelName: value.modelName,
                type: value.type,
                image: value.imageId,
                isSwarm: value.isSwarm,
                serialNumbers: value.serialNumbers,
                uavClasses: value.uavClasses,
                isCeCompliant: value.isCeCompliant,
                name: value.name,
            });
        } else {
            this.standardUavForm.setValue({
                manufacturer: value.manufacturer,
                uavModel: value.uavModel,
                isSwarm: value.isSwarm,
                serialNumbers: value.serialNumbers,
                uavClasses: value.uavClasses,
                name: value.name,
            });
        }
    }
}
