import { ChangeDetectionStrategy, Component } from "@angular/core";
import { Store } from "@ngxs/store";
import { map } from "rxjs/operators";
import { ApplicationCreatorWizardSteps } from "../../../services/specific-permit-application.models";
import { SpecificPermitApplicationState } from "../../../state/specific-permit-application.state";
import { APPLICATION_WIZARD_ID } from "../content/application-creator-wizard-content.component";

@Component({
    templateUrl: "./application-creator-wizard-header.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApplicationCreatorWizardHeaderComponent {
    protected readonly wizardId = APPLICATION_WIZARD_ID;
    protected readonly ApplicationCreatorWizardSteps = ApplicationCreatorWizardSteps;

    protected readonly sail$ = this.store.select(SpecificPermitApplicationState.operation).pipe(map((operation) => operation?.sail));

    constructor(private readonly store: Store) {}
}
