import { Injectable } from "@angular/core";
import { AuthState } from "@dtm-frontend/shared/auth";
import { FlightPositionUpdaterService } from "@dtm-frontend/shared/map/cesium";
import { GeoZonesActions } from "@dtm-frontend/shared/map/geo-zones";
import { MissionPlanAnalysisStatus, MissionPlanDataAndCapabilities, MissionUtils } from "@dtm-frontend/shared/mission";
import { NotificationsApiService } from "@dtm-frontend/shared/notifications";
import { HemsEventData, OperatorType } from "@dtm-frontend/shared/ui";
import { TranslationHelperService } from "@dtm-frontend/shared/ui/i18n";
import {
    Checkin,
    CheckinStatus,
    DeactivatedSectionsInfo,
    EmergencyType,
    FlightViolationUpdaterService,
    Mission,
    MissionData,
    MissionViewSettings,
    TacticalError,
    TacticalErrorType,
} from "@dtm-frontend/shared/ui/tactical";
import { ArrayUtils, FunctionUtils, RxjsUtils } from "@dtm-frontend/shared/utils";
import { Action, Selector, State, StateContext, Store } from "@ngxs/store";
import {
    EMPTY,
    Subject,
    Subscription,
    catchError,
    filter,
    finalize,
    first,
    firstValueFrom,
    map,
    merge,
    of,
    switchMap,
    takeUntil,
    tap,
} from "rxjs";
import { MissionAPIService } from "../../mission/services/mission-api.service";
import { MissionPlanVerificationAPIService } from "../../mission/services/mission-plan-verification-api.service";
import {
    MissionPlanningNotificationTargetType,
    MissionPlanningNotificationType,
    WebAppMissionPlanningNotification,
} from "../../shared/notifications/services/web-app-notifications.models";
import { OperatorContextState } from "../../shared/operator-context/state/operator-context.state";
import { CheckinEvent, TacticalApiService } from "../services/tactical-api.service";
import { TacticalActions } from "./tactical.actions";

export interface TacticalStateModel {
    acceptedMissions: Mission[];
    activeMissions: Mission[];
    areAdditionalInformationProcessing: boolean;
    deactivatedSectionsInfo: DeactivatedSectionsInfo[] | undefined;
    hemsEventData: HemsEventData[];
    isEmergencyProcessing: boolean;
    isListProcessing: boolean;
    isMenuMissionDataProcessing: boolean;
    isMissionDataProcessing: boolean;
    isNearbyMissionDataProcessing: boolean;
    missionData: MissionData | undefined;
    missionEmergency: EmergencyType | undefined;
    missionPlanAnalysisStatus: MissionPlanAnalysisStatus | undefined;
    missionPlanData: MissionPlanDataAndCapabilities | undefined;
    nearbyMissions: MissionData[];
    nearbyMissionsSettings: MissionViewSettings;
    selectedNearbyMission: MissionData | undefined;
    tacticalError: TacticalError | undefined;
    tacticalMenuError: TacticalError | undefined;
    checkinList: Checkin[];
}

const defaultState: TacticalStateModel = {
    acceptedMissions: [],
    activeMissions: [],
    areAdditionalInformationProcessing: false,
    deactivatedSectionsInfo: undefined,
    hemsEventData: [],
    isEmergencyProcessing: false,
    isListProcessing: false,
    isMenuMissionDataProcessing: false,
    isMissionDataProcessing: false,
    isNearbyMissionDataProcessing: false,
    missionData: undefined,
    missionEmergency: undefined,
    missionPlanAnalysisStatus: undefined,
    missionPlanData: undefined,
    nearbyMissions: [],
    nearbyMissionsSettings: {
        areAllEnabled: false,
        areOnlyActive: false,
    },
    selectedNearbyMission: undefined,
    tacticalError: undefined,
    tacticalMenuError: undefined,
    checkinList: [],
};

@State<TacticalStateModel>({
    name: "tactical",
    defaults: defaultState,
})
@Injectable()
export class TacticalState {
    private acceptedMissionUpdateSubscription: Subscription | undefined;
    private missionUpdateSubscription: Subscription | undefined;
    private missionsUpdateSubscription: Subscription | undefined;
    private sectionDeactivationEventsWatchSubscription: Subscription | undefined;
    private stopFlightPositionUpdates$: Subject<void> | undefined;
    private flightViolationsUpdatesSubscription: Subscription | undefined;
    private stopMissionStatusWatchSubscription: Subscription | undefined;
    private checkinEventsSubscription: Subscription | undefined;

    @Selector()
    public static acceptedMissions(state: TacticalStateModel): Mission[] {
        return state.acceptedMissions;
    }

    @Selector()
    public static tacticalError(state: TacticalStateModel): TacticalError | undefined {
        return state.tacticalError;
    }

    @Selector()
    public static tacticalMenuError(state: TacticalStateModel): TacticalError | undefined {
        return state.tacticalMenuError;
    }

    @Selector()
    public static missionData(state: TacticalStateModel): MissionData | undefined {
        return state.missionData;
    }

    @Selector()
    public static isListProcessing(state: TacticalStateModel): boolean {
        return state.isListProcessing;
    }

    @Selector()
    public static isMissionDataProcessing(state: TacticalStateModel): boolean {
        return state.isMissionDataProcessing;
    }

    @Selector()
    public static isMenuMissionDataProcessing(state: TacticalStateModel): boolean {
        return state.isMenuMissionDataProcessing;
    }

    @Selector()
    public static isNearbyMissionDataProcessing(state: TacticalStateModel): boolean {
        return state.isNearbyMissionDataProcessing;
    }

    @Selector()
    public static activeMissions(state: TacticalStateModel): Mission[] {
        return state.activeMissions;
    }

    @Selector()
    public static nearbyMissions(state: TacticalStateModel): MissionData[] | undefined {
        return state.nearbyMissions;
    }

    @Selector()
    public static deactivatedSectionsInfo(state: TacticalStateModel): DeactivatedSectionsInfo[] | undefined {
        return state.deactivatedSectionsInfo;
    }

    @Selector()
    public static hemsEventData(state: TacticalStateModel): HemsEventData[] {
        return state.hemsEventData;
    }

    @Selector()
    public static missionPlanData(state: TacticalStateModel): MissionPlanDataAndCapabilities | undefined {
        return state.missionPlanData;
    }

    @Selector()
    public static missionPlanAnalysisStatus(state: TacticalStateModel): MissionPlanAnalysisStatus | undefined {
        return state.missionPlanAnalysisStatus;
    }

    @Selector()
    public static nearbyMissionsSettings(state: TacticalStateModel): MissionViewSettings {
        return state.nearbyMissionsSettings;
    }

    @Selector()
    public static areAdditionalInformationProcessing(state: TacticalStateModel): boolean {
        return state.areAdditionalInformationProcessing;
    }

    @Selector()
    public static missionEmergency(state: TacticalStateModel): EmergencyType | undefined {
        return state.missionEmergency;
    }

    @Selector()
    public static isEmergencyProcessing(state: TacticalStateModel): boolean {
        return state.isEmergencyProcessing;
    }

    @Selector()
    public static selectedNearbyMission(state: TacticalStateModel): MissionData | undefined {
        return state.selectedNearbyMission;
    }

    @Selector()
    public static checkinList(state: TacticalStateModel): Checkin[] {
        return state.checkinList;
    }

    constructor(
        private readonly tacticalApi: TacticalApiService,
        private readonly missionApi: MissionAPIService,
        private readonly missionPlanVerificationApi: MissionPlanVerificationAPIService,
        private readonly store: Store,
        private readonly flightPositionUpdater: FlightPositionUpdaterService,
        private readonly flightViolationUpdater: FlightViolationUpdaterService,
        private readonly notificationsApiService: NotificationsApiService,
        private readonly translationHelperService: TranslationHelperService
    ) {}

    @Action(TacticalActions.GetAcceptedMissions, { cancelUncompleted: true })
    public async getAcceptedMission(context: StateContext<TacticalStateModel>) {
        return this.getAcceptedMissionFn(context);
    }

    // NOTE: when cancelUncompleted is used its cause sometimes 'no element in sequence' error
    // which leads to canceling navigation, so separate action is used for resolver
    @Action(TacticalActions.GetInitialAcceptedMissions)
    public async getInitialAcceptedMission(context: StateContext<TacticalStateModel>) {
        return this.getAcceptedMissionFn(context);
    }

    private async getAcceptedMissionFn(context: StateContext<TacticalStateModel>) {
        const currentOperatorContext = await firstValueFrom(
            this.store.select(OperatorContextState.selectedContext).pipe(first(FunctionUtils.isTruthy))
        );

        context.patchState({ isListProcessing: true });

        const operatorId = currentOperatorContext.type !== OperatorType.Personal ? currentOperatorContext.id : undefined;

        return this.tacticalApi.getAcceptedMissions(operatorId).pipe(
            map((acceptedMissionsList: Mission[]) => {
                context.patchState({
                    acceptedMissions: acceptedMissionsList,
                    tacticalError: undefined,
                    isListProcessing: false,
                });
            }),
            catchError((error) => {
                context.patchState({
                    tacticalError: error,
                    isListProcessing: false,
                });

                return EMPTY;
            })
        );
    }

    @Action(TacticalActions.CleanupAcceptedMissions)
    public cleanupAcceptedMissions(context: StateContext<TacticalStateModel>) {
        context.patchState({
            isListProcessing: false,
            acceptedMissions: [],
        });
    }

    @Action(TacticalActions.GetMissionWithRoute, { cancelUncompleted: true })
    public getMissionRoute(context: StateContext<TacticalStateModel>, { missionId }: TacticalActions.GetMissionWithRoute) {
        if (!missionId) {
            return;
        }
        context.patchState({ isMissionDataProcessing: true });

        return this.tacticalApi.getMissionDetails(missionId).pipe(
            tap((missionData) => {
                context.patchState({
                    tacticalError: undefined,
                    isMissionDataProcessing: false,
                    missionData: missionData,
                });
            }),
            catchError((error) => {
                context.patchState({
                    tacticalError: error,
                    isMissionDataProcessing: false,
                    missionData: undefined,
                });

                return EMPTY;
            })
        );
    }

    @Action(TacticalActions.CleanupMissionRoute)
    public cleanupMissionRoute(context: StateContext<TacticalStateModel>) {
        context.patchState({
            missionData: undefined,
            missionEmergency: undefined,
        });
    }

    @Action(TacticalActions.CancelMission)
    public cancelMission(context: StateContext<TacticalStateModel>, { missionId }: TacticalActions.CancelMission) {
        context.patchState({ isMissionDataProcessing: true });

        return this.tacticalApi.cancelMission(missionId).pipe(
            catchError((error) => {
                context.patchState({
                    tacticalError: error,
                    isMissionDataProcessing: false,
                });

                return EMPTY;
            }),
            switchMap(() => context.dispatch(TacticalActions.GetAcceptedMissions))
        );
    }

    @Action(TacticalActions.ActivateMission)
    public activateMission(context: StateContext<TacticalStateModel>, { missionId, missionStartTime }: TacticalActions.ActivateMission) {
        context.patchState({ isMissionDataProcessing: true });

        return this.tacticalApi.activateMission(missionId, missionStartTime).pipe(
            tap(() => context.patchState({ isMissionDataProcessing: false })),
            catchError((error) => {
                context.patchState({
                    tacticalError: error,
                    isMissionDataProcessing: false,
                });

                return EMPTY;
            })
        );
    }

    @Action(TacticalActions.DeactivateMission)
    public deactivateMission(context: StateContext<TacticalStateModel>, { missionId }: TacticalActions.CancelMission) {
        context.patchState({ isMissionDataProcessing: true });

        return this.tacticalApi.deactivateMission(missionId).pipe(
            tap(() => {
                context.patchState({
                    isMissionDataProcessing: true,
                });
            }),
            catchError((error) => {
                context.patchState({
                    tacticalError: error,
                    isMissionDataProcessing: true,
                });

                return EMPTY;
            })
        );
    }

    @Action(TacticalActions.GetActiveMissions, { cancelUncompleted: true })
    public async getActiveMissions(context: StateContext<TacticalStateModel>) {
        context.patchState({ isMenuMissionDataProcessing: true });
        const currentOperatorContext = await firstValueFrom(
            this.store.select(OperatorContextState.selectedContext).pipe(first(FunctionUtils.isTruthy))
        );

        const operatorId = currentOperatorContext.type !== OperatorType.Personal ? currentOperatorContext.id : undefined;

        return this.tacticalApi.getActiveMissions(operatorId).pipe(
            tap((activeMissions: Mission[]) => {
                context.patchState({
                    activeMissions,
                    tacticalMenuError: undefined,
                });
            }),
            catchError((error) => {
                context.patchState({
                    tacticalMenuError: error,
                });

                return EMPTY;
            }),
            finalize(() => {
                context.patchState({
                    isMenuMissionDataProcessing: false,
                });
            })
        );
    }

    @Action(TacticalActions.StartActiveMissionsUpdatesWatch)
    public startActiveMissionsUpdatesWatch(context: StateContext<TacticalStateModel>) {
        return context
            .dispatch(TacticalActions.GetActiveMissions)
            .pipe(
                tap(() =>
                    this.tacticalApi
                        .startActiveMissionsUpdatesWatch(this.store.selectSnapshot(AuthState.userId))
                        .subscribe(() => context.dispatch(TacticalActions.GetActiveMissions))
                )
            );
    }

    @Action(TacticalActions.StartAcceptedMissionsUpdatesWatch)
    public startAcceptedMissionsUpdatesWatch(context: StateContext<TacticalStateModel>) {
        this.acceptedMissionUpdateSubscription = this.tacticalApi
            .startAcceptedMissionsUpdatesWatch(this.store.selectSnapshot(AuthState.userId))
            .subscribe(() => context.dispatch(TacticalActions.GetAcceptedMissions));
    }

    @Action(TacticalActions.StopAcceptedMissionsUpdatesWatch)
    public stopAcceptedMissionsUpdatesWatch() {
        this.acceptedMissionUpdateSubscription?.unsubscribe();
    }

    @Action(TacticalActions.StartMissionUpdatesWatch)
    public startMissionUpdatesWatch(context: StateContext<TacticalStateModel>, { missionId }: TacticalActions.StartMissionUpdatesWatch) {
        this.missionUpdateSubscription = merge(
            this.tacticalApi.startMissionUpdatesWatch(this.store.selectSnapshot(AuthState.userId), missionId),
            this.notificationsApiService
                .startIncomingNotificationsWatch<
                    MissionPlanningNotificationType.MissionPlanningEvent,
                    WebAppMissionPlanningNotification["payload"]
                >(this.store.selectSnapshot(AuthState.userId), [MissionPlanningNotificationType.MissionPlanningEvent])
                .pipe(
                    RxjsUtils.filterFalsy(),
                    filter(
                        ({ payload: { target } }) =>
                            target.id === missionId && target.type === MissionPlanningNotificationTargetType.Mission
                    ),
                    tap(({ payload: { translationId, args } }) =>
                        context.dispatch(
                            new TacticalActions.ShowNotification(this.translationHelperService.selectSystemTranslation(translationId, args))
                        )
                    )
                )
        ).subscribe(() => context.dispatch(new TacticalActions.GetMissionWithRoute(missionId)));
    }

    @Action(TacticalActions.StopMissionUpdatesWatch)
    public stopMissionUpdatesWatch() {
        this.missionUpdateSubscription?.unsubscribe();
    }

    @Action(TacticalActions.StartMissionsUpdatesWatch)
    public startMissionsUpdatesWatch(context: StateContext<TacticalStateModel>) {
        this.missionsUpdateSubscription = this.notificationsApiService
            .startIncomingNotificationsWatch<
                MissionPlanningNotificationType.MissionPlanningEvent,
                WebAppMissionPlanningNotification["payload"]
            >(this.store.selectSnapshot(AuthState.userId), [MissionPlanningNotificationType.MissionPlanningEvent])
            .pipe(
                RxjsUtils.filterFalsy(),
                switchMap(({ payload: { target, translationId, args } }) => {
                    if (target.type === MissionPlanningNotificationTargetType.Mission) {
                        context.dispatch(
                            new TacticalActions.ShowNotification(this.translationHelperService.selectSystemTranslation(translationId, args))
                        );
                    }

                    const missionOnList = context.getState().acceptedMissions.find((mission) => mission.missionId === target.id);
                    if (!missionOnList) {
                        return context.dispatch(TacticalActions.GetAcceptedMissions);
                    }

                    context.patchState({ isMissionDataProcessing: true, tacticalError: undefined });

                    return this.tacticalApi.getMissionDetails(missionOnList.missionId).pipe(
                        tap((missionUpdate) => {
                            context.patchState({ isMissionDataProcessing: false });

                            const updatedMissions = context
                                .getState()
                                .acceptedMissions.map((mission) =>
                                    missionUpdate.missionId === mission.missionId ? missionUpdate : mission
                                );
                            context.patchState({ acceptedMissions: updatedMissions });

                            const activeMission = context.getState().missionData;
                            if (activeMission && activeMission.missionId === missionUpdate.missionId) {
                                context.patchState({ missionData: missionUpdate });
                            }
                        }),
                        catchError((error) => {
                            context.patchState({
                                tacticalError: error,
                                isMissionDataProcessing: false,
                            });

                            return EMPTY;
                        })
                    );
                })
            )
            .subscribe();
    }

    @Action(TacticalActions.StopMissionsUpdatesWatch)
    public stopMissionsUpdatesWatch() {
        this.missionsUpdateSubscription?.unsubscribe();
    }

    @Action(TacticalActions.StartSectionDeactivationEventWatch)
    public startTrackerUpdatesWatch(context: StateContext<TacticalStateModel>) {
        this.sectionDeactivationEventsWatchSubscription = this.tacticalApi
            .startSectionDeactivatedEventWatch(this.store.selectSnapshot(AuthState.userId))
            .subscribe((response) => {
                if (!response) {
                    return;
                }
                const { deactivatedSectionsInfo } = context.getState();

                const deactivatedSection: DeactivatedSectionsInfo = {
                    trackerId: response.trackerIdentifier,
                    sectionIndex: response.sectionIndex,
                };

                const deactivatedSectionsInfoUpdated = deactivatedSectionsInfo
                    ? ArrayUtils.unique([...deactivatedSectionsInfo, deactivatedSection], (item) => item.trackerId)
                    : [deactivatedSection];

                context.patchState({ deactivatedSectionsInfo: deactivatedSectionsInfoUpdated });
            });
    }

    @Action(TacticalActions.StopSectionDeactivationEventWatch)
    public stopSectionDeactivationEventWatch(context: StateContext<TacticalStateModel>) {
        this.sectionDeactivationEventsWatchSubscription?.unsubscribe();
        context.patchState({ deactivatedSectionsInfo: undefined });
    }

    @Action(TacticalActions.StartFlightPositionUpdatesWatch)
    public startFlightPositionUpdatesWatch(
        context: StateContext<TacticalStateModel>,
        { bbox }: TacticalActions.StartFlightPositionUpdatesWatch
    ) {
        this.stopFlightPositionUpdates$?.next();
        this.stopFlightPositionUpdates$?.complete();
        this.stopFlightPositionUpdates$ = new Subject<void>();

        return this.tacticalApi.startFlightPositionUpdatesWatch(bbox).pipe(
            takeUntil(this.stopFlightPositionUpdates$),
            tap((flightPositionUpdate) => {
                this.flightPositionUpdater.addFlightPositionUpdate(flightPositionUpdate);
            })
        );
    }

    @Action(TacticalActions.StopFlightPositionUpdatesWatch)
    public stopFlightPositionUpdatesWatch() {
        this.stopFlightPositionUpdates$?.next();
        this.stopFlightPositionUpdates$?.complete();
    }

    @Action(TacticalActions.StartFlightViolationsUpdatesWatch)
    public startFlightViolationsUpdatesWatch() {
        this.flightViolationsUpdatesSubscription?.unsubscribe();

        this.flightViolationsUpdatesSubscription = this.tacticalApi
            .startViolationUpdatesWatch(this.store.selectSnapshot(AuthState.userId))
            .pipe(
                filter(Boolean),
                tap((flightViolationUpdate) => {
                    this.flightViolationUpdater.addFlightViolationUpdate(flightViolationUpdate);
                })
            )
            .subscribe();
    }

    @Action(TacticalActions.StopFlightViolationsUpdatesWatch)
    public stopFlightViolationsUpdatesWatch() {
        this.flightViolationsUpdatesSubscription?.unsubscribe();
    }

    @Action(TacticalActions.GetNearbyMissions, { cancelUncompleted: true })
    public getNearbyMissions(context: StateContext<TacticalStateModel>, { settings }: TacticalActions.GetNearbyMissions) {
        context.patchState({ tacticalError: undefined, isNearbyMissionDataProcessing: true });

        return this.tacticalApi.getNearbyMissions(settings).pipe(
            tap((nearbyMissions) => {
                context.patchState({
                    tacticalError: undefined,
                    isNearbyMissionDataProcessing: false,
                    nearbyMissions,
                });
            }),
            catchError((error) => {
                context.patchState({
                    tacticalError: error,
                    isNearbyMissionDataProcessing: false,
                    nearbyMissions: [],
                });

                return EMPTY;
            })
        );
    }

    @Action(TacticalActions.ClearNearbyMissions)
    public clearNearbyMissions(context: StateContext<TacticalStateModel>) {
        context.patchState({ nearbyMissions: [] });
    }

    @Action(TacticalActions.ConfirmTrackerReadings)
    public confirmTrackerReadings(
        context: StateContext<TacticalStateModel>,
        { missionId, trackerIdentifier }: TacticalActions.ConfirmTrackerReadings
    ) {
        return this.tacticalApi.confirmTrackerReadings(missionId, trackerIdentifier).pipe(
            tap(() =>
                context.patchState({
                    tacticalError: undefined,
                })
            ),
            catchError((error) => {
                context.patchState({
                    tacticalError: error,
                });

                return EMPTY;
            })
        );
    }

    @Action(TacticalActions.GetHemsData)
    public getHemsData(context: StateContext<TacticalStateModel>) {
        return this.tacticalApi.getHemsData().pipe(
            tap((hemsData) =>
                context.patchState({
                    hemsEventData: hemsData,
                })
            )
        );
    }

    @Action(TacticalActions.ClearHemsData)
    public clearHemsData(context: StateContext<TacticalStateModel>) {
        context.patchState({
            hemsEventData: [],
        });
    }

    @Action(TacticalActions.GetMissionPlanData, { cancelUncompleted: true })
    public getMissionPlanData(context: StateContext<TacticalStateModel>, { planId }: TacticalActions.GetMissionPlanData) {
        context.patchState({ tacticalError: undefined, isMissionDataProcessing: true });
        const operatorId = this.store.selectSnapshot(OperatorContextState.selectedContext)?.id;

        return this.missionApi.getMissionData(planId, false, operatorId).pipe(
            tap((missionPlanDataAndCapabilities) => {
                context.patchState({
                    tacticalError: undefined,
                    isMissionDataProcessing: false,
                    missionPlanData: missionPlanDataAndCapabilities,
                });
            }),
            catchError((error) => {
                context.patchState({
                    tacticalError: error,
                    isMissionDataProcessing: false,
                    missionPlanData: undefined,
                });

                return EMPTY;
            })
        );
    }

    @Action(TacticalActions.GetMissionPlanAnalysis, { cancelUncompleted: true })
    public getMissionPlanAnalysis(context: StateContext<TacticalStateModel>) {
        const { missionData } = context.getState();

        if (!missionData) {
            return;
        }

        context.patchState({ tacticalError: undefined, isMissionDataProcessing: true });

        return this.missionPlanVerificationApi.getCurrentMissionPlanVerification(missionData.planId).pipe(
            switchMap((missionPlanAnalysisStatus) => {
                context.patchState({
                    missionPlanAnalysisStatus,
                });

                if (!(missionPlanAnalysisStatus.airspace.zoneIssues && Object.keys(missionPlanAnalysisStatus.airspace.zoneIssues).length)) {
                    return of(null);
                }

                const waypoints = MissionUtils.convertRouteToWaypoints(missionData.route);
                const timeRange = MissionUtils.getTimeRangeFromWaypointsWithSection(waypoints);
                const upperLimit = missionData.route.sections.reduce((result, section) => {
                    const ceiling = section.segment?.safetyArea.volume.ceiling ?? section.flightZone?.safetyArea.volume.ceiling ?? 0;

                    return Math.max(result, ceiling);
                }, 0);

                return context.dispatch(
                    new TacticalActions.SearchAirspaceElements({
                        designators: Object.keys(missionPlanAnalysisStatus.airspace.zoneIssues),
                        scope: {
                            startTime: timeRange.min,
                            endTime: timeRange.max,
                            upperLimit,
                        },
                        includeInformation: true,
                    })
                );
            }),
            catchError((error) => {
                context.patchState({
                    tacticalError: error,
                    missionPlanAnalysisStatus: undefined,
                });

                return EMPTY;
            }),
            finalize(() => {
                context.patchState({
                    isMissionDataProcessing: false,
                });
            })
        );
    }

    @Action(TacticalActions.SearchAirspaceElements, { cancelUncompleted: true })
    public searchAirspaceElements(context: StateContext<TacticalStateModel>, { options }: TacticalActions.SearchAirspaceElements) {
        context.patchState({ tacticalError: undefined, isMissionDataProcessing: true });

        return this.missionApi.searchAirspaceElements(options).pipe(
            tap((elements) => {
                this.store.dispatch(new GeoZonesActions.SetCustomElements(elements));
            }),
            catchError((error) => {
                context.patchState({
                    tacticalError: error,
                    isMissionDataProcessing: false,
                });

                return EMPTY;
            })
        );
    }

    @Action(TacticalActions.SetNearbyMissionViewSettings)
    public setNearbyMissionViewSettings(
        context: StateContext<TacticalStateModel>,
        { settings }: TacticalActions.SetNearbyMissionViewSettings
    ) {
        context.patchState({
            nearbyMissionsSettings: settings,
        });
    }

    @Action(TacticalActions.UpdateAdditionalInformation)
    public updateAdditionalInformation(
        context: StateContext<TacticalStateModel>,
        { planId, information }: TacticalActions.UpdateAdditionalInformation
    ) {
        context.patchState({ tacticalError: undefined, areAdditionalInformationProcessing: true });

        return this.missionApi.updateAdditionalInformation(planId, information).pipe(
            tap(() => {
                context.patchState({
                    tacticalError: undefined,
                });
            }),
            catchError(() => {
                context.patchState({
                    tacticalError: {
                        type: TacticalErrorType.CannotUpdateAdditionalInformation,
                    },
                });

                return EMPTY;
            }),
            finalize(() => {
                context.patchState({
                    areAdditionalInformationProcessing: false,
                });
            })
        );
    }

    @Action(TacticalActions.StartMissionStatusUpdateWatch)
    public startMissionStatusUpdateWatch(context: StateContext<TacticalStateModel>) {
        this.stopMissionStatusWatchSubscription?.unsubscribe();

        this.stopMissionStatusWatchSubscription = this.notificationsApiService
            .startIncomingNotificationsWatch<
                MissionPlanningNotificationType.MissionPlanningEvent,
                WebAppMissionPlanningNotification["payload"]
            >(this.store.selectSnapshot(AuthState.userId), [MissionPlanningNotificationType.MissionPlanningEvent])
            .pipe(
                RxjsUtils.filterFalsy(),
                filter(
                    ({ payload: { target } }) =>
                        target.type === MissionPlanningNotificationTargetType.Plan && target.id === context.getState().missionData?.planId
                )
            )
            .subscribe(() => {
                const missionId = context.getState().missionData?.missionId;
                if (missionId) {
                    context.dispatch(new TacticalActions.GetMissionWithRoute(missionId));
                }
            });
    }

    @Action(TacticalActions.StopMissionStatusUpdateWatch)
    public stopMissionStatusUpdateWatch() {
        this.stopMissionStatusWatchSubscription?.unsubscribe();
    }

    @Action(TacticalActions.MissionPlanMessageAcknowledgement)
    public missionPlanMessageAcknowledgement(context: StateContext<TacticalStateModel>) {
        const { missionPlanAnalysisStatus } = context.getState();

        if (!missionPlanAnalysisStatus?.systemVerificationId) {
            return;
        }
        context.patchState({ isMissionDataProcessing: true });

        return this.missionApi.acknowledgePlanMessage(missionPlanAnalysisStatus?.systemVerificationId).pipe(
            switchMap(() => context.dispatch(new TacticalActions.GetMissionPlanData(missionPlanAnalysisStatus.planId))),
            catchError((error) => {
                context.patchState({
                    tacticalError: error,
                });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isMissionDataProcessing: false }))
        );
    }

    @Action(TacticalActions.CleanupMissionPlanData)
    public cleanupMissionPlanData(context: StateContext<TacticalStateModel>) {
        context.patchState({
            missionPlanData: undefined,
        });
    }

    @Action(TacticalActions.CleanupTacticalState)
    public cleanupTacticalState(context: StateContext<TacticalStateModel>) {
        context.patchState({ ...defaultState, activeMissions: context.getState().activeMissions });
    }

    @Action(TacticalActions.GetMissionEmergency, { cancelUncompleted: true })
    public getMissionEmergency(context: StateContext<TacticalStateModel>, { missionId }: TacticalActions.GetMissionEmergency) {
        context.patchState({ tacticalError: undefined, isEmergencyProcessing: true });

        return this.tacticalApi.getMissionEmergency(missionId).pipe(
            tap((missionEmergency) => {
                context.patchState({
                    isEmergencyProcessing: false,
                    missionEmergency,
                });
            }),
            catchError((error) => {
                context.patchState({
                    tacticalError: error,
                    isEmergencyProcessing: false,
                    missionEmergency: undefined,
                });

                return EMPTY;
            })
        );
    }

    @Action(TacticalActions.ReportMissionEmergency)
    public reportMissionEmergency(context: StateContext<TacticalStateModel>, { emergencyType }: TacticalActions.ReportMissionEmergency) {
        context.patchState({ tacticalError: undefined, isEmergencyProcessing: true });
        const { missionData } = context.getState();

        if (!missionData?.missionId) {
            return;
        }

        return this.tacticalApi.reportMissionEmergency(missionData.missionId, emergencyType).pipe(
            tap((missionEmergency) => {
                context.patchState({
                    missionEmergency,
                    isEmergencyProcessing: false,
                });
            }),
            catchError((error) => {
                context.patchState({
                    tacticalError: error,
                    isEmergencyProcessing: false,
                });

                return EMPTY;
            })
        );
    }

    @Action(TacticalActions.SelectNearbyMission)
    public selectNearbyMission(context: StateContext<TacticalStateModel>, { mission }: TacticalActions.SelectNearbyMission) {
        context.patchState({ selectedNearbyMission: mission });
    }

    @Action(TacticalActions.GetCheckinList, { cancelUncompleted: true })
    public getCheckinList(context: StateContext<TacticalStateModel>) {
        context.patchState({ tacticalError: undefined });
        const { missionData } = context.getState();
        const dtmName = missionData?.dtmNames[0];

        if (!dtmName || !missionData) {
            return;
        }

        return this.tacticalApi
            .getCheckinList({
                dtmName,
                startFrom: missionData.startTime.min,
            })
            .pipe(
                tap((checkinList) => {
                    const filteredCheckinList = checkinList.filter((checkin) =>
                        [CheckinStatus.InRealization, CheckinStatus.Submitted, CheckinStatus.Expired].includes(checkin.status)
                    );

                    context.patchState({
                        checkinList: filteredCheckinList,
                    });
                }),
                catchError((error) => {
                    context.patchState({
                        tacticalError: error,
                        checkinList: [],
                    });

                    return EMPTY;
                })
            );
    }

    @Action(TacticalActions.StartCheckinUpdatesWatch)
    public startCheckinUpdatesWatch(context: StateContext<TacticalStateModel>) {
        this.checkinEventsSubscription?.unsubscribe();
        const { missionData } = context.getState();
        const dtmName = missionData?.dtmNames[0];
        if (!dtmName) {
            return;
        }
        this.checkinEventsSubscription = this.tacticalApi.startDtmCheckinUpdatesWatch(dtmName).subscribe((checkinUpdate) => {
            const eventType = checkinUpdate?.type;
            if (!eventType) {
                return;
            }

            const checkin = { ...checkinUpdate?.payload, status: this.mapCheckinEventToCheckinStatus(eventType) };

            if (checkin.missionId === missionData?.missionId) {
                return;
            }

            const { checkinList } = context.getState();
            let updatedCheckinList;

            switch (eventType) {
                case CheckinEvent.CheckinSubmittedEvent:
                    updatedCheckinList = [...checkinList, checkin];
                    break;
                case CheckinEvent.CheckinRealizationStartedEvent:
                case CheckinEvent.CheckinExpiredEvent:
                    updatedCheckinList = checkinList.map((checkinItem) =>
                        checkinItem.id === checkinUpdate.payload.id ? checkin : checkinItem
                    );
                    break;
                case CheckinEvent.CheckinCompletedEvent:
                    updatedCheckinList = checkinList.filter((checkinItem) => checkinItem.id !== checkinUpdate.payload.id);
                    break;
            }

            context.patchState({
                checkinList: updatedCheckinList,
            });
        });
    }

    public mapCheckinEventToCheckinStatus(checkinEvent: CheckinEvent): CheckinStatus {
        return {
            [CheckinEvent.CheckinSubmittedEvent]: CheckinStatus.Submitted,
            [CheckinEvent.CheckinRealizationStartedEvent]: CheckinStatus.InRealization,
            [CheckinEvent.CheckinExpiredEvent]: CheckinStatus.Expired,
            [CheckinEvent.CheckinCompletedEvent]: CheckinStatus.Completed,
        }[checkinEvent];
    }

    @Action(TacticalActions.StopCheckinUpdatesWatch)
    public stopCheckinUpdatesWatch(context: StateContext<TacticalStateModel>) {
        this.checkinEventsSubscription?.unsubscribe();
        context.patchState({ checkinList: [] });
    }
}
