<div class="error-container grid" *ngIf="error$ | ngrxPush as error; else noErrorTemplate" [ngSwitch]="error.type">
    <dtm-ui-error
        *ngSwitchCase="SpecificPermitApplicationErrorType.CannotGetCapabilities"
        (reload)="loadCapabilities()"
        [errorMessage]="'dtmWebAppLibSpecPermApp.messages.cannotGetCapabilitiesErrorMessage' | transloco"
    >
    </dtm-ui-error>
    <dtm-ui-error
        *ngSwitchCase="SpecificPermitApplicationErrorType.CannotLoadDraft"
        [errorMessage]="'dtmWebAppLibSpecPermApp.messages.cannotGetDraftErrorMessage' | transloco"
    >
    </dtm-ui-error>
</div>

<ng-template #noErrorTemplate>
    <div class="container">
        <div class="grid">
            <div class="grid-col-3 grid-col-tablet-0 grid-col-smartphone-wide-0 grid-col-sm-0"></div>
            <dtm-ui-loader-container
                class="grid-col-6 grid-col-tablet-8"
                *ngrxLet="{
                    isProcessing: isProcessing$,
                    isSaveDraftProcessing: isSaveDraftProcessing$,
                    isOperationSearchProcessing: isOperationSearchProcessing$,
                    isOperationSelectProcessing: isOperationSelectProcessing$,
                    isProcessingCapabilities: isProcessingCapabilities$,
                    operation: operation$,
                    activeLanguage: activeLanguage$,
                    operator: operator$,
                    operationalGeometryData: operationalGeometryData$
                } as vm"
                [isShown]="vm.isProcessingCapabilities"
            >
                <dtm-ui-lib-wizard-content [wizardId]="wizardId">
                    <ng-container *dtmUiLibWizardStepContent="ApplicationCreatorWizardSteps.OperationInfo">
                        <dtm-web-app-lib-spec-perm-app-operation-info-step
                            [initialValues]="operationInfoData$ | ngrxPush"
                            [areCapabilitiesReloaded]="!vm.isProcessingCapabilities"
                            [operations]="operations$ | ngrxPush"
                            [operation]="vm.operation"
                            [operator]="vm.operator"
                            [uav]="uav$ | ngrxPush"
                            [availableUavs]="availableUavs$ | ngrxPush"
                            [flightPurposes]="flightPurposes$ | ngrxPush"
                            [isProcessing]="vm.isProcessing"
                            [isSaveDraftProcessing]="vm.isSaveDraftProcessing"
                            [isOperationSearchProcessing]="vm.isOperationSearchProcessing"
                            [isOperationSelectProcessing]="vm.isOperationSelectProcessing"
                            [isPaymentMessageVisible]="isPaymentMessageVisible$ | ngrxPush"
                            [operationalGeometryData]="vm.operationalGeometryData"
                            (operationsByNameLoad)="loadOperationsByName($event)"
                            (operationIdChange)="changeOperation($event)"
                            (operationLoad)="loadOperation($event)"
                            (operationsManualGuideForSailOpen)="openGuidePanelForSail($event)"
                            (next)="goToOperationDetailsStep($event)"
                            (saveDraft)="saveOperationInfoDraft($event)"
                            (valueChange)="markValueChange()"
                        >
                        </dtm-web-app-lib-spec-perm-app-operation-info-step>
                    </ng-container>
                    <ng-container *dtmUiLibWizardStepContent="ApplicationCreatorWizardSteps.OperationDetails">
                        <dtm-web-app-lib-spec-perm-app-operation-details-step
                            [initialValues]="operationDetailsData$ | ngrxPush"
                            [isProcessing]="vm.isProcessing"
                            [operation]="vm.operation"
                            [operationPilotCompetencies]="operationPilotCompetencies$ | ngrxPush"
                            [predefinedAreas]="predefinedAreas$ | ngrxPush"
                            [availableOperationScenarios]="availableOperationScenarios$ | ngrxPush"
                            [activeLanguage]="vm.activeLanguage"
                            [availableTrainings]="availableTrainings$ | ngrxPush"
                            [requiredPilotTrainings]="requiredPilotTrainings$ | ngrxPush"
                            [availableCrewMembers]="availableCrewMembers$ | ngrxPush"
                            [customAreaInitialViewbox]="customAreaInitialViewbox$ | ngrxPush"
                            [isSaveDraftProcessing]="vm.isSaveDraftProcessing"
                            (areaPreviewShow)="showArea($event)"
                            (pilotCompetenciesChange)="fetchRequiredPilotTrainings($event)"
                            (next)="goToStatementsStep($event)"
                            (saveDraft)="saveOperationDetailsDraft($event)"
                            (back)="goBackToStep(ApplicationCreatorWizardSteps.OperationInfo)"
                            (valueChange)="markValueChange()"
                        >
                        </dtm-web-app-lib-spec-perm-app-operation-details-step>
                    </ng-container>
                    <ng-container *dtmUiLibWizardStepContent="ApplicationCreatorWizardSteps.Statements">
                        <dtm-web-app-lib-spec-perm-app-statements-step
                            [initialValues]="statementsData$ | ngrxPush"
                            [sail]="sail$ | ngrxPush"
                            [osoStatementGroups]="osoStatementGroups$ | ngrxPush"
                            [uavInfoOperationsManualChapterNumber]="uavInfoOperationsManualChapterNumber$ | ngrxPush"
                            [operationInfoOperationsManualChapterNumber]="operationInfoOperationsManualChapterNumber$ | ngrxPush"
                            [isSaveDraftProcessing]="vm.isSaveDraftProcessing"
                            (next)="goToApplicationInfoStep($event)"
                            (saveDraft)="saveStatementsDraft($event)"
                            (back)="goBackToStep(ApplicationCreatorWizardSteps.OperationDetails)"
                            (valueChange)="markValueChange()"
                        >
                        </dtm-web-app-lib-spec-perm-app-statements-step>
                    </ng-container>
                    <ng-container *dtmUiLibWizardStepContent="ApplicationCreatorWizardSteps.ApplicationInfo">
                        <dtm-web-app-lib-spec-perm-app-application-info-step
                            [initialValues]="applicationInfoData$ | ngrxPush"
                            [isSaveDraftProcessing]="vm.isSaveDraftProcessing"
                            [isProcessing]="vm.isProcessing"
                            (applicationGenerate)="generateApplication($event)"
                            (saveDraft)="saveApplicationInfoDraft($event)"
                            (back)="goBackToStep(ApplicationCreatorWizardSteps.Statements)"
                            (valueChange)="markValueChange()"
                        >
                        </dtm-web-app-lib-spec-perm-app-application-info-step>
                    </ng-container>
                </dtm-ui-lib-wizard-content>
            </dtm-ui-loader-container>
            <div class="grid-col-3 grid-col-tablet-4 info-container">
                <dtm-web-app-lib-spec-perm-app-manual
                    *ngrxLet="guidePanelSail$ as guidePanelSail"
                    (operationsManualGuideOpen)="setGuidePanelVisibility(true)"
                    (osoGuideOpen)="openGuidePanelForSail(guidePanelSail)"
                    (operationsManualTemplateDownload)="downloadOperationsManualTemplate()"
                    (attorneyPowerTemplateDownload)="downloadAttorneyPowerTemplate()"
                ></dtm-web-app-lib-spec-perm-app-manual>
            </div>
        </div>
        <div
            class="guide-panel"
            *ngrxLet="isGuidePanelVisible$ as isGuidePanelVisible"
            [@foldFarRight]="isGuidePanelVisible ? 'open' : 'closed'"
        >
            <header>
                <button
                    type="button"
                    class="button-icon close-button"
                    (click)="setGuidePanelVisibility(false)"
                    [attr.tabindex]="isGuidePanelVisible ? undefined : -1"
                >
                    <dtm-ui-icon name="close"></dtm-ui-icon>
                </button>
                <h2>{{ "dtmWebAppLibSpecPermApp.guideHeaderLabel" | transloco }}</h2>
            </header>
            <dtm-web-app-lib-opsman-guide
                class="guide-content"
                [guideChapterItems]="guideChapterItems$ | ngrxPush"
                [isGuidePanelVisible]="isGuidePanelVisible"
            ></dtm-web-app-lib-opsman-guide>
        </div>
    </div>
</ng-template>
