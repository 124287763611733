import { ChangeDetectionStrategy, Component, Input, Output } from "@angular/core";
import { DeviceSize, DeviceSizeService } from "@dtm-frontend/shared/ui";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { NavigationAccuracy, Tracker, UavSetup } from "../../models/uav.models";

interface UavSetupsComponentState {
    setups: UavSetup[];
    trackers: Tracker[];
    navigationAccuracyItems: NavigationAccuracy[];
    selectedSetup: UavSetup | undefined;
    isNarrowMode: boolean;
}

@UntilDestroy()
@Component({
    selector: "dtm-uav-lib-uav-setups",
    templateUrl: "./uav-setups.component.html",
    styleUrls: ["./uav-setups.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class UavSetupsComponent {
    @Input({ required: true }) public set setups(value: UavSetup[]) {
        this.localStore.patchState({ setups: value, selectedSetup: value[0] });
    }

    @Input({ required: true }) public set trackers(value: Tracker[] | undefined) {
        this.localStore.patchState({ trackers: value ?? [] });
    }

    @Input({ required: true }) public set navigationAccuracyItems(value: NavigationAccuracy[] | undefined) {
        this.localStore.patchState({ navigationAccuracyItems: value ?? [] });
    }

    @Input() public set selectedSetupId(value: string | undefined) {
        this.localStore.patchState(({ setups }) => {
            const selectedSetupIndex = setups.findIndex((setup) => setup.id === value);
            const selectedSetup = setups[Math.max(selectedSetupIndex, 0)];

            return { selectedSetup };
        });
    }

    @Output() public setupSelectionChange = this.localStore.selectByKey("selectedSetup").pipe(RxjsUtils.filterFalsy());

    protected readonly setups$ = this.localStore.selectByKey("setups");
    protected readonly trackers$ = this.localStore.selectByKey("trackers");
    protected readonly navigationAccuracyItems$ = this.localStore.selectByKey("navigationAccuracyItems");
    protected readonly selectedSetup$ = this.localStore.selectByKey("selectedSetup").pipe(RxjsUtils.filterFalsy());
    protected readonly isNarrowMode$ = this.localStore.selectByKey("isNarrowMode");

    constructor(
        private readonly localStore: LocalComponentStore<UavSetupsComponentState>,
        private readonly deviceSizeService: DeviceSizeService
    ) {
        this.localStore.setState({
            setups: [],
            trackers: [],
            navigationAccuracyItems: [],
            selectedSetup: undefined,
            isNarrowMode: false,
        });

        this.observeOnDeviceResize();
    }

    protected showSetupPreview(setup: UavSetup) {
        this.localStore.patchState({ selectedSetup: setup });
    }

    private observeOnDeviceResize() {
        this.deviceSizeService
            .getSizeObservable(DeviceSize.Smartphone, DeviceSize.SmartphoneWide)
            .pipe(untilDestroyed(this))
            .subscribe((isSmartphoneDevice) => {
                this.localStore.patchState({
                    isNarrowMode: isSmartphoneDevice,
                });
            });
    }
}
