import { HttpErrorResponse, HttpStatusCode } from "@angular/common/http";
import { CylinderEntity, EntityEditorConstraints, MapEntityType, MapUtils } from "@dtm-frontend/shared/map/cesium";
import { AirspaceElementStatus, AirspaceElementsInfo, GeoZoneType, InfoZoneType, Reservation } from "@dtm-frontend/shared/map/geo-zones";
import {
    AuthorityAcceptationItem,
    AuthorityAcceptationStatus,
    CaaPermitData,
    FlightTerminationSystem,
    FormalJustification,
    GroundRiskBufferType,
    MissionCategory,
    MissionPhaseExtensions,
    MissionPlanDataAndCapabilities,
    MissionPlanOperationCategoryOption,
    MissionPlanOperationCategoryOptionIssue,
    MissionPlanRemarks,
    MissionPlanSpecificPermitType,
    MissionPlanStatus,
    MissionProcessingPhase,
    MissionType,
    MissionUAVSetup,
    SoraSettings,
} from "@dtm-frontend/shared/mission";
import { FailSafe } from "@dtm-frontend/shared/uav";
import {
    ItineraryEditorType,
    MissionPlanRoute,
    MissionPlanRouteFlightZone,
    MissionPlanRouteSection,
    MissionPlanRouteSegment,
    PermitType,
    RouteStats,
    SectionElementResponseBody,
    Waypoint,
} from "@dtm-frontend/shared/ui";
import {
    DateUtils,
    FunctionUtils,
    ISO8601TimeDuration,
    METERS_IN_KILOMETER,
    ObjectUtils,
    SECONDS_IN_HOUR,
    SECONDS_IN_MINUTE,
} from "@dtm-frontend/shared/utils";
import { Polygon } from "@turf/helpers";
import { PansaUtmLink } from "../../pilot-profile/services/pilot-profile.models";
import { CylinderItineraryEntity, ItineraryContent, Polyline3DItineraryEntity, PrismItineraryEntity } from "../models/itinerary.model";
import {
    ActivePermit,
    AuthorityType,
    Elevation,
    FlightPurpose,
    FlightSpeedType,
    FormalJustificationRequestPayload,
    FormalJustificationResponseBody,
    ItineraryEditorFormData,
    MissionCapabilitiesResponseBodyPreferencesEntity,
    MissionCapabilitiesResponseBodyUAVEntity,
    MissionError,
    MissionErrorType,
    MissionPilot,
    MissionPlanAndCapabilities,
    MissionPlanAssistedItineraryEntity,
    MissionPlanCapabilitiesResponseBodyCategoryEntity,
    MissionPlanCapabilitiesResponseBodyPlanEntity,
    MissionPlanContent,
    MissionPlanCustomItineraryEntity,
    MissionPlanFlightPurposeOption,
    MissionPlanItineraryConstraints,
    MissionPlanItineraryEntityType,
    MissionPlanItineraryOptions,
    MissionPlanItineraryWithRoute,
    MissionPlanItineraryWithRouteResponseBody,
    MissionPlanList,
    MissionPlanListQuery,
    MissionPlanOperationCategory,
    MissionPlanStandardItineraryEntity,
    MissionPlanningPreferences,
    MissionStatus,
    MissionUAV,
    PermitLocation,
    PublicMissionPlanCapabilities,
    PublicMissionPlanData,
    RegulationExemptions,
    RegulationExemptionsLimits,
    WaypointEntity,
} from "../models/mission.model";

interface MissionPlanListResponseBodyContent
    extends Omit<
        MissionPlanListResponseBodyContentEntity,
        "missionSubmittedAt" | "flightStartAtMin" | "flightStartAtMax" | "flightFinishAtMin" | "flightFinishAtMax"
    > {
    missionSubmittedAt: string;
    flightStartAtMin: string;
    flightStartAtMax: string;
    flightFinishAtMin: string;
    flightFinishAtMax: string;
}

export interface MissionPlanListResponseBody {
    totalPages: number;
    totalElements: number;
    size: number;
    content: MissionPlanListResponseBodyContent[];
    number: number;
    sort?: Sort;
    pageable?: Pageable;
    numberOfElements?: number;
    first?: boolean;
    last?: boolean;
    empty?: boolean;
}

export type MissionPlanDataResponseBody = {
    plan: MissionPlanListResponseBodyContentEntity & MissionPlanCapabilitiesResponseBodyPlanEntity;
} & Omit<MissionPlanCapabilitiesResponseBody, "plan">;

interface MissionPlanListResponseBodyContentEntity {
    id: string;
    missionId: string;
    thumbnail: string;
    created: Date;
    modified: Date;
    name: string;
    status: MissionPlanStatus;
    phase: MissionProcessingPhase;
    abilities: MissionPlanContentActionsResponse;
    specificCaaPermitId: string;
    permitBusinessId: string;
    uavId: string;
    uavName?: string;
    uavSetupName: string;
    pilotId: string;
    pilotName?: string;
    operatorId: string;
    operatorName?: string;
    missionSubmittedAt: Date;
    flightStartAtMin: Date;
    flightStartAtMax: Date;
    flightFinishAtMin: Date;
    flightFinishAtMax: Date;
    flightType: MissionType;
    operationCategoryType: MissionCategory;
    flightPurposeCode: string;
    flightPurposeComment: string;
    soraSail: number;
    description: string;
    missionStatus: MissionStatus;
    uncertainConditions: boolean;
    remarks?: RemarksResponse;
}

interface MissionPlanContentActionsResponse {
    editable: boolean;
    deletable: boolean;
    cloneable: boolean;
    submittable: boolean;
    cancelable: boolean;
}

interface RemarksResponse {
    formalJustification?: FormalJustificationResponseBody;
    dtm?: AuthorityAcceptation;
    utm?: AuthorityAcceptation;
    dss?: AuthorityAcceptation;
    partialAcceptations?: PartialAuthorityAcceptationResponse[];
}

export interface PartialAuthorityAcceptationResponse {
    acknowledgedByUserAt: string;
    authorityType: string;
    authorityUnitId: string;
    authorityUnitName: string;
    authorityUnitType: string;
    comment: string;
    status: AuthorityAcceptationStatus;
    updatedAt: string;
}

interface AuthorityAcceptation {
    acceptationStatus: AuthorityAcceptationStatus;
    comment: string;
    updatedAt: string;
    confirmedByUser: boolean;
}

interface Sort {
    empty: boolean;
    sorted: boolean;
    unsorted: boolean;
}

interface Pageable {
    size: number;
    page: number;
    sort: string;
}

export interface MissionPlanRouteResponseBody {
    routeId: string;
    planId: string;
    missionId: string;
    dtmNames?: string[];
    pathBased?: boolean;
    dtmOnly?: boolean;
    estimatedDistance?: number;
    flightAreaSize?: number;
    groundRiskAreaSize?: number;
    adjacentAreaSize?: number;
    operationAreaSize?: number;
    elevations: Elevation[];
    sections: SectionElementResponseBody[];
    stats?: RouteStats;
}

export interface MissionPlanRoutesResponseBody {
    items: MissionPlanRouteResponseBody[];
}

export interface MissionCapabilitiesResponseBody {
    operator: MissionCapabilitiesResponseBodyOperatorEntity;
    preferences: MissionCapabilitiesResponseBodyPreferencesEntity;
    additionalCrewMembers: string[];
}

interface MissionCapabilitiesResponseBodyOperatorEntity {
    id: string;
    name: string;
    pilots: MissionCapabilitiesResponseBodyPilotEntity[];
    uavs: MissionCapabilitiesResponseBodyUAVEntity[];
    regulationExemptions: MissionCapabilitiesResponseBodyRegulationExemptions[];
}

interface MissionCapabilitiesResponseBodyRegulationExemptions {
    bvlos: boolean;
    pilots: { id: string; name: string; competencies: string[] }[];
    scenarioName: string;
    uavs: MissionCapabilitiesResponseBodyUAVEntity[];
    limits: RegulationExemptionsLimits;
}

export interface MissionCapabilitiesResponseBodyPilotEntity {
    id: string;
    name: string;
}

export interface FlightPurposeResponseBody {
    flightPurposes: FlightPurpose[];
}

interface MissionsPlanListRequestQuery {
    flightType?: MissionType;
    freeText?: string;
    operatorId?: string;
    missionStatus?: string[];
    processingPhase?: MissionProcessingPhase | MissionProcessingPhase[];
    planStatus?: MissionPlanStatus | MissionPlanStatus[];
    flightPurpose?: string | string[];
    flightDateFrom?: string;
    flightDateTo?: string;
    size?: number;
    page?: number;
    sort?: string;
}

export interface MissionPlanCapabilitiesResponseBody {
    plan: MissionPlanCapabilitiesResponseBodyPlanEntity;
    operationCategories?: MissionPlanCapabilitiesResponseBodyCategoryEntity[];
    operatorHasLightUasCertificate: boolean;
    flightPurposes?: {
        id: string;
        name: string;
        description: string;
        codename: string;
        loadWeightRequired: boolean;
        commentRequired: boolean;
        emergency: boolean;
    }[];
    options?: {
        [key in ItineraryEditorType]: { suggested: boolean };
    };
}

export interface PublicMissionPlanResponseBody {
    id: string;
    missionId?: string;
    capabilities?: PublicMissionPlanCapabilities;
    flightStartAtMin?: string;
    flightFinishAtMax?: string;
    route?: {
        id: string;
        dtmNames?: string[];
        pathBased?: boolean;
        dtmOnly?: boolean;
        estimatedDistance?: number;
        sections: SectionElementResponseBody[];
    };
}

interface ActivePermitUavResponseBody {
    disabled: boolean;
    id: string;
}

interface ActivePermitPilotResponseBody {
    disabled: boolean;
    id: string;
    displayName: string;
}

interface ActivePermitResponseBody {
    businessId: string;
    flightPurposes: string;
    flightType: MissionType;
    id: string;
    name: string;
    operatorId: string;
    type: PermitType;
    uavSerialNumbers: string[];
    validityPeriodFinish: Date;
    validityPeriodStart: Date;
    operator: {
        id: string;
        name: string;
        uavs: ActivePermitUavResponseBody[];
        pilots: ActivePermitPilotResponseBody[];
    };
    location?: PermitLocation;
}

export interface ActivePermitListResponseBody {
    permits: ActivePermitResponseBody[];
}

export interface FlightTerminationSystemResponseBody {
    available: boolean;
    required: boolean;
    issues: string[];
}

export interface CaaPermitDataResponseBody {
    id: string;
    soraSail: number;
    flight: {
        flightType: MissionType;
        purposeComment: string;
        dangerousGoods: boolean;
        maxSpeed: number;
    };
    operationArea: {
        maxHeight: number;
        size: number;
        maxRadius: number;
        maxWidth: number;
        maxDeclaredHeight: number;
    };
    flightArea: {
        estimatedDistance: number;
        maxRadius: number;
        maxDeclaredHeight: number;
        maxHeight: number;
        maxWidth: number;
        size: number;
    };
    safetyArea: {
        height: number;
        width: number;
    };
    adjacentArea: {
        bufferHorizontal: number;
        height: number;
        size: number;
    };
    groundRisk: {
        areaSize: number;
        finalGrl: number;
        adjFinalGrl: number;
        buffer?: number;
        bufferType: GroundRiskBufferType | null;
    };
    airspace: {
        operationalControlled: false;
        operationalUncontrolled: true;
        adjacentControlled: true;
        adjacentUncontrolled: true;
    };
    aroundObstacleOnly: false;
    segregatedAirspaceOnly: false;
    version: number;
    uav: {
        maxFlightSpeed: number;
        maxDroneWidth: number;
        flightSpeedLimit: number;
        failSafe: FailSafe[];
        geofencing: boolean;
        geocage: boolean;
        takeOffMass: number;
    };
    sora?: SoraSettings;
}

function getSpeedInMetersPerSecond(speed: number, speedType: FlightSpeedType): number {
    return speed * (speedType === FlightSpeedType.MetersPerSecond ? 1 : METERS_IN_KILOMETER / SECONDS_IN_HOUR);
}

export function convertMissionPlanListQuery(query?: MissionPlanListQuery): MissionsPlanListRequestQuery {
    if (!query) {
        return {};
    }

    let flightType: MissionType | undefined;
    if (Array.isArray(query.flightType)) {
        // NOTE: backend expects only one flight type, undefined is used for all
        flightType = query.flightType.length === 1 ? query.flightType[0] : undefined;
    } else {
        flightType = query.flightType ?? undefined;
    }

    const requestQuery: MissionsPlanListRequestQuery = {
        flightType,
        freeText: query.textSearch ?? undefined,
        processingPhase: query.processingPhase ?? undefined,
        flightDateFrom: query.flightDateFrom ? new Date(query.flightDateFrom).toISOString() : undefined,
        page: query.page,
        size: query.pageSize,
        sort: query.sort ?? undefined,
        flightPurpose: query.flightPurpose ?? undefined,
        operatorId: query.operatorId ?? undefined,
    };

    if (query.flightDateTo) {
        const dateTo = new Date(query.flightDateTo);
        dateTo.setDate(dateTo.getDate() + 1);
        requestQuery.flightDateTo = dateTo.toISOString();
    }

    return ObjectUtils.removeNullishProperties(requestQuery);
}

export function convertMissionErrorResponse(errorResponse: HttpErrorResponse, defaultErrorType?: MissionErrorType): MissionError {
    switch (errorResponse.status) {
        case HttpStatusCode.UnprocessableEntity:
        case HttpStatusCode.BadRequest:
            if (errorResponse.error?.fieldErrors?.length) {
                return {
                    type: MissionErrorType.Fields,
                    fields: errorResponse.error?.fieldErrors,
                };
            }
            break;
        case HttpStatusCode.Forbidden:
            return { type: MissionErrorType.NotAuthorized };
    }

    return { type: defaultErrorType ?? MissionErrorType.Unknown };
}

export function convertMissionPlanOperationCategoryToOption(
    category: MissionPlanOperationCategory
): MissionPlanOperationCategoryOption | undefined {
    const { specificCaaPermitId } = category;
    if (category.type === MissionCategory.Open) {
        return {
            type: MissionCategory.Open,
            scenarioName: category.scenarioName,
            isDisabled: false,
            issues: [],
        };
    }

    if (category.type === MissionCategory.Specific) {
        switch (category.specificPermit) {
            case MissionPlanSpecificPermitType.Individual:
                return {
                    type: MissionCategory.Specific,
                    specificPermitType: MissionPlanSpecificPermitType.Individual,
                    specificCaaPermitId,
                    isDisabled: false,
                };
            case MissionPlanSpecificPermitType.Luc:
                return {
                    type: MissionCategory.Specific,
                    specificPermitType: MissionPlanSpecificPermitType.Luc,
                    shouldSkipSora: category.specificLucSkipSora,
                    isDisabled: false,
                };
            case MissionPlanSpecificPermitType.Sts:
                return {
                    type: MissionCategory.Specific,
                    specificPermitType: MissionPlanSpecificPermitType.Sts,
                    scenarioName: category.scenarioName,
                    isDisabled: false,
                    issues: [],
                };
        }
    }

    return undefined;
}

export function convertMissionPlanResponseBodyToMissionPlanAndCapabilities(
    response: MissionPlanCapabilitiesResponseBody
): MissionPlanAndCapabilities {
    const operationCategories = (response.operationCategories ?? []).map((category): MissionPlanOperationCategoryOption => {
        const issues = category.issues.map(
            (issue): MissionPlanOperationCategoryOptionIssue => ({
                code: issue.id,
                args: issue.args,
            })
        );

        if (category.type === MissionCategory.Open) {
            return {
                type: category.type,
                scenarioName: category.name,
                issues,
                isDisabled: category.disabled,
                isHidden: category.hidden,
            };
        } else {
            return {
                type: MissionCategory.Specific,
                scenarioName: category.name,
                specificPermitType: MissionPlanSpecificPermitType.Sts,
                issues,
                isDisabled: category.disabled,
                isHidden: category.hidden,
            };
        }
    });

    operationCategories.push({
        type: MissionCategory.Specific,
        specificPermitType: MissionPlanSpecificPermitType.Individual,
        isDisabled: false,
        isHidden: false,
    });

    if (response.operatorHasLightUasCertificate) {
        const lucCategoryOption: MissionPlanOperationCategoryOption = {
            type: MissionCategory.Specific,
            specificPermitType: MissionPlanSpecificPermitType.Luc,
            shouldSkipSora: false,
            isDisabled: false,
            isHidden: false,
        };
        const lucCategorySkipSoraOption: MissionPlanOperationCategoryOption = {
            type: MissionCategory.Specific,
            specificPermitType: MissionPlanSpecificPermitType.Luc,
            shouldSkipSora: true,
            isDisabled: false,
            isHidden: false,
        };

        operationCategories.push(lucCategoryOption, lucCategorySkipSoraOption);
    }

    // TODO: DTM-828 Unlock other editors when implemented
    const enabledEditors: ItineraryEditorType[] = [ItineraryEditorType.Standard, ItineraryEditorType.Custom, ItineraryEditorType.Assisted];
    const availableEditorTypes: ItineraryEditorType[] = (response.options &&
        Object.keys(response.options).filter((name): name is ItineraryEditorType =>
            enabledEditors.includes(name as ItineraryEditorType)
        )) ?? [ItineraryEditorType.Standard];

    return {
        plan: {
            id: response.plan.id,
            status: response.plan.status,
            capabilities: {
                flightType: response.plan.capabilities.flightType,
                operatorId: response.plan.capabilities.operator,
                pilotId: response.plan.capabilities.pilot,
                uavSetupId: response.plan.capabilities.uavSetup,
                additionalCrew: response.plan.capabilities.additionalCrew,
            },
            flightPurpose: response.plan.flightPurpose,
            operationCategory: response.plan.category && convertMissionPlanOperationCategoryToOption(response.plan.category),
            information: {
                name: response.plan.information?.name,
                description: response.plan.information?.description,
                notes: response.plan.information?.notes,
            },
        },
        flightPurposes: (response.flightPurposes ?? []).map(
            (purpose): MissionPlanFlightPurposeOption => ({
                codename: purpose.codename,
                displayName: purpose.name,
                description: purpose.description,
                id: purpose.id,
                isCommentRequired: purpose.commentRequired,
                isLoadWeightRequired: purpose.loadWeightRequired,
                isEmergency: purpose.emergency,
            })
        ),
        operationCategories,
        editors: {
            options: availableEditorTypes,
            suggested:
                availableEditorTypes.find(
                    (type) => availableEditorTypes.length === 1 || (response.options && response.options[type].suggested)
                ) ?? ItineraryEditorType.Standard,
        },
    };
}

function convertAcceptationData(data?: AuthorityAcceptation): AuthorityAcceptationItem | undefined {
    return data
        ? {
              acceptationStatus: data.acceptationStatus,
              comment: data.comment,
              updatedAt: new Date(data.updatedAt),
              isConfirmedByUser: data.confirmedByUser,
          }
        : undefined;
}

export function convertActivePermitsResponseBodyToActivePermitList(activePermitList: ActivePermitResponseBody[]): ActivePermit[] {
    return activePermitList.map((permit) => ({
        ...permit,
        validityPeriodStart: new Date(permit.validityPeriodStart),
        validityPeriodFinish: new Date(permit.validityPeriodFinish),
        operator: {
            ...permit.operator,
            uavs: permit.operator.uavs.map((uav) => ({
                id: uav.id,
                isDisabled: uav.disabled,
            })),
            pilots: permit.operator.pilots.map((pilot) => ({
                id: pilot.id,
                isDisabled: pilot.disabled,
            })),
        },
        location: permit.location,
    }));
}

function convertRemarksResponse(remarksResponse: RemarksResponse | undefined): MissionPlanRemarks {
    return {
        authorityAcceptation: {
            dtm: convertAcceptationData(remarksResponse?.dtm),
            utm: convertAcceptationData(remarksResponse?.utm),
            dss: convertAcceptationData(remarksResponse?.dss),
        },
        justification: remarksResponse?.formalJustification
            ? {
                  shouldRequestForPriority: remarksResponse.formalJustification.requestForPriority,
                  reason: remarksResponse.formalJustification.reason,
                  attachmentIds: remarksResponse.formalJustification.attachmentIds ?? [],
                  attachments:
                      remarksResponse.formalJustification.attachments?.map(({ name, fileId }) => ({
                          id: fileId ?? "",
                          name: name ?? "",
                      })) ?? [],
              }
            : undefined,
        partialAcceptations:
            remarksResponse?.partialAcceptations?.map((acceptation) => ({
                acknowledgedByUserAt: acceptation.acknowledgedByUserAt ? new Date(acceptation.acknowledgedByUserAt) : undefined,
                authorityType: acceptation.authorityType,
                authorityUnitId: acceptation.authorityUnitId,
                authorityUnitName: acceptation.authorityUnitName,
                authorityUnitType: acceptation.authorityUnitType,
                comment: acceptation.comment,
                status: acceptation.status,
                updatedAt: acceptation.updatedAt ? new Date(acceptation.updatedAt) : undefined,
            })) ?? [],
    };
}

export function convertMissionPlanDataResponseBodyToMissionPlanDataAndCapabilities(
    response: MissionPlanDataResponseBody,
    operatorId?: string
): MissionPlanDataAndCapabilities {
    const { operationCategories } = convertMissionPlanResponseBodyToMissionPlanAndCapabilities(response);

    // TODO: DTM-828 Unlock other editors when implemented
    const enabledEditors = [ItineraryEditorType.Standard, ItineraryEditorType.Custom, ItineraryEditorType.Assisted];
    const availableEditorTypes: ItineraryEditorType[] = (response.options &&
        Object.keys(response.options).filter((name): name is ItineraryEditorType =>
            enabledEditors.includes(name as ItineraryEditorType)
        )) ?? [ItineraryEditorType.Standard];

    const itinerary = response.plan.itinerary
        ? {
              ...response.plan.itinerary,
              editorType: response.plan.itinerary?.editorType.toLowerCase() as ItineraryEditorType,
          }
        : undefined;

    const plan = ObjectUtils.convertStringPropertiesToDate(response.plan, [
        "flightFinishAtMin",
        "flightFinishAtMax",
        "flightStartAtMin",
        "flightStartAtMax",
        "created",
        "modified",
        "missionSubmittedAt",
    ]);

    return {
        plan: {
            ...plan,
            itinerary,
            id: response.plan.id,
            status: response.plan.status,
            category: response.plan.category && convertMissionPlanOperationCategoryToOption(response.plan.category),
            availableActions: {
                isCancelable: response.plan.abilities.cancelable,
                isCloneable: response.plan.abilities.cloneable && response.plan.operatorId === operatorId,
                isSubmittable: response.plan.abilities.submittable,
                isDeletable: response.plan.abilities.deletable,
                isEditable: response.plan.abilities.editable,
            },
            phase:
                plan.phase === MissionProcessingPhase.Accepted && plan.uncertainConditions
                    ? MissionPhaseExtensions.AcceptedConditionally
                    : plan.phase,
            route: plan.route
                ? {
                      id: plan.route.id,
                      dtmNames: plan.route.dtmNames,
                      isPathBased: plan.route.pathBased,
                      isDtmOnly: plan.route.dtmOnly,
                      modificationDate: DateUtils.convertStringDateToDateWithoutTimeZone(plan.route.modified),
                      creationDate: DateUtils.convertStringDateToDateWithoutTimeZone(plan.route.created),
                  }
                : undefined,
            remarks: convertRemarksResponse(plan.remarks),
            mission: plan.mission
                ? {
                      ...plan.mission,
                      rejectedAt: plan.mission.rejectedAt
                          ? DateUtils.convertStringDateToDateWithoutTimeZone(plan.mission.rejectedAt)
                          : undefined,
                      startAtMin: DateUtils.convertStringDateToDateWithoutTimeZone(plan.mission.startAtMin),
                      submittedAt: DateUtils.convertStringDateToDateWithoutTimeZone(plan.mission.submittedAt),
                      finishAtMax: DateUtils.convertStringDateToDateWithoutTimeZone(plan.mission.finishAtMax),
                      finishAtMin: DateUtils.convertStringDateToDateWithoutTimeZone(plan.mission.finishAtMin),
                      startAtMax: DateUtils.convertStringDateToDateWithoutTimeZone(plan.mission.startAtMax),
                  }
                : undefined,
        },
        flightPurposes: response.flightPurposes,
        operationCategories,
        editors: {
            options: availableEditorTypes,
            suggested:
                availableEditorTypes.find(
                    (type) => availableEditorTypes.length === 1 || (response.options && response.options[type].suggested)
                ) ?? ItineraryEditorType.Standard,
        },
    };
}

export function convertMissionsPlanListResponseBodyToMissionPlanList(
    response: MissionPlanListResponseBody,
    operatorId?: string
): MissionPlanList {
    const { content, ...pagination } = response;

    const missionList: MissionPlanContent[] = content.map((plan) => ({
        id: plan.id,
        missionId: plan.missionId,
        availableActions: {
            isDeletable: plan.abilities.deletable,
            isEditable: plan.abilities.editable,
            isCloneable: plan.abilities.cloneable && plan.operatorId === operatorId,
            isSubmittable: plan.abilities.submittable,
            isCancelable: plan.abilities.cancelable,
        },
        specificCaaPermitId: plan.specificCaaPermitId,
        uavSetupName: plan.uavSetupName,
        operatorId: plan.operatorId,
        pilotId: plan.pilotId,
        flightStartAtMin: new Date(plan.flightStartAtMin),
        flightStartAtMax: new Date(plan.flightStartAtMax),
        uavId: plan.uavId,
        uavName: plan.uavName,
        name: plan.name,
        pilotName: plan.pilotName,
        flightFinishAtMin: new Date(plan.flightFinishAtMin),
        flightFinishAtMax: new Date(plan.flightFinishAtMax),
        thumbnail: plan.thumbnail,
        operatorName: plan.operatorName,
        modified: plan.modified,
        created: plan.created,
        phase:
            plan.phase === MissionProcessingPhase.Accepted && plan.uncertainConditions
                ? MissionPhaseExtensions.AcceptedConditionally
                : plan.phase,
        flightType: plan.flightType,
        status: plan.status,
        missionSubmittedAt: new Date(plan.missionSubmittedAt),
        description: plan.description,
        flightPurpose: plan.flightPurposeCode,
        flightPurposeComment: plan.flightPurposeComment,
        operationCategoryType: plan.operationCategoryType,
        permitBusinessId: plan.permitBusinessId,
        soraSail: plan.soraSail,
        missionStatus: plan.missionStatus,
        remarks: convertRemarksResponse(plan.remarks),
    }));

    return {
        missionList,
        pagination: {
            pageNumber: pagination.number,
            pageSize: pagination.size,
            totalElements: pagination.totalElements,
        },
    };
}

export function convertMissionCapabilitiesResponseBodyPreferencesEntityToMissionPlanningPreferences(
    preferences: MissionCapabilitiesResponseBodyPreferencesEntity
): MissionPlanningPreferences {
    return {
        initialViewbox: preferences.initialViewBox,
        capabilities: !preferences.capabilities
            ? undefined
            : {
                  missionType: preferences.capabilities.flightType,
                  operatorId: preferences.capabilities.operator,
                  pilotId: preferences.capabilities.pilot,
                  uavSetupId: preferences.capabilities.uavSetup,
                  additionalCrew: preferences.capabilities.additionalCrew,
              },
        flightPurpose: preferences.flightPurpose ?? undefined,
        information: preferences.information ?? undefined,
        operationCategory: preferences.operationCategory
            ? convertMissionPlanOperationCategoryToOption(preferences.operationCategory)
            : undefined,
    };
}

export function convertMissionCapabilitiesResponseBodyPilotEntityToMissionPilot(
    pilot: MissionCapabilitiesResponseBodyPilotEntity
): MissionPilot {
    return {
        id: pilot.id,
        displayName: pilot.name,
    };
}

export function convertMissionCapabilitiesResponseBodyUAVEntityToMissionUAV(uav: MissionCapabilitiesResponseBodyUAVEntity): MissionUAV {
    const setups = uav.setups.map(
        (setup): MissionUAVSetup => ({
            id: setup.id,
            displayName: setup.name,
            isPrimary: setup.primary,
            technicalSpecification: {
                maxFlightSpeed: setup.technicalSpecification.maxFlightSpeed,
                maxDroneWidth: setup.technicalSpecification.maxDroneWidth,
                flightSpeedLimit: setup.technicalSpecification.flightSpeedLimit,
                failSafe: setup.technicalSpecification.failSafe,
                hasGeofencing: setup.technicalSpecification.geofencing,
                hasGeocage: setup.technicalSpecification.geocage,
                takeOffMass: setup.technicalSpecification.takeOffMass,
            },
        })
    );

    return {
        id: uav.id,
        displayName: uav.name,
        setups,
    };
}

export function convertMissionPlanItineraryOptionsToMissionPlanItineraryConstraints(
    itineraryOptions: MissionPlanItineraryOptions | undefined
): MissionPlanItineraryConstraints | undefined {
    if (!itineraryOptions) {
        return;
    }

    return {
        default: {
            height: itineraryOptions.limits.height.suggested,
            size: itineraryOptions.limits.radius.suggested,
            durationMinutes: Math.max(1, Math.floor(itineraryOptions.limits.time.suggested / SECONDS_IN_MINUTE)),
            startDelay: itineraryOptions.limits.startDelay.suggested,
            horizontalNavigationAccuracy: itineraryOptions.navigationAccuracies.horizontal.suggested,
            verticalNavigationAccuracy: itineraryOptions.navigationAccuracies.vertical.suggested,
            runwayHorizontalNavigationAccuracy: itineraryOptions.navigationAccuracies.runwayHorizontal,
            runwayVerticalNavigationAccuracy: itineraryOptions.navigationAccuracies.runwayVertical,
            largeZoneRadius: itineraryOptions.standard.limits.largeZoneRadius.suggested,
            regularZoneRadius: itineraryOptions.standard.limits.regularZoneRadius.suggested,
            distinctShapes: itineraryOptions.limits.distinctAreas.suggested,
            aroundObstacleHeight: itineraryOptions.standard.limits.aroundObstacleHeight.suggested,
            regularHeight: itineraryOptions.standard.limits.aroundObstacleHeight.suggested,
        },
        min: {
            height: itineraryOptions.limits.height.min,
            size: itineraryOptions.limits.radius.min,
            durationMinutes: Math.max(1, Math.floor(itineraryOptions.limits.time.min / SECONDS_IN_MINUTE)),
            startDelay: itineraryOptions.limits.startDelay.min,
            horizontalNavigationAccuracy: itineraryOptions.navigationAccuracies.horizontal.min,
            verticalNavigationAccuracy: itineraryOptions.navigationAccuracies.vertical.min,
            runwayHorizontalNavigationAccuracy: itineraryOptions.navigationAccuracies.runwayHorizontal,
            runwayVerticalNavigationAccuracy: itineraryOptions.navigationAccuracies.runwayVertical,
            largeZoneRadius: itineraryOptions.standard.limits.largeZoneRadius.min,
            regularZoneRadius: itineraryOptions.standard.limits.regularZoneRadius.min,
            distinctShapes: itineraryOptions.limits.distinctAreas.min,
            aroundObstacleHeight: itineraryOptions.standard.limits.aroundObstacleHeight.min,
            regularHeight: itineraryOptions.standard.limits.regularHeight.min,
        },
        max: {
            height: itineraryOptions.limits.height.max,
            size: itineraryOptions.limits.radius.max,
            durationMinutes: Math.max(1, Math.floor(itineraryOptions.limits.time.max / SECONDS_IN_MINUTE)),
            startDelay: itineraryOptions.limits.startDelay.max,
            horizontalNavigationAccuracy: itineraryOptions.navigationAccuracies.horizontal.max,
            verticalNavigationAccuracy: itineraryOptions.navigationAccuracies.vertical.max,
            runwayHorizontalNavigationAccuracy: itineraryOptions.navigationAccuracies.runwayHorizontal,
            runwayVerticalNavigationAccuracy: itineraryOptions.navigationAccuracies.runwayVertical,
            largeZoneRadius: itineraryOptions.standard.limits.largeZoneRadius.max,
            regularZoneRadius: itineraryOptions.standard.limits.regularZoneRadius.max,
            distinctShapes: itineraryOptions.limits.distinctAreas.max,
            aroundObstacleHeight: itineraryOptions.standard.limits.aroundObstacleHeight.max,
            regularHeight: itineraryOptions.standard.limits.regularHeight.max,
        },
    };
}

export function convertMissionPlanItineraryConstraintsToEntityEditorConstraints(
    constraints: MissionPlanItineraryConstraints,
    itineraryEditorFormData: ItineraryEditorFormData | undefined
): EntityEditorConstraints {
    const editorType = itineraryEditorFormData?.editorType ?? ItineraryEditorType.None;

    let result: EntityEditorConstraints = {
        default: {
            bottomHeight: constraints.min.height,
            radius: constraints.default.size,
            startDelay: constraints.default.startDelay,
            topHeight: itineraryEditorFormData?.flightLevel ?? constraints.default.height,
            horizontalNavigationAccuracy:
                (itineraryEditorFormData?.horizontalBuffer ?? constraints.default.horizontalNavigationAccuracy * 2) / 2,
            verticalNavigationAccuracy: (itineraryEditorFormData?.verticalBuffer ?? constraints.default.verticalNavigationAccuracy * 2) / 2,
            runwayHorizontalNavigationAccuracy: constraints.default.runwayHorizontalNavigationAccuracy,
            runwayVerticalNavigationAccuracy: Math.ceil(constraints.default.runwayVerticalNavigationAccuracy / 2) * 2,
            largeZoneRadius: constraints.default.largeZoneRadius,
            regularZoneRadius: constraints.default.regularZoneRadius,
        },
        max: {
            bottomHeight: constraints.max.height,
            radius: constraints.max.size,
            startDelay: constraints.max.startDelay,
            topHeight: constraints.max.height,
            horizontalNavigationAccuracy: constraints.max.horizontalNavigationAccuracy,
            verticalNavigationAccuracy: Math.ceil(constraints.max.verticalNavigationAccuracy / 2) * 2,
            runwayHorizontalNavigationAccuracy: constraints.max.runwayHorizontalNavigationAccuracy,
            runwayVerticalNavigationAccuracy: Math.ceil(constraints.max.runwayVerticalNavigationAccuracy / 2) * 2,
            largeZoneRadius: constraints.max.largeZoneRadius,
            regularZoneRadius: constraints.max.regularZoneRadius,
        },
        min: {
            bottomHeight: constraints.min.height,
            radius: Math.max(constraints.min.size, constraints.min.horizontalNavigationAccuracy),
            startDelay: constraints.min.startDelay,
            topHeight: constraints.min.height,
            horizontalNavigationAccuracy: constraints.min.horizontalNavigationAccuracy,
            verticalNavigationAccuracy: Math.ceil(constraints.min.verticalNavigationAccuracy / 2) * 2,
            runwayHorizontalNavigationAccuracy: constraints.min.runwayHorizontalNavigationAccuracy,
            runwayVerticalNavigationAccuracy: Math.ceil(constraints.min.runwayVerticalNavigationAccuracy / 2) * 2,
            largeZoneRadius: constraints.min.largeZoneRadius,
            regularZoneRadius: constraints.min.regularZoneRadius,
        },
    };

    if (editorType === ItineraryEditorType.Standard && itineraryEditorFormData) {
        const { zoneTopHeight, zoneBottomHeight, isEnlargedZoneStatementAccepted } = itineraryEditorFormData;

        result = {
            ...result,
            default: {
                ...result.default,
                bottomHeight: zoneBottomHeight ?? result.default.bottomHeight,
                topHeight: zoneTopHeight ?? result.default.topHeight,
                radius: isEnlargedZoneStatementAccepted ? constraints.default.largeZoneRadius : constraints.default.regularZoneRadius,
            },
            max: {
                ...result.max,
                bottomHeight: zoneBottomHeight ?? result.max.bottomHeight,
                topHeight: zoneTopHeight ?? result.max.topHeight,
                radius: isEnlargedZoneStatementAccepted ? constraints.max.largeZoneRadius : constraints.max.regularZoneRadius,
            },
            min: {
                ...result.min,
                bottomHeight: zoneBottomHeight ?? result.min.bottomHeight,
                topHeight: zoneTopHeight ?? result.min.topHeight,
                radius: isEnlargedZoneStatementAccepted ? constraints.min.largeZoneRadius : constraints.min.regularZoneRadius,
            },
        };
    }

    return result;
}

export function convertMissionPlanItineraryConstraintsToAssistedEntityEditorConstraints(
    constraints: MissionPlanItineraryConstraints
): EntityEditorConstraints {
    return {
        default: {
            bottomHeight: 0,
            radius: constraints.default.runwayHorizontalNavigationAccuracy,
            startDelay: constraints.default.startDelay,
            topHeight: constraints.default.height,
            horizontalNavigationAccuracy: constraints.default.horizontalNavigationAccuracy,
            verticalNavigationAccuracy: Math.ceil(constraints.default.verticalNavigationAccuracy / 2) * 2,
            runwayHorizontalNavigationAccuracy: constraints.default.runwayHorizontalNavigationAccuracy,
            runwayVerticalNavigationAccuracy: Math.ceil(constraints.default.runwayVerticalNavigationAccuracy / 2) * 2,
            largeZoneRadius: constraints.default.largeZoneRadius,
            regularZoneRadius: constraints.default.regularZoneRadius,
        },
        max: {
            bottomHeight: 0,
            radius: constraints.max.size,
            startDelay: constraints.max.startDelay,
            topHeight: constraints.default.height,
            horizontalNavigationAccuracy: constraints.max.horizontalNavigationAccuracy,
            verticalNavigationAccuracy: Math.ceil(constraints.max.verticalNavigationAccuracy / 2) * 2,
            runwayHorizontalNavigationAccuracy: constraints.max.runwayHorizontalNavigationAccuracy,
            runwayVerticalNavigationAccuracy: Math.ceil(constraints.max.runwayVerticalNavigationAccuracy / 2) * 2,
            largeZoneRadius: constraints.max.largeZoneRadius,
            regularZoneRadius: constraints.max.regularZoneRadius,
        },
        min: {
            bottomHeight: 0,
            radius: constraints.default.runwayHorizontalNavigationAccuracy,
            startDelay: constraints.min.startDelay,
            topHeight: constraints.default.height,
            horizontalNavigationAccuracy: constraints.min.horizontalNavigationAccuracy,
            verticalNavigationAccuracy: Math.ceil(constraints.min.verticalNavigationAccuracy / 2) * 2,
            runwayHorizontalNavigationAccuracy: constraints.min.runwayHorizontalNavigationAccuracy,
            runwayVerticalNavigationAccuracy: Math.ceil(constraints.min.runwayVerticalNavigationAccuracy / 2) * 2,
            largeZoneRadius: constraints.min.largeZoneRadius,
            regularZoneRadius: constraints.min.regularZoneRadius,
        },
    };
}

function parseWaypointResponseDates(waypoint: Waypoint, waypointIndex: number): Waypoint {
    const prefix = "A";

    return {
        ...waypoint,
        name: `${prefix}${waypointIndex}`,
        estimatedArriveAt: {
            min: new Date(waypoint.estimatedArriveAt.min),
            max: new Date(waypoint.estimatedArriveAt.max),
        },
    };
}

function convertResponseSections(sections: SectionElementResponseBody[]): MissionPlanRouteSection[] {
    let sectionId = 0;

    return sections.map((section, index): MissionPlanRouteSection => {
        const flightZone: MissionPlanRouteFlightZone | undefined = !section.flightZone
            ? undefined
            : {
                  center: parseWaypointResponseDates(
                      section.flightZone.center,
                      sections[index - 1]?.segment ? (sectionId += 2) : ++sectionId
                  ),
                  flightArea: section.flightZone.flightArea,
                  safetyArea: section.flightZone.safetyArea,
                  groundArea: section.flightZone.ground?.area,
                  groundAdjacentArea: section.flightZone.groundAdjacent?.area,
                  stopover: section.flightZone.stopover,
                  isRunway: section.flightZone.runway,
              };

        const segment: MissionPlanRouteSegment | undefined = !section.segment
            ? undefined
            : {
                  fromWaypoint: parseWaypointResponseDates(section.segment.from, ++sectionId),
                  toWaypoint: parseWaypointResponseDates(section.segment.to, sectionId + 1),
                  flightArea: section.segment.flightArea,
                  safetyArea: section.segment.safetyArea,
                  groundArea: section.segment.ground?.area,
                  groundAdjacentArea: section.segment.groundAdjacent?.area,
                  elevationProfile: section.segment.elevationProfile,
                  distance: section.segment.distance,
                  speed: section.segment.speed && {
                      min: +section.segment.speed.min || 0,
                      max: +section.segment.speed.max || 0,
                  },
                  duration: section.segment.duration,
              };

        return { flightZone, segment, isActive: section.active };
    });
}

export function convertMissionPlanRouteResponseBodyToMissionPlanRoute(
    response: MissionPlanRouteResponseBody,
    planId?: string
): MissionPlanRoute {
    return {
        routeId: response.routeId,
        planId: response.planId ?? planId,
        missionId: response.missionId,
        elevations: response.elevations,
        isPathBased: response.pathBased,
        sections: convertResponseSections(response.sections),
        estimatedDistance: response.estimatedDistance,
        stats: response?.stats,
    };
}

export const convertOperationalGeometryDataResponseBodyToCaaPermitData = (response: CaaPermitDataResponseBody): CaaPermitData => ({
    operationalGeometry: {
        safetyAreaWidth: response.safetyArea.width,
        safetyAreaHeight: response.safetyArea.height,
        groundRiskBuffer: response.groundRisk.buffer,
        groundRiskBufferType: response.groundRisk.bufferType,
        adjacentAreaBufferHorizontal: response.adjacentArea.bufferHorizontal,
        adjacentAreaHeight: response.adjacentArea.height,
        flightAreaMaxRadius: response.flightArea.maxRadius,
        flightAreaMaxDeclaredHeight: response.flightArea.maxDeclaredHeight,
        operationAreaMaxRadius: response.operationArea.maxRadius,
        flightAreaMaxHeight: response.flightArea.maxDeclaredHeight,
        operationAreaMaxHeight: response.operationArea.maxHeight,
        flightAreaEstimatedDistance: response.flightArea.estimatedDistance,
        flightAreaMaxWidth: response.flightArea.maxWidth,
        operationAreaMaxWidth: response.operationArea.maxWidth,
        operationAreaMaxDeclaredHeight: response.operationArea.maxDeclaredHeight,
        flightAreaSize: response.flightArea.size,
        operationAreaSize: response.operationArea.size,
    },
    technicalSpecification: {
        takeOffMass: response.uav.takeOffMass,
        maxFlightSpeed: response.uav.maxFlightSpeed,
        maxDroneWidth: response.uav.maxDroneWidth,
        hasGeofencing: response.uav.geofencing,
        hasGeocage: response.uav.geocage,
        flightSpeedLimit: response.uav.flightSpeedLimit,
        failSafe: response.uav.failSafe,
    },
    soraSettings: response.sora,
});

export function convertMissionPlanItineraryWithRouteResponseBodyToMissionPlanItineraryWithRoute(
    response: MissionPlanItineraryWithRouteResponseBody
): MissionPlanItineraryWithRoute {
    const constraints = convertMissionPlanItineraryOptionsToMissionPlanItineraryConstraints(response.options);

    if (response.itinerary?.input?.["@type"] === MissionPlanItineraryEntityType.Standard) {
        return {
            itinerary: {
                type: ItineraryEditorType.Standard,
                ...response.itinerary.input,
                constraints,
            },
            route: response.route
                ? convertMissionPlanRouteResponseBodyToMissionPlanRoute(response.route, response.itinerary.planId)
                : undefined,
        };
    } else if (response.itinerary?.input?.["@type"] === MissionPlanItineraryEntityType.Custom) {
        return {
            itinerary: {
                type: ItineraryEditorType.Custom,
                ...response.itinerary.input,
                constraints,
            },
            route: response.route
                ? convertMissionPlanRouteResponseBodyToMissionPlanRoute(response.route, response.itinerary.planId)
                : undefined,
        };
    } else if (response.itinerary?.input?.["@type"] === MissionPlanItineraryEntityType.Assisted) {
        return {
            itinerary: {
                type: ItineraryEditorType.Assisted,
                ...response.itinerary.input,
                constraints,
            },
        };
    }

    return {
        itinerary: {
            type: ItineraryEditorType.None,
            constraints,
        },
    };
}

function convertPolyline3DMapEntityToWaypointEntities(entity: Polyline3DItineraryEntity): WaypointEntity[] {
    return entity.positions.map((position, index): WaypointEntity => {
        const positionCartographic = MapUtils.convertCartesian3ToSerializableCartographic(position);
        const childEntity = Object.values(entity.childEntities).find((child) => child.waypointIndex === index)?.entity;

        const result: WaypointEntity = {
            position: {
                lat: positionCartographic.latitude,
                lon: positionCartographic.longitude,
                h: entity.heights[index],
            },
            beyondPath: false,
            runway: false,
        };

        if (childEntity) {
            result.runway = index === 0 || index === entity.positions.length - 1;
            result.beyondPath = true;
            result.stopoverMin = result.stopoverMax = entity.metadata?.stopovers[childEntity.id];

            const area =
                childEntity.type === MapEntityType.Cylinder
                    ? MapUtils.convertCylinderEntityToGeoJSONFeature(childEntity)
                    : MapUtils.convertPrismEntityToGeoJSONFeature(childEntity);

            result.flightZone = {
                minH: childEntity.bottomHeight,
                maxH: childEntity.topHeight,
                area,
            };
        } else {
            result.navigationAccuracy = {
                vertical: entity.bufferHeights[index] / 2,
                horizontal: entity.bufferWidths[index] / 2,
            };
        }

        return result;
    });
}

function convertPrismMapEntityToWaypointEntity(entity: PrismItineraryEntity): WaypointEntity | undefined {
    if (!entity.center) {
        return undefined;
    }

    const center = MapUtils.convertCartesian3ToSerializableCartographic(entity.center);

    return {
        position: {
            lat: center.latitude,
            lon: center.longitude,
            h: 0,
        },
        beyondPath: true,
        runway: false,
        flightZone: {
            minH: entity.bottomHeight,
            maxH: entity.topHeight,
            area: MapUtils.convertPrismEntityToGeoJSONFeature(entity),
        },
        stopoverMax: entity.metadata?.stopover,
        stopoverMin: entity.metadata?.stopover,
    };
}

function convertCylinderMapEntityToWaypointEntity(entity: CylinderItineraryEntity): WaypointEntity {
    const center = MapUtils.convertCartesian3ToSerializableCartographic(entity.center);

    return {
        position: {
            lat: center.latitude,
            lon: center.longitude,
            h: 0,
        },
        beyondPath: true,
        runway: false,
        flightZone: {
            minH: entity.bottomHeight,
            maxH: entity.topHeight,
            area: MapUtils.convertCylinderEntityToGeoJSONFeature(entity),
        },
        stopoverMax: entity.metadata?.stopover,
        stopoverMin: entity.metadata?.stopover,
    };
}

export function convertItineraryContentToMissionPlanCustomItineraryEntity(
    itineraryContent: ItineraryContent,
    startDate: string,
    itineraryData: ItineraryEditorFormData
): MissionPlanCustomItineraryEntity {
    const cruisingSpeed = getSpeedInMetersPerSecond(itineraryData.flightSpeed, itineraryData.flightSpeedType);

    const result: MissionPlanCustomItineraryEntity = {
        "@type": MissionPlanItineraryEntityType.Custom,
        limits: {
            speed: cruisingSpeed,
        },
        flight: {
            cruisingLevel: {
                value: itineraryData.flightLevel ?? 0,
                type: itineraryData.flightLevelType,
            },
            cruisingSpeed,
            pilotReactionDelay: itineraryData.pilotReactionDelaySeconds,
        },
        points: itineraryContent
            .flatMap((entity): WaypointEntity | WaypointEntity[] | undefined => {
                switch (entity.type) {
                    case MapEntityType.Polyline3D:
                        return convertPolyline3DMapEntityToWaypointEntities(entity);
                    case MapEntityType.Prism:
                        return convertPrismMapEntityToWaypointEntity(entity);
                    case MapEntityType.Cylinder:
                        return convertCylinderMapEntityToWaypointEntity(entity);
                }
            })
            .filter(FunctionUtils.isTruthy),
    };

    result.points[0].estimatedArriveAtMin = startDate;

    return result;
}

export function convertItineraryContentToMissionPlanStandardItineraryEntity(
    itineraryContent: ItineraryContent,
    startDate: string,
    durationTime: ISO8601TimeDuration,
    itineraryData: ItineraryEditorFormData
): MissionPlanStandardItineraryEntity {
    const result: MissionPlanStandardItineraryEntity = {
        "@type": MissionPlanItineraryEntityType.Standard,
        pilotReactionDelay: itineraryData.pilotReactionDelaySeconds,
        estimatedArriveAt: startDate,
        time: durationTime,
        aroundObstacle: itineraryData.isFlightAroundObstacleStatementAccepted ?? false,
        enlargedZoneRadius: itineraryData.isEnlargedZoneStatementAccepted ?? false,
        elements: itineraryContent
            .map((entity) => {
                switch (entity.type) {
                    case MapEntityType.Cylinder:
                        return {
                            flightZone: {
                                minH: entity.bottomHeight,
                                maxH: entity.topHeight,
                                area: MapUtils.convertCylinderEntityToGeoJSONFeature(entity),
                            },
                        };
                    case MapEntityType.Prism:
                        return {
                            flightZone: {
                                minH: entity.bottomHeight,
                                maxH: entity.topHeight,
                                area: MapUtils.convertPrismEntityToGeoJSONFeature(entity),
                            },
                        };
                    case MapEntityType.Polyline3D:
                        return {
                            flightZone: {
                                minH: 0,
                                maxH: entity.heights[0],
                                area: MapUtils.convertPolyline3DEntityToGeoJSONFeature(entity),
                            },
                        };
                    default:
                        return;
                }
            })
            .filter(FunctionUtils.isTruthy),
    };

    if (itineraryData.flightSpeed) {
        result.limits = {
            speed: getSpeedInMetersPerSecond(itineraryData.flightSpeed, itineraryData.flightSpeedType),
        };
    }

    return result;
}

export enum AssistedEntityId {
    Takeoff = "assisted_takeoff_itinerary_entity",
    Landing = "assisted_landing_itinerary_entity",
}

export function convertItineraryContentToMissionPlanAssistedItineraryEntity(
    itineraryContent: ItineraryContent,
    startDate: string,
    itineraryData: ItineraryEditorFormData
): MissionPlanAssistedItineraryEntity {
    const originEntity = itineraryContent.find(({ id }) => id === AssistedEntityId.Takeoff);
    const destinationEntity = itineraryContent.find(({ id }) => id === AssistedEntityId.Landing);

    if (!originEntity || !destinationEntity || ![originEntity.type, destinationEntity.type].includes(MapEntityType.Cylinder)) {
        throw new Error("Assisted editor entity missing or wrong type");
    }

    const origin = convertCylinderMapEntityToWaypointEntity(originEntity as CylinderEntity);
    const destination = convertCylinderMapEntityToWaypointEntity(destinationEntity as CylinderEntity);

    origin.beyondPath = true;
    destination.beyondPath = true;
    origin.runway = true;
    destination.runway = true;

    const result: MissionPlanAssistedItineraryEntity = {
        "@type": MissionPlanItineraryEntityType.Assisted,
        flight: {
            cruisingLevel: {
                value: itineraryData.flightLevel ?? 0,
                type: itineraryData.flightLevelType,
            },
            cruisingSpeed: getSpeedInMetersPerSecond(itineraryData.flightSpeed, itineraryData.flightSpeedType),
            pilotReactionDelay: itineraryData.pilotReactionDelaySeconds,
        },
        origin,
        destination,
    };

    result.origin.estimatedArriveAtMin = startDate;

    return result;
}

export function convertFormalJustificationToRequestPayload(
    authorityType: AuthorityType,
    justification?: FormalJustification
): FormalJustificationRequestPayload {
    if (!justification?.reason?.trim() && !justification?.attachmentIds?.length && !justification?.shouldRequestForPriority) {
        return { authorityType };
    }

    return {
        authorityType,
        formalJustification: {
            reason: justification.reason?.trim() || null,
            requestForPriority: !!justification.shouldRequestForPriority,
            attachmentIds: justification.attachmentIds ?? [],
        },
    };
}

export function convertPublicMissionPlanResponseBodyToPublicMissionPlanData(
    response: PublicMissionPlanResponseBody
): PublicMissionPlanData {
    return {
        id: response.id,
        missionId: response.missionId,
        capabilities: response.capabilities,
        flightStartAtMin: response.flightStartAtMin ? new Date(response.flightStartAtMin) : undefined,
        flightFinishAtMax: response.flightFinishAtMax ? new Date(response.flightFinishAtMax) : undefined,
        route: response.route
            ? {
                  routeId: response.route.id,
                  dtmNames: response.route.dtmNames,
                  isPathBased: response.route.pathBased,
                  isDtmOnly: response.route.dtmOnly,
                  estimatedDistance: response.route.estimatedDistance,
                  sections: convertResponseSections(response.route.sections),
              }
            : {
                  routeId: "",
                  sections: [],
              },
    };
}

export function convertFlightTerminationSystem({
    required,
    available,
    issues,
}: FlightTerminationSystemResponseBody): FlightTerminationSystem {
    return {
        isRequired: required,
        isAvailable: available,
        issues,
    };
}

export interface PansaUtmLinkStatusResponseBody {
    pansaUtmLink?: PansaUtmLink;
}

export interface RouteAirspaceInfoResponseBody {
    routeId: string;
    planId: string;
    elements: {
        id: string;
        type: string;
        geozone: GeoZoneType;
        designator: string;
        tag?: string;
        area: Polygon;
        reservations: {
            status: AirspaceElementStatus;
            verticalRange: { floor: number; ceiling: number };
            period: { from: string; to: string };
        }[];
        staticElement: boolean;
        h24: boolean;
        flight: boolean;
        safety: boolean;
        adjacent: boolean;
        zoneAttributes?: {
            name: string;
            localInformationType: InfoZoneType;
        };
    }[];
}

const DTM_TAG_NAME = "DTM";

export function convertRouteAirspaceInfoResponseBodyToAirspaceElementsInfo(response: RouteAirspaceInfoResponseBody): AirspaceElementsInfo {
    return {
        doesAupExist: false,
        airspaceElements: response.elements
            .filter(({ flight, safety }) => flight && safety)
            .map((element) => {
                const reservations: Reservation[] = element.reservations
                    .filter((reservation) => reservation.period.from)
                    .map((reservation) => ({
                        status: reservation.status,
                        scope: {
                            lowerLimit: reservation.verticalRange.floor,
                            endTime: new Date(reservation.period.to),
                            upperLimit: reservation.verticalRange.ceiling,
                            startTime: new Date(reservation.period.from),
                        },
                    }))
                    .sort((a, b) => a.scope.startTime.getTime() - b.scope.startTime.getTime());

                return {
                    id: element.designator,
                    designator: element.designator,
                    isStatic: element.staticElement,
                    isActive: true,
                    geometry: element.area,
                    activeTime: reservations.length
                        ? {
                              from: new Date(reservations[0].scope.startTime),
                              to: new Date(reservations[reservations.length - 1].scope.endTime),
                          }
                        : undefined,
                    type: element.type,
                    reservations,
                    tags: element.tag,
                    ids: [],
                    geoZoneType: element.tag?.includes(DTM_TAG_NAME) ? GeoZoneType.DroneTrafficManagement : element.geozone,
                    zoneAttributes: element.zoneAttributes && {
                        name: element.zoneAttributes.name,
                        localInformationType: element.zoneAttributes.localInformationType,
                        isAdsbForBvlosRequired: false,
                        isBvlosFlightsForbidden: false,
                        isBvlosPathFlightsForbidden: false,
                        isVlosFlightsForbidden: false,
                    },
                };
            }),
    };
}

export function convertMissionCapabilitiesResponseBodyRegulationExemptionsToRegulationsExemptions(
    response: MissionCapabilitiesResponseBodyRegulationExemptions
): RegulationExemptions {
    return {
        isBvlos: response.bvlos,
        scenarioName: response.scenarioName,
        pilotIds: response.pilots.map(({ id }) => id),
        uavSetupIds: response.uavs.flatMap(({ setups }) => setups).map(({ id }) => id),
        limits: response.limits,
    };
}
