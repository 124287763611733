import { HttpErrorResponse, HttpStatusCode } from "@angular/common/http";
import {
    Address,
    OperationScenarioCategory,
    OperatorAndPilotQualification,
    OperatorStatus,
    PhoneNumber,
    QualificationStatus,
} from "@dtm-frontend/shared/ui";
import { DateUtils } from "@dtm-frontend/shared/utils";
import { OperatorPilotOtherInformation } from "../../shared/models/pilot-and-operator.models";
import {
    Operator,
    OperatorAssociationRegistration,
    OperatorAttorneyPower,
    OperatorConfirmationDocumentType,
    OperatorProfileError,
    OperatorProfileErrorType,
    Owner,
} from "../models/operator.models";

interface OperationalAuthorizationResponseBody {
    id: string;
    status: QualificationStatus;
    statusReason: string;
    operationScenario: {
        id: string;
        name: string;
        category: OperationScenarioCategory;
    };
    expirationDate: string;
}

export interface GetOperatorResponseBody {
    id: string;
    status: OperatorStatus;
    suspensionReason: string;
    operatorNumber: {
        number: string;
        secret: string;
    };
    type: string;
    name: string;
    email: string;
    phoneNumber: PhoneNumber;
    companyNumber: string;
    companyName: string;
    nip: string;
    insurancePolicyNumber?: string;
    insurancePolicyExpirationDate?: string;
    address: Address;
    owner: {
        userId: string;
        firstName: string;
        lastName: string;
        dateOfBirth: string;
        nationality: string;
        phoneNumber: PhoneNumber;
        email: string;
        legalAge: boolean;
    };
    personOfLegalAge: boolean;
    pilotId: string;
    operationalAuthorizations: OperationalAuthorizationResponseBody[];
    attorneyPowerRequired: boolean;
    attorneyPowers?: OperatorAttorneyPower[];
    association: boolean;
    associationRegistration?: OperatorAssociationRegistration;
    emergency: boolean;
    documentsToDownload: OperatorConfirmationDocumentType[];
    otherInformation?: OperatorPilotOtherInformation[];
}

const convertResponseBodyToOwner = ({ owner }: GetOperatorResponseBody): Owner => ({
    userId: owner.userId,
    firstName: owner.firstName,
    lastName: owner.lastName,
    dateOfBirth: owner.dateOfBirth,
    nationality: owner.nationality,
    phoneNumber: owner.phoneNumber,
    email: owner.email,
    isLegalAge: owner.legalAge,
});

const convertOperationalAuthorizationResponseBodyToOperatorAndPilotQualification = (
    authorizations: OperationalAuthorizationResponseBody
): OperatorAndPilotQualification => ({
    id: authorizations.id,
    category: authorizations.operationScenario.category,
    expirationDate: DateUtils.convertStringDateToDateWithoutTimeZone(authorizations.expirationDate),
    name: authorizations.operationScenario.name,
    status: authorizations.status,
    statusReason: authorizations.statusReason,
});

export const convertGetResponseBodyToOperator = (response: GetOperatorResponseBody): Operator => ({
    id: response.id,
    status: response.status,
    suspensionReason: response.suspensionReason,
    operatorNumber: {
        number: response.operatorNumber.number,
        secretCode: response.operatorNumber.secret,
    },
    type: response.type,
    name: response.name,
    phoneNumber: response.phoneNumber,
    email: response.email,
    companyNumber: response.companyNumber,
    insurancePolicyNumber: response.insurancePolicyNumber,
    insurancePolicyExpirationDate: response.insurancePolicyExpirationDate
        ? DateUtils.convertStringDateToDateWithoutTimeZone(response.insurancePolicyExpirationDate)
        : undefined,
    address: response.address,
    owner: convertResponseBodyToOwner(response),
    isPersonOfLegalAge: response.personOfLegalAge,
    pilotId: response.pilotId,
    qualifications: response.operationalAuthorizations
        .map((authorizations) => convertOperationalAuthorizationResponseBodyToOperatorAndPilotQualification(authorizations))
        .sort((left, right) => left.name.localeCompare(right.name)),
    isAttorneyPowerRequired: response.attorneyPowerRequired,
    attorneyPowers: response.attorneyPowers,
    isAssociation: response.association,
    associationRegistration: response.associationRegistration,
    isEmergency: response.emergency,
    documentsToDownload: response.documentsToDownload,
    otherInformation: response.otherInformation ?? [],
});

export const convertGetOperatorProfileErrorResponse = (errorResponse: HttpErrorResponse): OperatorProfileError => {
    switch (errorResponse.status) {
        case HttpStatusCode.Forbidden:
            return { type: OperatorProfileErrorType.NotAuthorized };
        case HttpStatusCode.NotFound:
            return { type: OperatorProfileErrorType.NotFound };
        default:
            return { type: OperatorProfileErrorType.Unknown };
    }
};

export const convertEditOperatorCompanyInfoErrorResponseToOperatorProfileError = (
    errorResponse: HttpErrorResponse
): OperatorProfileError => {
    if (errorResponse.error.generalMessage?.includes("company_number_already_exists")) {
        return { type: OperatorProfileErrorType.CompanyNumberConflicted };
    }

    return { type: OperatorProfileErrorType.CannotSaveOperatorCompanyInfo };
};
