import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { ACCESSIBILITY_STATEMENT_URL, TERMS_OF_USE_URL } from "@dtm-frontend/shared/utils";

const CAA_EMAIL_ADDRESS = "lbsp@ulc.gov.pl";
const TECHNICAL_EMAIL_ADDRESS = "uav-support@pansa.pl";
const USER_GUIDE_FILE_URL = "https://assets.uav.pansa.pl/eRejestracja-podrecznik-uzytkownika.pdf";
const SORA_GUIDE_FILE_URL = "https://assets.uav.pansa.pl/eSora-podrecznik-uzytkownika.pdf";
const ELEARNING_USER_GUIDE_FILE_URL = "https://assets.uav.pansa.pl/eLearning-podrecznik-uzytkownika.pdf";

@Component({
    selector: "dtm-web-app-lib-help-center-container",
    templateUrl: "./help-center-container.component.html",
    styleUrls: ["./help-center-container.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HelpCenterContainerComponent {
    protected readonly CAA_EMAIL_ADDRESS = CAA_EMAIL_ADDRESS;
    protected readonly TECHNICAL_EMAIL_ADDRESS = TECHNICAL_EMAIL_ADDRESS;
    protected readonly USER_GUIDE_FILE_URL = USER_GUIDE_FILE_URL;
    protected readonly SORA_GUIDE_FILE_URL = SORA_GUIDE_FILE_URL;
    protected readonly ELEARNING_USER_GUIDE_FILE_URL = ELEARNING_USER_GUIDE_FILE_URL;
    protected readonly termsOfUseUrl = inject(TERMS_OF_USE_URL);
    protected readonly accessibilityStatementUrl = inject(ACCESSIBILITY_STATEMENT_URL);
}
