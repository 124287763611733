import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { CommunicationType, NavigationAccuracy, Tracker, UavProperties } from "@dtm-frontend/shared/uav";
import { FILES_UPLOAD_API_PROVIDER, UavType } from "@dtm-frontend/shared/ui";
import { AnimationUtils, LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { map } from "rxjs/operators";
import { SetupDocumentUploadApiService } from "../../../../services/setup-document-upload-api.service";
import { EditableUavSetup, UavModelProperties } from "../../../../services/uav.models";
import { SetupFormComponent } from "../../../setup/setup-form/setup-form.component";

interface NewUavWizardPrimarySetupStepComponentState {
    newUavModel: UavModelProperties | undefined;
    trackers: Tracker[] | undefined;
    navigationAccuracyItems: NavigationAccuracy[];
    primarySetup: EditableUavSetup | undefined;
    isFactoryParamsPreviewVisible: boolean;
    isIntroMessageVisible: boolean;
    isProcessing: boolean;
}

@Component({
    selector: "dtm-web-app-lib-new-uav-wizard-primary-setup-step[newUavModel][trackers][navigationAccuracyItems]",
    templateUrl: "./primary-setup-step.component.html",
    styleUrls: ["./primary-setup-step.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        LocalComponentStore,
        {
            provide: FILES_UPLOAD_API_PROVIDER,
            useClass: SetupDocumentUploadApiService,
        },
    ],
    animations: [AnimationUtils.foldAnimation(), AnimationUtils.fadeAnimation()],
})
export class NewUavWizardPrimarySetupStepComponent implements OnInit {
    @Input() public set newUavModel(value: UavModelProperties | undefined) {
        this.localStore.patchState({
            newUavModel: value,
            primarySetup: value ? this.prepareSetupDefaults(value) : undefined,
        });
    }

    @Input() public set trackers(value: Tracker[] | undefined) {
        this.localStore.patchState({ trackers: value });
    }

    @Input() public set navigationAccuracyItems(value: NavigationAccuracy[] | undefined) {
        this.localStore.patchState({ navigationAccuracyItems: value ?? [] });
    }

    @Input() public set isProcessing(value: BooleanInput) {
        this.localStore.patchState({ isProcessing: coerceBooleanProperty(value) });
    }

    @Output() public back = new EventEmitter<void>();
    @Output() public next = new EventEmitter<EditableUavSetup>();
    @Output() public valueChange = new EventEmitter<void>();

    @ViewChild(SetupFormComponent, { static: true }) protected setupForm: SetupFormComponent | undefined;

    protected readonly newUavModel$ = this.localStore.selectByKey("newUavModel").pipe(RxjsUtils.filterFalsy());
    protected readonly setupDefaults$ = this.newUavModel$.pipe(map((newUavModel) => this.prepareSetupDefaults(newUavModel)));
    protected readonly factoryCommunicationTypes$ = this.newUavModel$.pipe(
        map((newUavModel) => this.prepareFactoryCommunicationTypes(newUavModel))
    );
    protected readonly trackers$ = this.localStore.selectByKey("trackers");
    protected readonly navigationAccuracyItems$ = this.localStore.selectByKey("navigationAccuracyItems");
    protected readonly primarySetupProperties$ = this.newUavModel$.pipe(
        map((newUavModel) => this.preparePrimarySetupProperties(newUavModel))
    );
    protected readonly isFactoryParamsPreviewVisible$ = this.localStore.selectByKey("isFactoryParamsPreviewVisible");
    protected readonly isIntroMessageVisible$ = this.localStore.selectByKey("isIntroMessageVisible");
    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");
    protected readonly uavTechnicalSpecification$ = this.newUavModel$.pipe(map((newUavModel) => newUavModel.technicalSpecification));

    constructor(
        private readonly localStore: LocalComponentStore<NewUavWizardPrimarySetupStepComponentState>,
        private readonly transloco: TranslocoService
    ) {
        this.localStore.setState({
            newUavModel: undefined,
            trackers: undefined,
            navigationAccuracyItems: [],
            primarySetup: undefined,
            isFactoryParamsPreviewVisible: false,
            isIntroMessageVisible: true,
            isProcessing: false,
        });
    }

    public ngOnInit(): void {
        this.setupForm?.initSetupFormValues();
    }

    protected goToNext() {
        const primarySetup = this.localStore.selectSnapshotByKey("primarySetup");

        if (primarySetup) {
            this.next.emit(primarySetup);
        } else {
            this.setupForm?.markInvalidField();
        }
    }

    protected primarySetupChange(setup: EditableUavSetup | undefined) {
        this.localStore.patchState({
            primarySetup: setup,
        });
        this.valueChange.emit();
    }

    protected openFactoryParametersPreview() {
        this.localStore.patchState({ isFactoryParamsPreviewVisible: true });
    }

    protected closeFactoryParametersPreview() {
        this.localStore.patchState({ isFactoryParamsPreviewVisible: false });
    }

    protected closeIntroMessage() {
        this.localStore.patchState({ isIntroMessageVisible: false });
    }

    private prepareSetupDefaults(newUavModel: UavModelProperties): EditableUavSetup {
        return {
            name: this.transloco.translate("dtmWebAppLibUav.newUavWizardSteps.primarySetup.primarySetupName"),
            technicalSpecification: {
                maxFlightTime: newUavModel.technicalSpecification.maxFlightTime,
                hasFlightSpeedLimit: newUavModel.technicalSpecification.hasFlightSpeedLimit,
                maxFlightSpeed: newUavModel.technicalSpecification.maxFlightSpeed,
                minFlightSpeed: newUavModel.technicalSpecification.minFlightSpeed,
                failSafe: newUavModel.technicalSpecification.failSafe,
                hasGeofencing: newUavModel.technicalSpecification.hasGeofencing,
                hasGeocage: newUavModel.technicalSpecification.hasGeocage,
                hasEmergencyMotorStop: newUavModel.technicalSpecification.hasEmergencyMotorStop,
            },
            communications: [],
            documents: {},
            trackings: [],
            equipment: newUavModel.equipment,
        };
    }

    private preparePrimarySetupProperties(newUavModel: UavModelProperties): UavProperties {
        const technicalSpecification = { ...newUavModel.technicalSpecification };

        if (newUavModel.type !== UavType.Airplane) {
            technicalSpecification.minFlightSpeed = undefined;
        }

        return {
            technicalSpecification,
            communications: newUavModel.communications,
            equipment: newUavModel.equipment,
            documents: {},
        };
    }

    private prepareFactoryCommunicationTypes(newUavModel: UavModelProperties): CommunicationType[] {
        return newUavModel.communications.map((communication) => communication.type);
    }
}
