import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";

interface SetupPropertyBooleanComponentState {
    description: string | undefined;
    label: string | undefined;
    value: boolean | undefined;
}

@Component({
    selector: "dtm-uav-lib-setup-property-boolean",
    templateUrl: "./setup-property-boolean.component.html",
    styleUrls: ["./setup-property-boolean.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class SetupPropertyBooleanComponent {
    @Input({ required: true }) public set label(value: string | undefined) {
        this.localStore.patchState({ label: value });
    }

    @Input({ required: true }) public set value(value: boolean | null | undefined) {
        this.localStore.patchState({ value: value ?? undefined });
    }

    @Input() public set description(value: string | undefined) {
        this.localStore.patchState({ description: value });
    }

    protected readonly label$ = this.localStore.selectByKey("label");
    protected readonly description$ = this.localStore.selectByKey("description");
    protected readonly value$ = this.localStore.selectByKey("value");

    constructor(private readonly localStore: LocalComponentStore<SetupPropertyBooleanComponentState>) {
        this.localStore.setState({
            description: undefined,
            label: undefined,
            value: undefined,
        });
    }
}
