<div class="content" *ngIf="regulationExemptions$ | ngrxPush as exemptions">
    <h2>
        <dtm-ui-icon name="error-warning-fill" />
        {{ "dtmWebAppLibMission.dataFormStep.regulationExemptionsInfo.parametersLabel" | transloco }}
    </h2>
    <ul>
        <li *ngIf="exemptions.limits.maxHeight as value">
            <dtm-ui-icon name="check" />
            {{ "dtmWebAppLibMission.dataFormStep.regulationExemptionsInfo.maxFlightHeightLabelAgl" | transloco : { value } }}
        </li>
        <li *ngIf="exemptions.limits.distance as value">
            <dtm-ui-icon name="check" />
            {{
                "dtmWebAppLibMission.dataFormStep.regulationExemptionsInfo.maxFlightAreaRadiusInKmLabel"
                    | transloco : { value: value | convertToKilometers }
            }}
            <dtm-ui-icon
                name="information-fill"
                class="info-tooltip"
                [matTooltip]="
                    'dtmWebAppLibMission.dataFormStep.regulationExemptionsInfo.maxFlightAreaRadiusInKmInfo'
                        | transloco : { value: value | convertToKilometers }
                "
            ></dtm-ui-icon>
        </li>
        <li *ngIf="exemptions.limits.uavTom as value">
            <dtm-ui-icon name="check" />
            {{ "dtmWebAppLibMission.dataFormStep.regulationExemptionsInfo.mtomLabel" | transloco : { value } }}
        </li>
    </ul>
</div>
