<ng-container dtmUiInvalidFormScrollable>
    <dtm-ui-input-field>
        <label>
            {{ "dtmSharedUav.setupProperties.flightTechnicalProperties.numberOfEnginesLabel" | transloco }}
            {{ "dtmWebAppLibUav.setupForm.optionalLabel" | transloco }}
        </label>
        <input
            matInput
            type="number"
            [formControl]="propertiesForm.controls.numberOfEngines"
            [min]="MIN_NUMBER_OF_ENGINES_VALUE"
            [max]="MAX_NUMBER_OF_ENGINES_VALUE"
            inputmode="numeric"
        />
        <div class="field-error" *dtmUiFieldHasError="propertiesForm.controls.numberOfEngines; name: 'min'; error as error">
            {{ "dtmWebAppLibUav.setupForm.valueLessThanMinErrorHint" | transloco : { min: error.min | localizeNumber } }}
        </div>
        <div class="field-error" *dtmUiFieldHasError="propertiesForm.controls.numberOfEngines; name: 'max'; error as error">
            {{ "dtmWebAppLibUav.setupForm.valueGreaterThanMaxErrorHint" | transloco : { max: error.max | localizeNumber } }}
        </div>
        <div class="field-error" *dtmUiFieldHasError="propertiesForm.controls.numberOfEngines; name: 'integer'">
            {{ "dtmWebAppLibUav.setupForm.valueNotIntegerErrorHint" | transloco }}
        </div>
    </dtm-ui-input-field>
    <dtm-ui-select-field
        [formControl]="propertiesForm.controls.driveType"
        [placeholder]="'dtmWebAppLibUav.setupForm.flightTechnicalProperties.driveTypePlaceholder' | transloco"
    >
        <label>
            {{ "dtmSharedUav.setupProperties.flightTechnicalProperties.driveTypeLabel" | transloco }}
        </label>
        <dtm-ui-select-option *ngFor="let driveType of driveTypes" [value]="driveType">
            {{ "dtmSharedUav.setupProperties.flightTechnicalProperties.driveTypeValue" | transloco : { value: driveType } }}
        </dtm-ui-select-option>
        <div class="field-error" *dtmUiFieldHasError="propertiesForm.controls.driveType; name: ['required']">
            {{ "dtmWebAppLibUav.setupForm.requiredFieldErrorHint" | transloco }}
        </div>
    </dtm-ui-select-field>
    <dtm-ui-input-field>
        <label>
            {{ "dtmSharedUav.setupProperties.flightTechnicalProperties.takeOffMassLabel" | transloco }}
        </label>
        <input
            matInput
            type="number"
            [formControl]="propertiesForm.controls.takeOffMass"
            [min]="MIN_TAKE_OFF_MASS_VALUE"
            [max]="MAX_TAKE_OFF_MASS_VALUE"
            inputmode="decimal"
        />
        <div class="field-suffix">
            {{ "dtmWebAppLibUav.setupForm.flightTechnicalProperties.takeOffMassUnitLabel" | transloco }}
        </div>
        <div
            class="field-hint field-warning"
            *ngIf="propertiesForm.controls.takeOffMass.value && propertiesForm.controls.takeOffMass.value >= MASS_WARNING_THRESHOLD"
        >
            <div class="warning-icon">
                <dtm-ui-icon name="error-warning"></dtm-ui-icon>
            </div>
            <div class="warning-text">
                {{ "dtmWebAppLibUav.setupForm.massInKilogramsWarningHint" | transloco }}
            </div>
        </div>
        <div class="field-error" *dtmUiFieldHasError="propertiesForm.controls.takeOffMass; name: ['required', 'pattern']">
            {{ "dtmWebAppLibUav.setupForm.requiredFieldErrorHint" | transloco }}
        </div>
        <div class="field-error" *dtmUiFieldHasError="propertiesForm.controls.takeOffMass; name: 'min'; error as error">
            {{ "dtmWebAppLibUav.setupForm.valueLessThanMinErrorHint" | transloco : { min: error.min | localizeNumber } }}
        </div>
        <div class="field-error" *dtmUiFieldHasError="propertiesForm.controls.takeOffMass; name: 'max'; error as error">
            {{ "dtmWebAppLibUav.setupForm.valueGreaterThanMaxErrorHint" | transloco : { max: error.max | localizeNumber } }}
        </div>
    </dtm-ui-input-field>
    <dtm-ui-input-field>
        <label>
            {{ "dtmSharedUav.setupProperties.flightTechnicalProperties.maxTakeOffMassLabel" | transloco }}
        </label>
        <input
            matInput
            type="number"
            [formControl]="propertiesForm.controls.maxTakeOffMass"
            [min]="MIN_MAX_TAKE_OFF_MASS_VALUE"
            [max]="MAX_MAX_TAKE_OFF_MASS_VALUE"
            inputmode="decimal"
        />
        <div class="field-suffix">
            {{ "dtmWebAppLibUav.setupForm.flightTechnicalProperties.maxTakeOffMassUnitLabel" | transloco }}
        </div>
        <div
            class="field-hint field-warning"
            *ngIf="propertiesForm.controls.maxTakeOffMass.value && propertiesForm.controls.maxTakeOffMass.value >= MASS_WARNING_THRESHOLD"
        >
            <div class="warning-icon">
                <dtm-ui-icon name="error-warning"></dtm-ui-icon>
            </div>
            <div class="warning-text">
                {{ "dtmWebAppLibUav.setupForm.massInKilogramsWarningHint" | transloco }}
            </div>
        </div>
        <div class="field-error" *dtmUiFieldHasError="propertiesForm.controls.maxTakeOffMass; name: ['required', 'pattern']">
            {{ "dtmWebAppLibUav.setupForm.requiredFieldErrorHint" | transloco }}
        </div>
        <div class="field-error" *dtmUiFieldHasError="propertiesForm.controls.maxTakeOffMass; name: 'min'; error as error">
            {{ "dtmWebAppLibUav.setupForm.valueLessThanMinErrorHint" | transloco : { min: error.min | localizeNumber } }}
        </div>
        <div class="field-error" *dtmUiFieldHasError="propertiesForm.controls.maxTakeOffMass; name: 'max'; error as error">
            {{ "dtmWebAppLibUav.setupForm.valueGreaterThanMaxErrorHint" | transloco : { max: error.max | localizeNumber } }}
        </div>
    </dtm-ui-input-field>
    <dtm-ui-input-field>
        <label>
            {{ "dtmSharedUav.setupProperties.flightTechnicalProperties.maxDroneWidthLabel" | transloco }}
        </label>
        <input matInput type="number" [formControl]="propertiesForm.controls.maxDroneWidth" inputmode="decimal" />
        <div class="field-suffix">
            {{ "dtmWebAppLibUav.setupForm.flightTechnicalProperties.maxDroneWidthUnitLabel" | transloco }}
        </div>
        <div class="field-error" *dtmUiFieldHasError="propertiesForm.controls.maxDroneWidth; name: ['required', 'pattern']">
            {{ "dtmWebAppLibUav.setupForm.requiredFieldErrorHint" | transloco }}
        </div>
        <div class="field-error" *dtmUiFieldHasError="propertiesForm.controls.maxDroneWidth; name: 'min'; error as error">
            {{ "dtmWebAppLibUav.setupForm.valueLessThanMinErrorHint" | transloco : { min: error.min | localizeNumber } }}
        </div>
        <div class="field-error" *dtmUiFieldHasError="propertiesForm.controls.maxDroneWidth; name: 'max'; error as error">
            {{ "dtmWebAppLibUav.setupForm.valueGreaterThanMaxErrorHint" | transloco : { max: error.max | localizeNumber } }}
        </div>
    </dtm-ui-input-field>
    <dtm-ui-input-field>
        <label>
            {{ "dtmSharedUav.setupProperties.flightTechnicalProperties.maxFlightTimeLabel" | transloco }}
            <dtm-ui-popover
                [popoverText]="'dtmSharedUav.setupProperties.flightTechnicalProperties.maxFlightTimeDescription' | transloco"
            ></dtm-ui-popover>
        </label>
        <input
            matInput
            type="number"
            [formControl]="propertiesForm.controls.maxFlightTime"
            [min]="MIN_MAX_FLIGHT_TIME_VALUE"
            [max]="MAX_MAX_FLIGHT_TIME_VALUE"
            inputmode="numeric"
        />
        <div class="field-suffix">
            {{ "dtmWebAppLibUav.setupForm.flightTechnicalProperties.maxFlightTimeUnitLabel" | transloco }}
        </div>
        <div class="field-error" *dtmUiFieldHasError="propertiesForm.controls.maxFlightTime; name: ['required', 'pattern']">
            {{ "dtmWebAppLibUav.setupForm.requiredFieldErrorHint" | transloco }}
        </div>
        <div class="field-error" *dtmUiFieldHasError="propertiesForm.controls.maxFlightTime; name: 'min'; error as error">
            {{ "dtmWebAppLibUav.setupForm.valueLessThanMinErrorHint" | transloco : { min: error.min | localizeNumber } }}
        </div>
        <div class="field-error" *dtmUiFieldHasError="propertiesForm.controls.maxFlightTime; name: 'max'; error as error">
            {{ "dtmWebAppLibUav.setupForm.valueGreaterThanMaxErrorHint" | transloco : { max: error.max | localizeNumber } }}
        </div>
    </dtm-ui-input-field>
    <div class="switcher">
        <label>
            {{ "dtmSharedUav.setupProperties.flightTechnicalProperties.flightSpeedLimitLabel" | transloco }}
        </label>
        <mat-slide-toggle [formControl]="propertiesForm.controls.hasFlightSpeedLimit"></mat-slide-toggle>
    </div>
    <dtm-ui-input-field *ngIf="isMinFlightSpeedVisible$ | ngrxPush">
        <label>
            {{ "dtmSharedUav.setupProperties.flightTechnicalProperties.minFlightSpeedLabel" | transloco }}
        </label>
        <input
            matInput
            type="number"
            [formControl]="propertiesForm.controls.minFlightSpeed"
            [min]="MIN_MIN_FLIGHT_SPEED_VALUE"
            inputmode="decimal"
        />
        <div class="field-suffix">
            {{ "dtmWebAppLibUav.setupForm.flightTechnicalProperties.minFlightSpeedUnitLabel" | transloco }}
        </div>
        <div class="field-error" *dtmUiFieldHasError="propertiesForm.controls.minFlightSpeed; name: ['required', 'pattern']">
            {{ "dtmWebAppLibUav.setupForm.requiredFieldErrorHint" | transloco }}
        </div>
        <div class="field-error" *dtmUiFieldHasError="propertiesForm.controls.minFlightSpeed; name: 'min'; error as error">
            {{ "dtmWebAppLibUav.setupForm.valueLessThanMinErrorHint" | transloco : { min: error.min | localizeNumber } }}
        </div>
        <div class="field-error" *dtmUiFieldHasError="propertiesForm.controls.minFlightSpeed; name: 'max'; error as error">
            {{ "dtmWebAppLibUav.setupForm.valueGreaterThanMaxErrorHint" | transloco : { max: error.max | localizeNumber } }}
        </div>
    </dtm-ui-input-field>
    <dtm-ui-input-field>
        <label>
            {{ "dtmSharedUav.setupProperties.flightTechnicalProperties.maxFlightSpeedLabel" | transloco }}
        </label>
        <input
            matInput
            type="number"
            [formControl]="propertiesForm.controls.maxFlightSpeed"
            [min]="MIN_MAX_FLIGHT_SPEED_VALUE"
            [max]="MAX_MAX_FLIGHT_SPEED_VALUE"
            inputmode="decimal"
        />
        <div class="field-suffix">
            {{ "dtmWebAppLibUav.setupForm.flightTechnicalProperties.maxFlightSpeedUnitLabel" | transloco }}
        </div>
        <div class="field-error" *dtmUiFieldHasError="propertiesForm.controls.maxFlightSpeed; name: ['required', 'pattern']">
            {{ "dtmWebAppLibUav.setupForm.requiredFieldErrorHint" | transloco }}
        </div>
        <div class="field-error" *dtmUiFieldHasError="propertiesForm.controls.maxFlightSpeed; name: 'min'; error as error">
            {{ "dtmWebAppLibUav.setupForm.valueLessThanMinErrorHint" | transloco : { min: error.min | localizeNumber } }}
        </div>
        <div class="field-error" *dtmUiFieldHasError="propertiesForm.controls.maxFlightSpeed; name: 'max'; error as error">
            {{ "dtmWebAppLibUav.setupForm.valueGreaterThanMaxErrorHint" | transloco : { max: error.max | localizeNumber } }}
        </div>
    </dtm-ui-input-field>
    <dtm-ui-input-field>
        <label>
            {{ "dtmSharedUav.setupProperties.flightTechnicalProperties.maxClimbSpeedLabel" | transloco }}
            {{ "dtmWebAppLibUav.setupForm.optionalLabel" | transloco }}
        </label>
        <input
            matInput
            type="number"
            [formControl]="propertiesForm.controls.maxClimbSpeed"
            [min]="MIN_MAX_CLIMB_SPEED_VALUE"
            [max]="MAX_MAX_CLIMB_SPEED_VALUE"
            inputmode="decimal"
        />
        <div class="field-suffix">
            {{ "dtmWebAppLibUav.setupForm.flightTechnicalProperties.maxClimbSpeedUnitLabel" | transloco }}
        </div>
        <div class="field-error" *dtmUiFieldHasError="propertiesForm.controls.maxClimbSpeed; name: 'min'; error as error">
            {{ "dtmWebAppLibUav.setupForm.valueLessThanMinErrorHint" | transloco : { min: error.min | localizeNumber } }}
        </div>
        <div class="field-error" *dtmUiFieldHasError="propertiesForm.controls.maxClimbSpeed; name: 'max'; error as error">
            {{ "dtmWebAppLibUav.setupForm.valueGreaterThanMaxErrorHint" | transloco : { max: error.max | localizeNumber } }}
        </div>
    </dtm-ui-input-field>
    <dtm-ui-input-field>
        <label>
            {{ "dtmSharedUav.setupProperties.flightTechnicalProperties.maxDescentSpeedLabel" | transloco }}
            {{ "dtmWebAppLibUav.setupForm.optionalLabel" | transloco }}
        </label>
        <input
            matInput
            type="number"
            [formControl]="propertiesForm.controls.maxDescentSpeed"
            [min]="MIN_MAX_DESCENT_SPEED_VALUE"
            [max]="MAX_MAX_DESCENT_SPEED_VALUE"
            inputmode="decimal"
        />
        <div class="field-suffix">
            {{ "dtmWebAppLibUav.setupForm.flightTechnicalProperties.maxDescentSpeedUnitLabel" | transloco }}
        </div>
        <div class="field-error" *dtmUiFieldHasError="propertiesForm.controls.maxDescentSpeed; name: 'min'; error as error">
            {{ "dtmWebAppLibUav.setupForm.valueLessThanMinErrorHint" | transloco : { min: error.min | localizeNumber } }}
        </div>
        <div class="field-error" *dtmUiFieldHasError="propertiesForm.controls.maxDescentSpeed; name: 'max'; error as error">
            {{ "dtmWebAppLibUav.setupForm.valueGreaterThanMaxErrorHint" | transloco : { max: error.max | localizeNumber } }}
        </div>
    </dtm-ui-input-field>
    <dtm-ui-input-field>
        <label>
            {{ "dtmSharedUav.setupProperties.flightTechnicalProperties.maxWindLabel" | transloco }}
            {{ "dtmWebAppLibUav.setupForm.optionalLabel" | transloco }}
        </label>
        <input
            matInput
            type="number"
            [formControl]="propertiesForm.controls.maxWind"
            [min]="MIN_MAX_WIND_VALUE"
            [max]="MAX_MAX_WIND_VALUE"
            inputmode="decimal"
        />
        <div class="field-suffix">
            {{ "dtmWebAppLibUav.setupForm.flightTechnicalProperties.maxWindUnitLabel" | transloco }}
        </div>
        <div class="field-error" *dtmUiFieldHasError="propertiesForm.controls.maxWind; name: 'min'; error as error">
            {{ "dtmWebAppLibUav.setupForm.valueLessThanMinErrorHint" | transloco : { min: error.min | localizeNumber } }}
        </div>
        <div class="field-error" *dtmUiFieldHasError="propertiesForm.controls.maxWind; name: 'max'; error as error">
            {{ "dtmWebAppLibUav.setupForm.valueGreaterThanMaxErrorHint" | transloco : { max: error.max | localizeNumber } }}
        </div>
    </dtm-ui-input-field>
    <div class="switcher">
        <label>
            {{ "dtmSharedUav.setupProperties.flightTechnicalProperties.rainFlightPossibilityLabel" | transloco }}
        </label>
        <mat-slide-toggle [formControl]="propertiesForm.controls.hasRainFlightPossibility"></mat-slide-toggle>
    </div>
    <dtm-ui-input-field>
        <label>
            {{ "dtmSharedUav.setupProperties.flightTechnicalProperties.maxFlightAltitudeLabel" | transloco }}
            {{ "dtmWebAppLibUav.setupForm.optionalLabel" | transloco }}
        </label>
        <input
            matInput
            type="number"
            [formControl]="propertiesForm.controls.maxFlightAltitude"
            [min]="MIN_MAX_FLIGHT_ALTITUDE_VALUE"
            [max]="MAX_MAX_FLIGHT_ALTITUDE_VALUE"
            inputmode="decimal"
        />
        <div class="field-suffix">
            {{ "dtmWebAppLibUav.setupForm.flightTechnicalProperties.maxFlightAltitudeUnitLabel" | transloco }}
        </div>
        <div class="field-error" *dtmUiFieldHasError="propertiesForm.controls.maxFlightAltitude; name: 'min'; error as error">
            {{ "dtmWebAppLibUav.setupForm.valueLessThanMinErrorHint" | transloco : { min: error.min | localizeNumber } }}
        </div>
        <div class="field-error" *dtmUiFieldHasError="propertiesForm.controls.maxFlightAltitude; name: 'max'; error as error">
            {{ "dtmWebAppLibUav.setupForm.valueGreaterThanMaxErrorHint" | transloco : { max: error.max | localizeNumber } }}
        </div>
    </dtm-ui-input-field>
    <dtm-ui-input-field>
        <label>
            {{ "dtmWebAppLibUav.setupForm.flightTechnicalProperties.minRecommendedAmbientTemperatureLabel" | transloco }}
            {{ "dtmWebAppLibUav.setupForm.optionalLabel" | transloco }}
        </label>
        <input
            matInput
            type="number"
            [formControl]="propertiesForm.controls.minRecommendedAmbientTemperature"
            [min]="MIN_TEMPERATURE_VALUE"
            [max]="MAX_TEMPERATURE_VALUE"
            inputmode="decimal"
        />
        <div class="field-suffix">
            {{ "dtmWebAppLibUav.setupForm.flightTechnicalProperties.recommendedAmbientTemperatureUnitLabel" | transloco }}
        </div>
        <div
            class="field-error"
            *dtmUiFieldHasError="propertiesForm.controls.minRecommendedAmbientTemperature; name: 'min'; error as error"
        >
            {{ "dtmWebAppLibUav.setupForm.valueLessThanMinErrorHint" | transloco : { min: error.min | localizeNumber } }}
        </div>
        <div
            class="field-error"
            *dtmUiFieldHasError="propertiesForm.controls.minRecommendedAmbientTemperature; name: 'max'; error as error"
        >
            {{ "dtmWebAppLibUav.setupForm.valueGreaterThanMaxErrorHint" | transloco : { max: error.max | localizeNumber } }}
        </div>
        <div class="field-error" *dtmUiFieldHasError="propertiesForm; name: 'temperatureRange'">
            {{ "dtmWebAppLibUav.setupForm.flightTechnicalProperties.invalidTemperatureRangeErrorHint" | transloco }}
        </div>
    </dtm-ui-input-field>
    <dtm-ui-input-field>
        <label>
            {{ "dtmWebAppLibUav.setupForm.flightTechnicalProperties.maxRecommendedAmbientTemperatureLabel" | transloco }}
            {{ "dtmWebAppLibUav.setupForm.optionalLabel" | transloco }}
        </label>
        <input
            matInput
            type="number"
            [formControl]="propertiesForm.controls.maxRecommendedAmbientTemperature"
            [min]="MIN_TEMPERATURE_VALUE"
            [max]="MAX_TEMPERATURE_VALUE"
            inputmode="decimal"
        />
        <div class="field-suffix">
            {{ "dtmWebAppLibUav.setupForm.flightTechnicalProperties.recommendedAmbientTemperatureUnitLabel" | transloco }}
        </div>
        <div
            class="field-error"
            *dtmUiFieldHasError="propertiesForm.controls.maxRecommendedAmbientTemperature; name: 'min'; error as error"
        >
            {{ "dtmWebAppLibUav.setupForm.valueLessThanMinErrorHint" | transloco : { min: error.min | localizeNumber } }}
        </div>
        <div
            class="field-error"
            *dtmUiFieldHasError="propertiesForm.controls.maxRecommendedAmbientTemperature; name: 'max'; error as error"
        >
            {{ "dtmWebAppLibUav.setupForm.valueGreaterThanMaxErrorHint" | transloco : { max: error.max | localizeNumber } }}
        </div>
        <div class="field-error" *dtmUiFieldHasError="propertiesForm; name: 'temperatureRange'">
            {{ "dtmWebAppLibUav.setupForm.flightTechnicalProperties.invalidTemperatureRangeErrorHint" | transloco }}
        </div>
    </dtm-ui-input-field>
</ng-container>
