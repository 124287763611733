import {
    AirRiskMitigationsCategory,
    MissionCategory,
    MissionPlanSpecificPermitType,
    MissionType,
    PopulationDensity,
    RobustnessLevel,
} from "@dtm-frontend/shared/mission";
import { Address, GeoJSON, OperatorType, PhoneNumber, UavType } from "@dtm-frontend/shared/ui";
import { LanguageCode } from "@dtm-frontend/shared/ui/i18n";
import { DeepNonNullable } from "@dtm-frontend/shared/utils";
import { PilotCompetencies } from "../../shared";
import { GuideChapterItem, Sail } from "../../shared/models/operations-manual.models";

export enum ApplicationCreatorWizardSteps {
    OperationInfo = "OperationInfo",
    OperationDetails = "OperationDetails",
    Statements = "Statements",
    ApplicationInfo = "ApplicationInfo",
}

export enum SpecificPermitApplicationErrorType {
    Unknown = "SpecificPermitErrorUnknown",
    CannotGetCapabilities = "CannotGetCapabilities",
    CannotGetOperations = "CannotGetOperations",
    CannotGetOperation = "CannotGetOperation",
    InvalidOperator = "InvalidOperator",
    CannotSaveDraft = "CannotSaveDraft",
    CannotGetArea = "CannotGetArea",
    CannotGetRequiredPilotTrainings = "CannotGetRequiredPilotTrainings",
    CannotLoadDraft = "CannotLoadDraft",
    InvalidApplicationData = "InvalidApplicationData",
    CannotGenerateApplication = "CannotGenerateApplication",
    CannotDownloadDocumentTemplate = "CannotDownloadDocumentTemplate",
    CannotGetOperationGeometryData = "CannotGetOperationGeometryData",
}

export enum UavClass {
    C0 = "C0",
    C1 = "C1",
    C2 = "C2",
    C3 = "C3",
    C4 = "C4",
    C5 = "C5",
    C6 = "C6",
}

export enum CrewMember {
    Pilot = "PILOT",
    UavObserver = "UAV_OBSERVER",
    AirspaceObserver = "AIRSPACE_OBSERVER",
    FlightManager = "FLIGHT_MANAGER",
    DeputyFlightManager = "DEPUTY_FLIGHT_MANAGER",
    SafetyManager = "SAFETY_MANAGER",
    TechnicalStaff = "TECHNICAL_STAFF",
    AuxiliaryStaff = "AUXILIARY_STAFF",
}

export enum OperationAreaType {
    Operation = "MISSION_PLAN",
    CustomArea = "CUSTOM_AREA",
    PredefinedArea = "PREDEFINED_AREA",
}

export enum TimeOfTheDay {
    Day = "DAY",
    Night = "NIGHT",
    DayAndNight = "DAY_AND_NIGHT",
}

export enum TacticalMitigationPerformanceRequirement {
    VLOS = "VLOS",
    A = "A",
    B = "B",
    C = "C",
    D = "D",
    Unknown = "Unknown",
}

export enum FailSafe {
    ReturnToHome = "RETURN_TO_HOME",
    ReturnToLaunch = "RETURN_TO_LAUNCH",
    AutoLand = "AUTO_LAND",
    Hover = "HOVER",
}

export enum DocumentTemplate {
    EmergencyActionPlan = "EMERGENCY_ACTION_PLAN",
    OperationsManual = "INOP",
    AttorneyPower = "ATTORNEY_POWER",
}

export enum OsoStatementCriterionItemType {
    Criterion = "CRITERION",
    Note = "NOTE",
    Annotation = "ANNOTATION",
}

export interface SpecificPermitApplicationError {
    type: SpecificPermitApplicationErrorType;
}

export interface Capabilities {
    guideChapterItems: GuideChapterItem[];
    availableUavs: Uav[];
    flightPurposes: FlightPurpose[];
    predefinedAreas: PredefinedArea[];
    operationScenarios: OperationScenario[];
    trainings: Training[];
    crewMembers: CrewMember[];
    initialViewbox: GeoJSON;
}

export interface Uav {
    id: string;
    name: string;
    serialNumbers: string[];
    model: {
        id: string;
        name: string;
        manufacturer: string;
        type: UavType;
        uavClass: UavClass[];
    };
    isSwarm: boolean;
}

export interface FlightPurpose {
    id: string;
    codename: string;
    name: string;
    description: string;
    isLoadWeightRequired: boolean;
    isCommentRequired: boolean;
}

export interface OperationSearchItem {
    id: string;
    name: string;
}

export interface OperationBaseInformation {
    operator: OperationOperator;
    pilotCompetencies: OperationScenario[];
    uav: OperationUav;
    operation: Operation;
}

export interface OperationOperator {
    id: string;
    name: string;
    number: string;
    type: OperatorType;
    companyNumber?: string;
    insurancePolicy?: {
        number: string;
        expirationDate: Date;
    };
    address: Address;
    operationalAuthorizations: Competency[];
}

export interface OperationScenario {
    id: string;
    name: PilotCompetencies;
    category: MissionCategory;
}

export interface Competency {
    id: string;
    operationScenario: OperationScenario;
    expirationDate: Date | null;
}

export interface Training {
    id: string;
    name: {
        [language in LanguageCode]: string;
    };
}

export interface OperationUav extends Uav {
    technicalSpecification: {
        maxTakeOffMass: number;
        maxDroneWidth: number;
        maxFlightSpeed: number;
    };
    certificate: {
        noiseCertificateNumber?: string;
        airworthinessCertificateNumber?: string;
        typeCertificateNumberOrDvrReport?: string;
    };
}

export interface Operation {
    id: string;
    name: string;
    description?: string;
    flightStartAt: Date;
    flightFinishAt: Date;
    category: {
        type: MissionCategory;
        subtype: MissionPlanSpecificPermitType;
        scenarioName: PilotCompetencies;
        isSpecificLucSkipSora: boolean;
        specificCaaPermitId: string;
    };
    sail: Sail;
    flight: {
        flightType: MissionType;
        purposeCode: string;
        purposeComment: string;
        loadWeight: number | null;
        areDangerousGoods: boolean;
        maxSpeed: number;
        pilotReactionDelay: number;
    };
    flightArea: {
        estimatedDistance: number;
        maxWidth: number;
        maxHeight: number;
        safetyAreaWidth: number;
        safetyAreaHeight: number;
        groundRiskBuffer: number;
    };
    adjacentArea: {
        bufferHorizontal: number;
        bufferVertical: number;
        size: number;
    };
    groundRisk: GroundRisk;
    airspaceRisk: AirspaceRisk;
    adjacentAreaRisk: AdjacentAreaRisk;
    additionalCrew: AdditionalCrewMember[];
}

export interface AdditionalCrewMember {
    type: CrewMember;
    amount: number;
}

export interface AirspaceRisk {
    isUncontrolledAirspace: boolean;
    isControlledAirspace: boolean;
    isAroundObstacleOnly: boolean;
    isSegregatedAirspaceOnly: boolean;
    isFlightLowAgl: boolean;
    initialArc: number;
    residualArc: number;
    mitigations: AirspaceMitigationMeasure[];
    tmpr: TacticalMitigationPerformanceRequirement;
}

export interface AdjacentAreaRisk {
    peoplePerSquareKilometerAvg: number;
    intrinsicGrc: number;
    isPeopleAssembly: boolean;
    intrinsicGrl: number;
    initialArc: number;
    isFlightTerminationSystemAvailable: boolean;
    failSafe: FailSafe[];
    hasGeofencing: boolean;
    hasGeocage: boolean;
}

export interface AirspaceMitigationMeasure {
    category: AirRiskMitigationsCategory;
    initialArc: number | null;
    residualArc: number | null;
    methods: Array<{
        title: string;
        details: string;
    }>;
    designators: string[];
}

export interface GroundRisk {
    populationDensity: PopulationDensity;
    controlledGroundAreaComment: string | null;
    peopleAssemblyComment: string | null;
    finalGrl: number;
    intrinsicGrc: number;
    finalGrc: number;
    mitigations: {
        m1A?: {
            robustnessLevel: RobustnessLevel;
            statements: MitigationMeasureStatement[];
        };
        m1B?: {
            robustnessLevel: RobustnessLevel;
            statements: MitigationMeasureStatement[];
        };
        m2?: {
            robustnessLevel: RobustnessLevel;
            statements: MitigationMeasureStatement[];
        };
    };
}

export interface MitigationMeasureStatement {
    name: string;
    comments: MitigationMeasureStatementComment[];
}

export interface MitigationMeasureStatementComment {
    content: string;
    subItems?: string[];
}

export interface DraftData {
    [ApplicationCreatorWizardSteps.OperationInfo]?: OperationInfoData;
    [ApplicationCreatorWizardSteps.OperationDetails]?: OperationDetailsData;
    [ApplicationCreatorWizardSteps.Statements]?: StatementsData;
    [ApplicationCreatorWizardSteps.ApplicationInfo]?: ApplicationInfoData;
}

export interface ApplicationData {
    [ApplicationCreatorWizardSteps.OperationInfo]: FinalOperationInfoData;
    [ApplicationCreatorWizardSteps.OperationDetails]: FinalOperationDetailsData;
    [ApplicationCreatorWizardSteps.Statements]: FinalStatementsData;
    [ApplicationCreatorWizardSteps.ApplicationInfo]: FinalApplicationInfoData;
}

export interface OperationInfoData {
    operation: {
        id: string;
        name: string;
    } | null;
    specificPermitNumber: string | null;
    uavInfo: UavInfo | null;
    uavAdditionalInfo: UavAdditionalInfo | null;
}

export interface OperationDetailsData {
    operationsManualVersion: string | null;
    operationsManualChapter: number | null;
    operationArea: Area | null;
    pilotCompetencies: StaffCompetencies | null;
    pilotOptionalTrainings: StaffTrainings | null;
    additionalCrewMembers: AdditionalCrew | null;
    crewCompetencies: CrewCompetencies | null;
    crewTrainings: CrewTrainings | null;
    timeOfDay: TimeOfDayInfo | null;
    maxWindSpeed: number | null;
    minVisibility: number | null;
    minTemperature: number | null;
    maxTemperature: number | null;
    isFlightDuringRain: boolean;
}

export interface StatementsData {
    isStatementChecked: boolean;
    osoNumbers: number[];
}

export interface ApplicationInfoData {
    responsibleManager: string | null;
    contactPersonFullName: string | null;
    contactPersonPhone: PhoneNumber | null;
    contactPersonEmail: string | null;
    additionalNote: string | null;
}

export type FinalOperationInfoData = DeepNonNullable<OperationInfoData, "uavInfo">;
export type FinalOperationDetailsData = DeepNonNullable<
    OperationDetailsData,
    "pilotOptionalTrainings" | "additionalCrewMembers" | "crewCompetencies" | "crewTrainings"
>;
export interface FinalStatementsData extends DeepNonNullable<StatementsData> {
    osoNumbers: number[];
}
export type FinalApplicationInfoData = DeepNonNullable<ApplicationInfoData, "responsibleManager" | "additionalNote">;

export interface UavInfo {
    uavs: Uav[];
    isStatementChecked: boolean;
}

export interface UavAdditionalInfo {
    hasUavSetupBeenUsedPreviously: boolean;
    operationsManualChapter: number | null;
    technicalService: string | null;
    c3Link: string | null;
    hmi: string | null;
    adverseEnvironmentalConditions: string | null;
    dvrOrTcNumber: string | null;
}

export interface OperationArea {
    type: OperationAreaType.Operation;
}

export interface CustomAreaLocation {
    type: OperationAreaType.CustomArea;
    area: GeoJSON | null;
}

export interface PredefinedAreaLocation {
    type: OperationAreaType.PredefinedArea;
    predefinedAreaId: string | null;
}

export type Area = OperationArea | CustomAreaLocation | PredefinedAreaLocation;

export interface PredefinedArea {
    id: string;
    name: string;
}

export interface PredefinedAreaDescription {
    area: GeoJSON;
    name: string;
    description: string | undefined;
}

export interface StaffCompetencies {
    basic: OperationScenario[];
    additional: string[];
    hasEuRegulationCompetency: boolean;
}

export type CrewCompetencies = {
    [member in CrewMember]?: StaffCompetencies | null;
};

export interface StaffTrainings {
    basic: Training[];
    additional: string[];
}

export type CrewTrainings = {
    [member in CrewMember]?: StaffTrainings | null;
};

export type AdditionalCrew = Array<{
    member: CrewMember;
    amount: number | null;
    responsibilities: string | null;
}>;

export interface TimeOfDayInfo {
    timeOfDay: TimeOfTheDay;
    timeFrom?: Date;
    timeTo?: Date;
    isNightFlightStatement?: boolean;
}

export interface OsoStatementGroup {
    osoNumbers: number[];
    robustnessLevel: RobustnessLevel;
    titleKey: string;
    descriptionKey?: string;
    criteriaGroups: OsoStatementCriteriaGroup[];
}

export interface OsoStatementCriteriaGroup {
    titleKey: string;
    criteria: OsoStatementCriterionItem[];
}

export interface OsoStatementCriterionItem {
    type: OsoStatementCriterionItemType;
    contentHtmlKey: string;
    annotationHtmlKey?: string;
    comments?: Array<{
        id: string;
        contentHtmlKey: string;
    }>;
}

export interface ApplicationDraft {
    id: string;
    name: string;
    data: DraftData;
}
