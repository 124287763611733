import { ChangeDetectionStrategy, Component, forwardRef, Input } from "@angular/core";
import {
    ControlValueAccessor,
    FormControl,
    FormGroup,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
    ValidatorFn,
    Validators,
} from "@angular/forms";
import { M2StatementsKeys, MissionUAVSetup, RiskMitigationM2, RobustnessLevel } from "@dtm-frontend/shared/mission";
import { UploadedFile } from "@dtm-frontend/shared/ui";
import { AnimationUtils, BYTES_IN_MEGABYTE, FunctionUtils, LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { combineLatestWith, map } from "rxjs/operators";

const ALLOWED_FILE_EXTENSION = [".pdf", ".docx", ".doc", ".jpg", ".jpeg", ".png"];
const MAX_FILE_SIZE_MB = 20;
const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * BYTES_IN_MEGABYTE;
const MAX_FILES_LENGTH = 1;
const MAX_COMMENT_LENGTH = 1000;
const OBSTACLES_LINK = "https://www.casex.one/obstacles";
const MAX_UAV_WIDTH_IN_METER = 1;

interface RiskMitigationM2ComponentState {
    criticalArea: number | undefined;
    thresholds: RiskMitigationThreshold[];
    uavSetup: MissionUAVSetup | undefined;
}

interface RiskMitigationThreshold {
    min: number;
    max: number;
    criticalArea: number;
}

const riskMitigationThresholds: RiskMitigationThreshold[] = [
    { min: 8000, max: Number.MAX_VALUE, criticalArea: 80000 },
    { min: 800, max: 8000, criticalArea: 8000 },
    { min: 80, max: 800, criticalArea: 800 },
    { min: 8, max: 80, criticalArea: 80 },
    { min: Number.MIN_VALUE, max: 8, criticalArea: 8 },
];

const riskMitigationThresholdsForTinyUav: RiskMitigationThreshold[] = [
    { min: 8000, max: Number.MAX_VALUE, criticalArea: 80000 },
    { min: 800, max: 8000, criticalArea: 8000 },
    { min: 80, max: 800, criticalArea: 800 },
    { min: 8, max: 80, criticalArea: 80 },
    { min: 0.8, max: 8, criticalArea: 8 },
    { min: Number.MIN_VALUE, max: 0.8, criticalArea: 0.8 },
];

const DEFAULT_ROBUSTNESS_LEVELS = [RobustnessLevel.Medium, RobustnessLevel.High, RobustnessLevel.HighPlus];

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-risk-mitigation-m2[criticalArea]",
    templateUrl: "./risk-mitigation-m2.component.html",
    styleUrls: ["./risk-mitigation-m2.component.scss"],
    providers: [
        LocalComponentStore,
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => RiskMitigationM2Component),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => RiskMitigationM2Component),
            multi: true,
        },
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [AnimationUtils.slideInAnimation()],
})
export class RiskMitigationM2Component implements ControlValueAccessor, Validator {
    @Input() public set criticalArea(value: number | undefined) {
        this.localStore.patchState({ criticalArea: value });
    }
    @Input() public set missionUavSetup(value: MissionUAVSetup | undefined) {
        this.localStore.patchState({ uavSetup: value });
    }

    protected readonly riskMitigationMeasureFormControl = new FormControl(false, { nonNullable: true });
    protected readonly robustnessLevelFormControl = new FormControl<RobustnessLevel | null>(null, {
        nonNullable: true,
        validators: this.requiredFormControlValidator(this.riskMitigationMeasureFormControl),
    });
    protected readonly isParachuteFormControl = new FormControl(false, { nonNullable: true });
    protected readonly isCriticalAreaShieldedFormControl = new FormControl(false, { nonNullable: true });
    protected readonly isOtherFormControl = new FormControl(false, { nonNullable: true });

    protected readonly parachuteUserStatementsFormGroup = new FormGroup({
        [M2StatementsKeys.operatorHasDevelopedProcedures]: new FormControl(false, {
            nonNullable: true,
            validators: this.requiredTrueFormControlValidator(this.isParachuteFormControl),
        }),
        [M2StatementsKeys.operatorProvidesPracticalAndTheoreticalStaffTraining]: new FormControl(false, {
            nonNullable: true,
            validators: this.requiredTrueFormControlValidator(this.isParachuteFormControl),
        }),
        [M2StatementsKeys.parachuteOperatingInstructionsIsAttached]: new FormControl(false, {
            nonNullable: true,
            validators: this.requiredTrueFormControlValidator(this.isParachuteFormControl),
        }),
    });

    protected readonly parachuteAttachmentFormControl = new FormControl<UploadedFile[] | undefined>(undefined, {
        nonNullable: true,
        validators: [this.requiredFormControlValidator(this.isParachuteFormControl), Validators.maxLength(MAX_FILES_LENGTH)],
    });

    protected readonly criticalAreaShieldedAttachmentFormControl = new FormControl<UploadedFile[] | undefined>(undefined, {
        validators: [this.requiredFormControlValidator(this.isCriticalAreaShieldedFormControl), Validators.maxLength(MAX_FILES_LENGTH)],
    });

    protected readonly otherAttachment = new FormControl<UploadedFile[] | undefined>(undefined, {
        nonNullable: true,
        validators: [this.requiredFormControlValidator(this.isOtherFormControl), Validators.maxLength(MAX_FILES_LENGTH)],
    });

    protected readonly otherComment = new FormControl<string | null>(null, [
        this.requiredFormControlValidator(this.isOtherFormControl),
        Validators.maxLength(MAX_COMMENT_LENGTH),
    ]);

    protected readonly riskMitigationFormGroup = new FormGroup(
        {
            riskMitigationMeasure: this.riskMitigationMeasureFormControl,
            robustnessLevel: this.robustnessLevelFormControl,
            isParachute: this.isParachuteFormControl,
            isCriticalAreaShielded: this.isCriticalAreaShieldedFormControl,
            isOther: this.isOtherFormControl,
            parachuteUserStatements: this.parachuteUserStatementsFormGroup,
            parachuteAttachment: this.parachuteAttachmentFormControl,
            criticalAreaShieldedAttachment: this.criticalAreaShieldedAttachmentFormControl,
            otherAttachment: this.otherAttachment,
            otherComment: this.otherComment,
        },
        this.requiredOneForControlValidator(
            this.riskMitigationMeasureFormControl,
            this.isParachuteFormControl,
            this.isCriticalAreaShieldedFormControl,
            this.isOtherFormControl
        )
    );

    protected readonly RobustnessLevel = RobustnessLevel;
    protected readonly M2StatementsKeys = M2StatementsKeys;
    protected readonly ALLOWED_FILE_EXTENSION = ALLOWED_FILE_EXTENSION;
    protected readonly MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_BYTES;
    protected readonly MAX_COMMENT_LENGTH = MAX_COMMENT_LENGTH;
    protected readonly MAX_FILES_LENGTH = MAX_FILES_LENGTH;
    protected readonly OBSTACLES_LINK = OBSTACLES_LINK;
    protected readonly DEFAULT_ROBUSTNESS_LEVELS = DEFAULT_ROBUSTNESS_LEVELS;

    protected readonly criticalArea$ = this.localStore.selectByKey("criticalArea");
    protected readonly thresholds$ = this.criticalArea$.pipe(
        combineLatestWith(this.localStore.selectByKey("uavSetup").pipe(RxjsUtils.filterFalsy())),
        map(([criticalArea, uavSetup]) => {
            const { maxDroneWidth } = uavSetup.technicalSpecification;
            const isTinyUav = maxDroneWidth <= MAX_UAV_WIDTH_IN_METER;
            const riskMitigationThresholdsToUse = isTinyUav ? riskMitigationThresholdsForTinyUav : riskMitigationThresholds;

            return this.getRiskMitigationThresholds(criticalArea, riskMitigationThresholdsToUse);
        })
    );

    private propagateTouch = FunctionUtils.noop;
    private onValidationChange = FunctionUtils.noop;
    private propagateChange: (value: RiskMitigationM2 | null) => void = FunctionUtils.noop;

    constructor(private readonly localStore: LocalComponentStore<RiskMitigationM2ComponentState>) {
        this.localStore.setState({
            criticalArea: undefined,
            thresholds: [],
            uavSetup: undefined,
        });
        this.setupRiskMitigationChangesWatch();

        this.robustnessLevelFormControl.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
            this.setDisableLevelState(value);
        });
    }

    public writeValue(value: RiskMitigationM2 | null): void {
        if (!value) {
            this.riskMitigationFormGroup.reset(undefined, { emitEvent: false });
        } else {
            this.riskMitigationFormGroup.reset(
                {
                    riskMitigationMeasure: true,
                    robustnessLevel: value.robustnessLevel,
                    isParachute: !!value.parachute?.userStatements?.find((data) => data === M2StatementsKeys.parachute),
                    isCriticalAreaShielded: !!value.criticalAreaShielded?.userStatements?.find(
                        (data) => data === M2StatementsKeys.criticalAreaShielded
                    ),
                    isOther: !!value.other?.userStatements?.find((data) => data === M2StatementsKeys.other),
                    parachuteUserStatements: this.mapStatementToFormValue(value.parachute?.userStatements),
                    parachuteAttachment: this.getAttachmentInfo(value, "parachute"),
                    criticalAreaShieldedAttachment: this.getAttachmentInfo(value, "criticalAreaShielded"),
                    otherAttachment: this.getAttachmentInfo(value, "other"),
                    otherComment: value.other?.comment,
                },
                { emitEvent: false }
            );
        }

        this.setDisableLevelState(this.robustnessLevelFormControl.value);
    }

    public registerOnChange(fn: (value: RiskMitigationM2 | null) => void): void {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.propagateTouch = fn;
    }

    public registerOnValidatorChange(fn: () => void): void {
        this.onValidationChange = fn;
    }

    public validate(): ValidationErrors | null {
        if (!this.riskMitigationMeasureFormControl.value || this.riskMitigationFormGroup.valid) {
            return null;
        }

        return {
            riskMitigationM2: true,
        };
    }

    protected requiredFormControlValidator(formControls: FormControl): ValidatorFn {
        return (control) => (formControls.value ? Validators.required(control) : null);
    }

    protected requiredTrueFormControlValidator(forControl: FormControl): ValidatorFn {
        return (control) => (forControl.value ? Validators.requiredTrue(control) : null);
    }

    protected requiredOneForControlValidator(forControl: FormControl, ...formsControls: FormControl[]): ValidatorFn {
        return () => {
            if (forControl.value && !formsControls.some((form) => FunctionUtils.isTruthy(form.value))) {
                return {
                    oneRequired: true,
                };
            }

            return null;
        };
    }

    protected getDisabledRobustnessLevels(thresholdsLength: number): RobustnessLevel[] {
        return DEFAULT_ROBUSTNESS_LEVELS.filter((_, index) => index >= thresholdsLength);
    }

    private setDisableLevelState(robustnessLevel: RobustnessLevel | null): void {
        if (!robustnessLevel) {
            this.isParachuteFormControl.disable({ emitEvent: false });
            this.isCriticalAreaShieldedFormControl.disable({ emitEvent: false });
            this.isOtherFormControl.disable({ emitEvent: false });

            return;
        }

        this.isParachuteFormControl.enable({ emitEvent: false });
        this.isCriticalAreaShieldedFormControl.enable({ emitEvent: false });
        this.isOtherFormControl.enable({ emitEvent: false });
    }

    private setupRiskMitigationChangesWatch() {
        this.riskMitigationFormGroup.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
            if (!value.riskMitigationMeasure) {
                this.propagateChange(null);

                return;
            }

            Object.values(this.riskMitigationFormGroup.controls).forEach((control) => {
                control.updateValueAndValidity({ emitEvent: false });
            });

            if (this.riskMitigationFormGroup.invalid) {
                this.onValidationChange();

                return;
            }

            const riskMitigationM2Value: RiskMitigationM2 = {
                robustnessLevel: value.robustnessLevel as RobustnessLevel,
                parachute: undefined,
                criticalAreaShielded: undefined,
                other: undefined,
            };

            if (this.isParachuteFormControl.value) {
                riskMitigationM2Value.parachute = {
                    userStatements: [
                        M2StatementsKeys.parachute,
                        ...((Object.entries(value.parachuteUserStatements ?? [])
                            .filter(([, isStated]) => isStated)
                            .map(([statementKey]) => statementKey) as M2StatementsKeys[]) ?? []),
                    ],
                    attachments: value.parachuteAttachment?.map(({ name, id }) => ({
                        name,
                        fileId: id,
                    })),
                };

                this.parachuteAttachmentFormControl.markAllAsTouched();
            }

            if (this.isCriticalAreaShieldedFormControl.value) {
                riskMitigationM2Value.criticalAreaShielded = {
                    userStatements: [M2StatementsKeys.criticalAreaShielded],
                    attachments: value.criticalAreaShieldedAttachment?.map(({ name, id }) => ({
                        name,
                        fileId: id,
                    })),
                };

                this.criticalAreaShieldedAttachmentFormControl.markAllAsTouched();
            }

            if (this.isOtherFormControl.value) {
                riskMitigationM2Value.other = {
                    userStatements: [M2StatementsKeys.other],
                    attachments: value.otherAttachment?.map(({ name, id }) => ({
                        name,
                        fileId: id,
                    })),
                    comment: value.otherComment ?? undefined,
                };

                this.criticalAreaShieldedAttachmentFormControl.markAllAsTouched();
            }

            this.propagateChange(riskMitigationM2Value);
        });
    }

    private mapStatementToFormValue(statement?: M2StatementsKeys[]) {
        return statement?.reduce((statements, key) => ({ ...statements, [key]: true }), {});
    }

    private getAttachmentInfo(mitigationValue: RiskMitigationM2, key: "parachute" | "criticalAreaShielded" | "other"): UploadedFile[] {
        return (
            mitigationValue[key]?.attachments?.map((file) => ({
                name: file.name,
                id: file.fileId,
            })) ?? []
        );
    }

    private getRiskMitigationThresholds(value: number | undefined, thresholds: RiskMitigationThreshold[]): RiskMitigationThreshold[] {
        const numberOfThresholds = 3;

        if (value === undefined) {
            return [];
        }

        const thresholdIndex = thresholds.findIndex(({ min, max }) => value > min && value <= max);

        if (thresholdIndex === -1) {
            return thresholds.slice(1, numberOfThresholds);
        }

        return thresholds.slice(thresholdIndex + 1, thresholdIndex + 1 + numberOfThresholds);
    }
}
