import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";

interface SetupPropertyTextComponentState {
    description: string | undefined;
    label: string | undefined;
    isEmpty: boolean;
}

@Component({
    selector: "dtm-uav-lib-setup-property",
    templateUrl: "./setup-property.component.html",
    styleUrls: ["./setup-property.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class SetupPropertyComponent {
    @Input({ required: true }) public set label(value: string | undefined) {
        this.localStore.patchState({ label: value });
    }

    @Input() public set description(value: string | undefined) {
        this.localStore.patchState({ description: value });
    }

    @Input() public set isEmpty(value: BooleanInput) {
        this.localStore.patchState({ isEmpty: coerceBooleanProperty(value) });
    }

    public readonly label$ = this.localStore.selectByKey("label");
    public readonly description$ = this.localStore.selectByKey("description");
    public readonly isEmpty$ = this.localStore.selectByKey("isEmpty");

    constructor(private readonly localStore: LocalComponentStore<SetupPropertyTextComponentState>) {
        this.localStore.setState({
            description: undefined,
            label: undefined,
            isEmpty: false,
        });
    }
}
