import { ChangeDetectionStrategy, Component, Input, NgZone } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { Toast, ToastPackage, ToastrService } from "ngx-toastr";

interface CircularBoundaryViolationToastComponentState {
    violatedRadius: number | undefined;
}

@Component({
    templateUrl: "circular-boundary-violation-toast.component.html",
    styleUrls: ["circular-boundary-violation-toast.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class CircularBoundaryViolationToastComponent extends Toast {
    @Input() public set violatedRadius(value: number | undefined) {
        this.localStore.patchState({ violatedRadius: value });
    }

    protected readonly violatedRadius$ = this.localStore.selectByKey("violatedRadius");

    constructor(
        private readonly localStore: LocalComponentStore<CircularBoundaryViolationToastComponentState>,
        toastrService: ToastrService,
        toastPackage: ToastPackage,
        ngZone: NgZone
    ) {
        super(toastrService, toastPackage, ngZone);

        this.localStore.setState({
            violatedRadius: toastPackage.message ? +toastPackage.message : undefined,
        });
    }
}
