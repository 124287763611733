import { HttpClient, HttpContext } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import {
    AirRisk,
    AirwaysCollisionTranslationArgs,
    AnalysisIssueSource,
    AnalysisIssueStatus,
    AnalysisOptionStatus,
    DensityBox,
    DensityBoxColor,
    MissionPlanAirspaceAnalysisOption,
    MissionPlanAnalysisEvaluationIssue,
    MissionPlanAnalysisIssue,
    MissionPlanAnalysisIssueStatus,
    MissionPlanAnalysisOptionStatus,
    MissionPlanAnalysisStatus,
    MissionPlanEvaluationOption,
    MissionPlanStatus,
    MissionPlanTrafficAnalysisOption,
    MissionPlanVerificationResponseBodyAnalysisIssue,
    PlanVerificationStatus,
    SoraResult,
    TacticalMitigationPerformanceRequirementProperty,
    TrafficCollisionTranslationArgs,
} from "@dtm-frontend/shared/mission";
import { ArrayUtils, FunctionUtils, Logger, RxjsUtils, SKIP_NOT_FOUND_HTTP_INTERCEPTOR, StringUtils } from "@dtm-frontend/shared/utils";
import { WebsocketService } from "@dtm-frontend/shared/websocket";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { MISSION_ENDPOINTS, MissionEndpoints } from "../mission.tokens";
import { MissionPlanVerificationResponseBodyEvaluationIssue } from "../models/mission-plan-verification.model";
import { MissionErrorType } from "../models/mission.model";
import { FlightTerminationSystemResponseBody, convertFlightTerminationSystem } from "./mission-api.converters";

interface MissionPlanVerificationResponseBody {
    planId: string;
    routeId: string;
    specificCaaPermitId: string;
    planStatus: MissionPlanStatus;
    planSubmittable: boolean;
    status: PlanVerificationStatus;
    manualVerificationRequired: boolean;
    trafficStatus: AnalysisOptionStatus;
    trafficRequired: boolean;
    airspace: {
        adjacentControlled: boolean;
        adjacentUncontrolled: boolean;
        operationalControlled: boolean;
        operationalUncontrolled: boolean;
    };
    airspaceStatus: AnalysisOptionStatus;
    evaluationIssues: MissionPlanVerificationResponseBodyEvaluationIssue[];
    analysisIssues: MissionPlanVerificationResponseBodyAnalysisIssue[];
    applyForSpecificPermit: boolean;
    caaPermitRequired: boolean;
    caaPermitStatus?: AnalysisOptionStatus;
    soraRequired: boolean;
    soraStatus?: AnalysisOptionStatus;
    soraResult?: {
        effectiveGrl: number;
        finalGrl: number;
        finalGrc: number;
        intrinsicGrc: number;
        groundRiskTradeoff: number;
        criticalArea: number;
        residualArc: number;
        initialArc: number;
        sail: number;
        tmpr?: TacticalMitigationPerformanceRequirementProperty;
        populationDensity?: {
            boxes?: Partial<Record<DensityBoxColor, number>>;
            peoplePerSquareKilometerAvg?: number;
            peoplePerSquareKilometerMax?: number;
        };
        flightTerminationSystem: FlightTerminationSystemResponseBody;
        adjacentArea?: Omit<SoraResult, "adjacentArea" | "airRisk" | "tacticalMitigationPerformanceRequirementProperty">;
        airRisk: AirRisk;
    };
    version: number;
    update: string;
    systemVerificationId: string;
    flightConditions: {
        checkinApprovalRequired: boolean;
        translationArgs: Record<string, string> | null;
        translationId: string;
    }[];
}

@UntilDestroy()
@Injectable()
export class MissionPlanVerificationAPIService {
    constructor(
        private readonly httpClient: HttpClient,
        @Inject(MISSION_ENDPOINTS) private readonly endpoints: MissionEndpoints,
        private readonly websocketService: WebsocketService
    ) {
        if (websocketService === undefined) {
            throw new Error("Initialize MissionModule with .forRoot()");
        }
    }

    public startMissionPlanAnalysisStatusWatch(userId: string): Observable<MissionPlanAnalysisStatus> {
        return this.websocketService
            .watchTopic(StringUtils.replaceInTemplate(this.endpoints.wsMissionPlanningTopicName, { userId }), [
                "PlanVerificationUpdatedEvent",
            ])
            .pipe(
                map((message) => {
                    try {
                        const response: MissionPlanVerificationResponseBody = JSON.parse(message.body);

                        return this.transformMissionPlanVerificationResponseBodyToMissionPlanAnalysisStatus(response);
                    } catch (error) {
                        Logger.captureException(error);

                        return;
                    }
                }),
                RxjsUtils.filterFalsy(),
                untilDestroyed(this)
            );
    }

    public getCurrentMissionPlanVerification(planId: string): Observable<MissionPlanAnalysisStatus> {
        return this.httpClient
            .get<MissionPlanVerificationResponseBody>(
                StringUtils.replaceInTemplate(this.endpoints.getMissionPlanVerification, { planId }),
                { context: new HttpContext().set(SKIP_NOT_FOUND_HTTP_INTERCEPTOR, true) }
            )
            .pipe(
                map((response) => this.transformMissionPlanVerificationResponseBodyToMissionPlanAnalysisStatus(response)),
                catchError(() => throwError(() => ({ type: MissionErrorType.Unknown })))
            );
    }

    private transformMissionPlanVerificationResponseBodyToMissionPlanAnalysisStatus(
        response: MissionPlanVerificationResponseBody
    ): MissionPlanAnalysisStatus {
        const trafficIssues = this.getIssuesForAnalysisOption(AnalysisIssueSource.Traffic, response.analysisIssues);
        const [nearbyMissionsPlanIds, collisionMissionsPlanIds] = ArrayUtils.partition(
            trafficIssues.filter((item) => !!item.translationArgs?.planIds),
            (item) => item.status !== MissionPlanAnalysisIssueStatus.Error
        ).map((item) =>
            item
                .map((issue) => (issue.translationArgs as TrafficCollisionTranslationArgs)?.planIds?.split(", "))
                .filter(FunctionUtils.isTruthy)
                .flat()
        );

        const traffic: MissionPlanTrafficAnalysisOption | undefined =
            response.trafficRequired && !!response.trafficStatus
                ? {
                      status: this.transformOptionStatus(response.trafficStatus),
                      issues: trafficIssues,
                      nearbyMissionsPlanIds,
                      collisionMissionsPlanIds,
                  }
                : undefined;

        const evaluationIssues: MissionPlanAnalysisEvaluationIssue[] = response.evaluationIssues.map((issue) => ({
            codename: issue.codename,
            status: issue.status,
            translationArgs: issue.translationArgs,
            translationId: issue.translationId,
        }));

        const evaluation: MissionPlanEvaluationOption | undefined = evaluationIssues.reduce<MissionPlanEvaluationOption>(
            (assignedIssues, issue) => {
                const { zoneIssues } = assignedIssues;
                const designators = (issue.translationArgs as AirwaysCollisionTranslationArgs)?.designators
                    ?.split(",")
                    .map((designator) => designator.trim());

                designators?.forEach((designator) => {
                    zoneIssues[designator] = zoneIssues[designator] ? [...zoneIssues[designator], issue] : [issue];
                });

                issue.designators = designators;

                assignedIssues.issues.push(issue);

                return assignedIssues;
            },
            { issues: [], zoneIssues: {} }
        );

        const airspaceIssues = this.getIssuesForAnalysisOption(AnalysisIssueSource.Airspace, response.analysisIssues);
        const airspace: MissionPlanAirspaceAnalysisOption = {
            status: this.transformOptionStatus(response.airspaceStatus),
            issues: airspaceIssues,
            zoneIssues: airspaceIssues.reduce<Record<string, MissionPlanAnalysisIssue[]>>((assignedIssues, issue) => {
                const designators = (issue.translationArgs as AirwaysCollisionTranslationArgs)?.designators
                    ?.split(",")
                    .map((designator) => designator.trim());

                designators?.forEach((designator) => {
                    assignedIssues[designator] = assignedIssues[designator] ? [...assignedIssues[designator], issue] : [issue];
                });

                return assignedIssues;
            }, {}),
            isAdjacentAreaControlled: response.airspace.adjacentControlled,
            isAdjacentAreaUncontrolled: response.airspace.adjacentUncontrolled,
            isOperationalAreaControlled: response.airspace.operationalControlled,
            isOperationalAreaUncontrolled: response.airspace.operationalUncontrolled,
        };

        return {
            planId: response.planId,
            planStatus: response.planStatus,
            isPlanSubmittable: response.planSubmittable,
            status: response.status,
            isManualVerificationRequired: response.manualVerificationRequired,
            routeId: response.routeId,
            airspace,
            traffic,
            caaPermit:
                response.caaPermitRequired && !!response.caaPermitStatus
                    ? {
                          status: this.transformOptionStatus(response.caaPermitStatus),
                          issues: this.getIssuesForAnalysisOption(AnalysisIssueSource.CaaPermit, response.analysisIssues),
                      }
                    : undefined,
            sora:
                response.soraRequired && !!response.soraStatus
                    ? {
                          status: this.transformOptionStatus(response.soraStatus),
                          issues: this.getIssuesForAnalysisOption(AnalysisIssueSource.Sora, response.analysisIssues),
                          result: this.transformSoraResultFromResponse(response),
                      }
                    : undefined,
            version: response.version,
            evaluation,
            applyForSpecificPermit: response.applyForSpecificPermit,
            systemVerificationId: response.systemVerificationId,
            flightRules: response.flightConditions.map((condition) => ({
                translationArgs:
                    { designators: condition.translationArgs?.designators.split(",").map((designator) => designator.trim()) } ?? {},
                translationId: condition.translationId,
                isCheckinApprovalRequired: condition.checkinApprovalRequired,
            })),
        };
    }

    private transformSoraResultFromResponse(response: MissionPlanVerificationResponseBody): SoraResult | undefined {
        const responseSoraResult = response.soraResult;

        if (!responseSoraResult) {
            return undefined;
        }

        const {
            effectiveGrl,
            finalGrc,
            finalGrl,
            intrinsicGrc,
            populationDensity,
            sail,
            residualArc,
            adjacentArea,
            airRisk,
            groundRiskTradeoff,
            criticalArea,
            initialArc,
            flightTerminationSystem,
            tmpr,
        } = responseSoraResult;

        return {
            effectiveGrl,
            finalGrc,
            finalGrl,
            intrinsicGrc,
            sail,
            groundRiskTradeoff,
            criticalArea,
            initialArc,
            residualArc,
            adjacentArea,
            airRisk,
            flightTerminationSystem: convertFlightTerminationSystem(flightTerminationSystem),
            tacticalMitigationPerformanceRequirementProperty: tmpr,
            populationDensity: {
                peoplePerSquareKilometerAvg: populationDensity?.peoplePerSquareKilometerAvg,
                peoplePerSquareKilometerMax: populationDensity?.peoplePerSquareKilometerMax,
                boxes: {
                    [DensityBox.Lowest]: populationDensity?.boxes?.GREEN,
                    [DensityBox.Low]: populationDensity?.boxes?.YELLOW,
                    [DensityBox.Medium]: populationDensity?.boxes?.RED,
                    [DensityBox.High]: populationDensity?.boxes?.VIOLET,
                    [DensityBox.Highest]: populationDensity?.boxes?.BLUE,
                    [DensityBox.Unknown]: populationDensity?.boxes?.UNKNOWN,
                },
            },
        };
    }

    private getIssuesForAnalysisOption(
        option: AnalysisIssueSource,
        analysisIssues: MissionPlanVerificationResponseBodyAnalysisIssue[]
    ): MissionPlanAnalysisIssue[] {
        return analysisIssues
            .filter((issue) => issue.source === option)
            .map((issue) => ({
                codename: issue.codename,
                translationArgs: issue.translationArgs,
                translationId: issue.translationId,
                status: this.transformIssueStatus(issue.status),
                designators: issue.translationArgs?.designators
                    ?.split(",")
                    .filter(FunctionUtils.isTruthy)
                    .map((designator) => designator.trim()),
            }));
    }

    private transformIssueStatus(status: AnalysisIssueStatus): MissionPlanAnalysisIssueStatus {
        switch (status) {
            case AnalysisIssueStatus.Failure:
                return MissionPlanAnalysisIssueStatus.Error;
            case AnalysisIssueStatus.Warning:
                return MissionPlanAnalysisIssueStatus.Warning;
            case AnalysisIssueStatus.Success:
                return MissionPlanAnalysisIssueStatus.Success;
            case AnalysisIssueStatus.Info:
                return MissionPlanAnalysisIssueStatus.Info;
            case AnalysisIssueStatus.Hold:
                return MissionPlanAnalysisIssueStatus.Hold;
            default:
                return MissionPlanAnalysisIssueStatus.FatalError;
        }
    }

    private transformOptionStatus(status: AnalysisOptionStatus): MissionPlanAnalysisOptionStatus {
        switch (status) {
            case AnalysisOptionStatus.Completed:
                return MissionPlanAnalysisOptionStatus.Success;
            case AnalysisOptionStatus.Failure:
                return MissionPlanAnalysisOptionStatus.Error;
            case AnalysisOptionStatus.Canceled:
                return MissionPlanAnalysisOptionStatus.Canceled;
            case AnalysisOptionStatus.None:
                return MissionPlanAnalysisOptionStatus.Pending;
            case AnalysisOptionStatus.Reverted:
                return MissionPlanAnalysisOptionStatus.Reverted;
            case AnalysisOptionStatus.Hold:
                return MissionPlanAnalysisOptionStatus.Hold;
            default:
                return MissionPlanAnalysisOptionStatus.FatalError;
        }
    }
}
