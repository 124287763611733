<dtm-ui-loader-container
    *ngrxLet="{
        operatorProfile: operatorProfile$,
        qualificationsType: qualificationsType$,
        isOperatorReadPermission: isOperatorReadPermission$,
        isOperatorManagePermission: isOperatorManagePermission$
    } as vm"
    [isShown]="(isOperatorProfileProcessing$ | ngrxPush) || (isAvatarProcessing$ | ngrxPush)"
>
    <div class="qualifications-sidenav" [@foldFarRight]="vm.qualificationsType ? 'open' : 'closed'">
        <dtm-web-app-lib-pilot-operator-qualifications
            [title]="'dtmWebAppLibShared.operatorQualifications.operatorQualificationsHeader' | transloco"
            [qualifications]="OPERATIONAL_AUTHORIZATIONS"
            class="scroll-shadows"
            (drawerClose)="setOperatorQualificationsType()"
        ></dtm-web-app-lib-pilot-operator-qualifications>
    </div>

    <ng-container *ngrxLet="error$ as operatorProfileError">
        <dtm-ui-error
            *ngIf="operatorProfileError; else operatorProfileTemplate"
            (reload)="reloadOperatorProfile()"
            [errorMessage]="'dtmWebAppOperatorProfile.container.operatorProfileErrorMessage' | transloco"
        ></dtm-ui-error>
    </ng-container>
    <ng-template #operatorProfileTemplate>
        <ng-container *ngIf="vm.isOperatorReadPermission || vm.isOperatorManagePermission; else notAuthorizedTemplate">
            <div class="grid">
                <div class="grid-col-6 column">
                    <dtm-web-app-lib-operator-number
                        [operator]="vm.operatorProfile"
                        [canManageOperator]="!!vm.isOperatorManagePermission"
                        (downloadFile)="downloadOperatorDocument($event)"
                    ></dtm-web-app-lib-operator-number>
                    <dtm-web-app-lib-operator-company-info
                        [operator]="vm.operatorProfile"
                        [canManageOperator]="!!vm.isOperatorManagePermission"
                        (save)="editCompanyInfo($event)"
                    ></dtm-web-app-lib-operator-company-info>
                    <dtm-web-app-lib-operator-avatar
                        [canManageOperator]="!!vm.isOperatorManagePermission"
                        [operator]="vm.operatorProfile"
                        [avatarBase64]="avatarBase64$ | ngrxPush"
                        (saveAvatar)="operatorAvatarSave($event)"
                        (delete)="deleteOperatorAvatar()"
                    ></dtm-web-app-lib-operator-avatar>
                </div>
                <div class="grid-col-6 column">
                    <dtm-web-app-lib-operator-qualifications
                        [canManageOperator]="!!vm.isOperatorManagePermission"
                        [qualifications]="vm.operatorProfile.qualifications"
                        (operatorQualificationsShow)="setOperatorQualificationsType(OperatorQualificationsType.Operator)"
                    ></dtm-web-app-lib-operator-qualifications>
                    <dtm-web-app-lib-pilot-operator-other-information-tile
                        *ngIf="vm.operatorProfile.otherInformation.length"
                        [otherInformationList]="vm.operatorProfile.otherInformation"
                    ></dtm-web-app-lib-pilot-operator-other-information-tile>
                    <dtm-web-app-lib-insurance-policy-edit
                        *ngIf="vm.isOperatorManagePermission"
                        [expirationDate]="vm.operatorProfile.insurancePolicyExpirationDate"
                        [insuranceNumber]="vm.operatorProfile.insurancePolicyNumber"
                        [isFineInfoVisible]="isInsurancePolicyFineInfoVisible$ | ngrxPush"
                        (save)="editOperatorInsurancePolicy($event)"
                        (delete)="deleteInsurancePolicy()"
                    ></dtm-web-app-lib-insurance-policy-edit>
                    <dtm-web-app-lib-attorney-power
                        *ngIf="vm.isOperatorManagePermission"
                        [attorneyPowers]="vm.operatorProfile.attorneyPowers"
                        [companyName]="vm.operatorProfile.name"
                        [capabilities]="attorneyPowerCapabilities$ | ngrxPush"
                        [isCapabilitiesProcessing]="isAttorneyPowerCapabilitiesProcessing$ | ngrxPush"
                        [capabilitiesError]="attorneyPowerCapabilitiesError$ | ngrxPush"
                        [userId]="userId$ | ngrxPush"
                        [isAttorneyPowerProcessing]="isAttorneyPowerProcessing$ | ngrxPush"
                        (getCapabilities)="getAttorneyPowerCapabilities()"
                        (attorneyPowerTemplateDownload)="downloadAttorneyPowerTemplate()"
                        (formSave)="saveAttorneyPowerForm($event)"
                        (attorneyPowerRemove)="removeAttorneyPower($event)"
                    ></dtm-web-app-lib-attorney-power>
                </div>
            </div>
        </ng-container>
    </ng-template>
</dtm-ui-loader-container>

<ng-template #notAuthorizedTemplate> <dtm-ui-not-authorized></dtm-ui-not-authorized></ng-template>
