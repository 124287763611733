<div class="grid grid-maxsize-8" *ngIf="capabilities$ | ngrxPush as capabilities; else noCapabilitiesTemplate">
    <dtm-ui-lib-wizard-content [wizardId]="wizardId">
        <dtm-web-app-lib-register-pilot-basic-data
            *dtmUiLibWizardStepContent="RegisterPilotWizardSteps.BasicData"
            (basicDataFormSave)="saveBasicDataForm($event)"
            [capabilities]="capabilities"
            (foreignOperatorCheck)="checkForeignOperator($event)"
            [foreignOperatorValidation]="foreignOperatorValidation$ | ngrxPush"
            [isPansaUtmLinkInfoVisible]="isMissionPlanningFeatureAvailable$ | ngrxPush"
            [initialValues]="basicData$ | ngrxPush"
        ></dtm-web-app-lib-register-pilot-basic-data>
        <dtm-web-app-lib-register-pilot-additional-data
            *dtmUiLibWizardStepContent="RegisterPilotWizardSteps.AdditionalData"
            [isLegalGuardianRequired]="capabilities.isLegalGuardianRequired"
            [initialValues]="additionalData$ | ngrxPush"
            [isInsurancePolicyFineWarningVisible]="isInsurancePolicyFineWarningVisible$ | ngrxPush"
            (additionalDataFormSave)="saveAdditionalDataForm($event)"
            (goToPreviousStep)="goToBasicDataStep()"
        ></dtm-web-app-lib-register-pilot-additional-data>
        <dtm-web-app-lib-pilot-registration-summary
            *dtmUiLibWizardStepContent="RegisterPilotWizardSteps.Summary"
            [isProcessing]="isProcessing$ | ngrxPush"
            [pilotRegistrationFormValue]="pilotRegistrationFormValue$ | ngrxPush"
            [capabilities]="capabilities"
            (summaryDataSave)="registerPilot($event)"
            (goToBasicDataStep)="goToBasicDataStep()"
            (goToAdditionalDataStep)="goToAdditionalDataStep()"
        >
        </dtm-web-app-lib-pilot-registration-summary>
    </dtm-ui-lib-wizard-content>
</div>

<ng-template #noCapabilitiesTemplate>
    <dtm-ui-error
        *ngIf="isPilotRegistrationCapabilitiesError$ | ngrxPush; else loaderTemplate"
        (reload)="refreshCapabilities()"
    ></dtm-ui-error>
</ng-template>
<ng-template #loaderTemplate>
    <div class="loader">
        <dtm-ui-loader
            [shouldSetParentPositionRelative]="true"
            [isShown]="isPilotRegistrationCapabilitiesProcessing$ | ngrxPush"
        ></dtm-ui-loader>
    </div>
</ng-template>
