<dtm-ui-expandable-panel *ngrxLet="airspaceRisk$ as airspaceRisk" [isExpanded]="isExpanded$ | ngrxPush" [hasHeaderSeparator]="false">
    <dtm-ui-expandable-simple-header
        headerSlot
        [label]="'dtmWebAppLibSpecPermApp.operationInfoStep.airspaceRiskInfo.airspaceRiskHeaderLabel' | transloco"
    >
    </dtm-ui-expandable-simple-header>
    <div class="properties">
        <dtm-ui-label-value
            [label]="'dtmWebAppLibSpecPermApp.operationInfoStep.airspaceRiskInfo.airspaceTypeLabel' | transloco"
            class="whole-line-property"
        >
            {{
                "dtmWebAppLibSpecPermApp.operationInfoStep.airspaceRiskInfo.airspaceTypeLabelText"
                    | transloco : { type: airspaceRisk | invoke : getAirspaceType }
            }}
        </dtm-ui-label-value>
        <dtm-ui-label-value
            [label]="'dtmWebAppLibSpecPermApp.operationInfoStep.airspaceRiskInfo.unusualAirspaceLabel' | transloco"
            [value]="
                'dtmWebAppLibSpecPermApp.operationInfoStep.airspaceRiskInfo.unusualAirspaceValue'
                    | transloco
                        : {
                              value:
                                  airspaceRisk.isAroundObstacleOnly || airspaceRisk.isSegregatedAirspaceOnly || airspaceRisk.isFlightLowAgl
                          }
            "
            class="whole-line-property"
        ></dtm-ui-label-value>
        <dtm-ui-label-value
            [label]="'dtmWebAppLibSpecPermApp.operationInfoStep.airspaceRiskInfo.initialArcLabel' | transloco"
            [value]="airspaceRisk.initialArc | arcLabel : true"
            class="whole-line-property"
        ></dtm-ui-label-value>
        <dtm-ui-label-value
            [label]="'dtmWebAppLibSpecPermApp.operationInfoStep.airspaceRiskInfo.residualArcLabel' | transloco"
            [value]="airspaceRisk.residualArc | arcLabel"
            class="whole-line-property"
        ></dtm-ui-label-value>
    </div>
    <section class="mitigations">
        <dtm-ui-label-value
            *ngIf="airspaceRisk.mitigations.length"
            [label]="'dtmWebAppLibSpecPermApp.operationInfoStep.airspaceRiskInfo.strategicMitigationsLabel' | transloco"
            class="whole-line-property"
        >
            <ul class="mitigations-list">
                <li *ngFor="let mitigation of airspaceRisk.mitigations">
                    <strong class="category">
                        {{
                            "dtmWebAppLibSpecPermApp.operationInfoStep.airspaceRiskInfo.mitigationCategoryLabel"
                                | transloco : { value: mitigation.category }
                        }}
                    </strong>
                    <ul class="mitigation-items">
                        <li class="feature-item">
                            <dtm-ui-icon name="check"></dtm-ui-icon>
                            <span class="feature-value">
                                {{ "dtmWebAppLibSpecPermApp.operationInfoStep.airspaceRiskInfo.zonesLabel" | transloco }}
                            </span>
                            <span class="feature-description">
                                {{ mitigation.designators | join : ", " : "-" }}
                            </span>
                        </li>
                        <li class="feature-item">
                            <dtm-ui-icon name="check"></dtm-ui-icon>
                            <div class="feature-value arc-transition">
                                <div class="arc-transition-item">
                                    {{ "dtmWebAppLibSpecPermApp.operationInfoStep.airspaceRiskInfo.mitigationInitialArcLabel" | transloco }}
                                    <span>
                                        {{ mitigation.initialArc ? (mitigation.initialArc | arcLabel : true) : "-" }}
                                    </span>
                                </div>
                                <dtm-ui-icon name="arrow-right-2"></dtm-ui-icon>
                                <div class="arc-transition-item">
                                    {{
                                        "dtmWebAppLibSpecPermApp.operationInfoStep.airspaceRiskInfo.mitigationResidualArcLabel" | transloco
                                    }}
                                    <span>
                                        {{ mitigation.residualArc ? (mitigation.residualArc | arcLabel) : "-" }}
                                    </span>
                                </div>
                            </div>
                        </li>
                        <li class="feature-item" *ngFor="let method of mitigation.methods">
                            <dtm-ui-icon name="check"></dtm-ui-icon>
                            <span class="feature-value">
                                {{ method.title | transloco }}
                            </span>
                        </li>
                    </ul>
                </li>
            </ul>
        </dtm-ui-label-value>
        <dtm-ui-label-value
            [label]="'dtmWebAppLibSpecPermApp.operationInfoStep.airspaceRiskInfo.tmprLabel' | transloco"
            [value]="'dtmWebAppLibSpecPermApp.operationInfoStep.airspaceRiskInfo.tmprValue' | transloco : { value: airspaceRisk.tmpr }"
            class="whole-line-property"
        ></dtm-ui-label-value>
    </section>
</dtm-ui-expandable-panel>
