import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { RobustnessLevel } from "@dtm-frontend/shared/mission";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy } from "@ngneat/until-destroy";

export interface RiskMitigationLevelsComponentState {
    robustnessLevels: RobustnessLevel[];
    robustnessLevelValue: RobustnessLevel | null;
    robustnessLevelFirstPopupText: string;
    robustnessLevelSecondPopupText: string;
    robustnessLevelThirdPopupText: string;
    disabledLevels: RobustnessLevel[];
    robustnessLevelFirstDescription: string | undefined;
    robustnessLevelSecondDescription: string | undefined;
    robustnessLevelThirdDescription: string | undefined;
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-mission-risk-mitigation-levels[robustnessLevelValue]",
    templateUrl: "./risk-mitigation-levels.component.html",
    styleUrls: ["./risk-mitigation-levels.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class RiskMitigationLevelsComponent {
    @Input() public set robustnessLevels(value: RobustnessLevel[] | undefined) {
        this.localStore.patchState({ robustnessLevels: value ?? [RobustnessLevel.Low, RobustnessLevel.Medium, RobustnessLevel.High] });
    }
    @Input() public set robustnessLevelValue(value: RobustnessLevel | null) {
        this.localStore.patchState({ robustnessLevelValue: value });
    }
    @Input() public set robustnessLevelFirstPopupText(value: string | undefined) {
        this.localStore.patchState({ robustnessLevelFirstPopupText: value ?? "" });
    }
    @Input() public set robustnessLevelSecondPopupText(value: string | undefined) {
        this.localStore.patchState({ robustnessLevelSecondPopupText: value ?? "" });
    }
    @Input() public set robustnessLevelThirdPopupText(value: string | undefined) {
        this.localStore.patchState({ robustnessLevelThirdPopupText: value ?? "" });
    }
    @Input() public set disabledLevels(value: RobustnessLevel[] | undefined) {
        this.localStore.patchState({ disabledLevels: value ?? [] });
    }
    @Input() public set robustnessLevelFirstDescription(value: string | undefined) {
        this.localStore.patchState({ robustnessLevelFirstDescription: value });
    }
    @Input() public set robustnessLevelSecondDescription(value: string | undefined) {
        this.localStore.patchState({ robustnessLevelSecondDescription: value });
    }
    @Input() public set robustnessLevelThirdDescription(value: string | undefined) {
        this.localStore.patchState({ robustnessLevelThirdDescription: value });
    }
    @Output() public readonly robustnessLevelSet = new EventEmitter<RobustnessLevel>();

    protected readonly robustnessLevels$ = this.localStore.selectByKey("robustnessLevels");
    protected readonly robustnessLevelValue$ = this.localStore.selectByKey("robustnessLevelValue");
    protected readonly robustnessLevelFirstPopupText$ = this.localStore.selectByKey("robustnessLevelFirstPopupText");
    protected readonly robustnessLevelSecondPopupText$ = this.localStore.selectByKey("robustnessLevelSecondPopupText");
    protected readonly robustnessLevelThirdPopupText$ = this.localStore.selectByKey("robustnessLevelThirdPopupText");
    protected readonly disabledLevels$ = this.localStore.selectByKey("disabledLevels");
    protected readonly robustnessLevelFirstDescription$ = this.localStore.selectByKey("robustnessLevelFirstDescription");
    protected readonly robustnessLevelSecondDescription$ = this.localStore.selectByKey("robustnessLevelSecondDescription");
    protected readonly robustnessLevelThirdDescription$ = this.localStore.selectByKey("robustnessLevelThirdDescription");

    constructor(private readonly localStore: LocalComponentStore<RiskMitigationLevelsComponentState>) {
        this.localStore.setState({
            robustnessLevels: [RobustnessLevel.Low, RobustnessLevel.Medium, RobustnessLevel.High],
            robustnessLevelValue: null,
            robustnessLevelFirstPopupText: "",
            robustnessLevelSecondPopupText: "",
            robustnessLevelThirdPopupText: "",
            disabledLevels: [],
            robustnessLevelFirstDescription: undefined,
            robustnessLevelSecondDescription: undefined,
            robustnessLevelThirdDescription: undefined,
        });
    }

    protected setRobustnessLevel(level: RobustnessLevel) {
        this.robustnessLevelSet.next(level);
    }

    protected isLevelDisabled(disabledLevels: RobustnessLevel[], level: RobustnessLevel): boolean {
        return disabledLevels.includes(level);
    }
}
