<p class="field-label"><ng-content select="[titleSlot]"></ng-content></p>
<div
    *ngrxLet="{
        airRiskMitigationValue: airRiskMitigationValue$,
        airInitialRiskMitigationValue: airInitialRiskMitigationValue$,
        airRiskMitigationPopoverKeys: airRiskMitigationPopoverKeys$
    } as vm"
    class="cards"
    [class.one-card]="vm.airInitialRiskMitigationValue === AirRiskMitigationsArc.C"
>
    <dtm-web-app-lib-mission-risk-mitigation-level
        [isSelected]="vm.airRiskMitigationValue === AirRiskMitigationsArc.B"
        [popupText]="vm.airRiskMitigationPopoverKeys?.arcB ? (vm.airRiskMitigationPopoverKeys?.arcB | transloco) : ''"
        [text]="AirRiskMitigationsArc.B | airRiskMitigationsArcAsNumber | arcLabel"
        (riskMitigationLevelSelect)="airRiskMitigationSet.next(AirRiskMitigationsArc.B)"
    >
    </dtm-web-app-lib-mission-risk-mitigation-level>
    <dtm-web-app-lib-mission-risk-mitigation-level
        *ngIf="vm.airInitialRiskMitigationValue !== AirRiskMitigationsArc.C"
        [isSelected]="vm.airRiskMitigationValue === AirRiskMitigationsArc.C"
        [popupText]="vm.airRiskMitigationPopoverKeys?.arcC ? (vm.airRiskMitigationPopoverKeys?.arcC | transloco) : ''"
        [text]="AirRiskMitigationsArc.C | airRiskMitigationsArcAsNumber | arcLabel"
        (riskMitigationLevelSelect)="airRiskMitigationSet.next(AirRiskMitigationsArc.C)"
    >
    </dtm-web-app-lib-mission-risk-mitigation-level>
</div>
