<ng-container
    *ngrxLet="{
        analysisStatus: analysisStatus$,
        stepNumber: stepNumber$,
        stepsAmount: stepsAmount$,
        isProcessing: isProcessing$,
        additionalInformation: additionalInformation$,
        missionPlan: missionPlan$,
        route: route$,
        nearbyMissions: nearbyMissions$,
        collisionMissions: collisionMissions$,
        currentPlanDataAndCapabilities: currentPlanDataAndCapabilities$,
        zones: zones$,
        selectedZoneId: selectedZoneId$,
        soraSettings: soraSettings$,
        missionType: missionType$,
        isDtmOnly: isDtmOnly$
    } as vm"
>
    <dtm-web-app-lib-mission-wizard-step-wrapper
        [stepNumber]="vm.stepNumber"
        [stepsAmount]="vm.stepsAmount"
        [title]="'dtmWebAppLibMission.missionWizardSteps.summary.header' | transloco"
        class="step-wrapper"
        (next)="next()"
        (back)="back.emit()"
        [isNextButtonEnabled]="!vm.isProcessing && (vm.analysisStatus?.isPlanSubmittable || vm.analysisStatus?.applyForSpecificPermit)"
        [isLoading]="vm.isProcessing"
        [nextButtonLabel]="vm.analysisStatus | invoke : nextButtonLabelKey | transloco"
        [stepId]="MissionWizardSteps.Summary"
    >
        <dtm-mission-basic-details
            *ngIf="vm.missionPlan"
            [startDate]="vm.missionPlan.flightStartAtMin"
            [finishDate]="vm.missionPlan.flightFinishAtMax"
            [missionType]="vm.missionPlan.flightType"
            [isAuthorityAcceptationConfirmed]="vm.missionPlan.remarks | invoke : isAuthorityAcceptationConfirmed"
            [isWholeDayMode]="vm.missionPlan | invoke : isWholeDayMode"
            (zoom)="flyToMainMissionRoute.emit()"
        >
            <dtm-ui-input-field [isClearable]="false">
                <label>{{ "dtmWebAppLibShared.mission.nameLabel" | transloco }}</label>
                <input matInput type="text" required [formControl]="missionNameFormControl" />
                <div class="field-error" *dtmUiFieldHasError="missionNameFormControl; name: 'required'">
                    {{ "dtmWebAppLibMission.requiredFieldErrorHint" | transloco }}
                </div>
                <div class="field-error" *dtmUiFieldHasError="missionNameFormControl; name: 'maxlength'; error as error">
                    {{
                        "dtmWebAppLibShared.mission.nameMaxLengthError"
                            | transloco
                                : {
                                      maxLength: error.requiredLength,
                                      exceedValue: error.actualLength - error.requiredLength
                                  }
                    }}
                </div>
            </dtm-ui-input-field>
        </dtm-mission-basic-details>

        <dtm-web-app-lib-message-to-pansa
            *ngIf="vm.analysisStatus?.sora && !vm.currentPlanDataAndCapabilities?.plan?.category?.specificCaaPermitId"
            [planId]="vm.missionPlan?.id"
        ></dtm-web-app-lib-message-to-pansa>

        <dtm-web-app-lib-message-to-manager
            *ngIf="!vm.analysisStatus?.sora || vm.currentPlanDataAndCapabilities?.plan?.category?.specificCaaPermitId"
            [justification]="vm.missionPlan?.remarks?.justification"
            [planId]="vm.missionPlan?.id"
            [isPriorityEnabled]="vm.isDtmOnly"
            (valueChanges)="formalJustificationChange.emit($event)"
        ></dtm-web-app-lib-message-to-manager>

        <dtm-mission-mission-details [mission]="missionDataSimple$ | ngrxPush" [isExpanded]="false">
            <dtm-ui-expandable-simple-header headerSlot [label]="'dtmWebAppLibShared.mission.missionDataHeaderLabel' | transloco">
            </dtm-ui-expandable-simple-header>
        </dtm-mission-mission-details>

        <dtm-ui-expandable-panel [hasHeaderSeparator]="false">
            <dtm-ui-expandable-simple-header headerSlot [label]="'dtmWebAppLibShared.mission.routeDetailsHeaderLabel' | transloco">
            </dtm-ui-expandable-simple-header>
            <dtm-ui-route-details [route]="vm.route"></dtm-ui-route-details>
        </dtm-ui-expandable-panel>

        <dtm-web-app-lib-zone-analysis
            [airspaceAnalysis]="vm.analysisStatus?.airspace"
            [zones]="vm.zones"
            [selectedZoneId]="vm.selectedZoneId"
            (zoneSelect)="zoneSelect.emit($event)"
        >
            <dtm-mission-additional-analysis-info
                [additionalZones]="vm.zones.additionalZones"
                [evaluation]="vm.analysisStatus?.evaluation"
                [selectedZoneId]="vm.selectedZoneId"
                (zoneSelect)="zoneSelect.emit($event)"
            ></dtm-mission-additional-analysis-info>
        </dtm-web-app-lib-zone-analysis>

        <dtm-mission-operational-area-geometry
            *ngIf="vm.analysisStatus?.sora"
            [operationalGeometryData]="operationalGeometryData$ | ngrxPush"
            [isExpanded]="false"
        ></dtm-mission-operational-area-geometry>

        <dtm-mission-traffic-analysis
            *ngIf="vm.analysisStatus?.traffic"
            [nearbyMissions]="vm.nearbyMissions"
            [collisionMissions]="vm.collisionMissions"
            (flyToRoute)="flyToRoute.emit($event)"
            (flyToMainMissionRoute)="flyToMainMissionRoute.emit()"
            (otherMissionSelectedId)="otherMissionSelectedId.emit($event)"
        ></dtm-mission-traffic-analysis>

        <dtm-ui-expandable-panel [hasHeaderSeparator]="false" [isExpanded]="false" *ngIf="vm.analysisStatus?.sora">
            <dtm-ui-expandable-simple-header headerSlot [label]="'dtmWebAppLibShared.mission.analysis.groundRiskTitle' | transloco">
            </dtm-ui-expandable-simple-header>
            <dtm-mission-population-density
                [populationDensity]="vm.analysisStatus?.sora?.result?.populationDensity"
            ></dtm-mission-population-density>
        </dtm-ui-expandable-panel>

        <ng-container *ngIf="vm.analysisStatus && vm.analysisStatus.sora">
            <dtm-mission-operational-area-airspace-risk
                [airRiskMitigations]="vm.soraSettings?.airRiskMitigations"
                [airRisk]="vm.analysisStatus.sora.result?.airRisk"
                [isExpanded]="false"
            ></dtm-mission-operational-area-airspace-risk>
            <dtm-mission-initial-sora-issues
                [isExpanded]="false"
                [isControlledGroundArea]="!!vm.soraSettings?.controlledGroundAreaComment"
                [soraResult]="vm.analysisStatus.sora.result"
                [airspaceAnalysis]="vm.analysisStatus.airspace"
            >
            </dtm-mission-initial-sora-issues>
            <dtm-mission-risk-mitigation-measures
                *ngIf="vm.analysisStatus.sora && vm.soraSettings?.riskMitigations"
                [airRisk]="vm.analysisStatus.sora.result?.airRisk"
                [soraSettings]="vm.soraSettings"
                [isExpanded]="false"
            >
            </dtm-mission-risk-mitigation-measures>
            <dtm-mission-tactical-risk-mitigation-measures
                *ngIf="
                    vm.missionType === MissionType.BVLOS &&
                    !!vm.analysisStatus.sora.result?.tacticalMitigationPerformanceRequirementProperty &&
                    vm.analysisStatus.sora.result?.tacticalMitigationPerformanceRequirementProperty !==
                        TacticalMitigationPerformanceRequirement.VLOS
                "
                [tacticalMitigationPerformanceRequirementProperty]="
                    vm.analysisStatus.sora.result?.tacticalMitigationPerformanceRequirementProperty
                "
                [tacticalMitigationPerformanceRequirements]="vm.soraSettings?.tmpr"
                [isExpanded]="false"
            >
            </dtm-mission-tactical-risk-mitigation-measures>
            <dtm-mission-final-sora-issues
                [isExpanded]="false"
                [soraResult]="vm.analysisStatus.sora.result"
            ></dtm-mission-final-sora-issues>
            <dtm-mission-adjacent-area-and-space
                *ngIf="vm.analysisStatus.sora"
                [soraResult]="vm.analysisStatus.sora.result"
                [airspaceAnalysis]="vm.analysisStatus.airspace"
                [technicalSpecification]="(setup$ | ngrxPush)?.technicalSpecification"
                [isExpanded]="false"
            ></dtm-mission-adjacent-area-and-space>
        </ng-container>
        <dtm-web-app-lib-personal-notes
            (valueChanges)="additionalInformationChange.emit($event)"
            [information]="vm.additionalInformation"
            [isProcessing]="vm.isProcessing"
        ></dtm-web-app-lib-personal-notes>
    </dtm-web-app-lib-mission-wizard-step-wrapper>

    <ng-template #routeSideViewTemplate>
        <dtm-mission-route-side-view
            [isExpanded]="false"
            [isLoading]="vm.isProcessing"
            [isSimpleMode]="vm.currentPlanDataAndCapabilities?.plan?.itinerary?.editorType === ItineraryEditorType.Standard"
            [areBuffersVisible]="vm.currentPlanDataAndCapabilities?.plan?.flightType === MissionType.BVLOS"
            [route]="vm.route"
            (expandedChange)="heightViolationDialogLink.isExpanded = $event"
        >
            <div headerSlot class="side-view-title">
                <h2>{{ "dtmWebAppLibMission.routeSideView.sideViewHeader" | transloco }}</h2>
                <dtm-web-app-lib-height-violation-alert-dialog-link
                    [route]="vm.route"
                    #heightViolationDialogLink
                ></dtm-web-app-lib-height-violation-alert-dialog-link>
            </div>
        </dtm-mission-route-side-view>
    </ng-template>
</ng-container>
