import { ClipboardModule } from "@angular/cdk/clipboard";
import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatIconModule } from "@angular/material/icon";
import { MatLegacyButtonModule as MatButtonModule } from "@angular/material/legacy-button";
import { MatLegacyCardModule as MatCardModule } from "@angular/material/legacy-card";
import { MatLegacyChipsModule as MatChipsModule } from "@angular/material/legacy-chips";
import { MatLegacyDialogModule as MatDialogModule } from "@angular/material/legacy-dialog";
import { MatLegacyFormFieldModule as MatFormFieldModule } from "@angular/material/legacy-form-field";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { MatLegacyListModule as MatListModule } from "@angular/material/legacy-list";
import { MatLegacyMenuModule as MatMenuModule } from "@angular/material/legacy-menu";
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from "@angular/material/legacy-progress-spinner";
import { MatLegacySelectModule as MatSelectModule } from "@angular/material/legacy-select";
import { MatLegacySlideToggleModule as MatSlideToggleModule } from "@angular/material/legacy-slide-toggle";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatTooltipModule } from "@angular/material/tooltip";
import { RouterModule } from "@angular/router";
import { SharedAuthModule } from "@dtm-frontend/shared/auth";
import { SharedUavModule } from "@dtm-frontend/shared/uav";
import { FILES_UPLOAD_API_PROVIDER, SharedUiModule } from "@dtm-frontend/shared/ui";
import { LanguageCode, SharedI18nModule, getTranslocoInlineLoader } from "@dtm-frontend/shared/ui/i18n";
import { WizardModule } from "@dtm-frontend/shared/ui/wizard";
import { TRANSLOCO_SCOPE } from "@jsverse/transloco";
import { LetModule, PushModule } from "@ngrx/component";
import { NgxsModule } from "@ngxs/store";
import { SharedWebAppModule } from "../shared";
import { EditCustomUavDialogComponent } from "./components/edit-custom-uav-dialog/edit-custom-uav-dialog.component";
import { EditUavDialogComponent } from "./components/edit-uav-dialog/edit-uav-dialog.component";
import { ManageUavDocumentsDialogComponent } from "./components/manage-uav-documents-dialog/manage-uav-documents-dialog.component";
import { NewUavWizardContentComponent } from "./components/new-uav-wizard/content/new-uav-wizard-content.component";
import { NewUavWizardHeaderComponent } from "./components/new-uav-wizard/header/new-uav-wizard-header.component";
import { NewUavWizardBasicInfoStepComponent } from "./components/new-uav-wizard/steps/basic-info-step/basic-info-step.component";
import { CustomUavConfirmationDialogComponent } from "./components/new-uav-wizard/steps/basic-info-step/custom-uav-confirmation-dialog/custom-uav-confirmation-dialog.component";
import { NewUavWizardBasicInfoStepCustomUavComponent } from "./components/new-uav-wizard/steps/basic-info-step/custom-uav/custom-uav.component";
import { NewUavWizardBasicInfoStepStandardUavComponent } from "./components/new-uav-wizard/steps/basic-info-step/standard-uav/standard-uav.component";
import { UavSwarmControlComponent } from "./components/new-uav-wizard/steps/basic-info-step/uav-swarm-control/uav-swarm-control.component";
import { NewUavWizardCustomUavPrimarySetupStepComponent } from "./components/new-uav-wizard/steps/custom-uav-primary-setup-step/custom-uav-primary-setup-step.component";
import { NewUavWizardPrimarySetupStepComponent } from "./components/new-uav-wizard/steps/primary-setup-step/primary-setup-step.component";
import { SerialNumbersDialogComponent } from "./components/serial-numbers-dialog/serial-numbers-dialog.component";
import { SetupEditorComponent } from "./components/setup/setup-editor/setup-editor.component";
import { AccessoriesControlComponent } from "./components/setup/setup-form/accessories-control/accessories-control.component";
import { CommunicationsControlComponent } from "./components/setup/setup-form/communications-control/communications-control.component";
import { FrequenciesControlComponent } from "./components/setup/setup-form/communications-control/frequencies-control/frequencies-control.component";
import { CustomUavFlightTechnicalPropertiesControlComponent } from "./components/setup/setup-form/custom-uav-setup-form/custom-uav-flight-technical-properties-control/custom-uav-flight-technical-properties-control.component";
import { CustomUavGeneralTechnicalPropertiesControlComponent } from "./components/setup/setup-form/custom-uav-setup-form/custom-uav-general-technical-properties-control/custom-uav-general-technical-properties-control.component";
import { CustomUavSetupFormComponent } from "./components/setup/setup-form/custom-uav-setup-form/custom-uav-setup-form.component";
import { FlightTechnicalPropertiesControlComponent } from "./components/setup/setup-form/flight-technical-properties-control/flight-technical-properties-control.component";
import { GeneralTechnicalPropertiesControlComponent } from "./components/setup/setup-form/general-technical-properties-control/general-technical-properties-control.component";
import { SetupFormComponent } from "./components/setup/setup-form/setup-form.component";
import { ShareUavSetupComponent } from "./components/share-uav-setup/share-uav-setup.component";
import { UavCardComponent } from "./components/uav-card/uav-card.component";
import { UavHeaderComponent } from "./components/uav-header/uav-header.component";
import { UavImageControlComponent } from "./components/uav-image-control/uav-image-control.component";
import { UavInfoComponent } from "./components/uav-info/uav-info.component";
import { UavListFiltersComponent } from "./components/uav-list/uav-list-filters/uav-list-filters.component";
import { UavListItemComponent } from "./components/uav-list/uav-list-item/uav-list-item.component";
import { UavListComponent } from "./components/uav-list/uav-list.component";
import { UavModelDocumentsComponent } from "./components/uav-model-documents/uav-model-documents.component";
import { SetupDocumentsApiProviderDirective } from "./services/setup-document-upload-api.service";
import { UavAPIService } from "./services/uav-api.service";
import { UavAPIServiceStub } from "./services/uav-api.service.stub";
import { UavState } from "./state/uav.state";
import { UAV_ENDPOINTS } from "./uav.tokens";

@NgModule({
    declarations: [
        UavListComponent,
        UavListItemComponent,
        UavListFiltersComponent,
        UavCardComponent,
        UavHeaderComponent,
        UavInfoComponent,
        UavModelDocumentsComponent,
        ManageUavDocumentsDialogComponent,
        NewUavWizardContentComponent,
        NewUavWizardHeaderComponent,
        NewUavWizardBasicInfoStepComponent,
        NewUavWizardBasicInfoStepStandardUavComponent,
        NewUavWizardBasicInfoStepCustomUavComponent,
        NewUavWizardPrimarySetupStepComponent,
        NewUavWizardCustomUavPrimarySetupStepComponent,
        SetupFormComponent,
        CustomUavSetupFormComponent,
        CustomUavFlightTechnicalPropertiesControlComponent,
        CustomUavGeneralTechnicalPropertiesControlComponent,
        FlightTechnicalPropertiesControlComponent,
        GeneralTechnicalPropertiesControlComponent,
        CommunicationsControlComponent,
        FrequenciesControlComponent,
        AccessoriesControlComponent,
        SetupEditorComponent,
        EditUavDialogComponent,
        EditCustomUavDialogComponent,
        ShareUavSetupComponent,
        UavImageControlComponent,
        UavSwarmControlComponent,
        SerialNumbersDialogComponent,
        CustomUavConfirmationDialogComponent,
        SetupDocumentsApiProviderDirective,
    ],
    imports: [
        CommonModule,
        FormsModule,
        NgxsModule.forFeature([UavState]),
        RouterModule,
        SharedUiModule,
        MatCardModule,
        MatButtonModule,
        MatIconModule,
        MatListModule,
        MatProgressSpinnerModule,
        MatExpansionModule,
        LetModule,
        PushModule,
        WizardModule,
        MatFormFieldModule,
        MatSelectModule,
        ReactiveFormsModule,
        MatInputModule,
        MatDialogModule,
        MatTooltipModule,
        MatSlideToggleModule,
        MatMenuModule,
        MatSidenavModule,
        MatButtonToggleModule,
        SharedI18nModule,
        SharedAuthModule,
        SharedWebAppModule,
        MatChipsModule,
        ClipboardModule,
        SharedUavModule,
    ],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            multi: true,
            useValue: {
                scope: "dtmWebAppLibUav",
                loader: getTranslocoInlineLoader((language: LanguageCode) => import(`./assets/i18n/${language}.json`)),
            },
        },
        {
            provide: UavAPIService,
            useValue: undefined,
        },
    ],
    exports: [
        UavListComponent,
        UavListItemComponent,
        UavCardComponent,
        UavHeaderComponent,
        UavInfoComponent,
        UavModelDocumentsComponent,
        NewUavWizardBasicInfoStepComponent,
        NewUavWizardPrimarySetupStepComponent,
        NewUavWizardCustomUavPrimarySetupStepComponent,
        SetupFormComponent,
        CustomUavSetupFormComponent,
        ShareUavSetupComponent,
        UavImageControlComponent,
    ],
})
export class UavModule {
    public static forRoot(): ModuleWithProviders<UavModule> {
        return {
            ngModule: UavModule,
            providers: [
                {
                    provide: UavAPIService,
                    useClass: UavAPIService,
                },
            ],
        };
    }

    public static forTest(): ModuleWithProviders<UavModule> {
        class FilesUploadApiServiceMock {
            public uploadFile() {}
            public getFile() {}
            public getFileUrl() {}
            public getFilesCompressed() {}
            public manageUploadError() {}
        }

        return {
            ngModule: UavModule,
            providers: [
                {
                    provide: UavAPIService,
                    useClass: UavAPIServiceStub,
                },
                {
                    provide: FILES_UPLOAD_API_PROVIDER,
                    useClass: FilesUploadApiServiceMock,
                },
                {
                    provide: UAV_ENDPOINTS,
                    useValue: { customUavPhotoUpload: "", customUavPhotoDownload: "" },
                },
            ],
        };
    }
}
