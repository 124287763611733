<dtm-web-app-lib-mission-wizard-step-wrapper
    [stepNumber]="stepNumber$ | ngrxPush"
    [stepsAmount]="stepsAmount$ | ngrxPush"
    [title]="'dtmWebAppLibMission.missionWizardSteps.missionData.header' | transloco"
    class="step-wrapper"
    (next)="updateOperationCategoryAndGoNextStep()"
    [isNextButtonEnabled]="!vm.isProcessing && !vm.areCapabilitiesLoading && (vm.isPilotUTMConnected || !vm.isSelectedEnterpriseOperator)"
    [nextButtonLabel]="
        (missionPlanFormGroupStateController.isEditing$ | ngrxPush)
            ? ('dtmWebAppLibMission.dataFormStep.saveButtonLabel' | transloco)
            : ('dtmWebAppLibMission.dataFormStep.nextButtonLabel' | transloco)
    "
    [isBackButtonVisible]="false"
    [isLoading]="vm.isProcessing"
    [stepId]="MissionWizardSteps.MissionData"
    *ngrxLet="{
        isProcessing: isProcessing$,
        allPilots: allPilots$,
        isEditMode: isEditMode$,
        availableUavsWithSetups: availableUavsWithSetups$,
        activePermits: activePermits$,
        isSelectedEnterpriseOperator: isSelectedEnterpriseOperator$,
        selectedActivePermitUavs: selectedActivePermitUavs$,
        selectedActivePermitPilots: selectedActivePermitPilots$,
        areCapabilitiesLoading: areCapabilitiesLoading$,
        isSpecificPermitUsageEnabled: isSpecificPermitUsageEnabled$,
        isPilotUTMConnected: isPilotUTMConnected$,
        canManageUavs: canManageUavs$,
        regulationExemptions: regulationExemptions$
    } as vm"
    dtmUiInvalidFormScrollable
>
    <ng-container *ngIf="vm.allPilots.length && vm.availableUavsWithSetups.length; else missingPilotsOrUavTemplate">
        <ng-container *ngIf="isInitialized$ | ngrxPush; else loaderTemplate">
            <dtm-ui-loader [isShown]="vm.areCapabilitiesLoading"></dtm-ui-loader>

            <dtm-ui-info-message type="info" *ngIf="vm.isEditMode">
                <span class="info-message-text">{{ "dtmWebAppLibMission.dataFormStep.limitedEditModeInfoBoxMessage" | transloco }}</span>
                <button class="button-secondary clear-itinerary-button" type="button" (click)="itineraryClear.emit()">
                    {{ "dtmWebAppLibMission.dataFormStep.limitedEditModeInfoBoxClearButton" | transloco }}
                </button>
            </dtm-ui-info-message>

            <form [formGroup]="missionPlanFormGroup">
                <ng-container formGroupName="capabilities">
                    <dtm-web-app-lib-mission-planning-type-form-control
                        [isSpecificPermitUsageEnabled]="vm.isSpecificPermitUsageEnabled"
                        [isDisabled]="vm.isEditMode"
                        [type]="missionPlanningType$ | ngrxPush"
                        [hasPermits]="!(vm.activePermits | isEmptyArray)"
                        [hasRegulationExemptions]="!!vm.regulationExemptions.length"
                        (typeChange)="missionPlanningTypeChanged($event)"
                    ></dtm-web-app-lib-mission-planning-type-form-control>
                    <div class="form-entry active-permits" *ngIf="activePermitsFormControl.enabled">
                        <dtm-ui-select-field
                            [isClearable]="false"
                            [formControl]="activePermitsFormControl"
                            (valueChange)="activePermitsChanged($event)"
                        >
                            <label>{{ "dtmWebAppLibMission.dataFormStep.activePermitsLabel" | transloco }}</label>
                            <dtm-ui-select-option *ngFor="let permit of vm.activePermits" [value]="permit" [disabled]="vm.isEditMode">
                                {{ permit.name }}
                            </dtm-ui-select-option>
                            <div class="field-error" *dtmUiFieldHasError="activePermitsFormControl; name: 'required'">
                                {{ "dtmWebAppLibMission.requiredFieldErrorHint" | transloco }}
                            </div>
                        </dtm-ui-select-field>
                    </div>
                    <ng-container
                        *ngIf="activePermitsFormControl.disabled || (activePermitsFormControl.enabled && activePermitsFormControl.value)"
                    >
                        <dtm-ui-mission-type-form-control
                            [disableMissionType]="disableMissionType$ | ngrxPush"
                            [formControl]="missionTypeFormControl"
                        ></dtm-ui-mission-type-form-control>
                        <dtm-ui-form-errors>
                            <div class="field-error" *dtmUiFieldHasError="missionTypeFormControl; name: 'required'">
                                {{ "dtmWebAppLibMission.requiredFieldErrorHint" | transloco }}
                            </div>
                        </dtm-ui-form-errors>
                        <dtm-web-app-lib-regulation-exemptions-info
                            *ngIf="regulationExemptionFormControl.value"
                            [regulationExemptions]="regulationExemptionFormControl.value"
                        ></dtm-web-app-lib-regulation-exemptions-info>
                        <div class="form-entry pilot-control" *ngIf="missionTypeFormControl.value">
                            <dtm-ui-select-field [isClearable]="false" formControlName="pilot">
                                <label>{{ "dtmWebAppLibMission.dataFormStep.pilotLabel" | transloco }}</label>
                                <dtm-ui-select-option
                                    *ngFor="let pilot of vm.allPilots"
                                    [value]="pilot"
                                    [disabled]="
                                        vm.isEditMode ||
                                        (pilot
                                            | invoke
                                                : shouldPilotBeDisabled
                                                : vm.selectedActivePermitPilots
                                                : regulationExemptionFormControl.value)
                                    "
                                >
                                    {{ pilot.displayName }}
                                </dtm-ui-select-option>
                                <div class="field-error" *dtmUiFieldHasError="pilotFormControl; name: 'required'">
                                    {{ "dtmWebAppLibMission.requiredFieldErrorHint" | transloco }}
                                </div>
                                <div
                                    class="field-hint utm-connection-hint"
                                    *ngIf="vm.isSelectedEnterpriseOperator && !vm.isPilotUTMConnected"
                                >
                                    {{ "dtmWebAppLibMission.dataFormStep.pansaUtmPilotError" | transloco }}
                                </div>
                            </dtm-ui-select-field>
                        </div>

                        <div class="form-entry additional-crew-members" *ngIf="missionTypeFormControl.value">
                            <dtm-web-app-lib-additional-crew-members-edit
                                [isLimitedEditMode]="vm.isEditMode"
                                [additionalCrewMembers]="additionalCrewControl.value"
                                [additionalCrewMembersOptions]="additionalCrewMembersOptions$ | ngrxPush"
                                (save)="additionalCrewControl.setValue($event)"
                            ></dtm-web-app-lib-additional-crew-members-edit>
                        </div>

                        <div class="form-entry" *ngIf="missionTypeFormControl.value && pilotFormControl.value">
                            <!-- TODO: UX-57 this will change in future-->
                            <dtm-ui-select-field [isClearable]="false" formControlName="uavWithSetup">
                                <label>
                                    {{ "dtmWebAppLibMission.dataFormStep.uavLabel" | transloco }}
                                    <dtm-ui-icon
                                        name="information-fill"
                                        class="popover-icon"
                                        (click)="$event.preventDefault(); openZoneLegendDialog()"
                                    ></dtm-ui-icon>
                                </label>
                                <ng-container *ngFor="let uavsWithSetups of vm.availableUavsWithSetups">
                                    <p class="option-group-title">
                                        {{ uavsWithSetups[0].displayName }}
                                    </p>
                                    <dtm-ui-select-option
                                        [value]="uavWithSetup"
                                        *ngFor="let uavWithSetup of uavsWithSetups"
                                        class="option-group-item"
                                        [disabled]="
                                            vm.isEditMode ||
                                            (uavWithSetup
                                                | invoke
                                                    : shouldUavBeDisabled
                                                    : vm.selectedActivePermitUavs
                                                    : regulationExemptionFormControl.value)
                                        "
                                    >
                                        {{ uavWithSetup.setup.displayName }}
                                    </dtm-ui-select-option>
                                </ng-container>

                                <mat-select-trigger>
                                    {{ uavWithSetupFormControl.value?.displayName }}
                                    <span class="selected-uav-setup-name" *ngIf="uavWithSetupFormControl.value?.setup">
                                        &mdash; {{ uavWithSetupFormControl.value?.setup?.displayName }}
                                    </span>
                                </mat-select-trigger>

                                <div class="field-error" *dtmUiFieldHasError="uavWithSetupFormControl; name: 'required'">
                                    {{ "dtmWebAppLibMission.requiredFieldErrorHint" | transloco }}
                                </div>
                            </dtm-ui-select-field>
                        </div>
                    </ng-container>
                </ng-container>

                <ng-container
                    *ngIf="(missionPlanCapabilitiesFormGroup.valid && activePermitsFormControl.disabled) || activePermitsFormControl.value"
                >
                    <dtm-web-app-lib-operation-category-form-control
                        [options]="allOperationCategories$ | ngrxPush"
                        [flightType]="missionTypeFormControl.value"
                        formControlName="category"
                        dtmUiMarkValueAccessorControlsAsTouched
                        *ngIf="!activePermitsFormControl.value && !regulationExemptionFormControl.value"
                    >
                    </dtm-web-app-lib-operation-category-form-control>

                    <div class="values-from-permit" *ngIf="activePermitsFormControl.value">
                        <div class="permit-field">
                            <span class="field-label">
                                {{ "dtmWebAppLibMission.dataFormStep.operationCategoryLabel" | transloco }}
                            </span>
                            <p>{{ "dtmWebAppLibMission.dataFormStep.specificPermitLabel" | transloco }}</p>
                        </div>

                        <div class="permit-field">
                            <span class="field-label">
                                {{ "dtmWebAppLibMission.dataFormStep.flightPurposeLabel" | transloco }}
                            </span>
                            <p>{{ activePermitsFormControl.value.flightPurposes }}</p>
                        </div>
                        <!-- TODO: DTM-4801 - uncomment when flight comment is available from backend -->
                        <!--<div class="permit-field full-column" *ngIf="activePermitsFormControl.value.flightComment">
                            <span class="field-label">{{ "dtmSharedMission.missionData.flightPurposeDescriptionLabel" | transloco }}</span>
                            <p>{{ activePermitsFormControl.value.flightComment }}</p>
                        </div>-->
                    </div>

                    <ng-container formGroupName="flightPurpose" *ngIf="!categoryFormControl.invalid && !activePermitsFormControl.value">
                        <div class="form-entry">
                            <dtm-web-app-lib-flight-purpose-form-control
                                dtmUiMarkValueAccessorControlsAsTouched
                                [flightPurposes]="flightPurposes$ | ngrxPush"
                                [formControl]="flightPurposeFormControl"
                            ></dtm-web-app-lib-flight-purpose-form-control>
                        </div>

                        <div
                            class="dangerous-materials"
                            *ngIf="flightPurposeFormControl.value?.codename === FLIGHT_PURPOSE_TRANSPORT_CODE_NAME"
                        >
                            <label
                                [for]="dangerousMaterialsTransportToggle.inputId"
                                [class.disabled]="dangerousMaterialsTransportControl.disabled"
                            >
                                {{ "dtmWebAppLibMission.dataFormStep.dangerousMaterialsTransportLabel" | transloco }}&nbsp;<dtm-ui-popover
                                    (click)="$event.preventDefault()"
                                >
                                    <p>
                                        {{ "dtmWebAppLibMission.dataFormStep.dangerousMaterialsTransportTooltip.contentText" | transloco }}
                                    </p>
                                    <ul
                                        class="materials-list"
                                        [innerHTML]="
                                            'dtmWebAppLibMission.dataFormStep.dangerousMaterialsTransportTooltip.contentList' | transloco
                                        "
                                    ></ul>
                                    <p class="dangerous-materials-more-info">
                                        <span
                                            [innerHTML]="
                                                'dtmWebAppLibMission.dataFormStep.dangerousMaterialsTransportTooltip.moreInfoText'
                                                    | transloco
                                            "
                                        ></span>
                                        <a [attr.href]="DANGEROUS_MATERIALS_INFO_URL" target="_blank" class="button-link">
                                            {{
                                                "dtmWebAppLibMission.dataFormStep.dangerousMaterialsTransportTooltip.moreInfoLinkText"
                                                    | transloco
                                            }}
                                        </a>
                                    </p>
                                </dtm-ui-popover>
                                <p class="description" *ngIf="dangerousMaterialsTransportControl.enabled">
                                    {{ "dtmWebAppLibMission.dataFormStep.dangerousMaterialsTransportDescriptionText" | transloco }}
                                </p>
                            </label>
                            <mat-slide-toggle
                                #dangerousMaterialsTransportToggle
                                labelPosition="before"
                                [formControl]="dangerousMaterialsTransportControl"
                                (change)="flightPurposeDescriptionFormControl.updateValueAndValidity()"
                                [matTooltip]="'dtmWebAppLibMission.dataFormStep.dangerousMaterialsTransportDisabledTooltip' | transloco"
                                [matTooltipDisabled]="dangerousMaterialsTransportControl.enabled"
                            >
                            </mat-slide-toggle>
                        </div>

                        <div class="form-entry" *ngIf="flightPurposeFormControl.value">
                            <dtm-ui-textarea-field [maxLength]="MAX_FLIGHT_DESCRIPTION_LENGTH">
                                <label>
                                    {{
                                                "dtmWebAppLibMission.dataFormStep.flightPurposeDescriptionLabel"
                                                    | transloco
                                                        : {
                                                              required: flightPurposeDescriptionFormControl.hasValidator(
                                                                  Validators.required
                                                              ) || dangerousMaterialsTransportControl.value,
                                                          }
                                    }}
                                    <dtm-ui-popover
                                        [popoverText]="'dtmWebAppLibMission.dataFormStep.flightPurposeDescriptionTooltipText' | transloco"
                                    ></dtm-ui-popover>
                                </label>
                                <textarea
                                    matInput
                                    [formControl]="flightPurposeDescriptionFormControl"
                                    [maxLength]="MAX_FLIGHT_DESCRIPTION_LENGTH"
                                ></textarea>
                                <div class="field-error" *dtmUiFieldHasError="flightPurposeDescriptionFormControl; name: 'required'">
                                    {{ "dtmWebAppLibMission.requiredFieldErrorHint" | transloco }}
                                </div>
                            </dtm-ui-textarea-field>
                        </div>
                        <div class="form-entry" *ngIf="flightPurposeFormControl.value?.isLoadWeightRequired">
                            <dtm-ui-input-field>
                                <label>{{ "dtmWebAppLibMission.dataFormStep.loadWeightLabel" | transloco }}</label>
                                <input
                                    matInput
                                    type="number"
                                    inputmode="numeric"
                                    autocomplete="off"
                                    name="loadWeight"
                                    [formControl]="flightPurposeLoadWeightFormControl"
                                />
                                <span class="field-suffix">
                                    &nbsp;{{ "dtmWebAppLibMission.dataFormStep.loadWeightUnitLabel" | transloco }}
                                </span>
                                <div class="field-error" *dtmUiFieldHasError="flightPurposeLoadWeightFormControl; name: 'required'">
                                    {{ "dtmWebAppLibMission.requiredFieldErrorHint" | transloco }}
                                </div>
                            </dtm-ui-input-field>
                        </div>
                    </ng-container>
                </ng-container>
            </form>
            <dtm-web-app-lib-personal-notes
                (valueChanges)="additionalInformationChange.emit($event)"
                [information]="additionalInformation$ | ngrxPush"
                [isProcessing]="vm.isProcessing"
            ></dtm-web-app-lib-personal-notes>
        </ng-container>
    </ng-container>
    <ng-template #missingPilotsOrUavTemplate>
        <div *ngIf="!vm.allPilots.length" class="info-tile">
            <img src="assets/images/operator-widget.svg" alt="" />
            <p>
                {{ "dtmWebAppLibMission.dataFormStep.missingDataTiles.missingPilotMessageLabel" | transloco }}
            </p>
            <button routerLink="/membership/users-list" class="button-primary" type="button">
                <dtm-ui-icon name="gamepad"></dtm-ui-icon>
                {{ "dtmWebAppLibMission.dataFormStep.missingDataTiles.addFirstPilotButtonLabel" | transloco }}
            </button>
        </div>
        <div *ngIf="!vm.availableUavsWithSetups.length" class="info-tile">
            <img src="assets/images/uav-widget-cover.svg" alt="" />
            <p *ngIf="!vm.canManageUavs">
                {{ "dtmWebAppLibMission.dataFormStep.missingDataTiles.missingUavFromOperatorMessageLabel" | transloco }}
            </p>
            <ng-container *ngIf="vm.canManageUavs">
                <p>{{ "dtmWebAppLibMission.dataFormStep.missingDataTiles.missingUavMessageLabel" | transloco }}</p>
                <button routerLink="/uav/new" class="button-primary" type="button">
                    <dtm-ui-icon name="drone"></dtm-ui-icon>
                    {{ "dtmWebAppLibMission.dataFormStep.missingDataTiles.addFirstUavButtonLabel" | transloco }}
                </button>
            </ng-container>
        </div>
    </ng-template>
</dtm-web-app-lib-mission-wizard-step-wrapper>
<ng-template #loaderTemplate>
    <dtm-ui-loader [isShown]="true"></dtm-ui-loader>
</ng-template>
