import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { ToastrService } from "ngx-toastr";
import { Operator, OperatorConfirmationDocumentType } from "../../models/operator.models";

interface OperatorNumberComponentState {
    operator: Operator | undefined;
    isSecretCodeVisible: boolean;
    canManageOperator: boolean;
}

@Component({
    selector: "dtm-web-app-lib-operator-number[operator]",
    templateUrl: "./operator-number.component.html",
    styleUrls: ["./operator-number.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class OperatorNumberComponent {
    @Input() public set operator(value: Operator | undefined) {
        this.localStore.patchState({ operator: value });
    }

    @Input() public set canManageOperator(value: BooleanInput) {
        this.localStore.patchState({ canManageOperator: coerceBooleanProperty(value) });
    }

    @Output() public downloadFile = new EventEmitter<OperatorConfirmationDocumentType>();

    protected readonly OperatorConfirmationDocumentType = OperatorConfirmationDocumentType;
    protected readonly operator$ = this.localStore.selectByKey("operator").pipe(RxjsUtils.filterFalsy());
    protected readonly isSecretCodeVisible$ = this.localStore.selectByKey("isSecretCodeVisible");
    protected readonly canManageOperator$ = this.localStore.selectByKey("canManageOperator");

    constructor(
        private readonly localStore: LocalComponentStore<OperatorNumberComponentState>,
        private readonly transloco: TranslocoService,
        private readonly toastService: ToastrService
    ) {
        this.localStore.setState({
            operator: undefined,
            isSecretCodeVisible: false,
            canManageOperator: false,
        });
    }

    protected secretCodeVisibilityChange(): void {
        this.localStore.patchState(({ isSecretCodeVisible }) => ({ isSecretCodeVisible: !isSecretCodeVisible }));
    }

    protected handleValueCopy(isSuccess: boolean): void {
        if (isSuccess) {
            this.toastService.success(this.transloco.translate("dtmUi.copyToClipboard.successMessage"));
        } else {
            this.toastService.error(this.transloco.translate("dtmUi.copyToClipboard.errorMessage"));
        }
    }
}
