<div aria-live="polite" toastContainer class="circular-boundary-violation-toast-container-wrapper"></div>
<ng-container *ngrxLet="analysisStatus$; let analysisStatus" dtmUiInvalidFormScrollable>
    <!--  TODO:4695 - isNextButtonEnabled flag will be change after BE corrections -->
    <dtm-web-app-lib-mission-wizard-step-wrapper
        [stepNumber]="stepNumber$ | ngrxPush"
        [stepsAmount]="stepsAmount$ | ngrxPush"
        [title]="'dtmWebAppLibMission.summaryStep.analysis.headerLabel' | transloco"
        (next)="goToSummaryStep()"
        (back)="back.emit()"
        [isNextButtonEnabled]="
            !isProcessing &&
            analysisStatus?.status === PlanVerificationStatus.Completed &&
            (analysisStatus?.isPlanSubmittable || analysisStatus?.applyForSpecificPermit)
        "
        [isLoading]="isProcessing"
        [nextButtonLabel]="'dtmWebAppLibMission.dataFormStep.nextButtonLabel' | transloco"
        [stepId]="MissionWizardSteps.Analysis"
        *ngrxLet="isProcessing$; let isProcessing"
    >
        <dtm-ui-loader [isShown]="analysisStatus && isProcessing"></dtm-ui-loader>
        <ng-container
            *ngrxLet="{
                soraSettings: soraSettings$,
                isRiskMitigationAdded: isRiskMitigationAdded$,
                isTacticalRiskMitigationAdded: isTacticalRiskMitigationAdded$,
                missionType: missionType$,
                areSoraSettingsEnabled: areSoraSettingsEnabled$,
                isPlanWithPermit: isPlanWithPermit$,
            } as vm"
        >
            <dtm-web-app-lib-mission-plan-analysis-panel
                [analysisStatus]="analysisStatus"
                [nearbyMissions]="nearbyMissions$ | ngrxPush"
                [collisionMissions]="collisionMissions$ | ngrxPush"
                [areOtherMissionsProcessing]="areOtherMissionsProcessing$ | ngrxPush"
                [collisionZones]="collisionZones$ | ngrxPush"
                [selectedZoneId]="selectedZoneId$ | ngrxPush"
                [soraSettings]="vm.soraSettings"
                [operationalGeometryData]="operationalGeometryData$ | ngrxPush"
                (flyToRoute)="flyToRoute.emit($event)"
                (flyToMainMissionRoute)="flyToMainMissionRoute.emit()"
                (flyToZone)="flyToZone.emit($event)"
                (otherMissionSelectedId)="otherMissionSelectedId.emit($event)"
                (zoneSelect)="zoneSelect.emit($event)"
            ></dtm-web-app-lib-mission-plan-analysis-panel>
            <ng-container *ngIf="analysisStatus && vm.areSoraSettingsEnabled">
                <dtm-mission-risk-mitigation-measures
                    [isExpanded]="vm.isRiskMitigationAdded"
                    [airRisk]="analysisStatus.sora?.result?.airRisk"
                    [soraSettings]="vm.soraSettings"
                >
                    <button
                        type="button"
                        class="button-secondary sora-settings-button"
                        (click)="addOrEditRiskMitigations()"
                        *ngIf="!vm.isPlanWithPermit"
                    >
                        <ng-container *ngIf="vm.isRiskMitigationAdded; else addModeButtonTemplate">
                            <dtm-ui-icon name="edit"></dtm-ui-icon>
                            {{ "dtmWebAppLibMission.summaryStep.analysis.soraSettings.editButtonLabel" | transloco }}
                        </ng-container>
                        <ng-template #addModeButtonTemplate>
                            <dtm-ui-icon name="add"></dtm-ui-icon>
                            {{ "dtmWebAppLibMission.summaryStep.analysis.soraSettings.addButtonLabel" | transloco }}
                        </ng-template>
                    </button>
                </dtm-mission-risk-mitigation-measures>
                <dtm-mission-final-sora-issues isExpanded [soraResult]="analysisStatus.sora?.result"></dtm-mission-final-sora-issues>
                <dtm-mission-tactical-risk-mitigation-measures
                    *ngIf="
                        vm.missionType === MissionType.BVLOS &&
                        !!analysisStatus.sora?.result?.tacticalMitigationPerformanceRequirementProperty &&
                        analysisStatus.sora?.result?.tacticalMitigationPerformanceRequirementProperty !==
                            TacticalMitigationPerformanceRequirement.VLOS
                    "
                    [tacticalMitigationPerformanceRequirementProperty]="
                        analysisStatus.sora?.result?.tacticalMitigationPerformanceRequirementProperty
                    "
                    [tacticalMitigationPerformanceRequirements]="vm.soraSettings?.tmpr"
                    isExpanded
                    [isReadOnly]="vm.isPlanWithPermit"
                >
                    <button
                        headerButtonsSlot
                        *ngIf="
                            !vm.isPlanWithPermit &&
                            analysisStatus.sora?.result?.tacticalMitigationPerformanceRequirementProperty !==
                                TacticalMitigationPerformanceRequirement.A
                        "
                        type="button"
                        class="button-secondary sora-settings-button"
                        (click)="addOrEditTacticalRiskMitigations()"
                    >
                        <ng-container *ngIf="vm.isTacticalRiskMitigationAdded; else addModeButtonTemplate">
                            <dtm-ui-icon name="edit"></dtm-ui-icon>
                            {{
                                "dtmWebAppLibMission.summaryStep.analysis.soraSettings.tacticalRiskMitigationMeasures.editButtonLabel"
                                    | transloco
                            }}
                        </ng-container>
                        <ng-template #addModeButtonTemplate>
                            <dtm-ui-icon name="add"></dtm-ui-icon>
                            {{
                                "dtmWebAppLibMission.summaryStep.analysis.soraSettings.tacticalRiskMitigationMeasures.addButtonLabel"
                                    | transloco
                            }}
                        </ng-template>
                    </button>

                    <div
                        class="tmpr-error"
                        *ngIf="
                            !vm.isPlanWithPermit &&
                            !vm.isTacticalRiskMitigationAdded &&
                            (analysisStatus.sora?.result?.residualArc ?? 0) >= TMPR_MAXIMUM_RESIDUAL_ARC
                        "
                    >
                        {{
                            "dtmWebAppLibMission.summaryStep.analysis.soraSettings.tacticalRiskMitigationMeasures.tmprErrorLabel"
                                | transloco
                        }}
                    </div>
                </dtm-mission-tactical-risk-mitigation-measures>
                <dtm-mission-adjacent-area-and-space
                    [soraResult]="analysisStatus.sora?.result"
                    [airspaceAnalysis]="analysisStatus.airspace"
                    [technicalSpecification]="(setup$ | ngrxPush)?.technicalSpecification"
                ></dtm-mission-adjacent-area-and-space>
            </ng-container>
        </ng-container>
    </dtm-web-app-lib-mission-wizard-step-wrapper>
</ng-container>
