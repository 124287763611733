import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, Input, Output } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { combineLatest } from "rxjs";
import { map } from "rxjs/operators";

interface MissionPlanningTypeFormControlComponentState {
    hasPermits: boolean;
    hasRegulationExemptions: boolean;
    isDisabled: boolean;
    isSpecificPermitUsageEnabled: boolean;
}

export enum MissionPlanningType {
    CaaPermit = "CaaPermit",
    RegulationExemption = "RegulationExemption",
    Other = "Other",
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-mission-planning-type-form-control[hasPermits]",
    templateUrl: "./mission-planning-type-form-control.component.html",
    styleUrls: ["../mission-data-form-step.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class MissionPlanningTypeFormControlComponent {
    @Input() public set type(value: MissionPlanningType | undefined) {
        switch (value) {
            case MissionPlanningType.CaaPermit:
                this.caaPermitToggleFormControl.setValue(true, { emitEvent: false });
                break;
            case MissionPlanningType.RegulationExemption:
                this.regulationExemptionToggleFormControl.setValue(true, { emitEvent: false });
                break;
            default:
                this.planningTypeFormGroup.reset({ isCaaPermitToggled: false, isRegulationExemptionToggled: false }, { emitEvent: false });
                break;
        }
    }
    @Input() public set hasPermits(value: BooleanInput) {
        this.localStore.patchState({ hasPermits: coerceBooleanProperty(value) });
    }
    @Input() public set hasRegulationExemptions(value: BooleanInput) {
        this.localStore.patchState({ hasRegulationExemptions: coerceBooleanProperty(value) });
    }
    @Input() public set isDisabled(value: BooleanInput) {
        this.localStore.patchState({ isDisabled: coerceBooleanProperty(value) });
    }
    @Input() public set isSpecificPermitUsageEnabled(value: BooleanInput) {
        this.localStore.patchState({ isSpecificPermitUsageEnabled: coerceBooleanProperty(value) });
    }

    protected readonly caaPermitToggleFormControl = new FormControl<boolean | null>(false);
    protected readonly regulationExemptionToggleFormControl = new FormControl<boolean | null>(false);
    protected readonly planningTypeFormGroup = new FormGroup({
        isCaaPermitToggled: this.caaPermitToggleFormControl,
        isRegulationExemptionToggled: this.regulationExemptionToggleFormControl,
    });

    @Output()
    public typeChange = this.planningTypeFormGroup.valueChanges.pipe(
        map(({ isCaaPermitToggled, isRegulationExemptionToggled }) => {
            switch (true) {
                case isCaaPermitToggled:
                    return MissionPlanningType.CaaPermit;
                case isRegulationExemptionToggled:
                    return MissionPlanningType.RegulationExemption;
                default:
                    return MissionPlanningType.Other;
            }
        })
    );

    protected readonly hasPermits$ = this.localStore.selectByKey("hasPermits");
    protected readonly hasRegulationExemptions$ = this.localStore.selectByKey("hasRegulationExemptions");
    protected readonly isSpecificPermitUsageEnabled$ = this.localStore.selectByKey("isSpecificPermitUsageEnabled");

    constructor(private readonly localStore: LocalComponentStore<MissionPlanningTypeFormControlComponentState>) {
        this.localStore.setState({
            hasPermits: false,
            hasRegulationExemptions: false,
            isDisabled: false,
            isSpecificPermitUsageEnabled: false,
        });

        combineLatest([this.hasPermits$, this.localStore.selectByKey("isDisabled")])
            .pipe(untilDestroyed(this))
            .subscribe(([hasPermits, isDisabled]) => {
                if (isDisabled || !hasPermits) {
                    this.caaPermitToggleFormControl.disable();
                } else {
                    this.caaPermitToggleFormControl.enable();
                }
            });
    }
}
