import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatIconModule } from "@angular/material/icon";
import { MatLegacyButtonModule as MatButtonModule } from "@angular/material/legacy-button";
import { MatLegacyCardModule as MatCardModule } from "@angular/material/legacy-card";
import { MatLegacyCheckboxModule as MatCheckboxModule } from "@angular/material/legacy-checkbox";
import { MatLegacyDialogModule as MatDialogModule } from "@angular/material/legacy-dialog";
import { MatLegacyFormFieldModule as MatFormFieldModule } from "@angular/material/legacy-form-field";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { MatLegacyMenuModule as MatMenuModule } from "@angular/material/legacy-menu";
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from "@angular/material/legacy-progress-spinner";
import { MatLegacySelectModule as MatSelectModule } from "@angular/material/legacy-select";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatTooltipModule } from "@angular/material/tooltip";
import { CesiumMapModule } from "@dtm-frontend/shared/map/cesium";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { DmsCoordinatesModule } from "@dtm-frontend/shared/ui/dms-coordinates";
import { SharedI18nModule } from "@dtm-frontend/shared/ui/i18n";
import { LetModule, PushModule } from "@ngrx/component";
import { SharedWebAppModule } from "../../../../../../shared/shared-web-app.module";
import { SoraSettingsComponent } from "../sora-settings/sora-settings.component";
import { AssistedEditorItineraryCardComponent } from "./components/assisted-editor-itinerary-card/assisted-editor-itinerary-card.component";
import { CorridorViewEntityCardComponent } from "./components/corridor-view-entity-card/corridor-view-entity-card.component";
import { DistanceAndTimeDisplayComponent } from "./components/distance-and-time-display/distance-and-time-display.component";
import { DistanceAndTimeDisplayDirective } from "./components/distance-and-time-display/distance-and-time-display.directive";
import { MissionWizardItineraryEditorItineraryPanelComponent } from "./components/itinerary-panel/itinerary-panel.component";
import { PointControlsComponent } from "./components/point-controls/point-controls.component";
import { ReadonlyCardValueComponent } from "./components/readonly-card-value/readonly-card-value.component";
import { SegmentViewEntityCardComponent } from "./components/segment-view-entity-card/segment-view-entity-card.component";
import { ShapeViewEntityCardComponent } from "./components/shape-view-entity-card/shape-view-entity-card.component";
import { StandardEditorItineraryCardComponent } from "./components/standard-editor-itinerary-card/standard-editor-itinerary-card.component";
import { ItineraryPanelStopoverDialogComponent } from "./components/stopover-dialog/stopover-dialog.component";
import { TopBottomHeightControlsComponent } from "./components/top-bottom-height-controls/top-bottom-height-controls.component";
import { TrackDetailsComponent } from "./components/track-details/track-details.component";

@NgModule({
    declarations: [
        MissionWizardItineraryEditorItineraryPanelComponent,
        ReadonlyCardValueComponent,
        SegmentViewEntityCardComponent,
        PointControlsComponent,
        TopBottomHeightControlsComponent,
        ShapeViewEntityCardComponent,
        AssistedEditorItineraryCardComponent,
        DistanceAndTimeDisplayDirective,
        SoraSettingsComponent,
        ItineraryPanelStopoverDialogComponent,
        TrackDetailsComponent,
        StandardEditorItineraryCardComponent,
        CorridorViewEntityCardComponent,
        DistanceAndTimeDisplayComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        MatButtonModule,
        MatCardModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatDialogModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatNativeDateModule,
        MatProgressSpinnerModule,
        MatSelectModule,
        MatTooltipModule,
        LetModule,
        PushModule,
        ReactiveFormsModule,
        SharedUiModule,
        SharedI18nModule,
        MatMenuModule,
        SharedWebAppModule,
        DmsCoordinatesModule,
        MatSlideToggleModule,
        CesiumMapModule,
    ],
    exports: [
        MissionWizardItineraryEditorItineraryPanelComponent,
        AssistedEditorItineraryCardComponent,
        DistanceAndTimeDisplayDirective,
        SoraSettingsComponent,
        ItineraryPanelStopoverDialogComponent,
        TrackDetailsComponent,
        StandardEditorItineraryCardComponent,
        CorridorViewEntityCardComponent,
        DistanceAndTimeDisplayComponent,
    ],
})
export class ItineraryPanelModule {}
