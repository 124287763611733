<ng-container dtmUiInvalidFormScrollable>
    <dtm-ui-select-field
        [multiple]="true"
        [formControl]="propertiesForm.controls.failSafe"
        [placeholder]="'dtmSharedUav.setupProperties.generalTechnicalProperties.failSafePlaceholder' | transloco"
    >
        <label>
            {{ "dtmSharedUav.setupProperties.generalTechnicalProperties.failSafeLabel" | transloco }}
            {{ "dtmWebAppLibUav.setupForm.optionalLabel" | transloco }}
        </label>
        <dtm-ui-select-option *ngFor="let failSafe of failSafeOptions" [value]="failSafe">
            {{ "dtmSharedUav.setupProperties.generalTechnicalProperties.failSafeValue" | transloco : { value: failSafe } }}
        </dtm-ui-select-option>
    </dtm-ui-select-field>
    <div class="switcher">
        <label>
            {{ "dtmSharedUav.setupProperties.generalTechnicalProperties.geofencingLabel" | transloco }}
            <dtm-ui-popover
                [popoverText]="'dtmSharedUav.setupProperties.generalTechnicalProperties.geofencingDescription' | transloco"
            ></dtm-ui-popover>
        </label>
        <mat-slide-toggle [formControl]="propertiesForm.controls.hasGeofencing"></mat-slide-toggle>
    </div>
    <div class="switcher">
        <label>
            {{ "dtmSharedUav.setupProperties.generalTechnicalProperties.detectAndAvoidLabel" | transloco }}
            <dtm-ui-popover
                [popoverText]="'dtmSharedUav.setupProperties.generalTechnicalProperties.detectAndAvoidDescription' | transloco"
            ></dtm-ui-popover>
        </label>
        <mat-slide-toggle [formControl]="propertiesForm.controls.hasDetectAndAvoid"></mat-slide-toggle>
    </div>
    <div class="switcher">
        <label>
            {{ "dtmSharedUav.setupProperties.generalTechnicalProperties.geocageLabel" | transloco }}
            <dtm-ui-popover
                [popoverText]="'dtmSharedUav.setupProperties.generalTechnicalProperties.geocageDescription' | transloco"
            ></dtm-ui-popover>
        </label>
        <mat-slide-toggle [formControl]="propertiesForm.controls.hasGeocage"></mat-slide-toggle>
    </div>
    <div class="switcher">
        <label>
            {{ "dtmSharedUav.setupProperties.generalTechnicalProperties.moduleRedundancyLabel" | transloco }}
            <dtm-ui-popover
                [popoverText]="'dtmSharedUav.setupProperties.generalTechnicalProperties.moduleRedundancyDescription' | transloco"
            ></dtm-ui-popover>
        </label>
        <mat-slide-toggle [formControl]="propertiesForm.controls.hasModuleRedundancy"></mat-slide-toggle>
    </div>
    <div class="switcher">
        <label>
            {{ "dtmSharedUav.setupProperties.generalTechnicalProperties.proximitySensorsLabel" | transloco }}
        </label>
        <mat-slide-toggle [formControl]="propertiesForm.controls.hasProximitySensors"></mat-slide-toggle>
    </div>
</ng-container>
