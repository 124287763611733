<ng-container *ngrxLet="{ properties: properties$, navigationAccuracyItems: navigationAccuracyItems$ } as vm">
    <dtm-uav-lib-setup-property
        [label]="'dtmSharedUav.setupProperties.trackings.trackerLabel' | transloco"
        [isEmpty]="vm.properties.name === null"
    >
        {{ vm.properties.name }}
    </dtm-uav-lib-setup-property>
    <dtm-uav-lib-setup-property [label]="'dtmSharedUav.setupProperties.trackings.identifierLabel' | transloco">
        <span class="identifier-value">{{ vm.properties.identifier }}</span>
    </dtm-uav-lib-setup-property>
    <dtm-uav-lib-setup-property
        *ngIf="!vm.properties.isEmbedded"
        [label]="'dtmSharedUav.setupProperties.trackings.weightLabel' | transloco"
    >
        {{
            "dtmSharedUav.setupProperties.trackings.weightValue"
                | transloco : { value: vm.properties.weight | convertToGrams | localizeNumber }
        }}
    </dtm-uav-lib-setup-property>
    <strong class="accuracy-header">
        {{ "dtmSharedUav.setupProperties.trackings.uavPositionAccuracyLabel" | transloco }}
        <dtm-ui-popover [popoverText]="'dtmSharedUav.setupProperties.trackings.uavPositionAccuracyTooltip' | transloco" />
    </strong>
    <dtm-uav-lib-setup-property [label]="'dtmSharedUav.setupProperties.trackings.navigationAccuracy.horizontalFlight.label' | transloco">
        <span class="navigation-accuracy">
            {{
                "dtmSharedUav.setupProperties.trackings.navigationAccuracy.value"
                    | transloco : { value: vm.properties.flightNavigationAccuracy }
            }}
            <dtm-ui-popover>
                <dtm-uav-lib-navigation-accuracy-class-description
                    [items]="vm.navigationAccuracyItems"
                    [classValue]="vm.properties.flightNavigationAccuracy"
                    [type]="NavigationAccuracyType.HorizontalFlight"
                />
            </dtm-ui-popover>
        </span>
    </dtm-uav-lib-setup-property>
    <dtm-uav-lib-setup-property [label]="'dtmSharedUav.setupProperties.trackings.navigationAccuracy.takeoffAndLanding.label' | transloco">
        <span class="navigation-accuracy">
            {{
                "dtmSharedUav.setupProperties.trackings.navigationAccuracy.value"
                    | transloco : { value: vm.properties.takeoffAndLandingNavigationAccuracy }
            }}
            <dtm-ui-popover>
                <dtm-uav-lib-navigation-accuracy-class-description
                    [items]="vm.navigationAccuracyItems"
                    [classValue]="vm.properties.takeoffAndLandingNavigationAccuracy"
                    [type]="NavigationAccuracyType.TakeoffAndLanding"
                />
            </dtm-ui-popover>
        </span>
    </dtm-uav-lib-setup-property>
</ng-container>
