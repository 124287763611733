import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy } from "@angular/core";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { ActiveToast, ToastrService } from "ngx-toastr";
import { combineLatest, filter, first, forkJoin, map, switchMap, tap } from "rxjs";
import { MissionState } from "../../../state/mission.state";
import { checkIfMissionPlanRoutePossiblyViolateMaximumHeight } from "./check-route-height-violation";
import { HeightViolationAlertDialogComponent } from "./height-violation-alert-dialog/height-violation-alert-dialog.component";
import {
    HeightViolationAlertToastComponent,
    OPEN_ALERT_IMAGE_DIALOG_ACTION,
} from "./height-violation-alert-toast/height-violation-alert-toast.component";

interface HeightViolationAlertContainerComponentState {
    latestViolationClosedPlanId: string | undefined;
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-height-violation-alert-container",
    template: "",
    styleUrls: ["./height-violation-alert-container.component.scss"],
    providers: [LocalComponentStore],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeightViolationAlertContainerComponent implements OnDestroy, AfterViewInit {
    protected violationToast: ActiveToast<unknown> | undefined;
    private readonly route$ = this.store.select(MissionState.currentPlanRoute);
    private readonly doesRoutePossiblyViolateHeight$ = this.route$.pipe(map(checkIfMissionPlanRoutePossiblyViolateMaximumHeight));

    constructor(
        private readonly localStore: LocalComponentStore<HeightViolationAlertContainerComponentState>,
        private readonly toastrService: ToastrService,
        private readonly dialog: MatDialog,
        private readonly store: Store
    ) {
        this.localStore.setState({
            latestViolationClosedPlanId: undefined,
        });
    }

    public ngAfterViewInit(): void {
        this.watchForRouteChanges();
    }

    public ngOnDestroy(): void {
        this.toastrService.clear(this.violationToast?.toastId);
        this.violationToast = undefined;
    }

    private openDialog(): void {
        this.dialog.open(HeightViolationAlertDialogComponent);
    }

    private watchForRouteChanges(): void {
        this.doesRoutePossiblyViolateHeight$
            .pipe(
                filter((doesViolate) => {
                    if (!doesViolate) {
                        this.toastrService.clear(this.violationToast?.toastId);
                        this.violationToast = undefined;
                        this.localStore.patchState({ latestViolationClosedPlanId: undefined });

                        return false;
                    }

                    return true;
                }),
                switchMap(() => combineLatest([this.route$, this.localStore.selectByKey("latestViolationClosedPlanId")]).pipe(first())),
                filter(
                    ([route, latestViolationClosedPlanId]) =>
                        !this.violationToast && !!route && latestViolationClosedPlanId !== route.planId
                ),
                switchMap(([route]) => {
                    this.violationToast = this.toastrService.warning(undefined, undefined, {
                        toastComponent: HeightViolationAlertToastComponent,
                    });

                    return forkJoin([
                        this.violationToast.onHidden.pipe(
                            tap(() => {
                                this.localStore.patchState({
                                    latestViolationClosedPlanId: route?.planId,
                                });
                                this.violationToast = undefined;
                            })
                        ),
                        this.violationToast.onAction.pipe(
                            tap((action) => {
                                if (action === OPEN_ALERT_IMAGE_DIALOG_ACTION) {
                                    this.openDialog();
                                }
                            })
                        ),
                    ]);
                }),
                untilDestroyed(this)
            )
            .subscribe();
    }
}
