<ng-container
    *ngrxLet="{
        mission: mission$,
        missionPlanData: missionPlanData$,
        currentPlanAnalysisStatus: currentPlanAnalysisStatus$,
        collisionZones: collisionZones$,
        selectedZoneId: selectedZoneId$,
        isMissionDataProcessing: isMissionDataProcessing$,
        areAdditionalInformationProcessing: areAdditionalInformationProcessing$,
        nearbyMissions: nearbyMissions$,
        areNearbyMissionsEnabled: areNearbyMissionsEnabled$,
    } as vm"
>
    <mat-card class="side-panel dtm-tabs" *ngIf="vm.mission as mission">
        <mat-tab-group>
            <mat-tab [label]="'dtmWebAppLibTactical.missionInformationLabel' | transloco" color="accent">
                <div class="mission-details">
                    <div class="basic-info" *ngIf="vm.missionPlanData?.plan as missionPlan">
                        <div class="badge-group">
                            <dtm-web-app-lib-tactical-mission-status-chip
                                class="chip-info"
                                [status]="mission.status"
                            ></dtm-web-app-lib-tactical-mission-status-chip>
                            <div
                                class="chip light message"
                                *ngIf="missionPlan?.remarks | invoke : getAuthorityAcceptationItemWithMessage as authorityAcceptationItem"
                            >
                                <dtm-ui-icon
                                    [name]="authorityAcceptationItem.isConfirmedByUser ? 'mail-open' : 'complex-mail-notification'"
                                ></dtm-ui-icon>
                            </div>
                            <div class="chip light chip-info flight-type" *ngIf="missionPlan?.flightType as flightType">
                                <dtm-ui-icon [name]="flightType === MissionType.BVLOS ? 'eye-off' : 'eye'"></dtm-ui-icon>
                                {{ "dtmWebAppLibShared.missionTypeLabel" | transloco : { value: flightType } }}
                            </div>
                        </div>
                        <div class="content">
                            <div class="info">
                                <div class="row">
                                    <div class="column">
                                        <dtm-ui-icon name="calendar-event"></dtm-ui-icon>
                                        <span class="time-label">
                                            {{
                                                vm.mission.startTime.min
                                                    | localizeDate
                                                        : {
                                                              day: "numeric",
                                                              month: "numeric"
                                                          }
                                            }}
                                        </span>
                                        <div class="additional-info">
                                            {{ vm.mission.startTime.min | localizeDate : { weekday: "long" } }}
                                        </div>
                                    </div>
                                    <div class="column">
                                        <dtm-ui-icon name="time"></dtm-ui-icon>
                                        <p class="time-label">
                                            {{ vm.mission.startTime.min | localizeDate : { timeStyle: "short" } }}
                                            -
                                            {{ vm.mission.endTime.max | localizeDate : { timeStyle: "short" } }}
                                        </p>
                                        <p class="additional-info">
                                            <dtm-ui-time-diff-display
                                                [firstDate]="vm.mission.startTime.min"
                                                [secondDate]="vm.mission.endTime.max"
                                            ></dtm-ui-time-diff-display>
                                        </p>
                                    </div>
                                </div>
                                <dtm-web-app-lib-tactical-mission-details [mission]="mission"></dtm-web-app-lib-tactical-mission-details>
                            </div>
                            <button class="button-icon" type="button" (click)="flyToMission()">
                                <dtm-ui-icon name="road-map"></dtm-ui-icon>
                            </button>
                        </div>
                    </div>

                    <dtm-mission-mission-messages
                        [messages]="mission.timeOverrideJustification ? [{ text: mission.timeOverrideJustification }] : []"
                    ></dtm-mission-mission-messages>

                    <dtm-mission-flight-requirements
                        [zones]="vm.collisionZones"
                        [remarks]="vm.missionPlanData?.plan?.remarks"
                        [currentPlanAnalysisStatus]="vm.currentPlanAnalysisStatus"
                        [isProcessing]="vm.isMissionDataProcessing"
                        [selectedZoneId]="vm.selectedZoneId"
                        [formalJustification]="vm.missionPlanData?.plan?.remarks?.justification"
                        [missionContext]="MissionContextType.Operator"
                        [missionPhase]="vm.missionPlanData?.plan?.phase"
                        [rejectionJustification]="vm.missionPlanData?.plan?.mission?.rejectionJustification"
                        [rejectionDate]="vm.missionPlanData?.plan?.mission?.rejectedAt"
                        [isUtmSupervisorIntegrated]="isUtmSupervisorIntegrated$ | ngrxPush"
                        (zoneSelect)="toggleSelection($event)"
                        (messageFromManagerReadingConfirmed)="messageAcknowledged($event)"
                    ></dtm-mission-flight-requirements>

                    <dtm-mission-mission-details [mission]="missionDataSimple$ | ngrxPush" [isExpanded]="false">
                        <dtm-ui-expandable-simple-header
                            headerSlot
                            [label]="'dtmWebAppLibShared.mission.missionDataHeaderLabel' | transloco"
                        >
                        </dtm-ui-expandable-simple-header>
                    </dtm-mission-mission-details>

                    <dtm-ui-expandable-panel [isExpanded]="false" [hasHeaderSeparator]="false">
                        <dtm-ui-expandable-simple-header
                            headerSlot
                            [label]="'dtmWebAppLibTactical.routeDetailsLabel' | transloco"
                        ></dtm-ui-expandable-simple-header>
                        <dtm-ui-route-details
                            [route]="mission.route"
                            [sectionStatuses]="sectionStatuses$ | ngrxPush"
                        ></dtm-ui-route-details>
                    </dtm-ui-expandable-panel>
                    <dtm-ui-expandable-panel [hasHeaderSeparator]="false" *ngIf="vm.currentPlanAnalysisStatus?.sora">
                        <dtm-ui-expandable-simple-header
                            headerSlot
                            [label]="'dtmWebAppLibShared.mission.analysis.groundRiskTitle' | transloco"
                        >
                        </dtm-ui-expandable-simple-header>
                        <dtm-mission-population-density
                            [populationDensity]="vm.currentPlanAnalysisStatus?.sora?.result?.populationDensity"
                        ></dtm-mission-population-density>
                    </dtm-ui-expandable-panel>
                    <dtm-web-app-lib-personal-notes
                        [isProcessing]="vm.areAdditionalInformationProcessing"
                        [information]="vm.missionPlanData?.plan?.information"
                        (valueChanges)="updateAdditionalInformation($event)"
                    ></dtm-web-app-lib-personal-notes>
                </div>
            </mat-tab>
            <mat-tab
                [label]="
                    ('dtmWebAppLibTactical.nearbyMissionsLabel' | transloco) +
                    (vm.areNearbyMissionsEnabled | invoke : getNearbyMissionCountText : vm.nearbyMissions?.length)
                "
            >
                <dtm-web-app-lib-tactical-nearby-missions
                    [nearbyMissions]="vm.nearbyMissions"
                    [mainMission]="mission"
                    [isNearbyMissionDataProcessing]="isNearbyMissionDataProcessing$ | ngrxPush"
                    (zoomToGeometry)="zoomToGeometry($event)"
                    (nearbyMissionsRefresh)="refreshNearbyMissions($event)"
                    (nearbyMissionSelect)="onNearbyMissionSelect($event)"
                ></dtm-web-app-lib-tactical-nearby-missions>
            </mat-tab>
        </mat-tab-group>
    </mat-card>
    <div class="loader-overlay" [class.in-progress]="isMissionDataProcessing$ | ngrxPush">
        <mat-spinner diameter="60" color="accent" class="loader"></mat-spinner>
    </div>
</ng-container>
