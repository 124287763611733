import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from "@angular/material/legacy-autocomplete";
import { MatLegacyCardModule as MatCardModule } from "@angular/material/legacy-card";
import { MatLegacyDialogModule as MatDialogModule } from "@angular/material/legacy-dialog";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from "@angular/material/legacy-progress-spinner";
import { MatLegacyRadioModule as MatRadioModule } from "@angular/material/legacy-radio";
import { MatTooltipModule } from "@angular/material/tooltip";
import { RouterModule } from "@angular/router";
import { CesiumMapModule } from "@dtm-frontend/shared/map/cesium";
import { SharedMissionModule } from "@dtm-frontend/shared/mission";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { DmsCoordinatesModule } from "@dtm-frontend/shared/ui/dms-coordinates";
import { LanguageCode, SharedI18nModule, TranslationHelperService, getTranslocoInlineLoader } from "@dtm-frontend/shared/ui/i18n";
import { WizardModule } from "@dtm-frontend/shared/ui/wizard";
import { TRANSLOCO_SCOPE } from "@jsverse/transloco";
import { LetModule, PushModule } from "@ngrx/component";
import { NgxsModule } from "@ngxs/store";
import { SharedWebAppModule } from "../shared";
import { ApplicationCreatorWizardContentComponent } from "./components/application-creator-wizard/content/application-creator-wizard-content.component";
import { ApplicationCreatorWizardHeaderComponent } from "./components/application-creator-wizard/header/application-creator-wizard-header.component";
import { ApplicationInfoStepComponent } from "./components/application-creator-wizard/steps/application-info-step/application-info-step.component";
import { OperationDetailsStepComponent } from "./components/application-creator-wizard/steps/operation-details-step/operation-details-step.component";
import { OperationInfoStepComponent } from "./components/application-creator-wizard/steps/operation-info-step/operation-info-step.component";
import { StatementsStepComponent } from "./components/application-creator-wizard/steps/statements-step/statements-step.component";
import { DraftNameDialogComponent } from "./components/draft-name-dialog/draft-name-dialog.component";
import { ManualComponent } from "./components/manual/manual.component";
import { AdditionalCrewFormComponent } from "./components/operation-details/additional-crew-form/additional-crew-form.component";
import { AdditionalCrewComponent } from "./components/operation-details/additional-crew/additional-crew.component";
import { CompetenciesFormComponent } from "./components/operation-details/competencies-form/competencies-form.component";
import { CompetenciesComponent } from "./components/operation-details/competencies/competencies.component";
import { CrewCompetenciesFormComponent } from "./components/operation-details/crew-competencies-form/crew-competencies-form.component";
import { CrewCompetenciesComponent } from "./components/operation-details/crew-competencies/crew-competencies.component";
import { CrewTrainingsFormComponent } from "./components/operation-details/crew-trainings-form/crew-trainings-form.component";
import { CrewTrainingsComponent } from "./components/operation-details/crew-trainings/crew-trainings.component";
import { NamesControlComponent } from "./components/operation-details/names-control/names-control.component";
import { AreaEditorDialogComponent } from "./components/operation-details/operation-area-control/area-editor-dialog/area-editor-dialog.component";
import { EditorMapComponent } from "./components/operation-details/operation-area-control/area-editor-dialog/editor-map/editor-map.component";
import { ManualUpdatePanelComponent } from "./components/operation-details/operation-area-control/area-editor-dialog/manual-update-panel/manual-update-panel.component";
import { AreaPreviewDialogComponent } from "./components/operation-details/operation-area-control/area-preview-dialog/area-preview-dialog.component";
import { OperationAreaControlComponent } from "./components/operation-details/operation-area-control/operation-area-control.component";
import { PilotCompetenciesComponent } from "./components/operation-details/pilot-competencies/pilot-competencies.component";
import { PilotTrainingsComponent } from "./components/operation-details/pilot-trainings/pilot-trainings.component";
import { TimeOfTheDayControlComponent } from "./components/operation-details/time-of-the-day-control/time-of-the-day-control.component";
import { TrainingsFormComponent } from "./components/operation-details/trainings-form/trainings-form.component";
import { TrainingsComponent } from "./components/operation-details/trainings/trainings.component";
import { AdjacentAreaRiskInfoComponent } from "./components/operation-info/adjacent-area-risk-info/adjacent-area-risk-info.component";
import { AirspaceRiskInfoComponent } from "./components/operation-info/airspace-risk-info/airspace-risk-info.component";
import { GroundRiskInfoComponent } from "./components/operation-info/ground-risk-info/ground-risk-info.component";
import { OperationInfoComponent } from "./components/operation-info/operation-info/operation-info.component";
import { OperatorInfoComponent } from "./components/operation-info/operator-info/operator-info.component";
import { SailInfoComponent } from "./components/operation-info/sail-info/sail-info.component";
import { UavAdditionalInfoComponent } from "./components/operation-info/uav-additional-info/uav-additional-info.component";
import { UavInfoComponent } from "./components/operation-info/uav-info/uav-info.component";
import { ZoneGeometryInfoComponent } from "./components/operation-info/zone-geometry-info/zone-geometry-info.component";
import { OperationSearchControlComponent } from "./components/operation-search-control/operation-search-control.component";
import { SpecificPermitApplicationApiService } from "./services/specific-permit-application-api.service";
import { SpecificPermitApplicationState } from "./state/specific-permit-application.state";

@NgModule({
    declarations: [
        ApplicationCreatorWizardContentComponent,
        ApplicationCreatorWizardHeaderComponent,
        ManualComponent,
        OperationInfoStepComponent,
        OperationDetailsStepComponent,
        StatementsStepComponent,
        ApplicationInfoStepComponent,
        OperationSearchControlComponent,
        DraftNameDialogComponent,
        OperatorInfoComponent,
        UavInfoComponent,
        UavAdditionalInfoComponent,
        OperationInfoComponent,
        SailInfoComponent,
        ZoneGeometryInfoComponent,
        GroundRiskInfoComponent,
        AirspaceRiskInfoComponent,
        AdjacentAreaRiskInfoComponent,
        OperationAreaControlComponent,
        AreaPreviewDialogComponent,
        AreaEditorDialogComponent,
        EditorMapComponent,
        ManualUpdatePanelComponent,
        CompetenciesComponent,
        PilotCompetenciesComponent,
        NamesControlComponent,
        CompetenciesFormComponent,
        PilotTrainingsComponent,
        TrainingsComponent,
        TrainingsFormComponent,
        AdditionalCrewComponent,
        AdditionalCrewFormComponent,
        CrewCompetenciesComponent,
        CrewCompetenciesFormComponent,
        CrewTrainingsComponent,
        CrewTrainingsFormComponent,
        TimeOfTheDayControlComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        NgxsModule.forFeature([SpecificPermitApplicationState]),
        RouterModule,
        SharedUiModule,
        WizardModule,
        SharedI18nModule,
        LetModule,
        PushModule,
        MatAutocompleteModule,
        MatCardModule,
        MatProgressSpinnerModule,
        ReactiveFormsModule,
        MatDialogModule,
        MatInputModule,
        MatRadioModule,
        SharedWebAppModule,
        MatDatepickerModule,
        CesiumMapModule,
        MatTooltipModule,
        DmsCoordinatesModule,
        SharedMissionModule,
    ],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            multi: true,
            useValue: {
                scope: "dtmWebAppLibSpecPermApp",
                loader: getTranslocoInlineLoader((language: LanguageCode) => import(`./assets/i18n/${language}.json`)),
            },
        },
        {
            provide: SpecificPermitApplicationApiService,
            useValue: undefined,
        },
        TranslationHelperService,
    ],
    exports: [
        ManualComponent,
        OperationInfoStepComponent,
        OperationDetailsStepComponent,
        StatementsStepComponent,
        ApplicationInfoStepComponent,
        OperationSearchControlComponent,
        OperatorInfoComponent,
        UavInfoComponent,
        UavAdditionalInfoComponent,
        OperationInfoComponent,
        SailInfoComponent,
        ZoneGeometryInfoComponent,
        GroundRiskInfoComponent,
        AirspaceRiskInfoComponent,
        AdjacentAreaRiskInfoComponent,
        OperationAreaControlComponent,
        CompetenciesComponent,
        PilotCompetenciesComponent,
        NamesControlComponent,
        CompetenciesFormComponent,
        PilotTrainingsComponent,
        TrainingsComponent,
        TrainingsFormComponent,
        AdditionalCrewComponent,
        AdditionalCrewFormComponent,
        CrewCompetenciesComponent,
        CrewCompetenciesFormComponent,
        CrewTrainingsComponent,
        CrewTrainingsFormComponent,
        TimeOfTheDayControlComponent,
    ],
})
export class SpecificPermitApplicationModule {
    public static forRoot(): ModuleWithProviders<SpecificPermitApplicationModule> {
        return {
            ngModule: SpecificPermitApplicationModule,
            providers: [
                {
                    provide: SpecificPermitApplicationApiService,
                    useClass: SpecificPermitApplicationApiService,
                },
            ],
        };
    }

    public static forTest(): ModuleWithProviders<SpecificPermitApplicationModule> {
        return {
            ngModule: SpecificPermitApplicationModule,
            providers: [
                {
                    provide: SpecificPermitApplicationApiService,
                    useValue: null,
                },
            ],
        };
    }
}
