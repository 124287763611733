<ng-container [formGroup]="frequenciesForm">
    <ng-container formArrayName="frequencies">
        <section
            *ngFor="let frequency of frequenciesArray.controls; index as frequencyIndex"
            [formGroupName]="frequencyIndex"
            class="frequency"
        >
            <div class="section-header">
                <h5 class="section-title">
                    {{ "dtmWebAppLibUav.setupForm.communications.frequencySectionHeader" | transloco : { number: frequencyIndex + 1 } }}
                </h5>
                <button
                    type="button"
                    class="button-icon"
                    (click)="removeFrequency(frequencyIndex)"
                    [disabled]="frequenciesArray.controls.length === 1"
                >
                    <dtm-ui-icon name="delete-bin"></dtm-ui-icon>
                </button>
            </div>
            <div class="section-content">
                <p class="field-label">
                    {{ "dtmWebAppLibUav.setupForm.communications.frequencyType" | transloco }}
                </p>
                <dtm-ui-radio-group [formControl]="frequency.controls.asRange">
                    <dtm-ui-radio-field [value]="false">
                        {{ "dtmWebAppLibUav.setupForm.communications.frequencyAsSingleValueLabel" | transloco }}
                    </dtm-ui-radio-field>
                    <dtm-ui-radio-field [value]="true">
                        {{ "dtmWebAppLibUav.setupForm.communications.frequencyAsRangeValueLabel" | transloco }}
                    </dtm-ui-radio-field>
                </dtm-ui-radio-group>
                <div class="properties">
                    <dtm-ui-input-field *ngIf="!frequency.controls.asRange.value">
                        <label>
                            {{ "dtmWebAppLibUav.setupForm.communications.frequencySingleLabel" | transloco }}
                        </label>
                        <input
                            matInput
                            type="number"
                            [formControl]="frequency.controls.minGhz"
                            [min]="MIN_FREQUENCY_VALUE"
                            [max]="MAX_FREQUENCY_VALUE"
                            inputmode="decimal"
                            autocomplete="off"
                            required
                        />
                        <div class="field-suffix">
                            {{ "dtmSharedUav.setupProperties.communicationProperties.frequencyUnitLabel" | transloco }}
                        </div>
                        <div class="field-error" *dtmUiFieldHasError="frequency.controls.minGhz; name: ['required', 'pattern']">
                            {{ "dtmWebAppLibUav.setupForm.requiredFieldErrorHint" | transloco }}
                        </div>
                        <div class="field-error" *dtmUiFieldHasError="frequency.controls.minGhz; name: 'min'; error as error">
                            {{ "dtmWebAppLibUav.setupForm.valueLessThanMinErrorHint" | transloco : { min: error.min | localizeNumber } }}
                        </div>
                        <div class="field-error" *dtmUiFieldHasError="frequency.controls.minGhz; name: 'max'; error as error">
                            {{ "dtmWebAppLibUav.setupForm.valueGreaterThanMaxErrorHint" | transloco : { max: error.max | localizeNumber } }}
                        </div>
                    </dtm-ui-input-field>
                    <ng-container *ngIf="frequency.controls.asRange.value">
                        <dtm-ui-input-field>
                            <label>
                                {{ "dtmWebAppLibUav.setupForm.communications.frequencyRangeMinLabel" | transloco }}
                            </label>
                            <input
                                matInput
                                type="number"
                                [formControl]="frequency.controls.minGhz"
                                [min]="MIN_FREQUENCY_VALUE"
                                [max]="MAX_FREQUENCY_VALUE"
                                inputmode="decimal"
                                autocomplete="off"
                                required
                            />
                            <div class="field-suffix">
                                {{ "dtmSharedUav.setupProperties.communicationProperties.frequencyUnitLabel" | transloco }}
                            </div>
                            <div class="field-error" *dtmUiFieldHasError="frequency.controls.minGhz; name: ['required', 'pattern']">
                                {{ "dtmWebAppLibUav.setupForm.requiredFieldErrorHint" | transloco }}
                            </div>
                            <div class="field-error" *dtmUiFieldHasError="frequency.controls.minGhz; name: 'min'; error as error">
                                {{
                                    "dtmWebAppLibUav.setupForm.valueLessThanMinErrorHint" | transloco : { min: error.min | localizeNumber }
                                }}
                            </div>
                            <div class="field-error" *ngIf="frequency.controls.minGhz.valid && frequency.errors?.range">
                                {{ "dtmWebAppLibUav.setupForm.invalidFrequencyRangeErrorHint" | transloco }}
                            </div>
                            <div class="field-error" *dtmUiFieldHasError="frequency.controls.minGhz; name: 'max'; error as error">
                                {{
                                    "dtmWebAppLibUav.setupForm.valueGreaterThanMaxErrorHint"
                                        | transloco : { max: error.max | localizeNumber }
                                }}
                            </div>
                        </dtm-ui-input-field>
                        <dtm-ui-input-field>
                            <label>
                                {{ "dtmWebAppLibUav.setupForm.communications.frequencyRangeMaxLabel" | transloco }}
                            </label>
                            <input
                                matInput
                                type="number"
                                [formControl]="frequency.controls.maxGhz"
                                [min]="MIN_FREQUENCY_VALUE"
                                [max]="MAX_FREQUENCY_VALUE"
                                inputmode="decimal"
                                autocomplete="off"
                                required
                            />
                            <div class="field-suffix">
                                {{ "dtmSharedUav.setupProperties.communicationProperties.frequencyUnitLabel" | transloco }}
                            </div>
                            <div class="field-error" *dtmUiFieldHasError="frequency.controls.maxGhz; name: ['required', 'pattern']">
                                {{ "dtmWebAppLibUav.setupForm.requiredFieldErrorHint" | transloco }}
                            </div>
                            <div class="field-error" *dtmUiFieldHasError="frequency.controls.maxGhz; name: 'min'; error as error">
                                {{
                                    "dtmWebAppLibUav.setupForm.valueLessThanMinErrorHint" | transloco : { min: error.min | localizeNumber }
                                }}
                            </div>
                            <div class="field-error" *dtmUiFieldHasError="frequency.controls.maxGhz; name: 'max'; error as error">
                                {{
                                    "dtmWebAppLibUav.setupForm.valueGreaterThanMaxErrorHint"
                                        | transloco : { max: error.max | localizeNumber }
                                }}
                            </div>
                            <div class="field-error" *ngIf="frequency.controls.maxGhz.valid && frequency.errors?.range">
                                {{ "dtmWebAppLibUav.setupForm.invalidFrequencyRangeErrorHint" | transloco }}
                            </div>
                        </dtm-ui-input-field>
                    </ng-container>
                </div>
            </div>
        </section>
    </ng-container>
</ng-container>

<div class="add-frequency">
    <button type="button" class="button-secondary" (click)="addFrequency()">
        <dtm-ui-icon name="add"></dtm-ui-icon>
        {{ "dtmWebAppLibUav.setupForm.communications.addFrequencyButtonLabel" | transloco }}
    </button>
</div>
