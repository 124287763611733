<ng-container *ngIf="!(error$ | ngrxPush); else errorTemplate">
    <ng-container
        *ngrxLet="{
            currentTime: tacticalMissionService.currentTime$,
            trackerWarning: trackerWarning$,
            routeData: routeData$,
            isMissionDataProcessing: isMissionDataProcessing$,
            missionData: missionData$,
            missionPlanData: missionPlanData$,
            activeEmergency: activeEmergency$,
            isEmergencyProcessing: isEmergencyProcessing$,
            isManageableByPilot: isManageableByPilot$,
        } as vm"
    >
        <div class="main-container">
            <div class="side-panel" *ngrxLet="isDataPanelFolded$ as isDataPanelFolded" [class.folded]="isDataPanelFolded">
                <div class="data-panel" [attr.inert]="isDataPanelFolded || null">
                    <router-outlet></router-outlet>
                </div>
                <div class="view-switcher">
                    <button type="button" (click)="togglePanelFold()">
                        <dtm-ui-icon
                            [name]="isDataPanelFolded ? 'menu-unfold' : 'menu-fold'"
                            [matTooltip]="'dtmWebAppLibMission.foldingButtonLabel' | transloco : { isFolded: isDataPanelFolded }"
                            [attr.aria-label]="'dtmWebAppLibMission.foldingButtonLabel' | transloco : { isFolded: isDataPanelFolded }"
                            [attr.aria-expanded]="!isDataPanelFolded"
                            matTooltipPosition="right"
                        ></dtm-ui-icon>
                    </button>
                </div>
            </div>

            <div class="map-container">
                <dtm-web-app-lib-mission-route
                    [routeData]="vm.routeData"
                    [isProcessing]="vm.isMissionDataProcessing"
                    [flights]="flights$ | ngrxPush"
                    [trackerWarning]="vm.trackerWarning"
                    [zoomTo]="zoomTo$ | ngrxPush"
                    [violation]="violation$ | ngrxPush"
                    [trajectories]="trajectories$ | ngrxPush"
                    [hemsEventData]="hemsEventData$ | ngrxPush"
                    [checkinList]="checkinList$ | ngrxPush"
                    (flightPositionUpdatesEnrich)="enrichFlightPositionUpdates($event)"
                >
                    <div class="map-overlay">
                        <div class="warning-snackbar" [ngSwitch]="vm.trackerWarning">
                            <ng-container *ngSwitchCase="TrackerWarning.Verification">
                                <dtm-ui-icon name="information-fill"></dtm-ui-icon>
                                <div class="content">
                                    <h5>{{ "dtmWebAppLibTactical.trackerWarnings.verification.title" | transloco }}</h5>
                                    <p [innerHTML]="'dtmWebAppLibTactical.trackerWarnings.verification.text' | transloco"></p>
                                </div>
                            </ng-container>
                            <ng-container *ngSwitchCase="TrackerWarning.Confirmation">
                                <dtm-ui-icon name="information-fill" class="confirmation"></dtm-ui-icon>
                                <div class="content">
                                    <h5>{{ "dtmWebAppLibTactical.trackerWarnings.confirmation.title" | transloco }}</h5>
                                    <p [innerHTML]="'dtmWebAppLibTactical.trackerWarnings.confirmation.text' | transloco"></p>
                                </div>
                                <div
                                    class="buttons-container"
                                    *ngrxLet="isReadingConfirmationProcessing$ as isReadingConfirmationProcessing"
                                >
                                    <button
                                        type="button"
                                        class="button-primary"
                                        (click)="confirmReadings()"
                                        [disabled]="isReadingConfirmationProcessing"
                                    >
                                        <ng-container *ngIf="!isReadingConfirmationProcessing"
                                            >{{ "dtmWebAppLibTactical.trackerWarnings.confirmation.confirmationButtonText" | transloco }}
                                        </ng-container>
                                        <mat-spinner diameter="20" color="accent" *ngIf="isReadingConfirmationProcessing"></mat-spinner>
                                    </button>
                                    <button
                                        type="button"
                                        class="button-warn"
                                        (click)="rejectReadings()"
                                        [disabled]="isReadingConfirmationProcessing"
                                    >
                                        {{ "dtmWebAppLibTactical.trackerWarnings.confirmation.rejectionButtonText" | transloco }}
                                    </button>
                                </div>
                            </ng-container>
                            <ng-container *ngSwitchCase="TrackerWarning.BadReadings">
                                <dtm-ui-icon name="sign-prohibition" class="bad-readings"></dtm-ui-icon>
                                <div class="content">
                                    <h5>{{ "dtmWebAppLibTactical.trackerWarnings.badReadings.title" | transloco }}</h5>
                                    <p [innerHTML]="'dtmWebAppLibTactical.trackerWarnings.badReadings.text' | transloco"></p>
                                </div>
                                <div class="buttons-container">
                                    <button type="button" class="button-warn" (click)="confirmLanding()">
                                        {{ "dtmWebAppLibTactical.trackerWarnings.badReadings.confirmationButtonText" | transloco }}
                                    </button>
                                </div>
                            </ng-container>
                        </div>
                        <div aria-live="polite" toastContainer class="toast-container-wrapper"></div>
                    </div>
                    <dtm-web-app-lib-tactical-emergency
                        class="emergency-container"
                        [activeEmergency]="vm.activeEmergency"
                        [isLoading]="vm.isEmergencyProcessing"
                        (emergencyReport)="reportEmergency($event.emergencyType)"
                        (emergencyCancel)="cancelEmergency()"
                        *ngIf="
                            vm.missionData &&
                            [MissionStatus.Activated, MissionStatus.Started].includes(vm.missionData.status) &&
                            vm.isManageableByPilot
                        "
                    ></dtm-web-app-lib-tactical-emergency>
                </dtm-web-app-lib-mission-route>
                <dtm-mission-route-side-view
                    *ngIf="vm.routeData?.route | invoke : parseRouteDataForSideView as route"
                    [isExpanded]="false"
                    [isLoading]="vm.isMissionDataProcessing"
                    [route]="route"
                    [isSimpleMode]="vm.missionPlanData?.plan?.itinerary?.editorType === ItineraryEditorType.Standard"
                    [areBuffersVisible]="vm.missionPlanData?.plan?.flightType === MissionType.BVLOS"
                    (expandedChange)="heightViolationDialogLink.isExpanded = $event"
                >
                    <div headerSlot class="side-view-title">
                        <h2>{{ "dtmWebAppLibMission.routeSideView.sideViewHeader" | transloco }}</h2>
                        <dtm-web-app-lib-height-violation-alert-dialog-link
                            [isExpanded]="false"
                            [route]="route"
                            #heightViolationDialogLink
                        ></dtm-web-app-lib-height-violation-alert-dialog-link>
                    </div>
                    >
                </dtm-mission-route-side-view>
            </div>
        </div>
        <ng-container *ngIf="vm.missionData?.status as status">
            <div
                class="bottom-actions"
                *ngIf="(isDetailsView$ | ngrxPush) && !ENDED_MISSION_STATUES.includes(status) && vm.missionData as mission"
            >
                <button type="button" class="button-secondary" routerLink="/tactical/prepare-for-mission">
                    <dtm-ui-icon name="arrow-go-back"></dtm-ui-icon>
                    {{ "dtmWebAppLibTactical.returnToMissionListButtonLabel" | transloco }}
                </button>

                <div class="primary-actions">
                    <button
                        *ngIf="(status === MissionStatus.Started || status === MissionStatus.Activated) && vm.isManageableByPilot"
                        type="button"
                        class="button-primary"
                        (click)="openDeactivateMissionModal()"
                        [disabled]="vm.isMissionDataProcessing"
                    >
                        {{ "dtmWebAppLibTactical.deactivateMissionButtonLabel" | transloco }}
                    </button>
                    <ng-container *ngIf="status === MissionStatus.Accepted">
                        <button
                            type="button"
                            class="button-secondary"
                            (click)="openCancelMissionModal()"
                            [disabled]="vm.isMissionDataProcessing"
                        >
                            {{ "dtmWebAppLibTactical.cancelMissionButtonLabel" | transloco }}
                        </button>
                        <button
                            *ngIf="status === MissionStatus.Accepted && vm.isManageableByPilot"
                            type="button"
                            class="button-primary"
                            (click)="openActivationTimeChangeModal()"
                            [disabled]="
                                (vm.currentTime | invoke : tacticalMissionService.isActivationDisabled : mission) ||
                                vm.isMissionDataProcessing
                            "
                            [matTooltip]="
                                mission.earlyActivationTime
                                    ? ('dtmWebAppLibTactical.missionActivation.activationDisabledTooltipText'
                                      | transloco
                                          : {
                                                minutesBeforeActivationTime:
                                                    mission | invoke : tacticalMissionService.getMinutesBeforeActivationTime,
                                                remainingTimeText: vm.currentTime | datesDiff : mission.earlyActivationTime
                                            })
                                    : ''
                            "
                            [matTooltipDisabled]="!(vm.currentTime | invoke : tacticalMissionService.isActivationDisabled : mission)"
                        >
                            <dtm-ui-icon name="play-circle"></dtm-ui-icon>
                            {{ "dtmWebAppLibTactical.activateMissionButtonLabel" | transloco }}
                        </button>
                    </ng-container>
                </div>
            </div>
        </ng-container>
    </ng-container>
</ng-container>
<ng-template #errorTemplate>
    <dtm-ui-error class="error" [errorMessage]="'dtmWebAppLibMission.genericErrorMessage' | transloco" (reload)="reload()"></dtm-ui-error>
</ng-template>
