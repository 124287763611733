import { RobustnessLevel } from "@dtm-frontend/shared/mission";
import { Sail } from "../../../shared/models/operations-manual.models";
import { OsoStatementCriterionItemType, OsoStatementGroup } from "../../services/specific-permit-application.models";

/* eslint-disable no-magic-numbers, max-len */

export const OSO_STATEMENT_GROUPS_BY_SAIL: { [key in Sail]: OsoStatementGroup[] } = {
    [Sail.Sail1]: [
        {
            osoNumbers: [3],
            robustnessLevel: RobustnessLevel.Low,
            titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailI.oso3.title",
            descriptionKey: "dtmWebAppLibSpecPermApp.statementsStep.sailI.oso3.description",
            criteriaGroups: [
                {
                    titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailI.oso3.groupCriteria.title",
                    criteria: [
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailI.oso3.groupCriteria.criterion1ContentHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailI.oso3.groupCriteria.criterion2ContentHtml",
                            annotationHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailI.oso3.groupCriteria.criterion2AnnotationHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailI.oso3.groupCriteria.criterion3ContentHtml",
                            annotationHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailI.oso3.groupCriteria.criterion3AnnotationHtml",
                        },
                    ],
                },
                {
                    titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailI.oso3.groupProcedures.title",
                    criteria: [
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailI.oso3.groupProcedures.criterion1ContentHtml",
                            annotationHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailI.oso3.groupProcedures.criterion1AnnotationHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailI.oso3.groupProcedures.criterion2ContentHtml",
                            annotationHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailI.oso3.groupProcedures.criterion2AnnotationHtml",
                            comments: [
                                {
                                    id: "maintenance",
                                    contentHtmlKey:
                                        "dtmWebAppLibSpecPermApp.statementsStep.sailI.oso3.groupProcedures.criterion2CommentMaintenanceContentHtml",
                                },
                            ],
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailI.oso3.groupProcedures.criterion3ContentHtml",
                            annotationHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailI.oso3.groupProcedures.criterion3AnnotationHtml",
                        },
                    ],
                },
                {
                    titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailI.oso3.groupTrainings.title",
                    criteria: [
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailI.oso3.groupTrainings.criterion1ContentHtml",
                            annotationHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailI.oso3.groupTrainings.criterion1AnnotationHtml",
                        },
                    ],
                },
            ],
        },
        {
            osoNumbers: [7],
            robustnessLevel: RobustnessLevel.Low,
            titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailI.oso7.title",
            descriptionKey: "dtmWebAppLibSpecPermApp.statementsStep.sailI.oso7.description",
            criteriaGroups: [
                {
                    titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailI.oso7.groupCriteria.title",
                    criteria: [
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailI.oso7.groupCriteria.criterion1ContentHtml",
                            annotationHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailI.oso7.groupCriteria.criterion1AnnotationHtml",
                        },
                    ],
                },
                {
                    titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailI.oso7.groupProcedures.title",
                    criteria: [
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailI.oso7.groupProcedures.criterion1ContentHtml",
                            annotationHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailI.oso7.groupProcedures.criterion1AnnotationHtml",
                        },
                    ],
                },
                {
                    titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailI.oso7.groupTrainings.title",
                    criteria: [
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailI.oso7.groupTrainings.criterion1ContentHtml",
                            annotationHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailI.oso7.groupTrainings.criterion1AnnotationHtml",
                        },
                    ],
                },
            ],
        },
        {
            osoNumbers: [8, 11, 14, 21],
            robustnessLevel: RobustnessLevel.Low,
            titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailI.oso8_11_14_21.title",
            criteriaGroups: [
                {
                    titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailI.oso8_11_14_21.groupDefinition.title",
                    criteria: [
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailI.oso8_11_14_21.groupDefinition.criterion1ContentHtml",
                            comments: [
                                {
                                    id: "procedures",
                                    contentHtmlKey:
                                        "dtmWebAppLibSpecPermApp.statementsStep.sailI.oso8_11_14_21.groupDefinition.criterion1CommentProceduresContentHtml",
                                },
                            ],
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailI.oso8_11_14_21.groupDefinition.criterion2ContentHtml",
                            comments: [
                                {
                                    id: "uav",
                                    contentHtmlKey:
                                        "dtmWebAppLibSpecPermApp.statementsStep.sailI.oso8_11_14_21.groupDefinition.criterion2CommentUavContentHtml",
                                },
                            ],
                        },
                        {
                            type: OsoStatementCriterionItemType.Note,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailI.oso8_11_14_21.groupDefinition.noteHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Annotation,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailI.oso8_11_14_21.groupDefinition.annotationHtml",
                        },
                    ],
                },
                {
                    titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailI.oso8_11_14_21.groupProcedureComplexity.title",
                    criteria: [
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailI.oso8_11_14_21.groupProcedureComplexity.criterion1ContentHtml",
                            annotationHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailI.oso8_11_14_21.groupProcedureComplexity.criterion1AnnotationHtml",
                        },
                    ],
                },
                {
                    titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailI.oso8_11_14_21.groupHumanError.title",
                    criteria: [
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailI.oso8_11_14_21.groupHumanError.criterion1ContentHtml",
                        },
                    ],
                },
                {
                    titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailI.oso8_11_14_21.groupCriteria.title",
                    criteria: [
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailI.oso8_11_14_21.groupCriteria.criterion1ContentHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailI.oso8_11_14_21.groupCriteria.criterion2ContentHtml",
                            annotationHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailI.oso8_11_14_21.groupCriteria.criterion2AnnotationHtml",
                        },
                    ],
                },
            ],
        },
        {
            osoNumbers: [9, 15, 22],
            robustnessLevel: RobustnessLevel.Low,
            titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailI.oso9_15_22.title",
            criteriaGroups: [
                {
                    titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailI.oso9_15_22.groupCriteria.title",
                    criteria: [
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailI.oso9_15_22.groupCriteria.criterion1ContentHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailI.oso9_15_22.groupCriteria.criterion2ContentHtml",
                            annotationHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailI.oso9_15_22.groupCriteria.criterion2AnnotationHtml",
                        },
                    ],
                },
            ],
        },
        {
            osoNumbers: [10, 12],
            robustnessLevel: RobustnessLevel.Low,
            titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailI.oso10_12.title",
            criteriaGroups: [
                {
                    titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailI.oso10_12.groupCriteria.title",
                    criteria: [
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailI.oso10_12.groupCriteria.criterion1ContentHtml",
                            comments: [
                                {
                                    id: "crash",
                                    contentHtmlKey:
                                        "dtmWebAppLibSpecPermApp.statementsStep.sailI.oso10_12.groupCriteria.criterion1CommentCrashContentHtml",
                                },
                            ],
                            annotationHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailI.oso10_12.groupCriteria.criterion1AnnotationHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailI.oso10_12.groupCriteria.criterion2ContentHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Annotation,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailI.oso10_12.groupCriteria.annotationHtml",
                        },
                    ],
                },
            ],
        },
        {
            osoNumbers: [13],
            robustnessLevel: RobustnessLevel.Low,
            titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailI.oso13.title",
            descriptionKey: "dtmWebAppLibSpecPermApp.statementsStep.sailI.oso13.description",
            criteriaGroups: [
                {
                    titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailI.oso13.groupCriteria.title",
                    criteria: [
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailI.oso13.groupCriteria.criterion1ContentHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailI.oso13.groupCriteria.criterion2ContentHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Annotation,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailI.oso13.groupCriteria.annotationHtml",
                        },
                    ],
                },
            ],
        },
        {
            osoNumbers: [16],
            robustnessLevel: RobustnessLevel.Low,
            titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailI.oso16.title",
            descriptionKey: "dtmWebAppLibSpecPermApp.statementsStep.sailI.oso16.description",
            criteriaGroups: [
                {
                    titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailI.oso16.groupProcedures.title",
                    criteria: [
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailI.oso16.groupProcedures.criterion1ContentHtml",
                            annotationHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailI.oso16.groupProcedures.criterion1AnnotationHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailI.oso16.groupProcedures.criterion2ContentHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailI.oso16.groupProcedures.criterion3ContentHtml",
                        },
                    ],
                },
                {
                    titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailI.oso16.groupTrainings.title",
                    criteria: [
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailI.oso16.groupTrainings.criterion1ContentHtml",
                            annotationHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailI.oso16.groupTrainings.criterion1AnnotationHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailI.oso16.groupTrainings.criterion2ContentHtml",
                            annotationHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailI.oso16.groupTrainings.criterion2AnnotationHtml",
                        },
                    ],
                },
            ],
        },
        {
            osoNumbers: [17],
            robustnessLevel: RobustnessLevel.Low,
            titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailI.oso17.title",
            descriptionKey: "dtmWebAppLibSpecPermApp.statementsStep.sailI.oso17.description",
            criteriaGroups: [
                {
                    titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailI.oso17.groupCriteria.title",
                    criteria: [
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailI.oso17.groupCriteria.criterion1ContentHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailI.oso17.groupCriteria.criterion2ContentHtml",
                            annotationHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailI.oso17.groupCriteria.criterion2AnnotationHtml",
                        },
                    ],
                },
            ],
        },
        {
            osoNumbers: [23],
            robustnessLevel: RobustnessLevel.Low,
            titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailI.oso23.title",
            descriptionKey: "dtmWebAppLibSpecPermApp.statementsStep.sailI.oso23.description",
            criteriaGroups: [
                {
                    titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailI.oso23.groupDefinition.title",
                    criteria: [
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailI.oso23.groupDefinition.criterion1ContentHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailI.oso23.groupDefinition.criterion2ContentHtml",
                            annotationHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailI.oso23.groupDefinition.criterion2AnnotationHtml",
                        },
                    ],
                },
                {
                    titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailI.oso23.groupProcedures.title",
                    criteria: [
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailI.oso23.groupProcedures.criterion1ContentHtml",
                            annotationHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailI.oso23.groupProcedures.criterion1AnnotationHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailI.oso23.groupProcedures.criterion2ContentHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailI.oso23.groupProcedures.criterion3ContentHtml",
                        },
                    ],
                },
                {
                    titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailI.oso23.groupTrainings.title",
                    criteria: [
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailI.oso23.groupTrainings.criterion1ContentHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailI.oso23.groupTrainings.criterion2ContentHtml",
                            annotationHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailI.oso23.groupTrainings.criterion2AnnotationHtml",
                        },
                    ],
                },
            ],
        },
    ],
    [Sail.Sail2]: [
        {
            osoNumbers: [1],
            robustnessLevel: RobustnessLevel.Low,
            titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso1.title",
            descriptionKey: "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso1.description",
            criteriaGroups: [
                {
                    titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso1.groupCriteria.title",
                    criteria: [
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso1.groupCriteria.criterion1ContentHtml",
                            annotationHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso1.groupCriteria.criterion1AnnotationHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso1.groupCriteria.criterion2ContentHtml",
                        },
                    ],
                },
            ],
        },
        {
            osoNumbers: [3],
            robustnessLevel: RobustnessLevel.Low,
            titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso3.title",
            descriptionKey: "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso3.description",
            criteriaGroups: [
                {
                    titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso3.groupCriteria.title",
                    criteria: [
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso3.groupCriteria.criterion1ContentHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso3.groupCriteria.criterion2ContentHtml",
                            annotationHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso3.groupCriteria.criterion2AnnotationHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso3.groupCriteria.criterion3ContentHtml",
                            annotationHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso3.groupCriteria.criterion3AnnotationHtml",
                        },
                    ],
                },
                {
                    titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso3.groupProcedures.title",
                    criteria: [
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso3.groupProcedures.criterion1ContentHtml",
                            annotationHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso3.groupProcedures.criterion1AnnotationHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso3.groupProcedures.criterion2ContentHtml",
                            annotationHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso3.groupProcedures.criterion2AnnotationHtml",
                            comments: [
                                {
                                    id: "maintenance",
                                    contentHtmlKey:
                                        "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso3.groupProcedures.criterion2CommentMaintenanceContentHtml",
                                },
                            ],
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso3.groupProcedures.criterion3ContentHtml",
                            annotationHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso3.groupProcedures.criterion3AnnotationHtml",
                        },
                    ],
                },
                {
                    titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso3.groupTrainings.title",
                    criteria: [
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso3.groupTrainings.criterion1ContentHtml",
                            annotationHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso3.groupTrainings.criterion1AnnotationHtml",
                        },
                    ],
                },
            ],
        },
        {
            osoNumbers: [6],
            robustnessLevel: RobustnessLevel.Low,
            titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso6.title",
            descriptionKey: "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso6.description",
            criteriaGroups: [
                {
                    titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso6.groupCriteria.title",
                    criteria: [
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso6.groupCriteria.criterion1ContentHtml",
                            annotationHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso6.groupCriteria.criterion1AnnotationHtml",
                            comments: [
                                {
                                    id: "rf",
                                    contentHtmlKey:
                                        "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso6.groupCriteria.criterion1CommentRfContentHtml",
                                },
                            ],
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso6.groupCriteria.criterion2ContentHtml",
                            annotationHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso6.groupCriteria.criterion2AnnotationHtml",
                            comments: [
                                {
                                    id: "monitoring",
                                    contentHtmlKey:
                                        "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso6.groupCriteria.criterion2CommentMonitoringContentHtml",
                                },
                            ],
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso6.groupCriteria.criterion3ContentHtml",
                        },
                    ],
                },
            ],
        },
        {
            osoNumbers: [7],
            robustnessLevel: RobustnessLevel.Low,
            titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso7.title",
            descriptionKey: "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso7.description",
            criteriaGroups: [
                {
                    titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso7.groupCriteria.title",
                    criteria: [
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso7.groupCriteria.criterion1ContentHtml",
                            annotationHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso7.groupCriteria.criterion1AnnotationHtml",
                        },
                    ],
                },
                {
                    titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso7.groupProcedures.title",
                    criteria: [
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso7.groupProcedures.criterion1ContentHtml",
                            annotationHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso7.groupProcedures.criterion1AnnotationHtml",
                        },
                    ],
                },
                {
                    titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso7.groupTrainings.title",
                    criteria: [
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso7.groupTrainings.criterion1ContentHtml",
                            annotationHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso7.groupTrainings.criterion1AnnotationHtml",
                        },
                    ],
                },
            ],
        },
        {
            osoNumbers: [8, 11, 14, 21],
            robustnessLevel: RobustnessLevel.Medium,
            titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso8_11_14_21.title",
            criteriaGroups: [
                {
                    titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso8_11_14_21.groupDefinition.title",
                    criteria: [
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso8_11_14_21.groupDefinition.criterion1ContentHtml",
                            comments: [
                                {
                                    id: "procedures",
                                    contentHtmlKey:
                                        "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso8_11_14_21.groupDefinition.criterion1CommentProceduresContentHtml",
                                },
                            ],
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso8_11_14_21.groupDefinition.criterion2ContentHtml",
                            comments: [
                                {
                                    id: "uav",
                                    contentHtmlKey:
                                        "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso8_11_14_21.groupDefinition.criterion2CommentUavContentHtml",
                                },
                            ],
                        },
                        {
                            type: OsoStatementCriterionItemType.Note,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso8_11_14_21.groupDefinition.noteHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Annotation,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso8_11_14_21.groupDefinition.annotationHtml",
                        },
                    ],
                },
                {
                    titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso8_11_14_21.groupProcedureComplexity.title",
                    criteria: [
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso8_11_14_21.groupProcedureComplexity.criterion1ContentHtml",
                            annotationHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso8_11_14_21.groupProcedureComplexity.criterion1AnnotationHtml",
                            comments: [
                                {
                                    id: "security",
                                    contentHtmlKey:
                                        "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso8_11_14_21.groupProcedureComplexity.criterion1CommentSecurityContentHtml",
                                },
                            ],
                        },
                    ],
                },
                {
                    titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso8_11_14_21.groupHumanError.title",
                    criteria: [
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso8_11_14_21.groupHumanError.criterion1ContentHtml",
                        },
                    ],
                },
                {
                    titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso8_11_14_21.groupCriteria.title",
                    criteria: [
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso8_11_14_21.groupCriteria.criterion1ContentHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso8_11_14_21.groupCriteria.criterion2ContentHtml",
                            comments: [
                                {
                                    id: "amc2",
                                    contentHtmlKey:
                                        "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso8_11_14_21.groupCriteria.criterion2CommentAmc2ContentHtml",
                                },
                            ],
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso8_11_14_21.groupCriteria.criterion3ContentHtml",
                            annotationHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso8_11_14_21.groupCriteria.criterion3AnnotationHtml",
                        },
                    ],
                },
            ],
        },
        {
            osoNumbers: [9, 15, 22],
            robustnessLevel: RobustnessLevel.Low,
            titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso9_15_22.title",
            criteriaGroups: [
                {
                    titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso9_15_22.groupCriteria.title",
                    criteria: [
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso9_15_22.groupCriteria.criterion1ContentHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso9_15_22.groupCriteria.criterion2ContentHtml",
                            annotationHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso9_15_22.groupCriteria.criterion2AnnotationHtml",
                        },
                    ],
                },
            ],
        },
        {
            osoNumbers: [10, 12],
            robustnessLevel: RobustnessLevel.Low,
            titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso10_12.title",
            criteriaGroups: [
                {
                    titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso10_12.groupCriteria.title",
                    criteria: [
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso10_12.groupCriteria.criterion1ContentHtml",
                            comments: [
                                {
                                    id: "crash",
                                    contentHtmlKey:
                                        "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso10_12.groupCriteria.criterion1CommentCrashContentHtml",
                                },
                            ],
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso10_12.groupCriteria.criterion2ContentHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Annotation,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso10_12.groupCriteria.annotationHtml",
                        },
                    ],
                },
            ],
        },
        {
            osoNumbers: [13],
            robustnessLevel: RobustnessLevel.Low,
            titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso13.title",
            descriptionKey: "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso13.description",
            criteriaGroups: [
                {
                    titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso13.groupCriteria.title",
                    criteria: [
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso13.groupCriteria.criterion1ContentHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso13.groupCriteria.criterion2ContentHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Annotation,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso13.groupCriteria.annotationHtml",
                        },
                    ],
                },
            ],
        },
        {
            osoNumbers: [16],
            robustnessLevel: RobustnessLevel.Low,
            titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso16.title",
            descriptionKey: "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso16.description",
            criteriaGroups: [
                {
                    titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso16.groupProcedures.title",
                    criteria: [
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso16.groupProcedures.criterion1ContentHtml",
                            annotationHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso16.groupProcedures.criterion1AnnotationHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso16.groupProcedures.criterion2ContentHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso16.groupProcedures.criterion3ContentHtml",
                        },
                    ],
                },
                {
                    titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso16.groupTrainings.title",
                    criteria: [
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso16.groupTrainings.criterion1ContentHtml",
                            annotationHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso16.groupTrainings.criterion1AnnotationHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso16.groupTrainings.criterion2ContentHtml",
                            annotationHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso16.groupTrainings.criterion2AnnotationHtml",
                        },
                    ],
                },
            ],
        },
        {
            osoNumbers: [17],
            robustnessLevel: RobustnessLevel.Low,
            titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso17.title",
            descriptionKey: "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso17.description",
            criteriaGroups: [
                {
                    titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso17.groupCriteria.title",
                    criteria: [
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso17.groupCriteria.criterion1ContentHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso17.groupCriteria.criterion2ContentHtml",
                            annotationHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso17.groupCriteria.criterion2AnnotationHtml",
                        },
                    ],
                },
            ],
        },
        {
            osoNumbers: [20],
            robustnessLevel: RobustnessLevel.Low,
            titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso20.title",
            descriptionKey: "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso20.description",
            criteriaGroups: [
                {
                    titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso20.groupCriteria.title",
                    criteria: [
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso20.groupCriteria.criterion1ContentHtml",
                            comments: [
                                {
                                    id: "hmi",
                                    contentHtmlKey:
                                        "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso20.groupCriteria.criterion1CommentHmiContentHtml",
                                },
                            ],
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso20.groupCriteria.criterion2ContentHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Annotation,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso20.groupCriteria.annotationHtml",
                        },
                    ],
                },
            ],
        },
        {
            osoNumbers: [23],
            robustnessLevel: RobustnessLevel.Low,
            titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso23.title",
            descriptionKey: "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso23.description",
            criteriaGroups: [
                {
                    titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso23.groupDefinition.title",
                    criteria: [
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso23.groupDefinition.criterion1ContentHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso23.groupDefinition.criterion2ContentHtml",
                            annotationHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso23.groupDefinition.criterion2AnnotationHtml",
                        },
                    ],
                },
                {
                    titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso23.groupProcedures.title",
                    criteria: [
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso23.groupProcedures.criterion1ContentHtml",
                            annotationHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso23.groupProcedures.criterion1AnnotationHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso23.groupProcedures.criterion2ContentHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso23.groupProcedures.criterion3ContentHtml",
                        },
                    ],
                },
                {
                    titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso23.groupTrainings.title",
                    criteria: [
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso23.groupTrainings.criterion1ContentHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso23.groupTrainings.criterion2ContentHtml",
                            annotationHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailII.oso23.groupTrainings.criterion2AnnotationHtml",
                        },
                    ],
                },
            ],
        },
    ],
    [Sail.Sail3]: [
        {
            osoNumbers: [1],
            robustnessLevel: RobustnessLevel.Medium,
            titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso1.title",
            descriptionKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso1.description",
            criteriaGroups: [
                {
                    titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso1.groupCriteria.title",
                    criteria: [
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso1.groupCriteria.criterion1ContentHtml",
                            annotationHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso1.groupCriteria.criterion1AnnotationHtml",
                            comments: [
                                {
                                    id: "appropriate",
                                    contentHtmlKey:
                                        "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso1.groupCriteria.criterion1CommentAppropriateContentHtml",
                                },
                            ],
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso1.groupCriteria.criterion2ContentHtml",
                        },
                    ],
                },
            ],
        },
        {
            osoNumbers: [2],
            robustnessLevel: RobustnessLevel.Low,
            titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso2.title",
            descriptionKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso2.description",
            criteriaGroups: [
                {
                    titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso2.groupProjectCriteria.title",
                    criteria: [
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso2.groupProjectCriteria.criterion1ContentHtml",
                            annotationHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso2.groupProjectCriteria.criterion1AnnotationHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso2.groupProjectCriteria.criterion2ContentHtml",
                        },
                    ],
                },
                {
                    titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso2.groupProductionCriteria.title",
                    criteria: [
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso2.groupProductionCriteria.criterion1ContentHtml",
                            annotationHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso2.groupProductionCriteria.criterion1AnnotationHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso2.groupProductionCriteria.criterion2ContentHtml",
                        },
                    ],
                },
            ],
        },
        {
            osoNumbers: [3],
            robustnessLevel: RobustnessLevel.Medium,
            titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso3.title",
            descriptionKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso3.description",
            criteriaGroups: [
                {
                    titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso3.groupCriteria.title",
                    criteria: [
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso3.groupCriteria.criterion1ContentHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso3.groupCriteria.criterion2ContentHtml",
                            annotationHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso3.groupCriteria.criterion2AnnotationHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso3.groupCriteria.criterion3ContentHtml",
                            annotationHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso3.groupCriteria.criterion3AnnotationHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso3.groupCriteria.criterion4ContentHtml",
                            annotationHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso3.groupCriteria.criterion4AnnotationHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso3.groupCriteria.criterion5ContentHtml",
                            annotationHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso3.groupCriteria.criterion5AnnotationHtml",
                        },
                    ],
                },
                {
                    titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso3.groupProcedures.title",
                    criteria: [
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso3.groupProcedures.criterion1ContentHtml",
                            annotationHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso3.groupProcedures.criterion1AnnotationHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso3.groupProcedures.criterion2ContentHtml",
                            annotationHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso3.groupProcedures.criterion2AnnotationHtml",
                            comments: [
                                {
                                    id: "maintenance",
                                    contentHtmlKey:
                                        "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso3.groupProcedures.criterion2CommentMaintenanceContentHtml",
                                },
                            ],
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso3.groupProcedures.criterion3ContentHtml",
                            annotationHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso3.groupProcedures.criterion3AnnotationHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso3.groupProcedures.criterion4ContentHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso3.groupProcedures.criterion5ContentHtml",
                            annotationHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso3.groupProcedures.criterion5AnnotationHtml",
                        },
                    ],
                },
                {
                    titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso3.groupTrainings.title",
                    criteria: [
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso3.groupTrainings.criterion1ContentHtml",
                            annotationHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso3.groupTrainings.criterion1AnnotationHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso3.groupTrainings.criterion2ContentHtml",
                            annotationHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso3.groupTrainings.criterion2AnnotationHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso3.groupTrainings.criterion3ContentHtml",
                            annotationHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso3.groupTrainings.criterion3AnnotationHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso3.groupTrainings.criterion4ContentHtml",
                            annotationHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso3.groupTrainings.criterion4AnnotationHtml",
                        },
                    ],
                },
            ],
        },
        {
            osoNumbers: [5],
            robustnessLevel: RobustnessLevel.Low,
            titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso5.title",
            descriptionKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso5.description",
            criteriaGroups: [
                {
                    titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso5.groupCriteria.title",
                    criteria: [
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso5.groupCriteria.criterion1ContentHtml",
                            comments: [
                                {
                                    id: "crash",
                                    contentHtmlKey:
                                        "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso5.groupCriteria.criterion1CommentCrashContentHtml",
                                },
                            ],
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso5.groupCriteria.criterion2ContentHtml",
                            annotationHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso5.groupCriteria.criterion2AnnotationHtml",
                            comments: [
                                {
                                    id: "risk",
                                    contentHtmlKey:
                                        "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso5.groupCriteria.criterion2CommentRiskContentHtml",
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        {
            osoNumbers: [6],
            robustnessLevel: RobustnessLevel.Low,
            titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso6.title",
            descriptionKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso6.description",
            criteriaGroups: [
                {
                    titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso6.groupCriteria.title",
                    criteria: [
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso6.groupCriteria.criterion1ContentHtml",
                            annotationHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso6.groupCriteria.criterion1AnnotationHtml",
                            comments: [
                                {
                                    id: "rf",
                                    contentHtmlKey:
                                        "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso6.groupCriteria.criterion1CommentRfContentHtml",
                                },
                            ],
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso6.groupCriteria.criterion2ContentHtml",
                            annotationHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso6.groupCriteria.criterion2AnnotationHtml",
                            comments: [
                                {
                                    id: "monitoring",
                                    contentHtmlKey:
                                        "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso6.groupCriteria.criterion2CommentMonitoringContentHtml",
                                },
                            ],
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso6.groupCriteria.criterion3ContentHtml",
                        },
                    ],
                },
            ],
        },
        {
            osoNumbers: [7],
            robustnessLevel: RobustnessLevel.Medium,
            titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso7.title",
            descriptionKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso7.description",
            criteriaGroups: [
                {
                    titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso7.groupCriteria.title",
                    criteria: [
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso7.groupCriteria.criterion1ContentHtml",
                            annotationHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso7.groupCriteria.criterion1AnnotationHtml",
                        },
                    ],
                },
                {
                    titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso7.groupProcedures.title",
                    criteria: [
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso7.groupProcedures.criterion1ContentHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso7.groupProcedures.criterion2ContentHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Annotation,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso7.groupProcedures.annotationHtml",
                        },
                    ],
                },
                {
                    titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso7.groupTrainings.title",
                    criteria: [
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso7.groupTrainings.criterion1ContentHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso7.groupTrainings.criterion2ContentHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Annotation,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso7.groupTrainings.annotationHtml",
                        },
                    ],
                },
            ],
        },
        {
            osoNumbers: [8, 11, 14, 21],
            robustnessLevel: RobustnessLevel.High,
            titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso8_11_14_21.title",
            criteriaGroups: [
                {
                    titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso8_11_14_21.groupDefinition.title",
                    criteria: [
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso8_11_14_21.groupDefinition.criterion1ContentHtml",
                            comments: [
                                {
                                    id: "procedures",
                                    contentHtmlKey:
                                        "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso8_11_14_21.groupDefinition.criterion1CommentProceduresContentHtml",
                                },
                            ],
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso8_11_14_21.groupDefinition.criterion2ContentHtml",
                            comments: [
                                {
                                    id: "uav",
                                    contentHtmlKey:
                                        "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso8_11_14_21.groupDefinition.criterion2CommentUavContentHtml",
                                },
                            ],
                        },
                        {
                            type: OsoStatementCriterionItemType.Note,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso8_11_14_21.groupDefinition.noteHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Annotation,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso8_11_14_21.groupDefinition.annotationHtml",
                        },
                    ],
                },
                {
                    titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso8_11_14_21.groupProcedureComplexity.title",
                    criteria: [
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso8_11_14_21.groupProcedureComplexity.criterion1ContentHtml",
                        },
                    ],
                },
                {
                    titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso8_11_14_21.groupHumanError.title",
                    criteria: [
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso8_11_14_21.groupHumanError.criterion1ContentHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso8_11_14_21.groupHumanError.criterion2ContentHtml",
                            comments: [
                                {
                                    id: "training",
                                    contentHtmlKey:
                                        "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso8_11_14_21.groupHumanError.criterion2CommentTrainingContentHtml",
                                },
                            ],
                        },
                        {
                            type: OsoStatementCriterionItemType.Annotation,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso8_11_14_21.groupHumanError.annotationHtml",
                        },
                    ],
                },
                {
                    titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso8_11_14_21.groupCriteria.title",
                    criteria: [
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso8_11_14_21.groupCriteria.criterion1ContentHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso8_11_14_21.groupCriteria.criterion2ContentHtml",
                            comments: [
                                {
                                    id: "amc2",
                                    contentHtmlKey:
                                        "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso8_11_14_21.groupCriteria.criterion2CommentAmc2ContentHtml",
                                },
                            ],
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso8_11_14_21.groupCriteria.criterion3ContentHtml",
                            annotationHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso8_11_14_21.groupCriteria.criterion3AnnotationHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso8_11_14_21.groupCriteria.criterion4ContentHtml",
                            annotationHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso8_11_14_21.groupCriteria.criterion4AnnotationHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso8_11_14_21.groupCriteria.criterion5ContentHtml",
                            annotationHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso8_11_14_21.groupCriteria.criterion5AnnotationHtml",
                        },
                    ],
                },
            ],
        },
        {
            osoNumbers: [9, 15, 22],
            robustnessLevel: RobustnessLevel.Medium,
            titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso9_15_22.title",
            criteriaGroups: [
                {
                    titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso9_15_22.groupCriteria.title",
                    criteria: [
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso9_15_22.groupCriteria.criterion1ContentHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso9_15_22.groupCriteria.criterion2ContentHtml",
                            annotationHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso9_15_22.groupCriteria.criterion2AnnotationHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso9_15_22.groupCriteria.criterion3ContentHtml",
                            annotationHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso9_15_22.groupCriteria.criterion3AnnotationHtml",
                        },
                    ],
                },
            ],
        },
        {
            osoNumbers: [10, 12],
            robustnessLevel: RobustnessLevel.Medium,
            titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso10_12.title",
            criteriaGroups: [
                {
                    titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso10_12.groupCriteria.title",
                    criteria: [
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso10_12.groupCriteria.criterion1ContentHtml",
                            annotationHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso10_12.groupCriteria.criterion1AnnotationHtml",
                            comments: [
                                {
                                    id: "crash",
                                    contentHtmlKey:
                                        "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso10_12.groupCriteria.criterion1CommentCrashContentHtml",
                                },
                            ],
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso10_12.groupCriteria.criterion2ContentHtml",
                            annotationHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso10_12.groupCriteria.criterion2AnnotationHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso10_12.groupCriteria.criterion3ContentHtml",
                            annotationHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso10_12.groupCriteria.criterion3AnnotationHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso10_12.groupCriteria.criterion4ContentHtml",
                            annotationHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso10_12.groupCriteria.criterion4AnnotationHtml",
                        },
                    ],
                },
            ],
        },
        {
            osoNumbers: [13],
            robustnessLevel: RobustnessLevel.Medium,
            titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso13.title",
            descriptionKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso13.description",
            criteriaGroups: [
                {
                    titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso13.groupCriteria.title",
                    criteria: [
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso13.groupCriteria.criterion1ContentHtml",
                            annotationHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso13.groupCriteria.criterion1AnnotationHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso13.groupCriteria.criterion2ContentHtml",
                            annotationHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso13.groupCriteria.criterion2AnnotationHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso13.groupCriteria.criterion3ContentHtml",
                        },
                    ],
                },
            ],
        },
        {
            osoNumbers: [16],
            robustnessLevel: RobustnessLevel.Medium,
            titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso16.title",
            descriptionKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso16.description",
            criteriaGroups: [
                {
                    titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso16.groupProcedures.title",
                    criteria: [
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso16.groupProcedures.criterion1ContentHtml",
                            annotationHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso16.groupProcedures.criterion1AnnotationHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso16.groupProcedures.criterion2ContentHtml",
                            comments: [
                                {
                                    id: "amc2",
                                    contentHtmlKey:
                                        "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso16.groupProcedures.criterion2CommentAmc2ContentHtml",
                                },
                            ],
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso16.groupProcedures.criterion3ContentHtml",
                            annotationHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso16.groupProcedures.criterion3AnnotationHtml",
                        },
                    ],
                },
                {
                    titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso16.groupTrainings.title",
                    criteria: [
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso16.groupTrainings.criterion1ContentHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso16.groupTrainings.criterion2ContentHtml",
                            comments: [
                                {
                                    id: "training",
                                    contentHtmlKey:
                                        "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso16.groupTrainings.criterion2CommentTrainingContentHtml",
                                },
                            ],
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso16.groupTrainings.criterion3ContentHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso16.groupTrainings.criterion4ContentHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Annotation,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso16.groupTrainings.annotationHtml",
                        },
                    ],
                },
                {
                    titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso16.groupCommunicationDevices.title",
                    criteria: [
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso16.groupCommunicationDevices.criterion1ContentHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso16.groupCommunicationDevices.criterion2ContentHtml",
                            annotationHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso16.groupCommunicationDevices.criterion2AnnotationHtml",
                            comments: [
                                {
                                    id: "simulation",
                                    contentHtmlKey:
                                        "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso16.groupCommunicationDevices.criterion2CommentSimulationContentHtml",
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        {
            osoNumbers: [17],
            robustnessLevel: RobustnessLevel.Medium,
            titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso17.title",
            descriptionKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso17.description",
            criteriaGroups: [
                {
                    titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso17.groupCriteria.title",
                    criteria: [
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso17.groupCriteria.criterion1ContentHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso17.groupCriteria.criterion2ContentHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso17.groupCriteria.criterion3ContentHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso17.groupCriteria.criterion4ContentHtml",
                            annotationHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso17.groupCriteria.criterion4AnnotationHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso17.groupCriteria.criterion5ContentHtml",
                            annotationHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso17.groupCriteria.criterion5AnnotationHtml",
                        },
                    ],
                },
            ],
        },
        {
            osoNumbers: [18],
            robustnessLevel: RobustnessLevel.Low,
            titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso18.title",
            descriptionKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso18.description",
            criteriaGroups: [
                {
                    titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso18.groupCriteria.title",
                    criteria: [
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso18.groupCriteria.criterion1ContentHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso18.groupCriteria.criterion2ContentHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Annotation,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso18.groupCriteria.annotationHtml",
                        },
                    ],
                },
            ],
        },
        {
            osoNumbers: [19],
            robustnessLevel: RobustnessLevel.Low,
            titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso19.title",
            descriptionKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso19.description",
            criteriaGroups: [
                {
                    titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso19.groupProcedures.title",
                    criteria: [
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso19.groupProcedures.criterion1ContentHtml",
                            annotationHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso19.groupProcedures.criterion1AnnotationHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso19.groupProcedures.criterion2ContentHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso19.groupProcedures.criterion3ContentHtml",
                        },
                    ],
                },
                {
                    titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso19.groupTrainings.title",
                    criteria: [
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso19.groupTrainings.criterion1ContentHtml",
                            annotationHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso19.groupTrainings.criterion1AnnotationHtml",
                            comments: [
                                {
                                    id: "training",
                                    contentHtmlKey:
                                        "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso19.groupTrainings.criterion1CommentTrainingContentHtml",
                                },
                            ],
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso19.groupTrainings.criterion2ContentHtml",
                        },
                    ],
                },
                {
                    titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso19.groupUavProject.title",
                    criteria: [
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso19.groupUavProject.criterion1ContentHtml",
                            annotationHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso19.groupUavProject.criterion1AnnotationHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso19.groupUavProject.criterion2ContentHtml",
                            comments: [
                                {
                                    id: "robustness",
                                    contentHtmlKey:
                                        "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso19.groupUavProject.criterion2CommentRobustnessContentHtml",
                                },
                            ],
                            annotationHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso19.groupUavProject.criterion2AnnotationHtml",
                        },
                    ],
                },
            ],
        },
        {
            osoNumbers: [20],
            robustnessLevel: RobustnessLevel.Low,
            titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso20.title",
            descriptionKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso20.description",
            criteriaGroups: [
                {
                    titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso20.groupCriteria.title",
                    criteria: [
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso20.groupCriteria.criterion1ContentHtml",
                            comments: [
                                {
                                    id: "hmi",
                                    contentHtmlKey:
                                        "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso20.groupCriteria.criterion1CommentHmiContentHtml",
                                },
                            ],
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso20.groupCriteria.criterion2ContentHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Annotation,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso20.groupCriteria.annotationHtml",
                        },
                    ],
                },
            ],
        },
        {
            osoNumbers: [23],
            robustnessLevel: RobustnessLevel.Medium,
            titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso23.title",
            descriptionKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso23.description",
            criteriaGroups: [
                {
                    titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso23.groupDefinition.title",
                    criteria: [
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso23.groupDefinition.criterion1ContentHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso23.groupDefinition.criterion2ContentHtml",
                            annotationHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso23.groupDefinition.criterion2AnnotationHtml",
                            comments: [
                                {
                                    id: "simulation",
                                    contentHtmlKey:
                                        "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso23.groupDefinition.criterion2CommentSimulationContentHtml",
                                },
                            ],
                        },
                    ],
                },
                {
                    titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso23.groupProcedures.title",
                    criteria: [
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso23.groupProcedures.criterion1ContentHtml",
                            annotationHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso23.groupProcedures.criterion1AnnotationHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso23.groupProcedures.criterion2ContentHtml",
                            comments: [
                                {
                                    id: "amc2",
                                    contentHtmlKey:
                                        "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso23.groupProcedures.criterion2CommentAmc2ContentHtml",
                                },
                            ],
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso23.groupProcedures.criterion3ContentHtml",
                            annotationHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso23.groupProcedures.criterion3AnnotationHtml",
                        },
                    ],
                },
                {
                    titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso23.groupTrainings.title",
                    criteria: [
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso23.groupTrainings.criterion1ContentHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso23.groupTrainings.criterion2ContentHtml",
                            annotationHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso23.groupTrainings.criterion2AnnotationHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso23.groupTrainings.criterion3ContentHtml",
                            annotationHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso23.groupTrainings.criterion3AnnotationHtml",
                        },
                    ],
                },
            ],
        },
        {
            osoNumbers: [24],
            robustnessLevel: RobustnessLevel.Medium,
            titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso24.title",
            descriptionKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso24.description",
            criteriaGroups: [
                {
                    titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso24.groupCriteria.title",
                    criteria: [
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso24.groupCriteria.criterion1ContentHtml",
                            annotationHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso24.groupCriteria.criterion1AnnotationHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso24.groupCriteria.criterion2ContentHtml",
                            annotationHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso24.groupCriteria.criterion2AnnotationHtml",
                            comments: [
                                {
                                    id: "simulation",
                                    contentHtmlKey:
                                        "dtmWebAppLibSpecPermApp.statementsStep.sailIII.oso24.groupCriteria.criterion2CommentSimulationContentHtml",
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    ],
    [Sail.Sail4]: [
        {
            osoNumbers: [1],
            robustnessLevel: RobustnessLevel.High,
            titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso1.title",
            descriptionKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso1.description",
            criteriaGroups: [
                {
                    titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso1.groupCriteria.title",
                    criteria: [
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso1.groupCriteria.criterion1ContentHtml",
                            annotationHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso1.groupCriteria.criterion1AnnotationHtml",
                            comments: [
                                {
                                    id: "appropriate",
                                    contentHtmlKey:
                                        "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso1.groupCriteria.criterion1CommentAppropriateContentHtml",
                                },
                            ],
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso1.groupCriteria.criterion2ContentHtml",
                            annotationHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso1.groupCriteria.criterion2AnnotationHtml",
                        },
                    ],
                },
            ],
        },
        {
            osoNumbers: [2],
            robustnessLevel: RobustnessLevel.Medium,
            titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso2.title",
            descriptionKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso2.description",
            criteriaGroups: [
                {
                    titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso2.groupProjectCriteria.title",
                    criteria: [
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso2.groupProjectCriteria.criterion1ContentHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso2.groupProjectCriteria.criterion2ContentHtml",
                            annotationHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso2.groupProjectCriteria.criterion2AnnotationHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Annotation,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso2.groupProjectCriteria.annotationHtml",
                        },
                    ],
                },
                {
                    titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso2.groupProductionCriteria.title",
                    criteria: [
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso2.groupProductionCriteria.criterion1ContentHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso2.groupProductionCriteria.criterion2ContentHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Annotation,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso2.groupProductionCriteria.annotationHtml",
                        },
                    ],
                },
            ],
        },
        {
            osoNumbers: [3],
            robustnessLevel: RobustnessLevel.Medium,
            titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso3.title",
            descriptionKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso3.description",
            criteriaGroups: [
                {
                    titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso3.groupCriteria.title",
                    criteria: [
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso3.groupCriteria.criterion1ContentHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso3.groupCriteria.criterion2ContentHtml",
                            annotationHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso3.groupCriteria.criterion2AnnotationHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso3.groupCriteria.criterion3ContentHtml",
                            annotationHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso3.groupCriteria.criterion3AnnotationHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso3.groupCriteria.criterion4ContentHtml",
                            annotationHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso3.groupCriteria.criterion4AnnotationHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso3.groupCriteria.criterion5ContentHtml",
                            annotationHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso3.groupCriteria.criterion5AnnotationHtml",
                        },
                    ],
                },
                {
                    titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso3.groupProcedures.title",
                    criteria: [
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso3.groupProcedures.criterion1ContentHtml",
                            annotationHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso3.groupProcedures.criterion1AnnotationHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso3.groupProcedures.criterion2ContentHtml",
                            annotationHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso3.groupProcedures.criterion2AnnotationHtml",
                            comments: [
                                {
                                    id: "maintenance",
                                    contentHtmlKey:
                                        "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso3.groupProcedures.criterion2CommentMaintenanceContentHtml",
                                },
                            ],
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso3.groupProcedures.criterion3ContentHtml",
                            annotationHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso3.groupProcedures.criterion3AnnotationHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso3.groupProcedures.criterion4ContentHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso3.groupProcedures.criterion5ContentHtml",
                            annotationHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso3.groupProcedures.criterion5AnnotationHtml",
                        },
                    ],
                },
                {
                    titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso3.groupTrainings.title",
                    criteria: [
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso3.groupTrainings.criterion1ContentHtml",
                            annotationHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso3.groupTrainings.criterion1AnnotationHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso3.groupTrainings.criterion2ContentHtml",
                            annotationHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso3.groupTrainings.criterion2AnnotationHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso3.groupTrainings.criterion3ContentHtml",
                            annotationHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso3.groupTrainings.criterion3AnnotationHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso3.groupTrainings.criterion4ContentHtml",
                            annotationHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso3.groupTrainings.criterion4AnnotationHtml",
                        },
                    ],
                },
            ],
        },
        {
            osoNumbers: [4],
            robustnessLevel: RobustnessLevel.Low,
            titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso4.title",
            descriptionKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso4.description",
            criteriaGroups: [
                {
                    titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso4.groupCriteria.title",
                    criteria: [
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso4.groupCriteria.criterion1ContentHtml",
                            annotationHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso4.groupCriteria.criterion1AnnotationHtml",
                            comments: [
                                {
                                    id: "experiment",
                                    contentHtmlKey:
                                        "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso4.groupCriteria.criterion1CommentExperimentContentHtml",
                                },
                            ],
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso4.groupCriteria.criterion2ContentHtml",
                            annotationHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso4.groupCriteria.criterion2AnnotationHtml",
                        },
                    ],
                },
            ],
        },
        {
            osoNumbers: [5],
            robustnessLevel: RobustnessLevel.Medium,
            titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso5.title",
            descriptionKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso5.description",
            criteriaGroups: [
                {
                    titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso5.groupCriteria.title",
                    criteria: [
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso5.groupCriteria.criterion1ContentHtml",
                            annotationHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso5.groupCriteria.criterion1AnnotationHtml",
                            comments: [
                                {
                                    id: "crash",
                                    contentHtmlKey:
                                        "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso5.groupCriteria.criterion1CommentCrashContentHtml",
                                },
                            ],
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso5.groupCriteria.criterion2ContentHtml",
                            annotationHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso5.groupCriteria.criterion2AnnotationHtml",
                            comments: [
                                {
                                    id: "risk",
                                    contentHtmlKey:
                                        "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso5.groupCriteria.criterion2CommentRiskContentHtml",
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        {
            osoNumbers: [6],
            robustnessLevel: RobustnessLevel.Medium,
            titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso6.title",
            descriptionKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso6.description",
            criteriaGroups: [
                {
                    titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso6.groupCriteria.title",
                    criteria: [
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso6.groupCriteria.criterion1ContentHtml",
                            annotationHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso6.groupCriteria.criterion1AnnotationHtml",
                            comments: [
                                {
                                    id: "rf",
                                    contentHtmlKey:
                                        "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso6.groupCriteria.criterion1CommentRfContentHtml",
                                },
                            ],
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso6.groupCriteria.criterion2ContentHtml",
                            annotationHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso6.groupCriteria.criterion2AnnotationHtml",
                            comments: [
                                {
                                    id: "monitoring",
                                    contentHtmlKey:
                                        "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso6.groupCriteria.criterion2CommentMonitoringContentHtml",
                                },
                            ],
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso6.groupCriteria.criterion3ContentHtml",
                            annotationHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso6.groupCriteria.criterion3AnnotationHtml",
                        },
                    ],
                },
            ],
        },
        {
            osoNumbers: [7],
            robustnessLevel: RobustnessLevel.Medium,
            titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso7.title",
            descriptionKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso7.description",
            criteriaGroups: [
                {
                    titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso7.groupCriteria.title",
                    criteria: [
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso7.groupCriteria.criterion1ContentHtml",
                            annotationHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso7.groupCriteria.criterion1AnnotationHtml",
                        },
                    ],
                },
                {
                    titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso7.groupProcedures.title",
                    criteria: [
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso7.groupProcedures.criterion1ContentHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso7.groupProcedures.criterion2ContentHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Annotation,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso7.groupProcedures.annotationHtml",
                        },
                    ],
                },
                {
                    titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso7.groupTrainings.title",
                    criteria: [
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso7.groupTrainings.criterion1ContentHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso7.groupTrainings.criterion2ContentHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Annotation,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso7.groupTrainings.annotationHtml",
                        },
                    ],
                },
            ],
        },
        {
            osoNumbers: [8, 11, 14, 21],
            robustnessLevel: RobustnessLevel.High,
            titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso8_11_14_21.title",
            criteriaGroups: [
                {
                    titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso8_11_14_21.groupDefinition.title",
                    criteria: [
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso8_11_14_21.groupDefinition.criterion1ContentHtml",
                            comments: [
                                {
                                    id: "procedures",
                                    contentHtmlKey:
                                        "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso8_11_14_21.groupDefinition.criterion1CommentProceduresContentHtml",
                                },
                            ],
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso8_11_14_21.groupDefinition.criterion2ContentHtml",
                            comments: [
                                {
                                    id: "uav",
                                    contentHtmlKey:
                                        "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso8_11_14_21.groupDefinition.criterion2CommentUavContentHtml",
                                },
                            ],
                        },
                        {
                            type: OsoStatementCriterionItemType.Note,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso8_11_14_21.groupDefinition.noteHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Annotation,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso8_11_14_21.groupDefinition.annotationHtml",
                        },
                    ],
                },
                {
                    titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso8_11_14_21.groupProcedureComplexity.title",
                    criteria: [
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso8_11_14_21.groupProcedureComplexity.criterion1ContentHtml",
                        },
                    ],
                },
                {
                    titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso8_11_14_21.groupHumanError.title",
                    criteria: [
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso8_11_14_21.groupHumanError.criterion1ContentHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso8_11_14_21.groupHumanError.criterion2ContentHtml",
                            comments: [
                                {
                                    id: "training",
                                    contentHtmlKey:
                                        "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso8_11_14_21.groupHumanError.criterion2CommentTrainingContentHtml",
                                },
                            ],
                        },
                        {
                            type: OsoStatementCriterionItemType.Annotation,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso8_11_14_21.groupHumanError.annotationHtml",
                        },
                    ],
                },
                {
                    titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso8_11_14_21.groupCriteria.title",
                    criteria: [
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso8_11_14_21.groupCriteria.criterion1ContentHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso8_11_14_21.groupCriteria.criterion2ContentHtml",
                            comments: [
                                {
                                    id: "amc2",
                                    contentHtmlKey:
                                        "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso8_11_14_21.groupCriteria.criterion2CommentAmc2ContentHtml",
                                },
                            ],
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso8_11_14_21.groupCriteria.criterion3ContentHtml",
                            annotationHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso8_11_14_21.groupCriteria.criterion3AnnotationHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso8_11_14_21.groupCriteria.criterion4ContentHtml",
                            annotationHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso8_11_14_21.groupCriteria.criterion4AnnotationHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso8_11_14_21.groupCriteria.criterion5ContentHtml",
                            annotationHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso8_11_14_21.groupCriteria.criterion5AnnotationHtml",
                        },
                    ],
                },
            ],
        },
        {
            osoNumbers: [9, 15, 22],
            robustnessLevel: RobustnessLevel.Medium,
            titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso9_15_22.title",
            criteriaGroups: [
                {
                    titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso9_15_22.groupCriteria.title",
                    criteria: [
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso9_15_22.groupCriteria.criterion1ContentHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso9_15_22.groupCriteria.criterion2ContentHtml",
                            annotationHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso9_15_22.groupCriteria.criterion2AnnotationHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso9_15_22.groupCriteria.criterion3ContentHtml",
                            annotationHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso9_15_22.groupCriteria.criterion3AnnotationHtml",
                        },
                    ],
                },
            ],
        },
        {
            osoNumbers: [10, 12],
            robustnessLevel: RobustnessLevel.Medium,
            titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso10_12.title",
            criteriaGroups: [
                {
                    titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso10_12.groupCriteria.title",
                    criteria: [
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso10_12.groupCriteria.criterion1ContentHtml",
                            annotationHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso10_12.groupCriteria.criterion1AnnotationHtml",
                            comments: [
                                {
                                    id: "crash",
                                    contentHtmlKey:
                                        "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso10_12.groupCriteria.criterion1CommentCrashContentHtml",
                                },
                            ],
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso10_12.groupCriteria.criterion2ContentHtml",
                            annotationHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso10_12.groupCriteria.criterion2AnnotationHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso10_12.groupCriteria.criterion3ContentHtml",
                            annotationHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso10_12.groupCriteria.criterion3AnnotationHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso10_12.groupCriteria.criterion4ContentHtml",
                            annotationHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso10_12.groupCriteria.criterion4AnnotationHtml",
                        },
                    ],
                },
            ],
        },
        {
            osoNumbers: [13],
            robustnessLevel: RobustnessLevel.High,
            titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso13.title",
            descriptionKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso13.description",
            criteriaGroups: [
                {
                    titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso13.groupCriteria.title",
                    criteria: [
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso13.groupCriteria.criterion1ContentHtml",
                            annotationHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso13.groupCriteria.criterion1AnnotationHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso13.groupCriteria.criterion2ContentHtml",
                            annotationHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso13.groupCriteria.criterion2AnnotationHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso13.groupCriteria.criterion3ContentHtml",
                        },
                    ],
                },
            ],
        },
        {
            osoNumbers: [16],
            robustnessLevel: RobustnessLevel.Medium,
            titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso16.title",
            descriptionKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso16.description",
            criteriaGroups: [
                {
                    titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso16.groupProcedures.title",
                    criteria: [
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso16.groupProcedures.criterion1ContentHtml",
                            annotationHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso16.groupProcedures.criterion1AnnotationHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso16.groupProcedures.criterion2ContentHtml",
                            comments: [
                                {
                                    id: "amc2",
                                    contentHtmlKey:
                                        "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso16.groupProcedures.criterion2CommentAmc2ContentHtml",
                                },
                            ],
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso16.groupProcedures.criterion3ContentHtml",
                            annotationHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso16.groupProcedures.criterion3AnnotationHtml",
                        },
                    ],
                },
                {
                    titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso16.groupTrainings.title",
                    criteria: [
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso16.groupTrainings.criterion1ContentHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso16.groupTrainings.criterion2ContentHtml",
                            comments: [
                                {
                                    id: "training",
                                    contentHtmlKey:
                                        "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso16.groupTrainings.criterion2CommentTrainingContentHtml",
                                },
                            ],
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso16.groupTrainings.criterion3ContentHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso16.groupTrainings.criterion4ContentHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Annotation,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso16.groupTrainings.annotationHtml",
                        },
                    ],
                },
                {
                    titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso16.groupCommunicationDevices.title",
                    criteria: [
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso16.groupCommunicationDevices.criterion1ContentHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso16.groupCommunicationDevices.criterion2ContentHtml",
                            annotationHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso16.groupCommunicationDevices.criterion2AnnotationHtml",
                            comments: [
                                {
                                    id: "simulation",
                                    contentHtmlKey:
                                        "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso16.groupCommunicationDevices.criterion2CommentSimulationContentHtml",
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        {
            osoNumbers: [17],
            robustnessLevel: RobustnessLevel.Medium,
            titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso17.title",
            descriptionKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso17.description",
            criteriaGroups: [
                {
                    titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso17.groupCriteria.title",
                    criteria: [
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso17.groupCriteria.criterion1ContentHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso17.groupCriteria.criterion2ContentHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso17.groupCriteria.criterion3ContentHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso17.groupCriteria.criterion4ContentHtml",
                            annotationHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso17.groupCriteria.criterion4AnnotationHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso17.groupCriteria.criterion5ContentHtml",
                            annotationHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso17.groupCriteria.criterion5AnnotationHtml",
                        },
                    ],
                },
            ],
        },
        {
            osoNumbers: [18],
            robustnessLevel: RobustnessLevel.Medium,
            titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso18.title",
            descriptionKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso18.description",
            criteriaGroups: [
                {
                    titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso18.groupCriteria.title",
                    criteria: [
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso18.groupCriteria.criterion1ContentHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso18.groupCriteria.criterion2ContentHtml",
                            annotationHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso18.groupCriteria.criterion2AnnotationHtml",
                        },
                    ],
                },
            ],
        },
        {
            osoNumbers: [19],
            robustnessLevel: RobustnessLevel.Medium,
            titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso19.title",
            descriptionKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso19.description",
            criteriaGroups: [
                {
                    titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso19.groupProcedures.title",
                    criteria: [
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso19.groupProcedures.criterion1ContentHtml",
                            annotationHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso19.groupProcedures.criterion1AnnotationHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso19.groupProcedures.criterion2ContentHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso19.groupProcedures.criterion3ContentHtml",
                        },
                    ],
                },
                {
                    titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso19.groupTrainings.title",
                    criteria: [
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso19.groupTrainings.criterion1ContentHtml",
                            annotationHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso19.groupTrainings.criterion1AnnotationHtml",
                            comments: [
                                {
                                    id: "training",
                                    contentHtmlKey:
                                        "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso19.groupTrainings.criterion1CommentTrainingContentHtml",
                                },
                            ],
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso19.groupTrainings.criterion2ContentHtml",
                        },
                    ],
                },
                {
                    titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso19.groupUavProject.title",
                    criteria: [
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso19.groupUavProject.criterion1ContentHtml",
                            annotationHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso19.groupUavProject.criterion1AnnotationHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso19.groupUavProject.criterion2ContentHtml",
                            comments: [
                                {
                                    id: "simulation",
                                    contentHtmlKey:
                                        "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso19.groupUavProject.criterion2CommentSimulationContentHtml",
                                },
                            ],
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso19.groupUavProject.criterion3ContentHtml",
                        },
                    ],
                },
            ],
        },
        {
            osoNumbers: [20],
            robustnessLevel: RobustnessLevel.Medium,
            titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso20.title",
            descriptionKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso20.description",
            criteriaGroups: [
                {
                    titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso20.groupCriteria.title",
                    criteria: [
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso20.groupCriteria.criterion1ContentHtml",
                            comments: [
                                {
                                    id: "hmi",
                                    contentHtmlKey:
                                        "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso20.groupCriteria.criterion1CommentHmiContentHtml",
                                },
                            ],
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso20.groupCriteria.criterion2ContentHtml",
                            comments: [
                                {
                                    id: "simulation",
                                    contentHtmlKey:
                                        "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso20.groupCriteria.criterion2CommentSimulationContentHtml",
                                },
                            ],
                        },
                        {
                            type: OsoStatementCriterionItemType.Annotation,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso20.groupCriteria.annotationHtml",
                        },
                    ],
                },
            ],
        },
        {
            osoNumbers: [23],
            robustnessLevel: RobustnessLevel.Medium,
            titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso23.title",
            descriptionKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso23.description",
            criteriaGroups: [
                {
                    titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso23.groupDefinition.title",
                    criteria: [
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso23.groupDefinition.criterion1ContentHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso23.groupDefinition.criterion2ContentHtml",
                            annotationHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso23.groupDefinition.criterion2AnnotationHtml",
                            comments: [
                                {
                                    id: "simulation",
                                    contentHtmlKey:
                                        "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso23.groupDefinition.criterion2CommentSimulationContentHtml",
                                },
                            ],
                        },
                    ],
                },
                {
                    titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso23.groupProcedures.title",
                    criteria: [
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso23.groupProcedures.criterion1ContentHtml",
                            annotationHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso23.groupProcedures.criterion1AnnotationHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso23.groupProcedures.criterion2ContentHtml",
                            comments: [
                                {
                                    id: "amc2",
                                    contentHtmlKey:
                                        "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso23.groupProcedures.criterion2CommentAmc2ContentHtml",
                                },
                            ],
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso23.groupProcedures.criterion3ContentHtml",
                            annotationHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso23.groupProcedures.criterion3AnnotationHtml",
                        },
                    ],
                },
                {
                    titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso23.groupTrainings.title",
                    criteria: [
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso23.groupTrainings.criterion1ContentHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso23.groupTrainings.criterion2ContentHtml",
                            annotationHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso23.groupTrainings.criterion2AnnotationHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso23.groupTrainings.criterion3ContentHtml",
                            annotationHtmlKey:
                                "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso23.groupTrainings.criterion3AnnotationHtml",
                        },
                    ],
                },
            ],
        },
        {
            osoNumbers: [24],
            robustnessLevel: RobustnessLevel.High,
            titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso24.title",
            descriptionKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso24.description",
            criteriaGroups: [
                {
                    titleKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso24.groupCriteria.title",
                    criteria: [
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso24.groupCriteria.criterion1ContentHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Criterion,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso24.groupCriteria.criterion2ContentHtml",
                        },
                        {
                            type: OsoStatementCriterionItemType.Annotation,
                            contentHtmlKey: "dtmWebAppLibSpecPermApp.statementsStep.sailIV.oso24.groupCriteria.annotationHtml",
                        },
                    ],
                },
            ],
        },
    ],
    [Sail.Sail5]: [],
    [Sail.Sail6]: [],
};
