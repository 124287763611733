import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { map, withLatestFrom } from "rxjs/operators";
import { NavigationAccuracy, NavigationAccuracyClass, NavigationAccuracyType, Tracker, Tracking } from "../../../models/uav.models";

interface TrackingItem {
    name: string | undefined;
    identifier: string;
    weight: number;
    isEmbedded: boolean;
    flightNavigationAccuracy: NavigationAccuracyClass;
    takeoffAndLandingNavigationAccuracy: NavigationAccuracyClass;
}

export interface TrackingPropertiesComponentState {
    properties: Tracking | undefined;
    trackers: Tracker[];
    navigationAccuracyItems: NavigationAccuracy[];
}

@Component({
    selector: "dtm-uav-lib-tracking-properties",
    templateUrl: "./tracking-properties.component.html",
    styleUrls: ["./tracking-properties.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class TrackingPropertiesComponent {
    @Input({ required: true }) public set properties(value: Tracking | undefined) {
        this.localStore.patchState({ properties: value });
    }

    @Input({ required: true }) public set trackers(value: Tracker[] | undefined) {
        this.localStore.patchState({ trackers: value ?? [] });
    }

    @Input({ required: true }) public set navigationAccuracyItems(value: NavigationAccuracy[] | undefined) {
        this.localStore.patchState({ navigationAccuracyItems: value ?? [] });
    }

    protected readonly properties$ = this.localStore.selectByKey("properties").pipe(
        RxjsUtils.filterFalsy(),
        withLatestFrom(this.localStore.selectByKey("trackers")),
        map(([tracking, trackers]) => this.prepareProperties(tracking, trackers))
    );
    protected readonly navigationAccuracyItems$ = this.localStore.selectByKey("navigationAccuracyItems");
    protected readonly NavigationAccuracyType = NavigationAccuracyType;

    constructor(private readonly localStore: LocalComponentStore<TrackingPropertiesComponentState>) {
        this.localStore.setState({
            properties: undefined,
            trackers: [],
            navigationAccuracyItems: [],
        });
    }

    protected isEmpty(value: unknown) {
        return value === null || value === undefined;
    }

    private prepareProperties(tracking: Tracking, trackers: Tracker[]): TrackingItem {
        const tracker = trackers.find((trackerItem) => trackerItem.id === tracking.trackerId);

        return {
            name: tracker?.name,
            identifier: tracking.identifier,
            weight: tracking.weight,
            isEmbedded: tracking.isEmbedded,
            flightNavigationAccuracy: tracking.flightNavigationAccuracy,
            takeoffAndLandingNavigationAccuracy: tracking.takeoffAndLandingNavigationAccuracy,
        };
    }
}
