import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { AuthActions } from "@dtm-frontend/shared/auth";
import { EmailAddressFormValues, ErrorMode, GlobalFeatures, PhoneNumber, PhoneNumberFormValues } from "@dtm-frontend/shared/ui";
import {
    DEFAULT_PHONE_COUNTRY_CODE,
    LocalComponentStore,
    MIN_USER_AGE,
    PREVIOUS_MIN_USER_AGE,
    TERMS_OF_USE_URL,
} from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { ToastrService } from "ngx-toastr";
import { map } from "rxjs/operators";
import { OperatorContextState } from "../../../shared";
import { UserProfileError, UserProfileErrorType } from "../../models/user-profile.models";
import { UserProfileActions } from "../../state/user-profile.actions";
import { UserProfileState } from "../../state/user-profile.state";
import { getI18nUpdateProfileError } from "../../utils/update-error.translations";

interface NationalNodeUserProfileComponentState {
    currentEmail: string | undefined;
    currentPhoneNumber: PhoneNumber | undefined;
    isRequestedEmailChange: boolean;
    isRequestedPhoneNumberChange: boolean;
    tooManySmsCodeVerificationRequestDate: Date | undefined;
}

interface UserEditForm {
    emailAddress: FormControl<EmailAddressFormValues>;
    phoneNumber: FormControl<PhoneNumberFormValues>;
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-national-node-user-profile",
    templateUrl: "./national-node-user-profile.component.html",
    styleUrls: ["./national-node-user-profile.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class NationalNodeUserProfileComponent {
    protected readonly isRequestedEmailChange$ = this.localStore.selectByKey("isRequestedEmailChange");
    protected readonly tooManySmsCodeVerificationRequestDate$ = this.localStore.selectByKey("tooManySmsCodeVerificationRequestDate");
    protected readonly isRequestedPhoneNumberChange$ = this.localStore.selectByKey("isRequestedPhoneNumberChange");
    protected readonly currentEmail$ = this.localStore.selectByKey("currentEmail");
    protected readonly currentPhoneNumber$ = this.localStore.selectByKey("currentPhoneNumber");
    protected readonly userRegistration$ = this.store.select(UserProfileState.nationalNodeUserRegistration);
    protected readonly isProcessing$ = this.store.select(UserProfileState.isProcessing);
    protected readonly error$ = this.store.select(UserProfileState.nationalNodeUserRegistrationError);
    protected readonly hasPhoneNumberConflictError$ = this.store
        .select(UserProfileState.requestNationalNodeUserRegistrationPhoneNumberChangeError)
        .pipe(map((error) => error?.type === UserProfileErrorType.PhoneConflict));
    protected readonly hasEmailAddressConflictError$ = this.store
        .select(UserProfileState.requestNationalNodeUserRegistrationEmailChangeError)
        .pipe(map((error) => error?.type === UserProfileErrorType.EmailConflict));

    protected readonly emailAddressFormControl = new FormControl<EmailAddressFormValues>(
        {
            email: "",
            verificationCode: "",
        },
        { nonNullable: true }
    );
    protected readonly phoneNumberFormControl = new FormControl<PhoneNumberFormValues>(
        {
            phone: { number: "", countryCode: DEFAULT_PHONE_COUNTRY_CODE },
            verificationCode: "",
        },
        { nonNullable: true }
    );
    protected readonly termsFormControl = new FormControl<boolean>(false, { validators: Validators.requiredTrue, nonNullable: true });
    protected userEditForm = new FormGroup<UserEditForm>({
        emailAddress: this.emailAddressFormControl,
        phoneNumber: this.phoneNumberFormControl,
    });

    protected readonly ErrorMode = ErrorMode;
    protected readonly UserProfileErrorType = UserProfileErrorType;
    protected readonly MIN_USER_AGE = this.store.selectSnapshot(OperatorContextState.isFeatureAvailable(GlobalFeatures.AviationLav2025))
        ? MIN_USER_AGE
        : PREVIOUS_MIN_USER_AGE;

    constructor(
        @Inject(TERMS_OF_USE_URL) protected readonly termsOfUseUrl: string,
        private readonly store: Store,
        private readonly localStore: LocalComponentStore<NationalNodeUserProfileComponentState>,
        private readonly toastService: ToastrService,
        private readonly transloco: TranslocoService,
        private readonly router: Router
    ) {
        this.localStore.setState({
            isRequestedEmailChange: false,
            isRequestedPhoneNumberChange: false,
            currentPhoneNumber: undefined,
            currentEmail: undefined,
            tooManySmsCodeVerificationRequestDate: undefined,
        });
    }

    protected requestUserEmailChange() {
        const verificationCode = this.emailAddressFormControl.value.verificationCode;
        const email = this.emailAddressFormControl.value.email;
        const isRequestedEmailChange = this.localStore.selectSnapshotByKey("isRequestedEmailChange");

        if (isRequestedEmailChange && verificationCode) {
            this.store
                .dispatch(new UserProfileActions.ConfirmNationalNodeUserEmail(verificationCode))
                .pipe(untilDestroyed(this))
                .subscribe(() => {
                    const error = this.store.selectSnapshot(UserProfileState.confirmNationalNodeUserRegistrationEmailAddressError);
                    this.showErrorMessage(error);
                });
        } else if (!isRequestedEmailChange && email) {
            this.store
                .dispatch(new UserProfileActions.RequestNationalNodeUserEmailChange(email))
                .pipe(untilDestroyed(this))
                .subscribe(() => {
                    const error = this.store.selectSnapshot(UserProfileState.requestNationalNodeUserRegistrationEmailChangeError);
                    if (error?.type !== UserProfileErrorType.EmailConflict) {
                        this.showErrorMessage(error);
                    }
                    if (!error) {
                        this.localStore.patchState({ isRequestedEmailChange: true, currentEmail: email });
                    }
                });
        } else {
            this.emailAddressFormControl.markAllAsTouched();
        }
    }

    protected requestUserPhoneNumberChange() {
        const verificationCode = this.phoneNumberFormControl.value.verificationCode;
        const phone = this.phoneNumberFormControl.value.phone;
        const isRequestedPhoneNumberChange = this.localStore.selectSnapshotByKey("isRequestedPhoneNumberChange");
        if (isRequestedPhoneNumberChange && verificationCode) {
            this.store
                .dispatch(new UserProfileActions.ConfirmNationalNodeUserPhoneNumber(verificationCode))
                .pipe(untilDestroyed(this))
                .subscribe(() => {
                    const error = this.store.selectSnapshot(UserProfileState.confirmNationalNodeUserRegistrationPhoneNumberError);
                    this.showErrorMessage(error);
                });
        } else if (!isRequestedPhoneNumberChange && phone.number) {
            this.store
                .dispatch(new UserProfileActions.RequestNationalNodeUserPhoneNumberChange(phone))
                .pipe(untilDestroyed(this))
                .subscribe(() => {
                    const error = this.store.selectSnapshot(UserProfileState.requestNationalNodeUserRegistrationPhoneNumberChangeError);
                    if (error?.type !== UserProfileErrorType.EmailConflict) {
                        this.showErrorMessage(error);
                    }
                    if (!error) {
                        this.localStore.patchState({ isRequestedPhoneNumberChange: true, currentPhoneNumber: phone });
                    }
                });
        } else {
            this.phoneNumberFormControl.markAllAsTouched();
        }
    }

    protected cancelEmailVerification() {
        this.localStore.patchState({ isRequestedEmailChange: false, currentEmail: undefined });
    }

    protected cancelPhoneNumberVerification() {
        this.localStore.patchState({ isRequestedPhoneNumberChange: false, currentPhoneNumber: undefined });
    }

    protected logout() {
        this.store.dispatch(AuthActions.Logout);
    }

    protected resendEmailVerificationCode() {
        this.store
            .dispatch(new UserProfileActions.ResendNationalNodeUserRegistrationEmailVerificationCode())
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(UserProfileState.resendNationalNodeUserRegistrationEmailAddressError);
                this.showErrorMessage(error);
            });
    }

    protected resendPhoneNumberVerificationCode() {
        this.store
            .dispatch(new UserProfileActions.ResendNationalNodeUserRegistrationPhoneNumberVerificationCode())
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(UserProfileState.resendNationalNodeUserRegistrationPhoneNumberError);
                if (error?.type === UserProfileErrorType.TooManyRequests && error.date) {
                    this.localStore.patchState({ tooManySmsCodeVerificationRequestDate: error.date });

                    return;
                }

                this.localStore.patchState({ tooManySmsCodeVerificationRequestDate: undefined });
                this.showErrorMessage(error);
            });
    }

    private showErrorMessage(error: UserProfileError | undefined) {
        if (error) {
            this.toastService.error(this.transloco.translate(getI18nUpdateProfileError(error.type)));
        }
    }

    protected registerUser() {
        this.termsFormControl.markAsTouched();

        if (this.termsFormControl.invalid) {
            return;
        }

        this.store
            .dispatch(new UserProfileActions.RegisterNationalNodeUser(this.termsFormControl.value))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(UserProfileState.registerNationalNodeUserError);
                this.showErrorMessage(error);
                if (!error) {
                    this.router.navigateByUrl("dashboard");
                }
            });
    }
}
