import { ChangeDetectionStrategy, Component, Input, NgZone } from "@angular/core";
import { MissionPlanAnalysisIssue, MissionPlanAnalysisIssueBase, MissionPlanAnalysisIssueStatus } from "@dtm-frontend/shared/mission";
import { TranslationHelperService } from "@dtm-frontend/shared/ui/i18n";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { Toast, ToastPackage, ToastrService } from "ngx-toastr";

interface AnalysisAlertToastComponentState {
    issues: MissionPlanAnalysisIssueBase[] | undefined;
}

@Component({
    selector: "dtm-web-app-lib-analysis-alert-toast",
    templateUrl: "./analysis-alert-toast.component.html",
    styleUrls: ["./analysis-alert-toast.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class AnalysisAlertToastComponent extends Toast {
    @Input() public set issues(value: MissionPlanAnalysisIssueBase[] | undefined) {
        this.localStore.patchState({ issues: this.removeDuplicates(value) });
    }

    protected readonly issues$ = this.localStore.selectByKey("issues");

    constructor(
        private readonly localStore: LocalComponentStore<AnalysisAlertToastComponentState>,
        toastrService: ToastrService,
        toastPackage: ToastPackage,
        ngZone: NgZone,
        private readonly translationHelperService: TranslationHelperService
    ) {
        super(toastrService, toastPackage, ngZone);
        this.localStore.setState({
            issues: undefined,
        });
    }

    protected isError(issue: MissionPlanAnalysisIssueBase) {
        return [MissionPlanAnalysisIssueStatus.Error, MissionPlanAnalysisIssueStatus.FatalError].includes(
            (issue as MissionPlanAnalysisIssue).status
        );
    }

    private removeDuplicates(issues?: MissionPlanAnalysisIssueBase[]) {
        return issues?.filter((issue, index, self) => index === self.findIndex((item) => item.codename === issue.codename));
    }

    protected getTranslation(key: string, params?: Record<string, unknown>) {
        return this.translationHelperService.selectSystemTranslation(key, params);
    }
}
