<dtm-ui-loader-container [isShown]="data.isProcessing$ | ngrxPush">
    <div class="dialog-header">
        <div mat-dialog-title>{{ "dtmWebAppLibUav.editUav.editHeader" | transloco }}</div>
        <button type="button" class="button-icon" [matDialogClose]="false">
            <dtm-ui-icon name="close"></dtm-ui-icon>
        </button>
    </div>
    <div mat-dialog-content>
        <form [formGroup]="customUavForm" dtmUiInvalidFormScrollable>
            <dtm-ui-input-field>
                <label>
                    {{ "dtmWebAppLibUav.editUav.nameLabel" | transloco }}
                </label>
                <input matInput type="text" [formControl]="customUavForm.controls.name" required autocomplete="off" />
                <div class="field-error" *dtmUiFieldHasError="customUavForm.controls.name; name: 'maxlength'; error as error">
                    {{ "dtmWebAppLibUav.editUav.nameMaxLengthValueError" | transloco : { maxLength: error.requiredLength } }}
                </div>
                <div class="field-error" *dtmUiFieldHasError="customUavForm.controls.name; name: 'minlength'; error as error">
                    {{ "dtmWebAppLibUav.editUav.nameMinLengthValueError" | transloco : { minLength: error.requiredLength } }}
                </div>
                <div class="field-error" *dtmUiFieldHasError="customUavForm.controls.name; name: ['required', 'pattern']">
                    {{ "dtmWebAppLibUav.editUav.requiredFieldErrorHint" | transloco }}
                </div>
            </dtm-ui-input-field>
            <dtm-ui-serial-numbers-control
                *ngIf="data.currentIsSwarm"
                dtmUiMarkValueAccessorControlsAsTouched
                [formControl]="customUavForm.controls.serialNumbers"
                [isSingle]="false"
            ></dtm-ui-serial-numbers-control>
            <dtm-ui-radio-group [formControl]="isUavClassSetControl" (change)="manageIsUavClassSetValue($event)">
                <label class="label-with-popover">
                    {{ "dtmWebAppLibUav.editUav.isUavClassSetLabel" | transloco }}
                    <dtm-ui-popover>
                        <p [innerHtml]="'dtmWebAppLibUav.editUav.uavClassHelpInfo' | transloco"></p>
                    </dtm-ui-popover>
                </label>

                <div class="radio-buttons-container">
                    <dtm-ui-radio-field [value]="true">
                        {{ "dtmWebAppLibUav.editUav.isUavClassSetValueLabel" | transloco : { value: true } }}
                    </dtm-ui-radio-field>
                    <dtm-ui-radio-field [value]="false">
                        {{ "dtmWebAppLibUav.editUav.isUavClassSetValueLabel" | transloco : { value: false } }}
                    </dtm-ui-radio-field>
                </div>
            </dtm-ui-radio-group>
            <dtm-ui-select-field
                @slideIn
                *ngIf="isUavClassSetControl.value"
                [placeholder]="'dtmWebAppLibUav.editUav.uavClassPlaceholder' | transloco"
                [isClearable]="false"
                [formControl]="customUavForm.controls.uavClasses"
                [multiple]="true"
            >
                <label>
                    {{ "dtmWebAppLibUav.editUav.uavClassLabel" | transloco }}
                </label>
                <dtm-ui-select-option *ngFor="let uavClass of uavClasses" [value]="uavClass">
                    {{ "dtmWebAppLibUav.uavClassValue" | transloco : { value: uavClass } }}
                </dtm-ui-select-option>
                <div class="field-error" *dtmUiFieldHasError="customUavForm.controls.uavClasses; name: 'required'">
                    {{ "dtmWebAppLibUav.editUav.requiredFieldErrorHint" | transloco }}
                </div>
            </dtm-ui-select-field>
            <div class="switcher">
                <label>
                    {{ "dtmWebAppLibUav.editUav.isCeCompliantLabel" | transloco }}
                </label>
                <mat-slide-toggle [formControl]="customUavForm.controls.isCeCompliant"></mat-slide-toggle>
            </div>
            <dtm-web-app-lib-uav-image-control
                [formControl]="customUavForm.controls.image"
                (editMode)="setImageEditMode($event)"
            ></dtm-web-app-lib-uav-image-control>
        </form>
    </div>
    <div mat-dialog-actions align="end">
        <button type="button" class="button-secondary" [matDialogClose]="false">
            {{ "dtmWebAppLibUav.editUav.cancelButtonLabel" | transloco }}
        </button>
        <button type="button" class="button-primary" [disabled]="isSaveButtonDisabled$ | ngrxPush" (click)="save()">
            {{ "dtmWebAppLibUav.editUav.saveButtonLabel" | transloco }}
        </button>
    </div>
</dtm-ui-loader-container>
