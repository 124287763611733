import { EvaluationIssueStatus, MissionPlanAnalysisIssueBase } from "@dtm-frontend/shared/mission";

export enum MissionPlanVerificationType {
    Automatic = "Automatic",
    Manual = "Manual",
}

enum EvaluationIssueClosingEntity {
    CivilAviationAuthority = "CAA",
    Supervisor = "SUPERVISOR",
}

export interface MissionPlanVerificationResponseBodyEvaluationIssue extends MissionPlanAnalysisIssueBase {
    status: EvaluationIssueStatus;
    closedBy: EvaluationIssueClosingEntity;
    path: string;
}

export enum TacticalMitigationPerformanceRequirementProperty {
    VLOS = "VLOS",
    A = "A",
    B = "B",
    C = "C",
    D = "D",
}
