import { NgxSliderModule } from "@angular-slider/ngx-slider";
import { ClipboardModule } from "@angular/cdk/clipboard";
import { ObserversModule } from "@angular/cdk/observers";
import { PortalModule } from "@angular/cdk/portal";
import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatIconModule } from "@angular/material/icon";
import { MatLegacyButtonModule as MatButtonModule } from "@angular/material/legacy-button";
import { MatLegacyCardModule as MatCardModule } from "@angular/material/legacy-card";
import { MatLegacyCheckboxModule as MatCheckboxModule } from "@angular/material/legacy-checkbox";
import { MatLegacyChipsModule as MatChipsModule } from "@angular/material/legacy-chips";
import { MatLegacyDialogModule as MatDialogModule } from "@angular/material/legacy-dialog";
import { MatLegacyFormFieldModule as MatFormFieldModule } from "@angular/material/legacy-form-field";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { MatLegacyListModule as MatListModule } from "@angular/material/legacy-list";
import { MatLegacyMenuModule as MatMenuModule } from "@angular/material/legacy-menu";
import { MatLegacyPaginatorModule as MatPaginatorModule } from "@angular/material/legacy-paginator";
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from "@angular/material/legacy-progress-spinner";
import { MatLegacySelectModule as MatSelectModule } from "@angular/material/legacy-select";
import { MatLegacySlideToggleModule as MatSlideToggleModule } from "@angular/material/legacy-slide-toggle";
import { MatLegacyTableModule as MatTableModule } from "@angular/material/legacy-table";
import { MatLegacyTabsModule as MatTabsModule } from "@angular/material/legacy-tabs";
import { MatTooltipModule } from "@angular/material/tooltip";
import { RouterModule } from "@angular/router";
import { SharedAuthModule } from "@dtm-frontend/shared/auth";
import { GeolocationService, WebGeolocationService } from "@dtm-frontend/shared/map";
import { CesiumMapModule } from "@dtm-frontend/shared/map/cesium";
import { SharedMissionModule } from "@dtm-frontend/shared/mission";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { DmsCoordinatesModule } from "@dtm-frontend/shared/ui/dms-coordinates";
import { LanguageCode, SharedI18nModule, getTranslocoInlineLoader } from "@dtm-frontend/shared/ui/i18n";
import { SharedToastModule } from "@dtm-frontend/shared/ui/toast";
import { WizardModule } from "@dtm-frontend/shared/ui/wizard";
import { NGXS_CONCURRENCY_PLUGIN_OPTIONS, NgxsConcurrencyPlugin, NgxsConcurrencyPluginOptions } from "@dtm-frontend/shared/utils";
import { WebsocketService } from "@dtm-frontend/shared/websocket";
import { TRANSLOCO_SCOPE } from "@jsverse/transloco";
import { LetModule, PushModule } from "@ngrx/component";
import { NGXS_PLUGINS, NgxsModule } from "@ngxs/store";
import { ToastContainerModule } from "ngx-toastr";
import { SharedWebAppModule } from "../shared/shared-web-app.module";
import { AlertsWrapperComponent } from "./components/alerts-wrapper/alerts-wrapper.component";
import { MissionDetailsComponent } from "./components/mission-details/mission-details.component";
import { PersonalNotesComponent } from "./components/mission-notes-and-description/personal-notes.component";
import { MissionPlanListContentComponent } from "./components/mission-plan-list/content/mission-plan-list-content.component";
import { MissionPlanListFilterComponent } from "./components/mission-plan-list/filters/mission-plan-list-filter.component";
import { MissionTypeSelectComponent } from "./components/mission-plan-list/filters/mission-type-select/mission-type-select/mission-type-select.component";
import { MissionPlanListComponent } from "./components/mission-plan-list/list/mission-plan-list.component";
import { MissionPlanTileComponent } from "./components/mission-plan-list/tile/mission-plan-tile.component";
import { AnalysisAlertToastComponent } from "./components/mission-wizard/analysis-alert/analysis-alert-toast/analysis-alert-toast.component";
import { AnalysisAlertComponent } from "./components/mission-wizard/analysis-alert/analysis-alert.component";
import { SailAlertToastComponent } from "./components/mission-wizard/analysis-alert/sail-alert-toast/sail-alert-toast.component";
import { AssistedEditorMapFeaturesComponent } from "./components/mission-wizard/assisted-editor-map-features/assisted-editor-map-features.component";
import { CaaPermitLocationComponent } from "./components/mission-wizard/caa-permit-location/caa-permit-location.component";
import { ChildEntityHeightPointsLayerComponent } from "./components/mission-wizard/child-entity-height-points-layer/child-entity-height-points-layer.component";
import { CircularBoundaryViolationToastComponent } from "./components/mission-wizard/circular-boundary-violation/circular-boundary-violation-toast/circular-boundary-violation-toast.component";
import { CircularBoundaryViolationComponent } from "./components/mission-wizard/circular-boundary-violation/circular-boundary-violation.component";
import { MissionWizardContentComponent } from "./components/mission-wizard/content/mission-wizard-content.component";
import { FtsAlertContainerComponent } from "./components/mission-wizard/fts-alert/fts-alert-container.component";
import { FtsAlertToastComponent } from "./components/mission-wizard/fts-alert/fts-alert-toast/fts-alert-toast.component";
import { GrcAlertToastComponent } from "./components/mission-wizard/grc-alert/fts-alert-toast/grc-alert-toast.component";
import { GrcAlertContainerComponent } from "./components/mission-wizard/grc-alert/grc-alert-container.component";
import { MissionWizardHeaderComponent } from "./components/mission-wizard/header/mission-wizard-header.component";
import { HeightViolationAlertContainerComponent } from "./components/mission-wizard/height-violation-alert/height-violation-alert-container.component";
import { HeightViolationAlertDialogLinkComponent } from "./components/mission-wizard/height-violation-alert/height-violation-alert-dialog-link/height-violation-alert-dialog-link.component";
import { HeightViolationAlertDialogComponent } from "./components/mission-wizard/height-violation-alert/height-violation-alert-dialog/height-violation-alert-dialog.component";
import { HeightViolationAlertToastComponent } from "./components/mission-wizard/height-violation-alert/height-violation-alert-toast/height-violation-alert-toast.component";
import { RouteSelectorMapFeaturesComponent } from "./components/mission-wizard/route-selector-map-features/route-selector-map-features.component";
import { MissionWizardAnalysisStepComponent } from "./components/mission-wizard/steps/analysis-step/analysis-step.component";
import { MissionPlanAnalysisPanelComponent } from "./components/mission-wizard/steps/analysis-step/mission-plan-analysis-panel/mission-plan-analysis-panel.component";
import { AirRiskMitigationComponent } from "./components/mission-wizard/steps/analysis-step/mission-plan-analysis-panel/sora-settings/air-risk-mitigation/air-risk-mitigation.component";
import { RiskMitigationM1AComponent } from "./components/mission-wizard/steps/analysis-step/mission-plan-analysis-panel/sora-settings/risk-mitigation-m1-a/risk-mitigation-m1-a.component";
import { RiskMitigationM1BComponent } from "./components/mission-wizard/steps/analysis-step/mission-plan-analysis-panel/sora-settings/risk-mitigation-m1-b/risk-mitigation-m1-b.component";
import { RiskMitigationM2Component } from "./components/mission-wizard/steps/analysis-step/mission-plan-analysis-panel/sora-settings/risk-mitigation-m2/risk-mitigation-m2.component";
import { AnalysisSoraSettingsModalComponent } from "./components/mission-wizard/steps/analysis-step/mission-plan-analysis-panel/sora-settings/sora-settings-modal/analysis-sora-settings-modal.component";
import { TacticalRiskMitigationModalComponent } from "./components/mission-wizard/steps/analysis-step/mission-plan-analysis-panel/tactical-risk-mitigation/tactical-risk-mitigation-modal/tactical-risk-mitigation-modal.component";
import { ZoneAnalysisComponent } from "./components/mission-wizard/steps/analysis-step/mission-plan-analysis-panel/zone-analysis/zone-analysis.component";
import { AirRiskMitigationLevelsComponent } from "./components/mission-wizard/steps/common/air-risk-mitigation-levels/air-risk-mitigation-levels.component";
import { RiskMitigationLevelHeaderComponent } from "./components/mission-wizard/steps/common/risk-mitigation-level-header/risk-mitigation-level-header.component";
import { RiskMitigationLevelComponent } from "./components/mission-wizard/steps/common/risk-mitigation-level/risk-mitigation-level.component";
import { RiskMitigationLevelsComponent } from "./components/mission-wizard/steps/common/risk-mitigation-levels/risk-mitigation-levels.component";
import { MissionWizardItineraryEditorStepComponent } from "./components/mission-wizard/steps/itinerary-editor-step/itinerary-editor-step.component";
import { ItineraryPanelModule } from "./components/mission-wizard/steps/itinerary-editor-step/itinerary-panel/itinerary-panel.module";
import { MissionWizardItineraryEditorMapActionsHeaderComponent } from "./components/mission-wizard/steps/itinerary-editor-step/map-actions-header/map-actions-header.component";
import { MissionWizardItineraryEditorPreferencesPanelComponent } from "./components/mission-wizard/steps/itinerary-editor-step/mission-parameters/preferences-panel/preferences-panel.component";
import { MissionWizardItineraryEditorSettingsPanelComponent } from "./components/mission-wizard/steps/itinerary-editor-step/mission-parameters/settings-panel/settings-panel.component";
import { MissionWizardItineraryEditorZoneHeightPanelComponent } from "./components/mission-wizard/steps/itinerary-editor-step/mission-parameters/zone-height-panel/zone-height-panel.component";
import { MissionWizardItineraryEditorZoneRadiusPanelComponent } from "./components/mission-wizard/steps/itinerary-editor-step/mission-parameters/zone-radius-panel/zone-radius-panel.component";
import { AdditionalCrewMembersEditComponent } from "./components/mission-wizard/steps/mission-data-form-step/addictional-crew-members-edit/additional-crew-members-edit.component";
import { FlightPurposeFormControlComponent } from "./components/mission-wizard/steps/mission-data-form-step/flight-purpose-form-control/flight-purpose-form-control.component";
import { MissionWizardMissionDataFormStepComponent } from "./components/mission-wizard/steps/mission-data-form-step/mission-data-form-step.component";
import { MissionPlanningTypeFormControlComponent } from "./components/mission-wizard/steps/mission-data-form-step/mission-planning-type-form-control/mission-planning-type-form-control.component";
import { OperationCategoryFormControlComponent } from "./components/mission-wizard/steps/mission-data-form-step/operation-category-form-control/operation-category-form-control.component";
import { OperatorFormControlComponent } from "./components/mission-wizard/steps/mission-data-form-step/operator-form-control/operator-form-control.component";
import { RoutePanelComponent } from "./components/mission-wizard/steps/route-selector-step/route-panel/route-panel.component";
import { MissionWizardRouteSelectorStepComponent } from "./components/mission-wizard/steps/route-selector-step/route-selector-step.component";
import { MessageToManagerComponent } from "./components/mission-wizard/steps/summary-step/message-to-manager/message-to-manager.component";
import { MessageToPansaComponent } from "./components/mission-wizard/steps/summary-step/message-to-pansa/message-to-pansa.component";
import { MissionWizardSummaryStepComponent } from "./components/mission-wizard/steps/summary-step/summary-step.component";
import { MissionWizardStepWrapperComponent } from "./components/mission-wizard/utils/step-wrapper/step-wrapper.component";
import { MissionAPIService } from "./services/mission-api.service";
import { MissionPlanVerificationAPIService } from "./services/mission-plan-verification-api.service";
import { MissionActions } from "./state/mission.actions";
import { MissionState } from "./state/mission.state";

const ngxsConcurrencyPluginOptions: NgxsConcurrencyPluginOptions = {
    mutexActionTypes: [
        MissionActions.CreateOrUpdateMissionCapabilities,
        MissionActions.UpdateMissionPlanFlightPurpose,
        MissionActions.UpdateMissionPlanOperationCategory,
        MissionActions.UpdateMissionPlanItinerary,
        MissionActions.StartMissionPlanAnalysis,
        MissionActions.ClearItinerary,
        MissionActions.CreateAvailableMissionRoutes,
    ],
};

@NgModule({
    declarations: [
        MissionWizardContentComponent,
        MissionWizardMissionDataFormStepComponent,
        MissionWizardHeaderComponent,
        MissionWizardItineraryEditorStepComponent,
        MissionWizardAnalysisStepComponent,
        MissionPlanAnalysisPanelComponent,
        OperationCategoryFormControlComponent,
        MissionPlanListContentComponent,
        MissionPlanListComponent,
        MissionPlanTileComponent,
        MissionPlanListFilterComponent,
        MissionWizardStepWrapperComponent,
        OperatorFormControlComponent,
        MissionPlanningTypeFormControlComponent,
        FlightPurposeFormControlComponent,
        PersonalNotesComponent,
        MissionWizardSummaryStepComponent,
        AssistedEditorMapFeaturesComponent,
        MissionWizardRouteSelectorStepComponent,
        RouteSelectorMapFeaturesComponent,
        RoutePanelComponent,
        ChildEntityHeightPointsLayerComponent,
        RiskMitigationM1AComponent,
        RiskMitigationM1BComponent,
        RiskMitigationM2Component,
        MissionWizardItineraryEditorPreferencesPanelComponent,
        MissionWizardItineraryEditorSettingsPanelComponent,
        MissionWizardItineraryEditorZoneHeightPanelComponent,
        MissionWizardItineraryEditorZoneRadiusPanelComponent,
        MessageToManagerComponent,
        AdditionalCrewMembersEditComponent,
        AnalysisSoraSettingsModalComponent,
        ZoneAnalysisComponent,
        RiskMitigationLevelsComponent,
        RiskMitigationLevelComponent,
        RiskMitigationLevelHeaderComponent,
        AirRiskMitigationComponent,
        AirRiskMitigationLevelsComponent,
        MissionDetailsComponent,
        MissionWizardItineraryEditorMapActionsHeaderComponent,
        CircularBoundaryViolationToastComponent,
        TacticalRiskMitigationModalComponent,
        HeightViolationAlertContainerComponent,
        HeightViolationAlertToastComponent,
        HeightViolationAlertDialogComponent,
        HeightViolationAlertDialogLinkComponent,
        CircularBoundaryViolationComponent,
        MissionTypeSelectComponent,
        SailAlertToastComponent,
        FtsAlertContainerComponent,
        FtsAlertToastComponent,
        MessageToPansaComponent,
        GrcAlertContainerComponent,
        GrcAlertToastComponent,
        AnalysisAlertComponent,
        AnalysisAlertToastComponent,
        CaaPermitLocationComponent,
        AlertsWrapperComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        MatButtonModule,
        MatCardModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatDialogModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatNativeDateModule,
        MatProgressSpinnerModule,
        MatSelectModule,
        MatTooltipModule,
        NgxsModule.forFeature([MissionState]),
        NgxSliderModule,
        LetModule,
        PushModule,
        ReactiveFormsModule,
        RouterModule,
        SharedUiModule,
        SharedToastModule.forRoot(),
        SharedI18nModule,
        CesiumMapModule,
        WizardModule,
        MatListModule,
        MatGridListModule,
        MatPaginatorModule,
        SharedAuthModule,
        MatChipsModule,
        MatMenuModule,
        SharedWebAppModule,
        PortalModule,
        ToastContainerModule,
        DmsCoordinatesModule,
        ItineraryPanelModule,
        MatSlideToggleModule,
        MatTableModule,
        MatTabsModule,
        SharedMissionModule,
        ObserversModule,
        ClipboardModule,
    ],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            multi: true,
            useValue: {
                scope: "dtmWebAppLibMission",
                loader: getTranslocoInlineLoader((language: LanguageCode) => import(`./assets/i18n/${language}.json`)),
            },
        },
        {
            provide: MissionAPIService,
            useValue: undefined,
        },
        {
            provide: WebsocketService,
            useValue: undefined,
        },
        {
            provide: MissionPlanVerificationAPIService,
            useValue: undefined,
        },
        {
            provide: GeolocationService,
            useClass: WebGeolocationService,
        },
    ],
    exports: [
        MissionWizardMissionDataFormStepComponent,
        MissionWizardItineraryEditorStepComponent,
        MissionWizardAnalysisStepComponent,
        MissionPlanAnalysisPanelComponent,
        MissionPlanListComponent,
        MissionPlanTileComponent,
        MissionPlanListFilterComponent,
        MissionWizardStepWrapperComponent,
        MissionWizardRouteSelectorStepComponent,
        RiskMitigationM1AComponent,
        RiskMitigationM1BComponent,
        RiskMitigationM2Component,
        MissionWizardItineraryEditorPreferencesPanelComponent,
        MissionWizardItineraryEditorSettingsPanelComponent,
        MissionWizardItineraryEditorZoneHeightPanelComponent,
        MissionWizardItineraryEditorZoneRadiusPanelComponent,
        MissionWizardSummaryStepComponent,
        MessageToManagerComponent,
        AdditionalCrewMembersEditComponent,
        AnalysisSoraSettingsModalComponent,
        ZoneAnalysisComponent,
        RiskMitigationLevelsComponent,
        RiskMitigationLevelComponent,
        RiskMitigationLevelHeaderComponent,
        AirRiskMitigationComponent,
        AirRiskMitigationLevelsComponent,
        PersonalNotesComponent,
        MissionWizardItineraryEditorMapActionsHeaderComponent,
        CircularBoundaryViolationToastComponent,
        TacticalRiskMitigationModalComponent,
        HeightViolationAlertContainerComponent,
        HeightViolationAlertToastComponent,
        HeightViolationAlertDialogComponent,
        HeightViolationAlertDialogLinkComponent,
        MessageToPansaComponent,
        CaaPermitLocationComponent,
    ],
})
export class MissionModule {
    public static forRoot(): ModuleWithProviders<MissionModule> {
        return {
            ngModule: MissionModule,
            providers: [
                {
                    provide: MissionAPIService,
                    useClass: MissionAPIService,
                },
                {
                    provide: WebsocketService,
                    useClass: WebsocketService,
                },
                {
                    provide: MissionPlanVerificationAPIService,
                    useClass: MissionPlanVerificationAPIService,
                },
                {
                    provide: NGXS_PLUGINS,
                    useClass: NgxsConcurrencyPlugin,
                    multi: true,
                },
                {
                    provide: NGXS_CONCURRENCY_PLUGIN_OPTIONS,
                    useValue: ngxsConcurrencyPluginOptions,
                },
            ],
        };
    }

    public static forTest(): ModuleWithProviders<MissionModule> {
        return {
            ngModule: MissionModule,
            providers: [
                {
                    provide: MissionAPIService,
                    useValue: null,
                },
                {
                    provide: WebsocketService,
                    useValue: null,
                },
                {
                    provide: MissionPlanVerificationAPIService,
                    useValue: null,
                },
            ],
        };
    }
}
