<button type="button" class="button-icon" [matDialogClose]="undefined">
    <dtm-ui-icon name="close"></dtm-ui-icon>
</button>
<div class="content">
    <h4 mat-dialog-title>
        {{ "dtmWebAppLibUav.newUavWizardSteps.basicInfo.confirmUavDataDialog.titleText" | transloco }}
    </h4>
    <ul class="data-items">
        <li>
            {{ "dtmWebAppLibUav.newUavWizardSteps.basicInfo.confirmUavDataDialog.uavClassLabel" | transloco }}:
            <strong>
                <ng-container *ngIf="!data.uavClasses.length">{{
                    "dtmWebAppLibUav.newUavWizardSteps.basicInfo.confirmUavDataDialog.noUavClassLabel" | transloco
                }}</ng-container>
                <span class="uav-class" *ngFor="let uavClass of data.uavClasses">{{
                    "dtmWebAppLibUav.uavClassValue" | transloco : { value: uavClass }
                }}</span>
            </strong>
        </li>
    </ul>
    <p>
        {{ "dtmWebAppLibUav.newUavWizardSteps.basicInfo.confirmUavDataDialog.contentIntroLabel" | transloco }}
    </p>
    <ul class="data-items">
        <li>
            {{ "dtmWebAppLibUav.newUavWizardSteps.basicInfo.confirmUavDataDialog.manufacturerLabel" | transloco }}:
            <strong>{{ manufacturerName }}</strong>
        </li>
        <li>
            {{ "dtmWebAppLibUav.newUavWizardSteps.basicInfo.confirmUavDataDialog.modelLabel" | transloco }}:
            <strong>{{ modelName }}</strong>
        </li>
        <li *ngIf="type">
            {{ "dtmWebAppLibUav.newUavWizardSteps.basicInfo.confirmUavDataDialog.typeLabel" | transloco }}:
            <strong>{{ "dtmWebAppLibUav.uavTypeValue" | transloco : { value: type } }}</strong>
        </li>
        <li *ngIf="!data.isSwarm">
            {{ "dtmWebAppLibUav.newUavWizardSteps.basicInfo.confirmUavDataDialog.serialNumberLabel" | transloco }}:
            <strong>{{ data.serialNumbers[0] }}</strong>
        </li>
    </ul>
</div>

<mat-dialog-actions align="end">
    <button type="button" [mat-dialog-close]="false" class="button-secondary">
        {{ "dtmWebAppLibUav.newUavWizardSteps.basicInfo.confirmUavDataDialog.cancelButtonLabel" | transloco }}
    </button>
    <button type="button" class="button-primary" [mat-dialog-close]="true" cdkFocusInitial>
        {{ "dtmWebAppLibUav.newUavWizardSteps.basicInfo.confirmUavDataDialog.confirmButtonLabel" | transloco }}
    </button>
</mat-dialog-actions>
