<ng-container *ngrxLet="{ hasPermits: hasPermits$ } as vm">
    <div class="form-entry" *ngIf="!regulationExemptionToggleFormControl.value && (isSpecificPermitUsageEnabled$ | ngrxPush)">
        <mat-slide-toggle labelPosition="before" [formControl]="caaPermitToggleFormControl">
            {{ "dtmWebAppLibMission.dataFormStep.missionPlanningType.permit" | transloco }}

            <dtm-ui-icon
                *ngIf="vm.hasPermits"
                name="information-fill"
                class="hint-icon"
                [matTooltip]="'dtmWebAppLibMission.dataFormStep.missionPlanningType.enabledHint' | transloco"
            ></dtm-ui-icon>
            <dtm-ui-icon
                *ngIf="!vm.hasPermits"
                name="information-fill"
                class="hint-icon"
                [matTooltip]="'dtmWebAppLibMission.dataFormStep.missionPlanningType.disabledHint' | transloco"
            ></dtm-ui-icon>
        </mat-slide-toggle>
    </div>
    <div class="form-entry" *ngIf="!caaPermitToggleFormControl.value && (hasRegulationExemptions$ | ngrxPush)">
        <mat-slide-toggle labelPosition="before" [formControl]="regulationExemptionToggleFormControl">
            {{ "dtmWebAppLibMission.dataFormStep.missionPlanningType.regulationsExemptions" | transloco }}
        </mat-slide-toggle>
    </div>
</ng-container>
