import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { TACTICAL_MAX_HOURS_AFTER, TACTICAL_MAX_HOURS_BEFORE } from "@dtm-frontend/shared/map";
import { FlightPositionUpdaterService } from "@dtm-frontend/shared/map/cesium";
import { FlightTrackingActions, FlightTrackingErrorType, FlightTrackingState } from "@dtm-frontend/shared/map/flight-tracking";
import { WeatherActions } from "@dtm-frontend/shared/map/geo-weather";
import { MissionType } from "@dtm-frontend/shared/mission";
import {
    GlobalFeatures,
    ItineraryEditorType,
    MissionPlanRoute,
    OperatorType,
    RouteData,
    Trajectory,
    TrajectoryPosition,
    reloadComponent,
} from "@dtm-frontend/shared/ui";
import { TranslationHelperService } from "@dtm-frontend/shared/ui/i18n";
import {
    EmergencyType,
    FlightViolationEvent,
    FlightViolationUpdaterService,
    TacticalService,
    ViolationNotification,
} from "@dtm-frontend/shared/ui/tactical";
import { AnimationUtils, FunctionUtils, LocalComponentStore, ObjectUtils, RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Actions, Store, ofActionDispatched } from "@ngxs/store";
import buffer from "@turf/buffer";
import { AllGeoJSON, Polygon, Properties, feature, featureCollection } from "@turf/helpers";
import { ToastContainerDirective, ToastrService } from "ngx-toastr";
import { ActiveToast } from "ngx-toastr/toastr/toastr.service";
import { Observable, map } from "rxjs";
import { combineLatestWith, distinctUntilChanged, filter, startWith, switchMap, tap } from "rxjs/operators";
import { OperatorContextState } from "../../../shared/operator-context/state/operator-context.state";
import { MissionData, MissionStatus, TacticalError, TacticalErrorType, TrackerWarning, Violation } from "../../models/tactical.models";
import { TacticalMissionService } from "../../services/tactical-mission.service";
import { TacticalActions } from "../../state/tactical.actions";
import { TacticalState } from "../../state/tactical.state";

/* eslint-disable @typescript-eslint/no-explicit-any*/
declare const Cesium: any; // TODO: DTM-966

interface TacticalViewsComponentState {
    trackerWarning: TrackerWarning | undefined;
    trackerIdentifier: string | undefined;
    zoomTo: AllGeoJSON | undefined;
    trajectories: Map<string, Trajectory[]>;
    isDataPanelFolded: boolean;
    violation: Violation | undefined;
    isReadingConfirmationProcessing: boolean;
    hasReadingConfirmationError: boolean;
}

enum ZoomOnArea {
    Mission = "Mission",
    Start = "Start",
}

const VERIFICATION_ZOOM_BUFFER_IN_KILOMETERS = 1;
const IN_PROGRESS_ZOOM_BUFFER_IN_KILOMETERS = 3;

const VIOLATION_PRIORITY: Violation[] = [
    Violation.UavEnteredForeignSafetyArea,
    Violation.UavOutsideStartingFlightZone,
    Violation.UavLeftOwnSafetyArea,
    Violation.UavLeftOwnFlightArea,
];

const ENDED_MISSION_STATUES = [MissionStatus.Finished, MissionStatus.Canceled, MissionStatus.Rejected];

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-tactical-views",
    templateUrl: "./tactical-views.component.html",
    styleUrls: ["./tactical-views.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore, ToastrService],
    animations: [AnimationUtils.slideInAnimation(), AnimationUtils.foldAnimation()],
})
export class TacticalViewsComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild(ToastContainerDirective)
    private readonly toastContainer!: ToastContainerDirective;

    protected readonly isMissionDataProcessing$ = this.store.select(TacticalState.isMissionDataProcessing);
    protected readonly missionData$ = this.store.select(TacticalState.missionData);
    protected readonly missionPlanData$ = this.store.select(TacticalState.missionPlanData);
    protected readonly nearbyMissions$ = this.store
        .select(TacticalState.nearbyMissions)
        .pipe(
            map((list) => list?.filter((mission) => mission.missionId !== this.store.selectSnapshot(TacticalState.missionData)?.missionId))
        );

    private readonly sectionDeactivationEvents$ = this.store.select(TacticalState.deactivatedSectionsInfo).pipe(RxjsUtils.filterFalsy());

    protected readonly routeData$: Observable<RouteData<MissionData> | undefined>;
    protected readonly error$ = this.store.select(TacticalState.tacticalError).pipe(map((error) => this.handleMissionError(error)));
    protected readonly flights$ = this.flightPositionUpdater.flightPositionUpdate$;
    protected readonly trajectories$ = this.localStore.selectByKey("trajectories");

    protected readonly trackerWarning$ = this.localStore.selectByKey("trackerWarning");
    protected readonly zoomTo$ = this.localStore.selectByKey("zoomTo");
    protected readonly selectedNearbyMission$ = this.store.select(TacticalState.selectedNearbyMission);
    protected readonly isDataPanelFolded$ = this.localStore.selectByKey("isDataPanelFolded");
    protected readonly violation$ = this.localStore.selectByKey("violation");
    protected readonly isReadingConfirmationProcessing$ = this.localStore.selectByKey("isReadingConfirmationProcessing");
    protected readonly hemsEventData$ = this.store.select(TacticalState.hemsEventData);
    protected readonly activeEmergency$ = this.store.select(TacticalState.missionEmergency);
    protected readonly isEmergencyProcessing$ = this.store.select(TacticalState.isEmergencyProcessing);
    protected readonly checkinList$ = this.store.select(TacticalState.checkinList);
    protected readonly isManageableByPilot$ = this.store.select(OperatorContextState.selectedContext).pipe(
        combineLatestWith(this.missionData$, this.store.select(OperatorContextState.pilot)),
        map(([context, mission, pilot]) => context?.type === OperatorType.Personal && mission?.pilotId === pilot?.id)
    );

    protected isDetailsView$: Observable<boolean> | undefined;

    protected readonly TrackerWarning = TrackerWarning;
    protected readonly MissionType = MissionType;
    protected readonly MissionStatus = MissionStatus;
    protected readonly ItineraryEditorType = ItineraryEditorType;

    private violationToast: ActiveToast<unknown> | undefined;

    private readonly activeViolations = new Set<Violation>();
    private readonly tacticalService: TacticalService;

    protected ENDED_MISSION_STATUES = ENDED_MISSION_STATUES;

    constructor(
        private readonly store: Store,
        private readonly toastrService: ToastrService,
        private readonly transloco: TranslocoService,
        private readonly flightPositionUpdater: FlightPositionUpdaterService,
        private readonly localStore: LocalComponentStore<TacticalViewsComponentState>,
        private readonly flightViolationUpdaterService: FlightViolationUpdaterService,
        private readonly translationHelperService: TranslationHelperService,
        private readonly actions$: Actions,
        protected readonly tacticalMissionService: TacticalMissionService,
        protected readonly activatedRoute: ActivatedRoute,
        protected readonly router: Router
    ) {
        this.localStore.setState({
            trackerWarning: undefined,
            zoomTo: undefined,
            isDataPanelFolded: false,
            trajectories: new Map(),
            violation: undefined,
            isReadingConfirmationProcessing: false,
            hasReadingConfirmationError: false,
            trackerIdentifier: undefined,
        });

        this.store
            .select(TacticalState.missionData)
            .pipe(untilDestroyed(this))
            .subscribe((mission) =>
                this.localStore.patchState({
                    trajectories: mission?.trajectories?.length ? new Map([[mission.missionId, mission.trajectories]]) : new Map(),
                })
            );

        this.tacticalService = new TacticalService({
            caller: this,
            missionDataStream: this.missionData$,
            missionEmergencyStream: this.activeEmergency$,
            otherMissionsStream: this.store.select(TacticalState.nearbyMissions),
            refreshNearbyMissionListCallback: () => this.refreshNearbyMissionList(),
            sectionDeactivationEventsStream: this.sectionDeactivationEvents$,
            selectedNearbyMissionStream: this.selectedNearbyMission$,
        });

        this.routeData$ = this.tacticalService.initRouteData();

        this.routeData$
            .pipe(
                map((routeData) => routeData?.sectionStatuses ?? []),
                untilDestroyed(this)
            )
            .subscribe((statuses) => this.tacticalMissionService.publishSegmentStatuses(statuses));

        this.actions$
            .pipe(
                ofActionDispatched(TacticalActions.ZoomToGeometry),
                map((action) => action.geometry),
                untilDestroyed(this)
            )
            .subscribe((geometry) => this.localStore.patchState({ zoomTo: geometry }));
    }

    public ngOnInit(): void {
        this.updateTrackerWarningOnMissionAndTrackerUpdates();
        this.updateZoomOnMissionAndTrackerUpdates();
        this.updateTrajectoriesOnFlightUpdates();
        this.store.dispatch([
            TacticalActions.StartFlightViolationsUpdatesWatch,
            TacticalActions.StartSectionDeactivationEventWatch,
            TacticalActions.GetHemsData,
        ]);
        this.setupAndWatchIsDetailsViewChangesFromRouteData();
        this.watchForNotifications();
        this.watchFlightTrackingErrors();
        this.watchMissionDataChangesAndGetWeatherData();
    }

    private setupAndWatchIsDetailsViewChangesFromRouteData() {
        this.isDetailsView$ = this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd),
            startWith(undefined),
            map(() => this.activatedRoute.firstChild?.snapshot.data.isDetailsView)
        );
    }

    public ngOnDestroy(): void {
        this.store.dispatch([
            TacticalActions.StopFlightViolationsUpdatesWatch,
            TacticalActions.StopSectionDeactivationEventWatch,
            TacticalActions.CleanupTacticalState,
            FlightTrackingActions.CleanUpFlightTrackingState,
            WeatherActions.ResetWeatherState,
        ]);
        this.violationToast?.toastRef.close();
        this.tacticalMissionService.publishSegmentStatuses([]);
    }

    protected openDeactivateMissionModal(): void {
        this.tacticalMissionService
            .openDeactivateMissionModal()
            .pipe(untilDestroyed(this))
            .subscribe(() => this.deactivateMission());
    }

    protected openCancelMissionModal(): void {
        this.tacticalMissionService
            .openCancelMissionModal()
            .pipe(untilDestroyed(this))
            .subscribe(() => this.cancelMission());
    }

    protected openActivationTimeChangeModal(): void {
        const mission = this.store.selectSnapshot(TacticalState.missionData);
        if (!mission) {
            return;
        }

        this.tacticalMissionService
            .openMissionStartTimeModal(mission)
            .pipe(untilDestroyed(this))
            .subscribe((missionStartTime) => this.activateMission(missionStartTime));
    }

    protected activateMission(missionStartTime: Date): void {
        const missionId = this.store.selectSnapshot(TacticalState.missionData)?.missionId;
        if (!missionId) {
            return;
        }

        this.store
            .dispatch(new TacticalActions.ActivateMission(missionId, missionStartTime))
            .pipe(untilDestroyed(this))
            .subscribe(() => this.store.dispatch(new TacticalActions.GetMissionWithRoute(missionId)));
    }

    private cancelMission(): void {
        const missionId = this.store.selectSnapshot(TacticalState.missionData)?.missionId;
        if (!missionId) {
            return;
        }

        this.store
            .dispatch(new TacticalActions.CancelMission(missionId))
            .pipe(untilDestroyed(this))
            .subscribe(() => this.store.dispatch(new TacticalActions.GetMissionWithRoute(missionId)));
    }

    protected deactivateMission() {
        const missionId = this.store.selectSnapshot(TacticalState.missionData)?.missionId;
        if (!missionId) {
            return;
        }

        this.store
            .dispatch(new TacticalActions.DeactivateMission(missionId))
            .pipe(untilDestroyed(this))
            .subscribe(() => this.store.dispatch(new TacticalActions.GetMissionWithRoute(missionId)));
    }

    private refreshNearbyMissionList() {
        const currentMission = this.store.selectSnapshot(TacticalState.missionData);

        if (!currentMission) {
            return;
        }

        const settings = this.store.selectSnapshot(TacticalState.nearbyMissionsSettings);

        this.store.dispatch(
            settings?.areAllEnabled
                ? new TacticalActions.GetNearbyMissions({
                      missionId: currentMission.missionId,
                      areOnlyActive: !!settings?.areOnlyActive,
                  })
                : TacticalActions.ClearNearbyMissions
        );
    }

    private handleMissionError(error?: TacticalError): TacticalError | undefined {
        if (!error) {
            return undefined;
        }

        let message = this.getSystemErrorMessage(error);

        if (!message) {
            switch (error.type) {
                case TacticalErrorType.CannotCancelMission:
                    message = this.transloco.translate("dtmWebAppLibTactical.cannotCancelMissionErrorMessage");
                    break;
                case TacticalErrorType.CannotActivateMission:
                    message = this.transloco.translate("dtmWebAppLibTactical.missionActivation.activationError");
                    break;
                case TacticalErrorType.CannotDeactivateMission:
                    message = this.transloco.translate("dtmWebAppLibTactical.cannotDeactivateMissionErrorMessage");
                    break;
                case TacticalErrorType.NotFound:
                    message = this.transloco.translate("dtmWebAppLibTactical.missionNotFound");
                    break;
                case TacticalErrorType.CannotUpdateAdditionalInformation:
                    message = this.transloco.translate("dtmWebAppLibTactical.cannotUpdateAdditionalInformation");
                    break;
                case TacticalErrorType.CannotReportEmergency:
                    message = this.transloco.translate("dtmWebAppLibTactical.emergency.errors.cannotReportEmergencyText");
                    break;
                case TacticalErrorType.CannotCancelEmergency:
                    message = this.transloco.translate("dtmWebAppLibTactical.emergency.errors.cannotCancelEmergencyText");
                    break;
                default:
                    return error;
            }
        }

        this.toastrService.error(message);

        return undefined;
    }

    private getSystemErrorMessage(error: TacticalError): string {
        let message = "";

        if (error.code) {
            message = this.translationHelperService.selectSystemTranslation(error.code) + "\n";
        }

        error.fieldErrors?.forEach(({ code, args }) => {
            message += code ? this.translationHelperService.selectSystemTranslation(code, args) + "\n" : "";
        });

        return message;
    }

    protected async confirmReadings() {
        const missionData = this.store.selectSnapshot(TacticalState.missionData);
        const trackerIdentifier = this.localStore.selectSnapshotByKey("trackerIdentifier");

        if (!missionData || !trackerIdentifier) {
            return;
        }

        this.localStore.patchState({ isReadingConfirmationProcessing: true });

        this.store.dispatch(new TacticalActions.ConfirmTrackerReadings(missionData.missionId, trackerIdentifier)).subscribe(() => {
            this.localStore.patchState({ isReadingConfirmationProcessing: false });
            const error = this.store.selectSnapshot(TacticalState.tacticalError);
            if (error && error.type === TacticalErrorType.CannotConfirmReadings) {
                this.localStore.patchState({ hasReadingConfirmationError: true });

                return;
            }
            this.localStore.patchState({ trackerWarning: undefined });
            this.zoomOn(ZoomOnArea.Start, IN_PROGRESS_ZOOM_BUFFER_IN_KILOMETERS);
        });
    }

    public rejectReadings() {
        this.localStore.patchState({ trackerWarning: TrackerWarning.BadReadings });
    }

    public confirmLanding() {
        this.localStore.patchState({ trackerWarning: TrackerWarning.Verification });
    }

    private updateTrackerWarningOnMissionAndTrackerUpdates() {
        this.missionData$
            .pipe(
                tap((missionData) => {
                    const hasTracker = !!missionData?.uav?.trackersIdentifiers.length;

                    if (!hasTracker) {
                        this.localStore.patchState({ trackerWarning: undefined });

                        return;
                    }

                    const hasConfirmedTracker = !!missionData?.trackers?.find(({ isConfirmed }) => isConfirmed);
                    this.localStore.patchState({
                        trackerWarning:
                            !hasConfirmedTracker && missionData?.status === MissionStatus.Started ? TrackerWarning.Verification : undefined,
                    });
                }),
                RxjsUtils.filterFalsy(),
                switchMap((missionData) => this.flights$.pipe(map((flightsData) => ({ missionData, flightsData })))),
                filter(
                    ({ missionData, flightsData }) =>
                        !!missionData.uav.trackersIdentifiers.find(
                            (trackerIdentifier) => trackerIdentifier === flightsData.trackerIdentifier
                        )
                ),
                map(({ flightsData: { trackerIdentifier } }) => {
                    const trackerStatus = this.localStore.selectSnapshotByKey("trackerWarning");

                    if (trackerStatus === TrackerWarning.Verification) {
                        this.localStore.patchState({ trackerWarning: TrackerWarning.Confirmation, trackerIdentifier });
                    }
                }),
                untilDestroyed(this)
            )
            .subscribe();
    }

    private updateZoomOnMissionAndTrackerUpdates() {
        this.missionData$
            .pipe(
                distinctUntilChanged((left, right) => left?.missionId === right?.missionId),
                filter((missionData) => !!missionData && !this.localStore.selectSnapshotByKey("trackerWarning")),
                untilDestroyed(this)
            )
            .subscribe(() => this.zoomOn());

        this.trackerWarning$
            .pipe(filter(Boolean), untilDestroyed(this))
            .subscribe(() => this.zoomOn(ZoomOnArea.Start, VERIFICATION_ZOOM_BUFFER_IN_KILOMETERS));
    }

    protected zoomOn(zoomOn: ZoomOnArea = ZoomOnArea.Mission, bufferInKilometers = 0, mission?: MissionData) {
        const missionData = mission ?? this.store.selectSnapshot(TacticalState.missionData);

        if (!missionData) {
            return;
        }

        let zoomArea;

        if (zoomOn === ZoomOnArea.Mission) {
            zoomArea = featureCollection<Polygon, Properties>(
                missionData.route.sections
                    .map((section) => section.flightZone?.safetyArea.volume.area ?? section.segment?.safetyArea.volume.area)
                    .filter(FunctionUtils.isTruthy)
                    .map((polygon) => feature(polygon))
            );
        }

        if (zoomOn === ZoomOnArea.Start) {
            const area =
                missionData.route.sections[0].flightZone?.safetyArea.volume.area ??
                missionData.route.sections[0].segment?.safetyArea.volume.area;

            if (area) {
                zoomArea = buffer(area, bufferInKilometers, { units: "kilometers" });
            }
        }

        this.localStore.patchState({ zoomTo: zoomArea });
    }

    protected reportEmergency(emergencyType: EmergencyType): void {
        this.store.dispatch(new TacticalActions.ReportMissionEmergency(emergencyType));
    }

    protected cancelEmergency(): void {
        this.store.dispatch(new TacticalActions.CancelMissionEmergency());
    }

    protected reload() {
        reloadComponent(this.router);
    }

    private updateActiveViolations({ type, payload }: ViolationNotification) {
        if (type === FlightViolationEvent.FlightViolationOccurredEvent) {
            this.activeViolations.add(payload.violation);
        }

        if (type === FlightViolationEvent.FlightViolationCanceledEvent) {
            this.activeViolations.delete(payload.violation);
        }

        return this.activeViolations;
    }

    private getTopPriorityViolation(violations: Set<Violation>): Violation | undefined {
        if (violations.size === 0) {
            return undefined;
        }

        return VIOLATION_PRIORITY.find((violation) => violations.has(violation));
    }

    private handleViolationWarnings(violation?: Violation) {
        this.violationToast?.toastRef.close();
        this.violationToast = undefined;

        if (!violation) {
            return;
        }

        let message;
        let toastType: "warning" | "error" = "error";

        switch (violation) {
            case Violation.UavOutsideStartingFlightZone:
                message = "dtmWebAppLibTactical.violations.uavOutsideFlightZoneWarningText";
                break;
            case Violation.UavLeftOwnFlightArea:
                message = "dtmWebAppLibTactical.violations.uavLeftOwnFlightAreaWarningText";
                toastType = "warning";
                break;
            case Violation.UavLeftOwnSafetyArea:
                message = "dtmWebAppLibTactical.violations.uavLeftOwnSafetyAreaWarningText";
                break;
            default:
                return;
        }

        this.violationToast = this.createViolationToast(message, toastType);
    }

    private createViolationToast(message: string, type: "warning" | "error" = "error"): ActiveToast<unknown> {
        return this.toastrService[type](this.transloco.translate(message), undefined, {
            disableTimeOut: true,
            enableHtml: true,
            tapToDismiss: false,
        });
    }

    public ngAfterViewInit(): void {
        this.toastrService.overlayContainer = this.toastContainer;
        this.watchForViolationsUpdates();
    }

    protected togglePanelFold() {
        const isDataPanelFolded = this.localStore.selectSnapshotByKey("isDataPanelFolded");
        this.localStore.patchState({ isDataPanelFolded: !isDataPanelFolded });
    }

    protected parseRouteDataForSideView(route: MissionPlanRoute | undefined): MissionPlanRoute | undefined {
        if (!route || route.sections.length === 0) {
            return;
        }

        return route;
    }

    protected enrichFlightPositionUpdates(identifier: string): void {
        this.store.dispatch(new FlightTrackingActions.EnrichFlightPositionUpdates(identifier));
    }

    private updateTrajectoriesOnFlightUpdates() {
        this.flights$
            .pipe(
                filter((flight) => {
                    const missionData = this.store.selectSnapshot(TacticalState.missionData);

                    return (
                        missionData?.status === MissionStatus.Started &&
                        !!missionData?.uav.trackersIdentifiers?.some((trackerIdentifier) => trackerIdentifier === flight.trackerIdentifier)
                    );
                }),
                untilDestroyed(this)
            )
            .subscribe((flight) => {
                const missionData = this.store.selectSnapshot(TacticalState.missionData);

                if (!missionData || !flight.position?.latitude || !flight.position?.longitude) {
                    return;
                }

                const trajectoriesMap = this.localStore.selectSnapshotByKey("trajectories");
                const trajectories = ObjectUtils.cloneDeep(trajectoriesMap?.get(missionData.missionId));

                const activeTrajectory = trajectories?.find((trajectory) => trajectory.isActive);

                const trajectoryPoint: TrajectoryPosition = {
                    latitude: flight.position?.latitude,
                    longitude: flight.position?.longitude,
                };
                if (activeTrajectory) {
                    activeTrajectory.positions.push(trajectoryPoint);
                } else {
                    trajectories?.push({
                        isActive: true,
                        positions: [trajectoryPoint],
                        trackerIdentifier: flight.trackerIdentifier,
                    });
                }

                const update = new Map(trajectoriesMap).set(missionData.missionId, trajectories ?? []);

                this.localStore.patchState({ trajectories: update });
            });
    }

    private watchForViolationsUpdates() {
        this.missionData$
            .pipe(
                distinctUntilChanged((left, right) => left?.missionId === right?.missionId),
                untilDestroyed(this)
            )
            .subscribe((missionData) => {
                this.violationToast?.toastRef.close();
                this.violationToast = undefined;
                this.activeViolations.clear();

                const violations = missionData?.activeViolations;
                violations?.forEach((violation) => this.activeViolations.add(violation));

                const topPriorityViolation = this.getTopPriorityViolation(this.activeViolations);

                this.localStore.patchState({ violation: topPriorityViolation });
                this.handleViolationWarnings(topPriorityViolation);
            });

        this.flightViolationUpdaterService.flightViolationUpdate$
            .pipe(
                filter((update) => {
                    const missionData = this.store.selectSnapshot(TacticalState.missionData);

                    return !!missionData?.uav.trackersIdentifiers.find(
                        (trackerIdentifier) => trackerIdentifier === update.payload.trackerIdentifier
                    );
                }),
                map((update) => this.updateActiveViolations(update)),
                map(this.getTopPriorityViolation),
                untilDestroyed(this)
            )
            .subscribe((violation) => {
                this.localStore.patchState({ violation });
                this.handleViolationWarnings(violation);
            });
    }

    private watchForNotifications() {
        this.actions$
            .pipe(ofActionDispatched(TacticalActions.ShowNotification), untilDestroyed(this))
            .subscribe(({ message }) =>
                this.toastrService.warning(message, undefined, { disableTimeOut: "extendedTimeOut", closeButton: true })
            );
    }

    private watchFlightTrackingErrors(): void {
        this.store
            .select(FlightTrackingState.getError)
            .pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
            .subscribe((error) => {
                switch (error.type) {
                    case FlightTrackingErrorType.CannotGetHeightInformation:
                        this.toastrService.error(this.transloco.translate("dtmWebAppLibTactical.cannotGetHeightInformation"));
                        break;

                    default:
                        break;
                }
            });
    }

    private watchMissionDataChangesAndGetWeatherData() {
        if (!this.store.selectSnapshot(OperatorContextState.isFeatureAvailable(GlobalFeatures.WeatherForecast))) {
            return;
        }

        this.missionData$.pipe(untilDestroyed(this)).subscribe((missionData) => {
            if (!missionData) {
                this.store.dispatch(WeatherActions.ResetWeatherState);

                return;
            }

            this.store.dispatch(
                new WeatherActions.GetMissionPlanWeatherRange({
                    route: missionData.route,
                    missionStartTime: missionData.startTime.min,
                    missionEndTime: missionData.endTime.max,
                    hoursRangeBefore: TACTICAL_MAX_HOURS_BEFORE,
                    hoursRangeAfter: TACTICAL_MAX_HOURS_AFTER,
                })
            );
        });
    }
}
