import { Injectable } from "@angular/core";
import {
    CameraHelperService,
    EntityEditorConstraints,
    MapActionType,
    MapActionWithPayload,
    MapEntitiesEditorService,
    MapEntityType,
    MapUtils,
} from "@dtm-frontend/shared/map/cesium";
import { Logger, StringUtils } from "@dtm-frontend/shared/utils";
import { Cartesian3 } from "@pansa/ngx-cesium";
import { Feature, Geometry } from "@turf/helpers";
import { firstValueFrom } from "rxjs";

export interface CylinderAreaProperties {
    type: MapEntityType.Cylinder;
    center: [number, number];
    radius: number;
}

export interface PrismAreaProperties {
    type: MapEntityType.Prism;
    center?: [number, number];
}

export type AreaGeoJSON<P = CylinderAreaProperties | PrismAreaProperties> = Feature<Geometry, P>;

export const MAX_RADIUS = 25000;

const DEFAULT_CONSTRAINTS: EntityEditorConstraints = {
    default: {
        horizontalNavigationAccuracy: 50,
        verticalNavigationAccuracy: 1,
        topHeight: 2,
        bottomHeight: 1,
        radius: 1,
        startDelay: 0,
    },
    min: {
        horizontalNavigationAccuracy: 1,
        verticalNavigationAccuracy: 1,
        topHeight: 2,
        bottomHeight: 1,
        radius: 1,
        startDelay: 0,
    },
    max: {
        horizontalNavigationAccuracy: 10000,
        verticalNavigationAccuracy: 10000,
        topHeight: 2,
        bottomHeight: 1,
        radius: MAX_RADIUS,
        startDelay: 0,
    },
};

@Injectable()
export class AreaEditorMapService {
    public readonly editorContent$ = this.entitiesEditorService.editorContent$;
    public readonly activeMapAction$ = this.entitiesEditorService.activeMapAction$;
    public readonly activeEntityStatus$ = this.entitiesEditorService.activeEntityStatus$;
    public readonly mapEntitiesReady$ = this.cameraHelperService.mapEntitiesReady$;

    private readonly cylinderLabelProviders = { radius: (radius: number) => radius.toString() };

    constructor(
        private readonly entitiesEditorService: MapEntitiesEditorService,
        private readonly cameraHelperService: CameraHelperService
    ) {}

    public clearMap(): void {
        this.entitiesEditorService.stopEditors();
    }

    public async zoomToArea() {
        this.cameraHelperService.flyToContent(await firstValueFrom(this.entitiesEditorService.editorContent$));
    }

    public async processMapActionChange(action: MapActionWithPayload) {
        switch (action.type) {
            case MapActionType.RemoveContent:
                this.clearMap();

                return;

            case MapActionType.ShowEntireContent:
                this.zoomToArea();

                return;

            case MapActionType.FinishDrawing:
                this.entitiesEditorService.finishActiveEntityDrawing();

                return;

            case MapActionType.RemoveLastPoint:
                this.entitiesEditorService.removeLastPointFromActiveEntity();

                return;

            case MapActionType.CancelDrawing:
                this.entitiesEditorService.cancelActiveEntityDrawing();

                return;

            default:
                this.startEntityDrawing(action);

                break;
        }
    }

    public startEntityDrawing(action: MapActionWithPayload) {
        const entityId = StringUtils.generateId();

        switch (action.type) {
            case MapActionType.DrawCylinder:
                this.entitiesEditorService.startCylinderEditor(entityId, DEFAULT_CONSTRAINTS, this.cylinderLabelProviders);

                break;

            case MapActionType.DrawPrism:
                this.entitiesEditorService.startPrismEditor(entityId, DEFAULT_CONSTRAINTS, {});

                break;

            default:
                throw new Error(`Invalid action type: ${action.type}`);
        }
    }

    public createEditableArea(area: AreaGeoJSON) {
        if (area.properties?.type === MapEntityType.Cylinder) {
            this.createEditableCylinder(area as AreaGeoJSON<CylinderAreaProperties>);
        }

        if (area.properties?.type === MapEntityType.Prism) {
            this.createEditablePrism(area as AreaGeoJSON<PrismAreaProperties>);
        }
    }

    public updateAreaProperties(areaProperties: CylinderAreaProperties | PrismAreaProperties): void {
        const areaEntity = this.entitiesEditorService.editorContentValue[0];

        if (!areaEntity) {
            Logger.captureMessage("AreaEditorMapService.updateAreaProperties: entities editor returns no area", {
                level: "warning",
                extra: { areaEntity, areaProperties },
            });

            return;
        }

        const propertiesToUpdate: { center?: Cartesian3; radius?: number } = {};

        if (areaProperties.center) {
            propertiesToUpdate.center = MapUtils.convertSerializableCartographicToCartesian3({
                height: 0,
                latitude: areaProperties.center[1],
                longitude: areaProperties.center[0],
            });
        }

        if (areaProperties.type === MapEntityType.Cylinder && areaProperties.radius) {
            propertiesToUpdate.radius = areaProperties.radius;
        }

        this.entitiesEditorService.update({
            ...areaEntity,
            ...propertiesToUpdate,
        });
    }

    private createEditableCylinder(area: AreaGeoJSON<CylinderAreaProperties>) {
        if (!area.properties?.center) {
            Logger.captureMessage("AreaEditorMapService.createEditableCylinder: cylinder area has no `center` property", {
                level: "warning",
                extra: { area },
            });

            return;
        }

        this.entitiesEditorService.createEditableCylinder(
            MapUtils.convertSerializableCartographicToCartesian3({
                height: 0,
                latitude: area.properties.center[1],
                longitude: area.properties.center[0],
            }),
            StringUtils.generateId(),
            DEFAULT_CONSTRAINTS,
            this.cylinderLabelProviders,
            area.properties.radius,
            DEFAULT_CONSTRAINTS.default.topHeight
        );
    }

    private createEditablePrism(area: AreaGeoJSON<PrismAreaProperties>) {
        this.entitiesEditorService.createEditablePrism(
            this.convertGeoJsonPolygonGeometryToCartesian3(area?.geometry),
            StringUtils.generateId(),
            DEFAULT_CONSTRAINTS,
            {}
        );
    }

    private convertGeoJsonPolygonGeometryToCartesian3(geometry: Geometry) {
        return [geometry.type === "Polygon" ? geometry.coordinates : []]
            .flat(Number.MAX_SAFE_INTEGER)
            .reduce<Cartesian3[]>((result, coordinate, coordinateIndex, array) => {
                if (coordinateIndex % 2 === 1) {
                    result.push(
                        MapUtils.convertSerializableCartographicToCartesian3({
                            height: 0,
                            latitude: coordinate as number,
                            longitude: array[coordinateIndex - 1] as number,
                        })
                    );
                }

                return result;
            }, [])
            .slice(0, -1);
    }
}
