import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { MatLegacyCardModule as MatCardModule } from "@angular/material/legacy-card";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { LanguageCode, SharedI18nModule, getTranslocoInlineLoader } from "@dtm-frontend/shared/ui/i18n";
import { SharedToastModule } from "@dtm-frontend/shared/ui/toast";
import { TRANSLOCO_SCOPE } from "@jsverse/transloco";
import { LetModule, PushModule } from "@ngrx/component";
import { CommunicationPropertiesComponent } from "./components/setup-properties/communication-properties/communication-properties.component";
import { EquipmentPropertiesComponent } from "./components/setup-properties/equipment-properties/equipment-properties.component";
import { FlightTechnicalPropertiesComponent } from "./components/setup-properties/flight-technical-properties/flight-technical-properties.component";
import { NavigationAccuracyClassDescriptionComponent } from "./components/setup-properties/navigation-accuracy-class-description/navigation-accuracy-class-description.component";
import { OtherTechnicalPropertiesComponent } from "./components/setup-properties/other-technical-properties/other-technical-properties.component";
import { SetupPropertyBooleanComponent } from "./components/setup-properties/setup-property-boolean/setup-property-boolean.component";
import { SetupPropertyComponent } from "./components/setup-properties/setup-property/setup-property.component";
import { TrackingPropertiesComponent } from "./components/setup-properties/tracking-properties/tracking-properties.component";
import { UavSetupPreviewComponent } from "./components/uav-setup-preview/uav-setup-preview.component";
import { UavSetupsComponent } from "./components/uav-setups/uav-setups.component";

@NgModule({
    declarations: [
        FlightTechnicalPropertiesComponent,
        OtherTechnicalPropertiesComponent,
        CommunicationPropertiesComponent,
        TrackingPropertiesComponent,
        NavigationAccuracyClassDescriptionComponent,
        EquipmentPropertiesComponent,
        SetupPropertyComponent,
        SetupPropertyBooleanComponent,
        UavSetupPreviewComponent,
        UavSetupsComponent,
    ],
    exports: [
        FlightTechnicalPropertiesComponent,
        OtherTechnicalPropertiesComponent,
        CommunicationPropertiesComponent,
        TrackingPropertiesComponent,
        NavigationAccuracyClassDescriptionComponent,
        EquipmentPropertiesComponent,
        SetupPropertyComponent,
        SetupPropertyBooleanComponent,
        UavSetupPreviewComponent,
        UavSetupsComponent,
    ],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            multi: true,
            useValue: {
                scope: "dtmSharedUav",
                loader: getTranslocoInlineLoader((language: LanguageCode) => import(`./assets/i18n/${language}.json`)),
            },
        },
    ],
    imports: [CommonModule, SharedI18nModule, LetModule, PushModule, SharedUiModule, SharedToastModule.forRoot(), MatCardModule],
})
export class SharedUavModule {
    public static forRoot(): ModuleWithProviders<SharedUavModule> {
        return {
            ngModule: SharedUavModule,
            providers: [],
        };
    }

    public static forTest(): ModuleWithProviders<SharedUavModule> {
        return {
            ngModule: SharedUavModule,
            providers: [],
        };
    }
}
