import { ChangeDetectionStrategy, Component, ViewChild } from "@angular/core";
import { UntilDestroy } from "@ngneat/until-destroy";
import { DefaultGlobalConfig, ToastContainerDirective, ToastrService } from "ngx-toastr";

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-alerts-wrapper",
    templateUrl: "./alerts-wrapper.component.html",
    styleUrls: ["./alerts-wrapper.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [ToastrService],
})
export class AlertsWrapperComponent {
    @ViewChild(ToastContainerDirective) protected set boundaryViolationToastContainer(value: ToastContainerDirective) {
        this.initToastContainer(value);
    }

    constructor(private readonly toastrService: ToastrService) {}

    private initToastContainer(value: ToastContainerDirective | undefined) {
        if (!value || this.toastrService.overlayContainer === value) {
            return;
        }

        this.toastrService.overlayContainer = value;
        this.toastrService.toastrConfig = {
            ...DefaultGlobalConfig,
            autoDismiss: false,
            closeButton: true,
            tapToDismiss: false,
            disableTimeOut: true,
            positionClass: "toast-top-center",
        };
    }
}
