import { HttpClient, HttpErrorResponse, HttpParams, HttpStatusCode } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Tracker, Uav, UavModelDocumentType } from "@dtm-frontend/shared/uav";
import { FilesGroup, PageResponseBody } from "@dtm-frontend/shared/ui";
import { Logger, StringUtils } from "@dtm-frontend/shared/utils";
import { Observable, forkJoin, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { UAV_ENDPOINTS, UavEndpoints } from "../uav.tokens";
import {
    CapabilitiesToShareResponseBody,
    CreateUavResponseBody,
    ManufacturersResponseBody,
    UavCapabilitiesResponseBody,
    UavListItemResponseBody,
    UavModelPropertiesResponseBody,
    UavResponseBody,
    UavSetupResponseBody,
    convertCommunicationsToCommunicationsRequestPayload,
    convertDocumentsToDocumentsRequestPayload,
    convertEquipmentToEquipmentRequestPayload,
    convertFilesGroupsToUavDocumentsRequestPayload,
    convertManufacturersResponseBodyToManufacturers,
    convertTrackingsToTrackingsRequestPayload,
    convertUavCapabilitiesResponseBodyAndTrackersToUavCapabilities,
    convertUavCapabilitiesResponseBodyToCapabilitiesToShare,
    convertUavListItemsResponseBodyToUavListItems,
    convertUavModelPropertiesResponseBodyToUavModelProperties,
    convertUavResponseBodyToUav,
} from "./uav-api.converters";
import {
    CapabilitiesToShare,
    EditableCustomUav,
    EditableCustomUavSetup,
    EditableUav,
    EditableUavSetup,
    Manufacturer,
    NewCustomUav,
    NewUav,
    ShareUavModel,
    UavCapabilities,
    UavErrorType,
    UavListParams,
    UavListWithPages,
    UavModelProperties,
} from "./uav.models";

const UAV_ALREADY_SHARED_TO_OPERATOR_ERROR_MESSAGE = "already_shared_to_operator";

@Injectable({
    providedIn: "root",
})
export class UavAPIService {
    constructor(private readonly httpClient: HttpClient, @Inject(UAV_ENDPOINTS) private readonly endpoints: UavEndpoints) {}

    public getUavList(params: UavListParams): Observable<UavListWithPages> {
        let requestParams = new HttpParams()
            .set("size", params.size)
            .set("page", params.page)
            .set("operatorId", params.operatorId)
            .set("sort", params.sortBy);

        if (params.textSearch) {
            requestParams = requestParams.set("freeTextSearch", params.textSearch);
        }

        return this.httpClient
            .get<PageResponseBody<UavListItemResponseBody>>(this.endpoints.uavsManagement, { params: requestParams })
            .pipe(
                map((response: PageResponseBody<UavListItemResponseBody>) => ({
                    pages: {
                        pageSize: response.size,
                        pageNumber: response.number,
                        totalElements: response.totalElements,
                    },
                    content: convertUavListItemsResponseBodyToUavListItems(response.content, this.endpoints.getModelPhoto),
                })),
                catchError((error) => {
                    Logger.captureException(error);

                    return throwError(() => ({ type: UavErrorType.CannotGetUavs }));
                })
            );
    }

    public getUav(uavId: string): Observable<Uav> {
        return this.httpClient.get<UavResponseBody>(StringUtils.replaceInTemplate(this.endpoints.uavManagement, { id: uavId })).pipe(
            map((uav: UavResponseBody) => convertUavResponseBodyToUav(uav, this.endpoints.getModelPhoto)),
            catchError((error) => {
                Logger.captureException(error);

                return throwError(() => {
                    if (error?.status === HttpStatusCode.NotFound) {
                        return { type: UavErrorType.NotFound };
                    }

                    return { type: UavErrorType.CannotGetUav };
                });
            })
        );
    }

    public getCapabilities(operatorId: string): Observable<UavCapabilities> {
        return forkJoin([
            this.httpClient.get<UavCapabilitiesResponseBody>(StringUtils.replaceInTemplate(this.endpoints.getCapabilities, { operatorId })),
            this.httpClient.get<Tracker[]>(this.endpoints.getTrackers),
        ]).pipe(
            map(([capabilities, trackers]) => convertUavCapabilitiesResponseBodyAndTrackersToUavCapabilities(capabilities, trackers)),
            catchError((error) => {
                Logger.captureException(error);

                return throwError(() => ({ type: UavErrorType.CannotGetCapabilities }));
            })
        );
    }

    public getManufacturersAndModels(): Observable<Manufacturer[]> {
        return this.httpClient.get<ManufacturersResponseBody>(this.endpoints.getManufacturers).pipe(
            map((response) => convertManufacturersResponseBodyToManufacturers(response, this.endpoints.getModelPhoto)),
            catchError((error) => {
                Logger.captureException(error);

                return throwError(() => ({ type: UavErrorType.CannotGetManufacturers }));
            })
        );
    }

    public getUavModel(uavModelId: string, defaultCameraName: string): Observable<UavModelProperties> {
        return this.httpClient
            .get<UavModelPropertiesResponseBody>(StringUtils.replaceInTemplate(this.endpoints.getModel, { id: uavModelId }))
            .pipe(
                map((uavModel: UavModelPropertiesResponseBody) =>
                    convertUavModelPropertiesResponseBodyToUavModelProperties(uavModel, this.endpoints.getModelPhoto, defaultCameraName)
                ),
                catchError((error) => {
                    Logger.captureException(error);

                    return throwError(() => ({ type: UavErrorType.CannotGetModel }));
                })
            );
    }

    public getShareCapabilities(uavId: string): Observable<CapabilitiesToShare> {
        return this.httpClient
            .get<CapabilitiesToShareResponseBody>(StringUtils.replaceInTemplate(this.endpoints.getShareCapabilities, { uavId }))
            .pipe(
                map((response) => convertUavCapabilitiesResponseBodyToCapabilitiesToShare(response)),
                catchError((error) => {
                    Logger.captureException(error);

                    return throwError(() => ({ type: UavErrorType.CannotGetCapabilitiesForShareSetup }));
                })
            );
    }

    public shareUavSetups(uavId: string, shareUavModel: ShareUavModel): Observable<ShareUavModel> {
        return this.httpClient
            .post<ShareUavModel>(StringUtils.replaceInTemplate(this.endpoints.shareSetups, { uavId }), shareUavModel)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    Logger.captureException(error);

                    if (
                        error.status === HttpStatusCode.BadRequest &&
                        error.error.generalMessage.includes(UAV_ALREADY_SHARED_TO_OPERATOR_ERROR_MESSAGE)
                    ) {
                        return throwError(() => ({ type: UavErrorType.UavAlreadySharedToOperator }));
                    }

                    return throwError(() => ({ type: UavErrorType.CannotShareSetupForOperator }));
                })
            );
    }

    public createUav(uav: NewUav): Observable<string> {
        const payload = {
            name: uav.name,
            modelId: uav.uavModel.id,
            operatorId: uav.operatorId,
            swarm: uav.isSwarm,
            serialNumbers: uav.serialNumbers,
            uavClasses: uav.uavClasses,
            setup: {
                name: uav.setup.name,
                technicalSpecification: {
                    maxFlightTime: uav.setup.technicalSpecification.maxFlightTime,
                    flightSpeedLimit: uav.setup.technicalSpecification.hasFlightSpeedLimit,
                    maxFlightSpeed: uav.setup.technicalSpecification.maxFlightSpeed,
                    minFlightSpeed: uav.setup.technicalSpecification.minFlightSpeed,
                    failSafe: uav.setup.technicalSpecification.failSafe,
                    geofencing: uav.setup.technicalSpecification.hasGeofencing,
                    geocage: uav.setup.technicalSpecification.hasGeocage,
                    emergencyMotorStop: uav.setup.technicalSpecification.hasEmergencyMotorStop,
                },
                communications: convertCommunicationsToCommunicationsRequestPayload(uav.setup.communications),
                trackings: convertTrackingsToTrackingsRequestPayload(uav.setup.trackings),
                documentsAttachRequest: convertDocumentsToDocumentsRequestPayload(uav.setup.documents),
                equipment: convertEquipmentToEquipmentRequestPayload(uav.setup.equipment),
            },
        };

        return this.httpClient.post<CreateUavResponseBody>(this.endpoints.uavsManagement, payload).pipe(
            map((response) => response.id),
            catchError((error) => {
                Logger.captureException(error);

                return throwError(() => {
                    if (this.isSerialNumberAlreadyExistsError(error.error)) {
                        return { type: UavErrorType.CannotCreateUavSerialNumberExists };
                    }

                    return { type: UavErrorType.CannotCreateUav };
                });
            })
        );
    }

    public createCustomUav(uav: NewCustomUav): Observable<string> {
        const payload = {
            manufacturerName: uav.manufacturerName,
            modelName: uav.modelName,
            swarm: uav.isSwarm,
            serialNumbers: uav.serialNumbers,
            uavClasses: uav.uavClasses,
            ceCompliant: uav.isCeCompliant,
            name: uav.name,
            photoId: uav.imageId,
            operatorId: uav.operatorId,
            type: uav.type,
            setup: {
                name: uav.setup.name,
                technicalSpecification: {
                    numberOfEngines: uav.setup.technicalSpecification.numberOfEngines,
                    driveType: uav.setup.technicalSpecification.driveType,
                    minRecommendedAmbientTemperature: uav.setup.technicalSpecification.minRecommendedAmbientTemperature,
                    maxRecommendedAmbientTemperature: uav.setup.technicalSpecification.maxRecommendedAmbientTemperature,
                    takeOffMass: uav.setup.technicalSpecification.takeOffMass,
                    maxTakeOffMass: uav.setup.technicalSpecification.maxTakeOffMass,
                    maxDroneWidth: uav.setup.technicalSpecification.maxDroneWidth,
                    maxFlightTime: uav.setup.technicalSpecification.maxFlightTime,
                    minFlightSpeed: uav.setup.technicalSpecification.minFlightSpeed,
                    maxFlightSpeed: uav.setup.technicalSpecification.maxFlightSpeed,
                    maxClimbSpeed: uav.setup.technicalSpecification.maxClimbSpeed,
                    maxDescentSpeed: uav.setup.technicalSpecification.maxDescentSpeed,
                    maxWind: uav.setup.technicalSpecification.maxWind,
                    maxFlightAltitude: uav.setup.technicalSpecification.maxFlightAltitude,
                    rainFlightPossibility: uav.setup.technicalSpecification.hasRainFlightPossibility,
                    failSafe: uav.setup.technicalSpecification.failSafe,
                    geofencing: uav.setup.technicalSpecification.hasGeofencing,
                    detectAndAvoid: uav.setup.technicalSpecification.hasDetectAndAvoid,
                    proximitySensors: uav.setup.technicalSpecification.hasProximitySensors,
                    flightSpeedLimit: uav.setup.technicalSpecification.hasFlightSpeedLimit,
                    moduleRedundancy: uav.setup.technicalSpecification.hasModuleRedundancy,
                    geocage: uav.setup.technicalSpecification.hasGeocage,
                    emergencyMotorStop: uav.setup.technicalSpecification.hasEmergencyMotorStop,
                },
                communications: convertCommunicationsToCommunicationsRequestPayload(uav.setup.communications),
                trackings: convertTrackingsToTrackingsRequestPayload(uav.setup.trackings),
                documentsAttachRequest: convertDocumentsToDocumentsRequestPayload(uav.setup.documents),
                equipment: convertEquipmentToEquipmentRequestPayload(uav.setup.equipment),
            },
        };

        return this.httpClient.post<CreateUavResponseBody>(this.endpoints.customUavsManagement, payload).pipe(
            map((response) => response.id),
            catchError((error) => {
                Logger.captureException(error);

                return throwError(() => {
                    if (this.isSerialNumberAlreadyExistsError(error.error)) {
                        return { type: UavErrorType.CannotCreateUavSerialNumberExists };
                    }

                    return { type: UavErrorType.CannotCreateUav };
                });
            })
        );
    }

    public deleteUav(uavId: string): Observable<void> {
        return this.httpClient.delete<void>(StringUtils.replaceInTemplate(this.endpoints.uavManagement, { id: uavId })).pipe(
            catchError((error) => {
                Logger.captureException(error);

                return throwError(() => ({ type: UavErrorType.CannotDeleteUav }));
            })
        );
    }

    public updateUav(uavId: string, uavValues: EditableUav) {
        const payload = {
            id: uavId,
            name: uavValues.name,
            serialNumbers: uavValues.serialNumbers,
            uavClasses: uavValues.uavClasses,
        };

        return this.httpClient.put<void>(StringUtils.replaceInTemplate(this.endpoints.uavManagement, { id: uavId }), payload).pipe(
            catchError((error) => {
                Logger.captureException(error);

                return throwError(() => {
                    if (this.isSerialNumberAlreadyExistsError(error.error)) {
                        return { type: UavErrorType.CannotUpdateUavSerialNumberExists };
                    }

                    return { type: UavErrorType.CannotUpdateUav };
                });
            })
        );
    }

    public updateCustomUav(uavId: string, uavValues: EditableCustomUav) {
        const payload = {
            id: uavId,
            name: uavValues.name,
            serialNumbers: uavValues.serialNumbers,
            uavClasses: uavValues.uavClasses,
            manufacturerName: uavValues.manufacturerName,
            modelName: uavValues.modelName,
            type: uavValues.type,
            ceCompliant: uavValues.isCeCompliant,
            photoId: uavValues.imageId,
        };

        return this.httpClient.put<void>(StringUtils.replaceInTemplate(this.endpoints.customUavManagement, { id: uavId }), payload).pipe(
            catchError((error) => {
                Logger.captureException(error);

                return throwError(() => {
                    if (this.isSerialNumberAlreadyExistsError(error)) {
                        return { type: UavErrorType.CannotUpdateUavSerialNumberExists };
                    }

                    return { type: UavErrorType.CannotUpdateUav };
                });
            })
        );
    }

    public createSetup(uavId: string, setup: EditableUavSetup): Observable<string> {
        const payload = {
            name: setup.name,
            technicalSpecification: {
                maxFlightTime: setup.technicalSpecification.maxFlightTime,
                flightSpeedLimit: setup.technicalSpecification.hasFlightSpeedLimit,
                maxFlightSpeed: setup.technicalSpecification.maxFlightSpeed,
                minFlightSpeed: setup.technicalSpecification.minFlightSpeed,
                failSafe: setup.technicalSpecification.failSafe,
                geofencing: setup.technicalSpecification.hasGeofencing,
                geocage: setup.technicalSpecification.hasGeocage,
                emergencyMotorStop: setup.technicalSpecification.hasEmergencyMotorStop,
            },
            communications: convertCommunicationsToCommunicationsRequestPayload(setup.communications),
            trackings: convertTrackingsToTrackingsRequestPayload(setup.trackings),
            documentsAttachRequest: convertDocumentsToDocumentsRequestPayload(setup.documents),
            equipment: convertEquipmentToEquipmentRequestPayload(setup.equipment),
        };

        return this.httpClient
            .post<UavSetupResponseBody>(StringUtils.replaceInTemplate(this.endpoints.uavSetupsManagement, { id: uavId }), payload)
            .pipe(
                map((response) => response.id),
                catchError((error) => {
                    Logger.captureException(error);

                    return throwError(() => ({ type: UavErrorType.CannotCreateUavSetup }));
                })
            );
    }

    public updateSetup(setupId: string, uavId: string, setup: EditableUavSetup): Observable<void> {
        const payload = {
            id: setup.id,
            name: setup.name,
            technicalSpecification: {
                maxFlightTime: setup.technicalSpecification.maxFlightTime,
                flightSpeedLimit: setup.technicalSpecification.hasFlightSpeedLimit,
                maxFlightSpeed: setup.technicalSpecification.maxFlightSpeed,
                minFlightSpeed: setup.technicalSpecification.minFlightSpeed,
                failSafe: setup.technicalSpecification.failSafe,
                geofencing: setup.technicalSpecification.hasGeofencing,
                geocage: setup.technicalSpecification.hasGeocage,
                emergencyMotorStop: setup.technicalSpecification.hasEmergencyMotorStop,
            },
            communications: convertCommunicationsToCommunicationsRequestPayload(setup.communications, true),
            trackings: convertTrackingsToTrackingsRequestPayload(setup.trackings),
            documentsAttachRequest: convertDocumentsToDocumentsRequestPayload(setup.documents),
            equipment: convertEquipmentToEquipmentRequestPayload(setup.equipment),
        };

        return this.httpClient
            .put<void>(StringUtils.replaceInTemplate(this.endpoints.uavSetupManagement, { uavId, setupId }), payload)
            .pipe(
                catchError((error) => {
                    Logger.captureException(error);

                    return throwError(() => ({ type: UavErrorType.CannotUpdateUavSetup }));
                })
            );
    }

    public updateCustomUavSetup(setupId: string, uavId: string, setup: EditableCustomUavSetup): Observable<void> {
        const payload = {
            id: setup.id,
            name: setup.name,
            technicalSpecification: {
                numberOfEngines: setup.technicalSpecification.numberOfEngines,
                driveType: setup.technicalSpecification.driveType,
                minRecommendedAmbientTemperature: setup.technicalSpecification.minRecommendedAmbientTemperature,
                maxRecommendedAmbientTemperature: setup.technicalSpecification.maxRecommendedAmbientTemperature,
                takeOffMass: setup.technicalSpecification.takeOffMass,
                maxTakeOffMass: setup.technicalSpecification.maxTakeOffMass,
                maxDroneWidth: setup.technicalSpecification.maxDroneWidth,
                maxFlightTime: setup.technicalSpecification.maxFlightTime,
                minFlightSpeed: setup.technicalSpecification.minFlightSpeed,
                maxFlightSpeed: setup.technicalSpecification.maxFlightSpeed,
                maxClimbSpeed: setup.technicalSpecification.maxClimbSpeed,
                maxDescentSpeed: setup.technicalSpecification.maxDescentSpeed,
                maxWind: setup.technicalSpecification.maxWind,
                maxFlightAltitude: setup.technicalSpecification.maxFlightAltitude,
                rainFlightPossibility: setup.technicalSpecification.hasRainFlightPossibility,
                failSafe: setup.technicalSpecification.failSafe,
                geofencing: setup.technicalSpecification.hasGeofencing,
                detectAndAvoid: setup.technicalSpecification.hasDetectAndAvoid,
                proximitySensors: setup.technicalSpecification.hasProximitySensors,
                moduleRedundancy: setup.technicalSpecification.hasModuleRedundancy,
                geocage: setup.technicalSpecification.hasGeocage,
                emergencyMotorStop: setup.technicalSpecification.hasEmergencyMotorStop,
                flightSpeedLimit: setup.technicalSpecification.hasFlightSpeedLimit,
            },
            communications: convertCommunicationsToCommunicationsRequestPayload(setup.communications, true),
            trackings: convertTrackingsToTrackingsRequestPayload(setup.trackings),
            documentsAttachRequest: convertDocumentsToDocumentsRequestPayload(setup.documents),
            equipment: convertEquipmentToEquipmentRequestPayload(setup.equipment),
        };

        return this.httpClient
            .put<void>(StringUtils.replaceInTemplate(this.endpoints.customUavSetupManagement, { uavId, setupId }), payload)
            .pipe(
                catchError((error) => {
                    Logger.captureException(error);

                    return throwError(() => ({ type: UavErrorType.CannotUpdateUavSetup }));
                })
            );
    }

    public deleteSetup(setupId: string, uavId: string): Observable<void> {
        return this.httpClient.delete<void>(StringUtils.replaceInTemplate(this.endpoints.uavSetupManagement, { uavId, setupId })).pipe(
            catchError((error) => {
                Logger.captureException(error);

                return throwError(() => ({ type: UavErrorType.CannotDeleteUavSetup }));
            })
        );
    }

    public attachUavDocuments(uavId: string, documents: FilesGroup<UavModelDocumentType>[]): Observable<void> {
        const payload = convertFilesGroupsToUavDocumentsRequestPayload(documents);

        return this.httpClient.put<void>(StringUtils.replaceInTemplate(this.endpoints.attachUavDocuments, { id: uavId }), payload).pipe(
            catchError((error) => {
                Logger.captureException(error);

                return throwError(() => ({ type: UavErrorType.CannotAttachUavDocuments }));
            })
        );
    }

    private isSerialNumberAlreadyExistsError(error: { generalMessage: string }): boolean {
        return error.generalMessage === "user_directory.uav.serial_number_already_exists";
    }
}
