import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnDestroy } from "@angular/core";
import { MissionPlanAnalysisIssueBase, MissionPlanAnalysisIssueStatus, MissionPlanAnalysisStatus } from "@dtm-frontend/shared/mission";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { ToastrService } from "ngx-toastr";
import { ActiveToast } from "ngx-toastr/toastr/toastr.service";
import { AnalysisAlertToastComponent } from "./analysis-alert-toast/analysis-alert-toast.component";
import { SailAlertToastComponent } from "./sail-alert-toast/sail-alert-toast.component";

interface AnalysisAlertComponentState {
    analysisStatus: MissionPlanAnalysisStatus | undefined;
}

const MAX_SAIL_LEVEL = 5;

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-analysis-alert",
    template: "",
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class AnalysisAlertComponent implements AfterViewInit, OnDestroy {
    @Input() public set analysisStatus(value: MissionPlanAnalysisStatus | undefined) {
        this.localStore.patchState({ analysisStatus: value });
    }

    private toastInstance: ActiveToast<AnalysisAlertToastComponent | SailAlertToastComponent> | undefined;

    constructor(
        private readonly toastrService: ToastrService,
        private readonly localStore: LocalComponentStore<AnalysisAlertComponentState>
    ) {
        this.localStore.setState({
            analysisStatus: undefined,
        });
    }

    public ngAfterViewInit(): void {
        this.localStore
            .selectByKey("analysisStatus")
            .pipe(untilDestroyed(this))
            .subscribe((issues) => {
                if (!issues) {
                    this.toastrService.clear();
                    this.toastInstance = undefined;

                    return;
                }

                const sailLevel = issues?.sora?.result?.sail;
                if (sailLevel && sailLevel >= MAX_SAIL_LEVEL) {
                    this.handleSailToast(sailLevel);

                    return;
                }
                this.handleOtherIssues(issues);
            });
    }

    private handleOtherIssues(issues: MissionPlanAnalysisStatus) {
        const extractedIssues = this.extractIssues(issues);
        if (!extractedIssues?.length) {
            this.toastrService.clear(this.toastInstance?.toastId);
            this.toastInstance = undefined;

            return;
        }

        if (!(this.toastInstance?.portal.instance instanceof AnalysisAlertToastComponent)) {
            this.toastrService.clear(this.toastInstance?.toastId);
            this.toastInstance = this.toastrService.warning(undefined, undefined, { toastComponent: AnalysisAlertToastComponent });
        }

        (this.toastInstance.portal.instance as AnalysisAlertToastComponent).issues = extractedIssues;
    }

    private handleSailToast(sailLevel: number) {
        if (!(this.toastInstance?.portal.instance instanceof SailAlertToastComponent)) {
            this.toastrService.clear(this.toastInstance?.toastId);
            this.toastInstance = this.toastrService.warning(undefined, undefined, {
                toastComponent: SailAlertToastComponent,
                closeButton: true,
            });
        }

        (this.toastInstance.portal.instance as SailAlertToastComponent).sail = sailLevel;
    }

    public ngOnDestroy(): void {
        this.toastrService.clear(this.toastInstance?.toastId);
    }

    private extractIssues(analysis: MissionPlanAnalysisStatus): MissionPlanAnalysisIssueBase[] {
        const traffic = analysis.traffic?.issues || [];
        const airspace = analysis.airspace?.issues || [];
        const sora = analysis.sora?.issues || [];
        const caaPermit = analysis.caaPermit?.issues || [];
        const issues = [...traffic, ...airspace, ...sora, ...caaPermit].filter(
            ({ status }) =>
                ![
                    MissionPlanAnalysisIssueStatus.Info,
                    MissionPlanAnalysisIssueStatus.Success,
                    MissionPlanAnalysisIssueStatus.Warning,
                ].includes(status)
        );
        const evaluation = analysis.evaluation.issues || [];

        return [...issues, ...evaluation];
    }
}
