import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { NewUavBasicInfo } from "../../../new-uav-wizard.models";

@Component({
    templateUrl: "./uav-confirmation-dialog.component.html",
    styleUrls: ["./uav-confirmation-dialog.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UavConfirmationDialogComponent {
    protected get manufacturerName() {
        if (this.data.isCustom) {
            return this.data.manufacturerName;
        }

        return this.data.manufacturer.name;
    }

    protected get modelName() {
        if (this.data.isCustom) {
            return this.data.modelName;
        }

        return this.data.uavModel.name;
    }

    protected get type() {
        if (this.data.isCustom) {
            return this.data.type;
        }

        return undefined;
    }

    constructor(@Inject(MAT_DIALOG_DATA) protected data: NewUavBasicInfo) {}
}
