import { ChangeDetectionStrategy, Component, Inject, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { MatRadioChange } from "@angular/material/radio";
import { UavClass } from "@dtm-frontend/shared/uav";
import { InvalidFormScrollableDirective } from "@dtm-frontend/shared/ui";
import { AnimationUtils, ONLY_WHITE_SPACES_VALIDATION_PATTERN } from "@dtm-frontend/shared/utils";
import { Observable, Subject } from "rxjs";
import { EditableUav, MAX_UAV_NAME_LENGTH, MIN_UAV_NAME_LENGTH } from "../../services/uav.models";

interface EditUavDialogData {
    currentName: string;
    currentSerialNumbers: string[];
    currentUavClasses: UavClass[];
    currentIsSwarm: boolean;
    isProcessing$: Observable<boolean>;
}

interface UavForm {
    name: FormControl<string | null>;
    serialNumbers: FormControl<string[]>;
    uavClasses: FormControl<UavClass[]>;
}

interface UavFormValues {
    name: string;
    serialNumbers: string[];
    uavClasses: UavClass[];
}

@Component({
    selector: "dtm-web-app-lib-edit-uav-dialog",
    templateUrl: "./edit-uav-dialog.component.html",
    styleUrls: ["./edit-uav-dialog.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [AnimationUtils.slideInAnimation()],
})
export class EditUavDialogComponent {
    @ViewChild(InvalidFormScrollableDirective) private readonly invalidFormScrollable!: InvalidFormScrollableDirective;

    private readonly setUavValuesSubject = new Subject<EditableUav>();
    public readonly setUavValues$ = this.setUavValuesSubject.asObservable();

    protected readonly uavClasses: UavClass[] = Object.values(UavClass);
    protected readonly uavForm = new FormGroup<UavForm>({
        name: new FormControl<string | null>(null, [
            Validators.required,
            Validators.maxLength(MAX_UAV_NAME_LENGTH),
            Validators.minLength(MIN_UAV_NAME_LENGTH),
            Validators.pattern(ONLY_WHITE_SPACES_VALIDATION_PATTERN),
        ]),
        serialNumbers: new FormControl<string[]>([], {
            validators: [Validators.required],
            nonNullable: true,
        }),
        uavClasses: new FormControl<UavClass[]>([], { nonNullable: true }),
    });
    protected readonly isUavClassSetControl = new FormControl<boolean>(false, { nonNullable: true });

    constructor(@Inject(MAT_DIALOG_DATA) protected readonly data: EditUavDialogData) {
        const values = {
            name: data.currentName,
            serialNumbers: data.currentSerialNumbers,
            uavClasses: data.currentUavClasses,
        };

        this.uavForm.setValue(values, { emitEvent: false });
        this.isUavClassSetControl.setValue(!!data.currentUavClasses.length);
    }

    protected save(): void {
        if (this.uavForm.invalid) {
            this.uavForm.markAllAsTouched();
            this.invalidFormScrollable.scrollToFirstInvalidField();

            return;
        }

        const values = this.uavForm.value as UavFormValues;

        this.setUavValuesSubject.next(values);
    }

    protected manageIsUavClassSetValue({ value }: MatRadioChange) {
        if (value) {
            this.uavForm.controls.uavClasses.setValidators([Validators.required]);
        } else {
            this.uavForm.controls.uavClasses.setValidators([]);
            this.uavForm.controls.uavClasses.setValue([], { emitEvent: false });
        }
        this.uavForm.controls.uavClasses.updateValueAndValidity();
    }
}
