import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, Input, forwardRef } from "@angular/core";
import {
    ControlValueAccessor,
    FormControl,
    FormGroup,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
    ValidatorFn,
    Validators,
} from "@angular/forms";
import {
    AIR_RISK_MITIGATIONS_CATEGORIES,
    AirRisk,
    AirRiskElement,
    AirRiskMitigationSectionKeys,
    AirRiskMitigations,
    AirRiskMitigationsArc,
    AirRiskMitigationsCategory,
} from "@dtm-frontend/shared/mission";
import { AnimationUtils, FunctionUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { combineLatest } from "rxjs";
import { map } from "rxjs/operators";
import { RiskMitigationPopoverKeys } from "../../../../../../../models/mission.model";

export const AIR_RISK_MITIGATION_MAX_SAFETY_VOLUME_HEIGHT = 150;

const MAX_COMMENT_LENGTH = 1000;
const NUMBER_OF_HIDDEN_METHODS = 2;
const ONE_REQUIRED_VALIDATION_NAME = "oneRequired";

interface AirRiskMitigationWriteValue {
    [sectionKey: string]:
        | boolean
        | {
              [methodKey: string]: boolean | AirRiskMitigationsArc;
          }
        | { methods: { [methodKey: string]: { methodCheckbox: boolean; methodTextarea: string } } };
}

type MethodFormGroup = FormGroup<{
    methodCheckbox: FormControl<boolean>;
    methodTextarea: FormControl<string | null>;
}>;

interface AirRiskMitigationSectionForm {
    isSelected: FormControl<boolean>;
    airRiskClassMitigation: FormControl<AirRiskMitigationsArc | null>;
    methods: FormGroup<{
        [AirRiskMitigationSectionKeys?.commonFlightRules]: MethodFormGroup;
        [AirRiskMitigationSectionKeys?.commonAirspaceStructures]: MethodFormGroup;
        [AirRiskMitigationSectionKeys.timeSlotScheduledFlight]: MethodFormGroup;
        [AirRiskMitigationSectionKeys.geographicAreaLimitation]: MethodFormGroup;
        [AirRiskMitigationSectionKeys.limitationOfFlightTime]: MethodFormGroup;
    }>;
}

interface AirRiskMitigationForm {
    riskMitigationMeasure: FormControl<boolean>;
    [AirRiskMitigationsCategory.CtrCore]: FormGroup<AirRiskMitigationSectionForm>;
    [AirRiskMitigationsCategory.Ctr]: FormGroup<AirRiskMitigationSectionForm>;
    [AirRiskMitigationsCategory.MctrCore]: FormGroup<AirRiskMitigationSectionForm>;
    [AirRiskMitigationsCategory.Mctr]: FormGroup<AirRiskMitigationSectionForm>;
    [AirRiskMitigationsCategory.Rmz]: FormGroup<AirRiskMitigationSectionForm>;
    [AirRiskMitigationsCategory.AtzCore]: FormGroup<AirRiskMitigationSectionForm>;
    [AirRiskMitigationsCategory.Atz]: FormGroup<AirRiskMitigationSectionForm>;
    [AirRiskMitigationsCategory.Hlz]: FormGroup<AirRiskMitigationSectionForm>;
    [AirRiskMitigationsCategory.EmptyAirspace]: FormGroup<AirRiskMitigationSectionForm>;
    [AirRiskMitigationsCategory.Tma]: FormGroup<AirRiskMitigationSectionForm>;
    [AirRiskMitigationsCategory.Mtma]: FormGroup<AirRiskMitigationSectionForm>;
    [AirRiskMitigationsCategory.Other]: FormGroup<AirRiskMitigationSectionForm>;
    [AirRiskMitigationsCategory.Ignored]: FormGroup<AirRiskMitigationSectionForm>;
}

interface AirRiskMitigationComponentState {
    airRisk: AirRisk | undefined;
    isArcAirspaceRiskDecreased: boolean;
    isArcTooLow: boolean;
    isTmaCollision: boolean;
    sectionsKeysZones: { [key: string]: string[] };
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-air-risk-mitigation",
    templateUrl: "./air-risk-mitigation.component.html",
    styleUrls: ["./air-risk-mitigation.component.scss"],
    providers: [
        LocalComponentStore,
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AirRiskMitigationComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => AirRiskMitigationComponent),
            multi: true,
        },
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [AnimationUtils.slideInAnimation()],
})
export class AirRiskMitigationComponent implements ControlValueAccessor, Validator {
    @Input({ required: true }) public set isArcAirspaceRiskDecreased(value: BooleanInput) {
        this.localStore.patchState({ isArcAirspaceRiskDecreased: coerceBooleanProperty(value) });
    }
    @Input({ required: true }) public set airRisk(value: AirRisk | undefined) {
        const newValue = !value?.elements ? [] : value.elements;
        this.localStore.patchState({
            airRisk: value,
            isTmaCollision: value?.elements.some((element) => element.category === AirRiskMitigationsCategory.Tma),
        });
        this.setVisibility(newValue, value?.operationalEmpty?.initialArc);
    }

    protected readonly airRisk$ = this.localStore.selectByKey("airRisk");
    protected readonly airRiskElements$ = this.airRisk$.pipe(map((airRisk) => airRisk?.elements ?? []));
    protected readonly operationalHeight$ = this.airRisk$.pipe(
        map((airRisk) => airRisk?.operationalHeight ?? AIR_RISK_MITIGATION_MAX_SAFETY_VOLUME_HEIGHT)
    );
    protected readonly sectionsKeysZones$ = this.localStore.selectByKey("sectionsKeysZones");
    protected readonly isFormDisabled$ = combineLatest([
        this.localStore.selectByKey("isArcAirspaceRiskDecreased"),
        this.localStore.selectByKey("isArcTooLow"),
        this.localStore.selectByKey("isTmaCollision"),
    ]).pipe(
        map(([isArcAirspaceRiskDecreased, isArcTooLow, isTmaCollision]) => isArcAirspaceRiskDecreased || isArcTooLow || isTmaCollision)
    );

    protected readonly airRiskMitigationMeasureFormControl = new FormControl(false, { nonNullable: true });
    protected readonly airRiskMitigationFormGroup: FormGroup<AirRiskMitigationForm> = this.buildForm();

    protected sectionsKeys: AirRiskMitigationsCategory[] = [];

    protected readonly methodsKeys = [
        AirRiskMitigationSectionKeys.commonFlightRules,
        AirRiskMitigationSectionKeys.commonAirspaceStructures,
        AirRiskMitigationSectionKeys.timeSlotScheduledFlight,
        AirRiskMitigationSectionKeys.geographicAreaLimitation,
        AirRiskMitigationSectionKeys.limitationOfFlightTime,
    ];

    protected readonly methodsKeysPopoverMap = new Map([
        [AirRiskMitigationSectionKeys.commonFlightRules, "dtmWebAppLibShared.airRiskMitigationSectionKey.commonFlightRulesPopover"],
        [
            AirRiskMitigationSectionKeys.commonAirspaceStructures,
            "dtmWebAppLibShared.airRiskMitigationSectionKey.commonAirspaceStructuresPopover",
        ],
        [
            AirRiskMitigationSectionKeys.timeSlotScheduledFlight,
            "dtmWebAppLibShared.airRiskMitigationSectionKey.timeSlotScheduledFlightPopover",
        ],
        [
            AirRiskMitigationSectionKeys.geographicAreaLimitation,
            "dtmWebAppLibShared.airRiskMitigationSectionKey.geographicAreaLimitationPopover",
        ],
        [
            AirRiskMitigationSectionKeys.limitationOfFlightTime,
            "dtmWebAppLibShared.airRiskMitigationSectionKey.limitationOfFlightTimePopover",
        ],
    ]);

    protected readonly mitigationLevelPopoverMap: Map<AirRiskMitigationsCategory, RiskMitigationPopoverKeys> = new Map([
        [
            AirRiskMitigationsCategory.CtrCore,
            {
                arcB: "dtmWebAppLibShared.riskMitigationCategoryPopover.riskMitigationCommonArea.arcB",
                arcC: "dtmWebAppLibShared.riskMitigationCategoryPopover.riskMitigationCommonArea.arcC",
            },
        ],
        [
            AirRiskMitigationsCategory.Ctr,
            {
                arcB: "dtmWebAppLibShared.riskMitigationCategoryPopover.riskMitigationCommonArea.arcB",
                arcC: "dtmWebAppLibShared.riskMitigationCategoryPopover.riskMitigationCommonArea.arcC",
            },
        ],
        [
            AirRiskMitigationsCategory.MctrCore,
            {
                arcB: "dtmWebAppLibShared.riskMitigationCategoryPopover.riskMitigationCommonArea.arcB",
                arcC: "dtmWebAppLibShared.riskMitigationCategoryPopover.riskMitigationCommonArea.arcC",
            },
        ],
        [
            AirRiskMitigationsCategory.Mctr,
            {
                arcB: "dtmWebAppLibShared.riskMitigationCategoryPopover.riskMitigationCommonArea.arcB",
                arcC: "dtmWebAppLibShared.riskMitigationCategoryPopover.riskMitigationCommonArea.arcC",
            },
        ],
        [
            AirRiskMitigationsCategory.Rmz,
            {
                arcB: "dtmWebAppLibShared.riskMitigationCategoryPopover.riskMitigationCommonArea.arcB",
                arcC: "dtmWebAppLibShared.riskMitigationCategoryPopover.riskMitigationCommonArea.arcC",
            },
        ],
        [
            AirRiskMitigationsCategory.AtzCore,
            {
                arcB: "dtmWebAppLibShared.riskMitigationCategoryPopover.riskMitigationCommonArea.arcB",
                arcC: "dtmWebAppLibShared.riskMitigationCategoryPopover.riskMitigationCommonArea.arcC",
            },
        ],
        [AirRiskMitigationsCategory.Atz, { arcB: "dtmWebAppLibShared.riskMitigationCategoryPopover.other.arcB" }],
        [AirRiskMitigationsCategory.Hlz, { arcB: "dtmWebAppLibShared.riskMitigationCategoryPopover.other.arcB" }],
        [AirRiskMitigationsCategory.Other, { arcB: "dtmWebAppLibShared.riskMitigationCategoryPopover.other.arcB" }],
    ]);

    protected readonly AirRiskMitigationsCategory = AirRiskMitigationsCategory;
    protected readonly MAX_COMMENT_LENGTH = MAX_COMMENT_LENGTH;

    private propagateTouch = FunctionUtils.noop;
    private onValidationChange = FunctionUtils.noop;
    private propagateChange: (value: AirRiskMitigations | null) => void = FunctionUtils.noop;

    constructor(private readonly localStore: LocalComponentStore<AirRiskMitigationComponentState>) {
        this.localStore.setState({
            airRisk: undefined,
            isArcAirspaceRiskDecreased: false,
            isArcTooLow: false,
            isTmaCollision: false,
            sectionsKeysZones: {},
        });
        this.setupAirRiskMitigationChangesWatch();
    }

    public writeValue(value: AirRiskMitigations | null): void {
        if (!value) {
            this.airRiskMitigationFormGroup.reset(undefined, { emitEvent: false });

            return;
        }

        const mitigatingMeasures = value.mitigatingMeasures.filter(({ userStatements }) => !!userStatements.length);
        if (mitigatingMeasures.length) {
            const newValue: AirRiskMitigationWriteValue = {
                riskMitigationMeasure: true,
            };
            mitigatingMeasures.forEach((mitigatingMeasure) => {
                const sectionKey = mitigatingMeasure.userStatements[0];
                newValue[sectionKey] = {
                    isSelected: true,
                    airRiskClassMitigation: mitigatingMeasure.arc,
                    methods: {},
                };

                mitigatingMeasure.methods.forEach(({ title: methodKey, details }) => {
                    const section = newValue[sectionKey] as {
                        methods: { [methodKey: string]: { methodCheckbox: boolean; methodTextarea: string } };
                    };
                    section.methods[methodKey] = { methodCheckbox: true, methodTextarea: details };
                });
            });
            this.airRiskMitigationFormGroup.reset(newValue, { emitEvent: false });
        }
    }

    public registerOnChange(fn: (value: AirRiskMitigations | null) => void): void {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.propagateTouch = fn;
    }

    public setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.airRiskMitigationFormGroup.disable({ emitEvent: false });
        } else {
            this.airRiskMitigationFormGroup.enable({ emitEvent: false });
        }
    }

    public registerOnValidatorChange(fn: () => void): void {
        this.onValidationChange = fn;
    }

    public validate(): ValidationErrors | null {
        if (!this.airRiskMitigationFormGroup.value || this.airRiskMitigationFormGroup.valid) {
            return null;
        }

        return {
            AirRiskMitigations: true,
        };
    }

    protected getMethodPopoverKey(methodKey: AirRiskMitigationSectionKeys, popoverMap: Map<AirRiskMitigationSectionKeys, string>): string {
        return popoverMap.get(methodKey) ?? "";
    }

    protected getAirRiskMitigationPopoverKeys(
        sectionKey: AirRiskMitigationsCategory,
        popoverMap: Map<AirRiskMitigationsCategory, RiskMitigationPopoverKeys>
    ): RiskMitigationPopoverKeys | undefined {
        return popoverMap.get(sectionKey);
    }

    protected getMethodFormGroup = (sectionKey: keyof AirRiskMitigationForm) =>
        (this.airRiskMitigationFormGroup.controls[sectionKey] as FormGroup).controls.methods as FormGroup;

    protected riskMitigationChange(value: boolean) {
        if (value) {
            this.airRiskMitigationMeasureFormControl.setValue(value);
        } else {
            this.airRiskMitigationFormGroup.reset();
        }
    }

    protected getZones(zones: string[]): string {
        return zones.length ? zones.join(", ") : "";
    }

    protected getAirRiskMitigationLevel(sectionKey: AirRiskMitigationsCategory, airRisk: AirRisk): AirRiskMitigationsArc | undefined {
        return airRisk.elements?.find(({ category }) => category === sectionKey)?.initialArc ?? airRisk.operationalEmpty?.initialArc;
    }

    protected hasSectionSubtitleText(key: AirRiskMitigationsCategory, sections: AirRiskMitigationsCategory[]): boolean {
        return sections.includes(key);
    }

    protected getInitialArc(
        key: AirRiskMitigationsCategory,
        airRisk: AirRisk | undefined,
        airRiskElements: AirRiskElement[]
    ): AirRiskMitigationsArc | undefined {
        const initialArc = airRiskElements.find((element) => element.category === key)?.initialArc;

        if (!initialArc || initialArc === AirRiskMitigationsArc.B) {
            return airRisk?.operationalEmpty?.initialArc;
        }

        return initialArc;
    }

    protected shouldShowMethod(sectionKey: AirRiskMitigationsCategory, operationalHeight: number, methodIndex: number): boolean {
        const hiddenMethodsKeys = [AirRiskMitigationsCategory.Hlz, AirRiskMitigationsCategory.CtrCore, AirRiskMitigationsCategory.MctrCore];

        if (hiddenMethodsKeys.includes(sectionKey) || operationalHeight > AIR_RISK_MITIGATION_MAX_SAFETY_VOLUME_HEIGHT) {
            return methodIndex >= NUMBER_OF_HIDDEN_METHODS;
        }

        return true;
    }

    private setVisibility(elements: AirRiskElement[], emptyAirspaceInitialArc = AirRiskMitigationsArc.B) {
        const sectionsKeys = new Set<AirRiskMitigationsCategory>([]);
        const sectionsKeysZones: { [key: string]: string[] } = {};
        let isArcTooLow = true;

        elements
            .filter(
                ({ category, designator, initialArc }) =>
                    (!!designator || category === AirRiskMitigationsCategory.Other) &&
                    AIR_RISK_MITIGATIONS_CATEGORIES.includes(category) &&
                    initialArc !== AirRiskMitigationsArc.B
            )
            .forEach((element) => {
                sectionsKeys.add(element.category);
                if (!sectionsKeysZones[element.category]) {
                    sectionsKeysZones[element.category] = [];
                }
                sectionsKeysZones[element.category].push(element.designator);

                if (element.initialArc !== AirRiskMitigationsArc.B) {
                    isArcTooLow = false;
                }
            });

        const isNoSignificantElements = !elements.some(({ category }) => category !== AirRiskMitigationsCategory.Ignored);

        if (isNoSignificantElements && emptyAirspaceInitialArc !== AirRiskMitigationsArc.B) {
            sectionsKeys.add(AirRiskMitigationsCategory.EmptyAirspace);
            sectionsKeysZones[AirRiskMitigationsCategory.EmptyAirspace] = [];
            isArcTooLow = false;
        }

        this.sectionsKeys = Array.from(sectionsKeys);
        this.localStore.patchState({ sectionsKeysZones, isArcTooLow });
    }

    private buildForm(): FormGroup<AirRiskMitigationForm> {
        const sections = {
            [AirRiskMitigationsCategory.CtrCore]: this.buildSectionForm(),
            [AirRiskMitigationsCategory.Ctr]: this.buildSectionForm(),
            [AirRiskMitigationsCategory.MctrCore]: this.buildSectionForm(),
            [AirRiskMitigationsCategory.Mctr]: this.buildSectionForm(),
            [AirRiskMitigationsCategory.Rmz]: this.buildSectionForm(),
            [AirRiskMitigationsCategory.AtzCore]: this.buildSectionForm(),
            [AirRiskMitigationsCategory.Atz]: this.buildSectionForm(),
            [AirRiskMitigationsCategory.Hlz]: this.buildSectionForm(),
            [AirRiskMitigationsCategory.EmptyAirspace]: this.buildSectionForm(),
            [AirRiskMitigationsCategory.Tma]: this.buildSectionForm(),
            [AirRiskMitigationsCategory.Mtma]: this.buildSectionForm(),
            [AirRiskMitigationsCategory.Other]: this.buildSectionForm(),
            [AirRiskMitigationsCategory.Ignored]: this.buildSectionForm(),
        };

        return new FormGroup<AirRiskMitigationForm>(
            {
                riskMitigationMeasure: this.airRiskMitigationMeasureFormControl,
                ...sections,
            },
            this.requiredOneForControlValidator(
                this.airRiskMitigationMeasureFormControl,
                ...Object.values(sections).map((data) => data.controls.isSelected)
            )
        );
    }

    private buildSectionForm(): FormGroup<AirRiskMitigationSectionForm> {
        const commonFlightRules = { [AirRiskMitigationSectionKeys.commonFlightRules]: this.buildSection() };
        const commonAirspaceStructures = { [AirRiskMitigationSectionKeys.commonAirspaceStructures]: this.buildSection() };
        const timeSlotScheduledFlight = { [AirRiskMitigationSectionKeys.timeSlotScheduledFlight]: this.buildSection() };
        const geographicAreaLimitation = { [AirRiskMitigationSectionKeys.geographicAreaLimitation]: this.buildSection() };
        const limitationOfFlightTime = { [AirRiskMitigationSectionKeys.limitationOfFlightTime]: this.buildSection() };
        const isSelected = new FormControl(false, { nonNullable: true });

        const requiredOneValidator = this.requiredOneForControlValidator(
            isSelected,
            commonFlightRules[AirRiskMitigationSectionKeys.commonFlightRules].controls.methodCheckbox,
            commonAirspaceStructures[AirRiskMitigationSectionKeys.commonAirspaceStructures].controls.methodCheckbox,
            timeSlotScheduledFlight[AirRiskMitigationSectionKeys.timeSlotScheduledFlight].controls.methodCheckbox,
            geographicAreaLimitation[AirRiskMitigationSectionKeys.geographicAreaLimitation].controls.methodCheckbox,
            limitationOfFlightTime[AirRiskMitigationSectionKeys.limitationOfFlightTime].controls.methodCheckbox
        );

        return new FormGroup(
            {
                isSelected: isSelected,
                airRiskClassMitigation: new FormControl<AirRiskMitigationsArc | null>(AirRiskMitigationsArc.B, {
                    nonNullable: true,
                    validators: [this.requiredFormControlValidator(isSelected)],
                }),
                methods: new FormGroup(
                    {
                        ...commonFlightRules,
                        ...commonAirspaceStructures,
                        ...timeSlotScheduledFlight,
                        ...geographicAreaLimitation,
                        ...limitationOfFlightTime,
                    },
                    [this.methodsValidator(isSelected)]
                ),
            },
            requiredOneValidator
        );
    }

    private buildSection(): MethodFormGroup {
        const checkbox = new FormControl(false, {
            nonNullable: true,
        });

        return new FormGroup({
            methodCheckbox: checkbox,
            methodTextarea: new FormControl<string | null>(null, [
                this.requiredFormControlValidator(checkbox),
                Validators.maxLength(MAX_COMMENT_LENGTH),
            ]),
        });
    }

    private setupAirRiskMitigationChangesWatch() {
        this.airRiskMitigationFormGroup.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
            if (!value.riskMitigationMeasure) {
                this.propagateChange(null);

                return;
            }

            Object.values(this.airRiskMitigationFormGroup.controls).forEach((control) => {
                control.updateValueAndValidity({ emitEvent: false });
            });

            if (this.airRiskMitigationFormGroup.invalid) {
                this.onValidationChange();

                return;
            }

            const formValue = this.airRiskMitigationFormGroup.value;
            const airRiskMitigationValue: AirRiskMitigations = {
                mitigatingMeasures: this.sectionsKeys
                    .filter((sectionKey) => formValue[sectionKey]?.isSelected)
                    .map((sectionKey) => ({
                        arc: formValue[sectionKey]?.airRiskClassMitigation ?? AirRiskMitigationsArc.B,
                        category: sectionKey,
                        userStatements: [sectionKey],
                        methods: this.methodsKeys
                            .filter((methodKey) => formValue[sectionKey]?.methods?.[methodKey]?.methodCheckbox)
                            .map((methodKey) => ({
                                title: methodKey,
                                details: formValue[sectionKey]?.methods?.[methodKey]?.methodTextarea as string,
                            })),
                    })),
            };

            this.propagateChange(airRiskMitigationValue);
        });
    }

    private requiredFormControlValidator(formControl: FormControl): ValidatorFn {
        return (control) => (formControl.value ? Validators.required(control) : null);
    }

    private requiredOneForControlValidator(isSelectedControl: FormControl<boolean>, ...formControls: FormControl<boolean>[]): ValidatorFn {
        return () => {
            if (isSelectedControl.value && !formControls.some((form) => FunctionUtils.isTruthy(form.value))) {
                return {
                    [ONE_REQUIRED_VALIDATION_NAME]: true,
                };
            }

            return null;
        };
    }

    private methodsValidator(isSelectedControl: FormControl<boolean>): ValidatorFn {
        return (control) => {
            if (!isSelectedControl.value) {
                return null;
            }

            const methods = Object.values(control.value) as Array<{ methodCheckbox: boolean }>;
            const isError = !methods.some(({ methodCheckbox }) => methodCheckbox);

            if (isError) {
                return {
                    [ONE_REQUIRED_VALIDATION_NAME]: true,
                };
            }

            return null;
        };
    }
}
