<ng-container *ngrxLet="isProcessing$ as isProcessing">
    <div class="dialog-header">
        <h4 mat-dialog-title>{{ "dtmWebAppMembership.userInvitation.header" | transloco }}</h4>
        <button type="button" class="button-icon" [matDialogClose]="null" [disabled]="isProcessing">
            <dtm-ui-icon name="close"></dtm-ui-icon>
        </button>
    </div>

    <form [formGroup]="invitationForm" dtmUiInvalidFormScrollable>
        <div class="form-sections scroll-shadows" mat-dialog-content>
            <p class="info" [innerHTML]="'dtmWebAppMembership.userInvitation.invitationMeanInfo' | transloco"></p>

            <dtm-ui-radio-tile-group [formControl]="invitationMeanControl">
                <dtm-ui-radio-tile [value]="InvitationMean.MAIL">
                    <dtm-ui-icon name="mail"></dtm-ui-icon>
                    <p class="selectable-tile-label">
                        {{ "dtmWebAppMembership.userInvitation.invitationEmailLabel" | transloco }}
                    </p>
                </dtm-ui-radio-tile>
                <dtm-ui-radio-tile [value]="InvitationMean.PHONE">
                    <dtm-ui-icon name="phone"></dtm-ui-icon>
                    <p class="selectable-tile-label">
                        {{ "dtmWebAppMembership.userInvitation.invitationPhoneLabel" | transloco }}
                    </p>
                </dtm-ui-radio-tile>
            </dtm-ui-radio-tile-group>

            <ng-container *ngIf="invitationForm.controls['mail']">
                <dtm-ui-input-field>
                    <label>{{ "dtmWebAppMembership.userInvitation.invitationEmailLabel" | transloco }}</label>
                    <input matInput type="text" [formControl]="mailControl" />
                    <div class="field-error" *dtmUiFieldHasError="mailControl; name: 'required'">
                        {{ "dtmWebAppMembership.userInvitation.requiredError" | transloco }}
                    </div>
                    <div class="field-error" *dtmUiFieldHasError="mailControl; name: 'email'">
                        {{ "dtmWebAppMembership.userInvitation.invalidEmailError" | transloco }}
                    </div>
                </dtm-ui-input-field>
            </ng-container>
            <ng-container *ngIf="invitationForm.controls['phoneNumber']">
                <dtm-ui-phone-number-field [formControl]="phoneNumberControl">
                    <div class="field-error" *dtmUiFieldHasError="phoneNumberControl; name: 'required'">
                        {{ "dtmWebAppMembership.userInvitation.requiredError" | transloco }}
                    </div>
                    <div class="field-error" *dtmUiFieldHasError="phoneNumberControl; name: 'invalidNumber'">
                        {{ "dtmWebAppMembership.userInvitation.invalidPhoneError" | transloco }}
                    </div>
                    <div class="field-error" *dtmUiFieldHasError="phoneNumberControl; name: 'invalidNumberType'">
                        {{ "dtmWebAppMembership.userInvitation.phoneNumberNotAllowedError" | transloco }}
                    </div>
                </dtm-ui-phone-number-field>
            </ng-container>

            <div class="permissions-container" formGroupName="permissions">
                <p class="role-label">{{ "dtmWebAppMembership.userInvitation.permissionFieldLabel" | transloco }}</p>
                <div *ngFor="let permission of MembershipPermission | keyvalue : keepOrder">
                    <mat-slide-toggle class="spread" [formControlName]="permission.value" labelPosition="before">
                        {{ "dtmWebAppMembership.userInvitation.permissionLabel" | transloco : { permission: permission.value } }}
                    </mat-slide-toggle>

                    <ng-container [ngSwitch]="permission.value">
                        <ul class="role-description" *ngSwitchCase="MembershipPermission.OperatorManage">
                            <li *ngFor="let operatorManageDescriptionItem of OperatorManageDescriptionItem | keyvalue : keepOrder">
                                <dtm-ui-icon name="check"></dtm-ui-icon>
                                {{
                                    "dtmWebAppMembership.userInvitation.operatorManageDescriptionItemValue"
                                        | transloco : { value: operatorManageDescriptionItem.key }
                                }}
                            </li>
                        </ul>
                        <ul class="role-description" *ngSwitchCase="MembershipPermission.MissionPlanning">
                            <li *ngFor="let missionPlanningDescriptionItem of MissionPlanningDescriptionItem | keyvalue : keepOrder">
                                <dtm-ui-icon name="check"></dtm-ui-icon>
                                {{
                                    "dtmWebAppMembership.userInvitation.missionPlanningDescriptionItemValue"
                                        | transloco : { value: missionPlanningDescriptionItem.key }
                                }}
                            </li>
                        </ul>
                        <ul class="role-description" *ngSwitchCase="MembershipPermission.UavPiloting">
                            <li *ngFor="let uavPilotingDescriptionItem of UavPilotingDescriptionItem | keyvalue : keepOrder">
                                <dtm-ui-icon name="check"></dtm-ui-icon>
                                {{
                                    "dtmWebAppMembership.userInvitation.uavPilotingDescriptionItemValue"
                                        | transloco : { value: uavPilotingDescriptionItem.key }
                                }}
                            </li>
                        </ul>
                        <ul class="role-description" *ngSwitchCase="MembershipPermission.FleetManagement">
                            <li *ngFor="let fleetManagementDescriptionItem of FleetManagementDescriptionItem | keyvalue : keepOrder">
                                <dtm-ui-icon name="check"></dtm-ui-icon>
                                {{
                                    "dtmWebAppMembership.userInvitation.fleetManagementDescriptionItemValue"
                                        | transloco : { value: fleetManagementDescriptionItem.key }
                                }}
                            </li>
                        </ul>
                    </ng-container>
                </div>

                <div class="required-one-error" *dtmUiFieldHasError="permissionsControl; name: 'requiredAtLeastOnePermission'">
                    <dtm-ui-icon name="error-warning"></dtm-ui-icon>
                    {{ "dtmWebAppMembership.userInvitation.permissionRequiredError" | transloco }}
                </div>
            </div>

            <dtm-ui-checkbox-field [formControl]="showNoteControl" (change)="showNote($event.checked)">
                {{ "dtmWebAppMembership.userInvitation.addNoteLabel" | transloco }}
            </dtm-ui-checkbox-field>

            <ng-container *ngIf="showNoteControl.value">
                <dtm-ui-textarea-field [maxLength]="MAX_NOTE_LENGTH">
                    <label>{{ "dtmWebAppMembership.userInvitation.noteForUserLabel" | transloco }}</label>
                    <textarea matInput [formControl]="noteControl"></textarea>
                    <div class="field-error" *dtmUiFieldHasError="noteControl; name: 'maxlength'; error as error">
                        {{
                            "dtmWebAppMembership.userInvitation.noteForUserMaxLengthError" | transloco : { maxLength: error.requiredLength }
                        }}
                    </div>
                    <div class="field-error" *dtmUiFieldHasError="noteControl; name: 'required'">
                        {{ "dtmWebAppMembership.userInvitation.requiredError" | transloco }}
                    </div>
                </dtm-ui-textarea-field>
            </ng-container>
        </div>
    </form>
    <div class="action-buttons" mat-dialog-actions>
        <button type="button" class="button-secondary" (click)="cancel()" [disabled]="isProcessing">
            {{ "dtmWebAppMembership.userInvitation.cancelLabel" | transloco }}
        </button>
        <button type="submit" class="button-primary" (click)="prepareInvitation()" [disabled]="isProcessing">
            {{ "dtmWebAppMembership.userInvitation.inviteLabel" | transloco }}
        </button>
    </div>
</ng-container>
