<div *ngIf="error$ | ngrxPush as error; else userProfileTemplate" class="grid grid-maxsize-6">
    <div *ngIf="error.type === UserProfileErrorType.NationalNodeUserMinAgeRequired; else profileUnknownTemplate" class="min-age-required">
        <img src="assets/images/eye-closed.svg" alt="" class="min-age-required-image" />
        <p class="min-age-required-title">
            {{ "dtmWebAppUserProfile.nationalNodeUserProfile.nationalNodeUserMinAgeRequiredHeader" | transloco }}
        </p>
        <p class="min-age-required-message">
            {{
                "dtmWebAppUserProfile.nationalNodeUserProfile.nationalNodeUserMinAgeRequiredMessage" | transloco : { minAge: MIN_USER_AGE }
            }}
        </p>
        <button type="button" class="button-primary" (click)="logout()">
            {{ "dtmWebAppUserProfile.nationalNodeUserProfile.logoutButtonLabel" | transloco }}
        </button>
    </div>

    <ng-template #profileUnknownTemplate>
        <dtm-ui-error
            [mode]="ErrorMode.Secondary"
            [errorMessage]="'dtmWebAppUserProfile.nationalNodeUserProfile.profileUnknownErrorMessage' | transloco"
        ></dtm-ui-error>
    </ng-template>
</div>

<ng-template #userProfileTemplate>
    <dtm-ui-loader-container [isShown]="isProcessing$ | ngrxPush" *ngrxLet="userRegistration$ as userRegistration">
        <div class="grid grid-maxsize-6">
            <mat-card>
                <dtm-ui-card-header>
                    {{ "dtmWebAppUserProfile.userContact.contactDataHeader" | transloco }}
                </dtm-ui-card-header>
                <p class="subtext">
                    {{ "dtmWebAppUserProfile.nationalNodeUserProfile.informationLabel" | transloco }}
                </p>
                <form class="user-data-edit-form" [formGroup]="userEditForm">
                    <div class="box" *ngrxLet="{ isRequestedEmailChange: isRequestedEmailChange$, currentEmail: currentEmail$ } as vm">
                        <div *ngIf="!userRegistration?.isEmailVerified">
                            <h2>
                                {{ "dtmWebAppUserProfile.nationalNodeUserProfile.emailAddressHeader" | transloco }}
                            </h2>
                            <dtm-ui-edit-email-address-form
                                [currentEmail]="vm.currentEmail"
                                [isRequestedEmailChange]="vm.isRequestedEmailChange"
                                [isVerificationRequired]="true"
                                [emailConfirmationDescription]="
                                    'dtmWebAppUserProfile.nationalNodeUserProfile.emailConfirmationDescription' | transloco
                                "
                                [formControl]="emailAddressFormControl"
                                [hasEmailAddressConflictError]="hasEmailAddressConflictError$ | ngrxPush"
                                (resendVerificationCode)="resendEmailVerificationCode()"
                                dtmUiMarkValueAccessorControlsAsTouched
                            ></dtm-ui-edit-email-address-form>
                            <div class="buttons" [class.top-margin]="vm.isRequestedEmailChange">
                                <button
                                    type="button"
                                    class="button-secondary"
                                    *ngIf="vm.isRequestedEmailChange"
                                    (click)="cancelEmailVerification()"
                                >
                                    {{ "dtmWebAppUserProfile.nationalNodeUserProfile.cancelButtonLabel" | transloco }}
                                </button>
                                <button type="button" class="button-primary" (click)="requestUserEmailChange()">
                                    {{
                                        (vm.isRequestedEmailChange
                                            ? "dtmWebAppUserProfile.nationalNodeUserProfile.verifyButtonLabel"
                                            : "dtmWebAppUserProfile.nationalNodeUserProfile.sendCodeButtonLabel"
                                        ) | transloco
                                    }}
                                </button>
                            </div>
                        </div>
                        <dtm-ui-info-message type="success" *ngIf="userRegistration?.isEmailVerified">
                            {{
                                "dtmWebAppUserProfile.nationalNodeUserProfile.emailAddressVerifiedLabel"
                                    | transloco : { email: userRegistration?.email }
                            }}
                        </dtm-ui-info-message>
                    </div>
                    <div
                        class="box"
                        *ngrxLet="{
                            isRequestedPhoneNumberChange: isRequestedPhoneNumberChange$,
                            currentPhoneNumber: currentPhoneNumber$
                        } as vm"
                    >
                        <div *ngIf="!userRegistration?.isPhoneNumberVerified">
                            <h2>{{ "dtmWebAppUserProfile.nationalNodeUserProfile.phoneNumberHeader" | transloco }}</h2>
                            <dtm-ui-edit-phone-number-form
                                [currentPhoneNumber]="vm.currentPhoneNumber"
                                [isRequestedPhoneChange]="vm.isRequestedPhoneNumberChange"
                                [phoneNumberChangeDescription]="
                                    'dtmWebAppUserProfile.nationalNodeUserProfile.phoneNumberChangeDescription' | transloco
                                "
                                [isVerificationRequired]="true"
                                [formControl]="phoneNumberFormControl"
                                [hasPhoneNumberConflictError]="hasPhoneNumberConflictError$ | ngrxPush"
                                [tooManySmsCodeVerificationRequestDate]="tooManySmsCodeVerificationRequestDate$ | ngrxPush"
                                (resendVerificationCode)="resendPhoneNumberVerificationCode()"
                                dtmUiMarkValueAccessorControlsAsTouched
                            ></dtm-ui-edit-phone-number-form>
                            <div class="buttons" [class.top-margin]="vm.isRequestedPhoneNumberChange">
                                <button
                                    type="button"
                                    class="button-secondary"
                                    *ngIf="vm.isRequestedPhoneNumberChange"
                                    (click)="cancelPhoneNumberVerification()"
                                >
                                    {{ "dtmWebAppUserProfile.nationalNodeUserProfile.cancelButtonLabel" | transloco }}
                                </button>
                                <button type="button" class="button-primary" (click)="requestUserPhoneNumberChange()">
                                    {{
                                        (vm.isRequestedPhoneNumberChange
                                            ? "dtmWebAppUserProfile.nationalNodeUserProfile.verifyButtonLabel"
                                            : "dtmWebAppUserProfile.nationalNodeUserProfile.sendCodeButtonLabel"
                                        ) | transloco
                                    }}
                                </button>
                            </div>
                        </div>
                        <dtm-ui-info-message type="success" *ngIf="userRegistration?.isPhoneNumberVerified">
                            {{
                                "dtmWebAppUserProfile.nationalNodeUserProfile.phoneNumberVerifiedLabel"
                                    | transloco : { phoneNumber: userRegistration?.phoneNumber | formatPhoneNumber }
                            }}
                        </dtm-ui-info-message>
                    </div>
                    <dtm-ui-checkbox-field [formControl]="termsFormControl" required class="checkbox-with-button">
                        <span class="label">{{ "dtmWebAppUserProfile.nationalNodeUserProfile.acceptLabel" | transloco }}</span>
                        <a
                            [href]="termsOfUseUrl"
                            rel="noopener noreferrer"
                            target="_blank"
                            class="button-link custom"
                            (click)="$event.stopPropagation()"
                            >{{ "dtmWebAppUserProfile.nationalNodeUserProfile.termsOfUseLabel" | transloco }}</a
                        >
                        <dtm-ui-form-errors class="preserve-error-space">
                            <div class="field-error" *dtmUiFieldHasError="termsFormControl; name: 'required'">
                                {{ "dtmWebAppUserProfile.nationalNodeUserProfile.requiredFieldErrorHint" | transloco }}
                            </div>
                        </dtm-ui-form-errors>
                    </dtm-ui-checkbox-field>
                    <button
                        type="button"
                        class="button-primary"
                        [disabled]="!(userRegistration?.isEmailVerified && userRegistration?.isPhoneNumberVerified)"
                        (click)="registerUser()"
                    >
                        {{ "dtmWebAppUserProfile.nationalNodeUserProfile.acceptButtonLabel" | transloco }}
                    </button>
                </form>
            </mat-card>
        </div>
    </dtm-ui-loader-container>
</ng-template>
