import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot } from "@angular/router";
import { RxjsUtils } from "@dtm-frontend/shared/utils";
import { Store } from "@ngxs/store";
import { lastValueFrom } from "rxjs";
import { first, switchMap } from "rxjs/operators";
import { OperatorContextState } from "../../shared/operator-context/state/operator-context.state";
import { SpecificPermitApplicationActions } from "../state/specific-permit-application.actions";
import { SpecificPermitApplicationState } from "../state/specific-permit-application.state";

export const OPERATION_ID_QUERY_PARAM = "operationId";

@Injectable()
export class ApplicationCapabilitiesResolver {
    constructor(private readonly store: Store) {}

    public resolve() {
        return this.store.select(OperatorContextState.selectedContextId).pipe(
            RxjsUtils.filterFalsy(),
            first(),
            switchMap((operatorId) => this.store.dispatch(new SpecificPermitApplicationActions.GetCapabilities(operatorId)))
        );
    }
}

@Injectable()
export class ApplicationDraftResolver {
    constructor(private readonly store: Store) {}

    public resolve(route: ActivatedRouteSnapshot) {
        const draftId = route.params.draftId;

        return lastValueFrom(
            this.store.dispatch(new SpecificPermitApplicationActions.GetDraft(draftId)).pipe(
                switchMap(() => {
                    const operationInfoData = this.store.selectSnapshot(SpecificPermitApplicationState.operationInfoData);
                    if (!operationInfoData?.operation?.id) {
                        return Promise.resolve();
                    }

                    return this.store.dispatch([
                        new SpecificPermitApplicationActions.GetOperation(operationInfoData?.operation?.id),
                        new SpecificPermitApplicationActions.GetOperationalGeometryData(operationInfoData?.operation?.id),
                    ]);
                })
            )
        );
    }
}
