import { ChangeDetectionStrategy, Component } from "@angular/core";
import { GlobalFeatures, OperatorType, UIState } from "@dtm-frontend/shared/ui";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { map, withLatestFrom } from "rxjs/operators";
import { OperatorContextState } from "../../../shared/operator-context/state/operator-context.state";
import { DashboardMissionsFilters } from "../../services/dashboard.models";
import { DashboardActions } from "../../state/dashboard.actions";
import { DashboardState } from "../../state/dashboard.state";

interface DashboardContainerComponentState {
    missionsFilters: DashboardMissionsFilters;
}

@UntilDestroy()
@Component({
    templateUrl: "./dashboard-container.component.html",
    styleUrls: ["./dashboard-container.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class DashboardContainerComponent {
    protected readonly isMissionPlanningFeatureAvailable$ = this.store.select(
        OperatorContextState.isFeatureAvailable(GlobalFeatures.MissionPlanner)
    );
    protected readonly operators$ = this.store.select(OperatorContextState.operatorsSortedByName);
    protected readonly pilot$ = this.store.select(OperatorContextState.pilot);
    protected readonly operatorId$ = this.store.select(OperatorContextState.selectedOperatorContextId).pipe(RxjsUtils.filterFalsy());
    protected readonly activeLanguage$ = this.store.select(UIState.activeLanguage);
    protected readonly posts$ = this.store.select(DashboardState.posts);
    protected readonly isProcessing$ = this.store.select(DashboardState.isProcessing);
    protected readonly postsError$ = this.store.select(DashboardState.postsError);
    protected readonly missions$ = this.store.select(DashboardState.missions);
    protected readonly totalMissionElements$ = this.store.select(DashboardState.totalMissionElements);
    protected readonly missionsError$ = this.store.select(DashboardState.missionsError);
    protected readonly areMissionsProcessing$ = this.store.select(DashboardState.areMissionsProcessing);
    protected readonly isOperatorContext$ = this.store
        .select(OperatorContextState.selectedContext)
        .pipe(map((context) => context?.type === OperatorType.Enterprise));
    protected readonly missionsFilters$ = this.localStore.selectByKey("missionsFilters");

    constructor(private readonly store: Store, private readonly localStore: LocalComponentStore<DashboardContainerComponentState>) {
        this.localStore.setState({
            missionsFilters: { searchByText: null },
        });
        this.getPosts();
        this.onOperatorContextChange();
    }

    protected getMissions(filters?: DashboardMissionsFilters) {
        this.store.dispatch(new DashboardActions.GetMissions(filters));
    }

    private getPosts() {
        this.store.dispatch(new DashboardActions.GetPosts());
    }

    private onOperatorContextChange() {
        this.operatorId$
            .pipe(withLatestFrom(this.isMissionPlanningFeatureAvailable$), untilDestroyed(this))
            .subscribe(([, isMissionPlanningFeatureAvailable]: [string, boolean | undefined]) => {
                this.localStore.patchState({ missionsFilters: { searchByText: null } });
                if (isMissionPlanningFeatureAvailable) {
                    this.getMissions();
                }
            });
    }
}
