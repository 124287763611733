<dtm-ui-loader-container [isShown]="isCapabilitiesProcessing$ | ngrxPush">
    <dtm-ui-card-header [isEditButtonVisible]="false">
        <button additionalActions type="button" class="button-icon" (click)="closeForm()">
            <dtm-ui-icon name="close"></dtm-ui-icon>
        </button>
        {{ "dtmWebAppOperatorProfile.attorneyPowers.addAttorneyPowerLabel" | transloco }}
    </dtm-ui-card-header>
    <form [formGroup]="attorneyPowerForm" (ngSubmit)="saveForm()">
        <div class="form-container" *ngrxLet="capabilities$ as capabilities">
            <ng-container *ngIf="capabilities.members.length; else noMembersTemplate">
                <dtm-ui-select-field [formControl]="memberControl">
                    <label>
                        {{ "dtmWebAppOperatorProfile.attorneyPowers.form.chooseMemberLabel" | transloco }}
                        <dtm-ui-popover
                            [popoverText]="'dtmWebAppOperatorProfile.attorneyPowers.form.chooseMemberPopover' | transloco"
                        ></dtm-ui-popover>
                    </label>
                    <dtm-ui-select-option *ngFor="let member of capabilities.members" [value]="member">
                        {{ member.firstName }} {{ member.lastName }}
                    </dtm-ui-select-option>
                    <div class="field-error" *dtmUiFieldHasError="memberControl; name: 'required'">
                        {{ "dtmWebAppOperatorProfile.attorneyPowers.form.requiredFieldError" | transloco }}
                    </div>
                </dtm-ui-select-field>
                <dtm-ui-radio-group
                    *ngIf="memberControl.value"
                    [formControl]="isCompanyOwnerControl"
                    (change)="changeCompanyOwnerValue($event.value)"
                    @slideIn
                >
                    <label class="label">
                        {{ "dtmWebAppOperatorProfile.attorneyPowers.form.isCompanyOwnerLabel" | transloco }}
                    </label>
                    <div class="radio-content">
                        <dtm-ui-radio-field [value]="true">
                            {{ "dtmWebAppOperatorProfile.attorneyPowers.form.yesLabel" | transloco }}
                        </dtm-ui-radio-field>
                        <dtm-ui-radio-field [value]="false">
                            {{ "dtmWebAppOperatorProfile.attorneyPowers.form.noLabel" | transloco }}
                        </dtm-ui-radio-field>
                    </div>
                    <dtm-ui-form-errors class="preserve-error-space">
                        <div class="field-error" *dtmUiFieldHasError="isCompanyOwnerControl; name: 'required'">
                            {{ "dtmWebAppOperatorProfile.attorneyPowers.form.requiredFieldError" | transloco }}
                        </div>
                    </dtm-ui-form-errors>
                </dtm-ui-radio-group>
                <div *ngIf="attorneyPowerForm.controls.type" @slideIn>
                    <dtm-ui-radio-group [formControl]="typeControl" (change)="changeTypeValue($event.value)">
                        <label class="label">
                            {{ "dtmWebAppOperatorProfile.attorneyPowers.form.attorneyTypeFormLabel" | transloco }}
                        </label>
                        <div class="radio-content">
                            <dtm-ui-radio-field [value]="AttorneyPowerType.NationalCourtNumber">
                                {{
                                    "dtmWebAppOperatorProfile.attorneyPowers.form.attorneyTypeFormValue"
                                        | transloco : { type: AttorneyPowerType.NationalCourtNumber }
                                }}
                            </dtm-ui-radio-field>
                            <dtm-ui-radio-field [value]="AttorneyPowerType.ConfirmatoryDocument">
                                {{
                                    "dtmWebAppOperatorProfile.attorneyPowers.form.attorneyTypeFormValue"
                                        | transloco : { type: AttorneyPowerType.ConfirmatoryDocument }
                                }}
                            </dtm-ui-radio-field>
                        </div>
                        <dtm-ui-form-errors class="preserve-error-space">
                            <div class="field-error" *dtmUiFieldHasError="typeControl; name: 'required'">
                                {{ "dtmWebAppOperatorProfile.attorneyPowers.form.requiredFieldError" | transloco }}
                            </div>
                        </dtm-ui-form-errors>
                    </dtm-ui-radio-group>
                </div>
                <div *ngIf="typeControl.value === AttorneyPowerType.NationalCourtNumber" @slideIn>
                    <dtm-ui-input-field>
                        <label>
                            {{ "dtmWebAppOperatorProfile.attorneyPowers.form.nationalCourtRegistrationLabel" | transloco }}
                        </label>
                        <input
                            matInput
                            [formControl]="nationalCourtRegisterControl"
                            [mask]="NATIONAL_COURT_REGISTRATION_MASK"
                            [showMaskTyped]="true"
                        />
                        <div class="field-error" *dtmUiFieldHasError="nationalCourtRegisterControl; name: ['required', 'pattern']">
                            {{ "dtmWebAppOperatorProfile.attorneyPowers.form.requiredFieldError" | transloco }}
                        </div>
                        <div class="field-error" *dtmUiFieldHasError="nationalCourtRegisterControl; name: 'minlength'; error as error">
                            {{
                                "dtmWebAppOperatorProfile.attorneyPowers.form.nationalCourtRegistrationMinLengthError"
                                    | transloco : { min: error.requiredLength }
                            }}
                        </div>
                    </dtm-ui-input-field>
                </div>
                <div class="form-container" *ngIf="typeControl.value === AttorneyPowerType.ConfirmatoryDocument" @slideIn>
                    <button type="button" class="button-secondary download-model-document" (click)="getAttorneyPowerModelDocument()">
                        <dtm-ui-icon name="download"></dtm-ui-icon>
                        {{ "dtmWebAppOperatorProfile.attorneyPowers.form.getAttorneyPowerModelDocumentLabel" | transloco }}
                    </button>
                    <dtm-ui-files-upload-field
                        *ngIf="attorneyPowerForm.controls.attorneyPowerDocument"
                        [isDownloadAllButtonVisible]="false"
                        [formControl]="attorneyPowerDocumentControl"
                        [maxFileSize]="MAX_FILE_SIZE_BYTES"
                        [allowedTypes]="ATTORNEY_FILE_ALLOWED_MIME_TYPES"
                    >
                        <label>{{ "dtmWebAppOperatorProfile.attorneyPowers.form.attorneyConfirmatoryDocumentLabel" | transloco }}</label>
                        <div class="field-error" *dtmUiFieldHasError="attorneyPowerDocumentControl; name: 'maxlength'">
                            {{ "dtmWebAppOperatorProfile.attorneyPowers.form.attorneyMultipleFilesError" | transloco }}
                        </div>
                        <div class="field-error" *dtmUiFieldHasError="attorneyPowerDocumentControl; name: ['required', 'requiredTouched']">
                            {{ "dtmWebAppOperatorProfile.attorneyPowers.form.requiredFieldError" | transloco }}
                        </div>
                        <div class="field-hint">
                            {{
                                "dtmWebAppOperatorProfile.attorneyPowers.form.attorneyDocumentUploadHint"
                                    | transloco : { maxFileSize: MAX_FILE_SIZE_BYTES | formatFileSize }
                            }}
                        </div>
                    </dtm-ui-files-upload-field>
                </div>
            </ng-container>
            <div class="action-buttons">
                <button type="button" class="button-secondary" (click)="closeForm()">
                    {{ "dtmWebAppOperatorProfile.attorneyPowers.form.cancelLabel" | transloco }}
                </button>
                <button type="submit" class="button-primary" *ngIf="capabilities.members.length">
                    {{ "dtmWebAppOperatorProfile.attorneyPowers.form.saveFormLabel" | transloco }}
                </button>
            </div>
        </div>
    </form>
</dtm-ui-loader-container>

<ng-template #noMembersTemplate>
    <dtm-ui-info-message type="info">
        {{ "dtmWebAppOperatorProfile.attorneyPowers.form.noMembersMessage" | transloco }}
    </dtm-ui-info-message>
</ng-template>
