import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, Input, OnInit, forwardRef } from "@angular/core";
import {
    ControlValueAccessor,
    FormControl,
    FormGroup,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
    ValidatorFn,
    Validators,
} from "@angular/forms";
import {
    MissionCategory,
    MissionPlanOperationCategoryOption,
    MissionPlanSpecificPermitType,
    SoraSettings,
} from "@dtm-frontend/shared/mission";
import { FormType, FunctionUtils, LocalComponentStore, MAX_TEXT_LENGTH, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { combineLatestWith, filter } from "rxjs/operators";
import {
    ArcAirspaceRiskDecreasedType,
    GroundRiskBufferSettings,
    MissionPlanItineraryConstraints,
    MissionUAVWithSetup,
} from "../../../../../models/mission.model";

interface SoraSettingsComponentState {
    operationCategory: MissionPlanOperationCategoryOption | undefined;
    constraints: MissionPlanItineraryConstraints | undefined;
    uavWithSetup: MissionUAVWithSetup | undefined;
    defaultGroundRiskBuffer: number | undefined;
    minGroundRiskBuffer: number;
    isExpanded: boolean;
    shouldDisableLowLevelFlight: boolean;
}

const MAX_DRONE_WIDTH_FOR_FLYING_OVER_ASSEMBLIES_IN_METERS = 3;
const MAX_REQUIRED_BUFFER = 1000;

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-sora-settings",
    templateUrl: "./sora-settings.component.html",
    styleUrls: ["./sora-settings.component.scss"],
    providers: [
        LocalComponentStore,
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SoraSettingsComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => SoraSettingsComponent),
            multi: true,
        },
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SoraSettingsComponent implements ControlValueAccessor, Validator, OnInit {
    @Input() public set operationCategory(value: MissionPlanOperationCategoryOption | undefined) {
        this.localStore.patchState({ operationCategory: value });

        if (value?.specificCaaPermitId) {
            this.groundRiskBufferSettingsFormGroup.disable({ emitEvent: false });
        } else {
            this.groundRiskBufferSettingsFormGroup.enable({ emitEvent: false });
        }
    }

    @Input() public set constraints(value: MissionPlanItineraryConstraints | undefined) {
        this.localStore.patchState({ constraints: value });
    }

    @Input() public set uavWithSetup(value: MissionUAVWithSetup | undefined) {
        this.localStore.patchState({ uavWithSetup: value });
    }

    @Input() public set defaultGroundRiskBuffer(value: number | undefined) {
        this.localStore.patchState({ defaultGroundRiskBuffer: value });

        if (!this.individualGroundRiskBufferValueControl.value || this.individualGroundRiskBufferValueControl.value <= (value ?? 0)) {
            this.individualGroundRiskBufferValueControl.setValue(value || null);
        }
    }

    @Input() public set minGroundRiskBuffer(value: number | undefined) {
        this.localStore.patchState({ minGroundRiskBuffer: value ?? 0 });

        this.individualGroundRiskBufferValueControl.setValidators([
            Validators.min(value ?? 0),
            Validators.max(MAX_REQUIRED_BUFFER),
            this.requiredForControlValidator(this.isIndividualBufferSettingControl),
        ]);

        this.individualGroundRiskBufferValueControl.updateValueAndValidity({ emitEvent: false });
    }

    @Input() public set shouldResetArcAirspaceRisk(value: BooleanInput) {
        this.localStore.patchState({ shouldDisableLowLevelFlight: coerceBooleanProperty(value) });

        if (coerceBooleanProperty(value) && this.arcAirspaceRiskDecreasedTypeControl.value === ArcAirspaceRiskDecreasedType.FlightLowAgl) {
            this.arcAirspaceRiskDecreasedTypeControl.reset();
            this.isFlightLowAglControl.patchValue(false);
        }
    }

    protected readonly isIndividualBufferSettingControl = new FormControl(false, { nonNullable: true });
    protected readonly individualGroundRiskBufferValueControl = new FormControl<number | null>(null, [
        Validators.min(0),
        Validators.max(MAX_REQUIRED_BUFFER),
        this.requiredForControlValidator(this.isIndividualBufferSettingControl),
    ]);

    protected readonly isFlightsOverAssembliesControl = new FormControl(false, { nonNullable: true });
    protected readonly flightsOverAssembliesDescriptionControl = new FormControl<string | null>(
        null,
        this.requiredForControlValidator(this.isFlightsOverAssembliesControl)
    );

    protected readonly isFlightOverControlledAreaControl = new FormControl(false, { nonNullable: true });
    protected readonly flightOverControlledAreaDescriptionControl = new FormControl<string | null>(
        null,
        this.requiredForControlValidator(this.isFlightOverControlledAreaControl)
    );
    protected readonly isFlightOverControlledAreaStatementAcceptedControl = new FormControl(false, {
        validators: this.requiredTrueForControlValidator(this.isFlightOverControlledAreaControl),
        nonNullable: true,
    });

    protected readonly isArcAirspaceRiskDecreasedControl = new FormControl(false, { nonNullable: true });
    protected readonly arcAirspaceRiskDecreasedTypeControl = new FormControl<GroundRiskBufferSettings["arcAirspaceRiskDecreasedType"]>(
        null,
        this.requiredForControlValidator(this.isArcAirspaceRiskDecreasedControl)
    );
    protected readonly isFlightAroundObstacleStatementAcceptedControl = new FormControl(false, {
        nonNullable: true,
        validators: this.requiredTrueForSpecificArcAirspaceRiskDecreasedType(
            this.arcAirspaceRiskDecreasedTypeControl,
            ArcAirspaceRiskDecreasedType.FlightAroundObstacle
        ),
    });
    protected readonly flightAroundObstacleDescriptionControl = new FormControl<string | null>(
        null,
        this.requiredForSpecificArcAirspaceRiskDecreasedType(
            this.arcAirspaceRiskDecreasedTypeControl,
            ArcAirspaceRiskDecreasedType.FlightAroundObstacle
        )
    );
    protected readonly isFlightInSegregatedAirspaceStatementAcceptedControl = new FormControl(false, {
        nonNullable: true,
        validators: this.requiredTrueForSpecificArcAirspaceRiskDecreasedType(
            this.arcAirspaceRiskDecreasedTypeControl,
            ArcAirspaceRiskDecreasedType.FlightInSegregatedAirspace
        ),
    });
    protected readonly flightInSegregatedAirspaceDescriptionControl = new FormControl<string | null>(
        null,
        this.requiredForSpecificArcAirspaceRiskDecreasedType(
            this.arcAirspaceRiskDecreasedTypeControl,
            ArcAirspaceRiskDecreasedType.FlightInSegregatedAirspace
        )
    );
    protected readonly isFlightLowAglControl = new FormControl(false, {
        nonNullable: true,
        validators: this.requiredTrueForSpecificArcAirspaceRiskDecreasedType(
            this.arcAirspaceRiskDecreasedTypeControl,
            ArcAirspaceRiskDecreasedType.FlightLowAgl
        ),
    });
    protected readonly isGroupOfPeopleNearOperationAreaControl = new FormControl(false, { nonNullable: true });
    protected readonly groupOfPeopleNearOperationAreaDescriptionControl = new FormControl<string | null>(null, [
        this.requiredForControlValidator(this.isGroupOfPeopleNearOperationAreaControl),
        Validators.maxLength(MAX_TEXT_LENGTH),
    ]);

    protected readonly groundRiskBufferSettingsFormGroup = new FormGroup<FormType<GroundRiskBufferSettings>>({
        isIndividualBufferSetting: this.isIndividualBufferSettingControl,
        individualGroundRiskBufferValue: this.individualGroundRiskBufferValueControl,
        isFlightsOverAssemblies: this.isFlightsOverAssembliesControl,
        flightsOverAssembliesDescription: this.flightsOverAssembliesDescriptionControl,
        isFlightOverControlledArea: this.isFlightOverControlledAreaControl,
        isFlightOverControlledAreaStatementAccepted: this.isFlightOverControlledAreaStatementAcceptedControl,
        flightOverControlledAreaDescription: this.flightOverControlledAreaDescriptionControl,
        isArcAirspaceRiskDecreased: this.isArcAirspaceRiskDecreasedControl,
        arcAirspaceRiskDecreasedType: this.arcAirspaceRiskDecreasedTypeControl,
        isFlightAroundObstacleStatementAccepted: this.isFlightAroundObstacleStatementAcceptedControl,
        flightAroundObstacleDescription: this.flightAroundObstacleDescriptionControl,
        isFlightInSegregatedAirspaceStatementAccepted: this.isFlightInSegregatedAirspaceStatementAcceptedControl,
        flightInSegregatedAirspaceDescription: this.flightInSegregatedAirspaceDescriptionControl,
        isGroupOfPeopleNearOperationArea: this.isGroupOfPeopleNearOperationAreaControl,
        groupOfPeopleNearOperationAreaDescription: this.groupOfPeopleNearOperationAreaDescriptionControl,
        isFlightLowAgl: this.isFlightLowAglControl,
    });

    protected readonly operationCategory$ = this.localStore.selectByKey("operationCategory");
    protected readonly constraints$ = this.localStore.selectByKey("constraints");
    protected readonly isExpanded$ = this.localStore.selectByKey("isExpanded");
    protected readonly minGroundRiskBuffer$ = this.localStore.selectByKey("minGroundRiskBuffer");
    protected readonly shouldDisableLowLevelFlight$ = this.localStore.selectByKey("shouldDisableLowLevelFlight");

    protected readonly MissionCategory = MissionCategory;
    protected readonly MAX_TEXT_LENGTH = MAX_TEXT_LENGTH;
    protected readonly MAX_REQUIRED_BUFFER = MAX_REQUIRED_BUFFER;
    protected readonly ArcAirspaceRiskDecreasedType = ArcAirspaceRiskDecreasedType;

    private propagateTouch = FunctionUtils.noop;
    private onValidationChange = FunctionUtils.noop;
    private propagateChange: (value: SoraSettings) => void = FunctionUtils.noop;

    constructor(private readonly localStore: LocalComponentStore<SoraSettingsComponentState>) {
        localStore.setState({
            operationCategory: undefined,
            constraints: undefined,
            uavWithSetup: undefined,
            defaultGroundRiskBuffer: undefined,
            isExpanded: false,
            minGroundRiskBuffer: 0,
            shouldDisableLowLevelFlight: false,
        });

        this.isFlightOverControlledAreaControl.valueChanges
            .pipe(filter(FunctionUtils.isTruthy), untilDestroyed(this))
            .subscribe(() => this.isFlightsOverAssembliesControl.setValue(false));

        this.isFlightsOverAssembliesControl.valueChanges
            .pipe(filter(FunctionUtils.isTruthy), untilDestroyed(this))
            .subscribe(() => this.isFlightOverControlledAreaControl.setValue(false));

        this.groundRiskBufferSettingsFormGroup.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
            Object.values(this.groundRiskBufferSettingsFormGroup.controls).forEach((control) =>
                control.updateValueAndValidity({ emitEvent: false })
            );
            this.propagateChange(this.convertGroundRiskToSoraSettings(value));
            this.onValidationChange();
        });
    }

    public ngOnInit(): void {
        this.disableFlightOverAssembliesControlsBasedOnUavSpecification();
    }

    public writeValue(value: SoraSettings | null): void {
        const convertedValue = this.convertSoraToGroundRiskBufferSettings(value);
        this.groundRiskBufferSettingsFormGroup.reset(convertedValue);

        if (Object.values(convertedValue).length > 1) {
            this.localStore.patchState({ isExpanded: true });
        }
    }

    public registerOnChange(fn: (value: SoraSettings) => void): void {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.propagateTouch = fn;
    }

    public registerOnValidatorChange(fn: () => void): void {
        this.onValidationChange = fn;
    }

    public validate(): ValidationErrors | null {
        return this.groundRiskBufferSettingsFormGroup.invalid
            ? {
                  soraSettings: true,
              }
            : null;
    }

    protected shouldShowSoraOptions(category?: MissionPlanOperationCategoryOption): boolean {
        if (!category) {
            return false;
        }

        return category.type === MissionCategory.Specific && category.specificPermitType === MissionPlanSpecificPermitType.Individual;
    }

    protected requiredForControlValidator(forControl: FormControl): ValidatorFn {
        return (control) => (forControl.value ? Validators.required(control) : null);
    }

    protected requiredTrueForControlValidator(forControl: FormControl): ValidatorFn {
        return (control) => (forControl.value ? Validators.requiredTrue(control) : null);
    }

    protected requiredForSpecificArcAirspaceRiskDecreasedType(
        forControl: FormControl,
        type: GroundRiskBufferSettings["arcAirspaceRiskDecreasedType"]
    ): ValidatorFn {
        const isArcAirspaceRiskDecreasedControl = this.isArcAirspaceRiskDecreasedControl;

        return (control) => (isArcAirspaceRiskDecreasedControl.value && forControl.value === type ? Validators.required(control) : null);
    }

    protected requiredTrueForSpecificArcAirspaceRiskDecreasedType(
        forControl: FormControl,
        type: GroundRiskBufferSettings["arcAirspaceRiskDecreasedType"]
    ): ValidatorFn {
        const isArcAirspaceRiskDecreasedControl = this.isArcAirspaceRiskDecreasedControl;

        return (control) =>
            isArcAirspaceRiskDecreasedControl.value && forControl.value === type ? Validators.requiredTrue(control) : null;
    }

    private disableFlightOverAssembliesControlsBasedOnUavSpecification() {
        this.localStore
            .selectByKey("uavWithSetup")
            .pipe(RxjsUtils.filterFalsy(), combineLatestWith(this.groundRiskBufferSettingsFormGroup.statusChanges), untilDestroyed(this))
            .subscribe(
                ([
                    {
                        setup: {
                            technicalSpecification: { maxDroneWidth },
                        },
                    },
                    status,
                ]) => {
                    if (status === "DISABLED" || maxDroneWidth > MAX_DRONE_WIDTH_FOR_FLYING_OVER_ASSEMBLIES_IN_METERS) {
                        this.isFlightsOverAssembliesControl.disable({ emitEvent: false });
                        this.flightsOverAssembliesDescriptionControl.disable({ emitEvent: false });

                        return;
                    }

                    this.isFlightsOverAssembliesControl.enable({ emitEvent: false });
                    this.flightsOverAssembliesDescriptionControl.enable({ emitEvent: false });
                }
            );
    }

    private convertGroundRiskToSoraSettings(value: GroundRiskBufferSettings): SoraSettings {
        const soraSettings: SoraSettings = {};

        if (value.isArcAirspaceRiskDecreased) {
            soraSettings.isArcAirspaceRiskDecreased = true;
        }

        if (value.isIndividualBufferSetting) {
            soraSettings.customGroundRiskBuffer = value.individualGroundRiskBufferValue ?? 0;
        }

        if (value.isFlightsOverAssemblies) {
            soraSettings.peopleAssemblyComment = value.flightsOverAssembliesDescription ?? "";
        }

        if (value.isFlightOverControlledArea) {
            soraSettings.controlledGroundAreaComment = value.flightOverControlledAreaDescription ?? "";
        }

        if (
            value.isArcAirspaceRiskDecreased &&
            value.isFlightAroundObstacleStatementAccepted &&
            value.arcAirspaceRiskDecreasedType === ArcAirspaceRiskDecreasedType.FlightAroundObstacle
        ) {
            soraSettings.flightAroundObstacleComment = value.flightAroundObstacleDescription ?? "";
        }

        if (
            value.isArcAirspaceRiskDecreased &&
            value.isFlightInSegregatedAirspaceStatementAccepted &&
            value.arcAirspaceRiskDecreasedType === ArcAirspaceRiskDecreasedType.FlightInSegregatedAirspace
        ) {
            soraSettings.flightSegregatedAirspaceComment = value.flightInSegregatedAirspaceDescription ?? "";
        }

        if (value.isGroupOfPeopleNearOperationArea) {
            soraSettings.adjacentPeopleAssemblyComment = value.groupOfPeopleNearOperationAreaDescription ?? "";
        }

        if (
            value.isArcAirspaceRiskDecreased &&
            value.isFlightLowAgl &&
            value.arcAirspaceRiskDecreasedType === ArcAirspaceRiskDecreasedType.FlightLowAgl
        ) {
            soraSettings.flightLowHeightComment = "dtmWebAppLibMission.itineraryEditorStep.soraSettings.flightLowAglStatement";
        }

        return soraSettings;
    }

    private convertSoraToGroundRiskBufferSettings(value: SoraSettings | null): GroundRiskBufferSettings {
        const groundRiskBufferSettings: GroundRiskBufferSettings = {};

        if (value?.customGroundRiskBuffer) {
            groundRiskBufferSettings.isIndividualBufferSetting = true;
            groundRiskBufferSettings.individualGroundRiskBufferValue = value.customGroundRiskBuffer;
        } else {
            groundRiskBufferSettings.individualGroundRiskBufferValue = this.localStore.selectSnapshotByKey("defaultGroundRiskBuffer");
        }

        if (!value) {
            return groundRiskBufferSettings;
        }

        if (value.peopleAssemblyComment) {
            groundRiskBufferSettings.isFlightsOverAssemblies = true;
            groundRiskBufferSettings.flightsOverAssembliesDescription = value.peopleAssemblyComment;
        }

        if (value.controlledGroundAreaComment) {
            groundRiskBufferSettings.isFlightOverControlledArea = true;
            groundRiskBufferSettings.isFlightOverControlledAreaStatementAccepted = true;
            groundRiskBufferSettings.flightOverControlledAreaDescription = value.controlledGroundAreaComment;
        }

        if (value.flightAroundObstacleComment) {
            groundRiskBufferSettings.isArcAirspaceRiskDecreased = true;
            groundRiskBufferSettings.isFlightAroundObstacleStatementAccepted = true;
            groundRiskBufferSettings.flightAroundObstacleDescription = value.flightAroundObstacleComment;
            groundRiskBufferSettings.arcAirspaceRiskDecreasedType = ArcAirspaceRiskDecreasedType.FlightAroundObstacle;
        }

        if (value.flightSegregatedAirspaceComment) {
            groundRiskBufferSettings.isArcAirspaceRiskDecreased = true;
            groundRiskBufferSettings.isFlightInSegregatedAirspaceStatementAccepted = true;
            groundRiskBufferSettings.flightInSegregatedAirspaceDescription = value.flightSegregatedAirspaceComment;
            groundRiskBufferSettings.arcAirspaceRiskDecreasedType = ArcAirspaceRiskDecreasedType.FlightInSegregatedAirspace;
        }

        if (value.adjacentPeopleAssemblyComment) {
            groundRiskBufferSettings.isGroupOfPeopleNearOperationArea = true;
            groundRiskBufferSettings.groupOfPeopleNearOperationAreaDescription = value.adjacentPeopleAssemblyComment;
        }

        if (value.flightLowHeightComment) {
            groundRiskBufferSettings.isArcAirspaceRiskDecreased = true;
            groundRiskBufferSettings.isFlightLowAgl = true;
            groundRiskBufferSettings.arcAirspaceRiskDecreasedType = ArcAirspaceRiskDecreasedType.FlightLowAgl;
        }

        return groundRiskBufferSettings;
    }
}
