import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, HostBinding, Input } from "@angular/core";
import { EmptyStateMode, FILES_UPLOAD_API_PROVIDER, FilesGroup } from "@dtm-frontend/shared/ui";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import {
    Equipment,
    EquipmentProperties,
    EquipmentType,
    NavigationAccuracy,
    ParachuteProperties,
    Tracker,
    UavProperties,
    UavSetupDocumentType,
} from "../../models/uav.models";
import { DocumentsHelperService } from "../../services/documents-helper.service";
import { SETUP_DOCUMENT_API } from "../../shared-uav.tokens";

interface UavPropertiesWithEquipment extends Omit<UavProperties, "equipment" | "documents"> {
    equipment: Array<EquipmentProperties | ParachuteProperties>;
    documents: FilesGroup<UavSetupDocumentType>[] | null;
}

interface UavSetupPreviewComponentState {
    properties: UavPropertiesWithEquipment | undefined;
    trackers: Tracker[];
    navigationAccuracyItems: NavigationAccuracy[];
    isNarrowMode: boolean;
    isEmptyEquipmentVisible: boolean;
}

@Component({
    selector: "dtm-uav-lib-uav-setup-preview",
    templateUrl: "./uav-setup-preview.component.html",
    styleUrls: ["./uav-setup-preview.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        LocalComponentStore,
        {
            provide: FILES_UPLOAD_API_PROVIDER,
            useExisting: SETUP_DOCUMENT_API,
        },
    ],
})
export class UavSetupPreviewComponent {
    @Input({ required: true }) public set setup(value: UavProperties | undefined) {
        this.localStore.patchState({
            properties: value ? this.prepareSetupProperties(value) : undefined,
        });
    }

    @Input() public set trackers(value: Tracker[] | undefined) {
        this.localStore.patchState({ trackers: value ?? [] });
    }

    @Input({ required: true }) public set navigationAccuracyItems(value: NavigationAccuracy[] | undefined) {
        this.localStore.patchState({ navigationAccuracyItems: value ?? [] });
    }

    @Input() public set isNarrowMode(value: BooleanInput) {
        this.localStore.patchState({ isNarrowMode: coerceBooleanProperty(value) });
    }

    @Input() public set isEmptyEquipmentVisible(value: BooleanInput) {
        this.localStore.patchState({ isEmptyEquipmentVisible: coerceBooleanProperty(value) });
    }

    @HostBinding("class.narrow") protected get isNarrow() {
        return this.localStore.selectSnapshotByKey("isNarrowMode");
    }

    protected readonly properties$ = this.localStore.selectByKey("properties").pipe(RxjsUtils.filterFalsy());
    protected readonly isNarrowMode$ = this.localStore.selectByKey("isNarrowMode");
    protected readonly isEmptyEquipmentVisible$ = this.localStore.selectByKey("isEmptyEquipmentVisible");
    protected readonly navigationAccuracyItems$ = this.localStore.selectByKey("navigationAccuracyItems");
    protected readonly trackers$ = this.localStore.selectByKey("trackers");

    protected readonly EquipmentType = EquipmentType;
    protected readonly EmptyStateMode = EmptyStateMode;

    constructor(
        private readonly localStore: LocalComponentStore<UavSetupPreviewComponentState>,
        private readonly documentsHelperService: DocumentsHelperService
    ) {
        this.localStore.setState({
            properties: undefined,
            trackers: [],
            navigationAccuracyItems: [],
            isNarrowMode: false,
            isEmptyEquipmentVisible: true,
        });
    }

    private prepareSetupProperties(setup: UavProperties): UavPropertiesWithEquipment {
        return {
            technicalSpecification: setup.technicalSpecification,
            communications: [...setup.communications].sort(this.sortByIsEmbedded),
            trackings: [...(setup.trackings ?? [])].sort(this.sortByIsEmbedded),
            documents: Object.keys(setup.documents).length
                ? this.documentsHelperService.convertUavSetupDocumentsToSetupDocumentsFilesGroups(setup.documents)
                : null,
            equipment: this.prepareEquipment(setup.equipment),
        };
    }

    private sortByIsEmbedded(left: { isEmbedded: boolean }, right: { isEmbedded: boolean }): number {
        return +right.isEmbedded - +left.isEmbedded;
    }

    private prepareEquipment(equipment: Equipment): Array<EquipmentProperties | ParachuteProperties> {
        return [
            ...(equipment.camera ?? []).map((camera) => ({ type: EquipmentType.Camera, item: camera })),
            ...(equipment.parachute ?? []).map((parachute) => ({ type: EquipmentType.Parachute, item: parachute })),
            ...(equipment.propellersGuards ?? []).map((propellersGuards) => ({
                type: EquipmentType.PropellersGuards,
                item: propellersGuards,
            })),
            ...(equipment.navigationLighting ?? []).map((navigationLighting) => ({
                type: EquipmentType.NavigationLighting,
                item: navigationLighting,
            })),
            ...(equipment.strobeLighting ?? []).map((strobeLighting) => ({ type: EquipmentType.StrobeLighting, item: strobeLighting })),
            ...(equipment.nightLighting ?? []).map((nightLighting) => ({ type: EquipmentType.NightLighting, item: nightLighting })),
            ...(equipment.fts ?? []).map((fts) => ({ type: EquipmentType.Fts, item: fts })),
        ];
    }
}
