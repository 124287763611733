import { FailSafe, TechnicalSpecification } from "@dtm-frontend/shared/uav";

export interface EditableCustomUavGeneralTechnicalProperties {
    failSafe: FailSafe[];
    hasGeofencing: boolean;
    hasDetectAndAvoid: boolean;
    hasProximitySensors: boolean;
    hasModuleRedundancy: boolean;
    hasGeocage: boolean;
    hasEmergencyMotorStop: boolean;
}

type GeneralTechnicalSpecification = Pick<
    TechnicalSpecification,
    | "failSafe"
    | "hasGeofencing"
    | "hasDetectAndAvoid"
    | "hasProximitySensors"
    | "hasModuleRedundancy"
    | "hasGeocage"
    | "hasEmergencyMotorStop"
>;

export class CustomUavGeneralTechnicalPropertiesUtils {
    public static getFormValuesBySetupDefaults(
        technicalSpecification: GeneralTechnicalSpecification | null
    ): EditableCustomUavGeneralTechnicalProperties | null {
        if (!technicalSpecification) {
            return {
                failSafe: [],
                hasGeofencing: false,
                hasDetectAndAvoid: false,
                hasProximitySensors: false,
                hasModuleRedundancy: false,
                hasGeocage: false,
                hasEmergencyMotorStop: false,
            };
        }

        return {
            failSafe: technicalSpecification.failSafe,
            hasGeofencing: technicalSpecification.hasGeofencing,
            hasDetectAndAvoid: technicalSpecification.hasDetectAndAvoid,
            hasProximitySensors: technicalSpecification.hasProximitySensors,
            hasModuleRedundancy: technicalSpecification.hasModuleRedundancy,
            hasGeocage: technicalSpecification.hasGeocage,
            hasEmergencyMotorStop: technicalSpecification.hasEmergencyMotorStop,
        };
    }

    public static prepareResultTechnicalSpecificationSetup(
        properties: EditableCustomUavGeneralTechnicalProperties
    ): GeneralTechnicalSpecification {
        return {
            failSafe: properties.failSafe,
            hasGeofencing: properties.hasGeofencing,
            hasDetectAndAvoid: properties.hasDetectAndAvoid,
            hasProximitySensors: properties.hasProximitySensors,
            hasModuleRedundancy: properties.hasModuleRedundancy,
            hasGeocage: properties.hasGeocage,
            hasEmergencyMotorStop: properties.hasEmergencyMotorStop,
        };
    }
}
