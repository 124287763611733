import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatLegacyCheckboxChange as MatCheckboxChange } from "@angular/material/legacy-checkbox";
import { MatLegacyRadioChange as MatRadioChange } from "@angular/material/legacy-radio";
import { ALLOWED_IMAGE_UPLOAD_MIME_TYPES, InvalidFormScrollableDirective, UploadedFile } from "@dtm-frontend/shared/ui";
import {
    AnimationUtils,
    BYTES_IN_MEGABYTE,
    LocalComponentStore,
    NATIONAL_COURT_REGISTRATION_LENGTH,
    NATIONAL_COURT_REGISTRATION_MASK,
} from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { legalGuardianFormValidator } from "../../../shared/components/pilot-operator-registration-legal-guardian-form/pilot-operator-registration-legal-guardian-form.component";
import { PilotOperatorRegistrationInsurance, RegisterPilotOperatorLegalGuardian } from "../../../shared/models";
import { attorneyPowerDocumentsProviders } from "../../providers/attorney-document-upload.providers";
import { AttorneyPowerType, RegisterOperatorAdditionalData } from "../../services/models";

// eslint-disable-next-line no-magic-numbers
const MAX_FILE_SIZE_BYTES = 10 * BYTES_IN_MEGABYTE;
const ATTORNEY_FILE_ALLOWED_MIME_TYPES = [...ALLOWED_IMAGE_UPLOAD_MIME_TYPES, "application/pdf"];

interface RegisterOperatorAdditionalDataComponentState {
    isLegalGuardianRequired: boolean;
    isAssociation: boolean;
    initialValues: RegisterOperatorAdditionalData | undefined;
    isInsurancePolicyFineWarningVisible: boolean;
}

interface AdditionalDataForm {
    legalGuardian: FormControl<RegisterPilotOperatorLegalGuardian>;
    isCompanyOwner: FormControl<boolean | null>;
    isInsuranceOwner: FormControl<boolean>;
    insuranceDetails: FormControl<PilotOperatorRegistrationInsurance>;
    powerOfAttorney: FormControl<AttorneyPowerType | null>;
    nationalCourtRegister: FormControl<string>;
    attorneyPowerDocument: FormControl<UploadedFile[]>;
    feeConfirmation: FormControl<UploadedFile[]>;
    isDeclarationOfExemption: FormControl<boolean>;
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-register-operator-additional-data[isLegalGuardianRequired]",
    templateUrl: "./register-operator-additional-data.component.html",
    styleUrls: ["./register-operator-additional-data.component.scss", "../../../shared/styles.scss"],
    animations: [AnimationUtils.slideInAnimation()],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore, ...attorneyPowerDocumentsProviders],
})
export class RegisterOperatorAdditionalDataComponent {
    @Input()
    public set isLegalGuardianRequired(value: BooleanInput) {
        this.localStore.patchState({ isLegalGuardianRequired: coerceBooleanProperty(value) });

        if (value) {
            this.additionalDataForm.controls.legalGuardian.enable();
        }
    }
    @Input()
    public set isAssociation(value: BooleanInput) {
        this.localStore.patchState({ isAssociation: coerceBooleanProperty(value) });
    }

    @Input()
    public set initialValues(value: RegisterOperatorAdditionalData | undefined) {
        this.localStore.patchState({ initialValues: value });
        if (value) {
            this.assignInitialValuesToForm(value);
        }
    }
    @Input()
    public set isInsurancePolicyFineWarningVisible(value: BooleanInput) {
        this.localStore.patchState({ isInsurancePolicyFineWarningVisible: coerceBooleanProperty(value) });
    }

    @Output() public additionalDataFormSave = new EventEmitter<RegisterOperatorAdditionalData>();
    @Output() public back = new EventEmitter<void>();
    @Output() public attorneyPowerTemplateDownload = new EventEmitter<void>();
    @ViewChild(InvalidFormScrollableDirective) private readonly invalidFormScrollable!: InvalidFormScrollableDirective;

    protected readonly isAssociation$ = this.localStore.selectByKey("isAssociation");
    protected readonly isInsurancePolicyFineWarningVisible$ = this.localStore.selectByKey("isInsurancePolicyFineWarningVisible");
    protected readonly NATIONAL_COURT_REGISTRATION_MASK = NATIONAL_COURT_REGISTRATION_MASK;
    protected readonly MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_BYTES;
    protected readonly ATTORNEY_FILE_ALLOWED_MIME_TYPES = ATTORNEY_FILE_ALLOWED_MIME_TYPES;
    protected readonly AttorneyPowerType = AttorneyPowerType;
    protected readonly isCompanyOwnerControl = new FormControl<boolean | null>(null, Validators.required);
    protected readonly isInsuranceOwnerControl = new FormControl<boolean>(false, { nonNullable: true });
    protected readonly legalGuardianControl = new FormControl<RegisterPilotOperatorLegalGuardian>(
        { value: { firstName: "", lastName: "", email: "" }, disabled: true },
        { validators: legalGuardianFormValidator, nonNullable: true }
    );
    protected readonly attorneyPowerControl = new FormControl<AttorneyPowerType | null>(
        { value: null, disabled: true },
        {
            validators: Validators.required,
        }
    );
    protected readonly isDeclarationOfExemptionControl = new FormControl<boolean>(false, {
        nonNullable: true,
    });

    protected readonly attorneyPowerDocumentControl = new FormControl<UploadedFile[]>(
        { value: [], disabled: true },
        {
            validators: [Validators.required, Validators.maxLength(1)],
            nonNullable: true,
        }
    );
    protected readonly attorneyFeeConfirmationDocumentControl = new FormControl<UploadedFile[]>(
        { value: [], disabled: true },
        {
            validators: [Validators.required, Validators.maxLength(1)],
            nonNullable: true,
        }
    );

    protected readonly nationalCourtRegisterControl = new FormControl<string>(
        { value: "", disabled: true },
        {
            validators: [Validators.required, Validators.minLength(NATIONAL_COURT_REGISTRATION_LENGTH)],
            nonNullable: true,
        }
    );

    protected readonly insuranceDetailsControl = new FormControl<PilotOperatorRegistrationInsurance>(
        {
            value: {
                expirationDate: null,
                number: "",
            },
            disabled: true,
        },
        { nonNullable: true }
    );

    protected readonly additionalDataForm = new FormGroup<AdditionalDataForm>({
        isCompanyOwner: this.isCompanyOwnerControl,
        isInsuranceOwner: this.isInsuranceOwnerControl,
        legalGuardian: this.legalGuardianControl,
        insuranceDetails: this.insuranceDetailsControl,
        powerOfAttorney: this.attorneyPowerControl,
        nationalCourtRegister: this.nationalCourtRegisterControl,
        attorneyPowerDocument: this.attorneyPowerDocumentControl,
        feeConfirmation: this.attorneyFeeConfirmationDocumentControl,
        isDeclarationOfExemption: this.isDeclarationOfExemptionControl,
    });

    constructor(private localStore: LocalComponentStore<RegisterOperatorAdditionalDataComponentState>) {
        localStore.setState({
            isLegalGuardianRequired: false,
            isAssociation: false,
            initialValues: undefined,
            isInsurancePolicyFineWarningVisible: false,
        });
        this.isDeclarationOfExemptionControl.valueChanges.pipe(untilDestroyed(this)).subscribe((isDeclarationOfExemption) => {
            if (!isDeclarationOfExemption && this.attorneyPowerControl.value === AttorneyPowerType.ConfirmatoryDocument) {
                this.additionalDataForm.controls.feeConfirmation.enable();

                return;
            }

            this.additionalDataForm.controls.feeConfirmation.disable();
            this.attorneyFeeConfirmationDocumentControl.reset();
        });
    }

    protected validateFormAndSave() {
        this.additionalDataForm.markAllAsTouched();

        if (this.additionalDataForm.valid) {
            this.additionalDataFormSave.emit(this.getAdditionalData());

            return;
        }

        this.invalidFormScrollable.scrollToFirstInvalidField();
    }

    protected updateInsuranceDetailsControl(insuranceOwnership: MatCheckboxChange) {
        if (insuranceOwnership.checked) {
            this.additionalDataForm.controls.insuranceDetails.enable();

            return;
        }

        this.additionalDataForm.controls.insuranceDetails.disable();
        this.additionalDataForm.controls.insuranceDetails.reset();
    }

    protected changeCompanyOwnerValue(change: MatRadioChange) {
        if (!change.value) {
            this.additionalDataForm.controls.powerOfAttorney.enable();

            return;
        }

        this.changeNationalCourtRegisterAttorney(AttorneyPowerType.Owner);
        this.additionalDataForm.controls.powerOfAttorney.disable();
        this.additionalDataForm.controls.powerOfAttorney.reset();
    }

    protected changeNationalCourtRegisterAttorney(value: AttorneyPowerType) {
        switch (value) {
            case AttorneyPowerType.Owner:
                this.disablePowerOfAttorneyControl();
                this.disableControlsForAttorneyPower();
                this.disableControlsForNationalCourt();
                break;
            case AttorneyPowerType.ConfirmatoryDocument:
                this.disableControlsForNationalCourt();
                this.additionalDataForm.controls.feeConfirmation.enable();
                this.additionalDataForm.controls.attorneyPowerDocument.enable();
                this.additionalDataForm.controls.isDeclarationOfExemption.enable();
                break;
            case AttorneyPowerType.NationalCourtNumber:
                this.disableControlsForAttorneyPower();
                this.additionalDataForm.controls.nationalCourtRegister.enable();
                break;
        }
    }

    protected downloadPowerOfAttorneyModelDocument() {
        this.attorneyPowerTemplateDownload.emit();
    }

    private disablePowerOfAttorneyControl() {
        this.additionalDataForm.controls.powerOfAttorney.disable();
        this.attorneyPowerControl.reset();
    }

    private disableControlsForAttorneyPower() {
        this.additionalDataForm.controls.isDeclarationOfExemption.disable();
        this.isDeclarationOfExemptionControl.reset();
        this.additionalDataForm.controls.attorneyPowerDocument.disable();
        this.attorneyPowerDocumentControl.reset();
        this.additionalDataForm.controls.feeConfirmation.disable();
        this.attorneyFeeConfirmationDocumentControl.reset();
    }

    private disableControlsForNationalCourt() {
        this.additionalDataForm.controls.nationalCourtRegister.disable();
        this.additionalDataForm.controls.nationalCourtRegister.reset();
    }

    private getAdditionalData() {
        const attorneyPower = {
            type: this.isCompanyOwnerControl.value ? AttorneyPowerType.Owner : (this.attorneyPowerControl.value as AttorneyPowerType),
            nationalCourtRegister:
                this.attorneyPowerControl.value === AttorneyPowerType.NationalCourtNumber && this.nationalCourtRegisterControl.value
                    ? this.nationalCourtRegisterControl.value
                    : undefined,
            document:
                this.attorneyPowerControl.value === AttorneyPowerType.ConfirmatoryDocument && this.attorneyPowerDocumentControl.value.length
                    ? this.attorneyPowerDocumentControl.value
                    : undefined,
            feeConfirmation:
                this.attorneyPowerControl.value === AttorneyPowerType.ConfirmatoryDocument &&
                this.attorneyFeeConfirmationDocumentControl.value.length
                    ? this.attorneyFeeConfirmationDocumentControl.value
                    : undefined,
            isDeclarationOfExemption: this.additionalDataForm.controls.isDeclarationOfExemption.value,
        };

        return {
            insurancePolicyNumber: this.isInsuranceOwnerControl.value ? this.insuranceDetailsControl.value.number : undefined,
            insurancePolicyExpirationDate: this.insuranceDetailsControl.value.expirationDate ?? undefined,
            legalGuardian: this.localStore.selectSnapshotByKey("isLegalGuardianRequired") ? this.legalGuardianControl.value : undefined,
            attorneyPower,
        };
    }

    private assignInitialValuesToForm(initialValues: RegisterOperatorAdditionalData) {
        this.additionalDataForm.setValue({
            isCompanyOwner: initialValues.attorneyPower.type === AttorneyPowerType.Owner,
            isInsuranceOwner: !!initialValues.insurancePolicyNumber,
            legalGuardian: initialValues.legalGuardian ?? { firstName: "", lastName: "", email: "" },
            insuranceDetails: {
                expirationDate: initialValues.insurancePolicyExpirationDate ?? null,
                number: initialValues.insurancePolicyNumber ?? "",
            },
            powerOfAttorney: initialValues.attorneyPower.type ?? null,
            nationalCourtRegister: initialValues.attorneyPower.nationalCourtRegister ?? "",
            attorneyPowerDocument: initialValues.attorneyPower.document ?? [],
            feeConfirmation: initialValues.attorneyPower.feeConfirmation ?? [],
            isDeclarationOfExemption: !!initialValues.attorneyPower.isDeclarationOfExemption,
        });

        if (initialValues.insurancePolicyNumber) {
            this.additionalDataForm.controls.insuranceDetails.enable();
        }
        if (initialValues.legalGuardian) {
            this.additionalDataForm.controls.legalGuardian.enable();
        }

        switch (initialValues.attorneyPower.type) {
            case AttorneyPowerType.ConfirmatoryDocument:
                this.additionalDataForm.controls.powerOfAttorney.enable();
                this.additionalDataForm.controls.feeConfirmation.enable();
                this.additionalDataForm.controls.attorneyPowerDocument.enable();
                this.additionalDataForm.controls.isDeclarationOfExemption.enable();
                break;
            case AttorneyPowerType.NationalCourtNumber:
                this.additionalDataForm.controls.nationalCourtRegister.enable();
                this.additionalDataForm.controls.powerOfAttorney.enable();

                break;
            default:
                break;
        }
    }
}
