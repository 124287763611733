<form [formGroup]="customUavForm">
    <dtm-ui-input-field>
        <label>
            {{ "dtmWebAppLibUav.newUavWizardSteps.basicInfo.manufacturerNameLabel" | transloco }}
        </label>
        <input matInput type="text" [formControl]="customUavForm.controls.manufacturerName" required #manufacturerInput />
        <div class="field-error" *dtmUiFieldHasError="customUavForm.controls.manufacturerName; name: 'maxlength'; error as error">
            {{ "dtmWebAppLibUav.newUavWizardSteps.basicInfo.maxLengthValueError" | transloco : { maxLength: error.requiredLength } }}
        </div>
        <div class="field-error" *dtmUiFieldHasError="customUavForm.controls.manufacturerName; name: ['required', 'pattern']">
            {{ "dtmWebAppLibUav.newUavWizardSteps.requiredFieldErrorHint" | transloco }}
        </div>
    </dtm-ui-input-field>
    <dtm-ui-input-field>
        <label>
            {{ "dtmWebAppLibUav.newUavWizardSteps.basicInfo.modelNameLabel" | transloco }}
        </label>
        <input matInput type="text" [formControl]="customUavForm.controls.modelName" required />
        <div class="field-error" *dtmUiFieldHasError="customUavForm.controls.modelName; name: 'maxlength'; error as error">
            {{ "dtmWebAppLibUav.newUavWizardSteps.basicInfo.maxLengthValueError" | transloco : { maxLength: error.requiredLength } }}
        </div>
        <div class="field-error" *dtmUiFieldHasError="customUavForm.controls.modelName; name: ['required', 'pattern']">
            {{ "dtmWebAppLibUav.newUavWizardSteps.requiredFieldErrorHint" | transloco }}
        </div>
    </dtm-ui-input-field>
    <dtm-ui-select-field
        [placeholder]="'dtmWebAppLibUav.newUavWizardSteps.basicInfo.uavTypePlaceholder' | transloco"
        [isClearable]="false"
        [formControl]="customUavForm.controls.type"
    >
        <label>{{ "dtmWebAppLibUav.newUavWizardSteps.basicInfo.uavTypeLabel" | transloco }}</label>
        <dtm-ui-select-option *ngFor="let uavType of uavTypes" [value]="uavType">
            {{ "dtmWebAppLibUav.uavTypeValue" | transloco : { value: uavType } }}
        </dtm-ui-select-option>
        <div class="field-error" *dtmUiFieldHasError="customUavForm.controls.type; name: 'required'">
            {{ "dtmWebAppLibUav.newUavWizardSteps.requiredFieldErrorHint" | transloco }}
        </div>
    </dtm-ui-select-field>
    <dtm-web-app-lib-uav-swarm-control [formControl]="customUavForm.controls.isSwarm"></dtm-web-app-lib-uav-swarm-control>
    <dtm-ui-serial-numbers-control
        dtmUiMarkValueAccessorControlsAsTouched
        [formControl]="customUavForm.controls.serialNumbers"
        [isSingle]="!customUavForm.controls.isSwarm.value"
    ></dtm-ui-serial-numbers-control>
    <div class="uav-class-ce">
        <dtm-ui-select-field
            [placeholder]="'dtmWebAppLibUav.newUavWizardSteps.basicInfo.uavClassPlaceholder' | transloco"
            [isClearable]="false"
            [formControl]="customUavForm.controls.uavClasses"
            [multiple]="true"
        >
            <label class="label-with-popover">
                {{ "dtmWebAppLibUav.newUavWizardSteps.basicInfo.uavClassLabel" | transloco }}
                <dtm-ui-popover [popoverText]="'dtmWebAppLibUav.newUavWizardSteps.basicInfo.uavClassHelpInfo' | transloco"></dtm-ui-popover>
            </label>
            <dtm-ui-select-option *ngFor="let uavClass of uavClasses" [value]="uavClass">
                {{ "dtmWebAppLibUav.uavClassValue" | transloco : { value: uavClass } }}
            </dtm-ui-select-option>
        </dtm-ui-select-field>
        <div class="switcher">
            <label>
                {{ "dtmWebAppLibUav.newUavWizardSteps.basicInfo.isCeCompliantLabel" | transloco }}
            </label>
            <mat-slide-toggle [formControl]="customUavForm.controls.isCeCompliant"></mat-slide-toggle>
        </div>
    </div>
    <dtm-ui-input-field>
        <label class="label-with-popover">
            {{ "dtmWebAppLibUav.newUavWizardSteps.basicInfo.nameLabel" | transloco }}
            <dtm-ui-popover [popoverText]="'dtmWebAppLibUav.newUavWizardSteps.basicInfo.namePopover' | transloco"></dtm-ui-popover>
        </label>
        <input matInput type="text" [formControl]="customUavForm.controls.name" required autocomplete="off" />
        <div class="field-error" *dtmUiFieldHasError="customUavForm.controls.name; name: 'maxlength'; error as error">
            {{ "dtmWebAppLibUav.newUavWizardSteps.basicInfo.nameMaxLengthValueError" | transloco : { maxLength: error.requiredLength } }}
        </div>
        <div class="field-error" *dtmUiFieldHasError="customUavForm.controls.name; name: 'minlength'; error as error">
            {{ "dtmWebAppLibUav.newUavWizardSteps.basicInfo.nameMinLengthValueError" | transloco : { minLength: error.requiredLength } }}
        </div>
        <div class="field-error" *dtmUiFieldHasError="customUavForm.controls.name; name: ['required', 'pattern']">
            {{ "dtmWebAppLibUav.newUavWizardSteps.requiredFieldErrorHint" | transloco }}
        </div>
    </dtm-ui-input-field>
    <dtm-web-app-lib-uav-image-control
        [formControl]="customUavForm.controls.image"
        (editMode)="setImageEditMode($event)"
    ></dtm-web-app-lib-uav-image-control>
</form>
