import { HttpClient, HttpContext, HttpErrorResponse, HttpStatusCode } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Address } from "@dtm-frontend/shared/ui";
import { DateUtils, SKIP_AUTHENTICATION_HTTP_INTERCEPTOR, SKIP_NOT_FOUND_HTTP_INTERCEPTOR, StringUtils } from "@dtm-frontend/shared/utils";
import { saveAs } from "file-saver";
import { Observable, throwError } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { OperatorInsurancePolicy } from "../../shared/models/pilot-and-operator.models";
import { PILOT_PROFILE_ENDPOINTS, PilotProfileEndpoints } from "../pilot-profile.tokens";
import {
    AddForeignCompetencyRequestBody,
    PilotProfileResponseBody,
    convertForeignCompetencyDocumentDraftToAddForeignCompetencyRequestBody,
    convertPilotProfileResponseBodyToPilotProfile,
} from "./pilot-profile.converters";
import {
    ForeignCompetencyDocumentEditOrAdd,
    PansaUtmCredentials,
    PansaUtmLinkError,
    PansaUtmLinkErrorType,
    PilotConfirmationDocumentType,
    PilotProfile,
    PilotProfileError,
    PilotProfileErrorType,
} from "./pilot-profile.models";

@Injectable({
    providedIn: "root",
})
export class PilotProfileApiService {
    constructor(
        private readonly httpClient: HttpClient,
        @Inject(PILOT_PROFILE_ENDPOINTS) private readonly endpoints: PilotProfileEndpoints
    ) {}

    public getPilotProfile(id: string): Observable<PilotProfile> {
        return this.httpClient.get<PilotProfileResponseBody>(StringUtils.replaceInTemplate(this.endpoints.getPilotProfile, { id })).pipe(
            map((response: PilotProfileResponseBody) => convertPilotProfileResponseBodyToPilotProfile(response)),
            catchError((errorResponse: HttpErrorResponse) => throwError(this.transformGetProfileErrorResponse(errorResponse)))
        );
    }

    public editPilotInsurancePolicy(id: string, policy: OperatorInsurancePolicy): Observable<void> {
        return this.httpClient
            .put<void>(StringUtils.replaceInTemplate(this.endpoints.managePilotInsurancePolicy, { id }), {
                number: policy.number,
                expirationDate: DateUtils.getISOStringDate(policy.expirationDate.toISOString()),
            })
            .pipe(catchError(() => throwError(() => ({ type: PilotProfileErrorType.CannotEditOperatorInsurancePolicy }))));
    }

    public deletePilotInsurancePolicy(id: string): Observable<void> {
        return this.httpClient
            .delete<void>(StringUtils.replaceInTemplate(this.endpoints.managePilotInsurancePolicy, { id }))
            .pipe(catchError(() => throwError(() => ({ type: PilotProfileErrorType.CannotDeleteOperatorInsurancePolicy }))));
    }

    public editPilotAddress(id: string, address: Address): Observable<Address> {
        return this.httpClient
            .put<Address>(StringUtils.replaceInTemplate(this.endpoints.editPilotAddress, { id }), address)
            .pipe(catchError(() => throwError(() => ({ type: PilotProfileErrorType.CannotSavePilotAddress }))));
    }

    public downloadPilotsDocument(pilotId: string, documentType: PilotConfirmationDocumentType, fileName: string): Observable<Blob> {
        return this.httpClient
            .get(StringUtils.replaceInTemplate(this.endpoints.downloadDocument, { pilotId, documentType }), {
                responseType: "blob",
                context: new HttpContext().set(SKIP_NOT_FOUND_HTTP_INTERCEPTOR, true),
            })
            .pipe(
                tap((response) => saveAs(response, fileName)),
                catchError((error) => throwError(() => this.transformGetProfileErrorResponse(error)))
            );
    }

    public addPansaUtmLink(pilotId: string, { login, password }: PansaUtmCredentials): Observable<void> {
        return this.httpClient
            .post<void>(
                StringUtils.replaceInTemplate(this.endpoints.managePansaUtmLink, { pilotId }),
                { email: login, password },
                { context: new HttpContext().set(SKIP_NOT_FOUND_HTTP_INTERCEPTOR, true).set(SKIP_AUTHENTICATION_HTTP_INTERCEPTOR, true) }
            )
            .pipe(catchError((error) => throwError(() => this.transformPansaUtmLinkErrorResponse(error))));
    }

    public updatePansaUtmLink(pilotId: string, { login, password }: PansaUtmCredentials): Observable<void> {
        return this.httpClient
            .put<void>(
                StringUtils.replaceInTemplate(this.endpoints.managePansaUtmLink, { pilotId }),
                { email: login, password },
                { context: new HttpContext().set(SKIP_NOT_FOUND_HTTP_INTERCEPTOR, true).set(SKIP_AUTHENTICATION_HTTP_INTERCEPTOR, true) }
            )
            .pipe(catchError((error) => throwError(() => this.transformPansaUtmLinkErrorResponse(error))));
    }

    public deletePansaUtmLink(pilotId: string): Observable<void> {
        return this.httpClient
            .delete<void>(StringUtils.replaceInTemplate(this.endpoints.managePansaUtmLink, { pilotId }), {
                context: new HttpContext().set(SKIP_NOT_FOUND_HTTP_INTERCEPTOR, true).set(SKIP_AUTHENTICATION_HTTP_INTERCEPTOR, true),
            })
            .pipe(catchError(() => throwError(() => ({ type: PansaUtmLinkErrorType.Unknown }))));
    }

    public addForeignCompetency(pilotId: string, competency: ForeignCompetencyDocumentEditOrAdd): Observable<void> {
        const addForeignCompetencyRequestBody: AddForeignCompetencyRequestBody =
            convertForeignCompetencyDocumentDraftToAddForeignCompetencyRequestBody(competency.draft);

        if (competency.competencyId) {
            return this.httpClient
                .put<void>(
                    StringUtils.replaceInTemplate(this.endpoints.managePilotCompetency, { pilotId, competencyId: competency.competencyId }),
                    addForeignCompetencyRequestBody
                )
                .pipe(catchError(() => throwError(() => ({ type: PansaUtmLinkErrorType.Unknown }))));
        }

        return this.httpClient
            .post<void>(StringUtils.replaceInTemplate(this.endpoints.addPilotCompetency, { pilotId }), addForeignCompetencyRequestBody)
            .pipe(catchError(() => throwError(() => ({ type: PansaUtmLinkErrorType.Unknown }))));
    }

    public deleteForeignCompetencyError(pilotId: string, competencyId: string): Observable<void> {
        return this.httpClient
            .delete<void>(StringUtils.replaceInTemplate(this.endpoints.managePilotCompetency, { pilotId, competencyId }))
            .pipe(catchError(() => throwError(() => ({ type: PansaUtmLinkErrorType.Unknown }))));
    }

    private transformGetProfileErrorResponse(errorResponse: HttpErrorResponse): PilotProfileError {
        switch (errorResponse.status) {
            case HttpStatusCode.Forbidden:
                return { type: PilotProfileErrorType.NotAuthorized };
            case HttpStatusCode.NotFound:
                return { type: PilotProfileErrorType.NotFound };
            default:
                return { type: PilotProfileErrorType.Unknown };
        }
    }

    private transformPansaUtmLinkErrorResponse(errorResponse: HttpErrorResponse): PansaUtmLinkError {
        switch (errorResponse.status) {
            case HttpStatusCode.Unauthorized:
                return { type: PansaUtmLinkErrorType.WrongCredentials };
            default:
                return { type: PansaUtmLinkErrorType.Unknown };
        }
    }
}
