import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { AirRiskMitigationsArc } from "@dtm-frontend/shared/mission";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { RiskMitigationPopoverKeys } from "../../../../../models/mission.model";

export interface AirRiskMitigationLevelsComponentState {
    airInitialRiskMitigationValue: AirRiskMitigationsArc | null | undefined;
    airRiskMitigationValue: AirRiskMitigationsArc | null | undefined;
    airRiskMitigationPopoverKeys: RiskMitigationPopoverKeys | undefined;
}

@Component({
    selector: "dtm-web-app-lib-mission-air-risk-mitigation-levels[airInitialRiskMitigationValue]",
    templateUrl: "./air-risk-mitigation-levels.component.html",
    styleUrls: ["./air-risk-mitigation-levels.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class AirRiskMitigationLevelsComponent {
    @Input() public set airInitialRiskMitigationValue(value: AirRiskMitigationsArc | null | undefined) {
        this.localStore.patchState({ airInitialRiskMitigationValue: value });
    }
    @Input() public set airRiskMitigationValue(value: AirRiskMitigationsArc | null | undefined) {
        this.localStore.patchState({ airRiskMitigationValue: value });
    }
    @Input() public set airRiskMitigationsPopoverKeys(value: RiskMitigationPopoverKeys | undefined) {
        this.localStore.patchState({ airRiskMitigationPopoverKeys: value });
    }
    @Output() public readonly airRiskMitigationSet = new EventEmitter<AirRiskMitigationsArc>();

    protected readonly airInitialRiskMitigationValue$ = this.localStore.selectByKey("airInitialRiskMitigationValue");
    protected readonly airRiskMitigationValue$ = this.localStore.selectByKey("airRiskMitigationValue");
    protected readonly airRiskMitigationPopoverKeys$ = this.localStore.selectByKey("airRiskMitigationPopoverKeys");

    protected readonly AirRiskMitigationsArc = AirRiskMitigationsArc;

    constructor(private readonly localStore: LocalComponentStore<AirRiskMitigationLevelsComponentState>) {
        this.localStore.setState({
            airInitialRiskMitigationValue: undefined,
            airRiskMitigationValue: undefined,
            airRiskMitigationPopoverKeys: undefined,
        });
    }
}
