import { Injectable } from "@angular/core";
import { FileInGroup, FilesGroup, MAX_UPLOADED_FILE_SIZE_BYTES } from "@dtm-frontend/shared/ui";
import { TranslocoService } from "@jsverse/transloco";
import { UavDocumentFile, UavModelDocumentType, UavSetupDocumentType, UavSetupDocuments } from "../models/uav.models";

@Injectable({
    providedIn: "root",
})
export class DocumentsHelperService {
    constructor(private readonly transloco: TranslocoService) {}

    public convertUavSetupDocumentsToSetupDocumentsFilesGroups(documents: UavSetupDocuments): FilesGroup<UavSetupDocumentType>[] {
        return [
            this.prepareSetupFilesGroup(documents, UavSetupDocumentType.ParachuteManual),
            this.prepareSetupFilesGroup(documents, UavSetupDocumentType.ParachutePhoto, [".png", ".jpeg", ".jpg"]),
            this.prepareSetupFilesGroup(documents, UavSetupDocumentType.CheckList),
            this.prepareSetupFilesGroup(documents, UavSetupDocumentType.TechnicalSupportProgram),
            this.prepareSetupFilesGroup(documents, UavSetupDocumentType.Fts),
            this.prepareSetupFilesGroup(documents, UavSetupDocumentType.Dvr),
            this.prepareSetupFilesGroup(documents, UavSetupDocumentType.TestsResults),
        ];
    }

    public convertSetupDocumentsFilesGroupsToUavSetupDocuments(documents: FilesGroup<UavSetupDocumentType>[]): UavSetupDocuments {
        return documents.reduce<UavSetupDocuments>(
            (result, filesGroup) => ({
                ...result,
                [filesGroup.groupId]: filesGroup.files.map((file) => ({
                    id: file.id,
                    name: file.name,
                    size: file.size,
                    isEditable: file.isRemovable,
                })),
            }),
            {}
        );
    }

    public convertUavDocumentFilesToUavModelDocumentsFilesGroups(
        manual: UavDocumentFile | undefined,
        projectDocumentation: UavDocumentFile | undefined
    ): FilesGroup<UavModelDocumentType>[] {
        return [
            this.prepareUavModelFilesGroup(manual, UavModelDocumentType.Manual),
            this.prepareUavModelFilesGroup(projectDocumentation, UavModelDocumentType.ProjectDocumentation),
        ];
    }

    private prepareUavModelFilesGroup(document: UavDocumentFile | undefined, type: UavModelDocumentType): FilesGroup<UavModelDocumentType> {
        return {
            groupId: type,
            groupLabel: this.transloco.translate("dtmSharedUav.uavDocuments.model.documentTypeLabel", { type }),
            files: document ? [this.prepareFileInGroupByUavDocumentFile(document)] : [],
            validators: {
                singleFile: true,
                maxSize: MAX_UPLOADED_FILE_SIZE_BYTES,
                allowedTypes: [".pdf", ".png", ".jpeg", ".jpg"],
            },
        };
    }

    private prepareSetupFilesGroup(
        documents: UavSetupDocuments,
        type: UavSetupDocumentType,
        allowedTypes: Record<string, string> | string[] = [".pdf", ".png", ".jpeg", ".jpg"]
    ): FilesGroup<UavSetupDocumentType> {
        return {
            groupId: type,
            groupLabel: this.transloco.translate("dtmSharedUav.uavDocuments.setup.documentTypeLabel", { type }),
            files: documents[type]?.map((document) => this.prepareFileInGroupByUavDocumentFile(document)) ?? [],
            validators: {
                maxSize: MAX_UPLOADED_FILE_SIZE_BYTES,
                allowedTypes,
            },
        };
    }

    private prepareFileInGroupByUavDocumentFile(file: UavDocumentFile): FileInGroup {
        return {
            id: file.id,
            name: file.name,
            size: file.size,
            isRemovable: file.isEditable,
        };
    }
}
