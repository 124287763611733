<dtm-ui-loader-container
    *ngrxLet="{
        ownedPermissions: ownedPermissions$,
        statementsList: statementsList$,
        areFiltersApplied: areFiltersApplied$,
        isStatementsListProcessing: isStatementsListProcessing$,
        selectedTab: selectedTab$,
        isOwnedPermissionsProcessing: isOwnedPermissionsProcessing$,
        isPaymentFeatureAvailable: isPaymentFeatureAvailable$,
        isStatementsFeatureAvailable: isStatementsFeatureAvailable$,
        isPermissionsPermissionAvailable: isPermissionsPermissionAvailable$,
        isStatementsPermissionAvailable: isStatementsPermissionAvailable$
    } as vm"
    [isShown]="vm.isOwnedPermissionsProcessing || vm.isStatementsListProcessing"
>
    <div class="grid" *ngIf="vm.isPermissionsPermissionAvailable || vm.isStatementsPermissionAvailable; else notAuthorizedTemplate">
        <mat-card>
            <dtm-ui-card-header
                [buttonLabel]="'dtmWebAppLibOperatorPermissions.permissionsContainer.permissionsCardHeaderButtonLabel' | transloco"
                [isEditButtonVisible]="
                    vm.isStatementsFeatureAvailable &&
                    vm.isStatementsPermissionAvailable &&
                    ((vm.selectedTab === ListType.PERMISSIONS && !!vm.ownedPermissions?.length) ||
                        (vm.selectedTab === ListType.STATEMENTS && !!vm.statementsList?.length && !vm.areFiltersApplied))
                "
                icon="add"
                (edit)="navigateToGainingPermissions()"
            >
                <p class="header">{{ "dtmWebAppLibOperatorPermissions.permissionsContainer.permissionsCardHeaderTitle" | transloco }}</p>
            </dtm-ui-card-header>
            <mat-card-content class="dtm-tabs">
                <mat-tab-group
                    color="accent"
                    (selectedTabChange)="changeTab($event)"
                    [selectedIndex]="selectedTab$ | ngrxPush"
                    dynamicHeight
                >
                    <mat-tab
                        [label]="'dtmWebAppLibOperatorPermissions.permissionsContainer.permissionsLabel' | transloco"
                        *ngIf="vm.isPermissionsPermissionAvailable || vm.isStatementsPermissionAvailable"
                    >
                        <dtm-web-app-lib-operator-permissions-owned-list
                            *ngIf="!(ownedPermissionsListError$ | ngrxPush); else errorTemplate"
                            [ownedPermissions]="vm.ownedPermissions"
                            [isProcessing]="isOwnedPermissionsProcessing$ | ngrxPush"
                            [isMakingStatementAvailable]="vm.isStatementsFeatureAvailable"
                            (uavDetailsShow)="showUavDetailsForPermission($event)"
                            (permissionGet)="navigateToGainingPermissions()"
                        ></dtm-web-app-lib-operator-permissions-owned-list>
                    </mat-tab>
                    <mat-tab
                        *ngIf="vm.isStatementsFeatureAvailable && vm.isStatementsPermissionAvailable"
                        [label]="'dtmWebAppLibOperatorPermissions.permissionsContainer.statementsLabel' | transloco"
                    >
                        <ng-container *ngIf="!(statementsListError$ | ngrxPush); else errorTemplate">
                            <ng-container
                                *ngIf="
                                    vm.statementsList?.length || vm.areFiltersApplied || vm.isStatementsListProcessing;
                                    else statementsEmptyStateTemplate
                                "
                            >
                                <dtm-web-app-lib-statements-filter
                                    [isPaymentFeatureAvailable]="vm.isPaymentFeatureAvailable"
                                    [initialFilters]="currentFilters$ | ngrxPush"
                                    (filtersChange)="changeFilters($event)"
                                    (areFiltersAppliedChange)="changeAreFiltersApplied($event)"
                                ></dtm-web-app-lib-statements-filter>
                                <dtm-web-app-lib-statements-list
                                    [isPaymentFeatureAvailable]="vm.isPaymentFeatureAvailable"
                                    [statementsList]="statementsList$ | ngrxPush"
                                    [pagination]="statementsListPagination$ | ngrxPush"
                                    (pageChange)="changeStatementsPage($event)"
                                    (signatureRedirect)="redirectToSignature($event)"
                                    (administrativeFeeDocumentDownload)="getOperatorStatementAdministrativeFeeDocument($event)"
                                ></dtm-web-app-lib-statements-list>
                            </ng-container>
                        </ng-container>
                    </mat-tab>
                </mat-tab-group>
            </mat-card-content>
        </mat-card>
    </div>
</dtm-ui-loader-container>

<ng-template #errorTemplate>
    <dtm-ui-error
        [errorMessage]="'dtmWebAppLibOperatorPermissions.permissionsContainer.errorMessage' | transloco"
        (reload)="reloadList()"
    ></dtm-ui-error>
</ng-template>
<ng-template #statementsEmptyStateTemplate>
    <dtm-ui-empty-state [mode]="EmptyStateMode.ListView">
        <ng-container titleSlot>
            {{ "dtmWebAppLibOperatorPermissions.permissionsContainer.statementsEmptyState.title" | transloco }}
        </ng-container>
        <img imageSlot src="assets/images/no-results.svg" [alt]="'dtmUi.noResults.imageAltText' | transloco" />
        <p messageSlot>
            {{ "dtmWebAppLibOperatorPermissions.permissionsContainer.statementsEmptyState.message" | transloco }}
        </p>
        <button footerSlot type="button" class="button-primary" (click)="navigateToGainingPermissions()">
            <dtm-ui-icon name="add"></dtm-ui-icon>
            {{ "dtmWebAppLibOperatorPermissions.permissionsContainer.statementsEmptyState.buttonLabel" | transloco }}
        </button>
    </dtm-ui-empty-state>
</ng-template>

<ng-template #notAuthorizedTemplate>
    <dtm-ui-not-authorized></dtm-ui-not-authorized>
</ng-template>
