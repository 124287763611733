import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore, METERS_IN_KILOMETER, RxjsUtils, SECONDS_IN_HOUR } from "@dtm-frontend/shared/utils";
import { UntilDestroy } from "@ngneat/until-destroy";
import { Observable, combineLatest, map, startWith, switchMap } from "rxjs";
import { MIN_MAX_FLIGHT_SPEED_IN_METERS_PER_SECOND } from "../../itinerary-editor-step.component";
import {
    MissionWizardItineraryEditorParametersPanelBaseComponent,
    MissionWizardItineraryEditorParametersPanelBaseComponentState,
} from "../mission-parameters-panel-base.component";
import { FlightSpeedType, ItineraryEditorMissionSettingsFormData, MissionUAVWithSetup } from "./../../../../../../models/mission.model";

interface MissionWizardItineraryEditorSettingsPanelComponentState
    extends MissionWizardItineraryEditorParametersPanelBaseComponentState<ItineraryEditorMissionSettingsFormData> {
    uavWithSetup: MissionUAVWithSetup | undefined;
    maxDeclaredHeight: number | undefined;
}

const MAX_PILOT_REACTION_DELAY_SECONDS = 20;

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-mission-wizard-itinerary-editor-step-settings-panel[uavWithSetup][formControls]",
    templateUrl: "./settings-panel.component.html",
    styleUrls: ["./settings-panel.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class MissionWizardItineraryEditorSettingsPanelComponent extends MissionWizardItineraryEditorParametersPanelBaseComponent<
    ItineraryEditorMissionSettingsFormData,
    MissionWizardItineraryEditorSettingsPanelComponentState
> {
    protected readonly FlightSpeedType = FlightSpeedType;
    protected readonly MAX_PILOT_REACTION_DELAY_SECONDS = MAX_PILOT_REACTION_DELAY_SECONDS;

    @Input() public set uavWithSetup(value: MissionUAVWithSetup | undefined) {
        this.localStore.patchState({ uavWithSetup: value });
    }
    @Input() public set maxDeclaredHeight(value: number | undefined) {
        this.localStore.patchState({ maxDeclaredHeight: value });
    }

    protected readonly maxFlightSpeed$ = this.initMaxFlightSpeed();
    protected readonly maxDeclaredHeight$ = this.localStore.selectByKey("maxDeclaredHeight");

    constructor(localStore: LocalComponentStore<MissionWizardItineraryEditorSettingsPanelComponentState>) {
        super(localStore);
        localStore.setState({
            isExpanded: true,
            isDeferred: false,
            uavWithSetup: undefined,
            formControls: undefined,
            originalFormControls: undefined,
            formStateController: undefined,
            isDisabled: false,
            maxDeclaredHeight: undefined,
        });
    }

    private initMaxFlightSpeed(): Observable<number> {
        return combineLatest([
            this.localStore.selectByKey("uavWithSetup"),
            this.formControls$.pipe(
                RxjsUtils.filterFalsy(),
                switchMap((controls) => controls.flightSpeedType.valueChanges.pipe(startWith(controls.flightSpeedType.value)))
            ),
        ]).pipe(
            map(([uavWithSetup, flightSpeedType]) => {
                const maxSpeed = uavWithSetup?.setup.technicalSpecification.maxFlightSpeed ?? 1;

                if (flightSpeedType === FlightSpeedType.KilometersPerHour) {
                    return (maxSpeed * SECONDS_IN_HOUR) / METERS_IN_KILOMETER;
                }

                return Math.max(MIN_MAX_FLIGHT_SPEED_IN_METERS_PER_SECOND, maxSpeed);
            })
        );
    }
}
