<ng-container *ngIf="canManageUav$ | ngrxPush; else notAuthorizedTemplate">
    <ng-container *ngrxLet="isEditMode$ as isEditMode">
        <ng-container *ngIf="error$ | ngrxPush as error; else uavSetupEditorTemplate">
            <div class="uav-nav">
                <button type="button" class="button-secondary" [routerLink]="isEditMode ? '../..' : '..'">
                    <dtm-ui-icon name="arrow-go-back"></dtm-ui-icon>
                    {{ "dtmWebAppLibUav.uavSetupEditor.goBackToUavDetailsButtonLabel" | transloco : { name: "" } }}
                </button>
            </div>
            <ng-container [ngSwitch]="error.type">
                <dtm-ui-error
                    *ngSwitchCase="UavErrorType.NotFound"
                    [mode]="ErrorMode.Secondary"
                    [errorMessage]="'dtmWebAppLibUav.uavSetupEditor.uavNotFoundErrorMessage' | transloco"
                ></dtm-ui-error>
                <dtm-ui-error
                    *ngSwitchCase="UavErrorType.CannotGetTrackers"
                    [mode]="ErrorMode.Secondary"
                    [errorMessage]="'dtmWebAppLibUav.uavSetupEditor.trackersNotFoundErrorMessage' | transloco"
                ></dtm-ui-error>
                <dtm-ui-error
                    *ngSwitchCase="UavErrorType.CannotGetSetup"
                    [mode]="ErrorMode.Secondary"
                    [errorMessage]="'dtmWebAppLibUav.uavSetupEditor.setupNotFoundErrorMessage' | transloco"
                ></dtm-ui-error>
                <dtm-ui-error
                    *ngSwitchDefault
                    [mode]="ErrorMode.Secondary"
                    [errorMessage]="'dtmWebAppLibUav.uavSetupEditor.uavUnknownErrorMessage' | transloco"
                ></dtm-ui-error>
            </ng-container>
        </ng-container>

        <ng-template #uavSetupEditorTemplate>
            <ng-container *ngrxLet="uav$ as uav">
                <div class="uav-nav">
                    <button type="button" class="button-secondary" [routerLink]="['/uav', uav.id]">
                        <dtm-ui-icon name="arrow-go-back"></dtm-ui-icon>
                        {{ "dtmWebAppLibUav.uavSetupEditor.goBackToUavDetailsButtonLabel" | transloco : { name: "- " + uav.name } }}
                    </button>
                </div>

                <dtm-ui-loader-container
                    [isShown]="isProcessing$ | ngrxPush"
                    *ngrxLet="navigationAccuracyItems$ as navigationAccuracyItems"
                >
                    <div class="grid">
                        <dtm-web-app-lib-uav-header [uav]="uav" class="uav-header" isShortMode></dtm-web-app-lib-uav-header>
                        <ng-container *ngrxLet="isPrimarySetupSelected$ as isPrimarySetupSelected">
                            <mat-card class="uav-setup-editor" *ngrxLet="trackers$ as trackers">
                                <section class="header">
                                    <mat-card-title class="title">
                                        {{
                                            isEditMode
                                                ? ("dtmWebAppLibUav.uavSetupEditor.editSetupHeader" | transloco)
                                                : ("dtmWebAppLibUav.uavSetupEditor.addSetupHeader" | transloco)
                                        }}
                                    </mat-card-title>
                                    <button
                                        type="button"
                                        class="preview-primary-setup-button button-secondary"
                                        (click)="openPrimarySetupPreview()"
                                    >
                                        <dtm-ui-icon name="drawer-open"></dtm-ui-icon>
                                        {{ "dtmWebAppLibUav.uavSetupEditor.showPrimarySetupButtonLabel" | transloco }}
                                    </button>
                                </section>
                                <dtm-web-app-lib-setup-form
                                    *ngIf="!(uav.model.isCustom && isEditMode && isPrimarySetupSelected); else customUavSetupFormTemplate"
                                    class="setup-form scroll-shadows"
                                    [setupDefaults]="setupDefaults$ | ngrxPush"
                                    [factoryCommunicationTypes]="factoryCommunicationTypes$ | ngrxPush"
                                    [trackers]="trackers"
                                    [navigationAccuracyItems]="navigationAccuracyItems"
                                    [uavModel]="uav.model"
                                    [isNameDisabled]="isEditMode && isPrimarySetupSelected"
                                    [uavTechnicalSpecification]="uavTechnicalSpecification$ | ngrxPush"
                                    (setupChange)="setSetupValues($event)"
                                ></dtm-web-app-lib-setup-form>
                                <ng-template #customUavSetupFormTemplate>
                                    <dtm-web-app-lib-custom-uav-setup-form
                                        class="setup-form"
                                        [setupDefaults]="customUavSetupDefaults$ | ngrxPush"
                                        [trackers]="trackers"
                                        [navigationAccuracyItems]="navigationAccuracyItems"
                                        [isNameDisabled]="isEditMode && isPrimarySetupSelected"
                                        [uavType]="uav.model.type"
                                        (setupChange)="setSetupValues($event)"
                                    ></dtm-web-app-lib-custom-uav-setup-form>
                                </ng-template>
                                <footer class="actions">
                                    <button class="button-secondary" type="button" [routerLink]="['/uav', uav.id]">
                                        {{ "dtmWebAppLibUav.uavSetupEditor.cancelButtonLabel" | transloco }}
                                    </button>
                                    <button class="button-primary save-button" type="button" (click)="saveSetup()">
                                        {{ "dtmWebAppLibUav.uavSetupEditor.saveSetupButtonLabel" | transloco }}
                                    </button>
                                </footer>
                                <div
                                    class="primary-setup-preview-panel"
                                    *ngrxLet="isPrimarySetupPreviewVisible$ as isPrimarySetupPreviewVisible"
                                    [@foldFarRight]="isPrimarySetupPreviewVisible ? 'open' : 'closed'"
                                >
                                    <header>
                                        <button
                                            type="button"
                                            class="button-icon close-button"
                                            (click)="closePrimarySetupPreview()"
                                            [attr.tabindex]="isPrimarySetupPreviewVisible ? undefined : -1"
                                        >
                                            <dtm-ui-icon name="close"></dtm-ui-icon>
                                        </button>
                                        <h4>{{ "dtmWebAppLibUav.uavSetupEditor.primarySetupPreviewHeader" | transloco }}</h4>
                                    </header>
                                    <section>
                                        <dtm-uav-lib-uav-setup-preview
                                            dtmWebAppLibSetupDocumentsApiProvider
                                            [setup]="primarySetup$ | ngrxPush"
                                            [trackers]="trackers"
                                            [navigationAccuracyItems]="navigationAccuracyItems"
                                            [isNarrowMode]="true"
                                            [isEmptyEquipmentVisible]="false"
                                        />
                                    </section>
                                </div>
                            </mat-card>
                        </ng-container>
                    </div>
                </dtm-ui-loader-container>
            </ng-container>
        </ng-template>
    </ng-container>
</ng-container>

<ng-template #notAuthorizedTemplate>
    <dtm-ui-not-authorized></dtm-ui-not-authorized>
</ng-template>
