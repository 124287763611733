import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { AbstractControl, FormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import {
    AdditionalCrewMember,
    MissionCategory,
    MissionPlanIndividualOperationCategoryOption,
    MissionPlanOperationCategoryOption,
    MissionPlanSpecificPermitType,
    MissionType,
    UavClassesAndCategoriesInfoComponent,
} from "@dtm-frontend/shared/mission";
import { DialogService, InvalidFormScrollableDirective } from "@dtm-frontend/shared/ui";
import {
    DEFAULT_DEBOUNCE_TIME,
    FormStateController,
    FunctionUtils,
    GRAMS_IN_KILOGRAM,
    LocalComponentStore,
    RxjsUtils,
} from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import equal from "fast-deep-equal";
import { firstValueFrom, skip } from "rxjs";
import { combineLatestWith, debounceTime, distinctUntilChanged, map } from "rxjs/operators";
import {
    ActivePermit,
    ActivePermitPilot,
    ActivePermitUav,
    MissionCapabilities,
    MissionDataFormCapabilitiesData,
    MissionDataFormData,
    MissionDataFormFlightPurposeData,
    MissionOperator,
    MissionPilot,
    MissionPlan,
    MissionPlanFlightPurposeOption,
    MissionPlanningPreferences,
    MissionUAV,
    MissionUAVWithSetup,
    PermitLocationType,
} from "../../../../models/mission.model";
import { AdditionalInformationSettings } from "../../../mission-notes-and-description/personal-notes.component";
import { MissionWizardSteps } from "../../content/mission-wizard-content.component";
import { MissionPlanningType } from "./mission-planning-type-form-control/mission-planning-type-form-control.component";

interface MissionWizardMissionDataStepComponentState {
    allOperationCategories: MissionPlanOperationCategoryOption[];
    operator: MissionOperator | undefined;
    allPilots: MissionPilot[];
    uavsWithSetups: MissionUAVWithSetup[];
    availableUavsWithSetups: MissionUAVWithSetup[][];
    currentPlan: MissionPlan | undefined;
    flightPurposes: MissionPlanFlightPurposeOption[];
    isProcessing: boolean;
    preferences: MissionPlanningPreferences | undefined;
    isEditMode: boolean | undefined;
    activePermitsList: ActivePermit[];
    missionPlanningType: MissionPlanningType;
    areCapabilitiesLoading: boolean;
    isInitialized: boolean;
    additionalInformation: AdditionalInformationSettings | undefined;
    additionalCrewMembersOptions: string[] | undefined;
    stepNumber: number | undefined;
    stepsAmount: number | undefined;
    isSelectedEnterpriseOperator: boolean;
    disableMissionType: MissionType | undefined;
    selectedActivePermitUavs: ActivePermitUav[];
    canManageUavs: boolean;
    selectedActivePermitPilots: ActivePermitPilot[];
    isSpecificPermitUsageEnabled: boolean;
    isPilotUTMConnected: boolean;
}

const MAX_FLIGHT_DESCRIPTION_LENGTH = 200;
const FLIGHT_PURPOSE_SAVING_DEBOUNCE_TIME = DEFAULT_DEBOUNCE_TIME;
const FLIGHT_PURPOSE_TRANSPORT_CODE_NAME = "DEL";
const DANGEROUS_MATERIALS_INFO_URL = "https://eur-lex.europa.eu/legal-content/PL/TXT/PDF/?uri=CELEX:02019R0947-20220404&from=PL";

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-mission-wizard-mission-data-form-step[isProcessing][missionCapabilities][currentPlan]",
    templateUrl: "./mission-data-form-step.component.html",
    styleUrls: ["./mission-data-form-step.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class MissionWizardMissionDataFormStepComponent implements OnInit {
    @ViewChild(InvalidFormScrollableDirective) private readonly invalidFormScrollable: InvalidFormScrollableDirective | undefined;

    protected readonly MissionType = MissionType;
    protected readonly MissionWizardSteps = MissionWizardSteps;
    protected readonly MissionPlanSpecificPermitType = MissionPlanSpecificPermitType;
    protected readonly MissionCategory = MissionCategory;
    protected readonly DANGEROUS_MATERIALS_INFO_URL = DANGEROUS_MATERIALS_INFO_URL;

    @Input() public set missionPreferences(value: MissionPlanningPreferences | undefined) {
        this.localStore.patchState({ preferences: value });
    }
    @Input() public set isSelectedEnterpriseOperator(value: BooleanInput) {
        this.localStore.patchState({ isSelectedEnterpriseOperator: coerceBooleanProperty(value) });
    }
    @Input() public set missionCapabilities(value: MissionCapabilities | undefined) {
        this.localStore.patchState({
            operator: value?.operator,
            flightPurposes: value?.flightPurposes ?? [],
            allOperationCategories: value?.operationCategories ?? [],
            additionalCrewMembersOptions: value?.additionalCrewMembersOptions,
        });

        if (
            value?.operationCategories?.find((category) => {
                const { specificCaaPermitId, ...operationCategory } = this.categoryFormControl.value;

                return equal(category, operationCategory);
            })?.isDisabled !== false
        ) {
            this.setDefaultOperationCategory();
        }
    }
    @Input()
    public set activePermitsList(value: ActivePermit[] | undefined) {
        this.localStore.patchState({ activePermitsList: value ?? [] });
    }
    @Input() public set currentPlan(value: MissionPlan | undefined) {
        this.localStore.patchState({ currentPlan: value });
    }
    @Input() public set isProcessing(value: BooleanInput) {
        this.localStore.patchState({ isProcessing: coerceBooleanProperty(value) });
    }
    @Input() public set areCapabilitiesLoading(value: BooleanInput) {
        this.localStore.patchState({ areCapabilitiesLoading: coerceBooleanProperty(value) });
    }
    @Input() public set isEditMode(value: BooleanInput) {
        const isEditMode = coerceBooleanProperty(value);
        this.localStore.patchState({ isEditMode });
        this.changeDisabilityOfFormControlsWhenEditing(isEditMode);
    }
    @Input() public set additionalInformation(value: AdditionalInformationSettings | undefined) {
        this.localStore.patchState({ additionalInformation: value });
    }
    @Input() public set stepNumber(value: number | undefined) {
        this.localStore.patchState({ stepNumber: value });
    }
    @Input() public set stepsAmount(value: number | undefined) {
        this.localStore.patchState({ stepsAmount: value });
    }
    @Input() public set areDangerousMaterialsTransported(value: BooleanInput) {
        this.dangerousMaterialsTransportControl.setValue(coerceBooleanProperty(value), { emitEvent: false });
    }
    @Input() public set canManageUavs(value: BooleanInput) {
        this.localStore.patchState({ canManageUavs: coerceBooleanProperty(value) });
    }
    @Input() public set isSpecificPermitUsageEnabled(value: BooleanInput) {
        this.localStore.patchState({ isSpecificPermitUsageEnabled: coerceBooleanProperty(value) });
    }
    @Input() public set isPilotUTMConnected(value: BooleanInput) {
        this.localStore.patchState({ isPilotUTMConnected: coerceBooleanProperty(value) });
    }

    @Output() public readonly capabilitiesUpdateOrCreate = new EventEmitter<MissionDataFormCapabilitiesData>();
    @Output() public readonly formUpdate = new EventEmitter<MissionDataFormData>();
    @Output() public readonly flightPurposeUpdate = new EventEmitter<MissionDataFormFlightPurposeData>();
    @Output() public readonly operationCategoryUpdate = new EventEmitter<MissionPlanOperationCategoryOption>();
    @Output() public readonly next = new EventEmitter();
    @Output() public readonly additionalInformationChange = new EventEmitter<AdditionalInformationSettings>();
    @Output() public readonly itineraryClear = new EventEmitter<void>();
    @Output() public readonly loadPermitLocation = new EventEmitter<{ kmlFileId?: string; zoneDesignator?: string } | undefined>();

    protected readonly allOperationCategories$ = this.localStore.selectByKey("allOperationCategories");
    protected readonly flightPurposes$ = this.localStore.selectByKey("flightPurposes");
    protected readonly canManageUavs$ = this.localStore.selectByKey("canManageUavs");
    protected readonly allPilots$ = this.localStore.selectByKey("allPilots");
    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");
    protected readonly availableUavsWithSetups$ = this.localStore.selectByKey("availableUavsWithSetups");
    protected readonly isSelectedEnterpriseOperator$ = this.localStore.selectByKey("isSelectedEnterpriseOperator");
    protected readonly selectedActivePermitUavs$ = this.localStore.selectByKey("selectedActivePermitUavs");
    protected readonly selectedActivePermitPilots$ = this.localStore.selectByKey("selectedActivePermitPilots");

    protected readonly activePermits$ = this.localStore.selectByKey("activePermitsList").pipe(
        combineLatestWith(this.localStore.selectByKey("operator")),
        map(([list, operator]) => list?.filter((permit) => permit.operatorId === operator?.id))
    );
    protected readonly missionPlanningType$ = this.localStore.selectByKey("missionPlanningType");

    protected readonly areCapabilitiesLoading$ = this.localStore.selectByKey("areCapabilitiesLoading");
    protected readonly isInitialized$ = this.localStore.selectByKey("isInitialized");
    protected readonly isEditMode$ = this.localStore.selectByKey("isEditMode");

    protected readonly additionalInformation$ = this.localStore.selectByKey("additionalInformation");

    protected readonly stepNumber$ = this.localStore.selectByKey("stepNumber");
    protected readonly stepsAmount$ = this.localStore.selectByKey("stepsAmount");

    protected readonly disableMissionType$ = this.localStore.selectByKey("disableMissionType");
    protected readonly isSpecificPermitUsageEnabled$ = this.localStore.selectByKey("isSpecificPermitUsageEnabled").pipe(
        combineLatestWith(this.localStore.selectByKey("currentPlan")),
        map(([isSpecificPermitUsageEnabled, plan]) => !!plan?.operationCategory?.specificCaaPermitId || isSpecificPermitUsageEnabled)
    );
    protected readonly isPilotUTMConnected$ = this.localStore.selectByKey("isPilotUTMConnected");

    protected readonly additionalCrewMembersOptions$ = this.localStore
        .selectByKey("additionalCrewMembersOptions")
        .pipe(RxjsUtils.filterFalsy());

    protected readonly missionTypeFormControl = new FormControl<MissionType>(MissionType.VLOS, {
        nonNullable: true,
        validators: [Validators.required],
    });
    protected readonly pilotFormControl = new FormControl<MissionPilot | null>(null, [Validators.required]);
    protected readonly uavWithSetupFormControl = new FormControl<MissionUAVWithSetup | null>(null, [Validators.required]);
    protected readonly flightPurposeFormControl = new FormControl<MissionPlanFlightPurposeOption | null>(null, [Validators.required]);
    protected readonly flightPurposeDescriptionFormControl = new FormControl<string | null>(null);
    protected readonly flightPurposeLoadWeightFormControl = new FormControl<number | null>(null);
    protected readonly categoryFormControl = new FormControl<MissionPlanOperationCategoryOption>(
        { type: MissionCategory.Specific, specificPermitType: MissionPlanSpecificPermitType.Individual, isDisabled: false },
        { nonNullable: true, validators: [Validators.required] }
    );
    protected readonly activePermitsFormControl = new FormControl<ActivePermit | null>({ value: null, disabled: true }, [
        Validators.required,
    ]);
    protected readonly additionalCrewControl = new FormControl<AdditionalCrewMember[] | null>(null);
    protected readonly dangerousMaterialsTransportControl = new FormControl<boolean>(false);
    @Output() public readonly areDangerousMaterialsTransportedChange = this.dangerousMaterialsTransportControl.valueChanges;

    protected readonly missionPlanCapabilitiesFormGroup = new UntypedFormGroup({
        missionType: this.missionTypeFormControl,
        pilot: this.pilotFormControl,
        uavWithSetup: this.uavWithSetupFormControl,
        additionalCrew: this.additionalCrewControl,
    });
    protected readonly missionPlanCapabilitiesFormGroupStateController = new FormStateController(this.missionPlanCapabilitiesFormGroup);
    protected readonly missionPlanFlightPurposeFormGroup = new UntypedFormGroup({
        flightPurpose: this.flightPurposeFormControl,
        flightPurposeDescription: this.flightPurposeDescriptionFormControl,
        loadWeight: this.flightPurposeLoadWeightFormControl,
    });
    protected readonly missionPlanFlightPurposeFormGroupStateController = new FormStateController(this.missionPlanFlightPurposeFormGroup);
    protected readonly categoryFormControlStateController = new FormStateController(this.categoryFormControl);
    protected readonly missionPlanFormGroup = new UntypedFormGroup({
        capabilities: this.missionPlanCapabilitiesFormGroup,
        flightPurpose: this.missionPlanFlightPurposeFormGroup,
        category: this.categoryFormControl,
        activePermits: this.activePermitsFormControl,
        dangerousMaterialsTransportControl: this.dangerousMaterialsTransportControl,
    });

    protected readonly missionPlanFormGroupStateController = new FormStateController(this.missionPlanFormGroup);

    protected readonly MAX_FLIGHT_DESCRIPTION_LENGTH = MAX_FLIGHT_DESCRIPTION_LENGTH;
    protected readonly FLIGHT_PURPOSE_TRANSPORT_CODE_NAME = FLIGHT_PURPOSE_TRANSPORT_CODE_NAME;
    protected readonly Validators = Validators;

    constructor(
        private readonly localStore: LocalComponentStore<MissionWizardMissionDataStepComponentState>,
        private readonly dialogService: DialogService
    ) {
        this.localStore.setState({
            operator: undefined,
            allOperationCategories: [],
            flightPurposes: [],
            allPilots: [],
            uavsWithSetups: [],
            availableUavsWithSetups: [],
            isProcessing: false,
            currentPlan: undefined,
            preferences: undefined,
            isEditMode: false,
            activePermitsList: [],
            missionPlanningType: MissionPlanningType.Other,
            areCapabilitiesLoading: false,
            isInitialized: true,
            additionalInformation: undefined,
            additionalCrewMembersOptions: undefined,
            stepNumber: undefined,
            stepsAmount: undefined,
            isSelectedEnterpriseOperator: false,
            disableMissionType: undefined,
            selectedActivePermitUavs: [],
            selectedActivePermitPilots: [],
            canManageUavs: false,
            isSpecificPermitUsageEnabled: false,
            isPilotUTMConnected: false,
        });

        this.watchFormChanges();
        this.watchActivePermitsChangesAndLoadZoneData();
    }

    public ngOnInit(): void {
        this.watchPlanChangeAndUpdateForms();
        this.watchAndHandleOperatorChanges();
        this.watchAndHandlePreferencesUpdates();
    }

    private watchAndHandlePreferencesUpdates(): void {
        this.localStore
            .selectByKey("preferences")
            .pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
            .subscribe((preferences) => {
                if (this.localStore.selectSnapshotByKey("currentPlan")) {
                    return;
                }

                this.handleMissionPreferenceUpdate(preferences);
            });
    }

    private watchAndHandleOperatorChanges(): void {
        this.localStore
            .selectByKey("operator")
            .pipe(
                distinctUntilChanged((left, right) => left?.id === right?.id),
                RxjsUtils.filterFalsy(),
                untilDestroyed(this)
            )
            .subscribe((operator) => this.updateOperatorRelatedValues(operator, !this.localStore.selectSnapshotByKey("currentPlan")));
    }

    private watchPlanChangeAndUpdateForms(): void {
        this.localStore
            .selectByKey("currentPlan")
            .pipe(untilDestroyed(this))
            .subscribe((plan) => this.setFormValuesFromCurrentPlan(plan));
    }

    private updateOperatorRelatedValues(operator: MissionOperator, shouldReset = false): void {
        const uavWithSetups = this.createUavsWithSetupsForCurrentOperator(operator);
        const availableUavsWithSetups = this.getAvailableUavsWithSetups(uavWithSetups);

        this.localStore.patchState({
            allPilots: operator.pilots,
            availableUavsWithSetups,
        });

        if (shouldReset) {
            this.pilotFormControl.setValue(null);
            this.uavWithSetupFormControl.setValue(null);
            this.missionPlanFormGroup.reset();
            this.missionPlanningTypeChanged();
        }

        if (operator.pilots?.length === 1) {
            this.setPilot(operator.pilots[0]);
        }
    }

    private watchFormChanges(): void {
        this.missionPlanCapabilitiesFormGroup.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
            if (this.missionPlanCapabilitiesFormGroup.valid) {
                this.createOrUpdateCapabilities();
            }
        });

        this.missionPlanFlightPurposeFormGroup.valueChanges
            .pipe(debounceTime(FLIGHT_PURPOSE_SAVING_DEBOUNCE_TIME), untilDestroyed(this))
            .subscribe(() => {
                if (this.missionPlanFlightPurposeFormGroup.valid) {
                    this.updateFlightPurpose();
                }
            });

        this.flightPurposeFormControl.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => this.setFlightPurpose(value));
        this.missionTypeFormControl.valueChanges.pipe(untilDestroyed(this)).subscribe(() => this.missionTypeChanged());
        this.categoryFormControl.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => this.categoryChanged(value));
    }

    private categoryChanged(value: MissionPlanOperationCategoryOption | null): void {
        if (this.isIndividualOperationCategory(value)) {
            this.flightPurposeDescriptionFormControl.setValidators(Validators.required);
            this.flightPurposeDescriptionFormControl.updateValueAndValidity();
        } else {
            this.setFlightPurpose(this.flightPurposeFormControl.value);
        }
    }

    protected async missionTypeChanged(): Promise<void> {
        const pilots = this.localStore.selectSnapshotByKey("allPilots");

        if (pilots.length === 1) {
            this.pilotFormControl.setValue(pilots[0]);
        }

        if (
            this.localStore.selectSnapshotByKey("missionPlanningType") !== MissionPlanningType.CaaPermit &&
            this.activePermitsFormControl.value !== null
        ) {
            this.activePermitsFormControl.reset(null);
        }
    }

    protected missionPlanningTypeChanged(type?: MissionPlanningType): void {
        if (this.localStore.selectSnapshotByKey("missionPlanningType") === type) {
            return;
        }

        this.categoryFormControl.reset();
        this.localStore.patchState({ missionPlanningType: type ?? MissionPlanningType.Other });

        if (type === MissionPlanningType.CaaPermit) {
            this.activePermitsFormControl.enable();
            this.categoryFormControl.disable();
            this.flightPurposeFormControl.disable();

            return;
        }

        this.flightPurposeDescriptionFormControl.reset();
        this.activePermitsFormControl.reset(null, { emitEvent: this.activePermitsFormControl.value !== null });
        this.activePermitsFormControl.disable();
        this.uavWithSetupFormControl.enable({ emitEvent: false });
        this.missionPlanFlightPurposeFormGroup.enable();
        this.categoryFormControl.enable();
        this.flightPurposeFormControl.enable();
        this.localStore.patchState({ disableMissionType: undefined, selectedActivePermitUavs: [], selectedActivePermitPilots: [] });
    }

    protected async activePermitsChanged(data: ActivePermit, shouldEmitChanges = true): Promise<void> {
        this.missionPlanFlightPurposeFormGroup.reset();
        this.categoryFormControl.reset();
        this.missionTypeFormControl.setValue(data.flightType, { emitEvent: false });

        shouldEmitChanges = shouldEmitChanges && !!this.uavWithSetupFormControl.value;

        if (shouldEmitChanges) {
            const capabilities: MissionDataFormCapabilitiesData = {
                ...this.missionPlanCapabilitiesFormGroup.getRawValue(),
                operator: this.localStore.selectSnapshotByKey("operator"),
            };
            this.capabilitiesUpdateOrCreate.emit(capabilities);

            // NOTE: we need to wait for updated data from backend, skip(1) to ignore getting snapshot in moment of subscription
            await firstValueFrom(this.localStore.selectByKey("currentPlan").pipe(skip(1)));
        }
        this.flightPurposeFormControl.reset();
        this.missionPlanCapabilitiesFormGroupStateController.save();

        this.flightPurposeDescriptionFormControl.setValue(data.flightPurposes, { emitEvent: false });

        this.missionPlanFlightPurposeFormGroupStateController.save();

        if (shouldEmitChanges) {
            this.flightPurposeUpdate.emit(this.missionPlanFlightPurposeFormGroup.getRawValue() as MissionDataFormFlightPurposeData);
        }

        this.categoryFormControl.setValue(
            {
                type: MissionCategory.Specific,
                specificCaaPermitId: data.id,
                specificPermitType: MissionPlanSpecificPermitType.Individual,
                isDisabled: false,
            },
            { emitEvent: false }
        );

        this.localStore.patchState({
            disableMissionType: data.flightType === MissionType.VLOS ? MissionType.BVLOS : MissionType.VLOS,
            selectedActivePermitUavs: data.operator.uavs,
            selectedActivePermitPilots: data.operator.pilots,
        });

        this.uavWithSetupFormControl.setValue(null);
        this.pilotFormControl.setValue(null);
    }

    protected setPilot(pilot: MissionPilot): void {
        this.pilotFormControl.setValue(pilot);
        this.uavWithSetupFormControl.setValue(null);

        const operator = this.localStore.selectSnapshotByKey("operator");
        if (!operator) {
            return;
        }
        const availableUavsWithSetups = this.localStore.selectSnapshotByKey("uavsWithSetups");

        if (availableUavsWithSetups?.length === 1) {
            this.setUavSetup(availableUavsWithSetups[0]);
        }
    }

    protected shouldPilotBeDisabled(pilot: MissionPilot, selectedActivePermitPilots: ActivePermitPilot[]): boolean {
        if (!selectedActivePermitPilots.length) {
            return false;
        }

        return selectedActivePermitPilots.filter((activePermitPilot) => activePermitPilot.id === pilot.id)[0].isDisabled;
    }

    protected setUavSetup(uavSetup: MissionUAVWithSetup) {
        this.uavWithSetupFormControl.setValue(uavSetup);
    }

    protected shouldUavBeDisabled(uav: MissionUAVWithSetup, selectedActivePermitUavs: ActivePermitUav[]): boolean {
        if (!selectedActivePermitUavs.length) {
            return false;
        }

        return selectedActivePermitUavs.filter((activePermitUav) => activePermitUav.id === uav.id)[0]?.isDisabled;
    }

    protected setFlightPurpose(flightPurpose?: MissionPlanFlightPurposeOption | null): void {
        const descriptionValidators: ValidatorFn[] = [this.requiredForDangerousMaterialsTransport()];
        if (flightPurpose?.isCommentRequired || this.isIndividualOperationCategory(this.categoryFormControl.value)) {
            descriptionValidators.push(Validators.required);
        }

        if (this.categoryFormControl.value.type === MissionCategory.Certified) {
            this.dangerousMaterialsTransportControl.enable({ emitEvent: false });
        } else {
            this.dangerousMaterialsTransportControl.setValue(false, { emitEvent: false });
            this.dangerousMaterialsTransportControl.disable({ emitEvent: false });
        }

        this.flightPurposeDescriptionFormControl.setValidators(descriptionValidators);
        this.flightPurposeLoadWeightFormControl.setValidators(flightPurpose?.isLoadWeightRequired ? Validators.required : null);

        if (flightPurpose?.isLoadWeightRequired) {
            this.flightPurposeLoadWeightFormControl.enable({ emitEvent: false });
        } else {
            this.flightPurposeLoadWeightFormControl.disable({ emitEvent: false });
        }

        this.flightPurposeDescriptionFormControl.updateValueAndValidity();
        this.flightPurposeLoadWeightFormControl.updateValueAndValidity();
    }

    protected createOrUpdateCapabilities(): void {
        const isProcessing = this.localStore.selectSnapshotByKey("isProcessing");
        if (isProcessing || this.missionPlanCapabilitiesFormGroup.invalid) {
            return;
        }

        if (this.missionPlanCapabilitiesFormGroupStateController.hasChanged) {
            const capabilities: MissionDataFormCapabilitiesData = {
                ...this.missionPlanCapabilitiesFormGroup.getRawValue(),
                operator: this.localStore.selectSnapshotByKey("operator"),
            };
            const sortedCrew = [...(this.additionalCrewControl.value ?? [])].sort((left, right) => (left.type > right.type ? 1 : -1));
            this.additionalCrewControl.setValue(sortedCrew, { emitEvent: false });
            this.capabilitiesUpdateOrCreate.emit(capabilities);
            this.missionPlanCapabilitiesFormGroupStateController.save();
            this.categoryFormControlStateController.clear();
        }
    }

    protected updateFlightPurpose(): void {
        const isProcessing = this.localStore.selectSnapshotByKey("isProcessing");

        if (isProcessing || this.missionPlanFlightPurposeFormGroup.invalid) {
            return;
        }

        if (this.missionPlanFlightPurposeFormGroupStateController.hasChanged) {
            this.flightPurposeUpdate.emit(this.missionPlanFlightPurposeFormGroup.getRawValue() as MissionDataFormFlightPurposeData);
            this.missionPlanFlightPurposeFormGroupStateController.save();
            this.categoryFormControlStateController.clear();
        }
    }

    protected updateOperationCategoryAndGoNextStep(): void {
        const isProcessing = this.localStore.selectSnapshotByKey("isProcessing");
        this.invalidFormScrollable?.scrollToFirstInvalidField();
        this.missionPlanFormGroup.markAllAsTouched();

        if (isProcessing || this.missionPlanFormGroup.invalid) {
            return;
        }

        if (this.categoryFormControlStateController.hasChanged) {
            this.operationCategoryUpdate.emit(this.categoryFormControl.value);
            this.categoryFormControlStateController.save();
            this.missionPlanFormGroupStateController.save();

            return;
        }
        this.next.emit();
    }

    protected openZoneLegendDialog(): void {
        this.dialogService.open(UavClassesAndCategoriesInfoComponent);
    }

    private getAvailableUavsWithSetups(uavWithSetups?: MissionUAVWithSetup[]): MissionUAVWithSetup[][] {
        const setups = uavWithSetups ?? this.localStore.selectSnapshotByKey("uavsWithSetups") ?? [];
        const groupedSetups = setups.reduce<{
            [uavId: string]: MissionUAVWithSetup[];
        }>((result, uavWithSetup) => {
            if (!result[uavWithSetup.id]) {
                result[uavWithSetup.id] = [uavWithSetup];
            } else {
                result[uavWithSetup.id].push(uavWithSetup);
            }

            return result;
        }, {});

        return Object.values(groupedSetups);
    }

    private createUavsWithSetupsForCurrentOperator(operator: MissionOperator) {
        const generateUavWithSetupList = (uav: MissionUAV): MissionUAVWithSetup[] =>
            uav.setups.map(
                (setup): MissionUAVWithSetup => ({
                    displayName: uav.displayName,
                    id: uav.id,
                    setup,
                })
            );

        const uavsWithSetups = operator.uavs.map(generateUavWithSetupList).flat();

        this.localStore.patchState({ uavsWithSetups });

        return uavsWithSetups;
    }

    private async setDefaultCapabilities(): Promise<void> {
        const operator = this.localStore.selectSnapshotByKey("operator");

        if (!operator) {
            return;
        }

        const capabilitiesPreferences = {
            operatorId: operator.id,
            pilotId: operator.pilots[0]?.id,
            ...this.localStore.selectSnapshotByKey("preferences")?.capabilities,
        };

        if (capabilitiesPreferences.missionType && !this.missionTypeFormControl.value) {
            this.missionTypeFormControl.setValue(capabilitiesPreferences.missionType);
            await this.missionTypeChanged();
        }

        const pilot = operator.pilots.find(({ id }) => id === capabilitiesPreferences?.pilotId);

        if (pilot && !this.pilotFormControl.value) {
            this.setPilot(pilot);
        }

        const additionalCrew = capabilitiesPreferences.additionalCrew;

        if (additionalCrew && !this.additionalCrewControl.value) {
            this.additionalCrewControl.setValue(capabilitiesPreferences.additionalCrew ?? null, { emitEvent: false });
        }

        const uavWithSetup = capabilitiesPreferences.uavSetupId && this.getUavWithSetupById(capabilitiesPreferences.uavSetupId);

        if (uavWithSetup && !this.uavWithSetupFormControl.value && this.pilotFormControl.value) {
            this.setUavSetup(uavWithSetup);
        }
    }

    private getUavWithSetupById(uavSetupId: string) {
        const allUavsWithSetups = [...this.localStore.selectSnapshotByKey("uavsWithSetups").values()].flat();

        return allUavsWithSetups.find(({ setup }) => setup.id === uavSetupId);
    }

    private async setFormValuesFromCurrentPlan(plan: MissionPlan | undefined) {
        if (!plan) {
            return;
        }

        await this.setActivePermitsFormValues(plan.operationCategory?.specificCaaPermitId);
        await this.setCapabilitiesFormValuesFromCurrentPlan(plan);
        this.setFlightPurposeFromValuesFromCurrentPlan(plan);
        this.setOperationCategoryFormValuesFromCurrentPlan(plan);

        this.formUpdate.emit(this.missionPlanFormGroup.getRawValue());
    }

    private async setActivePermitsFormValues(specificActivePermitsId?: string): Promise<void> {
        if (!specificActivePermitsId) {
            return;
        }

        const activePermitsList = await firstValueFrom(
            this.localStore.selectByKey("activePermitsList").pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
        );
        const activePermit = activePermitsList.find((permit) => permit.id === specificActivePermitsId);

        if (activePermit) {
            this.localStore.patchState({ missionPlanningType: MissionPlanningType.CaaPermit });
            this.missionPlanningTypeChanged(MissionPlanningType.CaaPermit);

            this.activePermitsFormControl.setValue(activePermit, { emitEvent: false });
            this.activePermitsFormControl.enable({ emitEvent: false });

            await this.activePermitsChanged(activePermit, false);

            this.categoryFormControlStateController.save();
        }
    }

    private async setCapabilitiesFormValuesFromCurrentPlan(plan: MissionPlan) {
        if (plan.capabilities) {
            const operator = this.localStore.selectSnapshotByKey("operator");

            this.localStore.patchState({
                allPilots: operator?.pilots,
                availableUavsWithSetups: operator ? this.getAvailableUavsWithSetups() : [],
            });

            this.missionPlanCapabilitiesFormGroup.setValue(
                {
                    missionType: plan.capabilities.flightType,
                    pilot: this.localStore.selectSnapshotByKey("allPilots").find(({ id }) => id === plan.capabilities.pilotId) ?? null,
                    uavWithSetup: this.getUavWithSetupById(plan.capabilities.uavSetupId) ?? null,
                    additionalCrew: [...(plan.capabilities.additionalCrew ?? [])].sort((left, right) => (left.type > right.type ? 1 : -1)),
                },
                { emitEvent: false }
            );

            this.missionPlanCapabilitiesFormGroupStateController.save();

            return;
        }

        this.missionPlanCapabilitiesFormGroup.reset();
        this.missionPlanCapabilitiesFormGroupStateController.clear();
    }

    private setFlightPurposeFromValuesFromCurrentPlan(plan: MissionPlan) {
        if (plan.flightPurpose) {
            const flightPurpose = plan.flightPurpose; // NOTE: this is needed for TypeScript < 4.7

            // NOTE: if user entered new value for description or weight we don't want it overwritten by data from server
            // during automatic save
            this.missionPlanFlightPurposeFormGroup.setValue(
                {
                    flightPurpose: this.localStore.selectSnapshotByKey("flightPurposes").find(({ id }) => id === flightPurpose.id) ?? null,
                    flightPurposeDescription: this.flightPurposeDescriptionFormControl.value ?? flightPurpose.comment ?? null,
                    loadWeight:
                        this.flightPurposeLoadWeightFormControl.value ??
                        (!FunctionUtils.isNullOrUndefined(flightPurpose.loadWeight) ? flightPurpose.loadWeight * GRAMS_IN_KILOGRAM : null),
                },
                { emitEvent: false }
            );

            const flightPurposeOptions = this.localStore.selectSnapshotByKey("flightPurposes");
            const flightPurposeOption = flightPurposeOptions.find((option) => option.id === flightPurpose.id);

            this.setFlightPurpose(flightPurposeOption);

            this.missionPlanFlightPurposeFormGroupStateController.save();

            return;
        }
        this.missionPlanFlightPurposeFormGroup.reset();
        this.missionPlanFlightPurposeFormGroupStateController.clear();
    }

    private setOperationCategoryFormValuesFromCurrentPlan(plan: MissionPlan) {
        const activePermitsList = this.localStore.selectSnapshotByKey("activePermitsList");
        const selectedPermit = activePermitsList?.find((permit) => permit.id === plan.operationCategory?.specificCaaPermitId);
        if (!plan.operationCategory || selectedPermit) {
            return;
        }

        const { specificCaaPermitId, ...operationCategory } = plan.operationCategory;
        const foundCategory = this.localStore
            .selectSnapshotByKey("allOperationCategories")
            .find((category) => equal(category, operationCategory) && !category.isDisabled);

        if (foundCategory) {
            this.categoryFormControl.setValue(foundCategory);
        } else {
            this.setDefaultOperationCategory();
        }
        this.categoryFormControlStateController.save();
    }

    private async handleMissionPreferenceUpdate(value: MissionPlanningPreferences) {
        this.localStore.patchState({ isInitialized: false });
        await this.prepareInitialData(value);
        this.localStore.patchState({ isInitialized: true });
    }

    private async prepareInitialData(preferences: MissionPlanningPreferences) {
        if (this.localStore.selectSnapshotByKey("isEditMode")) {
            return;
        }

        if (!preferences) {
            return;
        }

        if (!(await this.checkAndPrepareCapabilitiesInitData(preferences))) {
            await this.setDefaultCapabilities();

            return;
        }

        await this.prepareFlightPurposeInitData(preferences);

        const operationCategory = preferences?.operationCategory;

        if (!operationCategory) {
            this.setDefaultOperationCategory();

            return;
        }

        const { specificCaaPermitId, ...selectedCategory } = operationCategory;

        if ((await this.checkAndSetCaaPermitInitData(specificCaaPermitId)) || !operationCategory) {
            return;
        }

        const foundCategory = this.localStore
            .selectSnapshotByKey("allOperationCategories")
            .find((category) => equal(category, selectedCategory) && !category.isDisabled);

        if (foundCategory) {
            this.categoryFormControl.setValue(foundCategory);
        } else {
            this.setDefaultOperationCategory();
        }
    }

    private setDefaultOperationCategory() {
        const allCategories = this.localStore.selectSnapshotByKey("allOperationCategories");
        const openCategory = allCategories.find((category) => category.type === MissionCategory.Open && !category.isDisabled);
        const selectedCategory =
            this.missionTypeFormControl.value === MissionType.VLOS ? openCategory : allCategories.find((category) => !category.isDisabled);

        this.categoryFormControl.reset(selectedCategory);
    }

    private async checkAndSetCaaPermitInitData(specificCaaPermitId?: string): Promise<boolean> {
        if (!((await firstValueFrom(this.isSpecificPermitUsageEnabled$)) || !specificCaaPermitId)) {
            return false;
        }

        const activePermitsList = this.localStore.selectSnapshotByKey("activePermitsList");

        const activePermit = activePermitsList?.find((permit) => permit.id === specificCaaPermitId);

        if (!activePermit) {
            return false;
        }

        this.localStore.patchState({ missionPlanningType: MissionPlanningType.CaaPermit });

        this.missionPlanningTypeChanged(MissionPlanningType.CaaPermit);

        this.activePermitsFormControl.setValue(activePermit);
        this.activePermitsFormControl.enable();
        this.localStore.patchState({
            selectedActivePermitUavs: activePermit.operator.uavs,
            selectedActivePermitPilots: activePermit.operator.pilots,
            disableMissionType: activePermit.flightType === MissionType.VLOS ? MissionType.BVLOS : MissionType.VLOS,
        });

        this.categoryFormControl.setValue({
            type: MissionCategory.Specific,
            specificCaaPermitId: activePermit.id,
            specificPermitType: MissionPlanSpecificPermitType.Individual,
            isDisabled: false,
        });

        return true;
    }

    private async checkAndPrepareCapabilitiesInitData(preferences: MissionPlanningPreferences): Promise<boolean> {
        const operator = this.localStore.selectSnapshotByKey("operator");

        if (!operator) {
            return false;
        }

        const preferredPilot = operator.pilots.find((pilot) => pilot.id === preferences.capabilities?.pilotId) ?? operator.pilots[0];
        const availableUavsWithSetups = this.localStore.selectSnapshotByKey("uavsWithSetups");
        const uavWithSetup =
            availableUavsWithSetups?.find((uav) => uav.setup.id === preferences.capabilities?.uavSetupId) ?? availableUavsWithSetups[0];
        const preferredMissionType = preferences.capabilities?.missionType ?? MissionType.VLOS;

        if (!uavWithSetup || !preferredPilot || !preferredMissionType) {
            return false;
        }

        this.capabilitiesUpdateOrCreate.emit({
            operator,
            uavWithSetup,
            pilot: preferredPilot,
            missionType: preferredMissionType,
            additionalCrew: preferences.capabilities?.additionalCrew ?? [],
        });

        await firstValueFrom(this.localStore.selectByKey("currentPlan").pipe(skip(1), untilDestroyed(this)));

        return true;
    }

    private async prepareFlightPurposeInitData(preferences: MissionPlanningPreferences) {
        const flightPurpose = this.localStore
            .selectSnapshotByKey("flightPurposes")
            .find((purpose) => purpose.id === preferences.flightPurpose?.id);

        if (!flightPurpose) {
            return;
        }

        const loadWeight = preferences.flightPurpose?.loadWeight ? preferences.flightPurpose.loadWeight * GRAMS_IN_KILOGRAM : null;

        this.flightPurposeUpdate.emit({
            loadWeight,
            flightPurpose,
            flightPurposeDescription: preferences.flightPurpose?.comment ?? null,
        });

        await firstValueFrom(this.localStore.selectByKey("currentPlan").pipe(skip(1), untilDestroyed(this)));
    }

    protected isIndividualOperationCategory(
        missionType?: MissionPlanOperationCategoryOption | null
    ): missionType is MissionPlanIndividualOperationCategoryOption {
        return (
            missionType?.type === MissionCategory.Specific && missionType.specificPermitType === MissionPlanSpecificPermitType.Individual
        );
    }

    protected requiredForDangerousMaterialsTransport(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (
                this.flightPurposeFormControl.value?.codename !== FLIGHT_PURPOSE_TRANSPORT_CODE_NAME ||
                !this.dangerousMaterialsTransportControl.value
            ) {
                return null;
            }

            return Validators.required(control);
        };
    }

    private changeDisabilityOfFormControlsWhenEditing(isEditMode: boolean) {
        if (isEditMode) {
            this.missionTypeFormControl.disable();
            this.categoryFormControl.disable();
        } else {
            this.missionTypeFormControl.enable();
            this.categoryFormControl.enable();
        }
    }

    private watchActivePermitsChangesAndLoadZoneData() {
        this.activePermitsFormControl.valueChanges
            .pipe(
                distinctUntilChanged((left, right) => left?.id === right?.id),
                untilDestroyed(this)
            )
            .subscribe((permit) => {
                if (!permit?.location) {
                    this.loadPermitLocation.emit(undefined);

                    return;
                }

                const { kmlFileId, type, dtmDesignator } = permit.location;

                if (type === PermitLocationType.Kml && kmlFileId) {
                    this.loadPermitLocation.emit({ kmlFileId });

                    return;
                }

                if (type === PermitLocationType.Dtm && dtmDesignator) {
                    this.loadPermitLocation.emit({ zoneDesignator: dtmDesignator });

                    return;
                }

                this.loadPermitLocation.emit(undefined);
            });
    }
}
