import { MissionProcessingPhaseExtended } from "@dtm-frontend/shared/mission";

export interface Post {
    id: string;
    date: string;
    title: string;
    content: string;
    link?: string;
}

export interface PostWithLanguages {
    englishContent: Post;
    nativeContent: Post;
}

export enum DashboardErrorType {
    CannotGetPosts = "CannotGetPosts",
    CannotGetMissions = "CannotGetMissions",
}

export interface DashboardError {
    type: DashboardErrorType;
}

export interface DashboardMission {
    id: string;
    missionId: string;
    name: string;
    status: DashboardMissionStatus;
    missionAcceptanceStatus: DashboardMissionAcceptanceStatus;
    phase: MissionProcessingPhaseExtended;
    flightStartAt: Date;
    flightFinishAt: Date;
    uavId: string;
    uavName: string;
    uavSetupName: string;
    pilotId: string;
    pilotName: string;
    pilotAvatarUrl: string;
    operatorName: string;
}

export enum DashboardMissionStatus {
    Draft = "DRAFT",
    Unverified = "UNVERIFIED",
    Analyzed = "ANALYZED",
    MissionReady = "MISSION_READY",
    MissionForbidden = "MISSION_FORBIDDEN",
    AnalysisInterrupted = "ANALYSIS_INTERRUPTED",
}

export enum DashboardMissionAcceptanceStatus {
    Accepted = "ACCEPTED",
    Rejected = "REJECTED",
    Canceled = "CANCELED",
}

export interface DashboardMissionsFilters {
    searchByText?: string | null;
    operatorId?: string | null;
}

export interface DashboardMissionsWithTotalElementsNumber {
    missions: DashboardMission[];
    totalElements: number;
}
