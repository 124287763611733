import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from "@angular/forms";
import { Alpha3CountryCode, IdentityDocumentType, InvalidFormScrollableDirective, UploadedFile } from "@dtm-frontend/shared/ui";
import { TranslationHelperService } from "@dtm-frontend/shared/ui/i18n";
import { AnimationUtils, BYTES_IN_MEGABYTE, DEFAULT_COUNTRY_CODE, LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { firstValueFrom } from "rxjs";
import { foreignOperatorFormValidator } from "../../../shared/components/foreign-operator-validation-fields/foreign-operator-validation-fields.component";
import {
    ForeignOperatorValidation,
    ForeignOperatorVerificationDetails,
    PilotOperatorAddressForm,
    PilotRegistrationCapabilities,
} from "../../../shared/models";
import { RegisterPilotBasicData } from "../../services/models";

interface RegisterPilotBasicDataComponentState {
    foreignOperatorValidation: ForeignOperatorValidation | undefined;
    isIdentityDocumentRequired: boolean;
    isPansaUtmLinkInfoVisible: boolean;
    capabilities: PilotRegistrationCapabilities | undefined;
    initialValues: RegisterPilotBasicData | undefined;
}

interface BasicDataForm {
    isOriginCountryFlyingDestination: FormControl<boolean | null>;
    address: FormControl<PilotOperatorAddressForm | null>;
    isRegisteredInOtherCountry: FormControl<boolean | null>;
    foreignOperator: FormControl<ForeignOperatorVerificationDetails>;
    identityDocument: FormGroup<IdentityDocumentGroup>;
    country: FormControl<Alpha3CountryCode | null>;
}

interface IdentityDocumentGroup {
    type: FormControl<IdentityDocumentType | null>;
    identityDocumentId: FormControl<UploadedFile[]>;
    identityDocumentExpirationDate: FormControl<Date | null>;
}

// eslint-disable-next-line no-magic-numbers
const MAX_FILE_SIZE_BYTES = 10 * BYTES_IN_MEGABYTE;

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-register-pilot-basic-data[foreignOperatorValidation]",
    templateUrl: "./register-pilot-basic-data.component.html",
    styleUrls: ["../../../shared/styles.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
    animations: [AnimationUtils.slideInAnimation()],
})
export class RegisterPilotBasicDataComponent {
    @Input()
    public set capabilities(value: PilotRegistrationCapabilities | undefined) {
        this.localStore.patchState({ capabilities: value });

        if (value?.isIdentityDocumentRequired) {
            this.identityDocumentFormGroup.controls.type.enable();
            this.identityDocumentFormGroup.controls.identityDocumentExpirationDate.enable();
            this.identityDocumentFormGroup.controls.identityDocumentId.enable();
        }

        if (value) {
            this.countryControl.setValue(value.originCountry, { emitEvent: false });
            this.initCountryControlValueChanges(value);
        }
    }

    @Input()
    public set foreignOperatorValidation(value: ForeignOperatorValidation | undefined) {
        this.localStore.patchState({ foreignOperatorValidation: value });
    }
    @Input()
    public set isPansaUtmLinkInfoVisible(value: BooleanInput | undefined) {
        this.localStore.patchState({ isPansaUtmLinkInfoVisible: coerceBooleanProperty(value) });
    }
    @Input()
    public set initialValues(value: RegisterPilotBasicData | undefined) {
        this.localStore.patchState({ initialValues: value });

        if (value) {
            this.assignInitialValues(value);
        }
    }

    public readonly MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_BYTES;
    public readonly DEFAULT_COUNTRY_CODE = DEFAULT_COUNTRY_CODE;
    public readonly IdentityDocumentType = IdentityDocumentType;
    protected readonly MIN_DATE = new Date();
    protected readonly datePickerPlaceholder$ = this.translocoHelper.datePickerPlaceholder$;

    protected readonly isRegisteredInOtherCountryControl = new FormControl<boolean | null>(null, Validators.required);
    protected readonly addressFormControl = new FormControl<PilotOperatorAddressForm | null>(null);
    protected readonly countryControl = new FormControl<Alpha3CountryCode | null>(null, {
        validators: [Validators.required],
    });
    protected readonly foreignOperatorControl = new FormControl<ForeignOperatorVerificationDetails>(
        {
            value: {
                number: "",
                secret: "",
            },
            disabled: true,
        },
        { validators: foreignOperatorFormValidator, nonNullable: true }
    );
    protected readonly identityDocumentIdControl = new FormControl<UploadedFile[]>(
        { value: [], disabled: true },
        {
            validators: [Validators.required, Validators.maxLength(1)],
            nonNullable: true,
        }
    );
    protected readonly identityDocumentExpirationDateControl = new FormControl<Date | null>(
        { value: null, disabled: true },
        {
            validators: Validators.required,
        }
    );
    protected readonly identityDocumentTypeControl = new FormControl<IdentityDocumentType>(
        { value: IdentityDocumentType.IdCard, disabled: true },
        {
            validators: [Validators.required],
            nonNullable: true,
        }
    );
    protected readonly isOriginCountryFlyingDestinationControl = new FormControl<boolean | null>(
        { value: null, disabled: true },
        {
            validators: Validators.required,
        }
    );

    private readonly countryInEasaGroupValidator = this.countryInEasaGroupValidatorFn.bind(this);

    @Output() public foreignOperatorCheck = new EventEmitter<ForeignOperatorVerificationDetails>();
    @Output() public basicDataFormSave = new EventEmitter<RegisterPilotBasicData>();
    @ViewChild(InvalidFormScrollableDirective) private readonly invalidFormScrollable!: InvalidFormScrollableDirective;
    @ViewChild("foreignOperatorElement") private readonly foreignOperatorElement!: ElementRef;

    protected readonly identityDocumentFormGroup = new FormGroup<IdentityDocumentGroup>({
        type: this.identityDocumentTypeControl,
        identityDocumentId: this.identityDocumentIdControl,
        identityDocumentExpirationDate: this.identityDocumentExpirationDateControl,
    });
    protected readonly basicDataForm: FormGroup<BasicDataForm> = new FormGroup({
        isRegisteredInOtherCountry: this.isRegisteredInOtherCountryControl,
        address: this.addressFormControl,
        country: this.countryControl,
        foreignOperator: this.foreignOperatorControl,
        identityDocument: this.identityDocumentFormGroup,
        isOriginCountryFlyingDestination: this.isOriginCountryFlyingDestinationControl,
    });
    protected readonly capabilities$ = this.localStore.selectByKey("capabilities").pipe(RxjsUtils.filterFalsy());
    protected readonly isPansaUtmLinkInfoVisible$ = this.localStore.selectByKey("isPansaUtmLinkInfoVisible");
    protected readonly foreignOperatorValidation$ = this.localStore.selectByKey("foreignOperatorValidation");

    constructor(
        private readonly localStore: LocalComponentStore<RegisterPilotBasicDataComponentState>,
        private readonly translocoHelper: TranslationHelperService
    ) {
        localStore.setState({
            capabilities: undefined,
            foreignOperatorValidation: undefined,
            isIdentityDocumentRequired: false,
            isPansaUtmLinkInfoVisible: false,
            initialValues: undefined,
        });
    }

    protected async save() {
        this.basicDataForm.markAllAsTouched();

        if (
            this.basicDataForm.controls.foreignOperator.enabled &&
            !(await firstValueFrom(this.foreignOperatorValidation$.pipe(untilDestroyed(this))))?.isValid
        ) {
            this.foreignOperatorElement.nativeElement.scrollIntoView({
                behavior: "smooth",
                block: "center",
                inline: "center",
            });

            return;
        }

        if (this.basicDataForm.valid) {
            this.basicDataFormSave.emit(this.getBasicData());

            return;
        }

        this.invalidFormScrollable.scrollToFirstInvalidField();
    }

    protected otherCountryRegisteredChange(isRegisteredAbroad: boolean) {
        if (isRegisteredAbroad) {
            this.basicDataForm.controls.foreignOperator.enable();
            this.basicDataForm.controls.country.removeValidators(this.countryInEasaGroupValidator);

            this.isOriginCountryFlyingDestinationControl.disable();
            this.isOriginCountryFlyingDestinationControl.reset();
        } else {
            this.basicDataForm.controls.foreignOperator.disable();
            this.basicDataForm.controls.foreignOperator.reset();
            this.basicDataForm.controls.country.addValidators(this.countryInEasaGroupValidator);
        }

        this.basicDataForm.controls.country.updateValueAndValidity();
    }

    private getBasicData(): RegisterPilotBasicData {
        return {
            address: {
                ...(this.addressFormControl.value as PilotOperatorAddressForm),
                country: this.countryControl.value as Alpha3CountryCode,
            },
            isRegisteredInOtherCountry: !!this.isRegisteredInOtherCountryControl.value,
            ...(this.foreignOperatorControl.enabled && {
                foreignOperator: this.foreignOperatorControl.value,
            }),
            identityDocument: this.basicDataForm.controls.identityDocument.controls.identityDocumentId.enabled
                ? this.identityDocumentIdControl.value
                : undefined,
            identityDocumentExpirationDate:
                this.basicDataForm.controls.identityDocument.controls.identityDocumentExpirationDate.value ?? undefined,
            identityDocumentType: this.basicDataForm.controls.identityDocument.controls.type.enabled
                ? this.identityDocumentTypeControl.value
                : undefined,
            isOriginCountryFlyingDestination: this.isOriginCountryFlyingDestinationControl.value ?? undefined,
        };
    }

    private initCountryControlValueChanges(capabilities: PilotRegistrationCapabilities) {
        this.countryControl.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
            this.isOriginCountryFlyingDestinationControl.reset();

            if (!value || this.isRegisteredInOtherCountryControl.value === null) {
                return;
            }

            if (
                this.isOriginCountry(value, capabilities) ||
                (this.isRegisteredInOtherCountryControl.value === false && this.isOriginOrEasaCountry(value, capabilities)) ||
                this.isRegisteredInOtherCountryControl.value
            ) {
                this.basicDataForm.controls.isOriginCountryFlyingDestination.disable();
                this.basicDataForm.controls.isOriginCountryFlyingDestination.reset();

                return;
            }

            this.basicDataForm.controls.isOriginCountryFlyingDestination.enable();
        });
    }

    private countryInEasaGroupValidatorFn(control: AbstractControl): ValidationErrors | null {
        const capabilities = this.localStore.selectSnapshotByKey("capabilities");

        const countryControlValue = control.value;

        if (!capabilities || !countryControlValue || countryControlValue === capabilities.originCountry) {
            return null;
        }

        if (capabilities.easaCountries.some((easa) => easa === countryControlValue)) {
            return { countryInEasaGroup: true };
        }

        return null;
    }

    private async assignInitialValues(initialValues: RegisterPilotBasicData) {
        const capabilities = await firstValueFrom(
            this.localStore.selectByKey("capabilities").pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
        );

        this.basicDataForm.setValue({
            isRegisteredInOtherCountry: initialValues.isRegisteredInOtherCountry,
            address: {
                streetName: initialValues.address.streetName,
                houseNumber: initialValues.address.houseNumber,
                apartmentNumber: initialValues.address.apartmentNumber,
                city: initialValues.address.city,
                postCode: initialValues.address.postCode,
            },
            country: initialValues.address.country,
            foreignOperator: initialValues.foreignOperator ?? { number: "", secret: "" },
            identityDocument: {
                identityDocumentId: initialValues.identityDocument ?? [],
                identityDocumentExpirationDate: initialValues.identityDocumentExpirationDate ?? null,
                type: initialValues.identityDocumentType ?? null,
            },
            isOriginCountryFlyingDestination: initialValues.isOriginCountryFlyingDestination ?? null,
        });

        if (initialValues.foreignOperator?.number) {
            this.foreignOperatorControl.enable();
        }

        if (initialValues.address.country !== capabilities.originCountry && !initialValues.isRegisteredInOtherCountry) {
            this.isOriginCountryFlyingDestinationControl.enable();
        }
    }

    private isOriginOrEasaCountry(value: Alpha3CountryCode, capabilities: PilotRegistrationCapabilities) {
        return value === capabilities.originCountry || capabilities.easaCountries.some((easa) => easa === value);
    }

    private isOriginCountry(value: Alpha3CountryCode, capabilities: PilotRegistrationCapabilities) {
        return value === capabilities.originCountry;
    }
}
