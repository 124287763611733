<mat-card>
    <dtm-ui-card-header
        [buttonLabel]="'dtmWebAppLibDashboard.missions.addNewMissionButtonLabel' | transloco"
        [isEditButtonVisible]="true"
        icon="add"
        (edit)="navigateToNewMission()"
    >
        {{ "dtmWebAppLibDashboard.missions.header" | transloco }}
    </dtm-ui-card-header>
    <mat-card-content>
        <dtm-web-app-lib-dashboard-missions-filters
            [isInitiallyExpanded]="shouldExpandFilters$ | ngrxPush"
            [incomingFilters]="incomingFilters$ | ngrxPush"
            (filtersChange)="changeFilters($event)"
        ></dtm-web-app-lib-dashboard-missions-filters>
        <dtm-web-app-lib-dashboard-missions-list
            [missions]="missions$ | ngrxPush"
            [isProcessing]="isProcessing$ | ngrxPush"
            [error]="error$ | ngrxPush"
            [isOperatorContext]="isOperatorContext$ | ngrxPush"
        ></dtm-web-app-lib-dashboard-missions-list>
    </mat-card-content>
    <div class="missions-footer">
        <a routerLink="../plan/list" [queryParams]="{ textSearch: (filters$ | ngrxPush)?.searchByText }" class="button-tertiary">
            {{
                "dtmWebAppLibDashboard.missions.missionsFooterButtonLabel"
                    | transloco : { totalMissionElements: totalMissionElements$ | ngrxPush }
            }}
        </a>
    </div>
</mat-card>
