import { HttpClient, HttpContext, HttpParams } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { OperationalGeometryData } from "@dtm-frontend/shared/mission";
import { GeoJSON } from "@dtm-frontend/shared/ui";
import { Logger, SKIP_NOT_FOUND_HTTP_INTERCEPTOR, StringUtils } from "@dtm-frontend/shared/utils";
import { saveAs } from "file-saver";
import { Observable, throwError } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { Sail } from "../../shared/models/operations-manual.models";
import { SPECIFIC_PERMIT_APPLICATION_ENDPOINTS, SpecificPermitApplicationEndpoints } from "../specific-permit-application.tokens";
import {
    CapabilitiesResponseBody,
    DraftResponseBody,
    OperationResponseBody,
    OperationalGeometryDataResponseBody,
    PredefinedAreaResponseBody,
    SaveDraftResponseBody,
    TrainingResponseBody,
    convertApplicationDataToApplicationDataRequestPayload,
    convertCapabilitiesResponseBodyToCapabilities,
    convertDraftResponseBodyToApplicationDraft,
    convertOperationResponseBodyToOperationBaseInformation,
    convertOperationalGeometryDataResponseBodyToOperationalGeometryData,
    convertTrainingResponseBodiesToTrainings,
} from "./specific-permit-application-api.converters";
import {
    ApplicationCreatorWizardSteps,
    ApplicationData,
    ApplicationDraft,
    Capabilities,
    DocumentTemplate,
    DraftData,
    OperationBaseInformation,
    OperationScenario,
    OperationSearchItem,
    PredefinedAreaDescription,
    SpecificPermitApplicationErrorType,
    Training,
} from "./specific-permit-application.models";

@Injectable({
    providedIn: "root",
})
export class SpecificPermitApplicationApiService {
    constructor(
        private readonly httpClient: HttpClient,
        @Inject(SPECIFIC_PERMIT_APPLICATION_ENDPOINTS) private readonly endpoints: SpecificPermitApplicationEndpoints
    ) {}

    public getCapabilities(operatorId: string): Observable<Capabilities> {
        return this.httpClient
            .get<CapabilitiesResponseBody>(StringUtils.replaceInTemplate(this.endpoints.getCapabilities, { operatorId }))
            .pipe(
                map((response) => convertCapabilitiesResponseBodyToCapabilities(response)),
                catchError((error) => {
                    Logger.captureException(error);

                    return throwError(() => ({ type: SpecificPermitApplicationErrorType.CannotGetCapabilities }));
                })
            );
    }

    public getOperations(operatorId: string, query: string): Observable<OperationSearchItem[]> {
        const params = new HttpParams().set("name", query);

        return this.httpClient
            .get<OperationSearchItem[]>(StringUtils.replaceInTemplate(this.endpoints.getOperations, { operatorId }), { params })
            .pipe(
                catchError((error) => {
                    Logger.captureException(error);

                    return throwError(() => ({ type: SpecificPermitApplicationErrorType.CannotGetOperations }));
                })
            );
    }

    public getOperation(operatorId: string, operationId: string): Observable<OperationBaseInformation> {
        return this.httpClient
            .get<OperationResponseBody>(StringUtils.replaceInTemplate(this.endpoints.getOperation, { operatorId, operationId }), {
                context: new HttpContext().set(SKIP_NOT_FOUND_HTTP_INTERCEPTOR, true),
            })
            .pipe(
                map((response) => convertOperationResponseBodyToOperationBaseInformation(response)),
                catchError((error) => {
                    Logger.captureException(error);

                    return throwError(() => ({ type: SpecificPermitApplicationErrorType.CannotGetOperation }));
                })
            );
    }

    public getOperationalGeometryData(planId: string, operatorId: string): Observable<OperationalGeometryData> {
        return this.httpClient
            .get<OperationalGeometryDataResponseBody>(
                StringUtils.replaceInTemplate(this.endpoints.getCaaPermitDetails, { planId, operatorId })
            )
            .pipe(
                map((response) => convertOperationalGeometryDataResponseBodyToOperationalGeometryData(response)),
                catchError((error) => {
                    Logger.captureException(error);

                    return throwError(() => ({ type: SpecificPermitApplicationErrorType.CannotGetOperationGeometryData }));
                })
            );
    }

    public createDraft(draftName: string, operatorId: string, data: DraftData): Observable<string> {
        return this.httpClient
            .post<SaveDraftResponseBody>(StringUtils.replaceInTemplate(this.endpoints.draftsManagement, { operatorId }), {
                name: draftName,
                operatorId,
                operationId: data[ApplicationCreatorWizardSteps.OperationInfo]?.operation?.id,
                jsonContent: JSON.stringify(data),
            })
            .pipe(
                map((response) => response.id),
                catchError((error) => {
                    Logger.captureException(error);

                    return throwError(() => ({ type: SpecificPermitApplicationErrorType.CannotSaveDraft }));
                })
            );
    }

    public updateDraft(draftId: string, operatorId: string, data: DraftData): Observable<string> {
        return this.httpClient
            .put<SaveDraftResponseBody>(
                StringUtils.replaceInTemplate(this.endpoints.draftManagement, { draftId, operatorId }),
                JSON.stringify(data)
            )
            .pipe(
                map((response) => response.id),
                catchError((error) => {
                    Logger.captureException(error);

                    return throwError(() => ({ type: SpecificPermitApplicationErrorType.CannotSaveDraft }));
                })
            );
    }

    public getOperationArea(operatorId: string, operationId: string): Observable<GeoJSON> {
        return this.httpClient
            .get<GeoJSON>(StringUtils.replaceInTemplate(this.endpoints.getOperationArea, { operatorId, operationId }))
            .pipe(
                catchError((error) => {
                    Logger.captureException(error);

                    return throwError(() => ({ type: SpecificPermitApplicationErrorType.CannotGetArea }));
                })
            );
    }

    public getPredefinedArea(operatorId: string, areaId: string): Observable<PredefinedAreaDescription> {
        return this.httpClient
            .get<PredefinedAreaResponseBody>(StringUtils.replaceInTemplate(this.endpoints.getPredefinedArea, { operatorId, areaId }))
            .pipe(
                map((predefinedArea) => ({
                    area: predefinedArea.area,
                    name: predefinedArea.name,
                    description: predefinedArea.description,
                })),
                catchError((error) => {
                    Logger.captureException(error);

                    return throwError(() => ({ type: SpecificPermitApplicationErrorType.CannotGetArea }));
                })
            );
    }

    public getRequiredPilotTrainings(operatorId: string, sail: Sail, operationScenarios: OperationScenario[]): Observable<Training[]> {
        const params = new HttpParams()
            .set("sail", sail)
            .set("competenciesToScenarioIds", operationScenarios.map((operationScenario) => operationScenario.id).join(","));

        return this.httpClient
            .get<TrainingResponseBody[]>(StringUtils.replaceInTemplate(this.endpoints.getRequiredPilotTrainings, { operatorId }), {
                params,
            })
            .pipe(
                map((response) => convertTrainingResponseBodiesToTrainings(response)),
                catchError((error) => {
                    Logger.captureException(error);

                    return throwError(() => ({ type: SpecificPermitApplicationErrorType.CannotGetRequiredPilotTrainings }));
                })
            );
    }

    public getDraft(operatorId: string, draftId: string): Observable<ApplicationDraft> {
        return this.httpClient
            .get<DraftResponseBody>(StringUtils.replaceInTemplate(this.endpoints.draftManagement, { operatorId, draftId }))
            .pipe(
                map((response) => convertDraftResponseBodyToApplicationDraft(response)),
                catchError((error) => {
                    Logger.captureException(error);

                    return throwError(() => ({ type: SpecificPermitApplicationErrorType.CannotLoadDraft }));
                })
            );
    }

    public generateApplication(operatorId: string, data: ApplicationData, fileName: string) {
        return this.httpClient
            .post(
                StringUtils.replaceInTemplate(this.endpoints.generateApplication, { operatorId }),
                convertApplicationDataToApplicationDataRequestPayload(operatorId, data),
                { responseType: "blob" }
            )
            .pipe(
                tap((blob: Blob) => saveAs(blob, fileName)),
                catchError((error) => {
                    Logger.captureException(error);

                    return throwError(() => ({ type: SpecificPermitApplicationErrorType.CannotGenerateApplication }));
                })
            );
    }

    public downloadDocumentTemplate(operatorId: string, type: DocumentTemplate, fileName: string) {
        const params = new HttpParams().set("templateType", type);

        return this.httpClient
            .get(StringUtils.replaceInTemplate(this.endpoints.downloadDocumentTemplate, { operatorId }), {
                params,
                responseType: "blob",
                context: new HttpContext().set(SKIP_NOT_FOUND_HTTP_INTERCEPTOR, true),
            })
            .pipe(
                tap((blob: Blob) => saveAs(blob, fileName)),
                catchError((error) => {
                    Logger.captureException(error);

                    return throwError(() => ({ type: SpecificPermitApplicationErrorType.CannotDownloadDocumentTemplate }));
                })
            );
    }
}
