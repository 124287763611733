import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, HostBinding, Input } from "@angular/core";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { MAXIMUM_KILOMETERS_PER_HOUR_FRACTION_DIGITS, TechnicalSpecification } from "../../../models/uav.models";

export interface FlightTechnicalPropertiesComponentState {
    properties: TechnicalSpecification | undefined;
    isNarrow: boolean;
}

@Component({
    selector: "dtm-uav-lib-flight-technical-properties",
    templateUrl: "./flight-technical-properties.component.html",
    styleUrls: ["./flight-technical-properties.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class FlightTechnicalPropertiesComponent {
    @Input({ required: true }) public set properties(value: TechnicalSpecification) {
        this.localStore.patchState({ properties: value });
    }

    @Input() public set isNarrow(value: BooleanInput) {
        this.localStore.patchState({ isNarrow: coerceBooleanProperty(value) });
    }

    @HostBinding("class.narrow") protected get isNarrowMode() {
        return this.localStore.selectSnapshotByKey("isNarrow");
    }

    protected readonly properties$ = this.localStore.selectByKey("properties").pipe(RxjsUtils.filterFalsy());
    protected readonly MAXIMUM_KILOMETERS_PER_HOUR_FRACTION_DIGITS = MAXIMUM_KILOMETERS_PER_HOUR_FRACTION_DIGITS;

    constructor(private readonly localStore: LocalComponentStore<FlightTechnicalPropertiesComponentState>) {
        this.localStore.setState({
            properties: undefined,
            isNarrow: false,
        });
    }

    protected isEmpty(value: unknown) {
        return value === null || value === undefined;
    }
}
