import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { FormalJustification } from "@dtm-frontend/shared/mission";
import { UploadedFile } from "@dtm-frontend/shared/ui";
import { BYTES_IN_MEGABYTE, FormStateController, LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import equal from "fast-deep-equal";

interface MessageToManagerComponentState {
    formalJustification: FormalJustification | undefined;
    isEditorMode: boolean;
    isProcessing: boolean;
    planId: string | undefined;
    isPriorityEnabled: boolean;
}

const MAX_MESSAGE_LENGTH = 200;
const MAX_FILE_SIZE_MB = 10;
const MAX_FILE_SIZE_IN_BYTES = MAX_FILE_SIZE_MB * BYTES_IN_MEGABYTE;
const ALLOWED_FILES_EXTENSIONS = [
    ".apng",
    ".avif",
    ".docx",
    ".doc",
    ".gif",
    ".jpeg",
    ".jpg",
    ".odt",
    ".pdf",
    ".png",
    ".svg",
    ".text",
    ".txt",
    ".webp",
    ".zip",
];

@Component({
    selector: "dtm-web-app-lib-message-to-manager",
    templateUrl: "./message-to-manager.component.html",
    styleUrls: ["./message-to-manager.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class MessageToManagerComponent {
    @Input() public set justification(value: FormalJustification | undefined) {
        if (equal(value, this.localStore.selectSnapshotByKey("formalJustification"))) {
            return;
        }

        this.localStore.patchState({ formalJustification: value });

        this.justificationFormGroup.setValue(
            {
                reason: value?.reason ?? "",
                attachments: value?.attachments ?? [],
                shouldRequestForPriority: !!value?.shouldRequestForPriority,
            },
            { emitEvent: false }
        );

        if (value?.reason || value?.attachmentIds?.length || value?.shouldRequestForPriority) {
            this.switchToEditorMode();
        }

        this.justificationFormGroupController.save();
    }
    @Input() public set readonly(value: BooleanInput) {
        if (coerceBooleanProperty(value)) {
            this.switchToEditorMode();
            this.justificationFormGroup.disable({ emitEvent: false });

            return;
        }
        this.justificationFormGroup.enable({ emitEvent: false });
    }
    @Input() public set planId(value: string | undefined) {
        this.localStore.patchState({ planId: value });
    }
    @Input() public set isPriorityEnabled(value: BooleanInput) {
        const isPriorityEnabled = coerceBooleanProperty(value);
        this.localStore.patchState({ isPriorityEnabled });

        if (!isPriorityEnabled) {
            this.dtmPriorityFormControl.disable();
        } else {
            this.dtmPriorityFormControl.enable();
        }

        if ((!isPriorityEnabled && this.dtmPriorityFormControl.value) || (isPriorityEnabled && this.dtmPriorityFormControl.disabled)) {
            this.save();
        }
    }

    protected readonly dtmReasonFormControl = new FormControl<string>("", {
        nonNullable: true,
        validators: Validators.maxLength(MAX_MESSAGE_LENGTH),
    });
    protected readonly dtmPriorityFormControl = new FormControl<boolean>(false, { nonNullable: true });
    protected readonly dtmAttachmentsFormControl = new FormControl<UploadedFile[] | undefined>(undefined, { nonNullable: true });
    protected readonly justificationFormGroup = new FormGroup({
        reason: this.dtmReasonFormControl,
        attachments: this.dtmAttachmentsFormControl,
        shouldRequestForPriority: this.dtmPriorityFormControl,
    });

    protected readonly justificationFormGroupController = new FormStateController(this.justificationFormGroup, { saveInitialValue: true });

    protected readonly MAX_MESSAGE_LENGTH = MAX_MESSAGE_LENGTH;
    protected readonly MAX_FILE_SIZE_IN_BYTES = MAX_FILE_SIZE_IN_BYTES;
    protected readonly ALLOWED_FILES_EXTENSIONS = ALLOWED_FILES_EXTENSIONS;

    protected readonly isEditorMode$ = this.localStore.selectByKey("isEditorMode");
    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");
    protected readonly isPriorityEnabled$ = this.localStore.selectByKey("isPriorityEnabled");
    protected readonly planId$ = this.localStore.selectByKey("planId").pipe(RxjsUtils.filterFalsy());

    @Output() public readonly valueChanges = new EventEmitter<FormalJustification>();

    constructor(private readonly localStore: LocalComponentStore<MessageToManagerComponentState>) {
        localStore.setState({
            formalJustification: undefined,
            isEditorMode: false,
            isProcessing: false,
            planId: undefined,
            isPriorityEnabled: false,
        });
    }

    protected switchToEditorMode() {
        this.localStore.patchState({ isEditorMode: true });
    }

    protected save() {
        if (this.justificationFormGroup.invalid) {
            return;
        }

        const { shouldRequestForPriority, reason, attachments } = this.justificationFormGroup.value;

        const formalJustification: FormalJustification = {
            reason,
            attachmentIds: attachments?.map(({ id }) => id) ?? [],
            shouldRequestForPriority,
        };

        this.valueChanges.emit(formalJustification);
        this.justificationFormGroupController.save();
    }
}
