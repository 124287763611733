import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import {
    ButtonTheme,
    ConfirmationDialogComponent,
    DialogService,
    ErrorMode,
    FILES_UPLOAD_API_PROVIDER,
    GeoJSON,
    GlobalFeatures,
    OperatorType,
    UIState,
} from "@dtm-frontend/shared/ui";
import { WizardActions } from "@dtm-frontend/shared/ui/wizard";
import { AnimationUtils, FunctionUtils, LocalComponentStore, Logger, ObjectUtils, RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { ToastrService } from "ngx-toastr";
import { Observable, combineLatest, defer, of, skip } from "rxjs";
import { filter, map, startWith, switchMap, tap } from "rxjs/operators";
import { OperationsManualGuideComponent } from "../../../../shared/components/operations-manual-guide/operations-manual-guide.component";
import { Sail } from "../../../../shared/models/operations-manual.models";
import { UserContextService } from "../../../../shared/operator-context/services/user-context.service";
import { OperatorContextState } from "../../../../shared/operator-context/state/operator-context.state";
import { FilesManagementApiService } from "../../../../shared/services/files-management/files-management-api.service";
import {
    ApplicationCreatorWizardSteps,
    ApplicationInfoData,
    Area,
    OperationAreaType,
    OperationDetailsData,
    OperationInfoData,
    OsoStatementGroup,
    SpecificPermitApplicationError,
    SpecificPermitApplicationErrorType,
    StaffCompetencies,
    StatementsData,
} from "../../../services/specific-permit-application.models";
import { OPERATION_ID_QUERY_PARAM } from "../../../services/specific-permit-application.resolvers";
import { SpecificPermitApplicationActions } from "../../../state/specific-permit-application.actions";
import { SpecificPermitApplicationState } from "../../../state/specific-permit-application.state";
import { DraftNameDialogComponent } from "../../draft-name-dialog/draft-name-dialog.component";
import { AreaPreviewDialogComponent } from "../../operation-details/operation-area-control/area-preview-dialog/area-preview-dialog.component";
import { OSO_STATEMENT_GROUPS_BY_SAIL } from "../statements.data";

export const APPLICATION_WIZARD_ID = "specific-permit-application-wizard";

interface ApplicationCreatorWizardContentComponentState {
    isGuidePanelVisible: boolean;
}

@UntilDestroy()
@Component({
    templateUrl: "./application-creator-wizard-content.component.html",
    styleUrls: ["./application-creator-wizard-content.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: FILES_UPLOAD_API_PROVIDER,
            useClass: FilesManagementApiService,
        },
        LocalComponentStore,
    ],
    animations: [AnimationUtils.foldAnimation()],
})
export class ApplicationCreatorWizardContentComponent implements OnInit, OnDestroy {
    protected readonly wizardId = APPLICATION_WIZARD_ID;
    protected readonly ApplicationCreatorWizardSteps = ApplicationCreatorWizardSteps;
    protected readonly SpecificPermitApplicationErrorType = SpecificPermitApplicationErrorType;
    protected readonly ErrorMode = ErrorMode;
    protected readonly OperatorType = OperatorType;

    @ViewChild(OperationsManualGuideComponent) private readonly guideComponent!: OperationsManualGuideComponent;

    private readonly capabilities$ = this.store.select(SpecificPermitApplicationState.capabilities).pipe(RxjsUtils.filterFalsy());
    protected readonly isProcessingCapabilities$ = this.store.select(SpecificPermitApplicationState.isProcessingCapabilities);
    protected readonly error$ = this.store.select(SpecificPermitApplicationState.capabilitiesError);
    protected readonly isProcessing$: Observable<boolean> = combineLatest([
        this.store.select(SpecificPermitApplicationState.isProcessing),
        this.isProcessingCapabilities$,
    ]).pipe(map(([isProcessing, isProcessingCapabilities]) => isProcessing && !isProcessingCapabilities));
    protected readonly isSaveDraftProcessing$ = this.store.select(SpecificPermitApplicationState.isSaveDraftProcessing);
    protected readonly isOperationSearchProcessing$ = this.store.select(SpecificPermitApplicationState.isOperationSearchProcessing);
    protected readonly isOperationSelectProcessing$ = this.store.select(SpecificPermitApplicationState.isOperationSelectProcessing);
    protected readonly guideChapterItems$ = this.capabilities$.pipe(map((capabilities) => capabilities.guideChapterItems));
    protected readonly availableUavs$ = this.capabilities$.pipe(map((capabilities) => capabilities.availableUavs));
    protected readonly flightPurposes$ = this.capabilities$.pipe(map((capabilities) => capabilities.flightPurposes));
    protected readonly predefinedAreas$ = this.capabilities$.pipe(map((capabilities) => capabilities.predefinedAreas));
    protected readonly availableOperationScenarios$ = this.capabilities$.pipe(map((capabilities) => capabilities.operationScenarios));
    protected readonly availableTrainings$ = this.capabilities$.pipe(map((capabilities) => capabilities.trainings));
    protected readonly customAreaInitialViewbox$ = this.capabilities$.pipe(map((capabilities) => capabilities.initialViewbox));
    protected readonly operations$ = this.store.select(SpecificPermitApplicationState.operations);
    protected readonly operation$ = this.store.select(SpecificPermitApplicationState.operation);
    protected readonly operationPilotCompetencies$ = this.store.select(SpecificPermitApplicationState.operationPilotCompetencies);
    protected readonly operator$ = this.store.select(SpecificPermitApplicationState.operationOperator);
    protected readonly uav$ = this.store.select(SpecificPermitApplicationState.operationUav).pipe(RxjsUtils.filterFalsy());
    protected readonly activeLanguage$ = this.store.select(UIState.activeLanguage);
    protected readonly requiredPilotTrainings$ = this.store.select(SpecificPermitApplicationState.requiredPilotTrainings);
    protected readonly availableCrewMembers$ = this.capabilities$.pipe(map((capabilities) => capabilities.crewMembers));
    protected readonly operationalGeometryData$ = this.store.select(SpecificPermitApplicationState.operationalGeometryData);
    protected readonly sail$ = this.operation$.pipe(map((operation) => operation?.sail));
    protected readonly osoStatementGroups$ = this.sail$.pipe(
        RxjsUtils.filterFalsy(),
        map((sail) => OSO_STATEMENT_GROUPS_BY_SAIL[sail]),
        startWith<OsoStatementGroup[]>([])
    );
    protected readonly isGuidePanelVisible$ = this.localStore.selectByKey("isGuidePanelVisible");
    protected readonly guidePanelSail$ = this.operation$.pipe(map((operation) => operation?.sail ?? Sail.Sail1));
    protected readonly operationInfoData$ = this.store
        .select(SpecificPermitApplicationState.operationInfoData)
        .pipe(map((data) => ObjectUtils.cloneDeep(data)));
    protected readonly operationDetailsData$ = this.store
        .select(SpecificPermitApplicationState.operationDetailsData)
        .pipe(map((data) => ObjectUtils.cloneDeep(data)));
    protected readonly statementsData$ = this.store
        .select(SpecificPermitApplicationState.statementsData)
        .pipe(map((data) => ObjectUtils.cloneDeep(data)));
    protected readonly applicationInfoData$ = this.store
        .select(SpecificPermitApplicationState.applicationInfoData)
        .pipe(map((data) => ObjectUtils.cloneDeep(data)));
    protected readonly uavInfoOperationsManualChapterNumber$ = this.operationInfoData$.pipe(
        map((data) => data?.uavAdditionalInfo?.operationsManualChapter ?? undefined)
    );
    protected readonly operationInfoOperationsManualChapterNumber$ = this.operationDetailsData$.pipe(
        map((data) => data?.operationsManualChapter ?? undefined)
    );
    protected readonly isPaymentMessageVisible$ = this.store.select(
        OperatorContextState.isFeatureAvailable(GlobalFeatures.AviationLav2025)
    );

    private readonly contextSwitchTry$ = this.initContextSwitchTry();

    constructor(
        private readonly route: ActivatedRoute,
        private readonly store: Store,
        private readonly localStore: LocalComponentStore<ApplicationCreatorWizardContentComponentState>,
        private readonly dialogService: DialogService,
        private readonly transloco: TranslocoService,
        private readonly userContextService: UserContextService,
        private readonly toastService: ToastrService
    ) {
        this.localStore.setState({
            isGuidePanelVisible: false,
        });

        const operationId = this.route.snapshot.queryParams[OPERATION_ID_QUERY_PARAM];
        this.goToAndEnableStep(ApplicationCreatorWizardSteps.OperationInfo);

        if (operationId) {
            this.loadOperation(operationId);
        }
    }

    public ngOnInit() {
        this.handleContextSwitch();
        this.handleCapabilitiesLoad();
        this.handleAreaPreview();
    }

    public ngOnDestroy() {
        this.store.dispatch(new WizardActions.CleanupWizard(this.wizardId));
        this.userContextService.unregisterContextSwitchTry(this.contextSwitchTry$);
    }

    protected loadCapabilities(operatorId?: string) {
        if (!operatorId) {
            operatorId = this.store.selectSnapshot(OperatorContextState.selectedContextId);
        }

        if (operatorId) {
            this.store.dispatch(new SpecificPermitApplicationActions.GetCapabilities(operatorId));
        }
    }

    protected setGuidePanelVisibility(isVisible: boolean) {
        this.localStore.patchState({ isGuidePanelVisible: isVisible });
    }

    protected openGuidePanelForSail(sail: Sail) {
        this.guideComponent.selectSail(sail);
        this.setGuidePanelVisibility(true);
    }

    protected downloadOperationsManualTemplate() {
        this.store
            .dispatch(
                new SpecificPermitApplicationActions.DownloadOperationsManualTemplate(
                    this.transloco.translate("dtmWebAppLibSpecPermApp.manual.operationsManualTemplateFileName")
                )
            )
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(SpecificPermitApplicationState.downloadDocumentTemplateError);

                if (error) {
                    this.toastService.error(
                        this.getGeneralErrorMessage(error) ??
                            this.transloco.translate(
                                "dtmWebAppLibSpecPermApp.messages.cannotDownloadOperationsManualDocumentTemplateErrorMessage"
                            )
                    );

                    return;
                }
            });
    }

    protected downloadAttorneyPowerTemplate() {
        this.store
            .dispatch(
                new SpecificPermitApplicationActions.DownloadAttorneyPowerTemplate(
                    this.transloco.translate("dtmWebAppLibSpecPermApp.manual.attorneyPowerTemplateFileName")
                )
            )
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(SpecificPermitApplicationState.downloadDocumentTemplateError);

                if (error) {
                    this.toastService.error(
                        this.getGeneralErrorMessage(error) ??
                            this.transloco.translate(
                                "dtmWebAppLibSpecPermApp.messages.cannotDownloadAttorneyPowerDocumentTemplateErrorMessage"
                            )
                    );

                    return;
                }
            });
    }

    protected goToOperationDetailsStep(operationInfo: OperationInfoData): void {
        this.store.dispatch(new SpecificPermitApplicationActions.SetOperationInfoData(operationInfo));
        this.goToAndEnableStep(ApplicationCreatorWizardSteps.OperationDetails);
    }

    protected goToStatementsStep(operationDetails: OperationDetailsData): void {
        this.store.dispatch(new SpecificPermitApplicationActions.SetOperationDetailsData(operationDetails));
        this.goToAndEnableStep(ApplicationCreatorWizardSteps.Statements);
    }

    protected goToApplicationInfoStep(statements: StatementsData): void {
        this.store.dispatch(new SpecificPermitApplicationActions.SetStatementsData(statements));
        this.goToAndEnableStep(ApplicationCreatorWizardSteps.ApplicationInfo);
    }

    protected markValueChange() {
        this.store.dispatch(new SpecificPermitApplicationActions.MarkAsDirty());
    }

    protected goBackToStep(step: ApplicationCreatorWizardSteps) {
        const allowGoBack = this.store.selectSnapshot(SpecificPermitApplicationState.isDirty) ? this.confirmStepLeave() : of(true);

        allowGoBack
            .pipe(
                filter(FunctionUtils.isTruthy),
                switchMap(() => this.store.dispatch(new SpecificPermitApplicationActions.MarkAsDirty(false))),
                untilDestroyed(this)
            )
            .subscribe(() => {
                this.goToAndEnableStep(step);
            });
    }

    protected loadOperationsByName(query: string) {
        this.store
            .dispatch(new SpecificPermitApplicationActions.GetOperations(query))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(SpecificPermitApplicationState.operationsError);

                if (error) {
                    this.toastService.error(
                        this.getGeneralErrorMessage(error) ??
                            this.transloco.translate("dtmWebAppLibSpecPermApp.messages.cannotGetOperationsErrorMessage")
                    );

                    return;
                }
            });
    }

    protected changeOperation(operationId: string) {
        this.store.dispatch(new SpecificPermitApplicationActions.ClearForm());
        this.loadOperation(operationId);
    }

    protected loadOperation(operationId: string) {
        this.store
            .dispatch([
                new SpecificPermitApplicationActions.GetOperation(operationId),
                new SpecificPermitApplicationActions.GetOperationalGeometryData(operationId),
            ])
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(SpecificPermitApplicationState.operationError);

                if (error) {
                    this.toastService.error(
                        this.getGeneralErrorMessage(error) ??
                            this.transloco.translate("dtmWebAppLibSpecPermApp.messages.cannotGetOperationErrorMessage")
                    );

                    return;
                }

                const operationGeometryError = this.store.selectSnapshot(SpecificPermitApplicationState.operationGeometryError);

                if (operationGeometryError) {
                    this.toastService.error(
                        this.transloco.translate("dtmWebAppLibSpecPermApp.messages.cannotGetOperationGeometryErrorMessage")
                    );
                }
            });
    }

    protected showArea(area: Area): void {
        if (area.type === OperationAreaType.Operation) {
            const operation = this.store.selectSnapshot(SpecificPermitApplicationState.operation);
            if (!operation) {
                Logger.captureMessage("ApplicationCreatorWizardContentComponent.showArea: operation is empty and cannot show the area", {
                    level: "warning",
                    extra: { operation, area },
                });

                return;
            }

            this.store.dispatch(new SpecificPermitApplicationActions.GetOperationArea(operation.id));
        } else if (area.type === OperationAreaType.PredefinedArea && area.predefinedAreaId) {
            this.store.dispatch(new SpecificPermitApplicationActions.GetPredefinedArea(area.predefinedAreaId));
        }
    }

    protected fetchRequiredPilotTrainings(pilotCompetencies: StaffCompetencies): void {
        const operation = this.store.selectSnapshot(SpecificPermitApplicationState.operation);

        if (!operation) {
            Logger.captureMessage(
                // eslint-disable-next-line max-len
                "ApplicationCreatorWizardContentComponent.fetchRequiredPilotTrainings: operation is empty and cannot fetch required pilot trainings",
                {
                    level: "warning",
                    extra: { operation, pilotCompetencies },
                }
            );

            return;
        }

        this.store
            .dispatch(new SpecificPermitApplicationActions.GetRequiredPilotTrainings(operation.sail, pilotCompetencies))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(SpecificPermitApplicationState.requiredPilotTrainingsError);

                if (error) {
                    this.toastService.error(
                        this.getGeneralErrorMessage(error) ??
                            this.transloco.translate("dtmWebAppLibSpecPermApp.messages.cannotGetRequiredPilotTrainingsErrorMessage")
                    );
                }
            });
    }

    protected saveOperationInfoDraft(operationInfo: OperationInfoData): void {
        this.store
            .dispatch(new SpecificPermitApplicationActions.SetOperationInfoData(operationInfo))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.saveDraft();
            });
    }

    protected saveOperationDetailsDraft(operationDetails: OperationDetailsData): void {
        this.store
            .dispatch(new SpecificPermitApplicationActions.SetOperationDetailsData(operationDetails))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.saveDraft();
            });
    }

    protected saveStatementsDraft(statements: StatementsData): void {
        this.store
            .dispatch(new SpecificPermitApplicationActions.SetStatementsData(statements))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.saveDraft();
            });
    }

    protected saveApplicationInfoDraft(applicationInfo: ApplicationInfoData): void {
        this.store
            .dispatch(new SpecificPermitApplicationActions.SetApplicationInfoData(applicationInfo))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.saveDraft();
            });
    }

    protected suggestDraftSave(): void {
        this.dialogService
            .open(ConfirmationDialogComponent, {
                data: {
                    titleText: this.transloco.translate("dtmWebAppLibSpecPermApp.suggestDraftSaveDialog.titleText"),
                    confirmationText: this.transloco.translate("dtmWebAppLibSpecPermApp.suggestDraftSaveDialog.contentText"),
                    declineButtonLabel: this.transloco.translate("dtmWebAppLibSpecPermApp.suggestDraftSaveDialog.cancelButtonLabel"),
                    confirmButtonLabel: this.transloco.translate("dtmWebAppLibSpecPermApp.suggestDraftSaveDialog.confirmButtonLabel"),
                },
            })
            .afterClosed()
            .pipe(filter(FunctionUtils.isTruthy), untilDestroyed(this))
            .subscribe(() => {
                this.saveDraft();
            });
    }

    protected generateApplication(applicationInfo: ApplicationInfoData): void {
        this.store
            .dispatch([
                new SpecificPermitApplicationActions.SetApplicationInfoData(applicationInfo),
                new SpecificPermitApplicationActions.GenerateApplication(),
            ])
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(SpecificPermitApplicationState.generateApplicationError);

                if (!error) {
                    this.suggestDraftSave();

                    return;
                }

                let errorMessage = this.getGeneralErrorMessage(error);
                if (!errorMessage && error.type === SpecificPermitApplicationErrorType.InvalidApplicationData) {
                    errorMessage = this.transloco.translate("dtmWebAppLibSpecPermApp.messages.invalidApplicationDataErrorMessage");
                } else {
                    errorMessage = this.transloco.translate("dtmWebAppLibSpecPermApp.messages.cannotGenerateApplicationErrorMessage");
                }

                this.toastService.error(errorMessage);
            });
    }

    private goToAndEnableStep(step: ApplicationCreatorWizardSteps) {
        this.store.dispatch([new WizardActions.SetActiveStep(this.wizardId, step), new WizardActions.EnableSteps(this.wizardId, [step])]);
    }

    private initContextSwitchTry(): Observable<boolean> {
        return defer(() => this.showContextSwitchConfirmationDialog());
    }

    private showContextSwitchConfirmationDialog(): Observable<boolean> {
        return this.dialogService
            .open(ConfirmationDialogComponent, {
                data: {
                    titleText: this.transloco.translate("dtmWebAppLibSpecPermApp.confirmUserContextSwitchDialog.titleText"),
                    confirmationText: this.transloco.translate("dtmWebAppLibSpecPermApp.confirmUserContextSwitchDialog.contentText", {
                        firstStepLabel: this.transloco.translate("dtmWebAppLibSpecPermApp.operationInfoStep.header"),
                    }),
                    declineButtonLabel: this.transloco.translate(
                        "dtmWebAppLibSpecPermApp.confirmUserContextSwitchDialog.cancelButtonLabel"
                    ),
                    confirmButtonLabel: this.transloco.translate(
                        "dtmWebAppLibSpecPermApp.confirmUserContextSwitchDialog.confirmButtonLabel"
                    ),
                    theme: ButtonTheme.Warn,
                },
            })
            .afterClosed();
    }

    private handleContextSwitch(): void {
        this.userContextService.registerContextSwitchTry(this.contextSwitchTry$);

        this.store
            .select(OperatorContextState.selectedContextId)
            .pipe(skip(1), RxjsUtils.filterFalsy(), untilDestroyed(this))
            .subscribe((operatorId) => this.loadCapabilities(operatorId));
    }

    private handleCapabilitiesLoad(): void {
        this.store
            .select(SpecificPermitApplicationState.capabilities)
            .pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
            .subscribe(() => {
                this.goToAndEnableStep(ApplicationCreatorWizardSteps.OperationInfo);
            });
    }

    private confirmStepLeave(): Observable<boolean> {
        return this.dialogService
            .open(ConfirmationDialogComponent, {
                data: {
                    titleText: this.transloco.translate("dtmWebAppLibSpecPermApp.confirmStepLeaveDialog.titleText"),
                    confirmationText: this.transloco.translate("dtmWebAppLibSpecPermApp.confirmStepLeaveDialog.contentText"),
                    declineButtonLabel: this.transloco.translate("dtmWebAppLibSpecPermApp.confirmStepLeaveDialog.cancelButtonLabel"),
                    confirmButtonLabel: this.transloco.translate("dtmWebAppLibSpecPermApp.confirmStepLeaveDialog.confirmButtonLabel"),
                    theme: ButtonTheme.Warn,
                },
            })
            .afterClosed();
    }

    private saveDraft() {
        const draftName = this.store.selectSnapshot(SpecificPermitApplicationState.draftName);

        if (!draftName) {
            this.promptDraftName();

            return;
        }

        this.handleSaveDraft().pipe(untilDestroyed(this)).subscribe();
    }

    private promptDraftName(): void {
        const dialogRef = this.dialogService.open(DraftNameDialogComponent, {
            data: {
                isSaveDraftProcessing$: this.isSaveDraftProcessing$,
            },
        });

        dialogRef.componentInstance.setDraftName$
            .pipe(
                switchMap((draftName) => this.store.dispatch(new SpecificPermitApplicationActions.SetDraftName(draftName))),
                switchMap(() => this.handleSaveDraft()),
                untilDestroyed(this)
            )
            .subscribe(() => {
                dialogRef.close();
            });
    }

    private handleSaveDraft() {
        return this.store.dispatch(new SpecificPermitApplicationActions.SaveDraft()).pipe(
            tap(() => {
                const error = this.store.selectSnapshot(SpecificPermitApplicationState.saveDraftError);

                if (error) {
                    this.toastService.error(
                        this.getGeneralErrorMessage(error) ??
                            this.transloco.translate("dtmWebAppLibSpecPermApp.messages.cannotSaveDraftErrorMessage")
                    );

                    return;
                }

                this.toastService.success(this.transloco.translate("dtmWebAppLibSpecPermApp.messages.saveDraftSuccessMessage"));
            })
        );
    }

    private handleAreaPreview(): void {
        this.store
            .select(SpecificPermitApplicationState.operationArea)
            .pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
            .subscribe((area) => {
                this.showAreaDialog(
                    this.transloco.translate(
                        "dtmWebAppLibSpecPermApp.operationDetailsStep.operationArea.areaPreviewDialog.operationAreaTitleLabel"
                    ),
                    this.store.selectSnapshot(SpecificPermitApplicationState.operation)?.name,
                    area
                );
            });

        this.store
            .select(SpecificPermitApplicationState.predefinedArea)
            .pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
            .subscribe(({ area, name, description }) => {
                this.showAreaDialog(
                    this.transloco.translate(
                        "dtmWebAppLibSpecPermApp.operationDetailsStep.operationArea.areaPreviewDialog.predefinedAreaTitleLabel",
                        { name }
                    ),
                    description,
                    area
                );
            });

        this.store
            .select(SpecificPermitApplicationState.areaError)
            .pipe(
                RxjsUtils.filterFalsy(),
                filter(
                    (error) =>
                        error?.type === SpecificPermitApplicationErrorType.CannotGetArea ||
                        error?.type === SpecificPermitApplicationErrorType.InvalidOperator
                ),
                untilDestroyed(this)
            )
            .subscribe((error) => {
                this.toastService.error(
                    this.getGeneralErrorMessage(error) ??
                        this.transloco.translate("dtmWebAppLibSpecPermApp.operationDetailsStep.operationArea.cannotGetAreaErrorMessage")
                );
            });
    }

    private showAreaDialog(title: string, description: string | undefined, area: GeoJSON): void {
        this.dialogService.open(AreaPreviewDialogComponent, {
            data: { title, description, area },
        });
    }

    private getGeneralErrorMessage(error: SpecificPermitApplicationError): string | undefined {
        switch (error.type) {
            case SpecificPermitApplicationErrorType.InvalidOperator:
                return this.transloco.translate("dtmWebAppLibSpecPermApp.messages.invalidOperatorErrorMessage");
            default:
                return undefined;
        }
    }
}
