import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { EquipmentItem, EquipmentParachute, EquipmentProperties, EquipmentType, ParachuteProperties } from "../../../models/uav.models";

export interface EquipmentPropertiesComponentState {
    equipmentProperties: EquipmentItem | undefined;
    parachuteProperties: EquipmentParachute | undefined;
}

@Component({
    selector: "dtm-uav-lib-equipment-properties",
    templateUrl: "./equipment-properties.component.html",
    styleUrls: ["./equipment-properties.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class EquipmentPropertiesComponent {
    @Input() public set properties(value: EquipmentProperties | ParachuteProperties | undefined) {
        if (value && value.type === EquipmentType.Parachute) {
            this.localStore.patchState({ parachuteProperties: (value as ParachuteProperties).item, equipmentProperties: undefined });
        } else {
            this.localStore.patchState({ equipmentProperties: value?.item, parachuteProperties: undefined });
        }
    }

    protected readonly equipmentProperties$ = this.localStore.selectByKey("equipmentProperties").pipe(RxjsUtils.filterFalsy());
    protected readonly parachuteProperties$ = this.localStore.selectByKey("parachuteProperties").pipe(RxjsUtils.filterFalsy());

    constructor(private readonly localStore: LocalComponentStore<EquipmentPropertiesComponentState>) {
        this.localStore.setState({
            equipmentProperties: undefined,
            parachuteProperties: undefined,
        });
    }

    protected isEmpty(value: unknown) {
        return value === null || value === undefined;
    }
}
