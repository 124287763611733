<dtm-ui-loader-container
    [isShown]="vm.isProcessing"
    *ngrxLet="{
        operators: operators$,
        pilot: pilot$,
        posts: posts$,
        isProcessing: isProcessing$,
        postsError: postsError$,
        isMissionPlanningFeatureAvailable: isMissionPlanningFeatureAvailable$,
    } as vm"
>
    <section *ngIf="vm.operators?.length || vm.pilot; else welcomeViewTemplate" class="grid">
        <dtm-web-app-lib-posts [error]="vm.postsError" [posts]="vm.posts" [language]="activeLanguage$ | ngrxPush"></dtm-web-app-lib-posts>
        <dtm-web-app-lib-dashboard-missions
            *ngIf="vm.isMissionPlanningFeatureAvailable"
            [missions]="missions$ | ngrxPush"
            [totalMissionElements]="totalMissionElements$ | ngrxPush"
            [error]="missionsError$ | ngrxPush"
            [isProcessing]="areMissionsProcessing$ | ngrxPush"
            [incomingFilters]="missionsFilters$ | ngrxPush"
            [isOperatorContext]="isOperatorContext$ | ngrxPush"
            (filtersChange)="getMissions($event)"
        ></dtm-web-app-lib-dashboard-missions>
    </section>
    <ng-template #welcomeViewTemplate>
        <dtm-web-app-lib-welcome-view [isPansaUtmLinkInfoVisible]="vm.isMissionPlanningFeatureAvailable"></dtm-web-app-lib-welcome-view>
    </ng-template>
</dtm-ui-loader-container>
