import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, HostBinding, Input } from "@angular/core";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { Communication, CommunicationType } from "../../../models/uav.models";

export interface CommunicationPropertiesComponentState {
    properties: Communication | undefined;
    isNarrow: boolean;
}

@Component({
    selector: "dtm-uav-lib-communication-properties",
    templateUrl: "./communication-properties.component.html",
    styleUrls: ["./communication-properties.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class CommunicationPropertiesComponent {
    @Input() public set properties(value: Communication | undefined) {
        this.localStore.patchState({ properties: value });
    }

    @Input() public set isNarrow(value: BooleanInput) {
        this.localStore.patchState({ isNarrow: coerceBooleanProperty(value) });
    }

    @HostBinding("class.narrow") protected get isNarrowMode() {
        return this.localStore.selectSnapshotByKey("isNarrow");
    }

    protected readonly properties$ = this.localStore.selectByKey("properties").pipe(RxjsUtils.filterFalsy());
    protected readonly CommunicationType = CommunicationType;

    constructor(private readonly localStore: LocalComponentStore<CommunicationPropertiesComponentState>) {
        this.localStore.setState({
            properties: undefined,
            isNarrow: false,
        });
    }

    protected isEmpty(value: unknown) {
        return value === null || value === undefined;
    }
}
