import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy } from "@angular/core";
import { ActiveToast, ToastrService } from "ngx-toastr";
import { FtsAlertToastComponent } from "./fts-alert-toast/fts-alert-toast.component";

@Component({
    selector: "dtm-web-app-lib-fts-alert-container",
    template: "",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FtsAlertContainerComponent implements AfterViewInit, OnDestroy {
    protected alertToast: ActiveToast<unknown> | undefined;

    constructor(private readonly toastrService: ToastrService) {}

    public ngAfterViewInit(): void {
        this.alertToast = this.toastrService.warning(undefined, undefined, { toastComponent: FtsAlertToastComponent });
    }

    public ngOnDestroy(): void {
        this.toastrService.clear(this.alertToast?.toastId);
        this.alertToast = undefined;
    }
}
