import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { RegulationExemptions } from "../../../../../models/mission.model";

interface RegulationExemptionsInfoComponentState {
    regulationExemptions: RegulationExemptions | undefined;
}

@Component({
    selector: "dtm-web-app-lib-regulation-exemptions-info",
    templateUrl: "./regulation-exemptions-info.component.html",
    styleUrls: ["./regulation-exemptions-info.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class RegulationExemptionsInfoComponent {
    @Input() public set regulationExemptions(value: RegulationExemptions | undefined) {
        this.localStore.patchState({ regulationExemptions: value });
    }

    protected readonly regulationExemptions$ = this.localStore.selectByKey("regulationExemptions");

    constructor(private readonly localStore: LocalComponentStore<RegulationExemptionsInfoComponentState>) {
        this.localStore.setState({ regulationExemptions: undefined });
    }
}
