import { FailSafe, TechnicalSpecification } from "@dtm-frontend/shared/uav";

export interface EditableGeneralTechnicalProperties {
    failSafe: FailSafe[];
    hasGeofencing: boolean;
    hasGeocage: boolean;
    hasEmergencyMotorStop: boolean;
}

type GeneralTechnicalSpecification = Pick<TechnicalSpecification, "failSafe" | "hasGeofencing" | "hasGeocage" | "hasEmergencyMotorStop">;

export class GeneralTechnicalPropertiesUtils {
    public static getFormValuesBySetupDefaults(technicalSpecification: GeneralTechnicalSpecification): EditableGeneralTechnicalProperties {
        return {
            failSafe: technicalSpecification.failSafe,
            hasGeofencing: technicalSpecification.hasGeofencing,
            hasGeocage: technicalSpecification.hasGeocage,
            hasEmergencyMotorStop: technicalSpecification.hasEmergencyMotorStop,
        };
    }

    public static prepareResultTechnicalSpecificationSetup(properties: EditableGeneralTechnicalProperties): GeneralTechnicalSpecification {
        return {
            failSafe: properties.failSafe,
            hasGeofencing: properties.hasGeofencing,
            hasGeocage: properties.hasGeocage,
            hasEmergencyMotorStop: properties.hasEmergencyMotorStop,
        };
    }
}
