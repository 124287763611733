import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { UavType } from "@dtm-frontend/shared/ui";

interface CustomUavConfirmationDialogData {
    manufacturerName: string;
    modelName: string;
    type: UavType;
    isSwarm: boolean;
    serialNumbers: string[];
}

@Component({
    selector: "dtm-web-app-lib-custom-uav-confirmation-dialog",
    templateUrl: "./custom-uav-confirmation-dialog.component.html",
    styleUrls: ["./custom-uav-confirmation-dialog.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomUavConfirmationDialogComponent {
    constructor(@Inject(MAT_DIALOG_DATA) protected data: CustomUavConfirmationDialogData) {}
}
