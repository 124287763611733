<button type="button" class="button-icon" [matDialogClose]="undefined">
    <dtm-ui-icon name="close"></dtm-ui-icon>
</button>
<div class="content">
    <h4 mat-dialog-title *ngIf="data.isSwarm">
        {{ "dtmWebAppLibUav.newUavWizardSteps.basicInfo.confirmCustomUavDataDialog.titleWithoutSerialNumberText" | transloco }}
    </h4>
    <h4 mat-dialog-title *ngIf="!data.isSwarm">
        {{ "dtmWebAppLibUav.newUavWizardSteps.basicInfo.confirmCustomUavDataDialog.titleText" | transloco }}
    </h4>
    <p>
        {{ "dtmWebAppLibUav.newUavWizardSteps.basicInfo.confirmCustomUavDataDialog.contentIntroLabel" | transloco }}
    </p>
    <ul class="data-items">
        <li>
            {{ "dtmWebAppLibUav.newUavWizardSteps.basicInfo.confirmCustomUavDataDialog.manufacturerLabel" | transloco }}:
            <strong>{{ data.manufacturerName }}</strong>
        </li>
        <li>
            {{ "dtmWebAppLibUav.newUavWizardSteps.basicInfo.confirmCustomUavDataDialog.modelLabel" | transloco }}:
            <strong>{{ data.modelName }}</strong>
        </li>
        <li>
            {{ "dtmWebAppLibUav.newUavWizardSteps.basicInfo.confirmCustomUavDataDialog.typeLabel" | transloco }}:
            <strong>{{ "dtmWebAppLibUav.uavTypeValue" | transloco : { value: data.type } }}</strong>
        </li>
        <li *ngIf="!data.isSwarm">
            {{ "dtmWebAppLibUav.newUavWizardSteps.basicInfo.confirmCustomUavDataDialog.serialNumberLabel" | transloco }}:
            <strong>{{ data.serialNumbers[0] }}</strong>
        </li>
    </ul>
</div>

<mat-dialog-actions align="end">
    <button type="button" [mat-dialog-close]="false" class="button-secondary">
        {{ "dtmWebAppLibUav.newUavWizardSteps.basicInfo.confirmCustomUavDataDialog.cancelButtonLabel" | transloco }}
    </button>
    <button type="button" class="button-primary" [mat-dialog-close]="true" cdkFocusInitial>
        {{ "dtmWebAppLibUav.newUavWizardSteps.basicInfo.confirmCustomUavDataDialog.confirmButtonLabel" | transloco }}
    </button>
</mat-dialog-actions>
