import { ChangeDetectionStrategy, Component, OnDestroy } from "@angular/core";
import { GuardsCheckEnd } from "@angular/router";
import { AZURE_MAPS_LAYER_OPTIONS } from "@dtm-frontend/shared/map/cesium";
import { GeoZonesActions, ZoneTimesSetting } from "@dtm-frontend/shared/map/geo-zones";
import {
    MissionCategory,
    MissionContextType,
    MissionPlanDataAndCapabilities,
    MissionPlanInformation,
    MissionPreviewData,
    MissionProcessingPhase,
    MissionType,
    OperationalGeometryData,
    SoraSettings,
    TacticalMitigationPerformanceRequirementProperty,
} from "@dtm-frontend/shared/mission";
import { ContextOperator, FILES_UPLOAD_API_PROVIDER, GlobalFeatures, ItineraryEditorType } from "@dtm-frontend/shared/ui";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { MapLayerProviderOptions, SceneMode } from "@pansa/ngx-cesium";
import { Observable, combineLatest, filter, map } from "rxjs";
import { OperatorContextState } from "../../../shared/operator-context/state/operator-context.state";
import { PlanFilesUploadApiService } from "../../services/plan-files-upload-api.service";
import { MissionActions } from "../../state/mission.actions";
import { MissionState } from "../../state/mission.state";
import { AdditionalInformationSettings } from "../mission-notes-and-description/personal-notes.component";
import { MissionBaseComponent } from "../mission.base.component";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const Cesium: any; // TODO: DTM-966

interface MissionDetailsComponentState {
    selectedOtherMissionId: string | undefined;
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-mission-details",
    templateUrl: "./mission-details.component.html",
    styleUrls: ["./mission-details.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore, { provide: FILES_UPLOAD_API_PROVIDER, useClass: PlanFilesUploadApiService }],
})
export class MissionDetailsComponent extends MissionBaseComponent<MissionDetailsComponentState> implements OnDestroy {
    protected readonly isUtmSupervisorIntegrated$ = this.store.select(
        OperatorContextState.isFeatureAvailable(GlobalFeatures.UtmSupervisorIntegration)
    );
    protected readonly missionData$ = combineLatest([this.currentPlanDataAndCapabilities$, this.operators$]).pipe(
        map(([planDataAndCapabilities, operators]) => this.prepareMissionPreviewData(planDataAndCapabilities, operators))
    );
    protected readonly missionName$ = this.currentPlanDataAndCapabilities$.pipe(
        map((planDataAndCapabilities) => planDataAndCapabilities?.plan.information?.name)
    );
    protected readonly flightPurposes$ = this.currentPlanDataAndCapabilities$.pipe(
        map((planDataAndCapabilities) =>
            (planDataAndCapabilities?.flightPurposes ?? []).reduce((result, item) => ({ ...result, [item.id]: item.name }), {})
        )
    );
    protected readonly operationalGeometryForPreview$: Observable<OperationalGeometryData | undefined> = this.operationalGeometryData$;
    protected readonly soraSettingsForPreview$: Observable<SoraSettings | undefined> = this.soraSettings$;

    protected readonly MissionCategory = MissionCategory;
    protected readonly ItineraryEditorType = ItineraryEditorType;
    protected readonly ZoneTimesSetting = ZoneTimesSetting;
    protected readonly MissionContextType = MissionContextType;
    protected readonly MissionProcessingPhase = MissionProcessingPhase;
    protected readonly MissionType = MissionType;
    protected readonly SceneMode = SceneMode;
    protected readonly MapLayerProviderOptions = MapLayerProviderOptions;
    protected readonly TacticalMitigationPerformanceRequirementProperty = TacticalMitigationPerformanceRequirementProperty;
    protected readonly AZURE_MAPS_LAYER_OPTIONS = AZURE_MAPS_LAYER_OPTIONS;

    constructor(protected readonly localStore: LocalComponentStore<MissionDetailsComponentState>) {
        super(localStore);

        localStore.setState({
            selectedOtherMissionId: undefined,
        });

        this.store.dispatch([new GeoZonesActions.SetCustomZonesVisibility(true), MissionActions.StartMissionStatusUpdateWatch]);

        this.cleanupOnRouteNavigation();
    }

    public ngOnDestroy(): void {
        this.store.dispatch(new GeoZonesActions.SetCustomZonesVisibility(false));
    }

    protected updateAdditionalInformation(additionalInformation: AdditionalInformationSettings) {
        const planId = this.store.selectSnapshot(MissionState.currentPlan)?.id;
        if (!planId) {
            return;
        }

        const { description, name, notes } = additionalInformation;

        const payload: MissionPlanInformation = {
            description: description?.trim(),
            notes: notes?.trim(),
            name: name?.trim(),
        };

        this.store.dispatch(new MissionActions.UpdateAdditionalInformation(planId, payload));
    }

    private cleanupOnRouteNavigation() {
        this.router.events
            .pipe(
                filter((event): event is GuardsCheckEnd => event instanceof GuardsCheckEnd),
                untilDestroyed(this)
            )
            .subscribe((event) => {
                if (event.url === this.router.url || event.shouldActivate === false) {
                    return;
                }

                this.store.dispatch([
                    MissionActions.CleanupMissionWizard,
                    MissionActions.StopMissionStatusUpdateWatch,
                    MissionActions.StopMissionPlanAnalysisStatusWatch,
                ]);
            });
    }

    private prepareMissionPreviewData(
        planAndCapabilities: MissionPlanDataAndCapabilities | undefined,
        operators: { [key: string]: ContextOperator }
    ): MissionPreviewData | undefined {
        const missionPlan = planAndCapabilities?.plan;

        if (!missionPlan) {
            return undefined;
        }

        return {
            id: missionPlan.id,
            phase: missionPlan.phase,
            pilotName: missionPlan.capabilities.pilotName,
            operatorName: operators?.[missionPlan.capabilities.operator]?.name,
            flightStartAtMin: missionPlan.flightStartAtMin,
            flightStartAtMax: missionPlan.flightStartAtMax,
            flightFinishAtMin: missionPlan.flightFinishAtMin,
            flightFinishAtMax: missionPlan.flightFinishAtMax,
            flightType: missionPlan.flightType,
            additionalCrew: missionPlan.capabilities.additionalCrew,
            uavName: missionPlan.capabilities.uavName,
            uavSerialNumbers: missionPlan.uav.serialNumbers,
            setupName: missionPlan.capabilities.setupName,
            trackersIdentifiers: missionPlan.capabilities.trackersIdentifiers,
            flightPurpose: missionPlan.flightPurpose,
            routeId: missionPlan.route?.id,
            itineraryEditorType: missionPlan.itinerary?.editorType ?? ItineraryEditorType.None,
            rejectionJustification: missionPlan.mission?.rejectionJustification,
            rejectedAt: missionPlan.mission?.rejectedAt,
            category: missionPlan.category,
            remarks: missionPlan.remarks,
        };
    }
}
