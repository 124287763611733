import { MissionType } from "@dtm-frontend/shared/mission";
import {
    Address,
    AdjacentGroundAreaCharacteristicType,
    AirRiskMitigationType,
    AssociationOperationType,
    AssociationPermit,
    AvoidingFlightsOverPeopleType,
    CaaContactPerson,
    ContactPerson,
    ContainmentLevelReachedType,
    ControlledGroundAreaCharacteristicType,
    GroundAreaCharacteristicType,
    GroundRiskMitigationType,
    LocalizationType,
    OperationalAirspaceClassType,
    OperationalArc,
    PageResponseBody,
    PermitType,
    PermitUavModel,
    PhoneNumber,
    RiskAssessmentReference,
    Sail,
    TacticalAirRiskMitigationType,
} from "@dtm-frontend/shared/ui";

import { DateUtils } from "@dtm-frontend/shared/utils";
import {
    OwnedPermitStatus,
    OwnedSpecificPermitListWithPages,
    SpecificPermitApplicationDraftStatus,
    SpecificPermitDraftsListWithPages,
} from "./operator-permits.models";
import { RawPermitDetails } from "./operator-permits.utils";

interface Uav {
    modelId: string;
    operatedNumber: number;
    serialNumbers: string;
    maxWidth: number;
    takeOffMass: number;
    maxFlightSpeed: number;
    additionalTechRequirements: string;
    dvrOrTc: string;
    cofA: string;
    noiseCertificate: string;
    groundRiskMitigation: GroundRiskMitigationType;
    swarm: boolean;
}

interface CaaContactUserResponseBody extends Omit<CaaContactPerson, "id"> {
    userId: string;
}

export interface SpecificPermitListItemResponseBody {
    id: string;
    number: string;
    type: PermitType;
    flightPurposes: string;
    status: SpecificPermitApplicationDraftStatus | OwnedPermitStatus;
    statusReason?: string;
    name: string;
    permitNumber: string;
    operatorId: string;
    applicationId?: string;
    draftId?: string;
    createdAt: string;
    validityPeriodStart: string;
    validityPeriodFinish: string;
}

export interface SpecificPermitApplicationDraftListItemResponseBody {
    id: string;
    name: string;
    createdAt: string;
    modifiedAt: string;
    operationName: string;
}

export type SpecificPermitListResponseBody = PageResponseBody<SpecificPermitListItemResponseBody>;
export type SpecificPermitDraftsListResponseBody = PageResponseBody<SpecificPermitApplicationDraftListItemResponseBody>;

export interface OperatorPermitWithUavModelResponseBody {
    uavModel: PermitUavModel;
    specificPermit: OperatorPermitResponseBody;
}

export interface OperatorPermitResponseBody {
    id: string;
    name: string;
    operatorId: string;
    operatorName: string;
    operatorNumber: string;
    operatorContact: ContactPerson;
    permitNumber: string;
    validityPeriodStart: string;
    validityPeriodFinish: string;
    flightPurposes: string;
    location: {
        type: LocalizationType;
        kmlFileId?: string;
        kmlFileName?: string;
        dtmId?: string;
        description?: string;
    };
    riskAssessmentReference: RiskAssessmentReference;
    pdraNumber: string;
    assuranceAndIntegrityLevel: Sail;
    operationType: MissionType;
    dangerousGoodsTransport: boolean;
    groundAreaCharacteristic: GroundAreaCharacteristicType;
    controlledGroundAreaCharacteristic?: ControlledGroundAreaCharacteristicType;
    adjacentGroundAreaCharacteristic: AdjacentGroundAreaCharacteristicType;
    adjacentGroundAreaExtent: number;
    operationalVolumeHeightLimit: number;
    operationalAirspaces: OperationalAirspaceClassType[];
    otherAirspace?: string;
    operationalArc: OperationalArc;
    adjacentArc: OperationalArc;
    strategicGroundRiskMitigation: GroundRiskMitigationType;
    emergencyResponsePlan?: GroundRiskMitigationType;
    avoidingFlightsOverPeople: AvoidingFlightsOverPeopleType;
    strategicAirRiskMitigations: AirRiskMitigationType[];
    tacticalAirRiskMitigation: TacticalAirRiskMitigationType;
    tacticalAirRiskMitigationRequirements: string;
    achievedContainmentLevel: ContainmentLevelReachedType;
    pilotCompetencies: string[];
    pilotCompetenciesFreeText?: string;
    personnelCompetencies?: string[];
    personnelCompetenciesFreeText?: string;
    pilotAdditionalCompetencies?: string[];
    insurance: boolean;
    operationsManualReference: string;
    additionalLimitations: string;
    remarks?: string;
    caaContact: CaaContactUserResponseBody;
    createdAt: string;
    status: string;
    statusReason: string;
    uav: Uav;
}

export interface AssociationPermitResponseBody {
    id: string;
    type: PermitType;
    name: string;
    caaContact: CaaContactPerson;
    associationInfo: {
        operatorId: string;
        operatorNumber: string;
        name: string;
        nationalCourtRegister?: string;
        registrationNumber?: string;
        companyNumber: string;
        phoneNumber: PhoneNumber;
        email: string;
        address: Address;
    };
    operatorContact: ContactPerson;
    permitNumber: string;
    validityPeriodStart: string;
    validityPeriodFinish: string;
    flightPurposes: string;
    locations: string;
    operationType: AssociationOperationType;
    insurance: boolean;
    createdAt: string;
    status: string;
    statusReason: string;
}

export interface CrossBorderPermitResponseBody {
    confirmationNumber: string;
    foreignSpecificPermit: CrossBorderOperatorPermitResponseBody;
    uavModel: PermitUavModel;
}

type CrossBorderOperatorPermitResponseBody = Omit<OperatorPermitResponseBody, "uavModel">;

export function convertOperatorPermitResponseBodyToRawPermitDetails(
    response: OperatorPermitWithUavModelResponseBody,
    confirmationNumber?: string
): RawPermitDetails {
    return {
        id: response.specificPermit.id,
        operatorName: response.specificPermit.operatorName,
        operatorNumber: response.specificPermit.operatorNumber,
        createdAt: new Date(response.specificPermit.createdAt),
        caaContact: {
            id: response.specificPermit.caaContact.userId,
            firstName: response.specificPermit.caaContact.firstName,
            lastName: response.specificPermit.caaContact.lastName,
            email: response.specificPermit.caaContact.email,
            phoneNumber: response.specificPermit.caaContact.phoneNumber,
        },
        operatorId: response.specificPermit.operatorId,
        operatorContact: response.specificPermit.operatorContact,
        permitNumber: response.specificPermit.permitNumber,
        permitConfirmationNumber: confirmationNumber,
        validityPeriodStart: DateUtils.convertStringDateToDateWithoutTimeZone(response.specificPermit.validityPeriodStart),
        validityPeriodFinish: DateUtils.convertStringDateToDateWithoutTimeZone(response.specificPermit.validityPeriodFinish),
        flightPurposes: response.specificPermit.flightPurposes,
        location: response.specificPermit.location,
        riskAssessmentReference: response.specificPermit.riskAssessmentReference,
        pdraNumber: response.specificPermit.pdraNumber,
        assuranceAndIntegrityLevel: response.specificPermit.assuranceAndIntegrityLevel,
        operationType: response.specificPermit.operationType,
        isDangerousGoodsTransport: response.specificPermit.dangerousGoodsTransport,
        groundAreaCharacteristic: response.specificPermit.groundAreaCharacteristic,
        controlledGroundAreaCharacteristic: response.specificPermit.controlledGroundAreaCharacteristic,
        adjacentGroundAreaCharacteristic: response.specificPermit.adjacentGroundAreaCharacteristic,
        adjacentGroundAreaExtent: response.specificPermit.adjacentGroundAreaExtent,
        operationalVolumeHeightLimit: response.specificPermit.operationalVolumeHeightLimit,
        operationalAirSpaces: response.specificPermit.operationalAirspaces,
        otherAirspace: response.specificPermit.otherAirspace,
        operationalArc: response.specificPermit.operationalArc,
        adjacentArc: response.specificPermit.adjacentArc,
        strategicGroundRiskMitigation: response.specificPermit.strategicGroundRiskMitigation,
        emergencyResponsePlan: response.specificPermit.emergencyResponsePlan,
        avoidingFlightsOverPeople: response.specificPermit.avoidingFlightsOverPeople,
        strategicAirRiskMitigations: response.specificPermit.strategicAirRiskMitigations,
        tacticalAirRiskMitigation: response.specificPermit.tacticalAirRiskMitigation,
        tacticalAirRiskMitigationRequirements: response.specificPermit.tacticalAirRiskMitigationRequirements,
        achievedContainmentLevel: response.specificPermit.achievedContainmentLevel,
        pilotCompetencies: response.specificPermit.pilotCompetencies,
        pilotAdditionalCompetencies: response.specificPermit.pilotAdditionalCompetencies,
        pilotCompetenciesFreeText: response.specificPermit.pilotCompetenciesFreeText,
        personnelCompetencies: response.specificPermit.personnelCompetencies,
        personnelCompetenciesFreeText: response.specificPermit.personnelCompetenciesFreeText,
        isInsurance: response.specificPermit.insurance,
        operationsManualReference: response.specificPermit.operationsManualReference,
        additionalLimitations: response.specificPermit.additionalLimitations,
        uav: {
            modelId: response.specificPermit.uav.modelId,
            operatedNumber: response.specificPermit.uav.operatedNumber,
            serialNumbers: response.specificPermit.uav.serialNumbers,
            maxWidth: response.specificPermit.uav.maxWidth,
            takeOffMass: response.specificPermit.uav.takeOffMass,
            maxFlightSpeed: response.specificPermit.uav.maxFlightSpeed,
            additionalTechRequirements: response.specificPermit.uav.additionalTechRequirements,
            designVerificationReport: response.specificPermit.uav.dvrOrTc,
            certificateOfAirworthinessNumber: response.specificPermit.uav.cofA,
            noiseCertificate: response.specificPermit.uav.noiseCertificate,
            groundRiskMitigation: response.specificPermit.uav.groundRiskMitigation,
            isSwarm: response.specificPermit.uav.swarm,
        },
        uavModel: response.uavModel,
    };
}

export function convertCrossBorderPermitResponseBodyToRawPermitDetails(response: CrossBorderPermitResponseBody): RawPermitDetails {
    return convertOperatorPermitResponseBodyToRawPermitDetails(
        { specificPermit: response.foreignSpecificPermit, uavModel: response.uavModel },
        response.confirmationNumber
    );
}

export function convertOwnedSpecificPermitListResponseBodyToOwnedSpecificPermitListWithPages(
    response: SpecificPermitListResponseBody
): OwnedSpecificPermitListWithPages {
    return {
        pageNumber: response.number,
        pageSize: response.size,
        totalElements: response.totalElements,
        content: response.content.map((responseListItem) => ({
            id: responseListItem.id,
            number: responseListItem.number,
            type: responseListItem.type,
            flightPurposes: responseListItem.flightPurposes,
            status: responseListItem.status as OwnedPermitStatus,
            statusReason: responseListItem.statusReason,
            name: responseListItem.name,
            permitNumber: responseListItem.permitNumber,
            operatorId: responseListItem.operatorId,
            applicationId: responseListItem.applicationId,
            draftId: responseListItem.draftId,
            createdAt: new Date(responseListItem.createdAt),
            validityPeriodStart: DateUtils.convertStringDateToDateWithoutTimeZone(responseListItem.validityPeriodStart),
            validityPeriodFinish: DateUtils.convertStringDateToDateWithoutTimeZone(responseListItem.validityPeriodFinish),
        })),
    };
}

export function convertSpecificPermitDraftsListResponseBodyToSpecificPermitDraftsListWithPages(
    response: SpecificPermitDraftsListResponseBody
): SpecificPermitDraftsListWithPages {
    return {
        pageNumber: response.number,
        pageSize: response.size,
        totalElements: response.totalElements,
        content: response.content.map((responseListItem) => ({
            id: responseListItem.id,
            name: responseListItem.name,
            operationName: responseListItem.operationName,
        })),
    };
}

export function convertAssociationPermitResponseBodyToAssociationPermit(response: AssociationPermitResponseBody): AssociationPermit {
    return {
        caaContact: { ...response.caaContact, phoneNumber: undefined },
        flightPurpose: response.flightPurposes,
        id: response.id,
        isInsurance: response.insurance,
        locations: response.locations,
        operationType: response.operationType,
        operatorContact: response.operatorContact,
        permitNumber: response.permitNumber,
        type: PermitType.Association,
        expirationDate: {
            dateFrom: DateUtils.convertStringDateToDateWithoutTimeZone(response.validityPeriodStart),
            dateTo: DateUtils.convertStringDateToDateWithoutTimeZone(response.validityPeriodFinish),
        },
        associationInfo: {
            associationNationalCourtRegister: response.associationInfo.nationalCourtRegister,
            associationRegistrationNumber: response.associationInfo.registrationNumber,
            address: response.associationInfo.address,
            id: response.associationInfo.operatorId,
            name: response.associationInfo.name,
            number: response.associationInfo.operatorNumber,
        },
    };
}
