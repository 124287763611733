import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { OperationalGeometryData } from "@dtm-frontend/shared/mission";
import { ButtonTheme, ConfirmationDialogComponent, InvalidFormScrollableDirective } from "@dtm-frontend/shared/ui";
import { FormType, LocalComponentStore, PAYMENT_CURRENCY_SYMBOL } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import equal from "fast-deep-equal";
import { Observable, of, pairwise, skip, switchMap } from "rxjs";
import { distinctUntilChanged, filter, map, startWith } from "rxjs/operators";
import { Sail } from "../../../../../shared/models/operations-manual.models";
import {
    FlightPurpose,
    Operation,
    OperationInfoData,
    OperationOperator,
    OperationSearchItem,
    OperationUav,
    Uav,
    UavAdditionalInfo,
    UavInfo,
} from "../../../../services/specific-permit-application.models";
import { UavAdditionalInfoComponent } from "../../../operation-info/uav-additional-info/uav-additional-info.component";

interface OperationInfoStepComponentState {
    isProcessing: boolean;
    isSaveDraftProcessing: boolean;
    isOperationSearchProcessing: boolean;
    isOperationSelectProcessing: boolean;
    operations: OperationSearchItem[];
    operation: Operation | undefined;
    operator: OperationOperator | undefined;
    uav: OperationUav | undefined;
    availableUavs: Uav[];
    flightPurposes: FlightPurpose[];
    operationalGeometryData: OperationalGeometryData | undefined;
    isPaymentMessageVisible: boolean;
}

const MAX_SPECIFIC_PERMIT_NUMBER_LENGTH = 50;

const PAYMENT_NEW_SINGLE_UAV_PLN = 500;
const PAYMENT_NEW_SWARM_PLN = 1000;
const PAYMENT_MODIFICATION_PLN = 500;

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-spec-perm-app-operation-info-step[operations]",
    templateUrl: "./operation-info-step.component.html",
    styleUrls: ["../../../common.scss", "./operation-info-step.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class OperationInfoStepComponent implements OnChanges {
    @Input() public set isProcessing(value: BooleanInput) {
        this.localStore.patchState({ isProcessing: coerceBooleanProperty(value) });
    }

    @Input() public set isSaveDraftProcessing(value: BooleanInput) {
        this.localStore.patchState({ isSaveDraftProcessing: coerceBooleanProperty(value) });
    }

    @Input() public set isOperationSearchProcessing(value: BooleanInput) {
        this.localStore.patchState({ isOperationSearchProcessing: coerceBooleanProperty(value) });
    }

    @Input() public set isOperationSelectProcessing(value: BooleanInput) {
        this.localStore.patchState({ isOperationSelectProcessing: coerceBooleanProperty(value) });
    }

    // NOTE: These inputs not as setter, because value change is handled in ngOnChanges hook
    @Input() public areCapabilitiesReloaded: BooleanInput;
    @Input() public initialValues: OperationInfoData | undefined;

    @Input() public set operations(value: OperationSearchItem[] | undefined) {
        this.localStore.patchState({ operations: value ?? [] });
    }

    @Input() public set operation(value: Operation | undefined) {
        this.localStore.patchState({ operation: value });
    }

    @Input() public set operator(value: OperationOperator | undefined) {
        this.localStore.patchState({ operator: value });
    }

    @Input() public set uav(value: OperationUav | undefined) {
        this.localStore.patchState({ uav: value });
    }

    @Input() public set availableUavs(value: Uav[] | undefined) {
        this.localStore.patchState({ availableUavs: value ?? [] });
    }

    @Input() public set flightPurposes(value: FlightPurpose[] | undefined) {
        this.localStore.patchState({ flightPurposes: value ?? [] });
    }

    @Input() public set operationalGeometryData(value: OperationalGeometryData | undefined) {
        this.localStore.patchState({ operationalGeometryData: value });
    }

    @Input() public set isPaymentMessageVisible(value: BooleanInput) {
        this.localStore.patchState({ isPaymentMessageVisible: coerceBooleanProperty(value) });
    }

    @Output() public readonly saveDraft = new EventEmitter<OperationInfoData>();
    @Output() public readonly next = new EventEmitter<OperationInfoData>();
    @Output() public readonly valueChange = new EventEmitter<void>();
    @Output() public readonly operationsByNameLoad = new EventEmitter<string>();
    @Output() public readonly operationIdChange = new EventEmitter<string>();
    @Output() public readonly operationLoad = new EventEmitter<string>();
    @Output() public readonly operationsManualGuideForSailOpen = new EventEmitter<Sail>();

    @ViewChild(InvalidFormScrollableDirective) private readonly invalidFormScrollable!: InvalidFormScrollableDirective;
    @ViewChild(UavAdditionalInfoComponent) private readonly uavAdditionalInfo!: UavAdditionalInfoComponent;

    protected readonly operations$ = this.localStore.selectByKey("operations");
    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");
    protected readonly isOperationSearchProcessing$ = this.localStore.selectByKey("isOperationSearchProcessing");
    protected readonly isOperationSelectProcessing$ = this.localStore.selectByKey("isOperationSelectProcessing");
    protected readonly operation$ = this.localStore.selectByKey("operation");
    protected readonly operator$ = this.localStore.selectByKey("operator");
    protected readonly uav$ = this.localStore.selectByKey("uav");
    protected readonly availableUavs$ = this.localStore.selectByKey("availableUavs");
    protected readonly flightPurposes$ = this.localStore.selectByKey("flightPurposes");
    protected readonly isSaveDraftProcessing$ = this.localStore.selectByKey("isSaveDraftProcessing");
    protected readonly operationalGeometryData$ = this.localStore.selectByKey("operationalGeometryData");
    protected readonly isPaymentMessageVisible$ = this.localStore.selectByKey("isPaymentMessageVisible");

    protected readonly operationInfoForm = new FormGroup<FormType<OperationInfoData>>({
        operation: new FormControl<OperationSearchItem | null>(null, Validators.required),
        specificPermitNumber: new FormControl<string | null>(null),
        uavInfo: new FormControl<UavInfo | null>(null),
        uavAdditionalInfo: new FormControl<UavAdditionalInfo | null>(null),
    });
    protected readonly isNewApplicationControl = new FormControl<boolean>(true, { nonNullable: true });

    protected readonly PAYMENT_NEW_SINGLE_UAV_PLN = PAYMENT_NEW_SINGLE_UAV_PLN;
    protected readonly PAYMENT_NEW_SWARM_PLN = PAYMENT_NEW_SWARM_PLN;
    protected readonly PAYMENT_MODIFICATION_PLN = PAYMENT_MODIFICATION_PLN;
    protected readonly PAYMENT_CURRENCY_SYMBOL = PAYMENT_CURRENCY_SYMBOL;

    constructor(
        private readonly localStore: LocalComponentStore<OperationInfoStepComponentState>,
        private readonly matDialog: MatDialog,
        private readonly transloco: TranslocoService
    ) {
        this.localStore.setState({
            isProcessing: false,
            isSaveDraftProcessing: false,
            isOperationSearchProcessing: false,
            isOperationSelectProcessing: false,
            operations: [],
            operation: undefined,
            operator: undefined,
            uav: undefined,
            availableUavs: [],
            flightPurposes: [],
            operationalGeometryData: undefined,
            isPaymentMessageVisible: false,
        });

        this.handleOperationChange();
        this.operationInfoForm.valueChanges.pipe(distinctUntilChanged(equal), skip(1), untilDestroyed(this)).subscribe(() => {
            this.valueChange.emit();
        });
    }

    public ngOnChanges({ areCapabilitiesReloaded, initialValues, operation }: SimpleChanges) {
        if (areCapabilitiesReloaded?.currentValue && !areCapabilitiesReloaded.firstChange) {
            this.setFormValues(undefined);
            this.operationInfoForm.controls.operation.setValue(null, { emitEvent: false });
        }

        if (initialValues && operation) {
            this.setFormValues(initialValues.currentValue);
            this.setOperationFormValue(operation.currentValue);
        }

        if (!initialValues && operation) {
            this.setOperationFormValue(operation.currentValue);
        }

        if (initialValues && !operation) {
            this.setFormValues(initialValues.currentValue);

            if (initialValues.currentValue?.operation?.id) {
                this.operationLoad.emit(initialValues.currentValue?.operation?.id);
            }
        }
    }

    protected manageSpecificPermitNumberValidation(isNewApplication: boolean): void {
        if (isNewApplication) {
            this.operationInfoForm.controls.specificPermitNumber.clearValidators();
            this.operationInfoForm.controls.specificPermitNumber.setValue(null, { emitEvent: false });
        } else {
            this.operationInfoForm.controls.specificPermitNumber.setValidators([
                Validators.required,
                Validators.maxLength(MAX_SPECIFIC_PERMIT_NUMBER_LENGTH),
            ]);
        }

        this.operationInfoForm.controls.specificPermitNumber.updateValueAndValidity();
    }

    protected goToNextStep(): void {
        this.operationInfoForm.markAllAsTouched();
        this.invalidFormScrollable.scrollToFirstInvalidField();

        if (this.operationInfoForm.controls.uavAdditionalInfo.invalid) {
            this.uavAdditionalInfo.isExpanded = true;
        }

        if (!this.operationInfoForm.valid) {
            return;
        }

        this.next.emit(this.getOperationInfo());
    }

    protected saveDataToDraft(): void {
        this.saveDraft.emit(this.getOperationInfo());
    }

    private handleOperationChange(): void {
        this.operationInfoForm.controls.operation.valueChanges
            .pipe(
                startWith(null),
                pairwise(),
                filter(([previousOperation, newOperation]) => previousOperation?.id !== newOperation?.id),
                switchMap<
                    [OperationSearchItem | null, OperationSearchItem | null],
                    Observable<[boolean, OperationSearchItem | null, OperationSearchItem | null]>
                >(([previousOperation, newOperation]) =>
                    this.isOperationChangeAllowed().pipe(
                        map((isOperationChangeAllowed) => [isOperationChangeAllowed, previousOperation, newOperation])
                    )
                ),
                untilDestroyed(this)
            )
            .subscribe(([isOperationChangeAllowed, previousOperation, newOperation]) => {
                if (isOperationChangeAllowed) {
                    if (newOperation) {
                        this.operationIdChange.emit(newOperation.id);
                        this.operationInfoForm.controls.uavInfo.reset(null, {
                            emitEvent: false,
                        });
                    } else {
                        this.localStore.patchState({ operation: undefined });
                    }

                    return;
                }

                this.operationInfoForm.controls.operation.setValue(previousOperation, { emitEvent: false });
            });
    }

    private getOperationInfo(): OperationInfoData {
        return this.operationInfoForm.getRawValue();
    }

    private setFormValues(values: OperationInfoData | undefined) {
        this.isNewApplicationControl.setValue(!values?.specificPermitNumber);

        this.operationInfoForm.patchValue(values ?? this.getDefaultOperationInfoFormValues(), {
            emitEvent: false,
        });
    }

    private setOperationFormValue(operation: Operation | undefined) {
        if (!this.operationInfoForm.controls.operation.value && operation) {
            this.operationInfoForm.controls.operation.setValue({ id: operation.id, name: operation.name }, { emitEvent: false });
        }
    }

    private getDefaultOperationInfoFormValues(): OperationInfoData {
        return {
            operation: null,
            specificPermitNumber: null,
            uavInfo: null,
            uavAdditionalInfo: null,
        };
    }

    private isOperationChangeAllowed(): Observable<boolean> {
        if (this.shouldConfirmOperationChange()) {
            return this.confirmOperationChange();
        }

        return of(true);
    }

    private shouldConfirmOperationChange(): boolean {
        const isOperationChanged = !!this.localStore.selectSnapshotByKey("operation");
        const isFormDirty = this.operationInfoForm.controls.uavInfo.dirty;

        return isOperationChanged && isFormDirty;
    }

    private confirmOperationChange(): Observable<boolean> {
        return this.matDialog
            .open(ConfirmationDialogComponent, {
                data: {
                    titleText: this.transloco.translate("dtmWebAppLibSpecPermApp.operationInfoStep.confirmOperationChangeDialog.titleText"),
                    confirmationText: this.transloco.translate(
                        "dtmWebAppLibSpecPermApp.operationInfoStep.confirmOperationChangeDialog.contentText"
                    ),
                    declineButtonLabel: this.transloco.translate(
                        "dtmWebAppLibSpecPermApp.operationInfoStep.confirmOperationChangeDialog.cancelButtonLabel"
                    ),
                    confirmButtonLabel: this.transloco.translate(
                        "dtmWebAppLibSpecPermApp.operationInfoStep.confirmOperationChangeDialog.confirmButtonLabel"
                    ),
                    theme: ButtonTheme.Warn,
                },
            })
            .afterClosed();
    }
}
