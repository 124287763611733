<mat-card *ngrxLet="invitedUsers$; let invitedUsers" class="dtm-table">
    <div class="table-header">
        <p>{{ "dtmWebAppMembership.invitedUsersList.header" | transloco }}</p>
    </div>

    <dtm-ui-loader-container
        *ngrxLet="{
            isProcessing: isMemberProcessing$,
            expandedElement: expandedElement$,
            isOperatorPermittedToManage: isOperatorPermittedToManage$
        } as vm"
        [isShown]="vm.isProcessing"
    >
        <dtm-ui-responsive-table [data]="invitedUsers">
            <table
                *ngIf="invitedUsers?.length; else emptyListTemplate"
                mat-table
                [dataSource]="invitedUsers"
                multiTemplateDataRows
                desktopTable
                class="dtm-table"
            >
                <ng-container matColumnDef="expandHandle">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let element">
                        <button type="button" class="button-icon" (click)="$event.stopPropagation(); toggleExpandableRow(element)">
                            <dtm-ui-icon
                                name="arrow-right"
                                class="expand-handle"
                                [class.expanded]="element === vm.expandedElement"
                            ></dtm-ui-icon>
                        </button>
                    </td>
                </ng-container>
                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef>{{ "dtmWebAppMembership.invitedUsersList.statusLabel" | transloco }}</th>
                    <td mat-cell *matCellDef="let user">
                        <ng-container
                            [ngTemplateOutlet]="statusTemplate"
                            [ngTemplateOutletContext]="{ $implicit: user.status }"
                        ></ng-container>
                    </td>
                </ng-container>
                <ng-container matColumnDef="invitationDate">
                    <th mat-header-cell *matHeaderCellDef>{{ "dtmWebAppMembership.invitedUsersList.invitationDateLabel" | transloco }}</th>
                    <td mat-cell *matCellDef="let user">
                        <p>{{ user.invitationDate | localizeDate }}</p>
                    </td>
                </ng-container>
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef>{{ "dtmWebAppMembership.invitedUsersList.userNameLabel" | transloco }}</th>
                    <td mat-cell *matCellDef="let user">
                        <ng-container [ngTemplateOutlet]="nameTemplate" [ngTemplateOutletContext]="{ $implicit: user }"></ng-container>
                    </td>
                </ng-container>
                <ng-container matColumnDef="phoneNumber">
                    <th mat-header-cell *matHeaderCellDef>{{ "dtmWebAppMembership.invitedUsersList.phoneNumberLabel" | transloco }}</th>
                    <td mat-cell *matCellDef="let user">
                        <ng-container
                            [ngTemplateOutlet]="phoneNumberTemplate"
                            [ngTemplateOutletContext]="{ $implicit: user.phoneNumber }"
                        ></ng-container>
                    </td>
                </ng-container>
                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef>{{ "dtmWebAppMembership.invitedUsersList.actionsLabel" | transloco }}</th>
                    <td mat-cell *matCellDef="let user">
                        <!-- TODO: REJ-2987  revert changes from REJ-2980 to restore ability to display details when time come-->
                        <button
                            *ngIf="vm.isOperatorPermittedToManage"
                            [matMenuTriggerFor]="manageActions"
                            [matMenuTriggerData]="{ $implicit: user }"
                            class="button-icon"
                            type="button"
                            (click)="$event.stopPropagation()"
                        >
                            <dtm-ui-icon name="more"></dtm-ui-icon>
                        </button>
                    </td>
                </ng-container>
                <ng-container matColumnDef="expandedDetail">
                    <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                        <div class="expanded-row-content" *ngIf="element === vm.expandedElement" @slideIn>
                            <div class="sub-cell">
                                <span class="permissions-label">{{
                                    "dtmWebAppMembership.invitedUsersList.permissionsLabel" | transloco
                                }}</span>
                                <ng-container
                                    [ngTemplateOutlet]="permissionsTemplate"
                                    [ngTemplateOutletContext]="{ $implicit: element.permissions }"
                                ></ng-container>
                            </div>
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="pagination">
                    <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">
                        <ng-container [ngTemplateOutlet]="paginationTemplate"></ng-container>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr
                    mat-row
                    *matRowDef="let row; columns: displayedColumns"
                    [class.expanded]="vm.expandedElement === row"
                    (click)="toggleExpandableRow(row)"
                ></tr>
                <tr mat-row *matRowDef="let expandedRow; columns: ['expandedDetail']" class="expansion-row"></tr>
                <tr mat-footer-row *matFooterRowDef="['pagination']" class="pagination-row"></tr>
            </table>
            <div *dtmUiMobileTable="let row">
                <div class="mobile-table-actions-row">
                    <dtm-ui-label-value [label]="'dtmWebAppMembership.invitedUsersList.statusLabel' | transloco">
                        <ng-container
                            [ngTemplateOutlet]="statusTemplate"
                            [ngTemplateOutletContext]="{ $implicit: row.status }"
                        ></ng-container>
                    </dtm-ui-label-value>
                    <button
                        *ngIf="vm.isOperatorPermittedToManage || (row.type === MemberType.MEMBER && row.isPilot)"
                        [matMenuTriggerFor]="vm.isOperatorPermittedToManage ? manageActions : readActions"
                        [matMenuTriggerData]="{ $implicit: row }"
                        class="button-icon"
                        type="button"
                        (click)="$event.stopPropagation()"
                    >
                        <dtm-ui-icon name="more"></dtm-ui-icon>
                    </button>
                </div>
                <div class="mobile-table-content">
                    <dtm-ui-label-value
                        [label]="'dtmWebAppMembership.invitedUsersList.invitationDateLabel' | transloco"
                        [value]="row.invitationDate | localizeDate"
                    >
                    </dtm-ui-label-value>
                    <dtm-ui-label-value [label]="'dtmWebAppMembership.invitedUsersList.userNameLabel' | transloco">
                        <ng-container [ngTemplateOutlet]="nameTemplate" [ngTemplateOutletContext]="{ $implicit: row }"></ng-container>
                    </dtm-ui-label-value>
                    <dtm-ui-label-value [label]="'dtmWebAppMembership.invitedUsersList.phoneNumberLabel' | transloco">
                        <ng-container
                            [ngTemplateOutlet]="phoneNumberTemplate"
                            [ngTemplateOutletContext]="{ $implicit: row.phoneNumber }"
                        ></ng-container>
                    </dtm-ui-label-value>
                    <dtm-ui-label-value
                        class="permissions-container"
                        [label]="'dtmWebAppMembership.invitedUsersList.permissionsLabel' | transloco"
                    >
                        <ng-container
                            [ngTemplateOutlet]="permissionsTemplate"
                            [ngTemplateOutletContext]="{ $implicit: row.permissions }"
                        ></ng-container>
                    </dtm-ui-label-value>
                </div>
            </div>
            <ng-container mobileTablePagination [ngTemplateOutlet]="paginationTemplate"></ng-container>
            <ng-container mobileTableNoData [ngTemplateOutlet]="emptyListTemplate"></ng-container>
        </dtm-ui-responsive-table>
    </dtm-ui-loader-container>
</mat-card>

<ng-template #emptyListTemplate>
    <dtm-ui-no-results></dtm-ui-no-results>
</ng-template>

<ng-template #missingDataTemplate>
    <p class="no-data">{{ "dtmWebAppMembership.invitedUsersList.missingDetailsLabel" | transloco }}</p>
</ng-template>

<ng-template #paginationTemplate>
    <dtm-ui-pagination *ngIf="pagination$ | ngrxPush as pagination" [pagination]="pagination" (page)="pageChange.emit($event)">
    </dtm-ui-pagination>
</ng-template>

<mat-menu #manageActions="matMenu">
    <ng-template matMenuContent let-user>
        <ng-container [ngSwitch]="user.type">
            <ng-container *ngSwitchCase="MemberType.INVITATION">
                <button type="button" mat-menu-item (click)="invitationRemove.emit(user.invitationId)">
                    <dtm-ui-icon name="close-circle"></dtm-ui-icon>
                    {{ "dtmWebAppMembership.invitedUsersList.removeInvitationLabel" | transloco }}
                </button>
                <button type="button" mat-menu-item (click)="invitationResend.emit(user.invitationId)">
                    <dtm-ui-icon name="mail"></dtm-ui-icon>
                    {{ "dtmWebAppMembership.invitedUsersList.resendInvitationLabel" | transloco }}
                </button>
            </ng-container>
            <ng-container *ngSwitchCase="MemberType.MEMBER">
                <!-- TODO: REJ-2987  revert changes from REJ-2980 to restore ability to display details when time come-->
                <!--<button *ngIf="user.isPilot" type="button" mat-menu-item (click)="redirectToPilotDetails(user)">
                    <dtm-ui-icon name="information"></dtm-ui-icon>
                    {{ "dtmWebAppMembership.invitedUsersList.pilotDetailsLabel" | transloco }}
                </button>-->
                <ng-container *ngIf="!user.isMe">
                    <button
                        *ngIf="user.status !== MembershipStatus.Deactivated; else deactivatedMemberActionsTemplate"
                        type="button"
                        mat-menu-item
                        (click)="memberDeactivation.emit(user)"
                    >
                        <dtm-ui-icon name="close-circle"></dtm-ui-icon>
                        {{ "dtmWebAppMembership.invitedUsersList.deactivateMemberLabel" | transloco }}
                    </button>
                </ng-container>
                <ng-template #deactivatedMemberActionsTemplate>
                    <button type="button" mat-menu-item (click)="invitationToMemberResend.emit(user)">
                        <dtm-ui-icon name="mail"></dtm-ui-icon>
                        {{ "dtmWebAppMembership.invitedUsersList.resendInvitationLabel" | transloco }}
                    </button>
                    <button type="button" mat-menu-item (click)="memberRemove.emit(user)">
                        <dtm-ui-icon name="close-circle"></dtm-ui-icon>
                        {{ "dtmWebAppMembership.invitedUsersList.removeMemberLabel" | transloco }}
                    </button>
                </ng-template>
            </ng-container>
            <button
                *ngIf="user.status !== MembershipStatus.Deactivated"
                type="button"
                mat-menu-item
                (click)="manageUserPermissions.emit(user)"
            >
                <dtm-ui-icon name="edit"></dtm-ui-icon>
                {{ "dtmWebAppMembership.invitedUsersList.managePermissionsLabel" | transloco }}
            </button>
        </ng-container>
    </ng-template>
</mat-menu>

<mat-menu #readActions="matMenu">
    <ng-template matMenuContent let-user>
        <button type="button" mat-menu-item (click)="redirectToPilotDetails(user)">
            <dtm-ui-icon name="information"></dtm-ui-icon>
            {{ "dtmWebAppMembership.invitedUsersList.pilotDetailsLabel" | transloco }}
        </button>
    </ng-template>
</mat-menu>

<ng-template #statusTemplate let-status>
    <ng-container [ngSwitch]="status">
        <span *ngSwitchCase="MembershipStatus.Active" class="chip success">
            <dtm-ui-icon name="checkbox-circle" class="status-icon"></dtm-ui-icon>
            {{ "dtmWebAppMembership.invitedUsersList.statusValueLabel" | transloco : { status } }}
        </span>
        <span *ngSwitchCase="MembershipStatus.Deactivated" class="chip disabled">
            <dtm-ui-icon name="error-warning" class="status-icon"></dtm-ui-icon>
            {{ "dtmWebAppMembership.invitedUsersList.statusValueLabel" | transloco : { status } }}
        </span>
        <span *ngSwitchCase="MembershipStatus.Pending" class="chip warning">
            <dtm-ui-icon name="rest-time" class="status-icon"></dtm-ui-icon>
            {{ "dtmWebAppMembership.invitedUsersList.statusValueLabel" | transloco : { status } }}
        </span>
        <span *ngSwitchCase="MembershipStatus.Rejected" class="chip error">
            <dtm-ui-icon name="close-circle" class="status-icon"></dtm-ui-icon>
            {{ "dtmWebAppMembership.invitedUsersList.statusValueLabel" | transloco : { status } }}
        </span>
    </ng-container>
</ng-template>

<ng-template #nameTemplate let-user>
    <p *ngIf="user.firstName || user.lastName; else missingDataTemplate">{{ user.firstName }} {{ user.lastName }}</p>
</ng-template>

<ng-template #phoneNumberTemplate let-phoneNumber>
    <p *ngIf="phoneNumber; else missingDataTemplate">
        {{ phoneNumber | formatPhoneNumber }}
    </p>
</ng-template>

<ng-template #permissionsTemplate let-permissions>
    <ul class="user-roles-list">
        <li *ngFor="let permission of permissions">
            {{ "dtmWebAppMembership.invitedUsersList.permissionValueLabel" | transloco : { permission } }}
        </li>
    </ul>
</ng-template>
