<ng-container *ngrxLet="distanceValues$ as distanceValues" [ngSwitch]="type$ | ngrxPush">
    <div
        *ngSwitchCase="NavigationAccuracyType.HorizontalFlight"
        [innerHTML]="'dtmSharedUav.setupProperties.trackings.navigationAccuracy.horizontalFlight.description' | transloco : distanceValues"
    ></div>
    <div
        *ngSwitchCase="NavigationAccuracyType.TakeoffAndLanding"
        [innerHTML]="'dtmSharedUav.setupProperties.trackings.navigationAccuracy.takeoffAndLanding.description' | transloco : distanceValues"
    ></div>
</ng-container>
