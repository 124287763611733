<dtm-ui-wizard-step-wrapper
    [stepNumber]="1"
    [stepsAmount]="4"
    [title]="'dtmWebAppLibSpecPermApp.operationInfoStep.header' | transloco"
    [isSaveDraftProcessing]="isSaveDraftProcessing$ | ngrxPush"
    [nextButtonLabel]="'dtmWebAppLibSpecPermApp.nextButtonLabel' | transloco"
    [isBackButtonVisible]="false"
    (next)="goToNextStep()"
    (saveDraft)="saveDataToDraft()"
    dtmUiInvalidFormScrollable
    *ngrxLet="{
        operations: operations$,
        isOperationSelectProcessing: isOperationSelectProcessing$,
        operation: operation$,
        uav: uav$
    } as vm"
>
    <p [innerHTML]="'dtmWebAppLibSpecPermApp.operationInfoStep.allFieldsRequiredMessage' | transloco"></p>
    <h2 class="section-header">
        {{ "dtmWebAppLibSpecPermApp.operationInfoStep.basicInformationHeader" | transloco }}
    </h2>
    <div class="risk-methodology-select">
        <div class="selectable-tile selected">
            <div class="selectable-tile-wrapper">
                <strong class="selectable-tile-label">
                    {{ "dtmWebAppLibSpecPermApp.operationInfoStep.riskAssessmentChoice.basedOnOperationLabel" | transloco }}
                </strong>
                <span class="selectable-tile-description">
                    {{ "dtmWebAppLibSpecPermApp.operationInfoStep.riskAssessmentChoice.basedOnOperationHint" | transloco }}
                </span>
            </div>
        </div>
        <div class="selectable-tile methodology-disabled">
            <div class="selectable-tile-wrapper">
                <strong class="selectable-tile-label">
                    {{ "dtmWebAppLibSpecPermApp.operationInfoStep.riskAssessmentChoice.customRiskAssessmentLabel" | transloco }}
                </strong>
                <span class="selectable-tile-description">
                    {{ "dtmWebAppLibSpecPermApp.operationInfoStep.riskAssessmentChoice.customRiskAssessmentHint" | transloco }}
                </span>
                <dtm-ui-popover
                    [popoverText]="'dtmWebAppLibSpecPermApp.operationInfoStep.riskAssessmentChoice.customRiskAssessmentTooltip' | transloco"
                ></dtm-ui-popover>
            </div>
        </div>
    </div>

    <dtm-web-app-lib-spec-perm-app-operation-search-control
        [formControl]="operationInfoForm.controls.operation"
        (searchTextChange)="operationsByNameLoad.emit($event)"
        [options]="vm.operations"
        [isProcessing]="isOperationSearchProcessing$ | ngrxPush"
    >
        <div class="field-error" *dtmUiFieldHasError="operationInfoForm.controls.operation; name: 'required'">
            {{ "dtmWebAppLibSpecPermApp.operationInfoStep.selectOperationError" | transloco }}
        </div>
    </dtm-web-app-lib-spec-perm-app-operation-search-control>
    <mat-spinner diameter="40" *ngIf="vm.isOperationSelectProcessing" color="accent"></mat-spinner>
    <ng-container *ngIf="!vm.isOperationSelectProcessing && vm.operation">
        <div class="specific-permit-number">
            <dtm-ui-radio-tile-group [formControl]="isNewApplicationControl" (change)="manageSpecificPermitNumberValidation($event.value)">
                <dtm-ui-radio-tile [value]="true">
                    <div class="tile">
                        {{ "dtmWebAppLibSpecPermApp.operationInfoStep.specificPermitNumber.newApplicationLabel" | transloco }}
                    </div>
                </dtm-ui-radio-tile>
                <dtm-ui-radio-tile [value]="false">
                    <div class="tile">
                        {{ "dtmWebAppLibSpecPermApp.operationInfoStep.specificPermitNumber.specificPermitModificationLabel" | transloco }}
                    </div>
                </dtm-ui-radio-tile>
            </dtm-ui-radio-tile-group>
            <ng-container *ngIf="isPaymentMessageVisible$ | ngrxPush" [ngSwitch]="true">
                <dtm-ui-info-message *ngSwitchCase="isNewApplicationControl.value && vm.uav && !vm.uav.isSwarm">
                    <span
                        [innerHtml]="
                            'dtmWebAppLibSpecPermApp.operationInfoStep.paymentMessage.newApplicationSingleUav'
                                | transloco : { value: PAYMENT_NEW_SINGLE_UAV_PLN | localizeNumber, currency: PAYMENT_CURRENCY_SYMBOL }
                        "
                    ></span>
                </dtm-ui-info-message>
                <dtm-ui-info-message *ngSwitchCase="isNewApplicationControl.value && vm.uav && vm.uav.isSwarm">
                    <span
                        [innerHtml]="
                            'dtmWebAppLibSpecPermApp.operationInfoStep.paymentMessage.newApplicationSwarm'
                                | transloco : { value: PAYMENT_NEW_SWARM_PLN | localizeNumber, currency: PAYMENT_CURRENCY_SYMBOL }
                        "
                    ></span>
                </dtm-ui-info-message>
                <dtm-ui-info-message *ngSwitchCase="!isNewApplicationControl.value">
                    <span
                        [innerHtml]="
                            'dtmWebAppLibSpecPermApp.operationInfoStep.paymentMessage.applicationModification'
                                | transloco : { value: PAYMENT_MODIFICATION_PLN | localizeNumber, currency: PAYMENT_CURRENCY_SYMBOL }
                        "
                    ></span>
                </dtm-ui-info-message>
            </ng-container>
            <dtm-ui-input-field [isClearable]="false" *ngIf="!isNewApplicationControl.value">
                <label>{{ "dtmWebAppLibSpecPermApp.operationInfoStep.specificPermitNumber.specificPermitNumberLabel" | transloco }}</label>
                <input matInput type="text" [formControl]="operationInfoForm.controls.specificPermitNumber" />
                <div class="field-error" *dtmUiFieldHasError="operationInfoForm.controls.specificPermitNumber; name: 'required'">
                    {{ "dtmWebAppLibSpecPermApp.requiredValueError" | transloco }}
                </div>
                <div
                    class="field-error"
                    *dtmUiFieldHasError="operationInfoForm.controls.specificPermitNumber; name: 'maxlength'; error as error"
                >
                    {{
                        "dtmWebAppLibSpecPermApp.operationInfoStep.specificPermitNumber.specificPermitNumberMaxLengthValueError"
                            | transloco : { maxLength: error.requiredLength }
                    }}
                </div>
            </dtm-ui-input-field>
        </div>

        <dtm-web-app-lib-spec-perm-app-operator-info [operator]="operator$ | ngrxPush"></dtm-web-app-lib-spec-perm-app-operator-info>
        <dtm-web-app-lib-spec-perm-app-uav-info
            [uav]="vm.uav"
            [availableUavs]="availableUavs$ | ngrxPush"
            [formControl]="operationInfoForm.controls.uavInfo"
            [loadWeight]="vm.operation.flight.loadWeight ?? undefined"
        ></dtm-web-app-lib-spec-perm-app-uav-info>
        <dtm-web-app-lib-spec-perm-app-uav-additional-info
            [sail]="vm.operation.sail"
            [formControl]="operationInfoForm.controls.uavAdditionalInfo"
            dtmUiMarkValueAccessorControlsAsTouched
        ></dtm-web-app-lib-spec-perm-app-uav-additional-info>
        <dtm-web-app-lib-spec-perm-app-operation-info
            [operation]="vm.operation"
            [flightPurposes]="flightPurposes$ | ngrxPush"
        ></dtm-web-app-lib-spec-perm-app-operation-info>
        <dtm-web-app-lib-spec-perm-app-sail-info
            [sail]="vm.operation.sail"
            (operationsManualGuideForSailOpen)="operationsManualGuideForSailOpen.emit($event)"
        ></dtm-web-app-lib-spec-perm-app-sail-info>
        <dtm-mission-operational-area-geometry
            [operationalGeometryData]="operationalGeometryData$ | ngrxPush"
            [isExpanded]="false"
        ></dtm-mission-operational-area-geometry>
        <dtm-web-app-lib-spec-perm-app-ground-risk-info
            [groundRisk]="vm.operation.groundRisk"
        ></dtm-web-app-lib-spec-perm-app-ground-risk-info>
        <dtm-web-app-lib-spec-perm-app-airspace-risk-info
            [airspaceRisk]="vm.operation.airspaceRisk"
        ></dtm-web-app-lib-spec-perm-app-airspace-risk-info>
        <dtm-web-app-lib-spec-perm-app-adjacent-area-risk-info
            [adjacentAreaRisk]="vm.operation.adjacentAreaRisk"
        ></dtm-web-app-lib-spec-perm-app-adjacent-area-risk-info>
    </ng-container>
</dtm-ui-wizard-step-wrapper>
