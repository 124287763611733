import { NgClass } from "@angular/common";
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Inject,
    Input,
    OnDestroy,
    Output,
    ViewChild,
} from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { SHARED_MAP_ENDPOINTS, SharedMapEndpoints } from "@dtm-frontend/shared/map";
import {
    AZURE_MAPS_LAYER_OPTIONS,
    CameraHelperService,
    DEFAULT_CESIUM_VIEWER_CONFIGURATION_OPTIONS,
    ViewControl,
} from "@dtm-frontend/shared/map/cesium";
import { ZoneTimesSetting } from "@dtm-frontend/shared/map/geo-zones";
import { MissionType } from "@dtm-frontend/shared/mission";
import {
    FlightPositionUpdate,
    HemsEventData,
    MissionPlanRoute,
    RouteAreaTypeId,
    RouteData,
    TimeRange,
    Trajectory,
} from "@dtm-frontend/shared/ui";
import { FunctionUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { Store } from "@ngxs/store";
import { AcMapComponent, MapLayerProviderOptions, SceneMode, ViewerConfiguration } from "@pansa/ngx-cesium";
import turfBbox from "@turf/bbox";
import { AllGeoJSON, BBox } from "@turf/helpers";
import { Observable, tap } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { Checkin, MissionData, TrackerWarning, Violation } from "../../models/tactical.models";
import { TacticalActions } from "../../state/tactical.actions";

/* eslint-disable @typescript-eslint/no-explicit-any*/
declare const Cesium: any; // TODO: DTM-966

interface MissionItineraryComponentState {
    routeData: RouteData<MissionData> | undefined;
    isProcessing: boolean;
    flightData: FlightPositionUpdate | undefined;
    trackerWarning: TrackerWarning | undefined;
    violation: Violation | undefined;
    zoomTo: AllGeoJSON | undefined;
    trajectories: Map<string, Trajectory[]>;
    hemsEventData: HemsEventData[];
    checkinList: Checkin[];
    isMeasureToolActive: boolean;
}

// TODO: DTM-1654 - this will change when API will be ready
const temporaryMockInitialViewData = {
    type: "Polygon",
    coordinates: [
        [
            /* eslint-disable no-magic-numbers*/
            [20.749963462907985, 52.1048546208032],
            [20.749963462907985, 52.18858653544731],
            [20.92380504882745, 52.18858653544731],
            [20.92380504882745, 52.1048546208032],
            [20.749963462907985, 52.1048546208032],
            /* eslint-enable no-magic-numbers*/
        ],
    ],
};

const VIEW_CONTROLS: ViewControl[] = [ViewControl.Path, ViewControl.SafetyArea];

@Component({
    selector: "dtm-web-app-lib-mission-route[routeData]",
    templateUrl: "./mission-route.component.html",
    styleUrls: ["./mission-route.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class MissionRouteComponent implements OnDestroy, AfterViewInit {
    @Input()
    public set routeData(value: RouteData<MissionData> | undefined) {
        this.localStore.patchState({
            routeData: value,
        });
    }
    @Input()
    public set isProcessing(value: boolean | undefined) {
        this.localStore.patchState({ isProcessing: value });
    }
    @Input()
    public set flights(value: FlightPositionUpdate | undefined) {
        this.localStore.patchState({ flightData: value });
    }
    @Input()
    public set trackerWarning(value: TrackerWarning | undefined) {
        this.localStore.patchState({ trackerWarning: value });
    }
    @Input()
    public set zoomTo(value: AllGeoJSON | undefined) {
        this.localStore.patchState({ zoomTo: value });
    }
    @Input()
    public set violation(value: Violation | undefined) {
        this.localStore.patchState({ violation: value });
    }
    @Input()
    public set trajectories(value: Map<string, Trajectory[]> | undefined) {
        this.localStore.patchState({ trajectories: value });
    }
    @Input()
    public set hemsEventData(value: HemsEventData[] | undefined) {
        this.localStore.patchState({ hemsEventData: value ?? [] });
    }
    @Input()
    public set checkinList(value: Checkin[] | undefined) {
        this.localStore.patchState({ checkinList: value ?? [] });
    }

    @Output()
    public readonly flightPositionUpdatesEnrich = new EventEmitter<string>();

    @ViewChild(AcMapComponent) private readonly acMap: AcMapComponent | undefined;

    protected readonly isPathVisibleControl = new FormControl(true);
    protected readonly isBlockerVisible = new FormControl(false);
    protected zoneSettingsFormControls: Partial<Record<RouteAreaTypeId, FormControl>> = {
        flightArea: new FormControl(true),
        path: this.isPathVisibleControl,
        blockers: this.isBlockerVisible,
        trajectories: new FormControl(true),
    };

    protected readonly drawingSettingsForm = new FormGroup(this.zoneSettingsFormControls);

    protected readonly routeEntities$ = this.localStore.selectByKey("routeData");
    protected readonly drawableFeatures$ = this.initDrawableFeatures();
    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");
    protected readonly flightData$ = this.localStore.selectByKey("flightData");
    protected readonly trackerWarning$ = this.localStore
        .selectByKey("trackerWarning")
        .pipe(tap((warning) => this.isBlockerVisible.setValue(!!warning)));
    protected readonly zoomTo$ = this.localStore.selectByKey("zoomTo");
    protected readonly violationClass$ = this.localStore.selectByKey("violation").pipe(map(this.mapViolationToClass));
    protected readonly trajectories$ = this.localStore.selectByKey("trajectories");
    protected readonly hemsEventData$ = this.localStore.selectByKey("hemsEventData");
    protected readonly missionTimeRange$: Observable<TimeRange | undefined> = this.initMissionTimeRange();
    protected readonly emergency$ = this.localStore.selectByKey("routeData").pipe(map((data) => data?.emergency));
    protected readonly checkinList$ = this.localStore.selectByKey("checkinList");
    protected readonly isMeasureToolActive$ = this.localStore.selectByKey("isMeasureToolActive");

    protected readonly MapLayerProviderOptions = MapLayerProviderOptions;
    protected readonly AZURE_MAPS_LAYER_OPTIONS = AZURE_MAPS_LAYER_OPTIONS;
    protected readonly VIEW_CONTROLS = VIEW_CONTROLS;
    protected readonly ZoneTimesSetting = ZoneTimesSetting;
    protected readonly MissionType = MissionType;

    constructor(
        private readonly store: Store,
        private readonly localStore: LocalComponentStore<MissionItineraryComponentState>,
        viewerConfiguration: ViewerConfiguration,
        @Inject(SHARED_MAP_ENDPOINTS) public readonly sharedMapEndpoints: SharedMapEndpoints,
        // NOTE: CameraHelperService need to be created before AcMap to set up viewerModifier before AcMap options are set
        private readonly cameraHelperService: CameraHelperService
    ) {
        viewerConfiguration.viewerOptions = {
            ...DEFAULT_CESIUM_VIEWER_CONFIGURATION_OPTIONS,
            sceneMode: SceneMode.SCENE3D, // TODO:  DTM-4517 Change to SCENE2D when fixed
        };
        this.setInitialViewbox();

        this.localStore.setState({
            routeData: undefined,
            isProcessing: true,
            flightData: undefined,
            trackerWarning: undefined,
            zoomTo: undefined,
            violation: undefined,
            trajectories: new Map(),
            hemsEventData: [],
            checkinList: [],
            isMeasureToolActive: false,
        });
    }

    public ngAfterViewInit(): void {
        // TODO:  DTM-4517 remove when fixed
        if (this.acMap) {
            this.acMap.getCesiumService().getViewer().scene.screenSpaceCameraController.enableTilt = false;
        }
    }

    public ngOnDestroy(): void {
        this.store.dispatch(TacticalActions.StopFlightPositionUpdatesWatch);
    }

    protected getRoutesFromRouteData(data?: RouteData<MissionData>): MissionPlanRoute[] {
        return [data?.route, data?.nearbyMissionsData?.map(({ route }) => route)].flat().filter(FunctionUtils.isTruthy);
    }

    protected updateMeasureToolStatus(isActive: boolean) {
        this.localStore.patchState({ isMeasureToolActive: isActive });
    }

    private initDrawableFeatures(): Observable<RouteAreaTypeId[]> {
        return this.drawingSettingsForm.valueChanges.pipe(
            startWith(null),
            map(() =>
                Object.entries(this.drawingSettingsForm.value)
                    .filter(([, value]) => value)
                    .map(([key]) => key as RouteAreaTypeId)
            )
        );
    }

    public handleVisibleAreaChange(area: BBox) {
        this.store.dispatch(new TacticalActions.StartFlightPositionUpdatesWatch(area));
    }

    private mapViolationToClass(violation: Violation | undefined): NgClass["ngClass"] {
        if (!violation) {
            return undefined;
        }

        return {
            warning: violation === Violation.UavLeftOwnFlightArea,
            danger: [
                Violation.UavOutsideStartingFlightZone,
                Violation.UavEnteredForeignSafetyArea,
                Violation.UavLeftOwnSafetyArea,
            ].includes(violation),
        };
    }

    private initMissionTimeRange() {
        return this.routeEntities$.pipe(
            map((route) => {
                const mission = route?.data;
                if (!mission) {
                    return undefined;
                }

                return {
                    min: mission.startTime.min,
                    max: mission.endTime.max,
                };
            })
        );
    }

    private setInitialViewbox() {
        const bbox = turfBbox(temporaryMockInitialViewData);

        Cesium.Camera.DEFAULT_VIEW_FACTOR = 0;
        Cesium.Camera.DEFAULT_VIEW_RECTANGLE = Cesium.Rectangle.fromDegrees(...bbox);
        // TODO:  DTM-4517 remove when fixed
        Cesium.Camera.DEFAULT_OFFSET = new Cesium.HeadingPitchRange(0, -Cesium.Math.PI_OVER_TWO, 0);

        return Cesium.Camera.DEFAULT_VIEW_RECTANGLE;
    }

    protected isMainAircraft(trackerId: string, trackersIdentifiers: string[] | undefined) {
        if (!trackersIdentifiers?.length) {
            return false;
        }

        return trackersIdentifiers.some((trackerIdentifier) => trackerIdentifier === trackerId);
    }
}
