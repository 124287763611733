import { OperatorAndPilotQualification } from "@dtm-frontend/shared/ui";
import { DateUtils } from "@dtm-frontend/shared/utils";
import {
    ForeignCompetencyDocument,
    ForeignCompetencyDocumentDraft,
    ForeignCompetencyType,
    OperationalAuthorization,
    Operator,
    PilotProfile,
} from "./pilot-profile.models";

export interface PilotProfileOperatorResponseBody extends Omit<Operator, "isRegisteredInOtherCountry" | "insurancePolicyExpirationDate"> {
    registeredInOtherCountry: boolean;
    insurancePolicyExpirationDate: string | undefined;
}
export interface PilotProfileCompetencyConfirmationResponseBody extends Omit<ForeignCompetencyDocument, "expirationDate"> {
    validUntil: string;
}

export interface PilotProfileResponseBody
    extends Omit<PilotProfile, "competencies" | "operationalAuthorizations" | "isSuperPilot" | "operator" | "competencyConfirmations"> {
    competencies: OperationalAuthorization[];
    operationalAuthorizations: OperationalAuthorization[];
    superPilot: boolean;
    operator: PilotProfileOperatorResponseBody;
    competencyConfirmations: PilotProfileCompetencyConfirmationResponseBody[];
}

export interface AddForeignCompetencyRequestBody {
    fileId: string;
    type: ForeignCompetencyType;
    validUntil: string;
}

const mapQualifications = (qualification: OperationalAuthorization): OperatorAndPilotQualification => ({
    id: qualification.id,
    category: qualification.operationScenario.category,
    expirationDate: qualification.expirationDate,
    name: qualification.operationScenario.name,
    status: qualification.status,
    statusReason: qualification.statusReason,
});

export const convertPilotProfileResponseBodyToPilotProfile = ({
    superPilot,
    ...pilotProfileResponseBody
}: PilotProfileResponseBody): PilotProfile => ({
    ...pilotProfileResponseBody,
    competencies: pilotProfileResponseBody.competencies.map(mapQualifications),
    operationalAuthorizations: pilotProfileResponseBody.operationalAuthorizations.map(mapQualifications),
    isSuperPilot: superPilot,
    otherInformation: pilotProfileResponseBody.otherInformation,
    operator: {
        id: pilotProfileResponseBody.operator.id,
        operatorNumber: pilotProfileResponseBody.operator.operatorNumber,
        name: pilotProfileResponseBody.operator.name,
        insurancePolicyNumber: pilotProfileResponseBody.operator.insurancePolicyNumber,
        insurancePolicyExpirationDate: pilotProfileResponseBody.operator.insurancePolicyExpirationDate
            ? DateUtils.convertStringDateToDateWithoutTimeZone(pilotProfileResponseBody.operator.insurancePolicyExpirationDate)
            : undefined,
        address: pilotProfileResponseBody.operator.address,
        status: pilotProfileResponseBody.operator.status,
        isRegisteredInOtherCountry: pilotProfileResponseBody.operator.registeredInOtherCountry,
    },
    competencyConfirmations:
        pilotProfileResponseBody.competencyConfirmations?.map((competencyConfirmation) => ({
            id: competencyConfirmation.id,
            status: competencyConfirmation.status,
            statusReason: competencyConfirmation.statusReason,
            type: competencyConfirmation.type,
            expirationDate: DateUtils.convertStringDateToDateWithoutTimeZone(competencyConfirmation.validUntil),
            file: competencyConfirmation.file,
        })) ?? [],
});

export function convertForeignCompetencyDocumentDraftToAddForeignCompetencyRequestBody(
    competencyDraft: ForeignCompetencyDocumentDraft
): AddForeignCompetencyRequestBody {
    return {
        fileId: competencyDraft.file[0].id,
        type: competencyDraft.type,
        validUntil: DateUtils.getISOStringDate(competencyDraft.expirationDate.toISOString()),
    };
}
