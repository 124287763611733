import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from "@angular/core";
import { CameraHelperService } from "@dtm-frontend/shared/map/cesium";
import { AirspaceElement, GeoZonesActions, GeoZonesState } from "@dtm-frontend/shared/map/geo-zones";
import {
    AuthorityAcceptationItem,
    MissionContextType,
    MissionDataSimple,
    MissionPlanDataAndCapabilities,
    MissionPlanRemarks,
    MissionType,
} from "@dtm-frontend/shared/mission";
import { ContextOperator, GlobalFeatures, MissionPlanRoute, RouteDataService } from "@dtm-frontend/shared/ui";
import { TranslationHelperService } from "@dtm-frontend/shared/ui/i18n";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import {
    AllGeoJSON,
    Feature as GeoJSONFeature,
    feature as createFeature,
    featureCollection as createFeatureCollection,
} from "@turf/helpers";
import { combineLatest, map, switchMap } from "rxjs";
import { distinctUntilChanged } from "rxjs/operators";
import { AdditionalInformationSettings } from "../../../mission/components/mission-notes-and-description/personal-notes.component";
import { OperatorContextState } from "../../../shared/operator-context/state/operator-context.state";
import { MissionData, MissionStatus } from "../../models/tactical.models";
import { NearbyMissionsSettings } from "../../services/tactical-api.service";
import { TacticalMissionService } from "../../services/tactical-mission.service";
import { TacticalActions } from "../../state/tactical.actions";
import { TacticalState } from "../../state/tactical.state";

interface TacticalMissionViewState {
    selectedZoneId: string | undefined;
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-tactical-mission-view",
    templateUrl: "./tactical-mission-view.component.html",
    styleUrls: ["./tactical-mission-view.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class TacticalMissionViewComponent implements OnInit, OnDestroy {
    protected readonly mission$ = this.store.select(TacticalState.missionData);
    protected readonly isMissionDataProcessing$ = this.store.select(TacticalState.isMissionDataProcessing);
    protected readonly areAdditionalInformationProcessing$ = this.store.select(TacticalState.areAdditionalInformationProcessing);
    protected readonly missionPlanData$ = this.store.select(TacticalState.missionPlanData);
    protected readonly currentPlanAnalysisStatus$ = this.store.select(TacticalState.missionPlanAnalysisStatus);
    protected readonly collisionZones$ = this.store.select(GeoZonesState.customZonesLayersData);
    protected readonly isProcessing$ = this.store.select(TacticalState.isMissionDataProcessing);
    protected readonly sectionStatuses$ = this.tacticalMissionService.segmentStatuses$;
    protected readonly isUtmSupervisorIntegrated$ = this.store.select(
        OperatorContextState.isFeatureAvailable(GlobalFeatures.UtmSupervisorIntegration)
    );

    protected readonly isNearbyMissionDataProcessing$ = this.store.select(TacticalState.isNearbyMissionDataProcessing);
    protected readonly nearbyMissions$ = this.store
        .select(TacticalState.nearbyMissions)
        .pipe(
            map((list) => list?.filter((mission) => mission.missionId !== this.store.selectSnapshot(TacticalState.missionData)?.missionId))
        );
    protected readonly areNearbyMissionsEnabled$ = this.store
        .select(TacticalState.nearbyMissionsSettings)
        .pipe(map(({ areAllEnabled }) => areAllEnabled));

    protected readonly selectedZoneId$ = this.localStore.selectByKey("selectedZoneId");
    protected readonly missionDataSimple$ = combineLatest([
        this.missionPlanData$.pipe(RxjsUtils.filterFalsy()),
        this.mission$.pipe(RxjsUtils.filterFalsy()),
        this.store.select(OperatorContextState.operators),
    ]).pipe(
        map(([currentPlanDataAndCapabilities, { route }, operators]) =>
            this.prepareMissionDataSimple(currentPlanDataAndCapabilities, route, operators)
        )
    );

    protected readonly MissionStatus = MissionStatus;
    protected readonly MissionType = MissionType;
    protected readonly MissionContextType = MissionContextType;

    constructor(
        private readonly store: Store,
        private readonly routeDataService: RouteDataService,
        private readonly translationHelperService: TranslationHelperService,
        private readonly localStore: LocalComponentStore<TacticalMissionViewState>,
        private readonly cameraHelperService: CameraHelperService,
        private readonly tacticalMissionService: TacticalMissionService
    ) {
        localStore.setState({
            selectedZoneId: undefined,
        });
        this.store.dispatch([new GeoZonesActions.SetCustomZonesVisibility(true), TacticalActions.StartMissionStatusUpdateWatch]);
    }

    public ngOnInit(): void {
        this.startActiveMissionsUpdatesWatch();
        this.updateTitleOnMissionChange();
        this.updateAnalysisOnMissionChange();
        this.store.dispatch(TacticalActions.StartCheckinUpdatesWatch);
    }

    public ngOnDestroy(): void {
        this.store.dispatch([
            TacticalActions.StopMissionUpdatesWatch,
            TacticalActions.ClearNearbyMissions,
            new TacticalActions.SetNearbyMissionViewSettings({ areAllEnabled: false, areOnlyActive: false }),
            TacticalActions.StopMissionStatusUpdateWatch,
            TacticalActions.StopCheckinUpdatesWatch,
        ]);
    }

    private startActiveMissionsUpdatesWatch(): void {
        const missionId = this.store.selectSnapshot(TacticalState.missionData)?.missionId;
        if (!missionId) {
            return;
        }
        this.store.dispatch(new TacticalActions.StartMissionUpdatesWatch(missionId));
    }

    protected toggleSelection(zone: AirspaceElement) {
        if (this.localStore.selectSnapshotByKey("selectedZoneId") === zone.id) {
            const route = this.store.selectSnapshot(TacticalState.missionData)?.route;
            this.flyToRoute(route);

            this.store.dispatch(new GeoZonesActions.SetSelectedZoneId(undefined));
            this.localStore.patchState({
                selectedZoneId: undefined,
            });

            return;
        }

        this.store.dispatch(new GeoZonesActions.SetSelectedZoneId(zone.id));
        this.localStore.patchState({
            selectedZoneId: zone.id,
        });

        this.cameraHelperService.flyToGeoJSON(zone.geometry);
    }

    protected flyToRoute(route?: MissionPlanRoute) {
        if (!route) {
            return;
        }

        const zoomArea = createFeatureCollection(
            route.sections.reduce<GeoJSONFeature[]>((features, section) => {
                const area = section.flightZone?.safetyArea.volume.area ?? section.segment?.safetyArea.volume.area;

                if (area) {
                    features.push(createFeature(area));
                }

                return features;
            }, [])
        );

        this.cameraHelperService.flyToGeoJSON(zoomArea);
    }

    protected zoomToGeometry(geometry: AllGeoJSON) {
        this.store.dispatch(new TacticalActions.ZoomToGeometry(geometry));
    }

    protected refreshNearbyMissions(setting: NearbyMissionsSettings) {
        const areEnabled = !!(setting.areAllEnabled || setting.areOnlyActive);
        this.store.dispatch([
            new TacticalActions.SetNearbyMissionViewSettings({
                areAllEnabled: areEnabled,
                areOnlyActive: !!setting.areOnlyActive,
            }),
            areEnabled ? new TacticalActions.GetNearbyMissions(setting) : new TacticalActions.ClearNearbyMissions(),
        ]);
    }

    protected onNearbyMissionSelect(mission: MissionData) {
        this.store.dispatch(new TacticalActions.SelectNearbyMission(mission));
    }

    protected updateAdditionalInformation(additionalInformation: AdditionalInformationSettings) {
        const planId = this.store.selectSnapshot(TacticalState.missionData)?.planId;
        if (!planId) {
            return;
        }

        const { notes, name, description } = additionalInformation;
        const payload = {
            notes: notes?.trim(),
            name: name?.trim(),
            description: description?.trim(),
        };

        this.store.dispatch(new TacticalActions.UpdateAdditionalInformation(planId, payload));
    }

    protected getNearbyMissionCountText(isEnabled: boolean, count?: number) {
        if (!isEnabled || count === undefined) {
            return "";
        }

        return `(${count})`;
    }

    protected messageAcknowledged(systemVerificationId?: string) {
        this.store.dispatch(new TacticalActions.MissionPlanMessageAcknowledgement(systemVerificationId));
    }

    protected flyToMission() {
        const route = this.store.selectSnapshot(TacticalState.missionData)?.route;
        if (route) {
            this.flyToRoute(route);
        }
    }

    protected getAuthorityAcceptationItemWithMessage(remarks?: MissionPlanRemarks): AuthorityAcceptationItem | undefined {
        const authorityAcceptation = remarks?.authorityAcceptation;

        if (!authorityAcceptation) {
            return;
        }

        return Object.values(authorityAcceptation).find((acceptation: AuthorityAcceptationItem | undefined) => acceptation?.comment);
    }

    private updateTitleOnMissionChange() {
        this.mission$
            .pipe(
                switchMap((mission) =>
                    this.translationHelperService
                        .waitForTranslation("dtmWebAppLibShared.missionTile.noNameTitle")
                        .pipe(map((noNameTitle) => ({ noNameTitle, mission })))
                ),
                untilDestroyed(this)
            )
            .subscribe(({ noNameTitle, mission }) => {
                const title = !mission ? "" : mission.name ?? noNameTitle;
                this.routeDataService.updateTitle(title);
            });
    }

    private updateAnalysisOnMissionChange() {
        this.mission$
            .pipe(
                distinctUntilChanged((previous, current) => previous?.planId === current?.planId),
                untilDestroyed(this)
            )
            .subscribe(() =>
                this.store.dispatch([new GeoZonesActions.SetCustomElements(undefined), TacticalActions.GetMissionPlanAnalysis])
            );
    }

    private prepareMissionDataSimple(
        { plan, flightPurposes }: MissionPlanDataAndCapabilities,
        route: MissionPlanRoute,
        operators: { [key: string]: ContextOperator } | undefined
    ): MissionDataSimple {
        return {
            isRoutePathBased: !!route?.isPathBased,
            flightStartAtMin: plan.flightStartAtMin,
            flightStartAtMax: plan.flightStartAtMax,
            flightFinishAtMin: plan.flightFinishAtMin,
            flightFinishAtMax: plan.flightFinishAtMax,
            phase: plan.phase,
            distance: route?.estimatedDistance,
            operatorName: operators?.[plan.capabilities.operator]?.name,
            pilotName: plan.capabilities.pilotName,
            uavName:
                plan.capabilities.uavName && plan.capabilities.setupName
                    ? `${plan.capabilities.uavName} (${plan.capabilities.setupName})`
                    : undefined,
            uavSerialNumbers: plan.uav.serialNumbers ?? [],
            trackersIdentifiers: plan.capabilities.trackersIdentifiers ?? [],
            category: plan.category,
            flightPurpose: {
                nameTranslationKey: flightPurposes?.find((purpose) => purpose.id === plan.flightPurpose?.id)?.name ?? "",
                comment: plan.flightPurpose?.comment ?? undefined,
            },
            additionalCrew: plan.capabilities.additionalCrew,
        };
    }
}
