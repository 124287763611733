import { InjectionToken } from "@angular/core";

export interface MissionEndpoints {
    getCapabilities: string;
    createMissionPlan: string;
    updateMissionPlanCapabilities: string;
    updateMissionPlanFlightPurpose: string;
    updateMissionPlanOperationCategory: string;
    updateMissionPlanItinerary: string;
    createMissionPlanRoutes: string;
    assignMissionPlanRoute: string;
    getMissionPlanVerification: string;
    getMissionRoute: string;
    getMissionPlanRoutes: string;
    registerMission: string;
    closeSpecificPermitPlan: string;
    wsMissionPlanningTopicName: string;
    getMissionPlanItinerary: string;
    getCaaPermitDetails: string;
    getMissionList: string;
    missionManagement: string;
    missionThumbnailManagement: string;
    getFlightPurposes: string;
    cloneMissionPlan: string;
    getActivePermitsList: string;
    updateAdditionalInformation: string;
    createOrUpdateFormalJustification: string;
    uploadMissionAttachment: string;
    getMissionAttachment: string;
    getPublicMissionPlan: string;
    editorOptions: string;
    messageAcknowledgement: string;
    pansaUtmLinkStatus: string;
    downloadKml: string;
    getRouteAirspaceInfo: string;
}
export const MISSION_ENDPOINTS = new InjectionToken<MissionEndpoints>("Mission endpoints");
