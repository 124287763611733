<ng-container *ngrxLet="properties$ as properties">
    <dtm-uav-lib-setup-property
        [label]="'dtmSharedUav.setupProperties.communicationProperties.typeLabel' | transloco"
        [isEmpty]="properties.type | invoke : isEmpty"
    >
        {{ "dtmSharedUav.setupProperties.communicationProperties.typeValue" | transloco : { value: properties.type } }}
    </dtm-uav-lib-setup-property>
    <dtm-uav-lib-setup-property
        [label]="'dtmSharedUav.setupProperties.communicationProperties.transmissionLinkRangeLabel' | transloco"
        [isEmpty]="properties.transmissionLinkRange | invoke : isEmpty"
    >
        {{
            "dtmSharedUav.setupProperties.communicationProperties.transmissionLinkRangeValue"
                | transloco : { value: properties.transmissionLinkRange | convertToKilometers | localizeNumber }
        }}
    </dtm-uav-lib-setup-property>
    <dtm-uav-lib-setup-property
        [label]="'dtmSharedUav.setupProperties.communicationProperties.linkDelayLabel' | transloco : { type: properties.linkType }"
        [isEmpty]="properties.linkDelay | invoke : isEmpty"
    >
        {{
            "dtmSharedUav.setupProperties.communicationProperties.linkDelayValue"
                | transloco : { value: properties.linkDelay | convertToMilliseconds | localizeNumber }
        }}
    </dtm-uav-lib-setup-property>
    <dtm-uav-lib-setup-property
        *ngIf="properties.type === CommunicationType.RADIO"
        [label]="'dtmSharedUav.setupProperties.communicationProperties.frequencyLabel' | transloco"
        [isEmpty]="!properties.frequencies.length"
    >
        <ul *ngIf="properties.frequencies?.length" class="frequencies">
            <li *ngFor="let frequency of properties.frequencies">
                <ng-container *ngIf="frequency.minGhz === frequency.maxGhz">{{
                    "dtmSharedUav.setupProperties.communicationProperties.frequencyValue"
                        | transloco : { value: frequency.minGhz | localizeNumber }
                }}</ng-container
                ><ng-container *ngIf="frequency.minGhz !== frequency.maxGhz"
                    >{{
                        "dtmSharedUav.setupProperties.communicationProperties.frequencyValue"
                            | transloco : { value: frequency.minGhz | localizeNumber }
                    }}
                    -
                    {{
                        "dtmSharedUav.setupProperties.communicationProperties.frequencyValue"
                            | transloco : { value: frequency.maxGhz | localizeNumber }
                    }}</ng-container
                >
            </li>
        </ul>
    </dtm-uav-lib-setup-property>
</ng-container>
