<ng-container dtmUiInvalidFormScrollable>
    <dtm-ui-input-field [isClearable]="false">
        <label>
            {{ "dtmSharedUav.setupProperties.flightTechnicalProperties.maxFlightTimeLabel" | transloco }}
            <dtm-ui-popover
                [popoverText]="'dtmSharedUav.setupProperties.flightTechnicalProperties.maxFlightTimeDescription' | transloco"
            ></dtm-ui-popover>
        </label>
        <input
            matInput
            type="number"
            [formControl]="propertiesForm.controls.maxFlightTime"
            [min]="MIN_MAX_FLIGHT_TIME_VALUE"
            [max]="MAX_MAX_FLIGHT_TIME_VALUE"
            inputmode="numeric"
            autocomplete="off"
            required
        />
        <div class="field-suffix">
            {{ "dtmWebAppLibUav.setupForm.flightTechnicalProperties.maxFlightTimeUnitLabel" | transloco }}
        </div>
        <div class="field-error" *dtmUiFieldHasError="propertiesForm.controls.maxFlightTime; name: ['required', 'pattern']">
            {{ "dtmWebAppLibUav.setupForm.requiredFieldErrorHint" | transloco }}
        </div>
        <div class="field-error" *dtmUiFieldHasError="propertiesForm.controls.maxFlightTime; name: 'min'; error as error">
            {{ "dtmWebAppLibUav.setupForm.valueLessThanMinErrorHint" | transloco : { min: error.min | localizeNumber } }}
        </div>
        <div class="field-error" *dtmUiFieldHasError="propertiesForm.controls.maxFlightTime; name: 'max'; error as error">
            {{ "dtmWebAppLibUav.setupForm.valueGreaterThanMaxErrorHint" | transloco : { max: error.max | localizeNumber } }}
        </div>
    </dtm-ui-input-field>
    <div class="switcher">
        <label>
            {{ "dtmSharedUav.setupProperties.flightTechnicalProperties.flightSpeedLimitLabel" | transloco }}
        </label>
        <mat-slide-toggle [formControl]="propertiesForm.controls.hasFlightSpeedLimit"></mat-slide-toggle>
    </div>
    <dtm-ui-input-field [isClearable]="false">
        <label>
            {{ "dtmSharedUav.setupProperties.flightTechnicalProperties.maxFlightSpeedLabel" | transloco }}
        </label>
        <input
            matInput
            type="number"
            [formControl]="propertiesForm.controls.maxFlightSpeed"
            [min]="MIN_MAX_FLIGHT_SPEED_VALUE"
            [max]="MAX_MAX_FLIGHT_SPEED_VALUE"
            inputmode="decimal"
            autocomplete="off"
            required
        />
        <div class="field-suffix">
            {{ "dtmWebAppLibUav.setupForm.flightTechnicalProperties.maxFlightSpeedUnitLabel" | transloco }}
        </div>
        <div class="field-error" *dtmUiFieldHasError="propertiesForm.controls.maxFlightSpeed; name: ['required', 'pattern']">
            {{ "dtmWebAppLibUav.setupForm.requiredFieldErrorHint" | transloco }}
        </div>
        <div class="field-error" *dtmUiFieldHasError="propertiesForm.controls.maxFlightSpeed; name: 'min'; error as error">
            {{ "dtmWebAppLibUav.setupForm.valueLessThanMinErrorHint" | transloco : { min: error.min | localizeNumber } }}
        </div>
        <div class="field-error" *dtmUiFieldHasError="propertiesForm.controls.maxFlightSpeed; name: 'max'; error as error">
            {{ "dtmWebAppLibUav.setupForm.valueGreaterThanMaxErrorHint" | transloco : { max: error.max | localizeNumber } }}
        </div>
    </dtm-ui-input-field>
    <dtm-ui-input-field [isClearable]="false" *ngIf="isMinFlightSpeedVisible$ | ngrxPush">
        <label>
            {{ "dtmSharedUav.setupProperties.flightTechnicalProperties.minFlightSpeedLabel" | transloco }}
        </label>
        <input
            matInput
            type="number"
            [formControl]="propertiesForm.controls.minFlightSpeed"
            [min]="MIN_MIN_FLIGHT_SPEED_VALUE"
            inputmode="decimal"
            autocomplete="off"
            required
        />
        <div class="field-suffix">
            {{ "dtmWebAppLibUav.setupForm.flightTechnicalProperties.minFlightSpeedUnitLabel" | transloco }}
        </div>
        <div class="field-error" *dtmUiFieldHasError="propertiesForm.controls.minFlightSpeed; name: ['required', 'pattern']">
            {{ "dtmWebAppLibUav.setupForm.requiredFieldErrorHint" | transloco }}
        </div>
        <div class="field-error" *dtmUiFieldHasError="propertiesForm.controls.minFlightSpeed; name: 'min'; error as error">
            {{ "dtmWebAppLibUav.setupForm.valueLessThanMinErrorHint" | transloco : { min: error.min | localizeNumber } }}
        </div>
    </dtm-ui-input-field>
</ng-container>
