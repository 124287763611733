import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, Input, forwardRef } from "@angular/core";
import {
    ControlValueAccessor,
    FormControl,
    FormGroup,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
    ValidatorFn,
    Validators,
} from "@angular/forms";
import { M1BStatementsKeys, RiskMitigationM1B, RobustnessLevel } from "@dtm-frontend/shared/mission";
import { AnimationUtils, FunctionUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

interface RiskMitigationM1BComponentState {
    isFlightOverControlledArea: boolean;
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-risk-mitigation-m1b",
    templateUrl: "./risk-mitigation-m1-b.component.html",
    styleUrls: ["./risk-mitigation-m1-b.component.scss"],
    providers: [
        LocalComponentStore,
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => RiskMitigationM1BComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => RiskMitigationM1BComponent),
            multi: true,
        },
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [AnimationUtils.slideInAnimation()],
})
export class RiskMitigationM1BComponent implements ControlValueAccessor, Validator {
    protected readonly isRiskMitigationEnabledControl = new FormControl(false, { nonNullable: true });

    protected readonly riskMitigationStatementsFormGroup = new FormGroup({
        [M1BStatementsKeys.ableToIdentifyRiskOnTheGround]: new FormControl(false, {
            nonNullable: true,
            validators: this.requiredTrueForControlValidator(this.isRiskMitigationEnabledControl),
        }),
        [M1BStatementsKeys.ableToReduceNumberOfBystandersAtRisk]: new FormControl(true, {
            nonNullable: true,
            validators: this.requiredTrueForControlValidator(this.isRiskMitigationEnabledControl),
        }),
        [M1BStatementsKeys.operationalProceduresDocumented]: new FormControl(false, {
            nonNullable: true,
            validators: this.requiredTrueForControlValidator(this.isRiskMitigationEnabledControl),
        }),
    });

    protected readonly isAbnormalSituationAlertFormGroup = new FormGroup({
        [M1BStatementsKeys.abnormalSituationAlert]: new FormControl(false, { nonNullable: true }),
    });

    protected readonly isAbnormalSituationCommandFormGroup = new FormGroup({
        [M1BStatementsKeys.abnormalSituationCommand]: new FormControl(false, { nonNullable: true }),
    });

    protected readonly isBystandersSafeDistanceFormGroup = new FormGroup({
        [M1BStatementsKeys.bystandersSafeDistance]: new FormControl(false, {
            nonNullable: true,
        }),
    });

    protected readonly riskMitigationFormGroup = new FormGroup({
        isRiskMitigationEnabled: this.isRiskMitigationEnabledControl,
        riskMitigationStatements: this.riskMitigationStatementsFormGroup,
        ableToReduceNumberOfBystandersAtRisk: new FormGroup(
            {
                isAbnormalSituationAlert: this.isAbnormalSituationAlertFormGroup,
                isAbnormalSituationCommand: this.isAbnormalSituationCommandFormGroup,
                isBystandersSafeDistance: this.isBystandersSafeDistanceFormGroup,
            },
            this.requiredOneForControlsValidator(
                [this.isRiskMitigationEnabledControl],
                this.isAbnormalSituationAlertFormGroup,
                this.isAbnormalSituationCommandFormGroup,
                this.isBystandersSafeDistanceFormGroup
            )
        ),
    });

    private propagateTouch = FunctionUtils.noop;
    private onValidationChange = FunctionUtils.noop;
    private propagateChange: (value: RiskMitigationM1B | null) => void = FunctionUtils.noop;

    protected readonly M1BStatementsKeys = M1BStatementsKeys;
    protected readonly RobustnessLevel = RobustnessLevel;

    protected readonly isFormDisabled$ = this.localStore.selectByKey("isFlightOverControlledArea");

    @Input() public set isFlightOverControlledArea(value: BooleanInput) {
        this.localStore.setState({ isFlightOverControlledArea: coerceBooleanProperty(value) });
    }

    constructor(private readonly localStore: LocalComponentStore<RiskMitigationM1BComponentState>) {
        this.localStore.setState({ isFlightOverControlledArea: false });
        this.setupRiskMitigationChangesWatch();
    }

    public writeValue(value: RiskMitigationM1B | null): void {
        let resetValue: typeof this.riskMitigationFormGroup.value | undefined;
        if (value) {
            resetValue = {
                riskMitigationStatements: this.mapStatementToFormValue(value.userStatements),
                ableToReduceNumberOfBystandersAtRisk: {
                    isAbnormalSituationCommand: this.mapStatementToFormValue(value.abnormalSituationCommand?.userStatements),
                    isAbnormalSituationAlert: this.mapStatementToFormValue(value.abnormalSituationAlert?.userStatements),
                    isBystandersSafeDistance: this.mapStatementToFormValue(value.bystandersSafeDistance?.userStatements),
                },
            };

            resetValue.isRiskMitigationEnabled = Object.values(resetValue).some((isStated) => isStated);
        }

        this.riskMitigationFormGroup.reset(resetValue, { emitEvent: false });
    }

    public registerOnChange(fn: (value: RiskMitigationM1B | null) => void): void {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.propagateTouch = fn;
    }

    public setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.riskMitigationFormGroup.disable({ emitEvent: false });
        } else {
            this.riskMitigationFormGroup.enable({ emitEvent: false });
        }
    }

    public registerOnValidatorChange(fn: () => void): void {
        this.onValidationChange = fn;
    }

    public validate(): ValidationErrors | null {
        return this.riskMitigationFormGroup.valid
            ? null
            : {
                  soraSettings: true,
              };
    }

    protected requiredTrueForControlValidator(forControl: FormControl): ValidatorFn {
        return (control) => (forControl.value ? Validators.requiredTrue(control) : null);
    }

    protected requiredOneForControlsValidator(forControls: FormControl[], ...formGroup: FormGroup[]): ValidatorFn {
        return () => {
            if (
                forControls.every(({ value }) => value) &&
                !formGroup.some((form) => Object.values(form.value).some(FunctionUtils.isTruthy))
            ) {
                return {
                    oneRequired: true,
                };
            }

            return null;
        };
    }

    private setupRiskMitigationChangesWatch() {
        this.riskMitigationFormGroup.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
            Object.values(this.riskMitigationFormGroup.controls).forEach((control) => control.updateValueAndValidity({ emitEvent: false }));
            Object.values(this.riskMitigationStatementsFormGroup.controls).forEach((control) =>
                control.updateValueAndValidity({ emitEvent: false })
            );

            if (this.riskMitigationFormGroup.invalid) {
                this.onValidationChange();

                return;
            }

            if (!value.isRiskMitigationEnabled) {
                this.propagateChange(null);
            } else {
                const riskMitigationM1BValue: RiskMitigationM1B = {
                    robustnessLevel: RobustnessLevel.Low,
                    userStatements: this.getStatementsKeys(value.riskMitigationStatements),
                    abnormalSituationCommand: {
                        userStatements: this.getStatementsKeys(value.ableToReduceNumberOfBystandersAtRisk?.isAbnormalSituationCommand),
                    },
                    bystandersSafeDistance: {
                        userStatements: this.getStatementsKeys(value.ableToReduceNumberOfBystandersAtRisk?.isBystandersSafeDistance),
                    },
                    abnormalSituationAlert: {
                        userStatements: this.getStatementsKeys(value.ableToReduceNumberOfBystandersAtRisk?.isAbnormalSituationAlert),
                    },
                };

                this.propagateChange(riskMitigationM1BValue);
            }
        });
    }

    private getStatementsKeys(statementsValue?: Record<string, boolean>): M1BStatementsKeys[] {
        if (!statementsValue) {
            return [];
        }

        return Object.entries(statementsValue)
            .filter(([, isStated]) => isStated)
            .map(([statementKey]) => statementKey) as M1BStatementsKeys[];
    }

    private mapStatementToFormValue(statement?: M1BStatementsKeys[]) {
        return statement?.reduce((statements, key) => ({ ...statements, [key]: true }), {});
    }
}
