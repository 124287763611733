import { HttpClient, HttpContext, HttpEvent, HttpParams } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import {
    AirspaceElementResponseBody,
    GEO_ZONES_ENDPOINTS,
    GeoZonesEndpoints,
    SearchAirspaceElementsRequestBody,
    convertAirspaceElementResponseBodyToAirspaceElement,
} from "@dtm-frontend/shared/map/geo-zones";
import {
    AdditionalCrewMember,
    CaaPermitData,
    FormalJustification,
    MissionCategory,
    MissionPlanDataAndCapabilities,
    MissionPlanInformation,
    MissionPlanOperationCategoryOption,
    MissionPlanResponseBodyCapabilitiesEntity,
    MissionPlanSpecificPermitType,
    MissionType,
} from "@dtm-frontend/shared/mission";
import { ItineraryEditorType } from "@dtm-frontend/shared/ui";
import {
    DateUtils,
    FileUploadErrorType,
    SECONDS_IN_DAY,
    SECONDS_IN_MINUTE,
    SKIP_GLOBAL_HTTP_ERRORS_INTERCEPTOR,
    SKIP_NOT_FOUND_HTTP_INTERCEPTOR,
    StringUtils,
} from "@dtm-frontend/shared/utils";
import { Polygon } from "@turf/helpers";
import { Observable, forkJoin, of, throwError } from "rxjs";
import { catchError, map, pluck, switchMap } from "rxjs/operators";
import { PansaUtmLinkStatus } from "../../pilot-profile/services/pilot-profile.models";
import { FlightPurpose } from "../../shared";
import { TACTICAL_ENDPOINTS, TacticalEndpoints } from "../../tactical/tactical.tokens";
import { MISSION_ENDPOINTS, MissionEndpoints } from "../mission.tokens";
import { ItineraryContent } from "../models/itinerary.model";
import {
    ActivePermit,
    AdditionalInformationRequestPayload,
    AuthorityType,
    ItineraryEditorFormData,
    MissionCapabilities,
    MissionErrorType,
    MissionPlanAndCapabilities,
    MissionPlanAssistedItineraryEntity,
    MissionPlanCustomItineraryEntity,
    MissionPlanItinerary,
    MissionPlanItineraryWithRoute,
    MissionPlanItineraryWithRouteResponseBody,
    MissionPlanList,
    MissionPlanListQuery,
    MissionPlanStandardItineraryEntity,
    MissionPlanUpdateFlightPurposeRequestEntity,
    MissionPlanUpdateOperationCategoryRequestEntity,
    MissionRoutePreference,
    PublicMissionPlanData,
} from "../models/mission.model";
import {
    ActivePermitListResponseBody,
    CaaPermitDataResponseBody,
    FlightPurposeResponseBody,
    MissionCapabilitiesResponseBody,
    MissionPlanCapabilitiesResponseBody,
    MissionPlanDataResponseBody,
    MissionPlanListResponseBody,
    MissionPlanRouteResponseBody,
    MissionPlanRoutesResponseBody,
    PansaUtmLinkStatusResponseBody,
    PublicMissionPlanResponseBody,
    RouteAirspaceInfoResponseBody,
    convertActivePermitsResponseBodyToActivePermitList,
    convertFormalJustificationToRequestPayload,
    convertItineraryContentToMissionPlanAssistedItineraryEntity,
    convertItineraryContentToMissionPlanCustomItineraryEntity,
    convertItineraryContentToMissionPlanStandardItineraryEntity,
    convertMissionCapabilitiesResponseBodyPilotEntityToMissionPilot,
    convertMissionCapabilitiesResponseBodyPreferencesEntityToMissionPlanningPreferences,
    convertMissionCapabilitiesResponseBodyRegulationExemptionsToRegulationsExemptions,
    convertMissionCapabilitiesResponseBodyUAVEntityToMissionUAV,
    convertMissionErrorResponse,
    convertMissionPlanDataResponseBodyToMissionPlanDataAndCapabilities,
    convertMissionPlanItineraryWithRouteResponseBodyToMissionPlanItineraryWithRoute,
    convertMissionPlanListQuery,
    convertMissionPlanResponseBodyToMissionPlanAndCapabilities,
    convertMissionPlanRouteResponseBodyToMissionPlanRoute,
    convertMissionsPlanListResponseBodyToMissionPlanList,
    convertOperationalGeometryDataResponseBodyToCaaPermitData,
    convertPublicMissionPlanResponseBodyToPublicMissionPlanData,
    convertRouteAirspaceInfoResponseBodyToAirspaceElementsInfo,
} from "./mission-api.converters";

@Injectable({
    providedIn: "root",
})
export class MissionAPIService {
    constructor(
        private readonly httpClient: HttpClient,
        @Inject(MISSION_ENDPOINTS) private readonly endpoints: MissionEndpoints,
        @Inject(TACTICAL_ENDPOINTS) private readonly tacticalEndpoints: TacticalEndpoints,
        @Inject(GEO_ZONES_ENDPOINTS) private readonly geoZonesEndpoints: GeoZonesEndpoints
    ) {}

    public getMissionCapabilities(operatorId: string): Observable<MissionCapabilities> {
        return this.httpClient
            .get<MissionCapabilitiesResponseBody>(StringUtils.replaceInTemplate(this.endpoints.getCapabilities, { operatorId }), {
                context: new HttpContext().set(SKIP_NOT_FOUND_HTTP_INTERCEPTOR, true),
            })
            .pipe(
                map(
                    (response): MissionCapabilities => ({
                        operator: {
                            displayName: response.operator.name,
                            id: response.operator.id,
                            pilots: response.operator.pilots.map(convertMissionCapabilitiesResponseBodyPilotEntityToMissionPilot),
                            uavs: response.operator.uavs.map(convertMissionCapabilitiesResponseBodyUAVEntityToMissionUAV),
                            regulationExemptions: response.operator.regulationExemptions.map(
                                convertMissionCapabilitiesResponseBodyRegulationExemptionsToRegulationsExemptions
                            ),
                        },
                        preferences: convertMissionCapabilitiesResponseBodyPreferencesEntityToMissionPlanningPreferences(
                            response.preferences
                        ),
                        additionalCrewMembersOptions: response.additionalCrewMembers,
                    })
                ),
                catchError((error) => throwError(convertMissionErrorResponse(error, MissionErrorType.CannotGetCapabilities)))
            );
    }

    public getMissionPlanItinerary(planId: string): Observable<MissionPlanItinerary> {
        return this.httpClient
            .get<MissionPlanItineraryWithRouteResponseBody>(
                StringUtils.replaceInTemplate(this.endpoints.getMissionPlanItinerary, { planId }),
                {
                    context: new HttpContext().set(SKIP_NOT_FOUND_HTTP_INTERCEPTOR, true),
                }
            )
            .pipe(
                map((response) => convertMissionPlanItineraryWithRouteResponseBodyToMissionPlanItineraryWithRoute(response).itinerary),
                catchError((error) => throwError(() => convertMissionErrorResponse(error, MissionErrorType.CannotGetMission)))
            );
    }

    public getCaaPermitData(planId: string): Observable<CaaPermitData> {
        return this.httpClient
            .get<CaaPermitDataResponseBody>(StringUtils.replaceInTemplate(this.endpoints.getCaaPermitDetails, { planId }), {
                context: new HttpContext().set(SKIP_NOT_FOUND_HTTP_INTERCEPTOR, true),
            })
            .pipe(
                map((response) => convertOperationalGeometryDataResponseBodyToCaaPermitData(response)),
                catchError((error) => throwError(() => convertMissionErrorResponse(error, MissionErrorType.CannotGetPermitDetails)))
            );
    }

    public getMissionRoute(routeId: string) {
        return this.httpClient
            .get<MissionPlanRouteResponseBody>(StringUtils.replaceInTemplate(this.endpoints.getMissionRoute, { routeId }), {
                headers: {
                    Accept: "application/vnd.pansa.mission-planner.route.api.v1+json",
                },

                context: new HttpContext().set(SKIP_NOT_FOUND_HTTP_INTERCEPTOR, true),
            })
            .pipe(
                map((response) => convertMissionPlanRouteResponseBodyToMissionPlanRoute(response)),
                catchError((error) => throwError(convertMissionErrorResponse(error, MissionErrorType.CannotGetRoute)))
            );
    }

    public getMissionRoutes(planId: string, routePreference: MissionRoutePreference = MissionRoutePreference.Length) {
        return this.httpClient
            .get<MissionPlanRoutesResponseBody>(this.endpoints.getMissionPlanRoutes, {
                params: { planId, prefer: routePreference },
                context: new HttpContext().set(SKIP_NOT_FOUND_HTTP_INTERCEPTOR, true),
            })
            .pipe(
                map((response) => response.items.map((item) => convertMissionPlanRouteResponseBodyToMissionPlanRoute(item))),
                catchError((error) => throwError(convertMissionErrorResponse(error, MissionErrorType.CannotGetRoute)))
            );
    }

    public createMissionCapabilities(
        flightType: MissionType,
        operatorId: string,
        pilotId: string,
        uavSetupId: string,
        additionalCrew: AdditionalCrewMember[]
    ): Observable<MissionPlanAndCapabilities> {
        const capabilities: MissionPlanResponseBodyCapabilitiesEntity = {
            flightType: flightType,
            operator: operatorId,
            pilot: pilotId,
            uavSetup: uavSetupId,
            additionalCrew,
        };

        return this.httpClient
            .post<MissionPlanCapabilitiesResponseBody>(
                this.endpoints.createMissionPlan,
                { capabilities },
                { context: new HttpContext().set(SKIP_GLOBAL_HTTP_ERRORS_INTERCEPTOR, true) }
            )
            .pipe(
                map((response) => convertMissionPlanResponseBodyToMissionPlanAndCapabilities(response)),
                catchError((error) => throwError(convertMissionErrorResponse(error, MissionErrorType.CannotCreateMissionPlan)))
            );
    }

    public updateMissionCapabilities(
        planId: string,
        flightType: MissionType,
        operatorId: string,
        pilotId: string,
        uavSetupId: string,
        additionalCrew: AdditionalCrewMember[]
    ): Observable<MissionPlanAndCapabilities> {
        const capabilities: MissionPlanResponseBodyCapabilitiesEntity = {
            flightType: flightType,
            operator: operatorId,
            pilot: pilotId,
            uavSetup: uavSetupId,
            additionalCrew,
        };

        return this.httpClient
            .put<MissionPlanCapabilitiesResponseBody>(
                StringUtils.replaceInTemplate(this.endpoints.updateMissionPlanCapabilities, { planId }),
                {
                    capabilities,
                },
                {
                    context: new HttpContext().set(SKIP_GLOBAL_HTTP_ERRORS_INTERCEPTOR, true),
                }
            )
            .pipe(
                map((response) => convertMissionPlanResponseBodyToMissionPlanAndCapabilities(response)),
                catchError((error) => throwError(convertMissionErrorResponse(error, MissionErrorType.CannotUpdateMissionPlan)))
            );
    }

    public updateMissionPlanFlightPurpose(
        planId: string,
        flightPurposeId: string,
        flightPurposeDescription: string | undefined,
        loadWeight: number | undefined
    ): Observable<MissionPlanAndCapabilities> {
        const flightPurpose: MissionPlanUpdateFlightPurposeRequestEntity = {
            id: flightPurposeId,
            comment: flightPurposeDescription,
            loadWeight,
        };

        return this.httpClient
            .put<MissionPlanCapabilitiesResponseBody>(
                StringUtils.replaceInTemplate(this.endpoints.updateMissionPlanFlightPurpose, { planId }),
                {
                    flightPurpose,
                },
                {
                    context: new HttpContext().set(SKIP_GLOBAL_HTTP_ERRORS_INTERCEPTOR, true),
                }
            )
            .pipe(
                map((response) => convertMissionPlanResponseBodyToMissionPlanAndCapabilities(response)),
                catchError((error) => throwError(convertMissionErrorResponse(error, MissionErrorType.CannotUpdateMissionPlan)))
            );
    }

    public updateMissionPlanOperationCategory(
        planId: string,
        category: MissionPlanOperationCategoryOption
    ): Observable<MissionPlanAndCapabilities> {
        const operationCategory: MissionPlanUpdateOperationCategoryRequestEntity = { type: category.type };

        if (
            category.type === MissionCategory.Open ||
            (category.type === MissionCategory.Specific && category.specificPermitType === MissionPlanSpecificPermitType.Sts)
        ) {
            operationCategory.scenarioName = category.scenarioName;
        }

        if (category.type === MissionCategory.Specific) {
            operationCategory.specificPermit = category.specificPermitType;
        }

        if (category.type === MissionCategory.Specific && category.specificPermitType === MissionPlanSpecificPermitType.Luc) {
            operationCategory.specificLucSkipSora = category.shouldSkipSora;
        }

        if (category.specificCaaPermitId) {
            operationCategory.specificCaaPermitId = category.specificCaaPermitId;
        }

        return this.httpClient
            .put<MissionPlanCapabilitiesResponseBody>(
                StringUtils.replaceInTemplate(this.endpoints.updateMissionPlanOperationCategory, { planId }),
                {
                    operationCategory,
                },
                {
                    context: new HttpContext().set(SKIP_GLOBAL_HTTP_ERRORS_INTERCEPTOR, true),
                }
            )
            .pipe(
                map((response) => convertMissionPlanResponseBodyToMissionPlanAndCapabilities(response)),
                catchError((error) => throwError(convertMissionErrorResponse(error, MissionErrorType.CannotUpdateMissionPlan)))
            );
    }

    public updateMissionPlanItinerary(
        planId: string,
        itineraryContent: ItineraryContent,
        itineraryData: ItineraryEditorFormData
    ): Observable<MissionPlanItineraryWithRoute> {
        if (itineraryContent.length === 0 || !itineraryData.datetime) {
            return throwError(() => ({ type: MissionErrorType.CannotUpdateMissionPlan }));
        }

        const missionDateRounded = new Date(itineraryData.datetime);
        missionDateRounded.setSeconds(0, 0);

        let missionDateIso = missionDateRounded?.toISOString();
        let durationTime = DateUtils.convertSecondsToISO8601Duration((itineraryData.durationMinutes ?? 0) * SECONDS_IN_MINUTE);

        if (itineraryData.dateRangeStart !== null && itineraryData.dateRangeEnd !== null) {
            const dateRangeStartRounded = new Date(itineraryData.dateRangeStart);
            dateRangeStartRounded.setHours(0, 0, 0, 0);

            missionDateIso = dateRangeStartRounded.toISOString();
            durationTime = DateUtils.convertSecondsToISO8601Duration(
                DateUtils.calculateDiffDate(itineraryData.dateRangeStart, itineraryData.dateRangeEnd).days * SECONDS_IN_DAY
            );
        }

        let payload: MissionPlanStandardItineraryEntity | MissionPlanCustomItineraryEntity | MissionPlanAssistedItineraryEntity | undefined;

        switch (itineraryData.editorType) {
            case ItineraryEditorType.Custom:
                payload = convertItineraryContentToMissionPlanCustomItineraryEntity(itineraryContent, missionDateIso, itineraryData);
                break;
            case ItineraryEditorType.Assisted:
                payload = convertItineraryContentToMissionPlanAssistedItineraryEntity(itineraryContent, missionDateIso, itineraryData);
                break;
            case ItineraryEditorType.Standard:
                payload = convertItineraryContentToMissionPlanStandardItineraryEntity(
                    itineraryContent,
                    missionDateIso,
                    durationTime,
                    itineraryData
                );
                break;
            default:
                return throwError(() => "Unsupported editor type");
        }

        payload.soraSettings = itineraryData.soraSettings;

        if (itineraryData.horizontalBuffer && itineraryData.verticalBuffer) {
            payload.navigationAccuracy = {
                horizontal: itineraryData.horizontalBuffer / 2,
                vertical: itineraryData.verticalBuffer / 2,
            };
        }

        return this.httpClient
            .post<MissionPlanItineraryWithRouteResponseBody>(
                StringUtils.replaceInTemplate(this.endpoints.updateMissionPlanItinerary, { planId }),
                {
                    itinerary: payload,
                },
                {
                    headers: {
                        "content-type": "application/vnd.pansa.mission-planner.itinerary.request.api.v1+json",
                    },
                    context: new HttpContext().set(SKIP_GLOBAL_HTTP_ERRORS_INTERCEPTOR, true),
                }
            )
            .pipe(
                switchMap((response): Observable<MissionPlanItineraryWithRoute> => {
                    const result = convertMissionPlanItineraryWithRouteResponseBodyToMissionPlanItineraryWithRoute(response);
                    if (result.itinerary.type !== ItineraryEditorType.None) {
                        return of(result);
                    }

                    return throwError(() => "Invalid itinerary response");
                }),
                catchError((error) => throwError(() => convertMissionErrorResponse(error, MissionErrorType.CannotUpdateMissionPlan)))
            );
    }

    public registerMission(planId: string): Observable<void> {
        return this.httpClient
            .post<void>(
                StringUtils.replaceInTemplate(this.endpoints.registerMission, { planId }),
                {},
                {
                    context: new HttpContext().set(SKIP_GLOBAL_HTTP_ERRORS_INTERCEPTOR, true),
                }
            )
            .pipe(catchError((error) => throwError(convertMissionErrorResponse(error, MissionErrorType.CannotRegisterMission))));
    }

    public closeSpecificPermitPlan(planId: string): Observable<void> {
        return this.httpClient
            .post<void>(
                StringUtils.replaceInTemplate(this.endpoints.closeSpecificPermitPlan, { planId }),
                {},
                {
                    context: new HttpContext().set(SKIP_GLOBAL_HTTP_ERRORS_INTERCEPTOR, true),
                }
            )
            .pipe(catchError(() => throwError(() => ({ type: MissionErrorType.CannotCloseSoraPlan }))));
    }

    public createMissionPlanRoutes(planId: string): Observable<string[]> {
        return this.httpClient
            .post<{ routeIds: string[] }>(
                StringUtils.replaceInTemplate(this.endpoints.createMissionPlanRoutes, { planId }),
                {},
                {
                    context: new HttpContext().set(SKIP_GLOBAL_HTTP_ERRORS_INTERCEPTOR, true),
                }
            )
            .pipe(
                map(({ routeIds }) => routeIds),
                catchError((error) => throwError(convertMissionErrorResponse(error, MissionErrorType.CannotUpdateMissionPlan)))
            );
    }

    public getMissionPlanList(query?: MissionPlanListQuery, operatorId?: string): Observable<MissionPlanList> {
        const params = new HttpParams({ fromObject: { ...convertMissionPlanListQuery(query) } });

        return this.httpClient
            .get<MissionPlanListResponseBody>(this.endpoints.getMissionList, {
                params,
                context: new HttpContext().set(SKIP_NOT_FOUND_HTTP_INTERCEPTOR, true),
            })
            .pipe(
                map((response) => convertMissionsPlanListResponseBodyToMissionPlanList(response, operatorId)),
                catchError(() => throwError({ type: MissionErrorType.Unknown }))
            );
    }

    public saveMissionThumbnail(file: Blob, planId: string): Observable<HttpEvent<void>> {
        const formData: FormData = new FormData();

        formData.append("file", file);

        return this.httpClient
            .put<void>(StringUtils.replaceInTemplate(this.endpoints.missionThumbnailManagement, { planId }), formData, {
                observe: "events",
                context: new HttpContext().set(SKIP_GLOBAL_HTTP_ERRORS_INTERCEPTOR, true),
            })
            .pipe(catchError(() => throwError({ type: FileUploadErrorType.Unknown })));
    }

    public getFlightPurposes(): Observable<FlightPurpose[]> {
        return this.httpClient
            .get<FlightPurposeResponseBody>(this.endpoints.getFlightPurposes, {
                context: new HttpContext().set(SKIP_NOT_FOUND_HTTP_INTERCEPTOR, true),
            })
            .pipe(
                map(({ flightPurposes }) => flightPurposes),
                catchError((error) => throwError(convertMissionErrorResponse(error, MissionErrorType.Unknown)))
            );
    }

    public deleteMissionPlan(planId: string): Observable<void> {
        return this.httpClient
            .delete<void>(StringUtils.replaceInTemplate(this.endpoints.missionManagement, { planId }), {
                context: new HttpContext().set(SKIP_GLOBAL_HTTP_ERRORS_INTERCEPTOR, true),
            })
            .pipe(catchError(() => throwError(() => ({ type: MissionErrorType.Unknown }))));
    }

    public cancelMission(missionId: string): Observable<void> {
        return this.httpClient
            .delete<void>(StringUtils.replaceInTemplate(this.tacticalEndpoints.missionManagement, { missionId }), {
                context: new HttpContext().set(SKIP_GLOBAL_HTTP_ERRORS_INTERCEPTOR, true),
            })
            .pipe(catchError(() => throwError(() => ({ type: MissionErrorType.CannotCancelMission }))));
    }

    public cloneMissionPlan(planId: string): Observable<string> {
        return this.httpClient
            .post<MissionPlanCapabilitiesResponseBody>(StringUtils.replaceInTemplate(this.endpoints.cloneMissionPlan, { planId }), null)
            .pipe(
                pluck("plan", "id"),
                catchError(() => throwError(() => ({ type: MissionErrorType.CannotCloneMission })))
            );
    }

    public getMissionData(
        planId: string | null,
        withEditorOptions: boolean,
        operatorId?: string
    ): Observable<MissionPlanDataAndCapabilities> {
        if (!planId) {
            throw new Error("PlanId not specified");
        }

        return forkJoin([
            this.httpClient.get<MissionPlanDataResponseBody>(StringUtils.replaceInTemplate(this.endpoints.missionManagement, { planId }), {
                context: new HttpContext().set(SKIP_NOT_FOUND_HTTP_INTERCEPTOR, true),
            }),
            withEditorOptions
                ? this.httpClient.get<MissionPlanDataResponseBody>(
                      StringUtils.replaceInTemplate(this.endpoints.editorOptions, { planId }),
                      { context: new HttpContext().set(SKIP_NOT_FOUND_HTTP_INTERCEPTOR, true) }
                  )
                : of({}),
        ]).pipe(
            map(([missionPlanData, editorOptions]): MissionPlanDataResponseBody => ({ ...missionPlanData, ...editorOptions })),
            map((response) => convertMissionPlanDataResponseBodyToMissionPlanDataAndCapabilities(response, operatorId)),
            catchError(() => throwError({ type: MissionErrorType.CannotGetMission }))
        );
    }

    public getActivePermitsList(operatorId: string): Observable<ActivePermit[]> {
        return this.httpClient
            .get<ActivePermitListResponseBody>(StringUtils.replaceInTemplate(this.endpoints.getActivePermitsList, { operatorId }), {
                context: new HttpContext().set(SKIP_NOT_FOUND_HTTP_INTERCEPTOR, true),
            })
            .pipe(
                map(({ permits }) => convertActivePermitsResponseBodyToActivePermitList(permits)),
                catchError(() => throwError(() => ({ type: MissionErrorType.Unknown })))
            );
    }

    public updateAdditionalInformation(planId: string, information: MissionPlanInformation) {
        const payload: AdditionalInformationRequestPayload = { information };

        return this.httpClient
            .put<MissionPlanDataResponseBody>(
                StringUtils.replaceInTemplate(this.endpoints.updateAdditionalInformation, { planId }),
                payload,
                {
                    context: new HttpContext().set(SKIP_GLOBAL_HTTP_ERRORS_INTERCEPTOR, true),
                }
            )
            .pipe(
                map((response) => convertMissionPlanResponseBodyToMissionPlanAndCapabilities(response)),
                catchError((error) => throwError(() => convertMissionErrorResponse(error, MissionErrorType.Unknown)))
            );
    }

    public assignMissionPlanRoute(planId: string, routeId: string): Observable<void> {
        return this.httpClient
            .put<void>(
                StringUtils.replaceInTemplate(this.endpoints.assignMissionPlanRoute, { planId }),
                { routeId },
                { context: new HttpContext().set(SKIP_GLOBAL_HTTP_ERRORS_INTERCEPTOR, true) }
            )
            .pipe(catchError((error) => throwError(() => convertMissionErrorResponse(error, MissionErrorType.Unknown))));
    }

    public createOrUpdateFormalJustification(formalJustification: FormalJustification | undefined, planId: string) {
        return this.httpClient
            .put<{ id: string }>(
                StringUtils.replaceInTemplate(this.endpoints.createOrUpdateFormalJustification, { planId }),
                convertFormalJustificationToRequestPayload(AuthorityType.Dtm, formalJustification),
                { context: new HttpContext().set(SKIP_GLOBAL_HTTP_ERRORS_INTERCEPTOR, true) }
            )
            .pipe(catchError((error) => throwError(() => convertMissionErrorResponse(error, MissionErrorType.Unknown))));
    }

    public searchAirspaceElements(options: Partial<SearchAirspaceElementsRequestBody>) {
        return this.httpClient
            .post<AirspaceElementResponseBody>(
                this.geoZonesEndpoints.searchAirspaceElements,
                {
                    ...options,
                    includeLocal: true,
                    includeTemporary: true,
                },
                { context: new HttpContext().set(SKIP_GLOBAL_HTTP_ERRORS_INTERCEPTOR, true) }
            )
            .pipe(map((respose) => convertAirspaceElementResponseBodyToAirspaceElement(respose, options.scope?.endTime)));
    }

    public getAirspaceElementsInfo(routeId: string) {
        return this.httpClient
            .get<RouteAirspaceInfoResponseBody>(StringUtils.replaceInTemplate(this.endpoints.getRouteAirspaceInfo, { routeId }), {
                context: new HttpContext().set(SKIP_GLOBAL_HTTP_ERRORS_INTERCEPTOR, true),
            })
            .pipe(map((response) => convertRouteAirspaceInfoResponseBodyToAirspaceElementsInfo(response)));
    }

    public getPublicMissionData(planId: string | null): Observable<PublicMissionPlanData> {
        if (!planId) {
            throw new Error("PlanId not specified");
        }

        return this.httpClient
            .get<PublicMissionPlanResponseBody>(StringUtils.replaceInTemplate(this.endpoints.getPublicMissionPlan, { planId }), {
                context: new HttpContext().set(SKIP_NOT_FOUND_HTTP_INTERCEPTOR, true),
            })
            .pipe(
                map((response) => convertPublicMissionPlanResponseBodyToPublicMissionPlanData(response)),
                catchError(() => throwError(() => ({ type: MissionErrorType.CannotGetMission })))
            );
    }

    public acknowledgePlanMessage(systemVerificationId: string, reviewerUnitId?: string): Observable<void> {
        let url;

        if (reviewerUnitId) {
            url = StringUtils.replaceInTemplate(this.endpoints.partialMessageAcknowledgement, { systemVerificationId, reviewerUnitId });
        } else {
            url = StringUtils.replaceInTemplate(this.endpoints.messageAcknowledgement, { systemVerificationId });
        }

        return this.httpClient
            .post<void>(url, null, {
                context: new HttpContext().set(SKIP_GLOBAL_HTTP_ERRORS_INTERCEPTOR, true),
            })
            .pipe(catchError(() => throwError(() => ({ type: MissionErrorType.Unknown }))));
    }

    public getPansaUtmLinkStatus(pilotId: string, operatorId: string): Observable<boolean> {
        return this.httpClient
            .get<PansaUtmLinkStatusResponseBody>(
                StringUtils.replaceInTemplate(this.endpoints.pansaUtmLinkStatus, { pilotId, operatorId }),
                {
                    context: new HttpContext().set(SKIP_GLOBAL_HTTP_ERRORS_INTERCEPTOR, true),
                }
            )
            .pipe(
                map(({ pansaUtmLink }) => pansaUtmLink?.status === PansaUtmLinkStatus.Active),
                catchError(() => throwError(() => ({ type: MissionErrorType.Unknown })))
            );
    }

    public getKmlFile(fileId: string): Observable<Blob> {
        return this.httpClient
            .get(StringUtils.replaceInTemplate(this.endpoints.downloadKml, { kmlFileId: fileId }), {
                responseType: "blob",
                context: new HttpContext().set(SKIP_NOT_FOUND_HTTP_INTERCEPTOR, true),
            })
            .pipe(catchError(() => throwError(() => ({ type: MissionErrorType.Unknown }))));
    }

    public getPermitZoneData(designator: string): Observable<Polygon> {
        return this.searchAirspaceElements({ designators: [designator] }).pipe(map((elements) => elements.airspaceElements[0]?.geometry));
    }
}
