import { AfterViewInit, ChangeDetectionStrategy, Component, forwardRef, Input } from "@angular/core";
import {
    AbstractControl,
    ControlValueAccessor,
    FormControl,
    FormGroup,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
    Validators,
} from "@angular/forms";
import {
    MissionCategory,
    MissionPlanLucOperationCategoryOption,
    MissionPlanOpenOperationCategoryOption,
    MissionPlanOperationCategoryOption,
    MissionPlanSpecificPermitType,
    MissionPlanStsOperationCategoryOption,
    MissionType,
} from "@dtm-frontend/shared/mission";
import { FunctionUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { defer } from "rxjs";
import { map, startWith, tap } from "rxjs/operators";

interface OperationCategoryFormControlComponentState {
    openCategoryOptions: MissionPlanOpenOperationCategoryOption[];
    stsCategoryOptions: MissionPlanStsOperationCategoryOption[];
    individualCategoryOption: MissionPlanOperationCategoryOption | undefined;
    lucCategoryOptions: { skipSora: MissionPlanOperationCategoryOption; withSora: MissionPlanOperationCategoryOption } | undefined;
    specificCaaPermitId: string | undefined;
    flightType: MissionType | undefined;
}

// TODO: DTM-4097 - this should be moved to the backend
const DEFAULT_STS_VLOS_SCENARIO_NAME = "NSTS-01 VLOS";

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-operation-category-form-control",
    templateUrl: "./operation-category-form-control.component.html",
    styleUrls: ["../mission-data-form-step.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        LocalComponentStore,
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => OperationCategoryFormControlComponent),
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: forwardRef(() => OperationCategoryFormControlComponent),
        },
    ],
})
export class OperationCategoryFormControlComponent implements ControlValueAccessor, Validator, AfterViewInit {
    protected MissionCategory = MissionCategory;
    protected MissionPlanSpecificPermitType = MissionPlanSpecificPermitType;

    protected readonly categoryFormControl = new FormControl<MissionCategory>(MissionCategory.Specific, {
        nonNullable: true,
        validators: [Validators.required],
    });
    protected readonly specificPermitFormControl = new FormControl<MissionPlanSpecificPermitType>(MissionPlanSpecificPermitType.Sts, {
        nonNullable: true,
        validators: [Validators.required],
    });
    protected readonly openScenarioFormControl = new FormControl<MissionPlanOpenOperationCategoryOption | undefined>(
        undefined,
        Validators.required
    );
    protected readonly specificScenarioFormControl = new FormControl<MissionPlanStsOperationCategoryOption | undefined>(
        undefined,
        Validators.required
    );
    protected readonly lucSkipSoraFormControl = new FormControl<boolean>(false, { nonNullable: true });

    protected readonly operationCategoryFormGroup = new FormGroup({
        category: this.categoryFormControl,
        specificPermit: this.specificPermitFormControl,
        openScenario: this.openScenarioFormControl,
        specificScenario: this.specificScenarioFormControl,
        skipSora: this.lucSkipSoraFormControl,
    });

    protected readonly isCaaPermit$ = this.localStore.selectByKey("specificCaaPermitId");
    protected readonly individualCategoryOption$ = this.localStore.selectByKey("individualCategoryOption");
    protected readonly lucCategoryOptions$ = this.localStore.selectByKey("lucCategoryOptions");
    protected readonly openCategoryOptions$ = this.localStore.selectByKey("openCategoryOptions");
    protected readonly stsCategoryOptions$ = this.localStore.selectByKey("stsCategoryOptions");
    protected readonly enabledStsCategoryOptions$ = this.stsCategoryOptions$.pipe(
        map((options) => options.filter((option) => !option.isDisabled))
    );
    protected readonly disabledStsCategoryOptions$ = this.stsCategoryOptions$.pipe(
        map((options) => options.filter((option) => option.isDisabled))
    );
    protected readonly enabledOpenCategoryOptions$ = this.openCategoryOptions$.pipe(
        map((options) => options.filter((option) => !option.isDisabled))
    );
    protected readonly disabledOpenCategoryOptions$ = this.openCategoryOptions$.pipe(
        map((options) => options.filter((option) => option.isDisabled))
    );
    protected readonly operationCategoryFormGroupValue$ = defer(() =>
        this.operationCategoryFormGroup.valueChanges.pipe(startWith(this.operationCategoryFormGroup.value))
    );

    @Input() public set options(value: MissionPlanOperationCategoryOption[] | undefined) {
        value ??= [];

        const individualCategoryOption: MissionPlanOperationCategoryOption = {
            type: MissionCategory.Specific,
            specificPermitType: MissionPlanSpecificPermitType.Individual,
            isDisabled: false,
        };

        const skipSoraLucCategoryOption = value.find(
            (category): category is MissionPlanLucOperationCategoryOption =>
                category.type === MissionCategory.Specific &&
                category.specificPermitType === MissionPlanSpecificPermitType.Luc &&
                category.shouldSkipSora
        );

        const withSoraLucCategoryOption = value.find(
            (category): category is MissionPlanLucOperationCategoryOption =>
                category.type === MissionCategory.Specific &&
                category.specificPermitType === MissionPlanSpecificPermitType.Luc &&
                category.shouldSkipSora === false
        );

        const openCategoryOptions = value.filter(
            (category): category is MissionPlanOpenOperationCategoryOption => category.type === MissionCategory.Open
        );

        const stsCategoryOptions = value.filter(
            (category): category is MissionPlanStsOperationCategoryOption =>
                category.type === MissionCategory.Specific &&
                category.specificPermitType === MissionPlanSpecificPermitType.Sts &&
                !category.isHidden
        );

        const lucCategoryOptions =
            !skipSoraLucCategoryOption || !withSoraLucCategoryOption
                ? undefined
                : {
                      skipSora: skipSoraLucCategoryOption,
                      withSora: withSoraLucCategoryOption,
                  };

        this.localStore.patchState({
            openCategoryOptions,
            stsCategoryOptions,
            individualCategoryOption,
            lucCategoryOptions,
        });

        this.updateFormControlsDependingOnAvailability(openCategoryOptions, stsCategoryOptions);
    }

    @Input() public set flightType(value: MissionType | undefined) {
        this.localStore.patchState({ flightType: value });
    }

    constructor(private readonly localStore: LocalComponentStore<OperationCategoryFormControlComponentState>) {
        this.localStore.setState({
            individualCategoryOption: undefined,
            lucCategoryOptions: undefined,
            openCategoryOptions: [],
            stsCategoryOptions: [],
            specificCaaPermitId: undefined,
            flightType: undefined,
        });

        this.operationCategoryFormGroup.valueChanges
            .pipe(
                tap(() => this.markAsTouched()),
                untilDestroyed(this)
            )
            .subscribe();
    }

    public onChange: (value?: MissionPlanOperationCategoryOption) => void = FunctionUtils.noop;
    public onTouched: () => void = FunctionUtils.noop;

    public touched = false;

    public ngAfterViewInit(): void {
        this.watchAndSetDefaultStsVLosScenario();
    }

    private watchAndSetDefaultStsVLosScenario() {
        this.operationCategoryFormGroup.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
            const flightType = this.localStore.selectSnapshotByKey("flightType");

            if (
                flightType === MissionType.VLOS &&
                value.category === MissionCategory.Specific &&
                value.specificPermit === MissionPlanSpecificPermitType.Sts &&
                !value.specificScenario
            ) {
                const defaultStsVLosScenario = this.localStore
                    .selectSnapshotByKey("stsCategoryOptions")
                    ?.find(({ scenarioName, isDisabled }) => scenarioName === DEFAULT_STS_VLOS_SCENARIO_NAME && !isDisabled);

                if (defaultStsVLosScenario) {
                    this.setSpecificScenarioOption(defaultStsVLosScenario);
                }
            }
        });
    }

    protected setCategoryTypeOption(category: MissionCategory) {
        this.categoryFormControl.setValue(category);

        const enabledOpenCategoryOptions = this.localStore
            .selectSnapshotByKey("openCategoryOptions")
            .filter((option) => !option.isDisabled);

        switch (category) {
            case MissionCategory.Open:
                if (this.openScenarioFormControl.value) {
                    this.setOpenScenarioOption(this.openScenarioFormControl.value);
                } else if (enabledOpenCategoryOptions.length > 0) {
                    this.setOpenScenarioOption(enabledOpenCategoryOptions[0]);
                }

                break;

            case MissionCategory.Specific:
                if (this.specificPermitFormControl.value) {
                    this.setSpecificPermitTypeOption(this.specificPermitFormControl.value);
                } else {
                    this.onChange();
                }
                break;

            case MissionCategory.Certified:
                throw new Error("Not implemented");
        }
    }

    protected setSpecificPermitTypeOption(type: MissionPlanSpecificPermitType, selectedOption?: MissionPlanOperationCategoryOption) {
        this.specificPermitFormControl.setValue(type);

        if (selectedOption) {
            this.onChange(selectedOption);
        } else {
            const enabledStsCategoryOptions = this.localStore
                .selectSnapshotByKey("stsCategoryOptions")
                .filter((option) => !option.isDisabled);

            if (type === MissionPlanSpecificPermitType.Sts && enabledStsCategoryOptions.length > 0) {
                this.setSpecificScenarioOption(this.specificScenarioFormControl.value ?? enabledStsCategoryOptions[0]);

                return;
            }

            if (type === MissionPlanSpecificPermitType.Sts && enabledStsCategoryOptions.length === 0) {
                this.setSpecificPermitTypeOption(MissionPlanSpecificPermitType.Individual);

                return;
            }

            if (type === MissionPlanSpecificPermitType.Individual) {
                this.setSpecificPermitTypeOption(
                    MissionPlanSpecificPermitType.Individual,
                    this.localStore.selectSnapshotByKey("individualCategoryOption")
                );

                return;
            }

            const lucCategoryOptions = this.localStore.selectSnapshotByKey("lucCategoryOptions");

            if (
                type === MissionPlanSpecificPermitType.Luc &&
                typeof this.lucSkipSoraFormControl.value === "boolean" &&
                lucCategoryOptions
            ) {
                this.setSpecificPermitTypeOption(
                    MissionPlanSpecificPermitType.Luc,
                    this.lucSkipSoraFormControl.value ? lucCategoryOptions.skipSora : lucCategoryOptions.withSora
                );
            }
        }
    }

    protected setOpenScenarioOption(selectedOption: MissionPlanOpenOperationCategoryOption) {
        this.openScenarioFormControl.setValue(selectedOption);
        this.onChange(selectedOption);
    }

    protected setSpecificScenarioOption(selectedOption: MissionPlanStsOperationCategoryOption) {
        this.specificScenarioFormControl.setValue(selectedOption);
        this.onChange(selectedOption);
    }

    public writeValue(option: MissionPlanOperationCategoryOption | undefined) {
        this.localStore.patchState({ specificCaaPermitId: option?.specificCaaPermitId });

        this.operationCategoryFormGroup.reset(undefined, { emitEvent: false });

        if (option?.type === MissionCategory.Open) {
            this.categoryFormControl.setValue(MissionCategory.Open, { emitEvent: false });
            this.openScenarioFormControl.setValue(option, { emitEvent: false });
        }

        if (option?.type === MissionCategory.Specific) {
            this.categoryFormControl.setValue(MissionCategory.Specific, { emitEvent: false });
            this.specificPermitFormControl.setValue(option.specificPermitType, { emitEvent: false });

            if (option.specificPermitType === MissionPlanSpecificPermitType.Sts) {
                this.specificScenarioFormControl.setValue(option, { emitEvent: false });
            }

            if (option.specificPermitType === MissionPlanSpecificPermitType.Luc) {
                this.lucSkipSoraFormControl.setValue(option.shouldSkipSora, { emitEvent: false });
            }
        }

        this.operationCategoryFormGroup.updateValueAndValidity();
    }

    public registerOnChange(onChange: (value?: MissionPlanOperationCategoryOption) => void) {
        this.onChange = onChange;
    }

    public registerOnTouched(onTouched: () => void) {
        this.onTouched = onTouched;
    }

    public markAsTouched() {
        if (!this.touched) {
            this.onTouched();
            this.touched = true;
        }
    }

    public setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.operationCategoryFormGroup.disable();

            return;
        }
        this.operationCategoryFormGroup.enable();
    }

    public validate(control: AbstractControl): ValidationErrors | null {
        return control.value ? null : { required: true };
    }

    private updateFormControlsDependingOnAvailability(
        openCategoryOptions: MissionPlanOpenOperationCategoryOption[],
        stsCategoryOptions: MissionPlanStsOperationCategoryOption[]
    ) {
        if (this.categoryFormControl.value === MissionCategory.Open && !openCategoryOptions.filter((option) => !option.isDisabled).length) {
            this.operationCategoryFormGroup.reset();
            this.setCategoryTypeOption(MissionCategory.Specific);
            this.setSpecificPermitTypeOption(MissionPlanSpecificPermitType.Sts);
        }

        if (
            this.categoryFormControl.value === MissionCategory.Specific &&
            this.specificPermitFormControl.value === MissionPlanSpecificPermitType.Sts &&
            !stsCategoryOptions.filter((option) => !option.isDisabled).length
        ) {
            this.operationCategoryFormGroup.reset({
                category: MissionCategory.Specific,
                specificPermit: MissionPlanSpecificPermitType.Individual,
            });
            this.setSpecificPermitTypeOption(MissionPlanSpecificPermitType.Individual);
        }
    }
}
