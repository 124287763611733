import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { DefaultGlobalConfig, ToastContainerDirective, ToastrService } from "ngx-toastr";
import { CircularBoundaryViolationToastComponent } from "./circular-boundary-violation-toast/circular-boundary-violation-toast.component";

interface CircularBoundaryViolationComponentState {
    isShown: boolean;
    maxRadius: number | undefined;
}

@Component({
    selector: "dtm-web-app-lib-circular-boundary-violation[maxRadius]",
    templateUrl: "circular-boundary-violation.component.html",
    styleUrls: ["circular-boundary-violation.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore, ToastrService],
})
export class CircularBoundaryViolationComponent {
    @ViewChild(ToastContainerDirective, { static: false }) protected set boundaryViolationToastContainer(value: ToastContainerDirective) {
        this.initToastContainer(value);
    }

    @Input() public set isShown(value: BooleanInput) {
        this.localStore.patchState({ isShown: coerceBooleanProperty(value) });
    }

    @Input() public set maxRadius(value: number | undefined) {
        this.localStore.patchState({ maxRadius: value });

        if (this.circularBoundaryViolationToastComponent) {
            this.circularBoundaryViolationToastComponent.violatedRadius = value;
        }
    }

    @Output() protected readonly violationChange = new EventEmitter<boolean>();

    protected circularBoundaryViolationToastId: number | undefined;
    protected circularBoundaryViolationToastComponent: CircularBoundaryViolationToastComponent | undefined;

    protected readonly isShown$ = this.localStore.selectByKey("isShown");
    protected readonly maxRadius$ = this.localStore.selectByKey("maxRadius");

    constructor(
        private readonly localStore: LocalComponentStore<CircularBoundaryViolationComponentState>,
        private readonly toastrService: ToastrService
    ) {
        this.localStore.setState({
            isShown: false,
            maxRadius: undefined,
        });
    }

    protected toggleCircularBoundaryViolationToast(isAreaValid: boolean): void {
        this.violationChange.emit(!isAreaValid);

        if (!isAreaValid) {
            const maxRadius = this.localStore.selectSnapshotByKey("maxRadius");
            const toast = this.toastrService.error(maxRadius?.toString());
            this.circularBoundaryViolationToastId = toast.toastId;
            this.circularBoundaryViolationToastComponent = toast.portal.instance;

            return;
        }

        this.toastrService.clear(this.circularBoundaryViolationToastId);
        this.circularBoundaryViolationToastId = undefined;
        this.circularBoundaryViolationToastComponent = undefined;
    }

    private initToastContainer(value: ToastContainerDirective | undefined) {
        if (!value || this.toastrService.overlayContainer === value) {
            return;
        }

        this.toastrService.overlayContainer = value;
        this.toastrService.toastrConfig = {
            ...DefaultGlobalConfig,
            autoDismiss: false,
            closeButton: false,
            tapToDismiss: false,
            disableTimeOut: true,
            maxOpened: 1,
            toastComponent: CircularBoundaryViolationToastComponent,
        };
    }
}
