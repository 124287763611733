import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, inject } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { ButtonTheme, ConfirmationDialogComponent } from "@dtm-frontend/shared/ui";
import { FormStateController, FormType, LocalComponentStore, ObjectUtils, RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import equal from "fast-deep-equal";
import { Observable, combineLatest, distinctUntilChanged, of, startWith, switchMap, takeUntil, tap } from "rxjs";
import { filter } from "rxjs/operators";

export const POLAND_AMSL_MAX_HEIGHT = 2623; // NOTE: 2623 m is the maximum height of the highest mountain in Poland (Rysy) with 120 m of buffer
export const POLAND_AMSL_MIN_HEIGHT = -2; // NOTE: -2 m is the minimum height above sea level in Poland (Marzęcino)

export interface MissionWizardItineraryEditorParametersPanelBaseComponentState<T extends object> {
    isExpanded: boolean;
    isDeferred: boolean;
    formControls: FormType<T> | undefined;
    originalFormControls: FormType<T> | undefined;
    formStateController: FormStateController | undefined;
    isDisabled: boolean;
}

@UntilDestroy()
@Component({ template: "", changeDetection: ChangeDetectionStrategy.OnPush })
export class MissionWizardItineraryEditorParametersPanelBaseComponent<
    T extends object,
    S extends MissionWizardItineraryEditorParametersPanelBaseComponentState<T>
> {
    private readonly matDialog = inject(MatDialog);
    private readonly translocoService = inject(TranslocoService);

    @Input() public set isExpanded(value: BooleanInput) {
        this.localStore.patchState((state) => ({ ...state, isExpanded: coerceBooleanProperty(value) }));
    }

    @Input() public set formControls(value: FormType<T> | undefined) {
        this.patchDeferredState({
            originalFormControls: value,
            formControls: value,
        });
    }

    @Input() public set isDeferred(value: BooleanInput) {
        this.patchDeferredState({ isDeferred: coerceBooleanProperty(value) });
    }

    @Input() public set isDisabled(value: BooleanInput) {
        this.localStore.patchState((state) => ({ ...state, isDisabled: coerceBooleanProperty(value) }));
    }

    @Output() public readonly deferredChangesSave = new EventEmitter<Partial<T>>();
    @Output() public readonly formChanged = new EventEmitter<Partial<T>>();
    @Output() public readonly isEditing = new EventEmitter<boolean>();

    protected readonly isExpanded$ = this.localStore.selectByKey("isExpanded");
    protected readonly isDisabled$ = this.localStore.selectByKey("isDisabled");
    protected readonly formControls$ = this.localStore.selectByKey("formControls");
    protected readonly formStateController$ = this.localStore.selectByKey("formStateController");

    constructor(protected readonly localStore: LocalComponentStore<S>) {
        combineLatest([
            this.formControls$.pipe(RxjsUtils.filterFalsy()),
            this.localStore.selectByKey("originalFormControls"),
            this.isDisabled$,
        ])
            .pipe(
                tap(([formControls, originalFormControls, isDisabled]) => {
                    [...Object.values(originalFormControls ?? {}), ...Object.values(formControls ?? {})].forEach((control) => {
                        const formControl = control as FormControl<T[keyof T]>;

                        if (isDisabled) {
                            formControl.disable();
                        } else {
                            formControl.enable();
                        }
                    });
                }),
                untilDestroyed(this)
            )
            .subscribe();

        this.watchOnControlsChange();
        this.watchOnFormEditing();
    }

    private patchDeferredState(newState: Partial<MissionWizardItineraryEditorParametersPanelBaseComponentState<T>>) {
        this.localStore.patchState((oldState) => {
            const combinedState = { ...oldState, ...newState };

            if (
                (oldState.isDeferred === newState.isDeferred && oldState.formControls === newState.formControls) ||
                !combinedState.formControls
            ) {
                return combinedState;
            }

            if (!newState.isDeferred && oldState.formStateController) {
                oldState.formStateController.restore();
                const savedValue = oldState.formStateController.savedValue;
                Object.entries(combinedState.formControls).forEach(([name, control]) => {
                    const formControl = control as FormControl;
                    formControl.setValue(savedValue[name]);
                });

                return Object.assign(combinedState, { formStateController: undefined, formControls: combinedState.originalFormControls });
            }

            if (newState.isDeferred && !oldState.formStateController) {
                const group = new FormGroup({});

                Object.entries(combinedState.formControls).forEach(([name, control]) => {
                    const formControl = control as FormControl;
                    const newControl = new FormControl(formControl.value, {
                        asyncValidators: formControl.asyncValidator,
                        validators: formControl.validator,
                        updateOn: formControl.updateOn,
                    });
                    formControl.statusChanges
                        .pipe(
                            startWith(formControl.disabled),
                            takeUntil(this.formStateController$.pipe(distinctUntilChanged())),
                            untilDestroyed(this)
                        )
                        .subscribe((status) => {
                            if (status === "DISABLED") {
                                newControl.disable();
                            } else {
                                newControl.enable();
                            }
                        });

                    group.addControl(name, newControl);
                });

                const formStateController = new FormStateController(group, { saveInitialValue: true });

                Object.assign(combinedState, {
                    formStateController,
                    formControls: group.controls,
                });
            }

            return combinedState;
        });
    }

    protected saveFormValues(formStateController: FormStateController, formControls: FormType<T>) {
        const dialogRef = this.matDialog.open(ConfirmationDialogComponent, {
            data: {
                titleText: this.translocoService.translate(
                    "dtmWebAppLibMission.itineraryEditorStep.missionParameters.saveDeferredChangesDialog.title"
                ),
                confirmationText: this.translocoService.translate(
                    "dtmWebAppLibMission.itineraryEditorStep.missionParameters.saveDeferredChangesDialog.text"
                ),
                declineButtonLabel: this.translocoService.translate(
                    "dtmWebAppLibMission.itineraryEditorStep.missionParameters.saveDeferredChangesDialog.cancelButtonLabel"
                ),
                confirmButtonLabel: this.translocoService.translate(
                    "dtmWebAppLibMission.itineraryEditorStep.missionParameters.saveDeferredChangesDialog.confirmButtonLabel"
                ),
                theme: ButtonTheme.Warn,
            },
        });

        dialogRef
            .afterClosed()
            .pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
            .subscribe(() => {
                const originalFormControls = this.localStore.selectSnapshotByKey("originalFormControls");

                if (!originalFormControls) {
                    return;
                }

                const oldValue = ObjectUtils.cloneDeep(formStateController.savedValue);
                formStateController.save();
                const newValue = formStateController.savedValue;
                const changes: Partial<T> = {};

                const controlKeys = Object.keys(formControls) as (keyof T)[];
                controlKeys.forEach((key) => {
                    if (equal(newValue[key], oldValue[key])) {
                        return;
                    }

                    changes[key] = newValue[key];
                    originalFormControls[key].setValue(newValue[key]);
                    originalFormControls[key].validator = formControls[key].validator;
                    originalFormControls[key].updateValueAndValidity();
                });

                this.deferredChangesSave.emit(changes);
            });
    }

    private watchOnControlsChange(): void {
        this.formControls$
            .pipe(
                switchMap((controls: FormType<T> | undefined) => {
                    if (!controls) {
                        return of();
                    }

                    const valueChanges = Object.fromEntries(
                        Object.entries(controls).map(([key, control]) => [
                            key,
                            (control as FormControl).valueChanges.pipe(startWith((control as FormControl).value), distinctUntilChanged()),
                        ])
                    );

                    return combineLatest(valueChanges);
                }),
                RxjsUtils.filterFalsy(),
                tap((changes) => {
                    this.formChanged.emit(changes as Partial<T>);
                }),
                untilDestroyed(this)
            )
            .subscribe();
    }

    private watchOnFormEditing(): void {
        this.formStateController$
            .pipe(
                filter((state) => !!state),
                switchMap((state) => state?.isEditing$ as Observable<boolean>),
                untilDestroyed(this)
            )
            .subscribe((value) => {
                this.isEditing.emit(value);
            });
    }
}
