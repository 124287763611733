<dtm-uav-lib-setup-property
    *ngrxLet="value$ as value"
    [label]="label$ | ngrxPush"
    [description]="description$ | ngrxPush"
    [isEmpty]="value === undefined"
>
    <span class="wrapper">
        <dtm-ui-icon [name]="value ? 'checkbox-circle' : 'close-circle'" />
        {{ "dtmSharedUav.setupProperties.booleanValue" | transloco : { value } }}
    </span>
</dtm-uav-lib-setup-property>
