import { TemplatePortal } from "@angular/cdk/portal";
import { DOCUMENT } from "@angular/common";
import { AfterViewInit, ChangeDetectionStrategy, Component, Inject, NgZone, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { ActivatedRoute, GuardsCheckEnd, Params } from "@angular/router";
import { MapLayer } from "@dtm-frontend/shared/map";
import {
    AZURE_MAPS_LAYER_OPTIONS,
    ManualCoordinatesInputDialogComponent,
    ManualCoordinatesInputEntityType,
    ManualWidthInputDialogComponent,
    MapActionType,
    MapActionWithPayload,
    MapActionsPanelMode,
    MapEntity,
    MapEntityType,
    MapLabelsUtils,
    SegmentLabelType,
} from "@dtm-frontend/shared/map/cesium";
import { WeatherActions } from "@dtm-frontend/shared/map/geo-weather";
import { GeoZonesActions, ZoneTimesSetting } from "@dtm-frontend/shared/map/geo-zones";
import {
    FormalJustification,
    MissionCategory,
    MissionPlanAnalysisIssue,
    MissionPlanAnalysisIssueStatus,
    MissionPlanOperationCategoryOption,
    MissionPlanSpecificPermitType,
    MissionType,
    SoraSettings,
    WATCHER_ID,
} from "@dtm-frontend/shared/mission";
import {
    ButtonTheme,
    ConfirmationDialogComponent,
    DialogService,
    FILES_UPLOAD_API_PROVIDER,
    GeoJSON,
    GlobalFeatures,
    GlobalOperatorPermissions,
    ImageHelperService,
    ItineraryEditorType,
    OperatorType,
} from "@dtm-frontend/shared/ui";
import { SYSTEM_TRANSLATION_SCOPE, TranslationHelperService } from "@dtm-frontend/shared/ui/i18n";
import { WizardActions, WizardState } from "@dtm-frontend/shared/ui/wizard";
import {
    AnimationUtils,
    DEFAULT_DEBOUNCE_TIME,
    GRAMS_IN_KILOGRAM,
    LocalComponentStore,
    Logger,
    ObjectUtils,
    RxjsUtils,
} from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { TranslocoLocaleService } from "@jsverse/transloco-locale";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { AcMapComponent, MapLayerProviderOptions, SceneMode } from "@pansa/ngx-cesium";
import turfBbox from "@turf/bbox";
import equal from "fast-deep-equal";
import { IndividualConfig, ToastrService } from "ngx-toastr";
import { EMPTY, Observable, combineLatest, defer, firstValueFrom, lastValueFrom, of, throttleTime } from "rxjs";
import {
    combineLatestWith,
    debounceTime,
    distinctUntilChanged,
    filter,
    finalize,
    first,
    map,
    pairwise,
    shareReplay,
    startWith,
    switchMap,
    tap,
} from "rxjs/operators";
import { EditPansaUtmLinkDialogComponent } from "../../../../pilot-profile/components/pilot-profile-container/edit-pansa-utm-link-dialog/edit-pansa-utm-link-dialog.component";
import { PansaUtmCredentials, PansaUtmLinkErrorType } from "../../../../pilot-profile/services/pilot-profile.models";
import { PilotProfileActions } from "../../../../pilot-profile/state/pilot-profile.actions";
import { PilotProfileState } from "../../../../pilot-profile/state/pilot-profile.state";
import { ConnectPansaUtmLinkDialogComponent } from "../../../../shared/components/connect-pansa-utm-link-dialog/connect-pansa-utm-link-dialog.component";
import { UserContextService } from "../../../../shared/operator-context/services/user-context.service";
import { OperatorContextState } from "../../../../shared/operator-context/state/operator-context.state";
import { MissionPlanVerificationType } from "../../../models/mission-plan-verification.model";
import {
    FlightSpeedType,
    HeightType,
    ItineraryEditorFormData,
    ItineraryEditorMissionParametersFormData,
    MissionDataFormCapabilitiesData,
    MissionDataFormData,
    MissionDataFormFlightPurposeData,
    MissionError,
    MissionErrorType,
    MissionFieldsError,
    MissionPlanItineraryConstraints,
    MissionPlanningPreferences,
    MissionRoutePreference,
    WaypointWithSection,
    ZoneHeightLimits,
} from "../../../models/mission.model";
import {
    AssistedItineraryDataEntity,
    AssistedItineraryEntity,
    CustomItineraryEntity,
    ItineraryEntity,
    ItineraryService,
    StandardItineraryEntity,
} from "../../../services/itinerary.service";
import {
    AssistedEntityId,
    convertMissionPlanItineraryConstraintsToAssistedEntityEditorConstraints,
    convertMissionPlanItineraryConstraintsToEntityEditorConstraints,
} from "../../../services/mission-api.converters";
import { MissionAPIService } from "../../../services/mission-api.service";
import { PlanFilesUploadApiService } from "../../../services/plan-files-upload-api.service";
import { MissionActions } from "../../../state/mission.actions";
import { MissionState } from "../../../state/mission.state";
import { AdditionalInformationSettings } from "../../mission-notes-and-description/personal-notes.component";
import { MissionBaseComponent, MissionBaseComponentState } from "../../mission.base.component";
import {
    MAX_FLIGHT_SPEED_FOR_BYSTANDERS_SHIELDED_STATEMENT,
    MAX_TAKE_OFF_MASS_FOR_BYSTANDERS_SHIELDED_STATEMENT,
} from "../steps/analysis-step/mission-plan-analysis-panel/sora-settings/risk-mitigation-m1-a/risk-mitigation-m1-a.component";
import { ItineraryPanelStopoverDialogComponent } from "../steps/itinerary-editor-step/itinerary-panel/components/stopover-dialog/stopover-dialog.component";
import { ItineraryPanelSettings } from "../steps/itinerary-editor-step/itinerary-panel/itinerary-panel.models";
import {
    ItineraryContent,
    ItineraryContentEntity,
    ItineraryContentMetadata,
    Polyline3DItineraryEntity,
} from "./../../../models/itinerary.model";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const Cesium: any; // TODO: DTM-966

export enum MissionWizardSteps {
    MissionData = "MissionData",
    ItineraryEditor = "ItineraryEditor",
    RouteSelector = "RouteSelector",
    Summary = "Summary",
    Analysis = "Analysis",
}

export const MISSION_WIZARD_ID = "mission-wizard";
const SKIP_CAN_DEACTIVATE = "skipCanDeactivate";

const missionAnalysisCodes = {
    soraFts: "sora.fts",
    failGrc: "fgrc",
};

interface MissionWizardContentComponentState extends MissionBaseComponentState {
    missionDataFormData: Partial<MissionDataFormData> | undefined;
    itineraryEditorFormData: ItineraryEditorFormData | undefined;
    areMissionParametersFormsValid: boolean;
    selectedEditorType: ItineraryEditorType | undefined;
    additionalInformation: AdditionalInformationSettings | undefined;
    isDataPanelFolded: boolean;
    itineraryPanelSettings: ItineraryPanelSettings;
    previewRouteId: string | undefined;
    bottomPanelTemplates: { [key in MissionWizardSteps]?: TemplatePortal<unknown> | undefined };
    selectedOtherMissionId: string | undefined;
    areDangerousMaterialsTransported: boolean | undefined;
    isCircularBoundaryViolated: boolean;
    isFlightAroundObstacle: boolean;
    isPilotUTMConnected: boolean;
    isMeasureToolActive: boolean;
    isMeasureToolEnabled: boolean;
    shouldHideNearbyMissionsOnMap: boolean;
    isPermitBoundaryViolated: boolean;
}

const THUMBNAIL_IMAGE_WIDTH = 500;
// eslint-disable-next-line no-magic-numbers
const THUMBNAIL_IMAGE_PROPORTION = 16 / 9;
const LABELS_SHOWING_DISTANCE_IN_METERS = 20000;
const TEMPLATE_HANDLED_ERRORS = [
    MissionErrorType.CannotGetMission,
    MissionErrorType.CannotGetCapabilities,
    MissionErrorType.CannotCreateMissionPlan,
];

enum Source {
    PlanList = "PlanList",
    PrepareForMission = "PrepareForMission",
}

const SourceUrl: { [key in Source]: string } = {
    [Source.PlanList]: "/plan/list",
    [Source.PrepareForMission]: "/tactical/prepare-for-mission",
};

// NOTE: Shortens class declaration line to prevent eslint brace-style error with Prettier formatting
type MissionWizardState = MissionWizardContentComponentState;

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-mission-wizard-content",
    templateUrl: "./mission-wizard-content.component.html",
    styleUrls: ["../../../styles/mission.common.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore, ItineraryService, { provide: FILES_UPLOAD_API_PROVIDER, useClass: PlanFilesUploadApiService }],
    animations: [AnimationUtils.foldAnimation()],
})
export class MissionWizardContentComponent extends MissionBaseComponent<MissionWizardState> implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild(AcMapComponent)
    private acMap: AcMapComponent | undefined;

    protected readonly MissionErrorType = MissionErrorType;
    protected readonly MapLayerProviderOptions = MapLayerProviderOptions;
    protected readonly Source = Source;
    protected readonly ItineraryEditorType = ItineraryEditorType;
    protected readonly SceneMode = SceneMode;
    protected readonly MapActionType = MapActionType;
    protected readonly Cesium = Cesium;
    protected readonly AZURE_MAPS_LAYER_OPTIONS = AZURE_MAPS_LAYER_OPTIONS;
    protected readonly TEMPLATE_HANDLED_ERRORS = TEMPLATE_HANDLED_ERRORS;
    protected readonly missionAnalysisCodes = missionAnalysisCodes;

    protected readonly wizardId = MISSION_WIZARD_ID;
    protected readonly MissionWizardSteps = MissionWizardSteps;
    protected readonly AssistedEntityId = AssistedEntityId;
    protected readonly MissionCategory = MissionCategory;
    protected readonly ZoneTimesSetting = ZoneTimesSetting;
    protected readonly SegmentLabelType = SegmentLabelType;
    protected readonly heightTypeOptions = Object.values(HeightType);

    protected readonly areAvailableRoutesLoading$ = this.store.select(MissionState.areAvailableRoutesLoading);
    protected readonly canManageUavs$ = this.store.select(OperatorContextState.isPermitted(GlobalOperatorPermissions.OperatorUavsManage));
    protected readonly error$ = this.store.select(MissionState.missionError);
    protected readonly missionCapabilities$ = this.store.select(MissionState.missionCapabilities);
    protected readonly missionPreferences$ = this.getMissionPreferences();
    protected readonly currentPlan$ = this.store.select(MissionState.currentPlan);
    protected readonly activeStepId$ = this.store.select(WizardState.activeStepId<MissionWizardSteps>(this.wizardId));
    protected readonly areCapabilitiesLoading$ = this.store.select(MissionState.areCapabilitiesLoading);
    protected readonly missionDataFormData$ = this.localStore.selectByKey("missionDataFormData");
    protected readonly itineraryEditorFormData$ = this.localStore.selectByKey("itineraryEditorFormData");
    protected readonly areMissionParametersFormsValid$ = this.localStore.selectByKey("areMissionParametersFormsValid");
    protected readonly itineraryPanelSettings$ = this.localStore.selectByKey("itineraryPanelSettings");
    protected readonly isFlightAroundObstacle$ = this.localStore.selectByKey("isFlightAroundObstacle");
    protected readonly selectedEditorType$ = combineLatest([
        this.localStore.selectByKey("selectedEditorType"),
        this.store.select(MissionState.suggestedEditor),
    ]).pipe(map(([localEditor, suggestedEditor]) => localEditor ?? suggestedEditor));
    protected readonly selectedMapActionsPanelMode$ = this.getSelectedMapActionsPanelModeObservable();
    protected readonly itineraryContent$ = this.itineraryService.itineraryContent$;
    protected readonly activeEntityStatus$ = this.itineraryService.activeEntityStatus$;
    protected readonly currentPlanItinerary$ = this.store.select(MissionState.currentPlanItinerary);
    protected readonly currentPlanRoute$ = combineLatest([this.store.select(MissionState.currentPlanRoute), this.itineraryContent$]).pipe(
        map(([currentPlanRoute, itineraryContent]) => {
            if (itineraryContent.length > 0 && currentPlanRoute) {
                return currentPlanRoute;
            }

            return undefined;
        })
    );
    protected readonly missionPlanInformation$ = this.store.select(MissionState.missionPlanInformation);
    protected readonly activePermitsList$ = this.store.select(MissionState.activePermitsList);
    protected readonly routeSuggestions$ = this.store.select(MissionState.routeSuggestions);
    protected readonly areRouteEntitiesVisible$ = combineLatest([this.route$, this.activeStepId$]).pipe(
        map(
            ([route, activeStepId]) =>
                route && (activeStepId === MissionWizardSteps.Summary || activeStepId === MissionWizardSteps.Analysis)
        )
    );
    protected readonly initialViewbox$ = this.getInitialViewboxObservable();
    protected readonly isSelectedEnterpriseOperator$ = this.store
        .select(OperatorContextState.selectedContextType)
        .pipe(map((type) => type === OperatorType.Enterprise));
    protected readonly includesUavObserver$ = this.missionDataFormData$.pipe(
        startWith(undefined),
        map((missionDataFormData) => {
            const crew = missionDataFormData?.capabilities?.additionalCrew ?? [];

            return crew.some((member) => member.type === WATCHER_ID);
        }),
        distinctUntilChanged(),
        shareReplay({ refCount: true, bufferSize: 1 })
    );
    protected readonly constraints$ = this.currentPlanItinerary$.pipe(
        map((itinerary) => itinerary?.constraints),
        shareReplay({ refCount: true, bufferSize: 1 })
    );

    private readonly isEditMode$ = this.activatedRoute.data.pipe(map((data) => !!data.isEditMode));
    protected readonly isEditingExisitingItinerary$ = this.isEditMode$.pipe(
        switchMap((isEditMode) =>
            !isEditMode
                ? of(false)
                : this.itineraryContent$.pipe(
                      map((itineraryContent) => itineraryContent.length === 0),
                      pairwise(),
                      first(([wasEmpty, isEmpty]) => !wasEmpty && isEmpty),
                      map(() => false),
                      startWith(true)
                  )
        )
    );

    protected readonly enabledSteps$ = this.store.select(WizardState.enabledSteps(this.wizardId));
    protected readonly areEditorsDisabled$ = this.itineraryService.areEditorsDisabled$;
    protected readonly isRouteInDtm$ = this.store
        .select(MissionState.currentPlanRoute)
        .pipe(map((route) => !!route?.stats?.flight.dtmNames.length));
    protected readonly sourceUrl$ = this.activatedRoute.queryParams.pipe(map(({ source }) => source));
    protected readonly activeMapAction$ = this.itineraryService.activeMapAction$;
    protected readonly additionalInformation$ = this.localStore.selectByKey("additionalInformation");
    protected readonly isDataPanelFolded$ = this.localStore.selectByKey("isDataPanelFolded");
    protected readonly isMapActionsBarVisible$ = this.activeStepId$.pipe(
        map((step) => [MissionWizardSteps.ItineraryEditor, MissionWizardSteps.RouteSelector].includes(step as MissionWizardSteps))
    );
    protected readonly previewRouteId$ = this.localStore.selectByKey("previewRouteId");
    protected readonly bottomPanelTemplate$ = combineLatest([this.localStore.selectByKey("bottomPanelTemplates"), this.activeStepId$]).pipe(
        map(([bottomPanelTemplates, activeStepId]) => {
            if (!bottomPanelTemplates || !activeStepId) {
                return undefined;
            }

            return bottomPanelTemplates[activeStepId];
        })
    );
    protected readonly circularBoundaryLayerMaxRadius$ = this.getCircularBoundaryLayerMaxRadiusObservable();
    protected readonly heightConstraints$ = this.getHeightConstraints();
    protected readonly isCircularBoundaryViolated$ = combineLatest([
        this.circularBoundaryLayerMaxRadius$,
        this.localStore.selectByKey("isCircularBoundaryViolated"),
    ]).pipe(map(([maxRadius, isCircularBoundaryViolated]) => !!maxRadius && isCircularBoundaryViolated));

    public footerTemplate = new Map<string, TemplatePortal>();

    private readonly contextSwitchTry$: Observable<boolean> = this.initContextSwitchTry();

    protected readonly areLabelsVisible$ = this.cameraHelperService.postRender$.pipe(
        map(() => this.acMap?.getCesiumService().getViewer().camera.positionCartographic.height <= LABELS_SHOWING_DISTANCE_IN_METERS),
        distinctUntilChanged(),
        shareReplay({ refCount: true, bufferSize: 1 })
    );
    protected readonly areDangerousMaterialsTransported$ = this.localStore.selectByKey("areDangerousMaterialsTransported");
    protected readonly selectedCaaPermit$ = this.activePermitsList$.pipe(
        combineLatestWith(this.localStore.selectByKey("missionDataFormData")),
        map(([permitList, missionFormData]) => {
            if (!missionFormData?.activePermits) {
                return;
            }

            return permitList?.filter((permit) => permit.id === missionFormData.activePermits?.id)[0];
        })
    );
    protected readonly isSpecificPermitUsageEnabled$ = this.store.select(
        OperatorContextState.isFeatureAvailable(GlobalFeatures.UseSpecificPermit)
    );
    protected readonly isPilotUTMConnected$ = this.localStore.selectByKey("isPilotUTMConnected");
    protected readonly isMeasureToolActive$ = this.localStore.selectByKey("isMeasureToolActive");
    protected readonly isMeasureToolEnabled$ = this.localStore.selectByKey("isMeasureToolEnabled").pipe(
        combineLatestWith(this.activeMapAction$),
        map(([isMeasureToolEnabled, activeMapAction]) => isMeasureToolEnabled && activeMapAction === MapActionType.None)
    );
    protected readonly shouldHideNearbyMissionsOnMap$ = this.localStore.selectByKey("shouldHideNearbyMissionsOnMap");
    protected readonly isPermitBoundaryViolated$ = this.localStore.selectByKey("isPermitBoundaryViolated");

    protected readonly permitLocationData$ = this.store.select(MissionState.permitLocationData);

    constructor(
        protected readonly localStore: LocalComponentStore<MissionWizardContentComponentState>,
        private readonly itineraryService: ItineraryService,
        private readonly toastService: ToastrService,
        private readonly transloco: TranslocoService,
        private readonly translocoLocaleService: TranslocoLocaleService,
        private readonly missionApiService: MissionAPIService,
        private readonly imageHelperService: ImageHelperService,
        private readonly activatedRoute: ActivatedRoute,
        private readonly translationHelper: TranslationHelperService,
        private readonly dialogService: DialogService,
        @Inject(DOCUMENT) private readonly document: Document,
        private readonly userContextService: UserContextService,
        private readonly zone: NgZone
    ) {
        super(localStore);

        this.localStore.setState({
            missionDataFormData: undefined,
            itineraryEditorFormData: undefined,
            areMissionParametersFormsValid: true,
            selectedEditorType: undefined,
            additionalInformation: undefined,
            isDataPanelFolded: false,
            itineraryPanelSettings: {
                flightSpeedType: FlightSpeedType.MetersPerSecond,
                heightType: HeightType.AGL,
            },
            previewRouteId: undefined,
            bottomPanelTemplates: {},
            selectedOtherMissionId: undefined,
            areDangerousMaterialsTransported: undefined,
            isCircularBoundaryViolated: false,
            isFlightAroundObstacle: false,
            isPilotUTMConnected: false,
            isMeasureToolActive: false,
            isMeasureToolEnabled: true,
            shouldHideNearbyMissionsOnMap: false,
            isPermitBoundaryViolated: false,
        });

        this.store.dispatch([
            new WizardActions.SetActiveStep(this.wizardId, MissionWizardSteps.MissionData),
            new WizardActions.EnableSteps(this.wizardId, [MissionWizardSteps.MissionData]),
        ]);

        this.enableEditableSteps();
        this.updatePlanAnalysis();
        this.cleanupOnRouteNavigation();
        this.setAdditionalInformationFromMissionPlan();
        this.startItineraryContinuousUpdate();
        this.watchLabelsOverlapping();
        this.watchOperatorContextChange();
        this.removeRiskMitigationM1BForNonVlosTypeChange();
        this.removeMitigationBystandersShieldedUserStatementWithUabSetupChange();
        this.removeRiskMitigationsForNonSpecificTypeChange();
        this.watchPlanNameChanges();

        this.selectedEditorType$
            .pipe(untilDestroyed(this))
            .subscribe((type) => this.store.dispatch(new MissionActions.SetSelectedEditorType(type)));

        this.activeStepId$.pipe(untilDestroyed(this)).subscribe((stepId) => {
            const areZonesVisible = [MissionWizardSteps.Analysis, MissionWizardSteps.Summary].includes(stepId as MissionWizardSteps);
            this.store.dispatch([new GeoZonesActions.SetCustomZonesVisibility(areZonesVisible), GeoZonesActions.SetSelectedZoneId]);

            if (stepId === MissionWizardSteps.Summary) {
                this.flyToMainMissionRoute();
            }
        });

        this.ensurePansaUtmConnection();

        this.missionDataFormData$
            .pipe(
                RxjsUtils.filterFalsy(),
                distinctUntilChanged(
                    (formDataLeft, formDataRight) => formDataLeft?.capabilities?.pilot.id === formDataRight?.capabilities?.pilot.id
                ),
                filter(() => this.store.selectSnapshot(OperatorContextState.selectedContext)?.type === OperatorType.Enterprise),
                untilDestroyed(this)
            )
            .subscribe(() => this.ensurePansaUtmConnection());
    }

    private static isSoraApplicationPermitType(missionFormCategory: MissionPlanOperationCategoryOption | undefined): boolean {
        return (
            !!missionFormCategory &&
            missionFormCategory.type === MissionCategory.Specific &&
            missionFormCategory.specificPermitType === MissionPlanSpecificPermitType.Individual &&
            !missionFormCategory.specificCaaPermitId
        );
    }

    protected changeCircularBoundaryViolation(isCircularBoundaryViolated: boolean): void {
        this.localStore.patchState({ isCircularBoundaryViolated });
    }

    private async ensurePansaUtmConnection(): Promise<boolean> {
        await firstValueFrom(this.refreshPansaUtmLinkStatus());

        const isPilotUTMConnected = this.store.selectSnapshot(MissionState.isPansaUtmLinkActive);
        const selectedContext = this.store.selectSnapshot(OperatorContextState.selectedContext);
        const pilotProfileError = this.store.selectSnapshot(PilotProfileState.pilotProfileError);

        if (pilotProfileError) {
            this.toastService.error(this.transloco.translate("dtmWebAppLibMission.cannotGetPilotIntegrationInfoErrorMessage"));

            return false;
        }

        this.localStore.patchState({ isPilotUTMConnected });

        if (isPilotUTMConnected || selectedContext?.type !== OperatorType.Personal) {
            return true;
        }

        const connectDialogResult = await firstValueFrom(this.dialogService.open(ConnectPansaUtmLinkDialogComponent).afterClosed());

        if (!connectDialogResult) {
            return false;
        }

        const isProcessing$ = this.store.select(PilotProfileState.isPilotProfileProcessing);
        const error$ = this.store.select(PilotProfileState.pansaUtmLinkError);

        const dialogRef: MatDialogRef<EditPansaUtmLinkDialogComponent> = this.dialogService.open(EditPansaUtmLinkDialogComponent, {
            data: { isProcessing$, error$ },
        });

        const pilotId = this.store.selectSnapshot(OperatorContextState.pilot)?.id;

        return firstValueFrom(
            dialogRef.componentInstance.newValue$.pipe(
                switchMap((credentials: PansaUtmCredentials) =>
                    this.store.dispatch(new PilotProfileActions.AddPansaUtmLink(credentials, undefined, pilotId))
                ),
                map(() => {
                    const error = this.store.selectSnapshot(PilotProfileState.pansaUtmLinkError);
                    if (!error) {
                        dialogRef.close();
                        this.toastService.success(this.transloco.translate("dtmWebAppLibPilotProfile.editPansaUtmLink.successMessage"));
                        this.refreshPansaUtmLinkStatus();

                        return true;
                    } else if (error.type === PansaUtmLinkErrorType.Unknown) {
                        this.toastService.error(this.transloco.translate("dtmWebAppLibPilotProfile.editPansaUtmLink.unknownErrorMessage"));
                    }

                    return false;
                }),
                untilDestroyed(this)
            )
        );
    }

    private refreshPansaUtmLinkStatus(): Observable<void> {
        const selectedContext = this.store.selectSnapshot(OperatorContextState.selectedContext);
        const pilotId =
            selectedContext?.type === OperatorType.Enterprise
                ? this.localStore.selectSnapshotByKey("missionDataFormData")?.capabilities?.pilot.id
                : this.store.selectSnapshot(OperatorContextState.pilot)?.id;

        if (pilotId) {
            return this.store.dispatch([new MissionActions.GetPansaUtmLinkStatus(pilotId)]);
        }

        return of(undefined);
    }

    private removeRiskMitigationM1BForNonVlosTypeChange() {
        this.missionDataFormData$
            .pipe(
                distinctUntilChanged((previous, current) => previous?.capabilities?.missionType === current?.capabilities?.missionType),
                untilDestroyed(this)
            )
            .subscribe((missionDataFormData) => {
                const soraSettings = this.localStore.selectSnapshotByKey("itineraryEditorFormData")?.soraSettings;

                if (missionDataFormData?.capabilities?.missionType !== MissionType.VLOS && soraSettings?.riskMitigations?.m1B) {
                    this.localStore.patchState(({ itineraryEditorFormData }) => ({
                        itineraryEditorFormData: itineraryEditorFormData && {
                            ...itineraryEditorFormData,
                            soraSettings: {
                                ...soraSettings,
                                riskMitigations: {
                                    ...soraSettings.riskMitigations,
                                    m1B: undefined,
                                },
                            },
                        },
                    }));
                }
            });
    }

    private removeMitigationBystandersShieldedUserStatementWithUabSetupChange() {
        this.missionDataFormData$
            .pipe(
                distinctUntilChanged((previous, current) => previous?.capabilities?.uavWithSetup === current?.capabilities?.uavWithSetup),
                untilDestroyed(this)
            )
            .subscribe((missionDataFormData) => {
                const technicalSpecification = missionDataFormData?.capabilities?.uavWithSetup.setup.technicalSpecification;
                const soraSettings = this.localStore.selectSnapshotByKey("itineraryEditorFormData")?.soraSettings;
                const m1A = soraSettings?.riskMitigations?.m1A;

                if (
                    !technicalSpecification ||
                    !soraSettings ||
                    !m1A?.bystandersShielded ||
                    (technicalSpecification.takeOffMass < MAX_TAKE_OFF_MASS_FOR_BYSTANDERS_SHIELDED_STATEMENT &&
                        technicalSpecification.maxFlightSpeed < MAX_FLIGHT_SPEED_FOR_BYSTANDERS_SHIELDED_STATEMENT)
                ) {
                    return;
                }

                this.localStore.patchState(({ itineraryEditorFormData }) => ({
                    itineraryEditorFormData: itineraryEditorFormData && {
                        ...itineraryEditorFormData,
                        soraSettings: {
                            ...soraSettings,
                            riskMitigations: {
                                ...soraSettings.riskMitigations,
                                m1A: m1A.lowerPeopleDensity
                                    ? {
                                          ...m1A,
                                          bystandersShielded: undefined,
                                      }
                                    : undefined,
                            },
                        },
                    },
                }));
            });
    }

    private removeRiskMitigationsForNonSpecificTypeChange() {
        this.missionDataFormData$
            .pipe(
                distinctUntilChanged((previous, current) => previous?.category === current?.category),
                untilDestroyed(this)
            )
            .subscribe((missionDataFormData) => {
                const soraSettings = this.localStore.selectSnapshotByKey("itineraryEditorFormData")?.soraSettings;

                if (missionDataFormData?.category?.type !== MissionCategory.Specific && soraSettings) {
                    this.localStore.patchState(({ itineraryEditorFormData }) => ({
                        itineraryEditorFormData: itineraryEditorFormData && {
                            ...itineraryEditorFormData,
                            soraSettings: {
                                ...soraSettings,
                                riskMitigations: undefined,
                            },
                        },
                    }));
                }
            });
    }

    public ngOnDestroy(): void {
        this.userContextService.unregisterContextSwitchTry(this.contextSwitchTry$);
        this.store.dispatch(new WeatherActions.ResetWeatherState());
    }

    private watchLabelsOverlapping() {
        this.activeStepId$
            .pipe(
                map((activeStepId) => activeStepId === MissionWizardSteps.ItineraryEditor),
                switchMap((isActive) => {
                    if (!isActive) {
                        return of(undefined);
                    }

                    return this.cameraHelperService.postRender$.pipe(
                        throttleTime(DEFAULT_DEBOUNCE_TIME, undefined, { leading: true, trailing: true }),
                        tap(() => this.zone.runOutsideAngular(() => this.removeOverlapping()))
                    );
                }),
                untilDestroyed(this)
            )
            .subscribe();
    }

    protected hasAnalysisStatusError(issues: MissionPlanAnalysisIssue[] | undefined, codeName: string): boolean {
        if (!issues) {
            return false;
        }

        return issues.some(
            (analysisStatus) => analysisStatus.codename === codeName && analysisStatus.status === MissionPlanAnalysisIssueStatus.Error
        );
    }

    protected async updateMissionParameters(missionParameters: Partial<ItineraryEditorMissionParametersFormData>) {
        const itineraryContent = await firstValueFrom(this.itineraryContent$.pipe(untilDestroyed(this)));

        this.updateMissionPlanItinerary(itineraryContent, itineraryContent, missionParameters)
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(MissionState.missionError);

                if (error) {
                    this.displayErrorMessage(error);
                }
            });
    }

    protected updateHeightPanelParameters({ isFlightAroundObstacle }: Partial<ItineraryEditorMissionParametersFormData>) {
        this.localStore.patchState({ isFlightAroundObstacle: !!isFlightAroundObstacle });
    }

    private updateMissionPlanItinerary(
        itineraryContent: ItineraryContent,
        previousItineraryContent: ItineraryContent,
        missionParameterChanges: Partial<ItineraryEditorMissionParametersFormData> = {},
        itineraryData?: ItineraryEditorFormData
    ): Observable<void> {
        itineraryData = itineraryData ?? this.localStore.selectSnapshotByKey("itineraryEditorFormData");
        const currentPlanItinerary = this.store.selectSnapshot(MissionState.currentPlanItinerary);

        const constraints$ = this.constraints$.pipe(
            map((constraints) => {
                if (!constraints) {
                    return undefined;
                }

                return itineraryData?.editorType === ItineraryEditorType.Assisted
                    ? convertMissionPlanItineraryConstraintsToAssistedEntityEditorConstraints(constraints)
                    : convertMissionPlanItineraryConstraintsToEntityEditorConstraints(constraints, itineraryData);
            })
        );

        if (!itineraryData || !currentPlanItinerary) {
            return of(undefined);
        }

        const planId = this.getCurrentPlanId();

        if (!planId || itineraryContent.length === 0) {
            return of(undefined);
        }

        if (itineraryData.editorType === ItineraryEditorType.Assisted && itineraryContent.length !== 2) {
            return of(undefined);
        }

        return constraints$.pipe(
            first(),
            switchMap((constraints) =>
                !constraints || !itineraryData
                    ? of(undefined)
                    : this.itineraryService.updateMissionPlanItinerary(
                          previousItineraryContent,
                          itineraryContent,
                          planId,
                          {
                              ...itineraryData,
                              ...missionParameterChanges,
                          },
                          constraints.default.runwayVerticalNavigationAccuracy ?? 1,
                          missionParameterChanges,
                          constraints
                      )
            )
        );
    }

    private getNextStep(editorType?: ItineraryEditorType) {
        return editorType === ItineraryEditorType.Assisted ? MissionWizardSteps.RouteSelector : MissionWizardSteps.Analysis;
    }

    private startItineraryContinuousUpdate() {
        let previousItineraryContent: ItineraryContent = [];

        this.itineraryContent$
            .pipe(
                combineLatestWith(this.itineraryEditorFormData$),
                combineLatestWith(this.includesUavObserver$),
                tap(() => this.store.dispatch(new MissionActions.SetIsProcessing(true))),
                debounceTime(DEFAULT_DEBOUNCE_TIME),
                distinctUntilChanged(
                    (
                        [[previousItinerary, previousFormData], previousIncludesUavObserver],
                        [[currentItinerary, currentFormData], currentIncludesUavObserver]
                    ) => {
                        if (
                            equal(previousItinerary, currentItinerary) &&
                            equal(previousFormData, currentFormData) &&
                            previousIncludesUavObserver === currentIncludesUavObserver
                        ) {
                            this.store.dispatch(new MissionActions.SetIsProcessing(false));

                            return true;
                        }

                        return false;
                    }
                ),
                switchMap(([[itineraryContent, itineraryEditorFormData]]) => {
                    const result = this.updateMissionPlanItinerary(itineraryContent, previousItineraryContent, {}, itineraryEditorFormData);
                    previousItineraryContent = itineraryContent;

                    return result.pipe(
                        finalize(() => {
                            const error = this.store.selectSnapshot(MissionState.missionError);

                            if (error) {
                                this.displayErrorMessage(error);
                            }
                        })
                    );
                }),
                tap(() => this.store.dispatch(new MissionActions.SetIsProcessing(false))),
                untilDestroyed(this)
            )
            .subscribe();
    }

    public async ngAfterViewInit() {
        await this.createMapEntityFromData();
        this.itineraryService.disableEditors();
    }

    public async createMapEntityFromData(): Promise<void> {
        await this.cameraHelperService.mapEntitiesReady$;

        const constraints = ObjectUtils.cloneDeep(await firstValueFrom(this.constraints$));
        const editorType =
            this.localStore.selectSnapshotByKey("selectedEditorType") ?? this.store.selectSnapshot(MissionState.suggestedEditor);
        const itinerary = this.store.selectSnapshot(MissionState.currentPlanItinerary);

        if (!constraints || !editorType || !itinerary) {
            return;
        }

        if (editorType !== itinerary.type) {
            this.localStore.patchState({ selectedEditorType: itinerary.type });
        }

        this.cameraHelperService.mapEntitiesReady$.pipe(RxjsUtils.filterFalsy(), first(), untilDestroyed(this)).subscribe(async () => {
            this.itineraryService.createEditorsFromItinerary(itinerary, constraints);
            // NOTE: some editor updates are not available in this cycle, so we will need to wait for next cycle
            await this.waitForNextCycle();
            this.showEntireItineraryContent();
        });
    }

    private getMissionPreferences() {
        return combineLatest([this.store.select(MissionState.missionPreferences), this.activatedRoute.queryParams]).pipe(
            map(([preferences, params]: [MissionPlanningPreferences | undefined, Params]) => {
                if (preferences && params.preferredPilotId) {
                    const capabilities = preferences.capabilities ?? {};

                    return {
                        ...preferences,
                        capabilities: {
                            ...capabilities,
                            pilotId: params.preferredPilotId ?? capabilities.pilotId,
                        },
                    };
                } else if (params.preferredPilotId) {
                    return {
                        capabilities: { pilotId: params.preferredPilotId },
                    };
                }

                return preferences;
            })
        );
    }

    private cleanupOnRouteNavigation() {
        this.router.events
            .pipe(
                filter((event): event is GuardsCheckEnd => event instanceof GuardsCheckEnd),
                untilDestroyed(this)
            )
            .subscribe((event) => {
                if (event.url === this.router.url || event.shouldActivate === false) {
                    return;
                }

                this.store.dispatch([
                    new WizardActions.CleanupWizard(this.wizardId),
                    new MissionActions.StopMissionPlanAnalysisStatusWatch(),
                    new MissionActions.CleanupMissionWizard(),
                ]);
                this.itineraryService.clearItinerary();
            });
    }

    public ngOnInit() {
        this.store.dispatch([
            new MissionActions.StartMissionPlanAnalysisStatusWatch(),
            new WizardActions.UpdateDirtyStep(this.wizardId, undefined),
        ]);

        if (this.activatedRoute.snapshot.data.isDetailsMode) {
            this.store.dispatch(new WizardActions.SetActiveStep(this.wizardId, MissionWizardSteps.Summary));
        } else {
            this.goToMissionDataStep();
        }
    }

    private enableEditableSteps() {
        const currentPlan = this.store.selectSnapshot(MissionState.currentPlan);
        const route = this.store.selectSnapshot(MissionState.currentPlanRoute);

        if (currentPlan?.capabilities && currentPlan?.flightPurpose && currentPlan?.operationCategory) {
            this.store.dispatch(new WizardActions.EnableSteps(this.wizardId, [MissionWizardSteps.ItineraryEditor]));
        } else {
            return;
        }
        if (route) {
            this.store.dispatch(new WizardActions.EnableSteps(this.wizardId, [MissionWizardSteps.Summary]));
        }
    }

    private updatePlanAnalysis() {
        const route = this.store.selectSnapshot(MissionState.currentPlanRoute);
        if (route) {
            this.store
                .dispatch(MissionActions.GetMissionPlanAnalysis)
                .pipe(
                    tap(() => {
                        const error = this.store.selectSnapshot(MissionState.missionError);

                        if (!error) {
                            return;
                        }

                        this.displayErrorMessage(error);
                    }),
                    untilDestroyed(this)
                )
                .subscribe();
        }
    }

    public goToItineraryEditorStep() {
        const planId = this.getCurrentPlanId();

        if (!planId) {
            return;
        }

        this.store.dispatch([
            new WizardActions.EnableSteps(this.wizardId, [MissionWizardSteps.MissionData, MissionWizardSteps.ItineraryEditor]),
            new WizardActions.SetActiveStep(this.wizardId, MissionWizardSteps.ItineraryEditor),
            GeoZonesActions.EnableAllGeoZones,
        ]);
    }

    public goToSummaryStep() {
        const planId = this.getCurrentPlanId();
        if (!planId) {
            return;
        }

        this.store.dispatch(new MissionActions.GetPlanData(planId)).subscribe(() => {
            this.store.dispatch(new WizardActions.SetActiveStep(this.wizardId, MissionWizardSteps.Summary));
        });
    }

    public gotToRouteSelectorStep() {
        this.store.dispatch(new WizardActions.SetActiveStep(this.wizardId, MissionWizardSteps.RouteSelector));
    }

    public goToAnalysisStep() {
        this.store.dispatch(new WizardActions.SetActiveStep(this.wizardId, MissionWizardSteps.Analysis));
    }

    public backToItineraryEditorStep() {
        this.store.dispatch(new WizardActions.SetActiveStep(this.wizardId, MissionWizardSteps.ItineraryEditor));
    }

    public goToMissionDataStep() {
        this.store.dispatch(new WizardActions.SetActiveStep(this.wizardId, MissionWizardSteps.MissionData));
    }

    public async handleCreateOrUpdateCapabilities(missionDataCapabilitiesData: MissionDataFormCapabilitiesData) {
        this.localStore.patchState((state) => ({
            missionDataFormData: {
                ...state.missionDataFormData,
                capabilities: missionDataCapabilitiesData,
            },
        }));

        const planId = this.getCurrentPlanId() ?? null;

        this.store
            .dispatch(
                new MissionActions.CreateOrUpdateMissionCapabilities(
                    planId,
                    missionDataCapabilitiesData.missionType,
                    missionDataCapabilitiesData.operator.id,
                    missionDataCapabilitiesData.pilot.id,
                    missionDataCapabilitiesData.uavWithSetup.setup.id,
                    missionDataCapabilitiesData.additionalCrew
                )
            )
            .pipe(
                tap(() => {
                    const error = this.store.selectSnapshot(MissionState.missionError);

                    if (!error) {
                        return;
                    }

                    this.displayErrorMessage(error);
                }),
                untilDestroyed(this)
            )
            .subscribe();
    }

    protected getMaxEditorEntities(
        selectedEditorType: ItineraryEditorType | undefined,
        constraints: MissionPlanItineraryConstraints | undefined,
        missionDataFormData: Partial<MissionDataFormData> | undefined
    ): number | undefined {
        if (!selectedEditorType || !constraints || !missionDataFormData?.category) {
            return;
        }

        const isSoraApplicationPermitType = MissionWizardContentComponent.isSoraApplicationPermitType(missionDataFormData.category);

        return selectedEditorType === ItineraryEditorType.Custom || isSoraApplicationPermitType ? 1 : constraints.max.distinctShapes;
    }

    public updateMissionFormData(missionDataFormData: MissionDataFormData) {
        this.localStore.patchState({
            missionDataFormData,
        });
    }

    public async updateItineraryFormData(itineraryEditorFormData: ItineraryEditorFormData) {
        const itineraryContent = await lastValueFrom(this.itineraryContent$.pipe(first(), untilDestroyed(this)));

        if (!itineraryContent) {
            return;
        }

        this.localStore.patchState(({ itineraryEditorFormData: originalData }) => ({
            itineraryEditorFormData: {
                ...itineraryEditorFormData,
                soraSettings: { riskMitigations: originalData?.soraSettings?.riskMitigations, ...itineraryEditorFormData.soraSettings },
            },
        }));
    }

    protected updateMissionParametersFormsValidationStatus(isValid: boolean) {
        this.localStore.patchState({ areMissionParametersFormsValid: isValid });
    }

    public handleUpdateFlightPurpose(missionDataFlightPurposeData: MissionDataFormFlightPurposeData) {
        this.localStore.patchState((state) => ({
            missionDataFormData: {
                ...state.missionDataFormData,
                flightPurpose: missionDataFlightPurposeData,
            },
        }));

        const planId = this.getCurrentPlanId();

        if (!planId || !missionDataFlightPurposeData.flightPurpose) {
            return;
        }

        this.store
            .dispatch(
                new MissionActions.UpdateMissionPlanFlightPurpose(
                    planId,
                    missionDataFlightPurposeData.flightPurpose.id,
                    missionDataFlightPurposeData.flightPurposeDescription ?? undefined,
                    missionDataFlightPurposeData.loadWeight !== null
                        ? missionDataFlightPurposeData.loadWeight / GRAMS_IN_KILOGRAM
                        : undefined
                )
            )
            .pipe(
                tap(() => {
                    const error = this.store.selectSnapshot(MissionState.missionError);

                    if (!error) {
                        return;
                    }

                    this.displayErrorMessage(error);
                }),
                untilDestroyed(this)
            )
            .subscribe();
    }

    public handleUpdateOperationCategory(operationCategory: MissionPlanOperationCategoryOption) {
        this.localStore.patchState((state) => ({
            missionDataFormData: {
                ...state.missionDataFormData,
                category: operationCategory,
            },
        }));

        const planId = this.getCurrentPlanId();

        if (!planId) {
            return;
        }

        this.store
            .dispatch(new MissionActions.UpdateMissionPlanOperationCategory(planId, operationCategory))
            .pipe(
                switchMap(() => this.store.dispatch(new MissionActions.GetMissionPlanItinerary(planId))),
                tap(() => {
                    const error = this.store.selectSnapshot(MissionState.missionError);

                    if (!error) {
                        this.goToItineraryEditorStep();
                        this.store.dispatch(
                            new WizardActions.DisableSteps(this.wizardId, [
                                MissionWizardSteps.Analysis,
                                MissionWizardSteps.RouteSelector,
                                MissionWizardSteps.Summary,
                            ])
                        );

                        return;
                    }

                    this.displayErrorMessage(error);
                }),
                untilDestroyed(this)
            )
            .subscribe();
    }

    public async updateAdditionalInformation(additionalInformation: AdditionalInformationSettings) {
        const planId = this.getCurrentPlanId();
        if (!planId) {
            return;
        }

        this.localStore.patchState({ additionalInformation });

        const { notes, name, description } = additionalInformation;
        const payload = {
            notes: notes?.trim(),
            name: name?.trim(),
            description: description?.trim(),
        };

        await lastValueFrom(
            this.store.dispatch(new MissionActions.UpdateAdditionalInformation(planId, payload)).pipe(
                tap(() => {
                    const error = this.store.selectSnapshot(MissionState.missionError);

                    if (!error) {
                        return;
                    }

                    this.displayErrorMessage(error);
                }),
                untilDestroyed(this)
            )
        );
    }

    public updateOrCreateFormalJustification(justification: FormalJustification | undefined) {
        const planId = this.getCurrentPlanId();
        if (!planId) {
            return;
        }
        this.store
            .dispatch(new MissionActions.CreateOrUpdateFormalJustifications(justification, planId))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(MissionState.missionError);

                if (error) {
                    this.displayErrorMessage(error);
                }
            });
    }

    protected shouldDisplayTimeRangeLabel(missionFormCategory: MissionPlanOperationCategoryOption | undefined): boolean {
        return MissionWizardContentComponent.isSoraApplicationPermitType(missionFormCategory);
    }

    protected getMapLayersForMissionType(missionType: MissionType | undefined): Record<MapLayer, boolean> {
        if (missionType === MissionType.BVLOS) {
            return {
                [MapLayer.Default]: false,
                [MapLayer.SoraBoxes]: true,
                [MapLayer.Obstacles]: false,
            };
        }

        return {
            [MapLayer.Default]: true,
            [MapLayer.SoraBoxes]: false,
            [MapLayer.Obstacles]: false,
        };
    }

    protected async handleSoraSettingsUpdates(settings: SoraSettings) {
        const itineraryContent = await firstValueFrom(this.itineraryContent$.pipe(untilDestroyed(this)));

        if (!itineraryContent) {
            return;
        }

        const planId = this.getCurrentPlanId();
        const itineraryEditorFormData = this.localStore.selectSnapshotByKey("itineraryEditorFormData");

        if (!planId || !itineraryEditorFormData) {
            return;
        }

        const itineraryEditorFormDataWithSoraSettings: ItineraryEditorFormData = {
            ...itineraryEditorFormData,
            soraSettings: {
                ...itineraryEditorFormData.soraSettings,
                riskMitigations: settings.riskMitigations ?? itineraryEditorFormData.soraSettings?.riskMitigations,
                airRiskMitigations:
                    !settings.airRiskMitigations && settings.tmpr
                        ? itineraryEditorFormData.soraSettings?.airRiskMitigations
                        : settings.airRiskMitigations,
                tmpr: settings.tmpr ?? itineraryEditorFormData.soraSettings?.tmpr,
            },
        };

        this.localStore.patchState({
            itineraryEditorFormData: itineraryEditorFormDataWithSoraSettings,
        });

        this.updateMissionPlanItinerary(itineraryContent, itineraryContent, {}, itineraryEditorFormDataWithSoraSettings)
            .pipe(
                switchMap(() => this.store.dispatch(new MissionActions.StartMissionPlanAnalysis(planId))),
                untilDestroyed(this)
            )
            .subscribe(() => {
                const error = this.store.selectSnapshot(MissionState.missionError);

                if (error) {
                    this.displayErrorMessage(error);
                    this.store.dispatch([
                        new WizardActions.DisableSteps(this.wizardId, [MissionWizardSteps.Analysis]),
                        new WizardActions.SetActiveStep(this.wizardId, MissionWizardSteps.ItineraryEditor),
                    ]);
                }
            });
    }

    public async handleItineraryCompleted(itineraryData: ItineraryEditorFormData) {
        const itineraryContent = await lastValueFrom(this.itineraryContent$.pipe(first(), untilDestroyed(this)));

        if (!itineraryContent) {
            return;
        }
        this.itineraryService.disableEditors();

        this.showEntireItineraryContent();

        const planId = this.getCurrentPlanId();

        if (!planId) {
            return;
        }

        await this.createPlanNameIfNotExistOrNotChanged(itineraryData);

        this.updateMissionPlanItinerary(itineraryContent, itineraryContent)
            .pipe(
                switchMap(() => {
                    const error = this.store.selectSnapshot(MissionState.missionError);

                    if (error) {
                        return EMPTY;
                    }

                    if (itineraryData?.editorType !== ItineraryEditorType.Assisted) {
                        return this.startAnalysis(itineraryData);
                    }

                    return this.store.dispatch(new MissionActions.CreateAvailableMissionRoutes(planId, MissionRoutePreference.Length));
                }),
                finalize(() => {
                    const error = this.store.selectSnapshot(MissionState.missionError);
                    const nextStep = this.getNextStep(itineraryData?.editorType);

                    if (error) {
                        this.displayErrorMessage(error);
                        this.store.dispatch(new WizardActions.DisableSteps(this.wizardId, [nextStep]));
                        this.itineraryService.enableEditors();

                        return;
                    }

                    this.store.dispatch([
                        new WizardActions.EnableSteps(this.wizardId, [
                            nextStep,
                            MissionWizardSteps.MissionData,
                            MissionWizardSteps.ItineraryEditor,
                        ]),
                        new WizardActions.SetActiveStep(this.wizardId, nextStep),
                    ]);

                    if (nextStep === MissionWizardSteps.Analysis) {
                        this.saveMissionThumbnail();
                    }

                    this.itineraryService.enableEditors();
                })
            )
            .subscribe();
    }

    public startAnalysis(itineraryData: ItineraryEditorFormData) {
        this.showEntireItineraryContent();

        const planId = this.getCurrentPlanId();

        if (!planId) {
            return EMPTY;
        }

        const action =
            itineraryData.editorType === ItineraryEditorType.Assisted
                ? MissionActions.GetMissionPlanAnalysis
                : new MissionActions.StartMissionPlanAnalysis(planId);

        return this.store.dispatch(action);
    }

    protected selectRoute(routeId: string) {
        this.store.dispatch(new MissionActions.SetRoute(routeId));
    }

    protected previewRoute(hoveredRouteId: string) {
        this.localStore.patchState({ previewRouteId: hoveredRouteId });
    }

    protected refreshRoutes(preferences: MissionRoutePreference) {
        const planId = this.getCurrentPlanId();

        if (!planId) {
            return;
        }

        this.store.dispatch(new MissionActions.GetAvailableMissionRoutes(planId, preferences));
    }

    protected saveSelectedRouteAndGoToNextStep() {
        const itineraryFormData = this.localStore.selectSnapshotByKey("itineraryEditorFormData");
        const planId = this.getCurrentPlanId();
        if (!itineraryFormData || !planId) {
            return;
        }

        this.store
            .dispatch(MissionActions.AssignSelectedRoute)
            .pipe(
                switchMap(() => {
                    const error = this.store.selectSnapshot(MissionState.missionError);
                    if (error) {
                        return EMPTY;
                    }

                    return this.startAnalysis(itineraryFormData);
                }),
                untilDestroyed(this),
                finalize(() => {
                    const error = this.store.selectSnapshot(MissionState.missionError);

                    if (error) {
                        this.displayErrorMessage(error);

                        return;
                    }

                    this.store.dispatch([
                        new WizardActions.EnableSteps(this.wizardId, [
                            MissionWizardSteps.MissionData,
                            MissionWizardSteps.ItineraryEditor,
                            MissionWizardSteps.Analysis,
                        ]),
                        new WizardActions.SetActiveStep(this.wizardId, MissionWizardSteps.Analysis),
                    ]);
                })
            )
            .subscribe(() => this.saveMissionThumbnail());
    }

    public clearItineraryContent() {
        this.store.dispatch(new WeatherActions.ResetWeatherState());
        this.itineraryService.clearItinerary();

        this.constraints$.pipe(first(), untilDestroyed(this)).subscribe((constraints) => {
            const suggestedEditor =
                this.localStore.selectSnapshotByKey("selectedEditorType") ?? this.store.selectSnapshot(MissionState.suggestedEditor);

            if (!constraints || !suggestedEditor) {
                return;
            }

            this.itineraryService.startOrEnableEditor(suggestedEditor, constraints);
        });
    }

    public async handleActiveStepChanged(activeStep: string | undefined) {
        const planId = this.getCurrentPlanId();

        if (activeStep === MissionWizardSteps.Analysis && planId) {
            await lastValueFrom(this.store.dispatch(new MissionActions.GetCaaPermitData(planId)));
        }

        if (activeStep !== MissionWizardSteps.ItineraryEditor || !planId) {
            // NOTE: if itinerary step was reseted on step change, we need to wait for next cycle for updates,
            // otherwise editor won't be disabled
            await this.waitForNextCycle();
            this.itineraryService.disableEditors();

            return;
        } else {
            this.itineraryService.enableEditors();
        }

        const constraints = await firstValueFrom(this.constraints$);
        const availableEditors = this.store.selectSnapshot(MissionState.missionCapabilities)?.availableEditors ?? [];
        const selectedEditorType = this.localStore.selectSnapshotByKey("selectedEditorType");
        const selectedEditor = selectedEditorType && availableEditors.includes(selectedEditorType) ? selectedEditorType : null;
        const suggestedEditor =
            selectedEditor ??
            this.store.selectSnapshot(MissionState.suggestedEditor) ??
            availableEditors[0] ??
            ItineraryEditorType.Standard;

        this.localStore.patchState((state) => {
            const itineraryEditorFormData = state.itineraryEditorFormData;

            if (itineraryEditorFormData) {
                itineraryEditorFormData.editorType = suggestedEditor;
            }

            return { selectedEditorType: suggestedEditor, itineraryEditorFormData };
        });

        if (!constraints) {
            await lastValueFrom(this.store.dispatch(new MissionActions.GetMissionPlanItinerary(planId)));
            this.handleActiveStepChanged(activeStep);

            return;
        }

        this.itineraryService.startOrEnableEditor(suggestedEditor, constraints);
    }

    public async changeEditorType(newEditorType: ItineraryEditorType) {
        this.itineraryService.clearItinerary();

        const planId = this.getCurrentPlanId();

        if (!planId) {
            return;
        }

        const constraints = await firstValueFrom(this.constraints$);

        if (!constraints) {
            await lastValueFrom(this.store.dispatch(new MissionActions.GetMissionPlanItinerary(planId)));
            this.changeEditorType(newEditorType);

            return;
        }

        this.localStore.patchState({ selectedEditorType: newEditorType });

        this.itineraryService.startOrEnableEditor(newEditorType, constraints);
    }

    public canDeactivate(): Observable<boolean> {
        return combineLatest([this.activeStepId$, this.currentPlan$]).pipe(
            switchMap(([activeStep, currentPlan]) => {
                if (
                    (activeStep !== MissionWizardSteps.Analysis && activeStep !== MissionWizardSteps.Summary) ||
                    !currentPlan ||
                    this.router.getCurrentNavigation()?.extras?.state?.[SKIP_CAN_DEACTIVATE] === true
                ) {
                    return of(true);
                }

                const dialogRef = this.dialogService.open(ConfirmationDialogComponent, {
                    data: {
                        titleText: this.transloco.translate("dtmWebAppLibMission.missionPlannerExitConfirmationDialog.title"),
                        confirmationText: "",
                        declineButtonLabel: this.transloco.translate(
                            "dtmWebAppLibMission.missionPlannerExitConfirmationDialog.dontSaveButtonLabel"
                        ),
                        confirmButtonLabel: this.transloco.translate(
                            "dtmWebAppLibMission.missionPlannerExitConfirmationDialog.saveButtonLabel"
                        ),
                    },
                });

                return dialogRef.afterClosed().pipe(
                    switchMap((result) => {
                        if (result === true) {
                            return of(true);
                        } else if (result === false) {
                            return this.store.dispatch(new MissionActions.DeleteMissionPlan(currentPlan.id)).pipe(map(() => true));
                        }

                        return of(false);
                    })
                );
            }),
            first()
        );
    }

    public cancelWizard() {
        this.router.navigateByUrl("/");
    }

    public goToStep(stepId: MissionWizardSteps) {
        this.store.dispatch(new WizardActions.SetActiveStep(this.wizardId, stepId));
    }

    public updateEntity(updatedEntity: ItineraryContentEntity) {
        this.itineraryService.update(updatedEntity);
    }

    protected updateEntityMetadata(metadata: ItineraryContentMetadata["value"]) {
        this.itineraryService.updateEntityMetadata(metadata);
    }

    public showEntireItineraryContent() {
        const route = this.store.selectSnapshot(MissionState.currentPlanRoute);

        if (route) {
            this.flyToRoute(route);
        } else {
            this.itineraryService.showEntireContent();
        }
    }

    public async submitMission(missionPlanVerificationType: MissionPlanVerificationType) {
        const enabledSteps = this.store.selectSnapshot(WizardState.enabledSteps(this.wizardId));
        const planId = this.getCurrentPlanId();

        if (!planId) {
            return;
        }

        if (await this.ensurePansaUtmConnection()) {
            this.store
                .dispatch([new WizardActions.DisableSteps(this.wizardId, enabledSteps), new MissionActions.RegisterMission(planId)])
                .pipe(
                    tap(() => {
                        const error = this.store.selectSnapshot(MissionState.missionError);

                        if (!error) {
                            this.onMissionRegistrationSuccessful(planId, missionPlanVerificationType);

                            return;
                        }

                        this.displayErrorMessage(error);
                    }),
                    untilDestroyed(this)
                )
                .subscribe();
        }
    }

    protected changeHeightType(heightType: HeightType) {
        this.localStore.patchState((state) => ({ itineraryPanelSettings: { ...state.itineraryPanelSettings, heightType } }));
    }

    public async applyForSpecificPermit() {
        const planId = this.getCurrentPlanId();

        if (!planId) {
            return;
        }

        this.store
            .dispatch(new MissionActions.CloseSpecificPermitPlan(planId))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(MissionState.closeSpecificPermitPlanError);

                if (!error) {
                    this.router.navigate(["/specific-permit/application-creator"], {
                        queryParams: { operationId: planId },
                        state: { [SKIP_CAN_DEACTIVATE]: true },
                    });

                    return;
                }

                this.displayErrorMessage(error);
            });
    }

    public edit() {
        this.router.navigate(["/plan/edit", this.getCurrentPlanId()]);
    }

    private manuallyAddCustomItineraryEntity(entity: CustomItineraryEntity, constraints: MissionPlanItineraryConstraints) {
        const itineraryEditorFormData = this.localStore.selectSnapshotByKey("itineraryEditorFormData");

        const entityEditorConstraints = convertMissionPlanItineraryConstraintsToEntityEditorConstraints(
            constraints,
            itineraryEditorFormData
        );

        this.itineraryService.addCustomItineraryEntity(entity, entityEditorConstraints);
    }

    protected addItineraryEntity(entity: ItineraryEntity, constraints: MissionPlanItineraryConstraints) {
        if (this.isAssistedEditorEntity(entity)) {
            this.itineraryService.createAssistedEditorItineraryEntity(entity);

            return;
        }

        const itineraryEditorFormData = this.localStore.selectSnapshotByKey("itineraryEditorFormData");
        if (this.isStandardEditorEntity(entity) && entity.isManual && itineraryEditorFormData) {
            this.openManualCoordinatesInputDialog(entity.type, constraints, itineraryEditorFormData);

            return;
        }

        this.manuallyAddCustomItineraryEntity(entity, constraints);
    }

    protected deleteItineraryEntity([entity, index]: [ItineraryContentEntity, number]) {
        this.itineraryService.deleteItineraryEntity(entity, index);
    }

    protected deletePolyline3DWaypoint([entity, index]: [Polyline3DItineraryEntity, number]) {
        this.itineraryService.deletePolyline3DWaypoint(entity, index);
    }

    protected zoomToEntity(entity: MapEntity) {
        this.cameraHelperService.flyToContent([entity]);
    }

    protected updateMeasureToolStatus(isActive: boolean) {
        this.localStore.patchState({ isMeasureToolActive: isActive });
    }

    private manuallyAddAssistedItineraryEntity(entity: AssistedItineraryEntity, constraints: MissionPlanItineraryConstraints) {
        const entityEditorConstraints = convertMissionPlanItineraryConstraintsToAssistedEntityEditorConstraints(constraints);

        this.itineraryService.addAssistedItineraryEntity(
            entity,
            entityEditorConstraints,
            entity.entityId === AssistedEntityId.Takeoff ? MapActionType.DrawAssistedTakeoffRunway : MapActionType.DrawAssistedLandingRunway
        );
    }

    private manuallyAddStandardItineraryEntity(
        entity: StandardItineraryEntity,
        constraints: MissionPlanItineraryConstraints,
        itineraryEditorFormData: ItineraryEditorFormData
    ) {
        const entityEditorConstraints = convertMissionPlanItineraryConstraintsToEntityEditorConstraints(
            constraints,
            itineraryEditorFormData
        );

        this.itineraryService.addStandardItineraryEntity(entity, entityEditorConstraints);
    }

    private isAssistedEditorEntity(entity: ItineraryEntity): entity is AssistedItineraryDataEntity {
        return entity.type === MapEntityType.Cylinder && "entityId" in entity && Object.values(AssistedEntityId).includes(entity.entityId);
    }

    private isStandardEditorEntity(entity: ItineraryEntity): entity is StandardItineraryEntity {
        return entity.type && (entity as StandardItineraryEntity).isManual !== undefined;
    }

    protected processMapActionChange(action: MapActionWithPayload, constraints?: MissionPlanItineraryConstraints) {
        if (!constraints) {
            return;
        }

        switch (action.type) {
            case MapActionType.RemoveContent:
                this.clearItineraryContent();

                return;

            case MapActionType.ShowEntireContent:
                this.showEntireItineraryContent();

                return;

            case MapActionType.FinishDrawing:
                this.itineraryService.finishActiveEntityDrawing();

                return;

            case MapActionType.RemoveLastPoint:
                this.itineraryService.removeLastPointFromActiveEntity();

                return;

            case MapActionType.CancelDrawing:
                this.itineraryService.cancelActiveEntityDrawing();

                return;

            default:
                break;
        }

        const selectedEditorType =
            this.localStore.selectSnapshotByKey("selectedEditorType") ?? this.store.selectSnapshot(MissionState.suggestedEditor);

        switch (selectedEditorType) {
            case ItineraryEditorType.Custom:
                this.processCustomEditorMapActionChange(action, constraints);
                break;
            case ItineraryEditorType.Assisted:
                this.processAssistedEditorMapActionChange(action, constraints);
                break;
            case ItineraryEditorType.Standard:
                this.processStandardEditorMapActionChange(action, constraints);
                break;

            default:
                return;
        }
    }

    private processCustomEditorMapActionChange(action: MapActionWithPayload, constraints: MissionPlanItineraryConstraints) {
        switch (action.type) {
            case MapActionType.DrawCylinder:
                this.manuallyAddCustomItineraryEntity({ type: MapEntityType.Cylinder }, constraints);
                break;

            case MapActionType.DrawPolyline:
                this.manuallyAddCustomItineraryEntity({ type: MapEntityType.Polyline3D }, constraints);
                break;

            case MapActionType.DrawPrism:
                this.manuallyAddCustomItineraryEntity({ type: MapEntityType.Prism }, constraints);
                break;

            default:
                break;
        }
    }

    private processAssistedEditorMapActionChange(action: MapActionWithPayload, constraints: MissionPlanItineraryConstraints) {
        switch (action.type) {
            case MapActionType.DrawAssistedLandingRunway:
                this.manuallyAddAssistedItineraryEntity({ type: MapEntityType.Cylinder, entityId: AssistedEntityId.Landing }, constraints);
                break;

            case MapActionType.DrawAssistedTakeoffRunway:
                this.manuallyAddAssistedItineraryEntity({ type: MapEntityType.Cylinder, entityId: AssistedEntityId.Takeoff }, constraints);
                break;

            default:
                break;
        }
    }

    private processStandardEditorMapActionChange(action: MapActionWithPayload, constraints: MissionPlanItineraryConstraints) {
        const itineraryEditorFormData = this.localStore.selectSnapshotByKey("itineraryEditorFormData");

        if (!itineraryEditorFormData) {
            return;
        }

        switch (action.type) {
            case MapActionType.DrawCylinder:
                this.manuallyAddStandardItineraryEntity(
                    { type: MapEntityType.Cylinder, isManual: false },
                    constraints,
                    itineraryEditorFormData
                );
                break;

            case MapActionType.DrawPrism:
                this.manuallyAddStandardItineraryEntity(
                    { type: MapEntityType.Prism, isManual: false },
                    constraints,
                    itineraryEditorFormData
                );
                break;

            case MapActionType.DrawPolylineCorridor:
                this.manuallyAddStandardItineraryEntity({ type: MapEntityType.Polyline3D, isManual: false }, constraints, {
                    ...itineraryEditorFormData,
                    horizontalBuffer: action.payload?.width ?? itineraryEditorFormData.horizontalBuffer,
                });
                break;
            case MapActionType.ManualCoordinatesInputCylinder:
                this.openManualCoordinatesInputDialog(MapEntityType.Cylinder, constraints, itineraryEditorFormData);
                break;
            case MapActionType.ManualCoordinatesInputPolylineCorridor:
                this.openManualCoordinatesInputDialog(MapEntityType.Polyline3D, constraints, itineraryEditorFormData);
                break;
            case MapActionType.ManualWidthInputPolylineCorridor:
                if (action.payload) {
                    this.openManualWidthInputForPolylineCorridorDialog(
                        action.payload.min,
                        action.payload.max,
                        action.payload.step,
                        constraints,
                        itineraryEditorFormData
                    );
                }
                break;
            default:
                break;
        }
    }

    private openManualWidthInputForPolylineCorridorDialog(
        minWidth: number,
        maxWidth: number,
        step: number,
        constraints: MissionPlanItineraryConstraints,
        itineraryEditorFormData: ItineraryEditorFormData
    ) {
        const dialogRef = this.dialogService.open(ManualWidthInputDialogComponent, {
            data: {
                minWidth,
                maxWidth,
                step,
            },
        });

        dialogRef
            .afterClosed()
            .pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
            .subscribe(({ width }) => {
                this.manuallyAddStandardItineraryEntity({ type: MapEntityType.Polyline3D, isManual: false }, constraints, {
                    ...itineraryEditorFormData,
                    horizontalBuffer: width,
                });
            });
    }

    private openManualCoordinatesInputDialog(
        type: ManualCoordinatesInputEntityType,
        constraints: MissionPlanItineraryConstraints,
        itineraryEditorFormData: ItineraryEditorFormData
    ) {
        const entityEditorConstraints = convertMissionPlanItineraryConstraintsToEntityEditorConstraints(
            constraints,
            itineraryEditorFormData
        );
        const dialogRef = this.dialogService.open(ManualCoordinatesInputDialogComponent, {
            data: {
                type,
                constraints: entityEditorConstraints,
            },
        });

        dialogRef
            .afterClosed()
            .pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
            .subscribe(({ coordinates, size }) => {
                this.itineraryService.createStandardItineraryEntity({ type, isManual: true }, constraints, coordinates, size);
            });
    }

    private onMissionRegistrationSuccessful(planId: string, missionPlanVerificationType: MissionPlanVerificationType) {
        const successMessage = this.transloco.translate(
            missionPlanVerificationType !== MissionPlanVerificationType.Manual
                ? "dtmWebAppLibMission.missionRegistrationSuccessMessage"
                : "dtmWebAppLibMission.missionWithManualValidationRegistrationSuccessMessage"
        );

        this.itineraryService.clearItinerary();
        this.toastService.success(successMessage);
        this.store.dispatch(new WizardActions.CleanupWizard(this.wizardId));
        this.router.navigate(["/plan/list", planId], { state: { [SKIP_CAN_DEACTIVATE]: true } });
    }

    private getCurrentPlanId(): string | undefined {
        return this.store.selectSnapshot(MissionState.currentPlan)?.id;
    }

    private getInitialViewboxObservable() {
        return this.missionCapabilities$.pipe(
            map((capabilities) => capabilities?.preferences?.initialViewbox),
            RxjsUtils.filterFalsy(),
            first(),
            map((viewbox: GeoJSON) => {
                const bbox = turfBbox(viewbox);

                Cesium.Camera.DEFAULT_VIEW_FACTOR = 0;
                Cesium.Camera.DEFAULT_VIEW_RECTANGLE = Cesium.Rectangle.fromDegrees(...bbox);

                return Cesium.Camera.DEFAULT_VIEW_RECTANGLE;
            })
        );
    }

    private displayErrorMessage(error: MissionError) {
        if (TEMPLATE_HANDLED_ERRORS.includes(error.type)) {
            return;
        }

        const {
            errorMessage,
            title,
            options,
        }: { errorMessage: string | undefined; title: string | undefined; options: Partial<IndividualConfig> | undefined } =
            this.getMissionErrorMessage(error);
        if (errorMessage && !this.toastService.findDuplicate(title ?? "", errorMessage, true, false)) {
            this.toastService.error(errorMessage, title, options);
        }
    }

    private getMissionErrorMessage(error: MissionError) {
        let errorMessage: string | undefined;
        let options: Partial<IndividualConfig> | undefined;
        let title: string | undefined;

        switch (error.type) {
            case MissionErrorType.CannotUpdateMissionPlan:
                errorMessage = this.transloco.translate("dtmWebAppLibMission.cannotCreateOrUpdateMissionPlanErrorMessage");
                break;
            case MissionErrorType.CannotRegisterMission:
                errorMessage = this.transloco.translate("dtmWebAppLibMission.cannotRegisterMissionErrorMessage");
                break;
            case MissionErrorType.NotAuthorized:
                errorMessage = this.transloco.translate("dtmWebAppLibMission.notAuthorizedErrorMessage");
                break;
            case MissionErrorType.CannotCloseSoraPlan:
                errorMessage = this.transloco.translate("dtmWebAppLibMission.cannotCloseSoraPlanError");
                break;
            case MissionErrorType.Fields:
                title = this.transloco.translate("dtmWebAppLibMission.missionPlanVerificationFieldsError.messageTitle");
                errorMessage = (error as MissionFieldsError).fields
                    .map(({ fieldName, code, args }) => {
                        const translation = `${SYSTEM_TRANSLATION_SCOPE}.${code}`;
                        const result = this.translationHelper.selectSystemTranslation(code, args);

                        if (result === translation) {
                            Logger.captureMessage("Missing system translation for field", {
                                level: "error",
                                extra: { fieldName, code, args },
                            });

                            return this.transloco.translate("dtmWebAppLibMission.missionPlanVerificationFieldsError.unknownInvalidField", {
                                fieldName,
                                code,
                            });
                        }

                        return result;
                    })
                    .join("<br />");
                options = { enableHtml: true };
                break;
            default:
                errorMessage = this.transloco.translate("dtmWebAppLibMission.genericErrorMessage");
        }

        return { errorMessage, title, options };
    }

    private saveMissionThumbnail() {
        const planId = this.getCurrentPlanId();

        if (!planId) {
            return;
        }
        this.showEntireItineraryContent();

        const cameraViewSettingsCache = this.cameraHelperService.freezeView();
        this.localStore.patchState({ shouldHideNearbyMissionsOnMap: true });

        this.cameraHelperService
            .takeScreenshotWhenStable()
            .pipe(
                switchMap((file) => {
                    if (!file) {
                        throw new Error("failed");
                    }

                    return this.imageHelperService.cropImageCentrally(file, THUMBNAIL_IMAGE_PROPORTION);
                }),
                switchMap((file) => {
                    if (!file) {
                        throw new Error("failed");
                    }

                    return this.imageHelperService.resizeImageFile(file, THUMBNAIL_IMAGE_WIDTH);
                }),
                tap((resizedFile) => {
                    if (!resizedFile) {
                        throw new Error("failed");
                    }

                    this.missionApiService.saveMissionThumbnail(resizedFile, planId).subscribe();
                }),
                finalize(() => {
                    this.localStore.patchState({ shouldHideNearbyMissionsOnMap: false });
                    this.cameraHelperService.restoreView(cameraViewSettingsCache);
                }),
                untilDestroyed(this)
            )
            .subscribe();
    }

    private async waitForNextCycle(): Promise<void> {
        return new Promise((resolve) => {
            setTimeout(resolve);
        });
    }

    public goBack(sourceUrl?: string) {
        if (sourceUrl) {
            this.router.navigateByUrl(sourceUrl);
        }
    }

    public getSource(sourceUrl: string): Source | undefined {
        return Object.entries(SourceUrl).find(([, url]) => decodeURI(sourceUrl).startsWith(url))?.[0] as Source;
    }

    public setAdditionalInformationFromMissionPlan() {
        this.missionPlanInformation$
            .pipe(first(Boolean), untilDestroyed(this))
            .subscribe((additionalInformation) => this.localStore.patchState({ additionalInformation }));
    }

    public togglePanelFold(isFolded: boolean) {
        this.localStore.patchState({ isDataPanelFolded: !isFolded });
    }

    protected setBottomPanelTemplate(template: TemplatePortal<unknown> | undefined, stepId: MissionWizardSteps) {
        this.localStore.patchState((state) => ({ bottomPanelTemplates: { ...state.bottomPanelTemplates, [stepId]: template } }));
    }

    protected getSegmentDuration(waypoints: WaypointWithSection[] | undefined, waypointIndex: number) {
        if (!waypoints || waypointIndex > waypoints.length - 1 || waypointIndex < 0 || waypoints.length < 2) {
            return undefined;
        }

        if (waypointIndex === waypoints.length - 1) {
            waypointIndex--; // NOTE: get duration of last valid segment
        }

        const segmentStart = waypoints[waypointIndex];
        const segmentEnd = waypoints[waypointIndex + 1];

        return {
            fromTime: segmentStart.waypoint.estimatedArriveAt.min,
            toTime: segmentEnd.waypoint.estimatedArriveAt.max,
        };
    }

    protected getSegmentAmslHeights(
        waypoints: WaypointWithSection[] | undefined,
        { waypointIndex, topHeight, bottomHeight }: { waypointIndex: number; topHeight?: number; bottomHeight?: number }
    ) {
        const waypoint = waypoints?.[waypointIndex];

        if (!waypoints || !waypoint?.parentSection.flightZone || topHeight === undefined || bottomHeight === undefined) {
            return undefined;
        }

        const amslOffset = waypoint.parentSection.flightZone.center.point.altitude - waypoint.parentSection.flightZone.center.point.height;

        return {
            topHeight: topHeight + amslOffset,
            bottomHeight: waypointIndex === 0 || waypointIndex === waypoints.length - 1 ? 0 : bottomHeight + amslOffset,
        };
    }

    protected getAmslHeightForWaypoint(
        waypoints: WaypointWithSection[] | undefined,
        waypointIndex: number,
        halfVerticalBuffer: number | undefined
    ) {
        if (waypoints?.[waypointIndex]?.waypoint.point.altitude === undefined) {
            return undefined;
        }

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return (waypoints[waypointIndex].waypoint.point.altitude! - (halfVerticalBuffer ?? 0)).toFixed(0);
    }

    private removeOverlapping() {
        const elements = [
            ...(this.document?.querySelectorAll(".radius-label, .segment-label, .default-label, .point-height-label").values() ?? []),
        ] as HTMLDivElement[];

        MapLabelsUtils.removeOverlapping(elements, {
            svgLineSelector: ".line-connector line",
            buffer: 4,
        });
    }

    private initContextSwitchTry() {
        return defer(() => {
            const capabilities = this.store.selectSnapshot(MissionState.missionCapabilities);

            if (!capabilities?.operator?.uavs.length || !capabilities?.operator.pilots.length) {
                return of(true);
            }

            return this.dialogService
                .open(ConfirmationDialogComponent, {
                    data: {
                        confirmationText: this.transloco.translate("dtmWebAppLibMission.confirmUserContextSwitchDialog.contentText"),
                        confirmButtonLabel: this.transloco.translate(
                            "dtmWebAppLibMission.confirmUserContextSwitchDialog.confirmButtonLabel"
                        ),
                        titleText: this.transloco.translate("dtmWebAppLibMission.confirmUserContextSwitchDialog.titleText"),
                        theme: ButtonTheme.Warn,
                    },
                })
                .afterClosed();
        });
    }

    private watchOperatorContextChange() {
        this.userContextService.registerContextSwitchTry(this.contextSwitchTry$);
    }

    protected async editStopover(waypointParentId: string, waypointIndex: number) {
        const itineraryContent = await lastValueFrom(this.itineraryContent$.pipe(first(), untilDestroyed(this)));
        const parentEntity = itineraryContent.find(
            (waypoint) => waypoint.id === waypointParentId && waypoint.type === MapEntityType.Polyline3D
        ) as Polyline3DItineraryEntity | undefined;

        const editedEntityId = Object.values(parentEntity?.childEntities ?? {}).find((entity) => entity.waypointIndex === waypointIndex)
            ?.entity.id;

        if (!parentEntity || !editedEntityId) {
            return;
        }

        const dialogRef = this.dialogService.open(ItineraryPanelStopoverDialogComponent, {
            data: {
                stopover: parentEntity.metadata?.stopovers[editedEntityId],
            },
        });

        dialogRef
            .afterClosed()
            .pipe(untilDestroyed(this), RxjsUtils.filterFalsy())
            .subscribe((data) => {
                this.updateEntityMetadata({
                    entityId: waypointParentId,
                    type: MapEntityType.Polyline3D,
                    stopovers: {
                        ...parentEntity.metadata?.stopovers,
                        [editedEntityId]: data.stopover,
                    },
                });
            });
    }

    protected handleOtherMissionSelection(missionId: string) {
        this.localStore.patchState({ selectedOtherMissionId: missionId });
    }

    private async createPlanNameIfNotExistOrNotChanged(itineraryData: ItineraryEditorFormData) {
        const additionalInformation = this.localStore.selectSnapshotByKey("additionalInformation");
        const formData = this.localStore.selectSnapshotByKey("missionDataFormData");

        const name = this.generateMissionName(
            itineraryData.dateRangeStart ?? itineraryData.datetime,
            formData?.capabilities?.uavWithSetup?.displayName
        );

        if (!additionalInformation?.name || (!additionalInformation.isNameDirty && additionalInformation.name !== name)) {
            await this.updateAdditionalInformation({
                ...additionalInformation,
                name,
            });
        }
    }

    private generateMissionName(date: Date, uavName?: string) {
        let locale = this.translocoLocaleService.getLocale();
        // TODO: DTM-3987 remove forcing en-GB when backend will change it's locale
        if (locale === "en-US") {
            locale = "en-GB";
        }

        const planDateTimeText = this.translocoLocaleService.localizeDate(date, locale, {
            dateStyle: "short",
            timeStyle: "short",
        });

        return `${planDateTimeText}, ${uavName}`;
    }

    private getSelectedMapActionsPanelModeObservable(): Observable<MapActionsPanelMode> {
        return this.selectedEditorType$.pipe(
            map((editorType): MapActionsPanelMode => {
                switch (editorType) {
                    case ItineraryEditorType.Assisted:
                        return MapActionsPanelMode.AssistedEditor;
                    case ItineraryEditorType.Custom:
                        return MapActionsPanelMode.CustomEditor;
                    case ItineraryEditorType.Standard:
                        return MapActionsPanelMode.StandardEditor;

                    case undefined:
                    case ItineraryEditorType.None:
                        return MapActionsPanelMode.None;
                }
            })
        );
    }

    private getHeightConstraints(): Observable<ZoneHeightLimits | undefined> {
        return combineLatest([this.isFlightAroundObstacle$, this.constraints$, this.currentPlan$]).pipe(
            map(([isFlightObstacle, constraints, currentPlan]) => {
                if (!constraints || !currentPlan) {
                    return;
                }

                const { min, max } = constraints;

                if (this.isOpenCategoryOrSpecificSts(currentPlan.operationCategory)) {
                    return {
                        min: isFlightObstacle ? min.aroundObstacleHeight : min.regularHeight,
                        max: isFlightObstacle ? max.aroundObstacleHeight : max.regularHeight,
                    };
                }

                return {
                    min: min.height,
                    max: max.height,
                };
            })
        );
    }

    private isOpenCategoryOrSpecificSts(operationCategory: MissionPlanOperationCategoryOption | undefined): boolean {
        return (
            (operationCategory?.type === MissionCategory.Specific &&
                operationCategory.specificPermitType === MissionPlanSpecificPermitType.Sts) ||
            operationCategory?.type === MissionCategory.Open
        );
    }

    private getCircularBoundaryLayerMaxRadiusObservable(): Observable<number | undefined> {
        return combineLatest([this.constraints$, this.itineraryEditorFormData$, this.currentPlan$, this.missionDataFormData$]).pipe(
            map(([constraints, itineraryEditorFormData, currentPlan, missionDataFormData]) => {
                if (!constraints || !itineraryEditorFormData) {
                    return undefined;
                }

                if (itineraryEditorFormData.editorType === ItineraryEditorType.Standard) {
                    return itineraryEditorFormData.isEnlargedZoneStatementAccepted
                        ? constraints.max.largeZoneRadius
                        : constraints.max.regularZoneRadius;
                }

                if (
                    itineraryEditorFormData.editorType === ItineraryEditorType.Custom &&
                    currentPlan?.operationCategory?.type === MissionCategory.Specific &&
                    currentPlan.operationCategory.specificPermitType === MissionPlanSpecificPermitType.Sts
                ) {
                    return constraints.max.largeZoneRadius;
                }

                return undefined;
            })
        );
    }

    protected updateAreDangerousMaterialsTransported(areDangerousMaterialsTransported: boolean | null) {
        this.localStore.patchState({ areDangerousMaterialsTransported: !!areDangerousMaterialsTransported });
    }

    private watchPlanNameChanges() {
        this.currentPlan$
            .pipe(
                distinctUntilChanged((left, right) => left?.information.name !== right?.information.name),
                RxjsUtils.filterFalsy(),
                untilDestroyed(this)
            )
            .subscribe((plan) => {
                const uavName = this.store
                    .selectSnapshot(MissionState.missionCapabilities)
                    ?.operator?.uavs.find((uav) => uav.setups.some((setup) => setup.id === plan?.capabilities.uavSetupId))?.displayName;

                if (!uavName || !plan?.flightStartDate) {
                    return;
                }

                const generatedName = this.generateMissionName(plan.flightStartDate, uavName);

                this.localStore.patchState(({ additionalInformation }) => ({
                    additionalInformation: {
                        ...additionalInformation,
                        name: plan.information.name,
                        isNameDirty: plan.information.name !== generatedName,
                    },
                }));
            });
    }

    protected loadPermitData(fileId: { kmlFileId?: string; zoneDesignator?: string } | undefined) {
        this.store.dispatch(new MissionActions.GetPermitLocationData(fileId));
    }

    protected isPermitAreaZoomEnabled(activeStepId?: MissionWizardSteps): boolean {
        return activeStepId ? [MissionWizardSteps.MissionData, MissionWizardSteps.ItineraryEditor].includes(activeStepId) : false;
    }

    protected updatePermitBoundaryViolationStatus(isPermitBoundaryViolated: boolean) {
        this.localStore.patchState({ isPermitBoundaryViolated });
    }
}
