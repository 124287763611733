<ac-layer
    acFor="let entity of pinEntities$"
    [context]="this"
    *ngrxLet="{ isZoomButtonVisible: isZoomButtonVisible$ } as vm"
    [show]="vm.isZoomButtonVisible"
>
    <ac-html-desc props="{ position: entity.position }">
        <ng-template let-pin>
            <div class="mission-pin" *ngIf="vm.isZoomButtonVisible">
                <dtm-ui-icon name="map-pin-fill" class="pin-icon"></dtm-ui-icon>
                <button type="button" class="button-icon map-icon" (click)="zoomToLocation(pin.zoomArea)">
                    <dtm-ui-icon name="road-map"></dtm-ui-icon>
                </button>
            </div>
        </ng-template>
    </ac-html-desc>
</ac-layer>
