<div class="grid grid-maxsize-8 grid-maxsize-tablet-10">
    <dtm-ui-wizard-step-wrapper
        [stepNumber]="2"
        [stepsAmount]="stepsAmount$ | ngrxPush"
        [title]="'dtmWebAppLibOperatorPermissions.statementsWizardSteps.uavInformation.cardHeader' | transloco"
        [nextButtonLabel]="
            vm.isPaymentFeatureAvailable
                ? ('dtmWebAppLibOperatorPermissions.statementsWizardSteps.uavInformation.nextButtonLabel' | transloco)
                : ('dtmWebAppLibOperatorPermissions.statementsWizardSteps.uavInformation.goToSummaryButtonLabel' | transloco)
        "
        (back)="back.emit()"
        (next)="submitForm()"
        dtmUiInvalidFormScrollable
        *ngrxLet="{
            isPaymentFeatureAvailable: isPaymentFeatureAvailable$,
            uavsRequiredPermissions: uavsRequiredPermissions$,
            availableUavs: availableUavs$,
            otherPossiblePermissions: otherPossiblePermissions$
        } as vm"
    >
        <dtm-ui-info-message type="warning">
            <p [innerHtml]="'dtmWebAppLibOperatorPermissions.statementsWizardSteps.uavInformation.uavClassWarningMessage' | transloco"></p>
            <button type="button" class="button-secondary" [routerLink]="['/uav/list']">
                {{ "dtmWebAppLibOperatorPermissions.statementsWizardSteps.uavInformation.uavListButtonLabel" | transloco }}
            </button>
        </dtm-ui-info-message>
        <form [formGroup]="permissionsForm" class="permissions-container">
            <dtm-web-app-lib-scenario-item
                *ngFor="let permission of vm.uavsRequiredPermissions"
                [scenario]="permission"
                [isPaymentFeatureAvailable]="vm.isPaymentFeatureAvailable"
            >
                <div class="uav-permission" [formGroupName]="permission.scenarioId">
                    <ol class="uav-list">
                        <li *ngFor="let control of permissionsForm.controls[permission.scenarioId].controls; let index = index">
                            <div class="uav-permission-label">
                                {{
                                    "dtmWebAppLibOperatorPermissions.statementsWizardSteps.uavInformation.uavsUsedForPermissionLabel"
                                        | transloco : { index: index + 1 }
                                }}
                                <button
                                    type="button"
                                    class="button-icon"
                                    [disabled]="permissionsForm.controls[permission.scenarioId].controls.length === 1"
                                    (click)="removeUav(permission.scenarioId, index)"
                                >
                                    <dtm-ui-icon name="delete-bin"></dtm-ui-icon>
                                </button>
                            </div>
                            <dtm-ui-select-field [formControlName]="index" [isClearable]="false" class="uav-permission-field">
                                <label>{{
                                    "dtmWebAppLibOperatorPermissions.statementsWizardSteps.uavInformation.uavLabel" | transloco
                                }}</label>
                                <dtm-ui-select-option
                                    *ngFor="let availableUav of vm.availableUavs | invoke : getAvailableUavsForPermission : permission"
                                    [value]="availableUav"
                                >
                                    {{ availableUav.name }}
                                </dtm-ui-select-option>
                                <!--NOTE: cannot use dtmUiFieldHasError as it does not refresh after changes in other control-->
                                <div class="field-error" *ngIf="control.errors?.notUnique">
                                    {{
                                        "dtmWebAppLibOperatorPermissions.statementsWizardSteps.uavInformation.uavAlreadySelectedError"
                                            | transloco
                                    }}
                                </div>
                                <div class="field-error" *dtmUiFieldHasError="control; name: 'required'">
                                    {{
                                        "dtmWebAppLibOperatorPermissions.statementsWizardSteps.uavInformation.uavRequiredError" | transloco
                                    }}
                                    <ng-container *ngIf="permission.allowedUavClasses.length">
                                        {{
                                            "dtmWebAppLibOperatorPermissions.statementsWizardSteps.uavInformation.uavRequiredClassError"
                                                | transloco
                                                    : {
                                                          allowedUavClassesLength: permission.allowedUavClasses.length,
                                                          allowedUavClasses: permission.allowedUavClasses | allowedUavClassesNames
                                                      }
                                        }}
                                    </ng-container>
                                </div>
                            </dtm-ui-select-field>
                        </li>
                    </ol>
                    <button type="button" (click)="addUavToPermission(permission.scenarioId)" class="button-secondary add-uav-button">
                        <dtm-ui-icon name="edit"></dtm-ui-icon>
                        {{ "dtmWebAppLibOperatorPermissions.statementsWizardSteps.uavInformation.addUavLabel" | transloco }}
                    </button>
                </div>
            </dtm-web-app-lib-scenario-item>
        </form>
        <div *ngIf="vm.otherPossiblePermissions.length">
            <h4 class="permissions-header">
                {{ "dtmWebAppLibOperatorPermissions.statementsWizardSteps.uavInformation.otherPermissionsHeader" | transloco }}
            </h4>
            <div class="permissions-container">
                <dtm-web-app-lib-scenario-item
                    *ngFor="let permission of vm.otherPossiblePermissions"
                    [scenario]="permission"
                    [isPaymentFeatureAvailable]="vm.isPaymentFeatureAvailable"
                >
                    <p>{{ permission.description }}</p>
                </dtm-web-app-lib-scenario-item>
            </div>
        </div>
    </dtm-ui-wizard-step-wrapper>
</div>
