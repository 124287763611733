<ng-container
    *ngrxLet="{
        constraints: constraints$,
        operationCategory: operationCategory$,
        isExpanded: isExpanded$,
        minGroundRiskBuffer: minGroundRiskBuffer$,
        shouldDisableLowLevelFlight: shouldDisableLowLevelFlight$,
        isReadOnly: isReadOnly$,
    } as vm"
>
    <dtm-ui-expandable-panel
        [isExpanded]="vm.isExpanded"
        [hasHeaderSeparator]="false"
        *ngIf="vm.constraints && (vm.operationCategory | ngrxPush | invoke : shouldShowSoraOptions)"
    >
        <div headerSlot>{{ "dtmWebAppLibMission.itineraryEditorStep.soraSettings.panelHeader" | transloco }}</div>
        <p class="field-label ground-risk-header">
            <span>{{ "dtmWebAppLibMission.itineraryEditorStep.soraSettings.groundRiskBufferLabel" | transloco }}</span>
            <dtm-ui-popover>
                <p [innerHTML]="'dtmWebAppLibMission.itineraryEditorStep.soraSettings.groundRiskBufferFirstInfoTooltip' | transloco"></p>
                <br />
                <p [innerHTML]="'dtmWebAppLibMission.itineraryEditorStep.soraSettings.groundRiskBufferSecondInfoTooltip' | transloco"></p>
            </dtm-ui-popover>
        </p>

        <dtm-ui-radio-group [formControl]="isIndividualBufferSettingControl" class="horizontal">
            <dtm-ui-radio-field [value]="false">
                {{ "dtmWebAppLibMission.itineraryEditorStep.soraSettings.groundRiskBufferStandardLabel" | transloco }}
            </dtm-ui-radio-field>
            <dtm-ui-radio-field [value]="true">
                {{ "dtmWebAppLibMission.itineraryEditorStep.soraSettings.groundRiskBufferIndividualLabel" | transloco }}
            </dtm-ui-radio-field>
        </dtm-ui-radio-group>

        <dtm-ui-input-field *ngIf="isIndividualBufferSettingControl.value" [isClearable]="false" class="buffer-value-field">
            <label>
                <span>{{ "dtmWebAppLibMission.itineraryEditorStep.soraSettings.groundRiskBufferValueLabel" | transloco }}</span>
                <dtm-ui-popover>
                    {{ "dtmWebAppLibMission.itineraryEditorStep.soraSettings.groundRiskRequirementsLabel" | transloco }}
                </dtm-ui-popover>
            </label>
            <input matInput type="number" inputmode="numeric" autocomplete="off" [formControl]="individualGroundRiskBufferValueControl" />

            <div class="field-suffix">
                {{ "dtmWebAppLibMission.itineraryEditorStep.soraSettings.groundRiskBufferUnitLabel" | transloco }}
            </div>
            <div class="field-error" *dtmUiFieldHasError="individualGroundRiskBufferValueControl; name: 'min'; error as error">
                {{
                    "dtmWebAppLibMission.itineraryEditorStep.soraSettings.minimumRequiredBufferErrorMessage"
                        | transloco : { min: error.min | localizeNumber }
                }}
            </div>
            <div class="field-error" *dtmUiFieldHasError="individualGroundRiskBufferValueControl; name: 'max'; error as error">
                {{
                    "dtmWebAppLibMission.itineraryEditorStep.soraSettings.maximumRequiredBufferErrorMessage"
                        | transloco : { max: error.max | localizeNumber }
                }}
            </div>
            <div class="field-error" *dtmUiFieldHasError="individualGroundRiskBufferValueControl; name: 'required'">
                {{ "dtmWebAppLibMission.requiredFieldErrorHint" | transloco }}
            </div>
        </dtm-ui-input-field>

        <hr />
        <mat-slide-toggle
            labelPosition="before"
            class="spread allow-wrap"
            [formControl]="isFlightsOverAssembliesControl"
            [matTooltip]="
                isFlightsOverAssembliesControl.disabled
                    ? ('dtmWebAppLibMission.itineraryEditorStep.soraSettings.flightOverAssembliesProhibitedLabel' | transloco)
                    : ''
            "
        >
            {{ "dtmWebAppLibMission.itineraryEditorStep.soraSettings.flightsOverAssembliesLabel" | transloco }}
            <dtm-ui-popover (click)="$event.preventDefault()">
                <p [innerHTML]="'dtmWebAppLibMission.itineraryEditorStep.soraSettings.flightsOverAssembliesTooltip' | transloco"></p>
                <br />
                <p
                    [innerHTML]="
                        'dtmWebAppLibMission.itineraryEditorStep.soraSettings.flightsOverAssembliesRestrictionsInfoTooltip' | transloco
                    "
                ></p>
            </dtm-ui-popover>
        </mat-slide-toggle>
        <dtm-ui-textarea-field *ngIf="!vm.isReadOnly && isFlightsOverAssembliesControl.value">
            <label> {{ "dtmWebAppLibMission.itineraryEditorStep.soraSettings.detailedDescriptionLabel" | transloco }}</label>
            <textarea matInput [rows]="4" [formControl]="flightsOverAssembliesDescriptionControl"></textarea>
            <div class="field-error" *dtmUiFieldHasError="flightsOverAssembliesDescriptionControl; name: 'required'">
                {{ "dtmWebAppLibMission.requiredFieldErrorHint" | transloco }}
            </div>
        </dtm-ui-textarea-field>
        <mat-slide-toggle labelPosition="before" class="spread allow-wrap" [formControl]="isFlightOverControlledAreaControl">
            {{ "dtmWebAppLibMission.itineraryEditorStep.soraSettings.flightOverControlledAreaLabel" | transloco }}
            <dtm-ui-popover (click)="$event.preventDefault()">
                <p [innerHTML]="'dtmWebAppLibMission.itineraryEditorStep.soraSettings.flightOverControlledAreaTooltip' | transloco"></p>
            </dtm-ui-popover>
        </mat-slide-toggle>
        <div class="statement" *ngIf="isFlightOverControlledAreaControl.value">
            <dtm-ui-checkbox-field [formControl]="isFlightOverControlledAreaStatementAcceptedControl">
                <div class="conditions-content">
                    <span class="required">*</span>
                    <span>
                        {{
                            "dtmWebAppLibMission.itineraryEditorStep.soraSettings.flightOverControlledAreaStatementText.header" | transloco
                        }}
                    </span>
                    <ul>
                        <li>
                            {{
                                "dtmWebAppLibMission.itineraryEditorStep.soraSettings.flightOverControlledAreaStatementText.firstCondition"
                                    | transloco
                            }}
                        </li>
                        <li>
                            {{
                                "dtmWebAppLibMission.itineraryEditorStep.soraSettings.flightOverControlledAreaStatementText.secondCondition"
                                    | transloco
                            }}
                        </li>
                        <li>
                            {{
                                "dtmWebAppLibMission.itineraryEditorStep.soraSettings.flightOverControlledAreaStatementText.thirdCondition"
                                    | transloco
                            }}
                        </li>
                    </ul>
                </div>
            </dtm-ui-checkbox-field>
            <dtm-ui-form-errors>
                <div class="field-error" *dtmUiFieldHasError="isFlightOverControlledAreaStatementAcceptedControl; name: 'required'">
                    {{ "dtmWebAppLibMission.requiredFieldErrorHint" | transloco }}
                </div>
            </dtm-ui-form-errors>
        </div>
        <dtm-ui-textarea-field *ngIf="!vm.isReadOnly && isFlightOverControlledAreaControl.value">
            <label> {{ "dtmWebAppLibMission.itineraryEditorStep.soraSettings.detailedDescriptionLabel" | transloco }}</label>
            <textarea matInput [rows]="4" [formControl]="flightOverControlledAreaDescriptionControl"></textarea>
            <div class="field-error" *dtmUiFieldHasError="flightOverControlledAreaDescriptionControl; name: 'required'">
                {{ "dtmWebAppLibMission.requiredFieldErrorHint" | transloco }}
            </div>
        </dtm-ui-textarea-field>
        <hr />

        <ng-container *ngIf="vm.operationCategory?.type === MissionCategory.Specific">
            <mat-slide-toggle labelPosition="before" class="spread allow-wrap" [formControl]="isGroupOfPeopleNearOperationAreaControl">
                <span>{{
                    "dtmWebAppLibMission.itineraryEditorStep.soraSettings.isThereGatheringOfPeopleNearPerformedOperationLabel" | transloco
                }}</span>
                <dtm-ui-popover (click)="$event.preventDefault()">
                    <p>
                        {{ "dtmWebAppLibMission.itineraryEditorStep.soraSettings.UERulesForGatheringAndMovingPeopleTooltip" | transloco }}
                    </p>
                </dtm-ui-popover>
            </mat-slide-toggle>

            <dtm-ui-textarea-field *ngIf="!vm.isReadOnly && isGroupOfPeopleNearOperationAreaControl.value" [maxLength]="MAX_TEXT_LENGTH">
                <label> {{ "dtmWebAppLibMission.itineraryEditorStep.soraSettings.detailedDescriptionLabel" | transloco }}</label>
                <textarea matInput [rows]="4" [formControl]="groupOfPeopleNearOperationAreaDescriptionControl"></textarea>
                <div class="field-error" *dtmUiFieldHasError="groupOfPeopleNearOperationAreaDescriptionControl; name: 'required'">
                    {{ "dtmWebAppLibMission.requiredFieldErrorHint" | transloco }}
                </div>
                <div
                    class="field-error"
                    *dtmUiFieldHasError="groupOfPeopleNearOperationAreaDescriptionControl; name: 'maxlength'; error as error"
                >
                    {{
                        "dtmWebAppLibMission.maxLengthErrorHint"
                            | transloco : { maxLength: error.requiredLength, exceedValue: error.actualLength - error.requiredLength }
                    }}
                </div>
            </dtm-ui-textarea-field>
            <hr />
        </ng-container>

        <mat-slide-toggle labelPosition="before" class="spread allow-wrap" [formControl]="isArcAirspaceRiskDecreasedControl">
            <span [innerHTML]="'dtmWebAppLibMission.itineraryEditorStep.soraSettings.airspaceRiskReductionARCLabel' | transloco"></span>
            <dtm-ui-popover (click)="$event.preventDefault()">
                <p
                    class="statement-label"
                    [innerHTML]="'dtmWebAppLibMission.itineraryEditorStep.soraSettings.airspaceRiskReductionARCTooltip' | transloco"
                ></p>
            </dtm-ui-popover>
        </mat-slide-toggle>

        <div *ngIf="!vm.isReadOnly && isArcAirspaceRiskDecreasedControl.value">
            <dtm-ui-radio-group [formControl]="arcAirspaceRiskDecreasedTypeControl">
                <dtm-ui-radio-field [value]="ArcAirspaceRiskDecreasedType.FlightAroundObstacle">
                    <span>{{ "dtmWebAppLibMission.itineraryEditorStep.soraSettings.flightAroundObstacleLabel" | transloco }}</span>
                    <dtm-ui-popover (click)="$event.preventDefault()">
                        <div class="conditions-content">
                            <span>
                                {{ "dtmWebAppLibMission.itineraryEditorStep.soraSettings.flightAroundObstacleTooltip.header" | transloco }}
                            </span>
                            <ul>
                                <li>
                                    {{
                                        "dtmWebAppLibMission.itineraryEditorStep.soraSettings.flightAroundObstacleTooltip.firstCondition"
                                            | transloco
                                    }}
                                </li>
                                <li>
                                    {{
                                        "dtmWebAppLibMission.itineraryEditorStep.soraSettings.flightAroundObstacleTooltip.secondCondition"
                                            | transloco
                                    }}
                                </li>
                                <li>
                                    {{
                                        "dtmWebAppLibMission.itineraryEditorStep.soraSettings.flightAroundObstacleTooltip.thirdCondition"
                                            | transloco
                                    }}
                                </li>
                                <li>
                                    {{
                                        "dtmWebAppLibMission.itineraryEditorStep.soraSettings.flightAroundObstacleTooltip.fourthCondition"
                                            | transloco
                                    }}
                                </li>
                            </ul>
                        </div>
                    </dtm-ui-popover>
                </dtm-ui-radio-field>
                <div
                    class="statement"
                    *ngIf="arcAirspaceRiskDecreasedTypeControl.value === ArcAirspaceRiskDecreasedType.FlightAroundObstacle"
                >
                    <dtm-ui-checkbox-field [formControl]="isFlightAroundObstacleStatementAcceptedControl">
                        <div class="conditions-content">
                            <span class="required">*</span>
                            <span>
                                {{
                                    "dtmWebAppLibMission.itineraryEditorStep.soraSettings.flightAroundObstacleStatementText.header"
                                        | transloco
                                }}
                            </span>
                            <ul>
                                <li>
                                    {{
                                        "dtmWebAppLibMission.itineraryEditorStep.soraSettings.flightAroundObstacleStatementText.firstCondition"
                                            | transloco
                                    }}
                                </li>
                                <li>
                                    {{
                                        "dtmWebAppLibMission.itineraryEditorStep.soraSettings.flightAroundObstacleStatementText.secondCondition"
                                            | transloco
                                    }}
                                </li>
                                <li>
                                    {{
                                        "dtmWebAppLibMission.itineraryEditorStep.soraSettings.flightAroundObstacleStatementText.thirdCondition"
                                            | transloco
                                    }}
                                </li>
                                <li>
                                    {{
                                        "dtmWebAppLibMission.itineraryEditorStep.soraSettings.flightAroundObstacleStatementText.fourthCondition"
                                            | transloco
                                    }}
                                </li>
                            </ul>
                        </div>
                    </dtm-ui-checkbox-field>
                    <dtm-ui-form-errors>
                        <div class="field-error" *dtmUiFieldHasError="isFlightAroundObstacleStatementAcceptedControl; name: 'required'">
                            {{ "dtmWebAppLibMission.requiredFieldErrorHint" | transloco }}
                        </div>
                    </dtm-ui-form-errors>
                </div>
                <dtm-ui-textarea-field
                    *ngIf="
                        !vm.isReadOnly && arcAirspaceRiskDecreasedTypeControl.value === ArcAirspaceRiskDecreasedType.FlightAroundObstacle
                    "
                >
                    <label>
                        {{ "dtmWebAppLibMission.itineraryEditorStep.soraSettings.detailedDescriptionLabel" | transloco }}
                    </label>
                    <textarea matInput [rows]="4" [formControl]="flightAroundObstacleDescriptionControl"></textarea>
                    <div class="field-hint">
                        {{ "dtmWebAppLibMission.itineraryEditorStep.soraSettings.flightAroundObstacleDescriptionHint" | transloco }}
                    </div>
                    <div class="field-error" *dtmUiFieldHasError="flightAroundObstacleDescriptionControl; name: 'required'">
                        {{ "dtmWebAppLibMission.requiredFieldErrorHint" | transloco }}
                    </div>
                </dtm-ui-textarea-field>
                <dtm-ui-radio-field [value]="ArcAirspaceRiskDecreasedType.FlightInSegregatedAirspace">
                    <span>{{ "dtmWebAppLibMission.itineraryEditorStep.soraSettings.flightInSegregatedAirspaceLabel" | transloco }}</span>
                    <dtm-ui-popover (click)="$event.preventDefault()">
                        <p
                            [innerHTML]="
                                'dtmWebAppLibMission.itineraryEditorStep.soraSettings.flightInSegregatedAirspaceTooltip' | transloco
                            "
                        ></p>
                    </dtm-ui-popover>
                </dtm-ui-radio-field>
                <div
                    class="statement"
                    *ngIf="arcAirspaceRiskDecreasedTypeControl.value === ArcAirspaceRiskDecreasedType.FlightInSegregatedAirspace"
                >
                    <dtm-ui-checkbox-field [formControl]="isFlightInSegregatedAirspaceStatementAcceptedControl">
                        <div class="conditions-content">
                            <span class="required">*</span>
                            <span
                                [innerHTML]="
                                    'dtmWebAppLibMission.itineraryEditorStep.soraSettings.flightInSegregatedAirspaceLabelStatementText'
                                        | transloco
                                "
                            >
                            </span>
                        </div>
                    </dtm-ui-checkbox-field>
                    <dtm-ui-form-errors>
                        <div
                            class="field-error"
                            *dtmUiFieldHasError="isFlightInSegregatedAirspaceStatementAcceptedControl; name: 'required'"
                        >
                            {{ "dtmWebAppLibMission.requiredFieldErrorHint" | transloco }}
                        </div>
                    </dtm-ui-form-errors>
                </div>
                <dtm-ui-textarea-field
                    *ngIf="
                        !vm.isReadOnly &&
                        arcAirspaceRiskDecreasedTypeControl.value === ArcAirspaceRiskDecreasedType.FlightInSegregatedAirspace
                    "
                >
                    <label> {{ "dtmWebAppLibMission.itineraryEditorStep.soraSettings.detailedDescriptionLabel" | transloco }}</label>
                    <textarea matInput [rows]="4" [formControl]="flightInSegregatedAirspaceDescriptionControl"></textarea>
                    <div class="field-hint">
                        {{ "dtmWebAppLibMission.itineraryEditorStep.soraSettings.flightInSegregatedAirspaceDescriptionHint" | transloco }}
                    </div>
                    <div class="field-error" *dtmUiFieldHasError="flightInSegregatedAirspaceDescriptionControl; name: 'required'">
                        {{ "dtmWebAppLibMission.requiredFieldErrorHint" | transloco }}
                    </div>
                </dtm-ui-textarea-field>
                <dtm-ui-radio-field [disabled]="vm.shouldDisableLowLevelFlight" [value]="ArcAirspaceRiskDecreasedType.FlightLowAgl">
                    <span>{{ "dtmWebAppLibMission.itineraryEditorStep.soraSettings.flightLowAglLabel" | transloco }}</span>
                    <dtm-ui-popover (click)="$event.preventDefault()">
                        <div class="conditions-content">
                            <span>
                                {{ "dtmWebAppLibMission.itineraryEditorStep.soraSettings.flightLowAglTooltip.header" | transloco }}
                            </span>
                            <ul>
                                <li>
                                    {{
                                        "dtmWebAppLibMission.itineraryEditorStep.soraSettings.flightLowAglTooltip.firstCondition"
                                            | transloco
                                    }}
                                </li>
                                <li>
                                    {{
                                        "dtmWebAppLibMission.itineraryEditorStep.soraSettings.flightLowAglTooltip.secondCondition"
                                            | transloco
                                    }}
                                </li>
                                <li>
                                    {{
                                        "dtmWebAppLibMission.itineraryEditorStep.soraSettings.flightLowAglTooltip.thirdCondition"
                                            | transloco
                                    }}
                                </li>
                                <li>
                                    {{
                                        "dtmWebAppLibMission.itineraryEditorStep.soraSettings.flightLowAglTooltip.fourthCondition"
                                            | transloco
                                    }}
                                </li>
                            </ul>
                        </div>
                    </dtm-ui-popover>
                </dtm-ui-radio-field>
                <div class="statement" *ngIf="arcAirspaceRiskDecreasedTypeControl.value === ArcAirspaceRiskDecreasedType.FlightLowAgl">
                    <dtm-ui-checkbox-field required [formControl]="isFlightLowAglControl">
                        <span
                            class="statement-label"
                            [innerHTML]="'dtmWebAppLibMission.itineraryEditorStep.soraSettings.flightLowAglStatement' | transloco"
                        ></span>
                    </dtm-ui-checkbox-field>
                    <dtm-ui-form-errors>
                        <div class="field-error" *dtmUiFieldHasError="isFlightLowAglControl; name: 'required'">
                            {{ "dtmWebAppLibMission.requiredFieldErrorHint" | transloco }}
                        </div>
                    </dtm-ui-form-errors>
                </div>
                <dtm-ui-form-errors>
                    <div class="field-error" *dtmUiFieldHasError="arcAirspaceRiskDecreasedTypeControl; name: 'required'">
                        {{ "dtmWebAppLibMission.requiredFieldErrorHint" | transloco }}
                    </div>
                </dtm-ui-form-errors>
            </dtm-ui-radio-group>
        </div>
    </dtm-ui-expandable-panel>
</ng-container>
