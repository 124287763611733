<ng-container
    *ngrxLet="{
        minimumDateTime: minimumDateTime$,
        maximumDateTime: maximumDateTime$,
        uavWithSetup: uavWithSetup$,
        isProcessing: isProcessing$,
        itineraryContent: itineraryContent$,
        isAnyMapActionActive: isAnyMapActionActive$,
        constraints: constraints$,
        heightConstraints: heightConstraints$,
        missionType: missionType$,
        operationCategory: operationCategory$,
        currentPlanRoute: currentPlanRoute$,
        isEditMode: isEditMode$,
        minGroundRiskBuffer: minGroundRiskBuffer$,
        selectedCaaPermit: selectedCaaPermit$,
        datePickerPlaceholder: datePickerPlaceholder$,
        isBoundaryViolated: isBoundaryViolated$,
        areEditorsDisabled: areEditorsDisabled$,
        isOutsideDtm: isOutsideDtm$,
        isPermitBoundaryViolated: isPermitBoundaryViolated$
    } as vm"
>
    <dtm-web-app-lib-mission-wizard-step-wrapper
        *ngrxLet="vm.itineraryContent | isEmptyArray as isItineraryEmpty"
        [stepNumber]="stepNumber$ | ngrxPush"
        [stepsAmount]="stepsAmount$ | ngrxPush"
        [title]="'dtmWebAppLibMission.missionWizardSteps.itineraryEditor.header' | transloco"
        [nextButtonLabel]="
            currentEditorTypeFormControl.value === ItineraryEditorType.Assisted
                ? ('dtmWebAppLibMission.itineraryEditorStep.goToRouteSelectorButtonLabel' | transloco)
                : ('dtmWebAppLibMission.itineraryEditorStep.nextButtonLabel' | transloco)
        "
        (next)="completeStep()"
        (back)="tryToGoBack()"
        [isNextButtonEnabled]="
            !!vm.constraints &&
            !vm.isProcessing &&
            (vm.itineraryContent | invoke : isItineraryValid : currentEditorTypeFormControl.value) &&
            !vm.isAnyMapActionActive &&
            !vm.isBoundaryViolated &&
            !vm.isPermitBoundaryViolated
        "
        [isBackButtonEnabled]="!vm.isProcessing && !vm.isAnyMapActionActive"
        [isLoading]="vm.isProcessing"
        [stepId]="MissionWizardSteps.ItineraryEditor"
        data-testid="next-button"
        dtmUiInvalidFormScrollable
        #invalidFormScrollable="invalidFormScrollable"
    >
        <ng-content stepTitleContent select="[stepTitleContent]"></ng-content>
        <mat-tab-group
            *ngIf="vm.constraints; else loaderTemplate"
            [selectedIndex]="
                isItineraryEmpty || currentEditorTypeFormControl.value === ItineraryEditorType.Standard || vm.isEditMode
                    ? ItineraryEditorActiveTab.BasicMissionParameters
                    : ItineraryEditorActiveTab.ItineraryPanel
            "
            class="dtm-tabs header-tabs"
            color="accent"
            dynamicHeight
        >
            <mat-tab [label]="'dtmWebAppLibMission.itineraryEditorStep.basicMissionParametersTabLabel' | transloco">
                <dtm-ui-select-field
                    class="field-container"
                    [isClearable]="false"
                    [formControl]="userSelectedEditorTypeFormControl"
                    (selectionChange)="changeEditorType($event.value)"
                >
                    <label>{{ "dtmWebAppLibMission.itineraryEditorStep.editorTypeLabel" | transloco }}</label>
                    <dtm-ui-select-option *ngFor="let type of availableEditors$ | ngrxPush" [value]="type">
                        <ng-template [ngTemplateOutlet]="editorTypeTemplate" [ngTemplateOutletContext]="{ type: type }"></ng-template>
                    </dtm-ui-select-option>
                </dtm-ui-select-field>

                <ng-container *ngIf="vm.operationCategory | invoke : checkIfIsSoraApplicationCategory; else dateTimeTemplate">
                    <dtm-ui-date-field [isClearable]="false" class="range">
                        <label>{{ "dtmWebAppLibMission.itineraryEditorStep.missionDateRangeLabel" | transloco }}</label>
                        <mat-date-range-input
                            #datesRangeInput
                            [rangePicker]="datesRangePicker"
                            [min]="vm.minimumDateTime"
                            dtmUiDateRangeValidatorsFixer
                            [max]="dateRangeStartFormControl.value | invoke : getSameDateInNextYear"
                        >
                            <input matStartDate [formControl]="dateRangeStartFormControl" [placeholder]="vm.datePickerPlaceholder" />
                            <input
                                matEndDate
                                [formControl]="dateRangeEndFormControl"
                                [placeholder]="vm.datePickerPlaceholder"
                                [min]="dateRangeStartFormControl.value"
                            />
                        </mat-date-range-input>
                        <mat-date-range-picker #datesRangePicker [startAt]="vm.minimumDateTime"></mat-date-range-picker>
                        <div
                            class="field-hint date-information-hint"
                            [matTooltip]="'dtmWebAppLibMission.itineraryEditorStep.missionDateRangeTooltip' | transloco"
                        >
                            <dtm-ui-icon name="information"></dtm-ui-icon>
                            {{ "dtmWebAppLibMission.itineraryEditorStep.missionDateRangeHint" | transloco }}
                        </div>
                        <div class="field-error" *dtmUiFieldHasError="dateRangeStartFormControl; name: 'matDatepickerMin'">
                            {{
                                "dtmWebAppLibMission.itineraryEditorStep.minimumDateTimeErrorMessage"
                                    | transloco : { minDateTime: datesRangeInput.min | localizeDate : { dateStyle: "short" } }
                            }}
                        </div>
                        <div class="field-error" *dtmUiFieldHasError="dateRangeEndFormControl; name: 'matDatepickerMin'">
                            {{
                                "dtmWebAppLibMission.itineraryEditorStep.minimumEndDateTimeErrorMessage"
                                    | transloco : { minDateTime: dateRangeStartFormControl.value | localizeDate : { dateStyle: "short" } }
                            }}
                        </div>
                        <div class="field-error" *dtmUiFieldHasError="dateRangeEndFormControl; name: 'matDatepickerMax'">
                            {{
                                "dtmWebAppLibMission.itineraryEditorStep.maximumDateTimeErrorMessage"
                                    | transloco : { maxDateTime: datesRangeInput.max | localizeDate : { dateStyle: "short" } }
                            }}
                        </div>
                        <div
                            class="field-error"
                            *ngIf="
                                (dateRangeStartFormControl.errors?.required || dateRangeEndFormControl.errors?.required) &&
                                (dateRangeStartFormControl.touched || dateRangeEndFormControl.touched)
                            "
                        >
                            {{ "dtmWebAppLibMission.itineraryEditorStep.missionDateRangeRequiredErrorMessage" | transloco }}
                        </div>
                        <div
                            class="field-error"
                            *ngIf="
                                (dateRangeStartFormControl.errors?.matStartDateInvalid ||
                                    dateRangeEndFormControl.errors?.matEndDateInvalid) &&
                                (dateRangeStartFormControl.touched || dateRangeEndFormControl.touched)
                            "
                        >
                            {{ "dtmWebAppLibMission.itineraryEditorStep.missionDateRangeErrorMessage" | transloco }}
                        </div>
                    </dtm-ui-date-field>
                </ng-container>

                <ng-template #dateTimeTemplate>
                    <dtm-ui-date-field class="field-container">
                        <label>{{ "dtmWebAppLibMission.itineraryEditorStep.missionDateLabel" | transloco }}</label>
                        <input
                            matInput
                            [formControl]="dateFormControl"
                            [matDatepicker]="missionDatePicker"
                            [placeholder]="vm.datePickerPlaceholder"
                            [min]="vm.minimumDateTime"
                            [max]="vm.maximumDateTime"
                            required
                            data-testid="mission-start-date-input"
                        />
                        <mat-datepicker #missionDatePicker></mat-datepicker>
                        <div class="field-hint">
                            {{ "dtmWebAppLibMission.itineraryEditorStep.maximumDateTimeStartErrorAndHintMessage" | transloco }}
                        </div>
                        <div class="field-error" *dtmUiFieldHasError="dateFormControl; name: 'missionOutsidePermitPeriod'; error as error">
                            {{
                                "dtmWebAppLibMission.itineraryEditorStep.missionOutsidePermitPeriodErrorMessage"
                                    | transloco
                                        : {
                                              startDate: error.validityPeriodStart | localizeDate : { dateStyle: "short" },
                                              endDate: error.validityPeriodFinish | localizeDate : { dateStyle: "short" }
                                          }
                            }}
                        </div>
                        <div class="field-error" *ngIf="!dateFormControl.errors?.missionOutsidePermitPeriod">
                            <span *dtmUiFieldHasError="dateFormControl; name: 'matDatepickerMin'">
                                {{
                                    "dtmWebAppLibMission.itineraryEditorStep.minimumDateTimeErrorMessage"
                                        | transloco : { minDateTime: vm.minimumDateTime | localizeDate : { dateStyle: "short" } }
                                }}
                            </span>
                        </div>
                        <div class="field-error" *dtmUiFieldHasError="dateFormControl; name: 'matDatepickerMax'">
                            {{ "dtmWebAppLibMission.itineraryEditorStep.maximumDateTimeStartErrorAndHintMessage" | transloco }}
                        </div>
                        <div class="field-error" *dtmUiFieldHasError="dateFormControl; name: 'required'">
                            {{ "dtmWebAppLibMission.requiredFieldErrorHint" | transloco }}
                        </div>
                    </dtm-ui-date-field>

                    <div
                        class="field-container"
                        [class.split-time-inputs]="currentEditorTypeFormControl.value === ItineraryEditorType.Standard"
                    >
                        <dtm-ui-time-field
                            [formControl]="datetimeFormControl"
                            [minTime]="vm.minimumDateTime"
                            [referenceDate]="dateFormControl.value"
                            required
                        >
                            <label>{{ "dtmWebAppLibMission.itineraryEditorStep.missionTimeLabel" | transloco }}</label>
                            <div class="field-error" *dtmUiFieldHasError="datetimeFormControl; name: 'min'">
                                {{
                                    "dtmWebAppLibMission.itineraryEditorStep.minimumDateTimeErrorMessage"
                                        | transloco : { minDateTime: vm.minimumDateTime | localizeDate : { timeStyle: "short" } }
                                }}
                            </div>
                            <div class="field-error" *dtmUiFieldHasError="datetimeFormControl; name: 'required'">
                                {{ "dtmWebAppLibMission.requiredFieldErrorHint" | transloco }}
                            </div>
                        </dtm-ui-time-field>

                        <dtm-ui-input-field
                            [isClearable]="false"
                            *ngIf="currentEditorTypeFormControl.value === ItineraryEditorType.Standard"
                        >
                            <label>{{ "dtmWebAppLibMission.itineraryEditorStep.durationLabel" | transloco }}</label>
                            <input
                                matInput
                                type="number"
                                [formControl]="durationFormControl"
                                required
                                autocomplete="off"
                                inputmode="numeric"
                                [min]="vm.constraints.min.durationMinutes"
                                [max]="vm.constraints.max.durationMinutes"
                                data-testid="mission-duration-input"
                            />
                            <div class="field-suffix">
                                {{ "dtmWebAppLibMission.itineraryEditorStep.durationUnitLabel" | transloco }}
                            </div>
                            <div class="field-error" *ngIf="durationFormControl.errors">
                                {{
                                    "dtmWebAppLibMission.itineraryEditorStep.durationConstraintsError"
                                        | transloco : { min: vm.constraints.min.durationMinutes, max: vm.constraints.max.durationMinutes }
                                }}
                            </div>
                        </dtm-ui-input-field>
                    </div>
                </ng-template>

                <ng-container [ngSwitch]="currentEditorTypeFormControl.value">
                    <div *ngSwitchCase="ItineraryEditorType.Standard">
                        <ng-template [ngTemplateOutlet]="standardEditorTemplate"></ng-template>
                    </div>
                    <div *ngSwitchCase="ItineraryEditorType.Custom">
                        <ng-template [ngTemplateOutlet]="customEditorTemplate"></ng-template>
                    </div>
                    <div *ngSwitchCase="ItineraryEditorType.Assisted">
                        <ng-template [ngTemplateOutlet]="assistedEditorTemplate"></ng-template>
                        <ng-template [ngTemplateOutlet]="customEditorTemplate"></ng-template>
                    </div>
                    <div *ngSwitchDefault>
                        <!-- TODO: implement other editors -->
                        Ten edytor nie jest jeszcze obsługiwany
                    </div>
                </ng-container>
                <ng-template #standardEditorTemplate>
                    <dtm-web-app-lib-mission-wizard-itinerary-editor-step-zone-height-panel
                        *ngIf="vm.missionType"
                        [formControls]="missionParametersZoneHeightFormControls"
                        [constraints]="vm.heightConstraints"
                        [isExpanded]="true"
                        [isDeferred]="!isItineraryEmpty"
                        [missionType]="vm.missionType"
                        [isDisabled]="vm.isAnyMapActionActive || vm.areEditorsDisabled"
                        [operationCategory]="vm.operationCategory"
                        (deferredChangesSave)="missionParametersChange.emit($event)"
                        (formChanged)="heightPanelParametersChange.emit($event)"
                        (isEditing)="changeEditingState($event, 'isHeightEditing')"
                    >
                    </dtm-web-app-lib-mission-wizard-itinerary-editor-step-zone-height-panel>
                    <dtm-web-app-lib-mission-wizard-itinerary-editor-step-zone-radius-panel
                        *ngIf="isStepZoneRadiusVisible$ | ngrxPush"
                        [formControls]="missionParametersZoneRadiusFormControls"
                        [isExpanded]="true"
                        [isDeferred]="!isItineraryEmpty"
                        [isDisabled]="vm.isAnyMapActionActive || vm.areEditorsDisabled"
                        (deferredChangesSave)="missionParametersChange.emit($event)"
                        (isEditing)="changeEditingState($event, 'isRadiusEditing')"
                    >
                    </dtm-web-app-lib-mission-wizard-itinerary-editor-step-zone-radius-panel>
                    <dtm-web-app-lib-mission-wizard-itinerary-editor-step-settings-panel
                        *ngIf="{ category: vm.operationCategory, missionType: vm.missionType } | invoke : shouldShowItinerarySettingsPanel"
                        [formControls]="missionSettingsFormControls"
                        [uavWithSetup]="vm.uavWithSetup"
                        [isExpanded]="true"
                        [isDisabled]="vm.isAnyMapActionActive || vm.areEditorsDisabled"
                        [isDeferred]="!isItineraryEmpty"
                        [maxDeclaredHeight]="vm.currentPlanRoute?.stats?.operation?.maxDeclaredHeight"
                        (deferredChangesSave)="missionParametersChange.emit($event)"
                        (isEditing)="changeEditingState($event, 'isSettingsEditing')"
                    >
                    </dtm-web-app-lib-mission-wizard-itinerary-editor-step-settings-panel>
                </ng-template>

                <ng-template #customEditorTemplate>
                    <dtm-web-app-lib-mission-wizard-itinerary-editor-step-preferences-panel
                        [formControls]="missionPreferencesFormControls"
                        [constraints]="vm.constraints"
                        [isExpanded]="true"
                        [isDeferred]="!isItineraryEmpty"
                        [isDisabled]="vm.isAnyMapActionActive || vm.areEditorsDisabled"
                        (deferredChangesSave)="missionParametersChange.emit($event)"
                    >
                    </dtm-web-app-lib-mission-wizard-itinerary-editor-step-preferences-panel>

                    <dtm-web-app-lib-mission-wizard-itinerary-editor-step-settings-panel
                        *ngIf="vm.operationCategory?.type !== MissionCategory.Open"
                        [formControls]="missionSettingsFormControls"
                        [uavWithSetup]="vm.uavWithSetup"
                        [isExpanded]="true"
                        [isDisabled]="vm.isAnyMapActionActive || vm.areEditorsDisabled"
                        [isDeferred]="!isItineraryEmpty"
                        [maxDeclaredHeight]="vm.currentPlanRoute?.stats?.operation?.maxDeclaredHeight"
                        (deferredChangesSave)="missionParametersChange.emit($event)"
                    >
                    </dtm-web-app-lib-mission-wizard-itinerary-editor-step-settings-panel>
                </ng-template>

                <ng-template #assistedEditorTemplate>
                    <dtm-ui-expandable-panel [isExpanded]="true" [hasHeaderSeparator]="false" class="flight-level-panel">
                        <dtm-ui-expandable-simple-header
                            headerSlot
                            [label]="'dtmWebAppLibMission.itineraryEditorStep.assistedEditor.routeGeneratorPanel.header' | transloco"
                        >
                        </dtm-ui-expandable-simple-header>

                        <div class="form-entry">
                            <p class="field-label">
                                {{
                                    "dtmWebAppLibMission.itineraryEditorStep.assistedEditor.routeGeneratorPanel.routeGenerationTypeLabel"
                                        | transloco
                                }}
                            </p>
                            <div class="cards">
                                <div
                                    class="selectable-tile"
                                    [class.selected]="routeGenerationSettings.value === MissionRouteGenerationSettings.Length"
                                    (click)="setRouteGenerationSettings(MissionRouteGenerationSettings.Length)"
                                >
                                    <h6 class="selectable-tile-label">
                                        {{
                                            "dtmWebAppLibMission.itineraryEditorStep.assistedEditor.routeGeneratorPanel.routeGenerationTypeOptionLabels.shortest"
                                                | transloco
                                        }}
                                    </h6>
                                </div>
                                <div class="selectable-tile disabled">
                                    <h6 class="selectable-tile-label">
                                        {{
                                            "dtmWebAppLibMission.itineraryEditorStep.assistedEditor.routeGeneratorPanel.routeGenerationTypeOptionLabels.safest"
                                                | transloco
                                        }}
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </dtm-ui-expandable-panel>
                </ng-template>
                <dtm-web-app-lib-sora-settings
                    dtmUiMarkValueAccessorControlsAsTouched
                    [formControl]="soraSettingsFormControl"
                    [constraints]="vm.constraints"
                    [operationCategory]="vm.operationCategory"
                    [defaultGroundRiskBuffer]="vm.minGroundRiskBuffer"
                    [uavWithSetup]="vm.uavWithSetup"
                    [minGroundRiskBuffer]="vm.minGroundRiskBuffer"
                    [shouldResetArcAirspaceRisk]="isOverHeightOrSafetyArea$ | ngrxPush"
                ></dtm-web-app-lib-sora-settings>
            </mat-tab>

            <mat-tab [label]="'dtmWebAppLibMission.itineraryEditorStep.routeDataPanelTabLabel' | transloco">
                <dtm-ui-outside-dtm-info-panel
                    *ngIf="vm.isOutsideDtm && currentEditorTypeFormControl.value === ItineraryEditorType.Custom"
                    [route]="vm.currentPlanRoute"
                ></dtm-ui-outside-dtm-info-panel>
                <dtm-web-app-lib-mwiesip-distance-and-time-display
                    *ngIf="currentEditorTypeFormControl.value === ItineraryEditorType.Custom"
                    [currentPlanRoute]="vm.currentPlanRoute"
                >
                </dtm-web-app-lib-mwiesip-distance-and-time-display>
                <ng-content select="[itineraryPanel]"></ng-content>
            </mat-tab>
        </mat-tab-group>

        <dtm-web-app-lib-personal-notes
            (valueChanges)="additionalInformationChange.emit($event)"
            [information]="additionalInformation$ | ngrxPush"
            [isProcessing]="vm.isProcessing"
        ></dtm-web-app-lib-personal-notes>
    </dtm-web-app-lib-mission-wizard-step-wrapper>

    <ng-template #loaderTemplate>
        <div class="editor-loader">
            <mat-spinner diameter="40" color="accent"></mat-spinner>
            <span>{{ "dtmWebAppLibMission.itineraryEditorStep.loadingMessage" | transloco }}</span>
        </div>
    </ng-template>

    <ng-template #editorTypeTemplate let-type="type">
        <ng-container [ngSwitch]="type">
            <ng-container *ngSwitchCase="ItineraryEditorType.Standard">
                <span>{{ "dtmWebAppLibMission.itineraryEditorStep.standardEditorLabel" | transloco }}</span>
            </ng-container>
            <ng-container *ngSwitchCase="ItineraryEditorType.Custom">
                <span>{{ "dtmWebAppLibMission.itineraryEditorStep.customEditorLabel" | transloco }}</span>
            </ng-container>
            <ng-container *ngSwitchCase="ItineraryEditorType.Assisted">
                <span>{{ "dtmWebAppLibMission.itineraryEditorStep.assistedEditorLabel" | transloco }}</span>
            </ng-container>
        </ng-container>
    </ng-template>

    <ng-template #routeSideViewTemplate>
        <dtm-mission-route-side-view
            [isExpanded]="false"
            [isLoading]="vm.isProcessing"
            [isSimpleMode]="currentEditorTypeFormControl.value === ItineraryEditorType.Standard"
            [areBuffersVisible]="vm.missionType === MissionType.BVLOS"
            [route]="vm.currentPlanRoute"
            *ngIf="
                currentEditorTypeFormControl.value === ItineraryEditorType.Custom ||
                currentEditorTypeFormControl.value === ItineraryEditorType.Standard
            "
            (expandedChange)="heightViolationDialogLink.isExpanded = $event"
        >
            <div headerSlot class="side-view-title">
                <h2>{{ "dtmWebAppLibMission.routeSideView.sideViewHeader" | transloco }}</h2>
                <dtm-web-app-lib-height-violation-alert-dialog-link
                    [isExpanded]="false"
                    [route]="vm.currentPlanRoute"
                    #heightViolationDialogLink
                ></dtm-web-app-lib-height-violation-alert-dialog-link>
            </div>
        </dtm-mission-route-side-view>
    </ng-template>
</ng-container>
