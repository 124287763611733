import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { AfterViewInit, ChangeDetectionStrategy, Component, Input, Output } from "@angular/core";
import { CameraHelperService, MapUtils } from "@dtm-frontend/shared/map/cesium";
import { ItineraryEditorType } from "@dtm-frontend/shared/ui";
import { FunctionUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { ActionType, CesiumService } from "@pansa/ngx-cesium";
import { booleanContains } from "@turf/boolean-contains";
import turfBuffer from "@turf/buffer";
import turfCenter from "@turf/center";
import {
    Feature,
    MultiPolygon,
    Polygon,
    featureCollection,
    feature as turFeature,
    point as turfPoint,
    polygon as turfPolygon,
} from "@turf/helpers";
import union from "@turf/union";
import { ToastrService } from "ngx-toastr";
import { Subject, distinctUntilChanged, map, shareReplay } from "rxjs";
import { combineLatestWith } from "rxjs/operators";
import { MissionPlanItineraryWithoutConstraints, PermitLocationData } from "../../../models/mission.model";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const Cesium: any; // TODO: DTM-966

interface CaaPermitLocationComponentState {
    locationData: PermitLocationData | undefined;
    permitLocationGeometry: Feature<Polygon | MultiPolygon> | undefined;
    isZoomButtonEnabled: boolean;
    currentPlanItinerary: MissionPlanItineraryWithoutConstraints | undefined;
    shouldCheckPermitLocationViolations: boolean;
    isPermitBoundaryViolated: boolean;
}

interface CesiumKmlEntityData {
    show: boolean;
    [key: string]: any;
}

type PolygonHierarchy = typeof Cesium.PolygonHierarchy;

// NOTE: Cesium doesn't handle big polygons well, so we need to split the mask into smaller polygons
// vertical overlapping of polygons causes rendering issues when it's on other side of the globe, prevented by 180 => 179.9999
/* eslint-disable no-magic-numbers */
const mainMaskPolygon = [0, 0, 0, 90, 90, 0];
const otherMaskPolygons = [
    [0, 0, 0, -90, 90, 0],
    [0, 0, 0, 90, -90, 0],
    [0, 0, 0, -90, -90, 0],
    [-90, 0, -90, 90, -179.9999, 0],
    [-90, 0, -90, -90, -179.9999, 0],
    [90, 0, 90, 90, 179.9999, 0],
    [90, 0, 90, -90, 179.9999, 0],
];
/* eslint-enable no-magic-numbers */

const MASK_OPACITY = 0.7;
const BOUNDARY_NORMAL_OUTLINE_COLOR = Cesium.Color.fromCssColorString("#007544"); // $color-status-success
const DEFAULT_OUTLINE_DASH_LENGTH = 20;
const DEFAULT_OUTLINE_DASH_PATTERN = MapUtils.createCesiumDashPattern("---------  --");
const DEFAULT_FILL_OPACITY = 0.2;
const BOUNDARY_NORMAL_FILL_COLOR = BOUNDARY_NORMAL_OUTLINE_COLOR.withAlpha(DEFAULT_FILL_OPACITY);
const DEFAULT_OUTLINE_WIDTH = 2;
const LABELS_SHOWING_DISTANCE_IN_METERS = 20000;

const OUTLINE_MATERIAL = new Cesium.PolylineDashMaterialProperty({
    color: BOUNDARY_NORMAL_OUTLINE_COLOR,
    dashLength: DEFAULT_OUTLINE_DASH_LENGTH,
    dashPattern: DEFAULT_OUTLINE_DASH_PATTERN,
});

const LOCATION_FILL_MATERIAL = new Cesium.ImageMaterialProperty({
    transparent: false,
    image: "/assets/images/boundary_fill_pattern.png",
    color: BOUNDARY_NORMAL_FILL_COLOR,
});

const LOCATION_PIN_ID = "caa-permit-location-pin";

enum Action {
    ADD = "add",
    DELETE = "delete",
}

const PERMIT_LOCATION_GEOMETRY_VALIDATION_BUFFER_IN_METERS = 3;

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-caa-permit-location",
    templateUrl: "./caa-permit-location.component.html",
    styleUrls: ["./caa-permit-location.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class CaaPermitLocationComponent implements AfterViewInit {
    @Input()
    public set locationData(value: PermitLocationData | undefined) {
        this.localStore.patchState({ locationData: value });
    }
    @Input()
    public set isZoomButtonEnabled(value: BooleanInput) {
        this.localStore.patchState({ isZoomButtonEnabled: coerceBooleanProperty(value) });
    }
    @Input()
    public set currentPlanItinerary(value: MissionPlanItineraryWithoutConstraints | undefined) {
        this.localStore.patchState({ currentPlanItinerary: value });
    }
    @Input()
    public set shouldCheckPermitLocationViolations(value: BooleanInput) {
        this.localStore.patchState({ shouldCheckPermitLocationViolations: coerceBooleanProperty(value) });
    }
    @Output()
    public readonly isGeometryLocationViolated = this.localStore.selectByKey("isPermitBoundaryViolated");

    protected readonly Cesium = Cesium;
    private masksEntity: typeof Cesium.CustomDataSource | undefined;
    private zoneEntities: (typeof Cesium.Entity)[] = [];

    private isProcessing = false;
    private queuedAction: { action: Action; data?: PermitLocationData } | undefined;

    protected readonly pinEntities$ = new Subject();

    protected readonly isZoomButtonVisible$ = this.cameraHelperService.postRender$.pipe(
        combineLatestWith(this.localStore.selectByKey("isZoomButtonEnabled")),
        map(
            ([, isEnabled]) =>
                isEnabled && this.cesiumService.getViewer().camera.positionCartographic.height > LABELS_SHOWING_DISTANCE_IN_METERS
        ),
        distinctUntilChanged(),
        shareReplay({ bufferSize: 1, refCount: true })
    );

    private violationToastId: number | undefined;

    constructor(
        private readonly cesiumService: CesiumService,
        private readonly cameraHelperService: CameraHelperService,
        private readonly localStore: LocalComponentStore<CaaPermitLocationComponentState>,
        private readonly transloco: TranslocoService,
        private readonly toastService: ToastrService
    ) {
        localStore.setState({
            locationData: undefined,
            permitLocationGeometry: undefined,
            isZoomButtonEnabled: false,
            currentPlanItinerary: undefined,
            shouldCheckPermitLocationViolations: false,
            isPermitBoundaryViolated: false,
        });
    }

    public ngAfterViewInit(): void {
        this.localStore
            .selectByKey("locationData")
            .pipe(untilDestroyed(this))
            .subscribe(async (data) => {
                if (this.isProcessing) {
                    this.queuedAction = { action: data ? Action.ADD : Action.DELETE, data };

                    return;
                }

                this.removeZoneAndMaskEntities();

                if (!data) {
                    return;
                }

                await this.handleLocationDataAction(Action.ADD, data);
            });

        this.watchGeometryViolations();
    }

    private async handleLocationDataAction(action: Action, data?: PermitLocationData) {
        if (action === Action.ADD && data) {
            await this.createEntities(data);
        }

        if (action === Action.DELETE || !data) {
            this.removeZoneAndMaskEntities();
        }

        const { action: nextAction, data: nextData } = this.queuedAction ?? {};
        this.queuedAction = undefined;

        if (nextAction) {
            await this.handleLocationDataAction(nextAction, nextData);
        }
    }

    private async createEntities(data: PermitLocationData) {
        this.isProcessing = true;

        if (data?.kmlFile) {
            await this.handleKmlFile(data?.kmlFile);
        }

        if (data?.zoneGeometry) {
            this.createZone(this.convertGeoJsonToPolygonHierarchy(turFeature(data.zoneGeometry)) ?? [], turFeature(data.zoneGeometry));
        }

        this.isProcessing = false;
    }

    private removeZoneAndMaskEntities() {
        const viewer = this.cesiumService.getViewer();

        this.zoneEntities.forEach((entity) => viewer.entities.remove(entity));
        viewer.dataSources.remove(this.masksEntity);
        this.pinEntities$.next({
            actionType: ActionType.DELETE,
            id: LOCATION_PIN_ID,
        });

        this.cesiumService.getScene().requestRender();
    }

    protected zoomToLocation(area: Feature): void {
        this.cameraHelperService.flyToGeoJSON(area);
    }

    private async handleKmlFile(kmlFile: Blob) {
        const dataSource: CesiumKmlEntityData = await Cesium.KmlDataSource.load(kmlFile);
        const hierarchies = dataSource.entities.values
            .map((entity: CesiumKmlEntityData) => entity.polygon?.hierarchy.getValue())
            .filter(FunctionUtils.isTruthy);

        this.createZone(hierarchies);
    }

    private convertKmlDataToGeoJsonPolygons(hierarchies: PolygonHierarchy[]) {
        return hierarchies.map((hierarchy) => {
            const coordinates = [
                hierarchy.positions.map((position: string[]) => {
                    const cartographic = Cesium.Cartographic.fromCartesian(position);

                    return [Cesium.Math.toDegrees(cartographic.longitude), Cesium.Math.toDegrees(cartographic.latitude)];
                }),
                hierarchy.holes[0]?.positions.map((hole: string[]) => {
                    const cartographic = Cesium.Cartographic.fromCartesian(hole);

                    return [Cesium.Math.toDegrees(cartographic.longitude), Cesium.Math.toDegrees(cartographic.latitude)];
                }),
            ].filter(FunctionUtils.isTruthy);

            return turfPolygon(coordinates);
        });
    }

    private createZone(hierarchies: PolygonHierarchy[], originalGeometry?: Feature<Polygon | MultiPolygon>) {
        const polygons = originalGeometry ? [originalGeometry] : this.convertKmlDataToGeoJsonPolygons(hierarchies);
        const combinedPolygon = polygons.length > 1 ? union(featureCollection(polygons)) : polygons[0];
        if (!combinedPolygon) {
            return;
        }
        this.prepareMasks(hierarchies);

        this.localStore.patchState({ permitLocationGeometry: combinedPolygon });

        const center = turfCenter(combinedPolygon).geometry.coordinates;
        const cartesianCenter = MapUtils.convertSerializableCartographicToCartesian3({
            longitude: center[0],
            latitude: center[1],
            height: 0,
        });

        this.zoneEntities =
            hierarchies?.map((hole) =>
                this.cesiumService.getViewer().entities.add({
                    polygon: {
                        hierarchy: hole,
                        material: LOCATION_FILL_MATERIAL,
                        outline: false,
                    },
                })
            ) ?? [];

        this.prepareOutlines(hierarchies);

        this.pinEntities$.next({
            actionType: ActionType.ADD_UPDATE,
            id: LOCATION_PIN_ID,
            entity: { position: cartesianCenter, zoomArea: combinedPolygon },
        });

        this.cesiumService.getScene().requestRender();
        this.cameraHelperService.flyToGeoJSON(combinedPolygon, 0);
    }

    private prepareOutlines(polygonHierarchies?: PolygonHierarchy[]) {
        polygonHierarchies?.forEach((hierarchy) => {
            this.zoneEntities.push(
                this.cesiumService.getViewer().entities.add({
                    polyline: {
                        positions: hierarchy.positions,
                        material: OUTLINE_MATERIAL,
                        width: DEFAULT_OUTLINE_WIDTH,
                    },
                })
            );

            this.prepareOutlines(hierarchy.holes);
        });
    }

    private prepareMasks(hierarchies: PolygonHierarchy[]) {
        this.masksEntity = new Cesium.CustomDataSource("masks");
        this.cesiumService.getViewer().dataSources.add(this.masksEntity);

        this.masksEntity.entities.add({
            polygon: {
                hierarchy: new Cesium.PolygonHierarchy(Cesium.Cartesian3.fromDegreesArray(mainMaskPolygon), hierarchies),
                material: Cesium.Color.GREY.withAlpha(MASK_OPACITY),
            },
        });

        otherMaskPolygons.forEach((polygon) => {
            this.masksEntity.entities.add({
                polygon: {
                    hierarchy: new Cesium.PolygonHierarchy(Cesium.Cartesian3.fromDegreesArray(polygon)),
                    material: Cesium.Color.GREY.withAlpha(MASK_OPACITY),
                },
            });
        });
    }

    private readonly convertGeoJsonToPolygonHierarchy = (geoJson: Feature<Polygon | MultiPolygon>): PolygonHierarchy[] | undefined => {
        const convertCoordinatesToPolygonHierarchy = (coordinates: number[][][]): typeof Cesium.PolygonHierarchy => {
            const outerBoundary = coordinates[0].map(([longitude, latitude]) =>
                MapUtils.convertSerializableCartographicToCartesian3({ longitude, latitude, height: 0 })
            );

            const innerHoles = coordinates.slice(1).map((hole) => convertCoordinatesToPolygonHierarchy([hole]));

            return new Cesium.PolygonHierarchy(outerBoundary, innerHoles);
        };

        if (geoJson.geometry.type === "Polygon") {
            return [convertCoordinatesToPolygonHierarchy(geoJson.geometry.coordinates)];
        }
        if (geoJson.geometry.type === "MultiPolygon") {
            return geoJson.geometry.coordinates.map((polygonCoords) => convertCoordinatesToPolygonHierarchy(polygonCoords));
        }

        return;
    };

    private watchGeometryViolations() {
        this.localStore
            .selectByKey("currentPlanItinerary")
            .pipe(
                combineLatestWith(
                    this.localStore.selectByKey("permitLocationGeometry"),
                    this.localStore.selectByKey("shouldCheckPermitLocationViolations")
                ),
                untilDestroyed(this)
            )
            .subscribe(([itinerary, permitLocationGeometry, shouldCheckPermitLocationViolations]) => {
                if (!shouldCheckPermitLocationViolations || !permitLocationGeometry || !itinerary) {
                    this.localStore.patchState({ isPermitBoundaryViolated: false });
                    this.handleViolationToast(true);

                    return;
                }

                const permitLocationBufferedGeometry = turfBuffer(
                    permitLocationGeometry,
                    PERMIT_LOCATION_GEOMETRY_VALIDATION_BUFFER_IN_METERS,
                    { units: "meters" }
                );
                let someElementsOutsidePermitArea = false;

                if (permitLocationBufferedGeometry && itinerary?.type === ItineraryEditorType.Standard) {
                    someElementsOutsidePermitArea = itinerary.elements.some(
                        (element) => !booleanContains(permitLocationBufferedGeometry, turFeature(element.flightZone.area?.geometry))
                    );
                }

                if (permitLocationBufferedGeometry && itinerary?.type === ItineraryEditorType.Custom) {
                    someElementsOutsidePermitArea = itinerary.points.some((point) => {
                        if (point.flightZone?.area?.geometry) {
                            return !booleanContains(permitLocationBufferedGeometry, turFeature(point.flightZone.area.geometry));
                        }

                        if (point.position) {
                            return !booleanContains(permitLocationBufferedGeometry, turfPoint([point.position.lon, point.position.lat]));
                        }

                        return false;
                    });
                }

                if (permitLocationBufferedGeometry && itinerary?.type === ItineraryEditorType.Assisted) {
                    someElementsOutsidePermitArea = [itinerary.origin, itinerary.destination].some((point) => {
                        if (point.flightZone?.area?.geometry) {
                            return !booleanContains(permitLocationBufferedGeometry, turFeature(point.flightZone.area.geometry));
                        }

                        return false;
                    });
                }

                this.localStore.patchState({ isPermitBoundaryViolated: someElementsOutsidePermitArea });
                this.handleViolationToast(!someElementsOutsidePermitArea);
            });
    }

    private handleViolationToast(isAreaValid: boolean): void {
        if (!isAreaValid && !this.violationToastId) {
            const errorText = this.transloco.translate("dtmWebAppLibMission.caaPermitGeometryValidationErrorMessage");
            const toast = this.toastService.error(errorText, undefined, { disableTimeOut: true });
            this.violationToastId = toast.toastId;
            toast.toastRef
                .afterClosed()
                .pipe(untilDestroyed(this))
                .subscribe(() => {
                    this.violationToastId = undefined;
                });

            return;
        }
        if (isAreaValid) {
            this.toastService.clear(this.violationToastId);
            this.violationToastId = undefined;
        }
    }
}
