import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, HostBinding, Input } from "@angular/core";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { TechnicalSpecification } from "../../../models/uav.models";

export interface OtherTechnicalPropertiesComponentState {
    properties: TechnicalSpecification | undefined;
    isNarrow: boolean;
}

@Component({
    selector: "dtm-uav-lib-other-technical-properties",
    templateUrl: "./other-technical-properties.component.html",
    styleUrls: ["./other-technical-properties.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class OtherTechnicalPropertiesComponent {
    @Input({ required: true }) public set properties(value: TechnicalSpecification | undefined) {
        this.localStore.patchState({ properties: value });
    }

    @Input() public set isNarrow(value: BooleanInput) {
        this.localStore.patchState({ isNarrow: coerceBooleanProperty(value) });
    }

    @HostBinding("class.narrow") protected get isNarrowMode() {
        return this.localStore.selectSnapshotByKey("isNarrow");
    }

    protected readonly properties$ = this.localStore.selectByKey("properties").pipe(RxjsUtils.filterFalsy());

    constructor(private readonly localStore: LocalComponentStore<OtherTechnicalPropertiesComponentState>) {
        this.localStore.setState({
            properties: undefined,
            isNarrow: false,
        });
    }

    protected isEmpty(value: unknown) {
        return value === null || value === undefined;
    }
}
