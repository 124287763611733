<div class="grid grid-maxsize-8 grid-maxsize-tablet-10">
    <dtm-ui-wizard-step-wrapper
        [stepNumber]="1"
        [stepsAmount]="stepsAmount$ | ngrxPush"
        [title]="'dtmWebAppLibOperatorPermissions.statementsWizardSteps.gainPermissions.cardHeader' | transloco"
        [nextButtonLabel]="nextButtonLabel$ | ngrxPush"
        [isNextButtonEnabled]="isNextButtonEnabled$ | ngrxPush"
        (next)="goToNextStep()"
        (back)="goToPreviousView()"
        dtmUiInvalidFormScrollable
        *ngrxLet="isPaymentFeatureAvailable$ as isPaymentFeatureAvailable"
    >
        <dtm-ui-info-message type="info">
            <p [ngSwitch]="isPaymentFeatureAvailable">
                <span
                    *ngSwitchCase="true"
                    class="info-message-text"
                    [innerHTML]="'dtmWebAppLibOperatorPermissions.statementsWizardSteps.gainPermissions.infoMessage' | transloco"
                ></span>
                <span *ngSwitchCase="false" class="info-message-text">
                    {{ "dtmWebAppLibOperatorPermissions.statementsWizardSteps.gainPermissions.infoMessageWithoutPayments" | transloco }}
                </span>
            </p>
        </dtm-ui-info-message>
        <div class="statement-container" *ngrxLet="possiblePermissions$ as possiblePermissions">
            <form [formGroup]="statementsForm">
                <ng-container formArrayName="statements" *ngrxLet="availableUavs$ as availableUavs">
                    <h2 class="statements-header" *ngIf="possiblePermissions.availableOperationScenarios.length">
                        {{ "dtmWebAppLibOperatorPermissions.statementsWizardSteps.gainPermissions.permissionsToGainHeader" | transloco }}
                    </h2>
                    <div
                        *ngFor="
                            let availableOperationScenario of possiblePermissions.availableOperationScenarios;
                            index as availableOperationScenarioIndex
                        "
                        [formGroupName]="availableOperationScenarioIndex"
                    >
                        <dtm-web-app-lib-operator-permissions-single-permission-control
                            class="single-permission-control"
                            formControlName="isSelected"
                            [availableUavs]="availableUavs"
                            [singlePermission]="availableOperationScenario"
                            [isPaymentFeatureAvailable]="isPaymentFeatureAvailable"
                        ></dtm-web-app-lib-operator-permissions-single-permission-control>
                    </div>
                    <ng-container *ngIf="possiblePermissions.operationalAuthorizations.length">
                        <h2 class="statements-header">
                            {{
                                "dtmWebAppLibOperatorPermissions.statementsWizardSteps.gainPermissions.permissionsToBeExtendedHeader"
                                    | transloco
                            }}
                        </h2>
                        <div
                            *ngFor="
                                let operationalAuthorization of possiblePermissions.operationalAuthorizations;
                                index as operationalAuthorizationIndex
                            "
                            [formGroupName]="operationalAuthorizationIndex + possiblePermissions.availableOperationScenarios.length"
                        >
                            <dtm-web-app-lib-operator-permissions-single-permission-control
                                class="single-permission-control"
                                formControlName="isSelected"
                                [availableUavs]="availableUavs"
                                [singlePermission]="operationalAuthorization"
                            ></dtm-web-app-lib-operator-permissions-single-permission-control>
                        </div>
                    </ng-container>
                    <dtm-ui-form-errors class="preserve-error-space">
                        <div
                            class="field-error"
                            *dtmUiFieldHasError="statementsForm.controls.statements; name: ['atLeastOneMarked', 'requiredTouched']"
                        >
                            {{
                                "dtmWebAppLibOperatorPermissions.statementsWizardSteps.gainPermissions.atLeastOneMarkedPermissionValueError"
                                    | transloco
                            }}
                        </div>
                    </dtm-ui-form-errors>
                    <dtm-ui-info-message type="error" *ngIf="areUavsRequiredButUnavailable$ | ngrxPush" @slideIn>
                        {{ "dtmWebAppLibOperatorPermissions.statementsWizardSteps.gainPermissions.noAvailableUavsError" | transloco }}
                        <br />
                        <a routerLink="/uav/new" class="button-secondary">
                            {{ "dtmWebAppLibOperatorPermissions.statementsWizardSteps.gainPermissions.addUavButtonLabel" | transloco }}
                            <dtm-ui-icon name="add"></dtm-ui-icon>
                        </a>
                    </dtm-ui-info-message>
                </ng-container>
                <div class="statements">
                    <h2 class="statements-header">
                        {{
                            "dtmWebAppLibOperatorPermissions.statementsWizardSteps.gainPermissions.statementsForPermissionsHeader"
                                | transloco
                        }}
                    </h2>
                    <dtm-ui-label-value
                        class="statements-date-label"
                        [label]="'dtmWebAppLibOperatorPermissions.statementsWizardSteps.gainPermissions.dateOfStatementLabel' | transloco"
                        [value]="today | localizeDate"
                    >
                    </dtm-ui-label-value>
                    <dtm-ui-checkbox-field class="with-validation" formControlName="euRegulationAgreement">
                        <span class="checkbox-label">
                            <span>
                                {{
                                    "dtmWebAppLibOperatorPermissions.statementsWizardSteps.gainPermissions.euRegulationAgreement.prefixLabel"
                                        | transloco
                                }}
                                <a
                                    class="link"
                                    href="https://eur-lex.europa.eu/legal-content/PL/TXT/PDF/?uri=CELEX:02019R0947-20220404"
                                    (click)="$event.stopPropagation()"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {{
                                        "dtmWebAppLibOperatorPermissions.statementsWizardSteps.gainPermissions.euRegulationAgreement.linkLabel"
                                            | transloco
                                    }}
                                </a>
                                {{
                                    "dtmWebAppLibOperatorPermissions.statementsWizardSteps.gainPermissions.euRegulationAgreement.suffixLabel"
                                        | transloco
                                }}
                            </span>
                        </span>
                    </dtm-ui-checkbox-field>
                    <dtm-ui-form-errors class="preserve-error-space">
                        <div
                            class="field-error"
                            *dtmUiFieldHasError="statementsForm.controls.euRegulationAgreement; name: ['required', 'requiredTouched']"
                        >
                            {{ "dtmWebAppLibOperatorPermissions.statementsWizardSteps.gainPermissions.requiredValueError" | transloco }}
                        </div>
                    </dtm-ui-form-errors>
                    <dtm-ui-checkbox-field class="with-validation" formControlName="insuranceAgreement">
                        <span class="checkbox-label">
                            {{ "dtmWebAppLibOperatorPermissions.statementsWizardSteps.gainPermissions.insuranceAgreement" | transloco }}
                        </span>
                    </dtm-ui-checkbox-field>
                    <dtm-ui-form-errors class="preserve-error-space">
                        <div
                            class="field-error"
                            *dtmUiFieldHasError="statementsForm.controls.insuranceAgreement; name: ['required', 'requiredTouched']"
                        >
                            {{ "dtmWebAppLibOperatorPermissions.statementsWizardSteps.gainPermissions.requiredValueError" | transloco }}
                        </div>
                    </dtm-ui-form-errors>
                </div>
                <ng-container *ngIf="isPaymentFeatureAvailable">
                    <h2 class="statements-header">
                        {{ "dtmWebAppLibOperatorPermissions.statementsWizardSteps.gainPermissions.cashAmountHeader" | transloco }}
                    </h2>
                    <div class="payment-container">
                        <dtm-ui-label-value
                            [label]="'dtmWebAppLibOperatorPermissions.statementsWizardSteps.gainPermissions.paymentAmountLabel' | transloco"
                            [value]="(price$ | ngrxPush) ?? 0 | translocoCurrency"
                        >
                        </dtm-ui-label-value>
                    </div>
                </ng-container>
            </form>
        </div>
    </dtm-ui-wizard-step-wrapper>
</div>
