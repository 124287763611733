import { SearchAirspaceElementsOptions } from "@dtm-frontend/shared/map/geo-zones";
import {
    AdditionalCrewMember,
    FormalJustification,
    MissionPlanAnalysisStatus,
    MissionPlanInformation,
    MissionPlanOperationCategoryOption,
    MissionType,
} from "@dtm-frontend/shared/mission";
import { ItineraryEditorType } from "@dtm-frontend/shared/ui";
import { ItineraryContent } from "../models/itinerary.model";
import { MissionPlanListQuery, MissionRoutePreference } from "../models/mission.model";
import { ItineraryEditorFormData } from "./../models/mission.model";

export namespace MissionActions {
    export class GetMissionCapabilities {
        public static readonly type = "[Mission] Get mission capabilities";
    }

    export class ClearItinerary {
        public static readonly type = "[Mission] Clear itinerary";
    }

    export class CreateOrUpdateMissionCapabilities {
        public static readonly type = "[Mission] Create or update mission plan capabilities";
        constructor(
            public planId: string | null,
            public flightType: MissionType,
            public operatorId: string,
            public pilotId: string,
            public uavSetupId: string,
            public additionalCrew: AdditionalCrewMember[]
        ) {}
    }

    export class UpdateMissionPlanFlightPurpose {
        public static readonly type = "[Mission] Update mission plan flight purpose";
        constructor(
            public planId: string,
            public flightPurposeId: string,
            public flightPurposeDescription: string | undefined,
            public loadWeightKilograms: number | undefined
        ) {}
    }

    export class UpdateMissionPlanOperationCategory {
        public static readonly type = "[Mission] Update mission plan operation category";
        constructor(public planId: string, public category: MissionPlanOperationCategoryOption) {}
    }

    export class UpdateMissionPlanItinerary {
        public static readonly type = "[Mission] Update mission plan itinerary";
        constructor(public planId: string, public itineraryContent: ItineraryContent, public itineraryFormData: ItineraryEditorFormData) {}
    }

    export class GetMissionPlanItinerary {
        public static readonly type = "[Mission] Get mission plan itinerary";
        constructor(public planId: string) {}
    }

    export class GetCaaPermitData {
        public static readonly type = "[Mission] Get CAA permit data";
        constructor(public planId: string) {}
    }

    export class UpdateMissionPlanAnalysisStatus {
        public static readonly type = "[Mission] Update mission plan analysis status";
        constructor(public status: MissionPlanAnalysisStatus) {}
    }

    export class GetNearbyMissionsRouteData {
        public static readonly type = "[Mission] Get nearby missions route data";
        constructor(public planId: string, public nearbyMissionsPlanIds: string[], public collisionMissionsPlanIds: string[]) {}
    }

    export class StartMissionPlanAnalysisStatusWatch {
        public static readonly type = "[Mission] Start mission plan analysis status watch";
    }

    export class StartMissionPlanAnalysis {
        public static readonly type = "[Mission] Start mission plan analysis";
        constructor(public planId: string) {}
    }

    export class GetMissionPlanAnalysis {
        public static readonly type = "[Mission] Get mission plan analysis";
    }

    export class StopMissionPlanAnalysisStatusWatch {
        public static readonly type = "[Mission] Stop mission plan analysis status watch";
    }

    export class RegisterMission {
        public static readonly type = "[Mission] Register mission";
        constructor(public planId: string) {}
    }

    export class CloseSpecificPermitPlan {
        public static readonly type = "[Mission] Close specific permit plan";
        constructor(public planId: string) {}
    }

    export class CleanupMissionWizard {
        public static readonly type = "[Mission] Cleanup mission wizard";
    }

    export class GetMissionList {
        public static readonly type = "[Mission] Get missions list";

        constructor(public query: MissionPlanListQuery) {}
    }

    export class CleanupMissionList {
        public static readonly type = "[Mission] Cleanup mission list";
    }

    export class GetFlightPurposes {
        public static readonly type = "[Mission] Get flight purposes";
    }

    export class DeleteMissionPlan {
        public static readonly type = "[Mission] Delete mission plan";

        constructor(public id: string) {}
    }

    export class GetMissionData {
        public static readonly type = "[Mission] Get mission data";

        constructor(public planId: string | null, public withEditorOptions: boolean) {}
    }

    export class GetPlanData {
        public static readonly type = "[Mission] Get plan data";

        constructor(public planId: string) {}
    }

    export class CloneAndEditMissionPlan {
        public static readonly type = "[Mission] Clone and edit mission plan";

        constructor(public sourceId: string) {}
    }

    export class CancelMission {
        public static readonly type = "[Mission] Cancel mission";
        constructor(public missionId: string) {}
    }

    export class GetActivePermitsList {
        public static readonly type = "[Mission] Get active permits list";
    }

    export class UpdateAdditionalInformation {
        public static readonly type = "[Mission] Update additional information";
        constructor(public planId: string, public information: MissionPlanInformation) {}
    }

    export class SetSelectedEditorType {
        public static readonly type = "[Mission] Set selected editor type";
        constructor(public editorType: ItineraryEditorType | undefined) {}
    }

    export class CreateAvailableMissionRoutes {
        public static readonly type = "[Mission] Create available mission routes";
        constructor(public planId: string, public type?: MissionRoutePreference) {}
    }

    export class GetAvailableMissionRoutes {
        public static readonly type = "[Mission] Get available mission routes";
        constructor(public planId: string, public type?: MissionRoutePreference) {}
    }

    export class SetRoute {
        public static readonly type = "[Mission] Set route";
        constructor(public routeId: string) {}
    }

    export class AssignSelectedRoute {
        public static readonly type = "[Mission] Assign selected route";
    }

    export class CreateOrUpdateFormalJustifications {
        public static readonly type = "[Mission] Create or update formal justification";
        constructor(public formalJustification: FormalJustification | undefined, public planId: string) {}
    }

    export class SearchAirspaceElements {
        public static readonly type = "[Mission] Search airspace elements";
        constructor(public options: SearchAirspaceElementsOptions) {}
    }

    export class GetRouteAirspaceInfo {
        public static readonly type = "[Mission] Get route airspace info";
        constructor(public routeId: string) {}
    }

    export class ClearAirspaceElements {
        public static readonly type = "[Mission] Clear airspace elements";
    }

    export class SetIsProcessing {
        public static readonly type = "[Mission] Set is processing";
        constructor(public isProcessing: boolean) {}
    }

    export class StartMissionStatusUpdateWatch {
        public static readonly type = "[Mission] Start mission status update watch";
    }

    export class StopMissionStatusUpdateWatch {
        public static readonly type = "[Mission] Stop mission status update watch";
    }

    export class MissionPlanMessageAcknowledgement {
        public static readonly type = "[Mission] Mission plan message acknowledgement";
    }

    export class GetPansaUtmLinkStatus {
        public static readonly type = "[Mission] Get pansa utm link status";
        constructor(public pilotId: string) {}
    }

    export class GetPermitLocationData {
        public static readonly type = "[Mission] Get permit location data";
        constructor(public data?: { kmlFileId?: string; zoneDesignator?: string }) {}
    }
}
