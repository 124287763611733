import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatRadioChange } from "@angular/material/radio";
import { UavClass } from "@dtm-frontend/shared/uav";
import { FILES_UPLOAD_API_PROVIDER, UavType } from "@dtm-frontend/shared/ui";
import { AnimationUtils } from "@dtm-frontend/shared/utils";
import { CustomUavImageUploadApiService } from "../../../../../services/custom-uav-image-upload-api.service";
import { CustomUavForm } from "../basic-info-step.component";

@Component({
    selector: "dtm-web-app-lib-new-uav-wizard-basic-info-step-custom-uav[formGroup]",
    templateUrl: "./custom-uav.component.html",
    styleUrls: ["./custom-uav.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: FILES_UPLOAD_API_PROVIDER,
            useClass: CustomUavImageUploadApiService,
        },
    ],
    animations: [AnimationUtils.slideInAnimation()],
})
export class NewUavWizardBasicInfoStepCustomUavComponent {
    @Input("formGroup") public customUavForm!: FormGroup<CustomUavForm>;
    @Output() public readonly isImageEditMode = new EventEmitter<boolean>();

    @ViewChild("manufacturerInput", { read: ElementRef }) private readonly manufacturerNameInput: ElementRef<HTMLInputElement> | undefined;

    protected readonly isUavClassSetControl = new FormControl<boolean>(false, { nonNullable: true });
    protected readonly uavTypes: UavType[] = Object.values(UavType);
    protected readonly uavClasses: UavClass[] = Object.values(UavClass);

    public focusOnManufacturerNameControl() {
        this.manufacturerNameInput?.nativeElement.focus();
    }

    protected setImageEditMode(isEditMode: boolean): void {
        this.isImageEditMode.emit(isEditMode);
    }

    protected manageIsUavClassSetValue({ value }: MatRadioChange) {
        if (value) {
            this.customUavForm.controls.uavClasses.setValidators([Validators.required]);
        } else {
            this.customUavForm.controls.uavClasses.setValidators([]);
            this.customUavForm.controls.uavClasses.setValue([], { emitEvent: false });
        }
        this.customUavForm.controls.uavClasses.updateValueAndValidity();
    }
}
