import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from "@angular/core";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { MissionCategory, MissionPlanStatus, MissionProcessingPhase, MissionType, MissionUtils } from "@dtm-frontend/shared/mission";
import { ButtonTheme, ConfirmationDialogComponent, ConfirmationDialogConfig } from "@dtm-frontend/shared/ui";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { firstValueFrom } from "rxjs";
import { map } from "rxjs/operators";
import { MissionPlanContent, MissionStatus } from "../../../models/mission.model";

interface MissionPlanTileComponentState {
    missionPlan: MissionPlanContent | undefined;
    isExtendedMode: boolean;
}

enum ConfirmationMode {
    Cancel = "Cancel",
    Delete = "Delete",
    DeleteAnalyzed = "DeleteAnalyzed",
    Clone = "Clone",
}

const CONFIRMATION_MODAL_LABELS: Record<ConfirmationMode, ConfirmationDialogConfig> = {
    [ConfirmationMode.Cancel]: {
        titleText: "dtmWebAppLibMission.missionTile.cancelConfirmationDialog.title",
        confirmationText: "dtmWebAppLibMission.missionTile.cancelConfirmationDialog.contentText",
        declineButtonLabel: "dtmWebAppLibMission.missionTile.cancelConfirmationDialog.cancelButtonLabel",
        confirmButtonLabel: "dtmWebAppLibMission.missionTile.cancelConfirmationDialog.confirmButtonLabel",
        theme: ButtonTheme.Warn,
    },
    [ConfirmationMode.Delete]: {
        titleText: "dtmWebAppLibMission.missionTile.deleteConfirmationDialog.title",
        confirmationText: "dtmWebAppLibMission.missionTile.deleteConfirmationDialog.contentText",
        declineButtonLabel: "dtmWebAppLibMission.missionTile.deleteConfirmationDialog.cancelButtonLabel",
        confirmButtonLabel: "dtmWebAppLibMission.missionTile.deleteConfirmationDialog.confirmButtonLabel",
        theme: ButtonTheme.Warn,
    },
    [ConfirmationMode.Clone]: {
        titleText: "dtmWebAppLibMission.missionTile.cloneConfirmationDialog.title",
        confirmationText: "dtmWebAppLibMission.missionTile.cloneConfirmationDialog.contentText",
        declineButtonLabel: "dtmWebAppLibMission.missionTile.cloneConfirmationDialog.cancelButtonLabel",
        confirmButtonLabel: "dtmWebAppLibMission.missionTile.cloneConfirmationDialog.confirmButtonLabel",
        theme: ButtonTheme.Primary,
    },
    [ConfirmationMode.DeleteAnalyzed]: {
        titleText: "dtmWebAppLibMission.missionTile.deleteConfirmationDialog.title",
        confirmationText: "dtmWebAppLibMission.missionTile.deleteAnalyzedConfirmationDialog.contentText",
        declineButtonLabel: "dtmWebAppLibMission.missionTile.deleteConfirmationDialog.cancelButtonLabel",
        confirmButtonLabel: "dtmWebAppLibMission.missionTile.deleteConfirmationDialog.confirmButtonLabel",
        theme: ButtonTheme.Warn,
    },
};

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-mission-plan-tile[missionPlan]",
    templateUrl: "./mission-plan-tile.component.html",
    styleUrls: ["./mission-plan-tile.component.scss"],
    providers: [LocalComponentStore],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MissionPlanTileComponent {
    @ViewChild("confirmationModalTemplate", { read: TemplateRef }) private confirmationModalTemplate!: TemplateRef<unknown>;

    @Input() public set missionPlan(value: MissionPlanContent) {
        this.localStore.patchState({ missionPlan: value });
    }
    @Input() public set isExtendedMode(value: boolean) {
        this.localStore.patchState({ isExtendedMode: value });
    }

    @Output() public missionPlanDelete: EventEmitter<string> = new EventEmitter();
    @Output() public missionPlanClone: EventEmitter<string> = new EventEmitter();
    @Output() public missionCancel: EventEmitter<string> = new EventEmitter();
    @Output() public missionPlanDownloadKml: EventEmitter<string> = new EventEmitter();

    protected readonly missionPlan$ = this.localStore.selectByKey("missionPlan").pipe(RxjsUtils.filterFalsy());
    protected readonly isExtendedMode$ = this.localStore.selectByKey("isExtendedMode");
    protected readonly currentUrl$ = this.route.queryParams.pipe(map(() => this.router.url));
    protected readonly missionDates$ = this.missionPlan$.pipe(
        map((missionPlan) => ({
            startDate: new Date(missionPlan.flightStartAtMin),
            endDate: new Date(missionPlan.flightFinishAtMax),
        }))
    );

    protected readonly MissionType = MissionType;
    protected readonly MissionStatus = MissionStatus;
    protected readonly MissionProcessingPhase = MissionProcessingPhase;
    protected readonly MissionCategory = MissionCategory;

    constructor(
        private readonly localStore: LocalComponentStore<MissionPlanTileComponentState>,
        private readonly dialog: MatDialog,
        private readonly transloco: TranslocoService,
        private readonly router: Router,
        private readonly route: ActivatedRoute
    ) {
        this.localStore.setState({
            missionPlan: undefined,
            isExtendedMode: false,
        });
    }

    protected async clone() {
        const isConfirmed = await this.openDialog(ConfirmationMode.Clone);

        if (isConfirmed) {
            this.confirmCloning();
        }
    }

    protected downloadKml() {
        this.missionPlanDownloadKml.emit(this.localStore.selectSnapshotByKey("missionPlan")?.id);
    }

    protected async delete(status: MissionPlanStatus) {
        const isConfirmed = await this.openDialog(
            status === MissionPlanStatus.Analyzed ? ConfirmationMode.DeleteAnalyzed : ConfirmationMode.Delete
        );

        if (isConfirmed) {
            this.confirmDeletion();
        }
    }

    protected async cancel() {
        const isConfirmed = await this.openDialog(ConfirmationMode.Cancel);

        if (isConfirmed) {
            this.confirmCancellation();
        }
    }

    protected confirmDeletion() {
        this.missionPlanDelete.emit(this.localStore.selectSnapshotByKey("missionPlan")?.id);
    }

    protected confirmCloning() {
        this.missionPlanClone.emit(this.localStore.selectSnapshotByKey("missionPlan")?.id);
    }

    protected confirmCancellation() {
        this.missionCancel.emit(this.localStore.selectSnapshotByKey("missionPlan")?.missionId);
    }

    protected isAuthorityAcceptationConfirmed = MissionUtils.isAuthorityAcceptationConfirmed;

    protected openDetailsWithMessageOpened(planId: string) {
        this.router.navigate([planId], { relativeTo: this.route, state: { shouldOpenMessage: true } });
    }

    private openDialog(mode: ConfirmationMode): Promise<unknown> {
        const data = Object.keys(CONFIRMATION_MODAL_LABELS[mode]).reduce((obj, key) => {
            const value = CONFIRMATION_MODAL_LABELS[mode][key as keyof ConfirmationDialogConfig];

            return { ...obj, [key]: this.valueIsTranslationKey(value) ? this.transloco.translate(value) : value };
        }, {});

        const modal = this.dialog.open(ConfirmationDialogComponent, { data });

        return firstValueFrom(modal.afterClosed().pipe(untilDestroyed(this)));
    }

    private valueIsTranslationKey(value: unknown): value is string {
        return typeof value === "string" && value.includes(".");
    }
}
