<ng-container
    *ngrxLet="{
        error: error$,
        missionData: missionData$,
        missionName: missionName$,
        flightPurposes: flightPurposes$,
        operationalGeometry: operationalGeometryForPreview$,
        nearbyMissionsRouteData: nearbyMissionsRouteData$,
        route: route$,
        collisionZones: collisionZones$,
        isProcessing: isProcessing$,
        isUtmSupervisorIntegrated: isUtmSupervisorIntegrated$,
        areOtherMissionsProcessing: areOtherMissionsProcessing$,
        currentPlanAnalysisStatus: currentPlanAnalysisStatus$,
        soraSettings: soraSettingsForPreview$,
        selectedZoneId: selectedZoneId$,
        currentPlanDataAndCapabilities: currentPlanDataAndCapabilities$
    } as vm"
>
    <dtm-ui-error
        class="error"
        *ngIf="vm.error; else noErrorTemplate"
        [errorMessage]="'dtmWebAppLibMission.cannotGetMissionErrorMessage' | transloco"
        (reload)="reload()"
    ></dtm-ui-error>
    <ng-template #noErrorTemplate>
        <dtm-mission-mission-preview
            *ngIf="vm.missionData"
            [isProcessing]="vm.isProcessing"
            [missionData]="vm.missionData"
            [route]="vm.route"
            [flightPurposes]="vm.flightPurposes"
            [analysisStatus]="vm.currentPlanAnalysisStatus"
            [operationalGeometry]="vm.operationalGeometry"
            [nearbyMissionsRouteData]="vm.nearbyMissionsRouteData"
            [isUtmSupervisorIntegrated]="vm.isUtmSupervisorIntegrated"
            [isTrafficAnalysisProcessing]="vm.areOtherMissionsProcessing"
            [collisionZones]="vm.collisionZones"
            [soraSettings]="vm.soraSettings"
            [selectedZoneId]="vm.selectedZoneId"
            isRouteSideViewInitiallyExpanded
            isMeasureToolEnabled
            (messageFromManagerReadingConfirmed)="messageAcknowledged()"
            (zoneSelect)="selectZone($event)"
        >
            <h2 class="mission-name" headerSlot>
                {{ vm.missionName || ("dtmWebAppLibShared.missionTile.noNameTitle" | transloco) }}
            </h2>
            <dtm-mission-route-side-view
                sideViewSlot
                [route]="vm.route"
                [isExpanded]="true"
                [isSimpleMode]="vm.missionData.itineraryEditorType === ItineraryEditorType.Standard"
                [areBuffersVisible]="vm.missionData.flightType === MissionType.BVLOS"
                (expandedChange)="heightViolationDialogLink.isExpanded = $event"
            >
                <div headerSlot class="side-view-title">
                    <h2>{{ "dtmWebAppLibMission.routeSideView.sideViewHeader" | transloco }}</h2>
                    <dtm-web-app-lib-height-violation-alert-dialog-link
                        #heightViolationDialogLink
                        [route]="vm.route"
                    ></dtm-web-app-lib-height-violation-alert-dialog-link>
                </div>
            </dtm-mission-route-side-view>
            <ng-container mapLayersSlot>
                <dtm-web-app-lib-height-violation-alert-container></dtm-web-app-lib-height-violation-alert-container>
            </ng-container>
            <dtm-web-app-lib-personal-notes
                sideRestSlot
                [isProcessing]="vm.isProcessing"
                [information]="vm.currentPlanDataAndCapabilities?.plan?.information"
                (valueChanges)="updateAdditionalInformation($event)"
            ></dtm-web-app-lib-personal-notes>
        </dtm-mission-mission-preview>
    </ng-template>
</ng-container>
