import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { DocumentsHelperService } from "@dtm-frontend/shared/uav";
import { FilesGroup, UavType } from "@dtm-frontend/shared/ui";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import equal from "fast-deep-equal";
import { Observable, merge } from "rxjs";
import { distinctUntilChanged, first, map } from "rxjs/operators";
import {
    EditableCustomUavInitialSetup,
    EditableCustomUavSetup,
    EquipmentType,
    NavigationAccuracy,
    TechnicalSpecification,
    Tracker,
    UavSetupDocumentType,
} from "../../../../services/uav.models";
import { AccessoriesControlComponent } from "../accessories-control/accessories-control.component";
import { Accessories, AccessoriesUtils } from "../accessories-control/accessories-utils";
import { CommunicationsControlComponent } from "../communications-control/communications-control.component";
import { CommunicationsUtils, EditableCommunication } from "../communications-control/communications-utils";
import { CustomUavFlightTechnicalPropertiesControlComponent } from "./custom-uav-flight-technical-properties-control/custom-uav-flight-technical-properties-control.component";
import {
    CustomUavFlightTechnicalPropertiesUtils,
    EditableCustomUavFlightTechnicalProperties,
} from "./custom-uav-flight-technical-properties-control/custom-uav-flight-technical-properties-utils";
import { CustomUavGeneralTechnicalPropertiesControlComponent } from "./custom-uav-general-technical-properties-control/custom-uav-general-technical-properties-control.component";
import {
    CustomUavGeneralTechnicalPropertiesUtils,
    EditableCustomUavGeneralTechnicalProperties,
} from "./custom-uav-general-technical-properties-control/custom-uav-general-technical-properties-utils";

interface CustomUavSetupFormComponentState {
    setupDefaults: EditableCustomUavInitialSetup | undefined;
    isNameDisabled: boolean;
    uavType: UavType | undefined;
    isMinFlightSpeedVisible: boolean;
    trackers: Tracker[];
    navigationAccuracyItems: NavigationAccuracy[];
}

interface SetupFormValues {
    id: string | null;
    name: string;
    flightTechnicalProperties: EditableCustomUavFlightTechnicalProperties;
    generalTechnicalProperties: EditableCustomUavGeneralTechnicalProperties;
    communications: EditableCommunication[];
    documents: FilesGroup<UavSetupDocumentType>[];
    accessories: Accessories;
}

interface SetupForm {
    id: FormControl<string | null>;
    name: FormControl<string>;
    flightTechnicalProperties: FormControl<EditableCustomUavFlightTechnicalProperties | null>;
    generalTechnicalProperties: FormControl<EditableCustomUavGeneralTechnicalProperties | null>;
    communications: FormControl<EditableCommunication[]>;
    documents: FormControl<FilesGroup<UavSetupDocumentType>[]>;
    accessories: FormControl<Accessories>;
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-custom-uav-setup-form[setupDefaults][uavType][trackers][navigationAccuracyItems]",
    templateUrl: "./custom-uav-setup-form.component.html",
    styleUrls: ["./custom-uav-setup-form.component.scss", "../setup-form-common.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class CustomUavSetupFormComponent {
    protected readonly EquipmentType = EquipmentType;

    @Input() public set setupDefaults(value: EditableCustomUavInitialSetup | undefined) {
        this.localStore.patchState({ setupDefaults: value });
    }

    @Input() public set isNameDisabled(value: BooleanInput) {
        if (coerceBooleanProperty(value)) {
            this.setupForm.controls.name.disable({ emitEvent: false });
        } else {
            this.setupForm.controls.name.enable({ emitEvent: false });
        }
    }

    @Input() public set uavType(value: UavType | undefined) {
        this.localStore.patchState({
            uavType: value,
            isMinFlightSpeedVisible: value ? this.isMinFlightSpeedVisible(value) : false,
        });
    }

    @Input() public set trackers(value: Tracker[] | undefined) {
        this.localStore.patchState({ trackers: value ?? [] });
    }

    @Input() public set navigationAccuracyItems(value: NavigationAccuracy[] | undefined) {
        this.localStore.patchState({ navigationAccuracyItems: value ?? [] });
    }

    @Output() public setupChange = new EventEmitter<EditableCustomUavSetup | undefined>();

    @ViewChild(CustomUavFlightTechnicalPropertiesControlComponent)
    private flightTechnicalPropertiesControl!: CustomUavFlightTechnicalPropertiesControlComponent;
    @ViewChild(CustomUavGeneralTechnicalPropertiesControlComponent)
    private generalTechnicalPropertiesControl!: CustomUavGeneralTechnicalPropertiesControlComponent;
    @ViewChild(CommunicationsControlComponent) private communicationsControl!: CommunicationsControlComponent;
    @ViewChild(AccessoriesControlComponent) private accessoriesControl!: AccessoriesControlComponent;

    protected readonly setupForm = this.initSetupForm();
    protected readonly isMinFlightSpeedVisible$ = this.localStore.selectByKey("isMinFlightSpeedVisible");
    protected readonly trackers$ = this.localStore.selectByKey("trackers");
    protected readonly navigationAccuracyItems$ = this.localStore.selectByKey("navigationAccuracyItems");
    protected readonly uavTechnicalSpecification$ = this.initUavTechnicalSpecificationObservable();

    constructor(
        private readonly localStore: LocalComponentStore<CustomUavSetupFormComponentState>,
        private readonly setupDocumentsService: DocumentsHelperService
    ) {
        this.localStore.setState({
            setupDefaults: undefined,
            isNameDisabled: false,
            uavType: undefined,
            isMinFlightSpeedVisible: false,
            trackers: [],
            navigationAccuracyItems: [],
        });

        this.watchSetupChange();
    }

    public initSetupFormValues() {
        this.localStore
            .selectByKey("setupDefaults")
            .pipe(RxjsUtils.filterFalsy(), first(), untilDestroyed(this))
            .subscribe((setupDefaults) => {
                this.initFormValues(setupDefaults);
            });
    }

    public markInvalidField() {
        this.setupForm.markAllAsTouched();

        if (this.setupForm.controls.flightTechnicalProperties.invalid) {
            this.flightTechnicalPropertiesControl.scrollToFirstInvalidField();
        } else if (this.setupForm.controls.generalTechnicalProperties.invalid) {
            this.generalTechnicalPropertiesControl.scrollToFirstInvalidField();
        } else if (this.setupForm.controls.communications.invalid) {
            this.communicationsControl.scrollToFirstInvalidField();
        } else if (this.setupForm.controls.accessories.invalid) {
            this.accessoriesControl.scrollToFirstInvalidField();
        }
    }

    private watchSetupChange(): void {
        this.setupForm.valueChanges
            .pipe(
                map((formValues) => ({ isFormValid: this.setupForm.valid, formValues })),
                distinctUntilChanged(equal),
                untilDestroyed(this)
            )
            .subscribe(({ formValues }) => {
                this.setupChange.emit(this.setupForm.valid ? this.prepareResultSetup(formValues as SetupFormValues) : undefined);
            });
    }

    private initSetupForm() {
        return new FormGroup<SetupForm>({
            id: new FormControl<string | null>(null),
            name: new FormControl<string>("", { validators: Validators.required, nonNullable: true }),
            flightTechnicalProperties: new FormControl<EditableCustomUavFlightTechnicalProperties | null>(null, {
                validators: Validators.required,
            }),
            generalTechnicalProperties: new FormControl<EditableCustomUavGeneralTechnicalProperties | null>(null, {
                validators: Validators.required,
            }),
            communications: new FormControl<EditableCommunication[]>([], {
                nonNullable: true,
            }),
            documents: new FormControl<FilesGroup<UavSetupDocumentType>[]>([], {
                nonNullable: true,
            }),
            accessories: new FormControl<Accessories>(
                {
                    equipment: [],
                    parachuteEquipment: null,
                    trackings: [],
                },
                {
                    nonNullable: true,
                }
            ),
        });
    }

    private initFormValues(setupDefaults: EditableCustomUavInitialSetup) {
        this.setupForm.setValue(
            {
                id: setupDefaults.id ?? null,
                name: setupDefaults.name,
                flightTechnicalProperties: CustomUavFlightTechnicalPropertiesUtils.getFormValuesBySetupDefaults(
                    setupDefaults.technicalSpecification
                ),
                generalTechnicalProperties: CustomUavGeneralTechnicalPropertiesUtils.getFormValuesBySetupDefaults(
                    setupDefaults.technicalSpecification
                ),
                communications: CommunicationsUtils.getFormValuesBySetupDefaults(setupDefaults.communications),
                documents: this.setupDocumentsService.convertUavSetupDocumentsToSetupDocumentsFilesGroups(setupDefaults.documents),
                accessories: AccessoriesUtils.getCustomUavFormValuesBySetupDefaults(setupDefaults),
            },
            { emitEvent: false }
        );
    }

    private prepareResultSetup(formValues: SetupFormValues): EditableCustomUavSetup | undefined {
        const setupDefaults = this.localStore.selectSnapshotByKey("setupDefaults");
        if (!setupDefaults) {
            return undefined;
        }

        const isMinFlightSpeedVisible = this.localStore.selectSnapshotByKey("isMinFlightSpeedVisible");

        const result: EditableCustomUavSetup = {
            name: this.setupForm.controls.name.disabled ? this.setupForm.controls.name.value : formValues.name,
            technicalSpecification: {
                ...CustomUavFlightTechnicalPropertiesUtils.prepareResultTechnicalSpecificationSetup(
                    formValues.flightTechnicalProperties,
                    isMinFlightSpeedVisible
                ),
                ...CustomUavGeneralTechnicalPropertiesUtils.prepareResultTechnicalSpecificationSetup(formValues.generalTechnicalProperties),
            },
            communications: CommunicationsUtils.prepareResultCommunicationsSetup(formValues.communications),
            documents: this.setupDocumentsService.convertSetupDocumentsFilesGroupsToUavSetupDocuments(formValues.documents),
            equipment: AccessoriesUtils.prepareResultEquipmentSetup(formValues.accessories),
            trackings: AccessoriesUtils.prepareResultTrackingSetup(formValues.accessories.trackings),
        };

        if (formValues.id) {
            result.id = formValues.id;
        }

        return result;
    }

    private isMinFlightSpeedVisible(uavType: UavType) {
        return uavType === UavType.Airplane;
    }

    private initUavTechnicalSpecificationObservable(): Observable<
        EditableCustomUavFlightTechnicalProperties | TechnicalSpecification | undefined
    > {
        return merge(
            this.setupForm.controls.flightTechnicalProperties.valueChanges,
            this.localStore.selectByKey("setupDefaults").pipe(
                RxjsUtils.filterFalsy(),
                first(),
                map((setupDefaults) => setupDefaults.technicalSpecification)
            )
        ).pipe(map((flightTechnicalProperties) => flightTechnicalProperties ?? undefined));
    }
}
