import { InjectionToken } from "@angular/core";

export interface TacticalEndpoints {
    getTacticalMissions: string;
    getNearbyMissions: string;
    missionActivationManagement: string;
    missionManagement: string;
    wsFlightControlTopicName: string;
    wsFlightPositionTopicName: string;
    confirmTrackerReadings: string;
    getHemsData: string;
    emergencyManagement: string;
    reportEmergency: string;
    getCheckins: string;
    wsDtmMissionsWatch: string;
}
export const TACTICAL_ENDPOINTS = new InjectionToken<TacticalEndpoints>("Tactical endpoints");
