/* eslint-disable max-len */

import { createDynamicEnvironment } from "@dtm-frontend/shared/utils";
import type DynamicConfiguation from "../assets/dynamic.config.json";
import { WebAppEnvironment } from "./environment.model";

export const dynamicEnvironment = createDynamicEnvironment<WebAppEnvironment, typeof DynamicConfiguation>(
    fetch("../assets/dynamic.config.json").then((config) => config.json()),
    ({ apiDomain, keycloakConfig, sharedMapEndpoints }) => {
        const apiUrl = `https://${apiDomain}/api`;
        const webSocketApiUrl = `wss://${apiDomain}/api/ws`;

        return {
            production: false,
            name: "dev",
            bearerExcludedUrls: [],
            routes: {
                missionPlanningCourseUrl:
                    "https://elearning.dev.pansa.io/catalog/available-courses/21d1a243-649e-4c80-b67d-c5693468281f/details",
                eLearningPlatformUrl: "https://elearning.dev.pansa.io/",
                pansaUtmUrl: "https://utm.pansa.pl",
                termsOfUseUrl: `${apiUrl}/terms-of-service/uav-app/current`,
                accessibilityStatementUrl: `${apiUrl}/accessibility-statement`,
            },
            webSocketEndpoint: webSocketApiUrl,
            keycloakConfig,
            pilotRegistrationEndpoints: {
                getPilotRegistrationCapabilities: `${apiUrl}/uav-app/pilot-registration/users/{{userId}}/capabilities`,
                verifyForeignOperatorNumber: `${apiUrl}/uav-app/pilot-registration/operator-number/validation`,
                uploadIdentityFile: `${apiUrl}/uav-app/pilot-registration/users/{{userId}}/identity-documents`,
                downloadIdentityFile: `${apiUrl}/uav-app/pilot-registration/users/{{userId}}/identity-documents/{{identityDocumentId}}`,
                registerPilot: `${apiUrl}/uav-app/pilot-registration/pilots`,
            },
            operatorRegistrationEndpoints: {
                verifyForeignOperatorNumber: `${apiUrl}/uav-app/operator-registration/operator-number/validation`,
                uploadIdentityFile: `${apiUrl}/uav-app/operator-registration/users/{{userId}}/identity-documents`,
                downloadIdentityFile: `${apiUrl}/uav-app/operator-registration/users/{{userId}}/identity-documents/{{identityDocumentId}}`,
                getOperatorRegistrationCapabilities: `${apiUrl}/uav-app/operator-registration/users/{{userId}}/capabilities`,
                registerEnterpriseOperator: `${apiUrl}/uav-app/operator-registration/enterprise-operators`,
                registerAssociationOperator: `${apiUrl}/uav-app/operator-registration/association-operators`,
                downloadAttorneyTemplate: `${apiUrl}/uav-app/operator-registration/attorney-power-template`,
                uploadFeeConfirmation: `${apiUrl}/uav-app/operator-registration/attorney-powers/documents/fee-confirmation`,
                downloadAttorneyPowerFile: `${apiUrl}/uav-app/operator-registration/attorney-powers/documents/{{id}}`,
                uploadAttorneyPowerFile: `${apiUrl}/uav-app/operator-registration/attorney-powers/documents/document`,
            },
            missionEndpoints: {
                getCapabilities: `${apiUrl}/mission-planning/operators/{{operatorId}}/capabilities`,
                createMissionPlan: `${apiUrl}/mission-planning`,
                updateMissionPlanCapabilities: `${apiUrl}/mission-planning/{{planId}}/capabilities`,
                updateMissionPlanFlightPurpose: `${apiUrl}/mission-planning/{{planId}}/flight-purpose`,
                updateMissionPlanOperationCategory: `${apiUrl}/mission-planning/{{planId}}/operation-category`,
                updateMissionPlanItinerary: `${apiUrl}/mission-planning/{{planId}}/itinerary`,
                createMissionPlanRoutes: `${apiUrl}/mission-planning/{{planId}}/routes`,
                assignMissionPlanRoute: `${apiUrl}/mission-planning/{{planId}}/route`,
                getMissionPlanVerification: `${apiUrl}/mission-planning/{{planId}}/plan-verification`,
                getMissionRoute: `${apiUrl}/mission-planning/routes/{{routeId}}`,
                getMissionPlanRoutes: `${apiUrl}/mission-planning/routes`,
                registerMission: `${apiUrl}/mission-planning/{{planId}}/mission`,
                closeSpecificPermitPlan: `${apiUrl}/mission-planning/{{planId}}/caa-permit-applications`,
                wsMissionPlanningTopicName: "/websocket/direct/{{userId}}/mission-planning",
                getMissionPlanItinerary: `${apiUrl}/mission-planning/{{planId}}/itinerary`,
                getCaaPermitDetails: `${apiUrl}/mission-planning/{{planId}}/caa-permit-details`,
                getMissionList: `${apiUrl}/mission-planning`,
                missionManagement: `${apiUrl}/mission-planning/{{planId}}`,
                missionThumbnailManagement: `${apiUrl}/mission-planning/{{planId}}/thumbnail`,
                getFlightPurposes: `${apiUrl}/mission-planning/flight-purposes`,
                cloneMissionPlan: `${apiUrl}/mission-planning/{{planId}}/clone`,
                getActivePermitsList: `${apiUrl}/mission-planning/operators/{{operatorId}}/active-permits`,
                updateAdditionalInformation: `${apiUrl}/mission-planning/{{planId}}/additional-information`,
                createOrUpdateFormalJustification: `${apiUrl}/mission-planning/{{planId}}/formal-justification`,
                getMissionAttachment: `${apiUrl}/mission-planning/{{planId}}/attachment/{{fileId}}`,
                uploadMissionAttachment: `${apiUrl}/mission-planning/{{planId}}/attachment`,
                getPublicMissionPlan: `${apiUrl}/mission-planning/{{planId}}/public-details`,
                editorOptions: `${apiUrl}/mission-planning/{{planId}}/editor-options`,
                messageAcknowledgement: `${apiUrl}/mission-planning/manual-plan-verification/{{systemVerificationId}}/plan-acceptation/acknowledgement`,
                pansaUtmLinkStatus: `${apiUrl}/mission-planning/operators/{{operatorId}}/pilots/{{pilotId}}/pansa-utm-link`,
                downloadKml: `${apiUrl}/mission-planning/specific-permits/kml-files/{{kmlFileId}}`,
            },
            uavEndpoints: {
                getCapabilities: `${apiUrl}/uav-app/fleet-management/operators/{{operatorId}}/capabilities`,
                getManufacturers: `${apiUrl}/uav-app/fleet-management/manufacturers`,
                getModel: `${apiUrl}/uav-app/fleet-management/models/{{id}}`,
                getModelPhoto: `${apiUrl}/uav-app/fleet-management/models/photos/{{photoId}}`,
                getTrackers: `${apiUrl}/uav-app/fleet-management/trackers`,
                getShareCapabilities: `${apiUrl}/uav-app/fleet-management/uavs/{{uavId}}/share/capabilities`,
                shareSetups: `${apiUrl}/uav-app/fleet-management/uavs/{{uavId}}/share`,
                uavsManagement: `${apiUrl}/uav-app/fleet-management/uavs`,
                customUavsManagement: `${apiUrl}/uav-app/fleet-management/custom-uavs`,
                uavManagement: `${apiUrl}/uav-app/fleet-management/uavs/{{id}}`,
                customUavManagement: `${apiUrl}/uav-app/fleet-management/custom-uavs/{{id}}`,
                customUavPhotoUpload: `${apiUrl}/uav-app/fleet-management/models/photos`,
                uavSetupsManagement: `${apiUrl}/uav-app/fleet-management/uavs/{{id}}/setups`,
                uavSetupManagement: `${apiUrl}/uav-app/fleet-management/uavs/{{uavId}}/setups/{{setupId}}`,
                customUavSetupManagement: `${apiUrl}/uav-app/fleet-management/custom-uavs/{{uavId}}/setups/{{setupId}}`,
                uavDocumentUpload: `${apiUrl}/uav-app/fleet-management/models/documents`,
                uavDocumentDownload: `${apiUrl}/uav-app/fleet-management/models/documents/{{id}}`,
                attachUavDocuments: `${apiUrl}/uav-app/fleet-management/uavs/{{id}}/documents`,
                setupDocumentUpload: `${apiUrl}/uav-app/fleet-management/uavs/setups/documents`,
                setupDocumentDownload: `${apiUrl}/uav-app/fleet-management/uavs/setups/documents/{{id}}`,
            },
            specificPermitApplicationEndpoints: {
                getCapabilities: `${apiUrl}/uav-app/specific-permit-application/{{operatorId}}/capabilities`,
                getOperations: `${apiUrl}/uav-app/specific-permit-application/{{operatorId}}/available-plans`,
                getOperation: `${apiUrl}/uav-app/specific-permit-application/{{operatorId}}/available-plans/{{operationId}}/base-information`,
                draftsManagement: `${apiUrl}/uav-app/specific-permit-application/{{operatorId}}/application-drafts`,
                draftManagement: `${apiUrl}/uav-app/specific-permit-application/{{operatorId}}/application-drafts/{{draftId}}`,
                getOperationArea: `${apiUrl}/uav-app/specific-permit-application/{{operatorId}}/available-plans/{{operationId}}/route`,
                getPredefinedArea: `${apiUrl}/uav-app/specific-permit-application/{{operatorId}}/areas/{{areaId}}`,
                getRequiredPilotTrainings: `${apiUrl}/uav-app/specific-permit-application/{{operatorId}}/trainings`,
                generateApplication: `${apiUrl}/uav-app/specific-permit-application/{{operatorId}}/applications`,
                downloadDocumentTemplate: `${apiUrl}/uav-app/specific-permit-application/{{operatorId}}/document-template`,
                getCaaPermitDetails: `${apiUrl}/uav-app/specific-permit-application/{{operatorId}}/available-plans/{{planId}}/details`,
            },
            tacticalEndpoints: {
                getTacticalMissions: `${apiUrl}/flight-control/missions`,
                getNearbyMissions: `${apiUrl}/flight-control/missions/nearby`,
                missionActivationManagement: `${apiUrl}/flight-control/missions/{{missionId}}/activation`,
                missionManagement: `${apiUrl}/flight-control/missions/{{missionId}}`,
                wsFlightControlTopicName: "/websocket/direct/{{userId}}/flight-control",
                wsFlightPositionTopicName: "/websocket/topic/flight-control/spatial?bbox={{bbox}}",
                confirmTrackerReadings: `${apiUrl}/flight-control/{{missionId}}/tracker/{{trackerIdentifier}}/confirmation`,
                getHemsData: `${apiUrl}/flight-control/hems/events`,
                emergencyManagement: `${apiUrl}/flight-control/missions/{{missionId}}/emergency`,
                getCheckins: `${apiUrl}/flight-control/checkins/search`,
                wsDtmMissionsWatch: "/websocket/topic/flight-control/dtm-name/{{dtmName}}",
            },
            filesManagementEndpoints: {
                getFile: `${apiUrl}/files/{{fileId}}`,
                getFilesCompressed: `${apiUrl}/files/compressed`,
                uploadFile: `${apiUrl}/files`,
            },
            translationEndpoints: {
                getSystemTranslations: `${apiUrl}/system/translations/{{locale}}`,
            },
            versionDataEndpoints: {
                getVersionDetails: `${apiUrl}/system/service-info`,
            },
            globalCapabilities: {
                getGlobalCapabilities: `${apiUrl}/uav-app/capabilities`,
                acceptTermsOfService: `${apiUrl}/uav-app/user-profile/{{userId}}/terms-of-service`,
            },
            operatorConversationsEndpoints: {
                getOrAddThreads: `${apiUrl}/uav-app/conversations/threads`,
                manageThread: `${apiUrl}/uav-app/conversations/threads/{{threadId}}`,
                getOrAddMessages: `${apiUrl}/uav-app/conversations/threads/{{threadId}}/messages`,
                uploadFile: `${apiUrl}/uav-app/conversations/{{operatorId}}/attachments`,
                downloadFile: `${apiUrl}/uav-app/conversations/{{operatorId}}/attachments/{{fileId}}`,
                downloadCompressedFiles: `${apiUrl}/uav-app/conversations/{{operatorId}}/attachments/compressed`,
                getOperatorMessageCapabilities: `${apiUrl}/uav-app/conversations/{{operatorId}}/messages-capabilities`,
            },
            notificationEndpoints: {
                notificationsList: `${apiUrl}/notifications`,
                notificationsCount: `${apiUrl}/notifications/counter`,
                getNotifications: "/websocket/direct/{{userId}}/notifications",
                markNotificationAsRead: `${apiUrl}/notifications/{{id}}/read`,
                markAllAsRead: `${apiUrl}/notifications/read`,
            },
            sharedMapEndpoints,
            membershipEndpoints: {
                getCapabilities: `${apiUrl}/uav-app/membership/members/capabilities`,
                addInvitation: `${apiUrl}/uav-app/membership/invitations`,
                changeInvitationStatus: `${apiUrl}/uav-app/membership/invitations/{{invitationId}}/status`,
                invitationManagement: `${apiUrl}/uav-app/membership/invitations/{{invitationId}}`,
                getMembers: `${apiUrl}/uav-app/membership/members`,
                getPilotsProfile: `${apiUrl}/uav-app/membership/members/pilot/{{pilotId}}/profile`,
                changeMemberStatus: `${apiUrl}/uav-app/membership/members/{{memberId}}/status`,
                resendInvitation: `${apiUrl}/uav-app/membership/invitations/{{invitationId}}/re-invite`,
                resendInvitationToMember: `${apiUrl}/uav-app/membership/members/{{membershipId}}/re-invite`,
                removeMember: `${apiUrl}/uav-app/membership/members/{{memberId}}`,
                getMissionsList: `${apiUrl}/uav-app/membership/operators/{{operatorId}}/pilot/{{pilotId}}/missions`,
                membershipUserPermissions: `${apiUrl}/uav-app/membership/members/{{memberId}}/permissions`,
                invitedUserPermissions: `${apiUrl}/uav-app/membership/invitations/{{invitationId}}/permissions`,
            },
            userProfileEndpoints: {
                getUserProfile: `${apiUrl}/uav-app/user-profile/{{id}}`,
                getNationalNodeUserRegistration: `${apiUrl}/uav-app/user-profile/{{id}}/national-node-user-registration`,
                requestNationalNodeUserRegistrationEmailChange: `${apiUrl}/uav-app/user-profile/{{id}}/national-node-user-registration/email`,
                requestNationalNodeUserRegistrationPhoneNumberChange: `${apiUrl}/uav-app/user-profile/{{id}}/national-node-user-registration/phone-number`,
                confirmNationalNodeUserRegistrationEmail: `${apiUrl}/uav-app/user-profile/{{id}}/national-node-user-registration/email-confirmation`,
                confirmNationalNodeUserRegistrationPhoneNumber: `${apiUrl}/uav-app/user-profile/{{id}}/national-node-user-registration/phone-number-confirmation`,
                resendNationalNodeUserRegistrationPhoneNumberVerificationCode: `${apiUrl}/uav-app/user-profile/{{id}}/national-node-user-registration/phone-number-code`,
                resendNationalNodeUserRegistrationEmailVerificationCode: `${apiUrl}/uav-app/user-profile/{{id}}/national-node-user-registration/email-code`,
                registerNationalNodeUser: `${apiUrl}/uav-app/user-profile/{{id}}/national-node-user-registration/users`,
                manageProfileAvatar: `${apiUrl}/uav-app/user-profile/{{id}}/avatar`,
                saveEmailAddress: `${apiUrl}/uav-app/user-profile/{{id}}/email-change-request/{{changeRequestId}}`,
                savePhoneNumber: `${apiUrl}/uav-app/user-profile/{{id}}/phone-number-change-request/{{changeRequestId}}`,
                requestPhoneNumberChange: `${apiUrl}/uav-app/user-profile/{{id}}/phone-number-change-request`,
                requestEmailChange: `${apiUrl}/uav-app/user-profile/{{id}}/email-change-request`,
                updateProfileLanguage: `${apiUrl}/uav-app/user-profile/me/language-tag`,
                resetUserPassword: `${apiUrl}/uav-app/user-profile/{{id}}/reset-password`,
                getIdentityFile: `${apiUrl}/uav-app/user-profile/{{userId}}/identity-documents/{{identityDocumentId}}`,
                uploadIdFile: `${apiUrl}/uav-app/user-profile/{{userId}}/identity-documents`,
                saveIdentityDocument: `${apiUrl}/uav-app/user-profile/{{id}}/identity-documents/{{documentId}}`,
                downloadIdentityDocument: `${apiUrl}/uav-app/user-profile/{{userId}}/identity-documents/{{identityDocumentId}}`,
                resendLegalGuardianEmailAddress: `${apiUrl}/uav-app/user-profile/{{id}}/legal-guardian-confirmation-request/resend-email`,
                changeLegalGuardianData: `${apiUrl}/uav-app/user-profile/{{id}}/legal-guardian-confirmation-request`,
                deleteIdentityDocument: `${apiUrl}/uav-app/user-profile/{{userId}}/identity-documents/{{identityDocumentId}}`,
                getAttorneyPowerDocument: `${apiUrl}/uav-app/user-profile/attorney-powers/documents/{{documentId}}`,
            },
            operatorProfileEndpoints: {
                getOperatorProfile: `${apiUrl}/uav-app/operator-profile/{{id}}`,
                editOperatorCompanyInfo: `${apiUrl}/uav-app/operator-profile/{{id}}/company-info`,
                manageOperatorInsurancePolicy: `${apiUrl}/uav-app/operator-profile/{{id}}/insurance-policy`,
                manageOperatorAvatar: `${apiUrl}/uav-app/operator-profile/{{id}}/logo`,
                downloadOperatorConfirmationDocument: `${apiUrl}/uav-app/operator-profile/{{id}}/documents/{{documentType}}`,
                getAttorneyPowerCapabilities: `${apiUrl}/uav-app/operator-profile/{{operatorId}}/attorney-powers/capabilities`,
                downloadAttorneyTemplate: `${apiUrl}/uav-app/operator-registration/attorney-power-template`,
                addAttorneyPower: `${apiUrl}/uav-app/operator-profile/{{operatorId}}/attorney-powers`,
                removeAttorneyPower: `${apiUrl}/uav-app/operator-profile/{{operatorId}}/attorney-powers/{{attorneyPowerId}}`,
                attorneyPowerFeeConfirmationUpload: `${apiUrl}/uav-app/operator-profile/attorney-powers/documents/fee-confirmation`,
                attorneyPowerDocumentUpload: `${apiUrl}/uav-app/operator-profile/attorney-powers/documents`,
                getAttorneyPowerDocument: `${apiUrl}/uav-app/operator-profile/attorney-powers/documents/{{id}}`,
            },
            operatorPermitsEndpoints: {
                specificPermitDrafts: `${apiUrl}/uav-app/specific-permit-application/{{id}}/application-drafts`,
                specificPermits: `${apiUrl}/uav-app/specific-permits/operators/{{id}}/specific-permits`,
                getOperatorPermitsCapabilities: `${apiUrl}/uav-app/specific-permits/operators/{{id}}/specific-permits/capabilities`,
                getOperatorPermitDetails: `${apiUrl}/uav-app/specific-permits/operators/{{operatorId}}/specific-permits/{{id}}`,
                getAssociationPermitDetails: `${apiUrl}/uav-app/specific-permits/operators/{{operatorId}}/association-specific-permits/{{id}}`,
                updatePermitName: `${apiUrl}/uav-app/specific-permits/operators/{{operatorId}}/specific-permits/{{id}}/name`,
                getCrossBorderPermitDetails: `${apiUrl}/uav-app/specific-permits/operators/{{operatorId}}/cross-border-specific-permits/{{id}}`,
                downloadKmlLocationFile: `${apiUrl}/uav-app/specific-permits/operators/{{operatorId}}/specific-permits/kml-files/{{kmlFileId}}`,
                removeDraft: `${apiUrl}/uav-app/specific-permit-application/{{operatorId}}/application-drafts/{{draftId}}`,
            },
            pilotProfileEndpoints: {
                getPilotProfile: `${apiUrl}/uav-app/pilot-profile/{{id}}`,
                managePilotInsurancePolicy: `${apiUrl}/uav-app/pilot-profile/{{id}}/insurance-policy`,
                editPilotAddress: `${apiUrl}/uav-app/pilot-profile/{{id}}/address`,
                downloadDocument: `${apiUrl}/uav-app/pilot-profile/pilots/{{pilotId}}/documents/{{documentType}}`,
                managePansaUtmLink: `${apiUrl}/uav-app/pilot-profile/{{pilotId}}/pansa-utm-link`,
                addPilotCompetency: `${apiUrl}/uav-app/pilot-profile/{{pilotId}}/competency-confirmations`,
                managePilotCompetency: `${apiUrl}/uav-app/pilot-profile/{{pilotId}}/competency-confirmations/{{competencyId}}`,
                uploadForeignCompetency: `${apiUrl}/uav-app/pilot-profile/{{pilotId}}/competency-confirmation-files`,
                downloadForeignCompetency: `${apiUrl}/uav-app/pilot-profile/{{pilotId}}/competency-confirmation-files/{{fileId}}`,
            },
            legalGuardianDetailsEndpoints: {
                getLegalGuardianGeneralData: `${apiUrl}/legal-guardian-form/legal-guardian-confirmation-request/{{requestId}}`,
                generatePdf: `${apiUrl}/legal-guardian-form/legal-guardian-confirmation/pdf`,
                sendForSignature: `${apiUrl}/legal-guardian-form/legal-guardian-confirmation-request`,
                verifyLegalGuardianSignature: `${apiUrl}/legal-guardian-form/legal-guardian-confirmation-request/{{requestId}}/sign-verification`,
            },
            operatorPermissionsEndpoints: {
                ownedPermissionsList: `${apiUrl}/uav-app/operational-authorizations/operators/{{operatorId}}/operational-authorizations`,
                statementsList: `${apiUrl}/uav-app/operational-authorizations/operators/{{operatorId}}/operation-scenario-statements`,
                capabilities: `${apiUrl}/uav-app/operational-authorizations/operators/{{operatorId}}/operation-scenario-statements/capabilities`,
                saveStatement: `${apiUrl}/uav-app/operational-authorizations/operators/{{operatorId}}/operation-scenarios-statements`,
                payForStatement: `${apiUrl}/uav-app/operational-authorizations/operators/{{operatorId}}/operation-scenario-statements/{{statementId}}/payment`,
                getSavedStatement: `${apiUrl}/uav-app/operational-authorizations/operators/{{operatorId}}/operation-scenario-statements/{{statementId}}`,
                createPaymentRedirectUrl: `${apiUrl}/uav-app/operational-authorizations/operators/{{operatorId}}/operation-scenario-statements/{{statementId}}/payment`,
                createSignatureRedirectUrl: `${apiUrl}/uav-app/operational-authorizations/operators/{{operatorId}}/operation-scenario-statements/{{statementId}}/sign`,
                getUavDetailsForPermission: `${apiUrl}/uav-app/operational-authorizations/operators/{{operatorId}}/operational-authorizations/{{permissionId}}/uav-details`,
                getStatementInPdf: `${apiUrl}/uav-app/operational-authorizations/operators/{{operatorId}}/operation-scenario-statements/pdf`,
                uploadAdministrativeFeeFile: `${apiUrl}/uav-app/operational-authorizations/operators/{{operatorId}}/attorney-power-administrative-fee/documents`,
                downloadAdministrativeFeeFile: `${apiUrl}/uav-app/operational-authorizations/operators/{{operatorId}}/attorney-power-administrative-fee/documents/{{documentId}}`,
                getPermissionSignatureStatus: `${apiUrl}/uav-app/operational-authorizations/operators/{{operatorId}}/operation-scenario-statements/{{statementId}}/sign-verification`,
            },
            publicProfileEndpoints: {
                getOperatorDetails: `${apiUrl}/uav-app/public-profile/operators/{{operatorId}}`,
                getPilotDetails: `${apiUrl}/uav-app/public-profile/pilots/{{pilotId}}`,
            },
            operationsManualEndpoints: {
                getCapabilities: `${apiUrl}/uav-app/operations-manual/operators/{{operatorId}}/capabilities`,
                createOperationsManual: `${apiUrl}/uav-app/operations-manual/operators/{{operatorId}}/operations-manuals`,
                getTableOfContents: `${apiUrl}/uav-app/operations-manual/operators/{{operatorId}}/operations-manuals/{{operationsManualId}}/contents-table`,
                publish: `${apiUrl}/uav-app/operations-manual/operators/{{operatorId}}/operations-manuals/{{operationsManualId}}/publish`,
                downloadPdf: `${apiUrl}/uav-app/operations-manual/operators/{{operatorId}}/operations-manuals/{{operationsManualId}}/pdf`,
                getChapter: `${apiUrl}/uav-app/operations-manual/operators/{{operatorId}}/operations-manuals/{{operationsManualId}}/chapters/{{chapterId}}`,
                saveChapter: `${apiUrl}/uav-app/operations-manual/operators/{{operatorId}}/operations-manuals/{{operationsManualId}}/chapters/{{chapterId}}/content`,
                getAttachment: `${apiUrl}/uav-app/operations-manual/operators/{{operatorId}}/operations-manuals/{{operationsManualId}}/chapters/{{chapterId}}/attachments/{{attachmentId}}`,
                uploadAttachment: `${apiUrl}/uav-app/operations-manual/operators/{{operatorId}}/operations-manuals/{{operationsManualId}}/chapters/{{chapterId}}/attachments`,
                setChapterAttachments: `${apiUrl}/uav-app/operations-manual/operators/{{operatorId}}/operations-manuals/{{operationsManualId}}/chapters/{{chapterId}}/attachments`,
                addSubchapter: `${apiUrl}/uav-app/operations-manual/operators/{{operatorId}}/operations-manuals/{{operationsManualId}}/operation-subchapters`,
                removeSubchapter: `${apiUrl}/uav-app/operations-manual/operators/{{operatorId}}/operations-manuals/{{operationsManualId}}/operation-subchapters/{{subchapterId}}`,
            },
            azureMapsSubscriptionKey: "HS9JYfhTrM0Ud2smxePEEpkueCl81_SAD2T-VU-ZNFk",
            geoZonesEndpoints: {
                searchAirspaceElements: `${apiUrl}/geospace/airspace-elements/search`,
                wsAirspaceTopicName: "/websocket/topic/geospace/airspace/all",
            },
            trainingExamResults: {
                getTrainingResultsList: `${apiUrl}/uav-app/education/{{operatorId}}/training-confirmations`,
                getExamResultsList: `${apiUrl}/uav-app/education/{{operatorId}}/exam-confirmations`,
            },
            operatorMigrationEndpoints: {
                importOperatorDetails: `${apiUrl}/uav-app/import-operator/migration`,
                migrateEnterpriseOperator: `${apiUrl}/uav-app/import-operator/migrations/{{operatorId}}/registration/enterprise`,
                migratePersonalOperator: `${apiUrl}/uav-app/import-operator/migrations/{{operatorId}}/registration/personal`,
                resetPassword: `${apiUrl}/uav-app/import-operator/migration/temporary-password`,
            },
            dashboardEndpoints: {
                getPosts: `${apiUrl}/uav-app/dashboard/posts`,
                getMissions: `${apiUrl}/uav-app/dashboard/missions`,
            },
            weatherEndpoints: {
                getWeatherConditions: `${apiUrl}/weather-forecast/dtm/{{dtmName}}/range2`,
                getMissionPlanWeather: `${apiUrl}/weather-forecast/range2`,
            },
            flightTrackingEndpoints: {
                enrichFlightPositionUpdates: `${apiUrl}/flight-control/temp-height-enrichment/{{identifier}}`,
            },
        };
    }
);
