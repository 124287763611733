export interface OperatorInsurancePolicy {
    number: string;
    expirationDate: Date;
}

export enum AssociationRegistrationIdType {
    NationalCourtRegister = "NationalCourtRegister",
    RegistrationNumber = "RegistrationNumber",
}

export interface AssociationOperatorDetails {
    associationRegistrationId?: string;
    associationRegistrationIdType: AssociationRegistrationIdType;
}

export interface OperatorPilotOtherInformation {
    id: string;
    text: string;
}
