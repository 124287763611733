import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatLegacyCardModule as MatCardModule } from "@angular/material/legacy-card";
import { MatLegacyChipsModule as MatChipsModule } from "@angular/material/legacy-chips";
import { MatLegacyDialogModule as MatDialogModule } from "@angular/material/legacy-dialog";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { MatLegacyMenuModule as MatMenuModule } from "@angular/material/legacy-menu";
import { MatLegacyPaginatorModule as MatPaginatorModule } from "@angular/material/legacy-paginator";
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from "@angular/material/legacy-progress-spinner";
import { MatLegacyTableModule as MatTableModule } from "@angular/material/legacy-table";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { SharedMissionModule } from "@dtm-frontend/shared/mission";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { LanguageCode, SharedI18nModule, getTranslocoInlineLoader } from "@dtm-frontend/shared/ui/i18n";
import { TRANSLOCO_SCOPE, TranslocoModule } from "@jsverse/transloco";
import { LetModule, PushModule } from "@ngrx/component";
import { NgxsModule } from "@ngxs/store";
import { SharedWebAppModule } from "../shared";
import { MembershipOperatorProfileComponent } from "./components/operators/membership-operator-profile/membership-operator-profile.component";
import { MembershipOperatorsFiltersComponent } from "./components/operators/membership-operators-filters/membership-operators-filters.component";
import { MembershipOperatorsListComponent } from "./components/operators/membership-operators-list/membership-operators-list.component";
import { MembershipOperatorsComponent } from "./components/operators/membership-operators/membership-operators.component";
import { MembershipMissionsFilterComponent } from "./components/shared/membership-missions-filter/membership-missions-filter.component";
import { MembershipMissionsListComponent } from "./components/shared/membership-missions-list/membership-missions-list.component";
import { MembershipMissionsComponent } from "./components/shared/membership-missions/membership-missions.component";
import { ResignDialogComponent } from "./components/shared/resign-dialog/resign-dialog.component";
import { InvitationAcceptanceComponent } from "./components/users/invitation-acceptance/invitation-acceptance.component";
import { InvitedUsersFiltersComponent } from "./components/users/invited-users-filters/invited-users-filters.component";
import { InvitedUsersListComponent } from "./components/users/invited-users-list/invited-users-list.component";
import { ManagePermissionsDialogComponent } from "./components/users/manage-permissions-dialog/manage-permissions-dialog.component";
import { MembershipPilotProfileComponent } from "./components/users/membership-pilot-profile/membership-pilot-profile.component";
import { MembershipUsersComponent } from "./components/users/membership-users/membership-users.component";
import { PilotMemberCompetenciesComponent } from "./components/users/pilot-member-competencies/pilot-member-competencies.component";
import { UserInvitationDialogComponent } from "./components/users/user-invitation-dialog/user-invitation-dialog.component";
import { UserMemberDetailsComponent } from "./components/users/user-member-details/user-member-details.component";
import { MembershipRoutingModule } from "./membership-routing.module";
import { MembershipApiService } from "./services/membership-api.service";
import { MembershipState } from "./state/membership.state";

@NgModule({
    declarations: [
        MembershipUsersComponent,
        InvitationAcceptanceComponent,
        InvitedUsersListComponent,
        UserInvitationDialogComponent,
        ManagePermissionsDialogComponent,
        MembershipPilotProfileComponent,
        InvitedUsersFiltersComponent,
        UserMemberDetailsComponent,
        PilotMemberCompetenciesComponent,
        ResignDialogComponent,
        MembershipOperatorsComponent,
        MembershipOperatorsListComponent,
        MembershipOperatorsFiltersComponent,
        MembershipMissionsListComponent,
        MembershipMissionsComponent,
        MembershipOperatorProfileComponent,
        MembershipMissionsFilterComponent,
    ],
    imports: [
        CommonModule,
        NgxsModule.forFeature([MembershipState]),
        MembershipRoutingModule,
        TranslocoModule,
        SharedUiModule,
        SharedI18nModule,
        LetModule,
        PushModule,
        SharedWebAppModule,
        ReactiveFormsModule,
        MatCardModule,
        MatDialogModule,
        MatInputModule,
        LetModule,
        PushModule,
        MatPaginatorModule,
        MatTableModule,
        SharedI18nModule,
        MatProgressSpinnerModule,
        MatDatepickerModule,
        SharedI18nModule,
        MatMenuModule,
        MatChipsModule,
        SharedMissionModule,
        MatButtonToggleModule,
        MatSlideToggleModule,
    ],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            multi: true,
            useValue: {
                scope: "dtmWebAppMembership",
                loader: getTranslocoInlineLoader((language: LanguageCode) => import(`./assets/i18n/${language}.json`)),
            },
        },
        {
            provide: MembershipApiService,
            useValue: undefined,
        },
    ],
    exports: [
        InvitedUsersListComponent,
        UserMemberDetailsComponent,
        PilotMemberCompetenciesComponent,
        ResignDialogComponent,
        MembershipOperatorsListComponent,
        MembershipOperatorsFiltersComponent,
        InvitedUsersFiltersComponent,
        MembershipMissionsListComponent,
        MembershipMissionsFilterComponent,
        MembershipMissionsComponent,
        ManagePermissionsDialogComponent,
    ],
})
export class MembershipModule {
    public static forRoot(): ModuleWithProviders<MembershipModule> {
        return {
            ngModule: MembershipModule,
            providers: [MembershipApiService],
        };
    }

    public static forTest(): ModuleWithProviders<MembershipModule> {
        return {
            ngModule: MembershipModule,
            providers: [
                {
                    provide: MembershipApiService,
                    useValue: null,
                },
            ],
        };
    }
}
