<ng-container *ngrxLet="properties$ as properties">
    <dtm-uav-lib-setup-property
        [label]="'dtmSharedUav.setupProperties.generalTechnicalProperties.failSafeLabel' | transloco"
        [isEmpty]="properties.failSafe | invoke : isEmpty"
    >
        <span class="fail-safe-item" *ngFor="let failSafe of properties.failSafe">{{
            "dtmSharedUav.setupProperties.generalTechnicalProperties.failSafeValue" | transloco : { value: failSafe }
        }}</span>
        <span *ngIf="properties.failSafe?.length === 0">
            {{ "dtmSharedUav.setupProperties.generalTechnicalProperties.noFailSafeLabel" | transloco }}
        </span>
    </dtm-uav-lib-setup-property>
    <dtm-uav-lib-setup-property-boolean
        [label]="'dtmSharedUav.setupProperties.generalTechnicalProperties.geofencingLabel' | transloco"
        [description]="'dtmSharedUav.setupProperties.generalTechnicalProperties.geofencingDescription' | transloco"
        [value]="properties.hasGeofencing"
    />
    <dtm-uav-lib-setup-property-boolean
        [label]="'dtmSharedUav.setupProperties.generalTechnicalProperties.detectAndAvoidLabel' | transloco"
        [description]="'dtmSharedUav.setupProperties.generalTechnicalProperties.detectAndAvoidDescription' | transloco"
        [value]="properties.hasDetectAndAvoid"
    />
    <dtm-uav-lib-setup-property-boolean
        [label]="'dtmSharedUav.setupProperties.generalTechnicalProperties.geocageLabel' | transloco"
        [description]="'dtmSharedUav.setupProperties.generalTechnicalProperties.geocageDescription' | transloco"
        [value]="properties.hasGeocage"
    />
    <dtm-uav-lib-setup-property-boolean
        [label]="'dtmSharedUav.setupProperties.generalTechnicalProperties.moduleRedundancyLabel' | transloco"
        [description]="'dtmSharedUav.setupProperties.generalTechnicalProperties.moduleRedundancyDescription' | transloco"
        [value]="properties.hasModuleRedundancy"
    />
    <dtm-uav-lib-setup-property-boolean
        [label]="'dtmSharedUav.setupProperties.generalTechnicalProperties.proximitySensorsLabel' | transloco"
        [value]="properties.hasProximitySensors"
    />
</ng-container>
