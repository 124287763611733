import { SearchAirspaceElementsOptions } from "@dtm-frontend/shared/map/geo-zones";
import { MissionPlanInformation } from "@dtm-frontend/shared/mission";
import { EmergencyType, MissionData, MissionViewSettings } from "@dtm-frontend/shared/ui/tactical";
import { AllGeoJSON, BBox } from "@turf/helpers";
import { NearbyMissionsSettings } from "../services/tactical-api.service";

export namespace TacticalActions {
    export class GetAcceptedMissions {
        public static readonly type = "[Tactical] Get accepted missions";
    }

    export class GetInitialAcceptedMissions {
        public static readonly type = "[Tactical] Get initial accepted missions";
    }

    export class GetMissionWithRoute {
        public static readonly type = "[Tactical] Get mission with route";
        constructor(public missionId?: string) {}
    }

    export class CleanupMissionRoute {
        public static readonly type = "[Tactical] Cleanup mission route";
    }

    export class CleanupAcceptedMissions {
        public static readonly type = "[Tactical] Cleanup accepted missions";
    }

    export class CancelMission {
        public static readonly type = "[Tactical] Cancel mission";
        constructor(public missionId: string) {}
    }

    export class ActivateMission {
        public static readonly type = "[Tactical] Activate mission";
        constructor(public missionId: string, public missionStartTime: Date) {}
    }

    export class DeactivateMission {
        public static readonly type = "[Tactical] Deactivate mission";
        constructor(public missionId: string) {}
    }

    export class GetActiveMissions {
        public static readonly type = "[Tactical] Get active missions";
    }

    export class StartActiveMissionsUpdatesWatch {
        public static readonly type = "[Tactical] Start active missions updates watch";
    }

    export class StartAcceptedMissionsUpdatesWatch {
        public static readonly type = "[Tactical] Start accepted missions updates watch";
    }

    export class StopAcceptedMissionsUpdatesWatch {
        public static readonly type = "[Tactical] Stop accepted missions updates watch";
    }

    export class StartMissionUpdatesWatch {
        public static readonly type = "[Tactical] Start mission updates watch";
        constructor(public missionId: string) {}
    }

    export class StopMissionUpdatesWatch {
        public static readonly type = "[Tactical] Stop missions updates watch";
    }

    export class StartFlightPositionUpdatesWatch {
        public static readonly type = "[Tactical] Start flight position updates watch";
        constructor(public bbox: BBox) {}
    }

    export class StopFlightPositionUpdatesWatch {
        public static readonly type = "[Tactical] Stop flight position updates watch";
    }

    export class StartFlightViolationsUpdatesWatch {
        public static readonly type = "[Tactical] Start flight violations updates watch";
    }

    export class StopFlightViolationsUpdatesWatch {
        public static readonly type = "[Tactical] Stop flight violations updates watch";
    }

    export class GetNearbyMissions {
        public static readonly type = "[Tactical] Get nearby missions";
        constructor(public settings: NearbyMissionsSettings, public pageIndex?: number) {}
    }

    export class ClearNearbyMissions {
        public static readonly type = "[Tactical] Clear nearby missions";
    }

    export class StartSectionDeactivationEventWatch {
        public static readonly type = "[Tactical] Start section deactivation event watch";
        constructor() {}
    }

    export class StopSectionDeactivationEventWatch {
        public static readonly type = "[Tactical] Stop  section deactivation event watch";
    }

    export class ConfirmTrackerReadings {
        public static readonly type = "[Tactical] Confirm tracker readings";
        constructor(public missionId: string, public trackerIdentifier: string) {}
    }

    export class GetHemsData {
        public static readonly type = "[Tactical] Get hems data";
    }

    export class ClearHemsData {
        public static readonly type = "[Tactical] Clear hems data";
    }

    export class GetMissionPlanData {
        public static readonly type = "[Tactical] Get mission plan data";
        constructor(public planId: string) {}
    }

    export class GetMissionPlanAnalysis {
        public static readonly type = "[Tactical] Get mission plan analysis";
    }

    export class SearchAirspaceElements {
        public static readonly type = "[Tactical] Search airspace elements";
        constructor(public options: SearchAirspaceElementsOptions) {}
    }

    export class ZoomToGeometry {
        public static readonly type = "[Tactical] Zoom to geometry";
        constructor(public geometry: AllGeoJSON | undefined) {}
    }

    export class SelectNearbyMission {
        public static readonly type = "[Tactical] Select nearby mission";
        constructor(public mission: MissionData | undefined) {}
    }

    export class SetNearbyMissionViewSettings {
        public static readonly type = "[Tactical] Set nearby missions view settings";
        constructor(public settings: MissionViewSettings) {}
    }

    export class UpdateAdditionalInformation {
        public static readonly type = "[Tactical] Update additional information";
        constructor(public planId: string, public information: MissionPlanInformation) {}
    }

    export class StartMissionStatusUpdateWatch {
        public static readonly type = "[Tactical] Start mission status update watch";
    }

    export class StopMissionStatusUpdateWatch {
        public static readonly type = "[Tactical] Stop mission status update watch";
    }

    export class MissionPlanMessageAcknowledgement {
        public static readonly type = "[Tactical] Mission plan message acknowledgement";
        constructor(public reviewerUnitId?: string) {}
    }

    export class CleanupMissionPlanData {
        public static readonly type = "[Tactical] Cleanup mission plan data";
    }

    export class CleanupTacticalState {
        public static readonly type = "[Tactical] Cleanup tactical state";
    }

    export class GetMissionEmergency {
        public static readonly type = "[Tactical] Get mission emergency";
        constructor(public missionId: string) {}
    }

    export class ReportMissionEmergency {
        public static readonly type = "[Tactical] Report mission emergency";
        constructor(public emergencyType: EmergencyType) {}
    }

    export class CancelMissionEmergency {
        public static readonly type = "[Tactical] Cancel mission emergency";
    }

    export class GetCheckinList {
        public static readonly type = "[Tactical] Get checkins list";
    }

    export class StartCheckinUpdatesWatch {
        public static readonly type = "[Tactical] Start checkin updates watch";
    }

    export class StopCheckinUpdatesWatch {
        public static readonly type = "[Tactical] Stop checkin updates watch";
    }

    export class StartMissionsUpdatesWatch {
        public static readonly type = "[Tactical] Start missions updates watch";
    }

    export class StopMissionsUpdatesWatch {
        public static readonly type = "[Tactical] Stop missions updates watch";
    }

    export class ShowNotification {
        public static readonly type = "[Tactical] Show notification";
        constructor(public message: string) {}
    }
}
